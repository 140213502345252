import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests, fileDownload } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Container, Segment, Button, Divider, Grid, Popup } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module specific
import PaymentRequestForm from '../components/PaymentRequestForm';

const PaymentRequests = ({ bank_account, connection }) => {
    const { t } = useTranslation()
    const [paymentRequests, setPaymentRequests] = useState([])
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState(1)
    const [initializedPaymentRequest, setInitializedPaymentRequest] = useState(null)

    async function fetchPaymentRequests(){
        setLoading(true)

        let params = ""
        if (view === 1) params += "&transaction_status=DRAFT"
        if (view === 2) params += "&exclude_statuses=DRAFT,ACSC,RJCT"
        if (view === 3) params += "&transaction_status=ACSC"
        if (view === 4) params += "&transaction_status=RJCT"

        const request = await requests.get(API.INVOICES + "payments/payment_requests/?connection=" + connection.connection_id + "&account_id=" + bank_account.accountId + params) 
        if (request.status === 200) {
            setPaymentRequests(request.response)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchPaymentRequests()
        // eslint-disable-next-line
    }, [view])

    const initializePayment = async (payment_request) => {
        setInitializedPaymentRequest(payment_request.id)
        if (moment().diff(moment(payment_request.date_for_payment, "YYYY-MM-DD"), "days") > 0){
            setTimeout(() => {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: "",
                    description: t('payment_date_cant_be_past'),
                    animation: 'bounce',
                    time: 5000,
                }); 
                setInitializedPaymentRequest(null)
            }, 500);
            return;
        }


        const domain = window.location.href
        const request = await requests.post(API.INVOICES + "payments/payment_requests/" + payment_request.id + "/initialize_payment/?connection=" + connection.connection_id + "&domain=" + domain)

        if(request.status === 200) {
            // confirm payment if success
            window.location.href = request.response.authorize_url;
        } else {
            if (request.response?.transactionStatus === "RJCT") {
                setPaymentRequests(prev => prev.filter(item => item.id !== payment_request.id))
            }

            toast({
                type: 'error',
                icon: 'warning',
                title: request.response?.transactionStatus === "RJCT" ? "REJECTED" : "",
                description: request.response?.errorDescription || request.response?.additionalInformation || "Error",
                animation: 'bounce',
                time: 5000,
            }); 
        }
        setInitializedPaymentRequest(null)
    }

    const reInitializePayment = async (payment_request) => {
        setInitializedPaymentRequest(payment_request.id)
        if (moment().diff(moment(payment_request.date_for_payment, "YYYY-MM-DD"), "days") > 0){
            setTimeout(() => {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: "",
                    description: t('payment_date_cant_be_past'),
                    animation: 'bounce',
                    time: 5000,
                }); 
                setInitializedPaymentRequest(null)
            }, 500);
            return;
        }

        const domain = window.location.href
        const request = await requests.post(API.INVOICES + "payments/payment_requests/" + payment_request.id + "/reinitialize_payment/?connection=" + connection.connection_id + "&domain=" + domain)

        if(request.status === 200) {
            // confirm payment if success
            window.location.href = request.response.authorize_url;
        } else {
            if (request.response?.transactionStatus !== "RJCT") {
                setPaymentRequests(prev => prev.filter(item => item.id !== payment_request.id))
            } else if (request.response?.transactionStatus === "RJCT") {
                const fetchPaymentRequests = await requests.get(API.INVOICES + "payments/payment_requests/" + payment_request.id + "/?connection=" + connection.connection_id)
                if (fetchPaymentRequests.status === 200) {
                    setPaymentRequests(prev => prev.map(item => {
                        if (item.id === payment_request.id) {
                            item = fetchPaymentRequests.response
                        }
    
                        return item
                    }))
                }
            }

            toast({
                type: 'error',
                icon: 'warning',
                title: request.response?.transactionStatus === "RJCT" ? "REJECTED" : "",
                description: request.response?.errorDescription || request.response?.additionalInformation || "Error",
                animation: 'bounce',
                time: 5000,
            }); 
        }
        setInitializedPaymentRequest(null)
    }

    const calculateAmount = (payment_request) => {
        return payment_request.payments.map(payment => parseFloat(payment.amount)).reduce((partialSum, amount) => partialSum + amount, 0).toFixed(2)
    }

    const generateSEPAXML = async (payment_request) => {
        await fileDownload("GET", API.INVOICES + "payments/payment_requests/" + payment_request.id + "/xml_sepa/?connection=" + connection.connection_id, "application/xml", "xml-sepa-"+ payment_request.request_id +".xml")
    }

    const FlexRowWithToggle = ({ rowContent, content, loading, background }) => {
        const [open, setOpen] = useState(false)

        return (
            <Segment style={{ padding: 0, marginBottom: "0.5rem", background: background || "var(--white)" }}>
                <FlexRow background="transparent" borders>
                    <FlexItem textAlign="center" basis="10%">
                        <Icon
                            style={{ cursor: 'pointer' }}
                            name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
                            onClick={() => setOpen(!open)}
                        />
                    </FlexItem>
                    {rowContent}
                </FlexRow>
                {open &&
                    <Segment loading={loading} style={{ padding: 0, marginTop: 0, border: "none" }}>
                        {content}
                    </Segment>
                }
            </Segment>
        )
    }

    return (
        <Container fluid>
            <Grid>
                <Grid.Row columns="2" style={{ padding: 0 }}>
                    <Grid.Column>
                        <Button.Group align="left" basic size="small" style={{ borderRadius: 0, textAlign: "left" }}>
                            <Button active={view === 1} style={{ fontWeight: view === 1 ? "bold" : "normal" }} onClick={ () => setView(1) }>{ t('draft') }</Button>
                            <Button active={view === 2} style={{ fontWeight: view === 2 ? "bold" : "normal" }} onClick={ () => setView(2) }>{ t('processing_payment') }</Button>
                            <Button active={view === 3} style={{ fontWeight: view === 3 ? "bold" : "normal" }} onClick={ () => setView(3) }>{ t('completed') }</Button>
                            <Button active={view === 4} style={{ fontWeight: view === 4 ? "bold" : "normal" }} onClick={ () => setView(4) }>{ t('rejected_payment') }</Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column style={{ textAlign: "right" }}>
                        <Icon name="funnel-outline" className="disabled" style={{ marginTop: "0.8rem", cursor: "not-allowed" }}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <Segment 
                loading={loading}
                style={{ 
                    padding: 0, 
                    background: "transparent", 
                    boxShadow: "none", 
                    border: "none",
                    marginBottom: "1rem",
                    marginTop: "2rem",
                }}
            >
                { loading && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_loading_payments') } </p> }

                { !loading && 
                    <>
                        <EmptyRow length={paymentRequests.length}/>
                        { paymentRequests.map(request => (
                            <FlexRowWithToggle
                                background={"var(--light)"}
                                key={request.id}
                                loading={false}
                                rowContent={
                                    <>
                                        <FlexItem basis="50%">
                                            <strong> { t('date_for_payment') } </strong><br/>
                                            <small>{ moment(request.date_for_payment).format("DD.MM.YYYY") }</small>
                                        </FlexItem>
                                        <FlexItem basis="50%">
                                            <strong> { t('transactions') } </strong><br/>
                                            <small>{request.payments.length} { request.payments.length > 1 && <>(BULK)</> }</small>
                                        </FlexItem>
                                        <FlexItem basis="50%">
                                            <strong> { t('sum') } </strong><br/>
                                            <small>{ calculateAmount(request) } { bank_account.accountReference.currency }</small>
                                        </FlexItem>
                                        <FlexItem basis="50%">
                                            <strong> { request.transaction_status === "RJCT" ? t('reason') : t('status') } </strong><br/>
                                            <small>
                                                { request.transaction_status === "RJCT" 
                                                    ? <> { request.rejection_reason } <Popup position="top center" trigger={ <Icon name="information-circle-outline" style={{ cursor: "pointer" }}/>} content={t('rejection_reason_' + request.rejection_reason)} /></>
                                                    : request.transaction_status 
                                                }
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="50%" textAlign="right">
                                            { ((request.transaction_status === "DRAFT" || request.transaction_status === "RJCT") && bank_account.permissions.can_manage_account_payments) && 
                                                <SuperDuperModal
                                                    size="small"
                                                    closeOnDimmerClick={false}
                                                    closeOnDocumentClick={false}
                                                    trigger={
                                                        <Icon name="pencil-outline" style={{ cursor: "pointer", marginRight: "1.5rem" }}/>
                                                    }
                                                    content={ 
                                                        <PaymentRequestForm payment_request={request} setPaymentRequests={setPaymentRequests} connection={connection} bankAccount={bank_account}/>
                                                    }
                                                />
                                            }
                                            <Popup
                                                position="top center"
                                                trigger={
                                                    <Icon name="download-outline" style={{ cursor: "pointer", marginRight: "1.5rem", color: "var(--blue)" }} onClick={ () => generateSEPAXML(request) }/>
                                                }
                                                content={t('SEPA XML')}
                                            />
                                            <>
                                                { request.transaction_status === "RJCT" 
                                                    ? (bank_account.bankCode === "TBSK" && bank_account.permissions.can_manage_account_payments) &&
                                                        <Button onClick={ () => reInitializePayment(request) } size="small" 
                                                            loading={initializedPaymentRequest === request.id}
                                                            disabled={((request.transaction_status !== "DRAFT" && request.transaction_status !== "RJCT") || (initializedPaymentRequest !== null && initializedPaymentRequest !== request.id)) || initializedPaymentRequest === request.id} primary>
                                                            { t('re-pay') }
                                                        </Button>
                                                        
                                                    : (bank_account.bankCode === "TBSK" && ['DRAFT'].includes(request.transaction_status) && bank_account.permissions.can_manage_account_payments) &&
                                                        <Button onClick={ () => initializePayment(request) } size="small" 
                                                            loading={initializedPaymentRequest === request.id}
                                                            disabled={request.transaction_status !== "DRAFT" || request.payments.length === 0 || (initializedPaymentRequest !== null && initializedPaymentRequest !== request.id) || initializedPaymentRequest === request.id} primary>
                                                            { t('pay') }
                                                        </Button>
                                                }
                                            </>
                                            
                                        </FlexItem>
                                    </>
                                }
                                content={
                                    <Container fluid style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                        <EmptyRow length={request.payments.length}/>
                                        { request.payments.map((payment, index) => (
                                            <FlexRow padding="1rem" borders>
                                                <FlexItem basis="20%">
                                                    <strong> #{ index + 1 } </strong><br/>
                                                </FlexItem>
                                                <FlexItem basis="110%">
                                                    <strong> { t('receiver_iban_bic') } </strong><br/>
                                                    <small>{payment.receiver_iban} / {payment.receiver_bic}</small>
                                                </FlexItem>
                                                <FlexItem>
                                                    <strong> { t('receiver_name') } </strong><br/>
                                                    <small>{payment.receiver_name}</small>
                                                </FlexItem>
                                                <FlexItem>
                                                    <strong> { t('reference') } </strong><br/>
                                                    <small>{payment.end_to_end_reference}</small>
                                                </FlexItem>
                                                <FlexItem>
                                                    <strong> { t('sum') } </strong><br/>
                                                    <small>{payment.amount} { payment.currency }</small>
                                                </FlexItem>
                                                <FlexItem>
                                                    <strong> { t('note') } </strong><br/>
                                                    <small>{payment.receiver_note !== "" ? payment.receiver_note : t('empty_note')}</small>
                                                </FlexItem>
                                            </FlexRow>
                                        )) }
                                    </Container>
                                }
                            />
                        )) }
                    </>
                }
            </Segment>
        </Container>
    );
};

export default PaymentRequests;