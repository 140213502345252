import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import { Container } from 'semantic-ui-react';
import UsersList from '../../users/views/UsersList';
import ColorPreferences from '../components/ColorPreferences';
import DocumentTemplates from '../components/templates/DocumentTemplates';
import CustomPermissions from '../components/permissions/CustomPermissions';
import TypesList from '../components/types-management/TypesList';
import Gdpr from '../components/gdprs/Gdpr';
import IntegrationsList from '../components/integrations/IntegrationsList';
import Preferences from '../components/Preferences';
import FinanceACL from '../components/FinanceACL';
import TariffsManagement from '../components/TariffsManagement';
import CatalogueItems from '../../../sm/orders/sales/views/CatalogueItems';
import Imports from '../components/imports/Imports';
import Warehouses from '../components/Warehouses';
import WarehousePositions from '../components/WarehousePositions';
import ShiftManagement from '../components/ShiftManagement';

const SettingsView = () => {
    const params = useParams();
    // eslint-disable-next-line
    const { t } = useTranslation();

    return (
        <>
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                {params.slug === undefined && <ColorPreferences />}
                {params.slug === 'appearance' && <ColorPreferences />}
                {params.slug === 'templates' && <DocumentTemplates />}
                {params.slug === 'permissions' && <CustomPermissions />}
                {params.slug === 'users' && <UsersList />}
                {params.slug === 'types' && <TypesList />}
                {params.slug === 'tariffs' && <TariffsManagement />}
                {params.slug === 'gdpr' && <Gdpr />}
                {params.slug === 'integrations' && <IntegrationsList />}
                {params.slug === 'preferences' && <Preferences />}
                {params.slug === 'finances' && <FinanceACL />}
                {params.slug === 'catalogue-items' && <CatalogueItems />}
                {params.slug === 'imports' && <Imports />}
                {params.slug === 'warehouses' && <Warehouses />}
                {params.slug === 'warehouse-positions' && <WarehousePositions />}
                {params.slug === 'work-shifts' && <ShiftManagement />}
            </Container>
        </>
    );
};

export default SettingsView;
