import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { icons } from '@store/icons';
import { tzDateTime } from '@helpers/dates';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import SpinnerSegment from '@components/SpinnerSegment';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Label, Divider, /*Menu,*/ Table, Popup, Grid, Segment } from 'semantic-ui-react';
//
import InvoiceReferenceIndicator from './InvoiceReferenceIndicator';
import LogisticRecordForm from '../../logistics/components/LogisticRecordForm';

const FulfillmentSection = ({ order, showFulfillments, setShowFulfillments }) => {
    const { t } = useTranslation();

    return (
        <Segment style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <div>
                    <strong>
                        {t('fulfillments')}
                        <Label style={{ marginLeft: '1rem' }} basic>
                            {order.total_fulfillments || 0}
                        </Label>
                    </strong>
                </div>
                <div>
                    <Icon
                        onClick={() => setShowFulfillments(!showFulfillments)}
                        name={showFulfillments ? 'chevron-up-outline' : 'chevron-down-outline'}
                        style={{ cursor: 'pointer', position: 'relative', top: '0.1rem' }}
                    />
                </div>
            </div>
            {showFulfillments && (
                <>
                    <Divider style={{ marginTop: '0.5rem' }} />
                    {order.resourcetype === 'TransportOrder' && (
                        <TransportFulfillments fulfillments={order.fulfillment ? [order.fulfillment] : []} />
                    )}
                    {order.resourcetype === 'SaleOrder' && (
                        <SalesFulfilllments fulfillments={order?.fulfillments || []} orderedItems={order.items} />
                    )}
                    {order.resourcetype === 'HROrder' && <HROrderFulfillments order={order} />}
                </>
            )}
        </Segment>
    );
};

const OrderDetail = ({ type, id, onClose }) => {
    const { t } = useTranslation();
    const dateFormat = useSelector((state) => state.date_format);

    const [order, setOrder] = useState(null);
    const [logisticRecords, setLogisticRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showTransportDetails, setShowTransportDetails] = useState(false);
    const [showFulfillments, setShowFulfillments] = useState(false);
    const [showOrderedItems, setShowOrderedItems] = useState(false);
    const [showLogisticRecords, setShowLogisticRecords] = useState(false);

    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        async function fetchOrder() {
            setLoading(true);
            const request = await requests.get(API.ORDERS + id + '/');
            if (request.status === 200) {
                setOrder(request.response);

                if (request.response.resourcetype === 'TransportOrder') setShowTransportDetails(true);
                if (request.response.resourcetype === 'SaleOrder') {
                    setShowOrderedItems(true);
                    let records = [];
                    for (let i = 0; i < request.response.items.length; i++) {
                        let logisticRecordsList = request.response.items[i].logistic_records;
                        for (let y = 0; y < logisticRecordsList.length; y++) {
                            if (records.find((item) => item.id === logisticRecordsList[y].id) === undefined) {
                                records.push(logisticRecordsList[y]);
                            }
                        }
                    }
                    setLogisticRecords(records);
                }
            }
            setLoading(false);
        }

        async function fetchEmployees() {
            const request = await requests.get(API.EMPLOYEES + '?only_basic_info=true&is_active=true');
            if (request.status === 200) {
                setEmployees(
                    request.response.map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: item.fullname_with_titles,
                    }))
                );
            }
        }

        fetchOrder();
        fetchEmployees();
    }, [id]);

    const determinateOrderType = (resourceType) => {
        if (resourceType === 'TransportOrder') return t('transport');
        if (resourceType === 'SaleOrder') return t('business');
        if (resourceType === 'ServiceOrder') return t('service');
        if (resourceType === 'HROrder') return t('recruitment');
        if (resourceType === 'EmploymentOrder') return t('employment');

        return '';
    };

    const InfoRow = ({ name, content }) => {
        return (
            <Table.Row>
                <Table.HeaderCell>{name}</Table.HeaderCell>
                <Table.HeaderCell style={{ fontWeight: 'normal' }}>{content}</Table.HeaderCell>
            </Table.Row>
        );
    };

    const handleDisplayAddress = (address) => {
        if (address?.country === '' || address?.city === '') return `${t('unspecified')} ${t('address')}`;
        let addressToRender = `${address?.street || ''}, ${address?.postcode || ''} ${address?.city || ''}, ${
            address?.country_display || ''
        }`;

        return addressToRender.trim();
    };

    const getItem = (logisticRecord) => {
        return order.items.find((item) => item.id === logisticRecord?.order_commodity);
    };

    const getCommodityItem = (logisticRecord) => {
        let commodity = order.items.find((item) => item.id === logisticRecord?.order_commodity);

        if (commodity) {
            return `${commodity.item.code ? commodity.item.code + ' - ' : ''} ${commodity.item.title}`;
        } else {
            return '--';
        }
    };

    const saleItemPricePreview = (items) => {
        if (items.length === 0) return 0;
        const currencies = [];
        for (const item of items) {
            if (!currencies.includes(item.currency)) {
                currencies.push(item.currency);
            }
        }

        let totals = [];
        for (let i = 0; i < currencies.length; i++) {
            // calculate total for currency
            let total = 0;
            let currency = currencies[i];
            for (let y = 0; y < items.length; y++) {
                if (items[y].currency === currency) {
                    total += items[y].price_without_tax * items[y].quantity;
                }
            }

            totals.push({
                currency: currency,
                sum: total,
            });
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {totals.map((total) => (
                    <span>
                        {' '}
                        {thousandsSeparators(parseFloat(total.sum).toFixed(2))} {total.currency}{' '}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading_order_details')}>
            {!loading && (
                <>
                    {/* Order Header - shared details */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                            {t('order_number')} {order.order_number} <br />
                            <small style={{ fontWeight: 'normal', opacity: '0.8' }}>
                                {t('created_by')} {order.created_by?.name || ''}, {t('at')}{' '}
                                {tzDateTime(order.created_on).format(dateFormat + ' HH:mm')}
                            </small>
                        </div>
                        <div>
                            {' '}
                            {/* -- Type/State --*/}
                            <Label basic>
                                <Icon name={icons.ORDERS} style={{ marginRight: '0.5rem' }} />
                                <span style={{ position: 'relative', top: '-0.1rem' }}>
                                    {determinateOrderType(order.resourcetype)}
                                </span>
                            </Label>
                            <Label color={order.is_active ? 'green' : 'red'} style={{ cursor: 'pointer' }}>
                                {order.is_active ? t('active_order') : t('closed_order')}
                                {/* <Icon name="chevron-down-sharp" style={{ marginLeft: "0.5rem", position: "relative", top: "0.1rem" }}/> */}
                            </Label>
                            {/* <Popup
                                style={{ marginTop: 0, padding: 0, background: "transparent" }}
                                basic
                                hoverable
                                position="bottom right"
                                trigger={
                                    <Label color={ order.is_active ? "green" : "red" } style={{ cursor: "pointer" }}>
                                        { order.is_active ? t('active_order') : t('closed_order') }
                                        <Icon name="chevron-down-sharp" style={{ marginLeft: "0.5rem", position: "relative", top: "0.1rem" }}/>
                                    </Label>
                                }
                                content={
                                    <Menu vertical size="tiny" style={{ width: "auto" }}>
                                        <Menu.Item
                                            name='active'
                                            active={true}
                                            onClick={() => alert("Activated")}
                                        >
                                            <Icon 
                                                name="ellipse"
                                                style={{ 
                                                    color: "green",
                                                    marginRight: "0.5rem"
                                                }}
                                            /> 
                                            <span style={{ 
                                                position: "relative",
                                                top: "-0.1rem",
                                                color: "var(--dark)",
                                                fontWeight: "bold"
                                            }}>
                                                { t('active_order') }
                                            </span>
                                        </Menu.Item>

                                        <Menu.Item
                                            name='inactive'
                                            active={false}
                                            onClick={() => alert("Deactivated")}
                                            >
                                            <Icon 
                                                name="ellipse"
                                                style={{ 
                                                    color: "red",
                                                    marginRight: "0.5rem"
                                                }}
                                            /> 
                                            <span style={{ 
                                                position: "relative",
                                                top: "-0.1rem",
                                                color: "var(--dark)",
                                                fontWeight: "bold"
                                            }}>
                                                { t('closed_order') }
                                            </span>
                                        </Menu.Item>
                                    </Menu>
                                }
                            /> */}
                        </div>
                    </div>
                    <Divider />

                    <Grid stackable style={{ padding: 0 }}>
                        <Grid.Row columns="2" style={{ padding: 0 }}>
                            <Grid.Column style={{ padding: 0 }}>
                                <Table style={{ border: 'none' }}>
                                    <InfoRow name={t('order_name')} content={order.name} />
                                    <InfoRow name={t('internal_order_number')} content={order.original_order_number} />
                                    <InfoRow
                                        name={t('contract_reference')}
                                        content={order.business_contract_reference || '--'}
                                    />
                                    <InfoRow
                                        name={t('account')}
                                        content={
                                            <>
                                                <Link
                                                    target="_blank"
                                                    to={
                                                        (order.is_purchase_order
                                                            ? routes.UNIT_DETAIL
                                                            : routes.ACCOUNTS) +
                                                        (order.is_purchase_order
                                                            ? order.owner.id
                                                            : order.business_detail?.id)
                                                    }
                                                >
                                                    {order.is_purchase_order
                                                        ? order.owner.business_detail.name
                                                        : order.business_detail?.name}
                                                </Link>
                                            </>
                                        }
                                    />
                                    <InfoRow
                                        name={t('supplier')}
                                        content={
                                            <>
                                                <Link
                                                    target="_blank"
                                                    to={
                                                        (order.is_purchase_order
                                                            ? routes.SUPPLIERS
                                                            : routes.UNIT_DETAIL) +
                                                        (order.is_purchase_order
                                                            ? order.business_detail.id
                                                            : order.owner?.id)
                                                    }
                                                >
                                                    {order.is_purchase_order
                                                        ? order.business_detail.name
                                                        : order.owner.business_detail?.name}
                                                </Link>
                                            </>
                                        }
                                    />
                                    <InfoRow
                                        name={t('order_manager')}
                                        content={
                                            order.order_manager?.id ? (
                                                <Link
                                                    target="_blank"
                                                    to={routes.EMPLYOEES_DETAIL + order?.order_manager?.id}
                                                >
                                                    {order.order_manager?.fullname}
                                                </Link>
                                            ) : (
                                                '--'
                                            )
                                        }
                                    />
                                </Table>
                                <strong> {t('note')}: </strong>
                                <p>{order.note}</p>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }}>
                                <Table style={{ border: 'none' }}>
                                    {order.resourcetype === 'HROrder' && (
                                        <InfoRow
                                            name={t('target')}
                                            content={`${order.fulfillment_stats?.already_fulfilled || 0} / ${
                                                order.amount
                                            } (${
                                                order.fulfillment_stats?.already_fulfilled > 0
                                                    ? parseFloat(
                                                          (order.fulfillment_stats?.already_fulfilled / order.amount) *
                                                              100
                                                      )
                                                    : 0
                                            }%)`}
                                        />
                                    )}
                                    {order.resourcetype === 'HROrder' && (
                                        <InfoRow
                                            name={t('fee')}
                                            content={`${thousandsSeparators(order?.fee?.sum || 0)} ${
                                                order?.fee?.currency
                                            }`}
                                        />
                                    )}
                                    {order.resourcetype === 'TransportOrder' && (
                                        <InfoRow
                                            name={t('price_excluding_vat')}
                                            content={
                                                <strong>
                                                    {' '}
                                                    {thousandsSeparators(order.price?.sum || 0)} {order.price?.currency}{' '}
                                                </strong>
                                            }
                                        />
                                    )}
                                    {order.resourcetype === 'SaleOrder' && (
                                        <InfoRow
                                            name={t('price_excluding_vat')}
                                            content={<strong> {saleItemPricePreview(order.items)} </strong>}
                                        />
                                    )}
                                    {order.resourcetype === 'HROrder' && (
                                        <InfoRow
                                            name={t('expected_price_excluding_vat')}
                                            content={
                                                <>
                                                    {thousandsSeparators(
                                                        parseFloat((order?.fee?.sum || 0) * order.amount)?.toFixed(2) ||
                                                            0
                                                    ) +
                                                        ' ' +
                                                        order?.fee?.currency}
                                                </>
                                            }
                                        />
                                    )}
                                    {order.resourcetype === 'HROrder' && (
                                        <InfoRow
                                            name={t('fulfilled_price_excluding_vat')}
                                            content={
                                                <strong>
                                                    {thousandsSeparators(
                                                        parseFloat(
                                                            (order?.fee?.sum || 0) *
                                                                (order.fulfillment_stats?.already_fulfilled || 0)
                                                        )?.toFixed(2) || 0
                                                    ) +
                                                        ' ' +
                                                        order?.fee?.currency}
                                                </strong>
                                            }
                                        />
                                    )}

                                    <InfoRow
                                        name={t('state')}
                                        content={<strong> {order.order_status_display} </strong>}
                                    />
                                    <InfoRow
                                        name={t('confirmed_by')}
                                        content={
                                            order.confirmed_by?.id ? (
                                                <Link
                                                    target="_blank"
                                                    to={routes.EMPLYOEES_DETAIL + order?.confirmed_by?.id}
                                                >
                                                    {order.confirmed_by?.name}
                                                </Link>
                                            ) : (
                                                '--'
                                            )
                                        }
                                    />
                                    <InfoRow
                                        name={t('confirmation_date')}
                                        content={
                                            order.confirmed_on ? moment(order.confirmed_on).format(dateFormat) : '--'
                                        }
                                    />
                                </Table>

                                {order.resourcetype === 'TransportOrder' && (
                                    <>
                                        <strong>
                                            {' '}
                                            {t('description')} / {t('note')}{' '}
                                        </strong>
                                        <p>{order.description}</p>
                                    </>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {order.resourcetype === 'TransportOrder' && (
                        <Segment style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <strong>
                                        {t('transport_information')}{' '}
                                        <Label style={{ marginLeft: '1rem', opacity: 0 }} basic>
                                            {' '}
                                            10{' '}
                                        </Label>{' '}
                                    </strong>
                                </div>
                                <div>
                                    <Icon
                                        onClick={() => setShowTransportDetails(!showTransportDetails)}
                                        name={showTransportDetails ? 'chevron-up-outline' : 'chevron-down-outline'}
                                        style={{
                                            cursor: 'pointer',
                                            position: 'relative',
                                            top: '0.1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            {showTransportDetails && (
                                <>
                                    <Divider style={{ marginTop: '0.5rem' }} />
                                    <Grid stackable style={{ padding: 0 }}>
                                        <Grid.Row style={{ padding: 0 }}>
                                            <Grid.Column style={{ padding: 0 }} width="9">
                                                <Table style={{ border: 'none' }}>
                                                    <InfoRow
                                                        name={t('loading_address')}
                                                        content={handleDisplayAddress(order?.loading_address)}
                                                    />
                                                    <InfoRow
                                                        name={t('delivery_address')}
                                                        content={handleDisplayAddress(order?.destination_address)}
                                                    />
                                                    <InfoRow name={t('distance')} content={order?.distance || '--'} />
                                                    <InfoRow
                                                        name={t('loading_date')}
                                                        content={
                                                            order.loading_date
                                                                ? moment(order.loading_date).format(dateFormat)
                                                                : '--'
                                                        }
                                                    />
                                                    {/* <InfoRow name={t('loading_time')} content={ order.loading_time ? moment(order.loading_time).format(dateFormat) : "--" }/> */}
                                                    <InfoRow
                                                        name={t('delivery_date')}
                                                        content={
                                                            order?.fulfillment?.delivery_date
                                                                ? moment(order?.fulfillment?.delivery_date).format(
                                                                      dateFormat
                                                                  )
                                                                : '--'
                                                        }
                                                    />
                                                    <InfoRow
                                                        name={t('note')}
                                                        content={
                                                            order?.fulfillment?.note ? order?.fulfillment?.note : ''
                                                        }
                                                    />
                                                </Table>
                                            </Grid.Column>
                                            <Grid.Column style={{ padding: 0 }} width="7">
                                                <Table style={{ border: 'none' }}>
                                                    <InfoRow
                                                        name={t('contact_person')}
                                                        content={order?.contact_person_name || '--'}
                                                    />
                                                    <InfoRow
                                                        name={t('email')}
                                                        content={order?.contact_person_email || '--'}
                                                    />
                                                    <InfoRow
                                                        name={t('phone')}
                                                        content={order?.contact_person_phone || '--'}
                                                    />
                                                    <InfoRow
                                                        name={t('truck')}
                                                        content={
                                                            order.truck?.car_plate_number ||
                                                            order.external_truck ||
                                                            '--'
                                                        }
                                                    />
                                                    <InfoRow
                                                        name={t('trailer')}
                                                        content={
                                                            order.trailer?.car_plate_number ||
                                                            order.external_trailer ||
                                                            '--'
                                                        }
                                                    />
                                                    <InfoRow
                                                        name={t('driver')}
                                                        content={
                                                            order?.driver?.fullname ||
                                                            order?.external_carrier?.name ||
                                                            '--'
                                                        }
                                                    />
                                                </Table>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </>
                            )}
                        </Segment>
                    )}

                    {order.resourcetype === 'SaleOrder' && (
                        <Segment style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <strong>
                                        {t('ordered_items')}{' '}
                                        <Label style={{ marginLeft: '1rem' }} basic>
                                            {' '}
                                            {order?.items?.length || 0}{' '}
                                        </Label>{' '}
                                    </strong>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Icon
                                        onClick={() => setShowOrderedItems(!showOrderedItems)}
                                        name={showOrderedItems ? 'chevron-up-outline' : 'chevron-down-outline'}
                                        style={{
                                            cursor: 'pointer',
                                            position: 'relative',
                                            top: '0.1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            {showOrderedItems && (
                                <>
                                    <Divider style={{ marginTop: '0.5rem' }} />
                                    <Table>
                                        <Table.Header>
                                            <Table.HeaderCell singleLine>{t('item')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('unit_price')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('quantity')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('price_without_tax')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('note')}</Table.HeaderCell>
                                        </Table.Header>
                                        <Table.Body>
                                            {order.items.map((item) => (
                                                <Table.Row>
                                                    <Table.Cell singleLine>
                                                        {item.item.code ? item.item.code + ' - ' : ''} {item.item.title}
                                                    </Table.Cell>
                                                    <Table.Cell singleLine>
                                                        {thousandsSeparators(item.price_without_tax || 0)}{' '}
                                                        {item.currency}
                                                    </Table.Cell>
                                                    <Table.Cell singleLine>
                                                        {item.quantity}
                                                        {item.item.weight_is_primary_measurement
                                                            ? ` ${item?.item?.measure_unit?.abbreviation || ''}`
                                                            : ''}
                                                    </Table.Cell>
                                                    <Table.Cell singleLine>
                                                        {thousandsSeparators(
                                                            parseFloat(item.price_without_tax * item.quantity)?.toFixed(
                                                                2
                                                            ) || 0
                                                        )}{' '}
                                                        {item.currency}
                                                    </Table.Cell>
                                                    <Table.Cell singleLine>
                                                        {item.note && (
                                                            <Popup
                                                                position="top center"
                                                                trigger={
                                                                    <Icon
                                                                        name="chatbox-ellipses-outline"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            position: 'relative',
                                                                            top: '0.1rem',
                                                                        }}
                                                                    />
                                                                }
                                                                content={item.note}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </>
                            )}
                        </Segment>
                    )}

                    <FulfillmentSection
                        order={order}
                        showFulfillments={showFulfillments}
                        setShowFulfillments={setShowFulfillments}
                    />

                    {order.resourcetype === 'SaleOrder' && (
                        <Segment style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <strong>
                                        {t('logistic_records')}{' '}
                                        <Label style={{ marginLeft: '1rem' }} basic>
                                            {' '}
                                            {logisticRecords?.length || 0}{' '}
                                        </Label>{' '}
                                    </strong>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '1rem' }} title={t('add_logistic_records')}>
                                        <SuperDuperModal
                                            header={t('add_logistic_records')}
                                            size="small"
                                            trigger={
                                                <Icon
                                                    name="add-outline"
                                                    style={{ cursor: 'pointer', position: 'relative', top: '0rem' }}
                                                />
                                            }
                                            content={
                                                <LogisticRecordForm
                                                    initialOrder={order}
                                                    employees={employees}
                                                    type={order.is_purchase_order ? 1 : 2}
                                                    setLogisticRecords={setLogisticRecords}
                                                />
                                            }
                                        />
                                    </div>
                                    <Icon
                                        onClick={() => setShowLogisticRecords(!showLogisticRecords)}
                                        name={showLogisticRecords ? 'chevron-up-outline' : 'chevron-down-outline'}
                                        style={{
                                            cursor: 'pointer',
                                            position: 'relative',
                                            top: '0.1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            {showLogisticRecords && (
                                <>
                                    <Divider style={{ marginTop: '0.5rem' }} />
                                    <Table>
                                        <Table.Header>
                                            <Table.HeaderCell singleLine>{t('reference')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('transport')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('commodity')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('quantity')}</Table.HeaderCell>
                                            {/* <Table.HeaderCell singleLine>{t('total_weight')}</Table.HeaderCell> */}
                                            <Table.HeaderCell singleLine>{t('price_without_tax')}</Table.HeaderCell>
                                            <Table.HeaderCell singleLine>
                                                {t('expected_real_delivery_date')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell singleLine>{t('status')}</Table.HeaderCell>
                                        </Table.Header>
                                        <Table.Body>
                                            {logisticRecords.map((item) => (
                                                <Table.Row>
                                                    <Table.Cell singleLine>{item.reference_number}</Table.Cell>
                                                    <Table.Cell singleLine>{item.type_of_transport?.title}</Table.Cell>
                                                    <Table.Cell singleLine>{getCommodityItem(item)}</Table.Cell>
                                                    <Table.Cell singleLine>
                                                        {item.quantity}
                                                        {getItem(item)?.weight_is_primary_measurement
                                                            ? ' ' + item?.measure_unit?.abbreviation
                                                            : ''}
                                                    </Table.Cell>
                                                    {/* <Table.Cell singleLine>{ item.weight_total || "--" } { item?.measure_unit?.abbreviation || "" }</Table.Cell> */}
                                                    <Table.Cell singleLine>
                                                        {thousandsSeparators(item.price_paid || 0)} {item.currency}
                                                    </Table.Cell>
                                                    <Table.Cell singleLine>
                                                        {moment(item.estimated_delivery_date).format(dateFormat)} /{' '}
                                                        {item?.delivery_date
                                                            ? moment(item?.delivery_date).format(dateFormat)
                                                            : '--'}
                                                    </Table.Cell>
                                                    <Table.Cell singleLine>{item.status_display}</Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </>
                            )}
                        </Segment>
                    )}
                </>
            )}
        </SpinnerSegment>
    );
};

const SalesFulfilllments = ({ fulfillments, orderedItems }) => {
    const { t } = useTranslation();
    const dateFormat = useSelector((state) => state.date_format);

    const getItemTitle = (fulfillment) => {
        let title = '';
        let orderedItem = orderedItems.find((item) => item.id === fulfillment.order_item);

        if (orderedItem) {
            title = `${orderedItem.item.code && orderedItem.item.code + ' - '}${orderedItem.item.title}`;
        }

        return title;
    };

    return (
        <Table style={{ fontSize: '0.9rem' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t('delivery_date')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('item')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('amount')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('price_without_tax')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('created')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('billing')}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {fulfillments.length === 0 && (
                    <Table.Row>
                        <Table.Cell colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {t('no_data')}
                        </Table.Cell>
                    </Table.Row>
                )}
                {fulfillments.map((fulfillment) => (
                    <Table.Row key={fulfillment.id}>
                        <Table.Cell>{moment(fulfillment.delivery_date).format(dateFormat)}</Table.Cell>
                        <Table.Cell>{getItemTitle(fulfillment)}</Table.Cell>
                        <Table.Cell>{fulfillment.quantity}</Table.Cell>
                        <Table.Cell>
                            {fulfillment.total_fee} {fulfillment.currency}
                        </Table.Cell>
                        <Table.Cell>
                            {tzDateTime(fulfillment.created_on).format(dateFormat + ' HH:mm')} <br />
                            <small> {fulfillment.created_by?.name} </small>
                        </Table.Cell>
                        <Table.Cell>
                            <InvoiceReferenceIndicator fulfillment={fulfillment} />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

const TransportFulfillments = ({ fulfillments }) => {
    const { t } = useTranslation();
    const dateFormat = useSelector((state) => state.date_format);

    return (
        <Table style={{ fontSize: '0.9rem' }}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t('delivery_date')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('price_without_tax')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('note')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('created')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('billing')}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {fulfillments.length === 0 && (
                    <Table.Row>
                        <Table.Cell colSpan="5" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {t('no_data')}
                        </Table.Cell>
                    </Table.Row>
                )}
                {fulfillments.map((fulfillment) => (
                    <Table.Row key={fulfillment.id}>
                        <Table.Cell>{moment(fulfillment.delivery_date).format(dateFormat)}</Table.Cell>
                        <Table.Cell>
                            {fulfillment.transportorder_price} {fulfillment.transportorder_price_currency}
                        </Table.Cell>
                        <Table.Cell>{fulfillment.note}</Table.Cell>
                        <Table.Cell>
                            {tzDateTime(fulfillment.created_on).format(dateFormat + ' HH:mm')} <br />
                            <small> {fulfillment.created_by?.name} </small>
                        </Table.Cell>
                        <Table.Cell>
                            <InvoiceReferenceIndicator fulfillment={fulfillment} />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

const HROrderFulfillments = ({ order }) => {
    const { t } = useTranslation();
    const dateFormat = useSelector((state) => state.date_format);

    const [loading, setLoading] = useState(true);
    const [fulfillments, setFulfillments] = useState([]);

    useEffect(() => {
        async function fetchFullfillments() {
            setLoading(true);
            const request = await requests.get(API.ORDERS + 'fulfillments/?order=' + order.id);
            if (request.status === 200) setFulfillments(request.response);

            setLoading(false);
        }

        fetchFullfillments();
    }, [order.id]);

    return (
        <SpinnerSegment loading={loading}>
            {!loading && (
                <Table style={{ fontSize: '0.9rem' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('delivery_date')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('candidate')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('price_without_tax')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('note')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('created')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('billing')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {fulfillments.length === 0 && (
                            <Table.Row>
                                <Table.Cell colSpan="6" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {t('no_data')}
                                </Table.Cell>
                            </Table.Row>
                        )}
                        {fulfillments.map((fulfillment) => (
                            <Table.Row key={fulfillment.id}>
                                <Table.Cell>{moment(fulfillment.date).format(dateFormat)}</Table.Cell>
                                <Table.Cell>{fulfillment.profile.fullname} </Table.Cell>
                                <Table.Cell>
                                    {fulfillment.total_fee} {fulfillment.currency}
                                </Table.Cell>
                                <Table.Cell>{fulfillment.note}</Table.Cell>
                                <Table.Cell>
                                    {tzDateTime(fulfillment.created_on).format(dateFormat + ' HH:mm')} <br />
                                    <small> {fulfillment.created_by?.name} </small>
                                </Table.Cell>
                                <Table.Cell>--</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </SpinnerSegment>
    );
};

export default OrderDetail;
