import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperField from '@components/forms/SuperField'

const EstimatedTimeForm = ({ value, onChange }) => {
    const { t } = useTranslation()
    const checking_array = [1, 2, 4, 8]
    const [picked, setPicked] = useState(checkValuesForPicked())
    const [custom, setCustom] = useState(checkValuesFoCustom())

    useEffect(() => {
        if (picked && picked !== 0) {
            onChange(+picked)
        } else if (custom) {
            onChange(+custom)
        }
        //eslint-disable-next-line
    }, [picked, custom])

    function checkValuesForPicked() {
        if (checking_array.find(item => item === value)) return value
        return null
    }
    function checkValuesFoCustom() {
        if (!checking_array.find(item => item === value) && value) return value
        return 0
    }

    return (
        <FlexRow padding="0" background="transparent">
            <FlexItem>{t('set_estimated_time')}</FlexItem>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div onClick={() => setPicked(picked !== 1 ? 1 : null)} style={picked === 1 ? styles.picked : styles.box}>1h</div>
                <div onClick={() => setPicked(picked !== 2 ? 2 : null)} style={picked === 2 ? styles.picked : styles.box}>2h</div>
                <div onClick={() => setPicked(picked !== 4 ? 4 : null)} style={picked === 4 ? styles.picked : styles.box}>4h</div>
                <div onClick={() => setPicked(picked !== 8 ? 8 : null)} style={picked === 8 ? styles.picked : styles.box}>8h</div>
                <div className="estimated_time_form" onClick={() => setPicked(picked !== 0 ? 0 : null)} style={picked === 0 ? styles.input_picked : styles.input_box}>
                    <SuperField
                        as="input"
                        type="number"
                        min="0"
                        style={{ width: '5rem', margin: 0 }}
                        value={custom}
                        onChange={(e, { value }) => setCustom(value)} />
                    <span style={{ marginLeft: '0.2rem' }}> h</span>
                </div>
            </div>
        </FlexRow>
    );
};
const styles = {
    box: {
        background: "var(--variant4)",
        color: 'var(--white)',
        padding: '1rem',
        margin: '0 0.5rem',
        cursor: 'pointer',
        widht: '2rem'
    },
    picked: {
        background: "var(--primary)",
        fontWeight: 'bold',
        color: 'var(--white)',
        padding: '1rem',
        margin: '0 0.5rem',
        cursor: 'pointer',
        widht: '2rem'
    },
    input_box: {
        background: "var(--variant4)",
        color: 'var(--white)',
        padding: '0.3rem',
        marginLeft: '0.5rem',
        cursor: 'pointer',
        widht: '2rem',
        display: 'flex',
        alignItems: 'center',
    },
    input_picked: {
        background: "var(--primary)",
        fontWeight: 'bold',
        color: 'var(--white)',
        padding: '0.3rem',
        marginLeft: '0.5rem',
        cursor: 'pointer',
        widht: '2rem',
        display: 'flex',
        alignItems: 'center',
    },

}

export default EstimatedTimeForm;