import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ChangeDeadlineForm = ({ onboarding, setBoardings, onClose}) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false);
    const [deadline, setDeadline] = useState(onboarding?.deadline || moment().format("YYYY-MM-DD"))

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await requests.patch(API.ONBOARDING_CHECKLIST + onboarding.id + "/", {
            deadline: deadline
        })
        
        if(request.status === 200){
            setBoardings(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    results: prev.data.results.filter(item => {
                        if( item.id === onboarding.id ){
                            item.deadline = request.response.deadline
                        }
    
                        return item
                    })
                }
            }))
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="datepicker"
                required
                label={t('deadline')}
                value={deadline}
                onChange={(e, { value }) => setDeadline(value)}
            />
            <Divider/>
            <Form.Field style={{ textAlign: "right"}}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    disabled={isProcessing}
                    loading={isProcessing}
                />
            </Form.Field>

        </Form>
    );
};

export default ChangeDeadlineForm;