import React from 'react'
import { Label } from "semantic-ui-react"

export function toOptionsFromRedux(input) {
    let output = []
    let result = Object.entries(input)
    for (let item of result) {
        output.push({ key: item[0], value: parseInt(item[0]), text: item[1] })
    }
    return output
}
export function toOptionsLabels(input) {
    let output = []
    for (let item of input) {
        output.push({ key: item.id, value: item.id, text: item.name, label: <Label size="tiny" circular style={{ backgroundColor: item.color }} /> })
    }
    return output

}
// sort array of objects that have "date_joined" property, it sorts date and time at once
// example : array = [
//     {date_joined:'2021-10-10 10:10:10'},
//     {date_joined:'2021-11-11 11:11:11'},
//     {date_joined:'2021-12-12 12:12:12'},
// ]
export function sortDateTimeFromTheOldest(input) {
    let array = input
    array.sort((a, b) => {
        let first = a.date_joined
        let second = b.date_joined
        let first_date_time = first.split(' ')
        let second_date_time = second.split(' ')
        let first_date = first_date_time[0].split('-')
        let first_time = first_date_time[1].split(':')
        let second_date = second_date_time[0].split('-')
        let second_time = second_date_time[1].split(':')
        if (first_date[0] > second_date[0]) return 1
        if (first_date[0] < second_date[0]) return -1
        if (first_date[0] === second_date[0]) {
            if (first_date[1] > second_date[1]) return 1
            if (first_date[1] < second_date[1]) return -1
            if (first_date[1] === second_date[1]) {
                if (first_date[2] > second_date[2]) return 1
                if (first_date[2] < second_date[2]) return -1
                if (first_date[2] === second_date[2]) {
                    if (first_time[0] > second_time[0]) return 1
                    if (first_time[0] < second_time[0]) return -1
                    if (first_time[0] === second_time[0]) {
                        if (first_time[1] > second_time[1]) return 1
                        if (first_time[1] < second_time[1]) return -1
                        if (first_time[1] === second_time[1]) {
                            if (first_time[2] > second_time[2]) return 1
                            if (first_time[2] < second_time[2]) return -1
                            if (first_time[2] === second_time[2]) return 0
                        }
                    }
                }
            }
        }
        return 0
    })
    return array
}
export function sortDateTimeFromTheNewest(input) {
    let array = input
    array.sort((a, b) => {
        let first = b.date_joined
        let second = a.date_joined
        let first_date_time = first.split(' ')
        let second_date_time = second.split(' ')
        let first_date = first_date_time[0].split('-')
        let first_time = first_date_time[1].split(':')
        let second_date = second_date_time[0].split('-')
        let second_time = second_date_time[1].split(':')
        if (first_date[0] > second_date[0]) return 1
        if (first_date[0] < second_date[0]) return -1
        if (first_date[0] === second_date[0]) {
            if (first_date[1] > second_date[1]) return 1
            if (first_date[1] < second_date[1]) return -1
            if (first_date[1] === second_date[1]) {
                if (first_date[2] > second_date[2]) return 1
                if (first_date[2] < second_date[2]) return -1
                if (first_date[2] === second_date[2]) {
                    if (first_time[0] > second_time[0]) return 1
                    if (first_time[0] < second_time[0]) return -1
                    if (first_time[0] === second_time[0]) {
                        if (first_time[1] > second_time[1]) return 1
                        if (first_time[1] < second_time[1]) return -1
                        if (first_time[1] === second_time[1]) {
                            if (first_time[2] > second_time[2]) return 1
                            if (first_time[2] < second_time[2]) return -1
                            if (first_time[2] === second_time[2]) return 0
                        }
                    }
                }
            }
        }
        return 0
    })
    return array
}

export function roundingTime(value) {
    let time = parseFloat(value).toFixed(2)
    let decimal = parseInt(time.split('.')[1])
    let number = parseInt(time.split('.')[0])
    let result = 0

    if( decimal < 25 && decimal > 0 ) result = number
    if( number > 0 && decimal === 0 ) result = number
    if( decimal >= 25 && decimal <= 50 ) result = parseFloat(number + ".50")
    if( decimal > 50 && decimal < 75 ) result = parseFloat(number + ".50")
    if( decimal >= 75 && decimal <= 99 ) result = parseFloat(number + 1)
    return result
}
