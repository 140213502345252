import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as XLSX from "xlsx";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import { Grid, Divider, Container, Segment, Form, Button, Card } from 'semantic-ui-react';
import { FlexHeader, FlexTable, FlexRow, FlexItem } from '@components/tables/FlexTable';

const Transactions = ({ bank_account, connection }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [dateFrom, setDateFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    // eslint-disable-next-line
    const [dateTo, setDateTo] = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const [rangeType, setRangeType] = useState(1)
    const [loading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState([])

    const fetchTransactions = async () => {
        setLoading(true)
        let dateRangeParams = ""
        if (dateFrom) dateRangeParams += "&date_from=" + dateFrom
        if (dateTo) dateRangeParams += "&date_to=" + dateTo
        
        const request = await requests.get(API.INVOICES + "banking/tb/premium/accounts/?connection=" + connection.connection_id + "&transactions_only=true&account=" + bank_account.accountId + dateRangeParams)
        
        if (request.status === 200) {
            setTransactions(request?.response?.transactions || [])
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchTransactions()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (rangeType === 1) {
            setDateFrom(moment().startOf('month').format('YYYY-MM-DD'))
            setDateTo(moment().endOf('month').format('YYYY-MM-DD'))
        }

        if (rangeType === 2) {
            setDateFrom(moment().startOf('week').format('YYYY-MM-DD'))
            setDateTo(moment().endOf('week').format('YYYY-MM-DD'))
        }

        if (rangeType === 3) {
            setDateFrom(moment().startOf('week').subtract(7,'days').format('YYYY-MM-DD'))
            setDateTo(moment().endOf('week').subtract(7, 'days').format('YYYY-MM-DD'))
        }
        // eslint-disable-next-line
    }, [rangeType])

    const onExportFile = () => {
        const ws = XLSX.utils.json_to_sheet(transactions);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, dateFrom + " - " + dateTo);


        XLSX.writeFile(wb, "transactions_" + dateFrom + "/" + dateTo +".xlsx");
    }

    const calculateTotals = () => {
        let totalIncome = 0
        let totalOutcome = 0

        for (let i = 0; i < transactions.length; i++) {
            if (transactions[i].transactionAmount.amount > 0) {
                totalIncome += parseFloat(transactions[i].transactionAmount.amount)
            } else {
                totalOutcome += parseFloat(transactions[i].transactionAmount.amount)
            }
        }

        return {
            income: thousandsSeparators(parseFloat(totalIncome).toFixed(2)), 
            outcome: thousandsSeparators(parseFloat(totalOutcome).toFixed(2))
        }
    }

    const totals = calculateTotals()

    return (
        <Container fluid>
            <Grid>
                <Grid.Row style={{ padding: 0 }} verticalAlign="middle">
                    <Grid.Column computer={10} mobile={16} tablet={16}>
                        <Form onSubmit={() => fetchTransactions()}>
                            <Form.Group style={{ marginBottom: 0 }}>
                                <SuperField as="choice"
                                    value={rangeType}
                                    onChange={ (e, { value }) => setRangeType(value) }
                                    customOptions={[
                                        { key: 1, value: 1, text: t('current_month')},
                                        { key: 2, value: 2, text: t('current_week')},
                                        { key: 3, value: 3, text: t('last_week')},
                                        { key: 4, value: 4, text: t('custom_range')}
                                    ]}
                                />
                                { rangeType === 4 && 
                                    <>
                                        <SuperField as="datepicker"
                                            value={dateFrom}
                                            onChange={(e, { value }) => setDateFrom(value)}
                                            placeholder={t('date_from')}
                                        />
                                        <SuperField as="datepicker"
                                            value={dateTo}
                                            onChange={(e, { value }) => setDateTo(value)}
                                            placeholder={t('date_to')}
                                        />
                                    </>
                                }
                                <Button primary content={t('confirm')}/>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column textAlign="right" computer={6} mobile={16} tablet={16}>
                        <p style={{ fontSize: "1.2rem" }}>
                            <strong>{t('date')}: </strong> { moment(dateFrom).format("DD.MM.YYYY") } - { moment(dateTo).format("DD.MM.YYYY") } 
                            <Icon onClick={onExportFile} disabled={loading} name="download-outline" style={{ marginLeft: "2rem", marginRight: "1rem", cursor: "pointer" }}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <Segment 
                loading={loading}
                style={{ 
                    padding: 0, 
                    background: "transparent", 
                    boxShadow: "none", 
                    border: "none",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                }}
            >
                { loading && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_loading_transactions') } </p> }

                {/* Stats */}
                { !loading && 
                    <>
                        <Card.Group centered verticalAlign="middle">
                            <Card fluid style={{ borderRadius: 0 }}>
                                <Card.Content>
                                    <Grid columns={2} relaxed='very'>
                                        <Grid.Row>
                                            <Grid.Column textAlign="center">
                                                <span style={{ fontSize: "1.5rem", color: "var(--success)" }}>{ totals.income } { bank_account?.accountReference?.currency || "" }</span>
                                                <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{ t('income') }</div>
                                            </Grid.Column>
                                            <Grid.Column textAlign="center">
                                                <span style={{ fontSize: "1.5rem", color: "var(--danger)" }}>{ totals.outcome } { bank_account?.accountReference?.currency || "" }</span>
                                                <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{ t('outcome') }</div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Divider vertical>
                                        <Icon name="swap-horizontal-outline" style={{ fontSize: "2rem", marginTop: "-0.9rem" }}/>
                                    </Divider>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                        <Divider/>
                    </>
                }
                
                { !loading && 
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader basis="50%" content={t('date')}/>
                            <FlexHeader content={t('sender')}/>
                            <FlexHeader content={t('receiver')}/>
                            <FlexHeader content={t('reference')}/>
                            <FlexHeader basis="60%" content={t('sum') + " - " + bank_account?.accountReference?.currency || "" }/>
                            <FlexHeader content={t('note')}/>
                        </FlexRow>
                        <EmptyRow length={transactions.length}/>
                        { transactions?.map(transaction => ( 
                            <FlexRow background="transparent" fontSize="0.9rem" borders>
                                <FlexItem basis="50%">
                                    <div style={{ fontSize: "1.2rem" }}> { moment(transaction.bookingDate).format("DD.MM.YYYY") } </div>
                                </FlexItem>
                                <FlexItem>
                                    <strong>{ transaction?.debtorAccount?.iban || "--" }</strong> <br/>
                                    { transaction.debtorName }
                                </FlexItem>
                                <FlexItem>
                                    <strong>{ transaction?.creditorAccount?.iban && <>{ transaction?.creditorAccount?.iban }<br/></>}</strong>
                                    { transaction.creditorName || transaction.tradingPartyIdentification }
                                </FlexItem>
                                <FlexItem>
                                    { transaction.endToEndId }
                                </FlexItem>

                                <FlexItem bold fontSize="1.5rem" basis="60%" >
                                    <span style={{ color: transaction.transactionAmount.amount > 0 ? "var(--success)" : "var(--danger)", fontSize: "1.2rem" }}>
                                        { thousandsSeparators(transaction.transactionAmount.amount) }
                                    </span>
                                </FlexItem>

                                <FlexItem>
                                    { transaction.additionalInformation }
                                </FlexItem>
                            </FlexRow>
                        ))} 
                    </FlexTable>
                }
                
                
            </Segment>
        </Container>
    );
};

export default Transactions;