import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { isEmptyErrorList, isPhoneError } from '@helpers/validation';
// components
import { Icon, Step } from 'semantic-ui-react';

const MultiStepWizzard = ({ setCurrentView, person, isExternal, birthInfo, permanentAddress, isProcessing }) => {
    const { t } = useTranslation();

    const [isPersonFinished, setIsPersonFinished] = useState(false);
    const [personCounter, setPersonCounter] = useState(0);
    const [isBirthInfoFinished, setIsBirthInfoFinished] = useState(false);
    const [birthInfoCounter, setBirthInfoCounter] = useState(0);

    const [isAddressFinished, setIsAddressFinished] = useState(false);

    useEffect(() => {
        let personCount = 0;
        let birthInfoCount = 0;
        if (isEmptyErrorList([
            person.firstname,
            person.lastname,
            person.personalNumber,
            person.gender,
            person.maritalStatus,
            person.contact.phone,
            person.contact.phoneType,
            isExternal
        ]) && !isPhoneError(person.contact.phone)) {
            setIsPersonFinished(true);
        }

        if (person.firstname) personCount++;
        if (person.lastname) personCount++;
        if (person.personalNumber) personCount++;
        if (person.gender) personCount++;
        if (person.maritalStatus) personCount++;
        if (person.contact.phone && !isPhoneError(person.contact.phone) && person.contact.phoneType) personCount++;
        if (isExternal) personCount++;

        setPersonCounter(personCount);

        if (isEmptyErrorList([
            birthInfo.dateOfBirth,
            birthInfo.placeOfBirth,
            birthInfo.nationality,
            birthInfo.citizenship,
            birthInfo.countryOfBirth,
        ])) {
            setIsBirthInfoFinished(true);
        }

        if (birthInfo.dateOfBirth) birthInfoCount++;
        if (birthInfo.placeOfBirth) birthInfoCount++;
        if (birthInfo.nationality) birthInfoCount++;
        if (birthInfo.citizenship) birthInfoCount++;
        if (birthInfo.countryOfBirth) birthInfoCount++;

        setBirthInfoCounter(birthInfoCount);

        if (isEmptyErrorList([
            permanentAddress.street,
            permanentAddress.city,
            permanentAddress.postcode,
            permanentAddress.country,
        ])) {
            setIsAddressFinished(true);
        }
    }, [person, isExternal, birthInfo, permanentAddress])


    return (
        <Step.Group fluid vertical stackable="tablet">
            <Step
                onClick={() => setCurrentView(1)}
                completed={isPersonFinished}
                disabled={isProcessing}>
                <Icon name='user' />
                <Step.Content>
                    <Step.Title>{t("personal")} <small>({personCounter}/6)</small></Step.Title>
                    <Step.Description>{t("personal_text")}</Step.Description>
                </Step.Content>
            </Step>


            <Step
                onClick={() => setCurrentView(2)}
                completed={isBirthInfoFinished}
                disabled={!isPersonFinished || isProcessing}>
                <Icon name='warning' disabled={!isPersonFinished} />
                <Step.Content>
                    <Step.Title>{t("birth")} <small>({birthInfoCounter}/5)</small></Step.Title>
                    <Step.Description>{t("birth_text")}</Step.Description>
                </Step.Content>
            </Step>
            <Step
                onClick={() => setCurrentView(3)}
                completed={isAddressFinished}
                disabled={!isBirthInfoFinished || isProcessing}>
                <Icon name='warning' disabled={!isBirthInfoFinished} />
                <Step.Content>
                    <Step.Title>{t("address")}</Step.Title>
                    <Step.Description>{t("address_text")}</Step.Description>
                </Step.Content>
            </Step>
            <Step
                onClick={() => setCurrentView(4)}
                completed={isProcessing}
                disabled={!isAddressFinished || isProcessing}>
                <Icon name='warning' disabled={!isAddressFinished} />
                <Step.Content>
                    <Step.Title>{t("confirm")}</Step.Title>
                    <Step.Description>{t("confirm_text")}</Step.Description>
                </Step.Content>
            </Step>

        </Step.Group>
    );
};

export default MultiStepWizzard;