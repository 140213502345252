import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useSelectOptions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import Loading from '@components/general/Loading';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import UploadFile from '@components/UploadFile';
import { Container, Divider, Button, Segment } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import CoursesFilters from '../../../../pages/dashboard/hr/courses/components/CoursesFilters';

// module specific components

const CoursesSection = ({ data }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState([]);
    const [categoriesForSelection] = useSelectOptions(API.COURSES_CATEGORIES, 'title');

    const initState = {
        category: '',
        search: '',
        mandatory: false,
        selfImprovemet: false,
    };

    const [filters, setFilters] = useState(initState);

    const setParams = () => {
        let params = '';

        if (filters.search !== '') {
            params += '&search=' + filters.search;
        }
        if (filters.category !== '') {
            params += '&category=' + filters.category;
        }
        if (filters.mandatory && !filters.selfImprovemet) {
            params += '&is_mandatory=true';
        } else if (!filters.mandatory && filters.selfImprovemet) {
            params += '&is_mandatory=false';
        }
        return params;
    };

    const fetchData = async (params, isFiltered) => {
        setLoading(true);
        params = params || '';
        params += setParams();
        if (isFiltered) {
            setPage(1);
        } else {
            let searchParams = new URLSearchParams(location.search);
            const param = searchParams.get('page');
            setPage(param !== null ? param : 1);
        }
        if (!params.includes('page')) {
            params += '&page=' + page;
        }

        if (!params.includes('limit')) {
            params += '&limit=' + limit;
        }

        const request = await requests.get(API.COURSES_RECORDS + '?paginate=true&person=' + data?.id + params);

        if (request.status === 200) {
            setCourses({
                ...courses,
                data: request.response,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData('', true);
        // eslint-disable-next-line
    }, [filters]);

    const resolveExpiration = (dateFrom, dateTo) => {
        if (dateTo === null || dateTo === undefined || dateTo === '') {
            return t('no_expiration');
        }

        if (moment().isAfter(dateTo)) {
            return <span style={{ color: 'var(--danger)' }}>{t('expired')}</span>;
        }

        return moment(dateTo).endOf('day').fromNow();
    };

    return (
        <CanView permissions={['courses.c_view_all_courses', 'courses.c_view_user_courses']}>
            <Container fluid>
                <Segment
                    style={{
                        boxShadow: 'none',
                        border: 'none',
                        padding: 0,
                        background: 'transparent',
                    }}
                >
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <CoursesFilters
                                filter={filters}
                                setFilter={setFilters}
                                categories={categoriesForSelection.options}
                                fetchRecords={fetchData}
                            />
                            <div style={{ marginTop: '0.5rem' }} />
                            <FlexTable>
                                <FlexRow>
                                    <FlexHeader basis="130%" content={'Course'} />
                                    <FlexHeader content={t('made_on')} />
                                    <FlexHeader content={t('valid_from')} />
                                    <FlexHeader content={t('valid_until')} />
                                    <FlexHeader content={t('status')} />
                                    <FlexHeader content={t('document')} />
                                </FlexRow>
                                <EmptyRow length={courses?.data?.results?.length || 0} />
                                {courses?.data?.results?.map((record) => (
                                    <FlexRow borders key={record.id}>
                                        <FlexItem
                                            basis="130%"
                                            bold
                                            content={
                                                <>
                                                    {record?.course?.title} <br />
                                                    <small>
                                                        {resolveExpiration(record.valid_from, record.valid_until)}
                                                    </small>
                                                </>
                                            }
                                        />
                                        <FlexItem content={record.made_on} />
                                        <FlexItem content={record.valid_from} />
                                        <FlexItem content={record.valid_until} />
                                        <FlexItem
                                            bold
                                            content={
                                                !record.failed ? (
                                                    <span style={{ color: 'var(--success)' }}>{t('completed')}</span>
                                                ) : (
                                                    <span style={{ color: 'var(--danger)' }}>{t('failed')}</span>
                                                )
                                            }
                                        />
                                        <FlexItem>
                                            {record?.attachment ? (
                                                // eslint-disable-next-line
                                                <a href={record?.attachment} target="_blank" rel="noopener noreferrer">
                                                    <Icon
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: 'var(--danger)',
                                                            fontSize: '2rem',
                                                        }}
                                                        name="document-outline"
                                                    />
                                                </a>
                                            ) : (
                                                <CanView permissions={['courses.c_manage_all_courses']}>
                                                    <SuperDuperModal
                                                        trigger={
                                                            <Button size="tiny" basic content={t('add_document')} />
                                                        }
                                                        content={
                                                            <UploadFile
                                                                field="attachment"
                                                                endpoint={
                                                                    API.COURSES_RECORDS + record.id + '/attachment/'
                                                                }
                                                                callback={(response) =>
                                                                    setCourses((prev) => ({
                                                                        ...prev,
                                                                        data: {
                                                                            ...prev.data,
                                                                            results: prev.data.results.filter(
                                                                                (item) => {
                                                                                    if (item.id === record.id) {
                                                                                        item.attachment =
                                                                                            response.attachment;
                                                                                    }

                                                                                    return item;
                                                                                }
                                                                            ),
                                                                        },
                                                                    }))
                                                                }
                                                            />
                                                        }
                                                    />
                                                </CanView>
                                            )}
                                        </FlexItem>
                                    </FlexRow>
                                ))}
                            </FlexTable>
                            <Paginator
                                forcePage={page || 1}
                                forceReload={false}
                                limit={limit}
                                setLimit={setLimit}
                                length={courses?.data?.count || 0}
                                onChange={(params) => fetchData(params)}
                            />
                        </>
                    )}
                </Segment>
            </Container>
            <Divider />
        </CanView>
    );
};

export default CoursesSection;
