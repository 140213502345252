import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useSelectOptions } from '@helpers/hooks';
// components
import { Form, Divider, Message } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';
// components specific
import TransferItems from './TransferItems';

const WarehouseTransferCardForm = ({ onClose, setData, setTotal }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [warehouses] = useSelectOptions(API.ASSETS + "warehouses/", "title")
    const [form, setForm] = useState({
        warehouse_from: "",
        warehouse_to: "",
        transfered_items: [],
        responsible_person: "",
        note: "",
    })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)
        
        const request = await requests.post(API.ASSETS + "warehouse_transfer_cards/", {
            ...form,
            responsible_person: form.responsible_person !== "" ? form.responsible_person : null,
            transfered_items: { create: form.transfered_items }
        })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            setData(prev => [request.response, ...prev])
            onClose()
        }

        setProcessing(false)
    }

    const isValidForm = () => {
        if (form.transfered_items.length === 0) return false
        if (form.warehouse_from === "" || form.warehouse_to === "") return false
        if (isSelectedSameWarehouse() === true) return false

        return true
    }

    const isSelectedSameWarehouse = () => {
        if (form.warehouse_from !== "" && form.warehouse_to !== ""){
            return form.warehouse_from === form.warehouse_to
        }

        return false
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                visible
                info
                content={
                    <span style={{ color: "var(--dark)", fontWeight: "bold" }}>
                        { t('select_warehouse_from_to_load_available_items_for_transfer') }.
                    </span>
                }
            />
            <NonFieldErrors errors={errors}/>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    required
                    label={t('transfer_from')}
                    value={form.warehouse_from}
                    loading={warehouses.isLoading}
                    disabled={warehouses.isLoading}
                    customOptions={warehouses.options}
                    onChange={(e, { value }) => setForm(prev => ({...prev, warehouse_from: value}))}
                />
                <SuperField as="choice"
                    search
                    required
                    label={t('transfer_to')}
                    value={form.warehouse_to}
                    key={form.warehouse_from}
                    loading={warehouses.isLoading}
                    disabled={warehouses.isLoading}
                    customOptions={warehouses.options}
                    onChange={(e, { value }) => setForm(prev => ({...prev, warehouse_to: value}))}
                    error={isSelectedSameWarehouse() ? t('warehouse_can_not_be_the_same_as_warehouse_that_are_used_to_transfer_from') : false}
                />
            </Form.Group>

            <SuperField as="choice"
                search
                text="fullname_with_titles"
                label={t('responsible_person')}
                value={form.responsible_person}
                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                onChange={(e, { value }) => setForm(prev => ({...prev, responsible_person: value}))}
            />

            <SuperField as="textarea" 
                label={t('note')}
                value={form.note}
                onChange={(e, { value }) => setForm(prev => ({...prev, note: value}))}
            />

            <TransferItems 
                warehouseFrom={form.warehouse_from} 
                handleSelectedItems={(selected) => setForm(prev => ({...prev, transfered_items: selected}))}
            />            

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || !isValidForm()}
                />
            </Form.Field>
        </Form>
    );
};

export default WarehouseTransferCardForm;