import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// import { setDotSeparator } from '@helpers/functions';
// components
// import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import ModalCancel from '@components/buttons/ModalCancel';
import { Form, Divider, Button/*, Header*/ } from 'semantic-ui-react';

const ProcessForm = ({ onClose, record, setData, setHeader, setTotal }) => {
    const { t } = useTranslation();

    const [templates, setTemplates] = useState([])
    const [view, setView] = useState(record === undefined ? 1 : 2) // select template view
    const [loading, setLoading] = useState(true)
    const [asNew, setAsNew] = useState(false)

    useEffect(() => {
        if (asNew) {
            setView(2)
        }
    }, [asNew])

    useEffect(() => {
        async function getTemplates(){
            setLoading(true)
            if (record === undefined) { // only if its create
                const request = await requests.get(API.ASSETS + "warehouse_processes/?is_template=true")
                if (request.status === 200) {
                    if (request.response?.results?.length > 0) {
                        setTemplates(request.response?.results || [])
                    } else {
                        setAsNew(true)
                    }
                }
            } else {
                setHeader(t('update_process'))
            }
            
            setLoading(false)
        }

        getTemplates()
        // eslint-disable-next-line
    }, [])

    return (
        <SpinnerSegment loading={loading}>
            { !loading && 
                <>
                    { view === 1 && 
                        <ProcessTemplateForm
                            asNew={asNew}
                            onClose={onClose}
                            setData={setData}
                            setTotal={setTotal}
                            setAsNew={setAsNew}
                            templates={templates}
                        />
                    }
                    { view === 2 && 
                        <WarehouseProcessForm
                            onClose={onClose}
                            record={record}
                            setData={setData}
                            setTotal={setTotal}
                        />
                    }
                </>
            }
            
        </SpinnerSegment>
    );
};

const ProcessTemplateForm = ({ templates, setData, setTotal, asNew, setAsNew, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false) 
    const [template, setTemplate] = useState("") 

    const handleSubmit = async () => {
        setProcessing(true)
        const selectedTemplate = templates.find(item => item.id === template) || undefined
        const request = await requests.post(API.ASSETS + "warehouse_processes/", {
            title: selectedTemplate?.title || "",
            note: selectedTemplate?.note || "",
            start_date: moment().format("YYYY-MM-DD"),
            is_active: true,
            is_template: false,
            responsible_persons: {
                add: selectedTemplate.responsible_persons.map(item => item.id)
            },
            // items: selectedTemplate.items.length > 0 ? {
            //     create: selectedTemplate.items.map(item => ({
            //         quantity: item.quantity,
            //         item: item.item.id
            //     }))
            // } : null
        })

        if (request.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('warehouse_process_created'),
                animation: 'pulse',
                time: 2000,
            })
            setTotal(prev => prev + 1)
            setData(prev => ([request.response, ...prev]))
            onClose()
        } else {
            console.log(request)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="choice" 
                search
                required
                clearable
                value={template}
                customOptions={templates?.map(item => ({
                    key: item.id,
                    value: item.id,
                    text: item.title
                }))}
                label={t('select_from_existing_templates')}
                onChange={(e, { value }) => setTemplate(value)}
            />

            <SuperField as="checkbox"
                label={t('create_new_warehouse_process')}
                checked={asNew}
                onChange={() => setAsNew(!asNew)}
            />
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={processing}/>
                <Button primary
                    loading={processing}
                    content={t('confirm')}
                    disabled={template === "" || processing}
                />
            </Form.Field>
        </Form>
    )
    
}

const WarehouseProcessForm = ({ record, setTotal, setData, onClose }) => {
    const { t } = useTranslation()

    // eslint-disable-next-line 
    const [loading, setLoading] = useState(false) 
    // const [storedItems, setStoredItems] = useState([]) 
    const [processing, setProcessing] = useState(false) 
    const [asTemplate, setAsTemplate] = useState(false) 
    const [form, setForm] = useState({
        title: record?.title || "",
        note: record?.note || "",
        responsible_persons: record?.responsible_persons.map(item => item.id) || [],
        start_date: record?.start_date || moment().format("YYYY-MM-DD"),
    })

    // const [items, setItems] = useState(record?.items?.length > 0 
    //     ? record?.items?.map(item => ({
    //         id: item.id,
    //         is_deleted: false,
    //         item: item.item.id,
    //         quantity: item.quantity
    //     })) 
    //     : 
    //     [
    //         {
    //             id: 0,
    //             item: "",
    //             quantity: "",
    //             is_deleted: false
    //         }
    //     ]
    // )

    // useEffect(() => {
    //     async function fetchStoredItems() {
    //         setLoading(true)
    //         const request = await requests.get(API.ASSET_STORED_ITEMS + "?query={id, code, catalogue_item{id, title, code}, warehouse{id, title}}&exclude_asset_item=true")
    //         if (request.status === 200) setStoredItems(request.response)
    //         setLoading(false)
    //     }

    //     fetchStoredItems()
    // }, [])

    // const isValidNumber = (number) => {
    //     if (number === "") return false
    //     if (isNaN(number)) return false
    //     if (parseFloat(number) < 0) return false
    
    //     return true
    // }

    const isFormValid = () => {
        if (form.title === "") return false
        // let total_items = items.filter(item => item.is_deleted !== true && item.item !== "" && isValidNumber(item.quantity)).length
        // if (total_items >= 0) return true 

        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)

        if (record?.id === undefined) { // create
            // const itemsToCreate = items.filter(item => item.is_deleted === false && item.id === 0 && item.item !== "" && isValidNumber(item.quantity))
            const dataset = {
                title: form?.title || "",
                note: form?.note || "",
                start_date: form?.start_date || null,
                is_active: true,
                is_template: false,
                responsible_persons: {
                    add: form.responsible_persons
                },
                // items: itemsToCreate.length > 0 ? {
                //     create: itemsToCreate.map(item => ({
                //         quantity: item.quantity,
                //         item: item.item
                //     }))
                // } : null
            }

            const request = await requests.post(API.ASSETS + "warehouse_processes/", dataset)
            if (request.status === 201) {
                if (asTemplate) {
                    await requests.post(API.ASSETS + "warehouse_processes/", {
                        ...dataset,
                        is_template: true
                    })
                }
                
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('warehouse_process_created'),
                    animation: 'pulse',
                    time: 2000,
                })
                setTotal(prev => prev + 1)
                setData(prev => ([request.response, ...prev]))
                onClose()
            }
        } else { // update
            // const itemsToCreate = items.filter(item => item.is_deleted === false && item.id === 0 && item.item !== "" && isValidNumber(item.quantity))
            // const itemsToUpdate = items.filter(item => item.is_deleted === false && item.id !== 0 && item.item !== "" && isValidNumber(item.quantity))
            // const itemsToDelete = items.filter(item => item.is_deleted === true && item.id !== 0)

            const dataset = {
                title: form?.title || "",
                note: form?.note || "",
                start_date: form.start_date || null,
                is_active: true,
                is_template: false,
                responsible_persons: {
                    remove: record.responsible_persons.map(item => item.id),
                    add: form.responsible_persons
                }
            }

            // const items_query = {
            //     create: [],
            //     update: {}
            // }

            // if (itemsToCreate.length > 0) {
            //     for (let i = 0; i < itemsToCreate.length; i++) {
            //         items_query['create'].push({
            //             quantity: itemsToCreate[i].quantity,
            //             item: itemsToCreate[i].item,
            //         })
            //     }
            // }

            // if (itemsToUpdate.length > 0) {
            //     for (let i = 0; i < itemsToUpdate.length; i++) {
            //         items_query['update'][itemsToUpdate[i].id] = {
            //             quantity: itemsToUpdate[i].quantity,
            //             item: itemsToUpdate[i].item,
            //         }
            //     }
            // }

            const request = await requests.patch(API.ASSETS + "warehouse_processes/" + record.id + "/", {
                ...dataset,
                // items: items_query
            })

            if (request.status === 200) {
                // perform delete on warehouse process items if exists
                // if (itemsToDelete.length > 0) {
                //     for (let i = 0; i < itemsToDelete.length; i++) {
                //         await requests.del(API.ASSETS + "warehouse_process_items/" + itemsToDelete[i].id + "/")
                //     }
                // }
                
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('warehouse_process_updated'),
                    animation: 'pulse',
                    time: 2000,
                })
                // const requestProcess = await requests.get(API.ASSETS + "warehouse_processes/" + record.id + "/")
                setData(prev => prev.map(item => {
                    if (item.id === record.id) {
                        item = request.response//requestProcess.response
                    }

                    return item
                }))
                onClose()
            }
        }

        setProcessing(false)
    }

    // const toExclude = (current) => {
    //     const toExcludeItems = []
    //     for (const item of items){
    //         if (item.item !== "" && item.is_deleted !== true && current.item !== item.item){
    //             toExcludeItems.push(item.item)
    //         }
    //     }

    //     return toExcludeItems
    // } 

    // const handleItemChange = (idx, value, attribute) => {
    //     setItems(prev => prev.filter((item, index) => {
    //         if (idx === index) {
    //             item[attribute] = value
    //         }
    //         return item
    //     }))
    // }
    
    return (
        <SpinnerSegment loading={loading}>
            { !loading && 
                <Form onSubmit={handleSubmit}>
                    <SuperField as="input"
                        autoFocus
                        required
                        label={t('title')}
                        value={form.title}
                        onChange={(e, { value }) => setForm(prev => ({...prev, title: value}))}
                    />

                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={t('start_date')}
                            value={form.start_date}
                            onChange={(e, { value }) => setForm(prev => ({...prev, start_date: value}))}
                        />
                    </Form.Group>

                    <SuperField as="choice"
                        search
                        multiple
                        text="fullname_with_titles"
                        label={t('responsible_persons')}
                        value={form.responsible_persons}
                        endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                        onChange={(e, { value }) => setForm(prev => ({...prev, responsible_persons: value}))}
                    />

                    <SuperField as="textarea"
                        label={t('note')}
                        value={form.note}
                        onChange={(e, { value }) => setForm(prev => ({...prev, note: value}))}
                    />

                    {/* <Header as="h3" content={t('stored_items')} style={{ marginBottom: 0, marginTop: "0.5rem" }}/>
                    <div style={{ marginBottom: "0.5rem" }}><small>{t('select_at_least_one_item_to_process')}</small></div>
                    {
                        items.map((item, index) => (
                            <Form.Group key={index} widths="equal" style={item.is_deleted ? { display: "none" } : {}}>
                                <SuperField as="choice"
                                    search
                                    value={item.item}
                                    customOptions={storedItems.filter(storedItem => !toExclude(item).includes(storedItem.id)).map(storedItem => ({
                                        key: storedItem.id,
                                        value: storedItem.id,
                                        text: `${storedItem.code ? storedItem.code + ": " : storedItem?.catalogue_item?.code ? storedItem?.catalogue_item?.code + ": " : ""}${storedItem?.catalogue_item?.title} - ${storedItem.warehouse.title}`
                                    }))}
                                    onChange={(e, { value }) => handleItemChange(index, value, "item")}
                                />
                                <SuperField as="input"
                                    width="6"
                                    style={{ marginTop: "0.2rem" }}
                                    placeholder={t('quantity')}
                                    value={item.quantity}
                                    onChange={(e, { value }) => handleItemChange(index, setDotSeparator(value), "quantity")}
                                />
                                <Form.Field width="1" style={{ marginTop: "0.9rem" }}>
                                    <Icon
                                        name="close-outline"
                                        style={{
                                            color: "var(--danger)",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => setItems(prev => prev.filter((item, idx) => {
                                            if (idx === index) item.is_deleted = true
                                            return item
                                        }))}
                                    />
                                </Form.Field>
                            </Form.Group>
                        ))
                    } */}

                    {/* <Button
                        color="black"
                        type="button"
                        size="tiny"
                        content={t('add_item')}
                        onClick={() => setItems(prev => ([...prev, {
                            id: 0,
                            item: "",
                            quantity: "",
                            is_deleted: false
                        }]))}    
                    /> */}

                    <Divider/>
                    <Form.Group widths="equal">
                        { record?.id === undefined &&
                            <SuperField as="checkbox"
                                style={{ marginTop: "0.9rem" }}
                                label={t('save_as_template')}
                                checked={asTemplate}
                                onChange={() => setAsTemplate(!asTemplate)}
                            />
                        }
                        <Form.Field style={{ textAlign: "right" }}>
                            <ModalCancel onClose={onClose} disabled={processing}/>
                            <Button primary
                                loading={processing}
                                content={t('confirm')}
                                disabled={!isFormValid() || processing}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            }
        </SpinnerSegment>
    )
} 

export default ProcessForm;