import React from 'react';
import { Loader } from 'semantic-ui-react';
// components
import KanbanView from './KanbanView';
import ListView from './ListView';


const IssueViews = ({ stats, selectedView, setIssues, issues, groups, isLoading, setStats, filtered, state, setIsLoading }) => {

    return (
        <>
            {isLoading ? <Loader active={isLoading} /> :
                <>
                    {selectedView === "list" &&
                        <ListView
                            stats={stats}
                            issues={issues}
                            groups={groups}
                            setIssues={setIssues}
                            setStats={setStats}
                            filtered={filtered}
                            setIsLoading={setIsLoading}
                            state={state} />}
                    {selectedView === "kanban" && <KanbanView issues={issues} />}
                </>
            }
        </>
    );
};

export default IssueViews;