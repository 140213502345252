import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
//components
import Icon from '@components/Icon';
import ModalCancel from '@components/buttons/ModalCancel';
import { FlexRow, FlexItem, FlexTable } from '@components/tables/FlexTable';
import { Button, Checkbox, Grid, Header, Loader, Modal } from 'semantic-ui-react';

const PermissionsList = ({ label, permissions, groupPerms, setGroupPermissions, group }) => {
    // eslint-disable-next-line
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false)

    const splitValue = (permission) => {
        return permission.split("__")
    }

    const PermissionToggle = ({ permission }) => {
        const modalInit = {
            open: false,
            action: null,
            dependencies: [],
        }

        const [isProcessing, setIsProcessing] = useState(false)
        const [modal, setModal] = useState(modalInit)

        const isChecked = (perm) => {
            return groupPerms.some(groupPerm => groupPerm.id === perm.id)
        }

        const checkDependencies = async (perm) => {
            let request = undefined;
            let action = null;
            setIsProcessing(true)
            if (isChecked(perm)) { // means that currently we are setting off permission
                // remove from permission group list
                action = "remove"
                request = await requests.get(API.PERMISSION_GROUPS + group.id + "/permissions_to_remove/" + perm.id + "/")
            } else {
                // add to permission group list
                action = "add"
                request = await requests.get(API.PERMISSION_GROUPS + group.id + "/permissions_to_add/" + perm.id + "/")

            }

            if (request !== undefined && request?.response?.length > 0) {
                setModal({
                    open: true,
                    action: action,
                    dependencies: request.response
                })
            } else {
                await handlePermission(perm)
            }

            setIsProcessing(false)
        }

        const handlePermission = async (perm, dependencies) => {
            setIsProcessing(true)
            if (isChecked(perm)) { // means that currently we are setting off permission
                // remove from permission group list
                const request = await requests.del(API.PERMISSION_GROUPS + group.id + "/permissions/" + perm.id + "/")
                if (request.status === 204) {
                    if (dependencies !== undefined) {
                        let permsToRemove = [...dependencies, perm]

                        setGroupPermissions(prevState => prevState.filter(permission => {
                            return !permsToRemove.some(item => item.id === permission.id)
                        }))
                    } else {
                        setGroupPermissions(prevState => prevState.filter(permission => permission.id !== perm.id))
                    }
                }
            } else {
                // add to permission group list
                const request = await requests.put(API.PERMISSION_GROUPS + group.id + "/permissions/", { id: perm.id })
                if (request.status === 200) {
                    if (dependencies !== undefined) {
                        let permissionsToAdd = []
                        permissionsToAdd.push(perm)
                        for (let i = 0; i < dependencies.length; i++) {
                            permissionsToAdd.push(dependencies[i])
                        }

                        setGroupPermissions(prevState => {
                            return [...prevState, ...permissionsToAdd]
                        })
                    } else {
                        setGroupPermissions(prevState => {
                            return [...prevState, perm]
                        })
                    }
                }
            }

            setIsProcessing(false)
            setModal(modalInit)
        }

        return (
            <>
                {isProcessing && <Loader size="small" className='dark-loader' active inline style={{ marginRight: "0.5rem", marginTop: "-0.7rem" }} />}
                <Checkbox disabled={isProcessing} toggle checked={isChecked(permission)} onChange={() => checkDependencies(permission)} />

                <Modal
                    size="tiny"
                    open={modal.open}
                    onClose={() => setModal({ ...modal, open: false })}
                >
                    <Modal.Header as="h4">
                        {t('permissions_listed')} <u>{(modal.action !== null && modal.action === "add") ? "added" : "removed"}</u> {t('with_this_action')}
                    </Modal.Header>
                    <Modal.Content>
                        {modal.dependencies.map(dependencyPerm => (
                            <FlexRow key={dependencyPerm.id} borders>
                                <FlexItem>
                                    <Header as='h4'>
                                        <Header.Content>
                                            {splitValue(dependencyPerm.name)[0]}
                                            <Header.Subheader>
                                                {splitValue(dependencyPerm.name)[1]}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </FlexItem>
                                <FlexItem basis="20%" textAlign="right">
                                    <Checkbox style={{ cursor: "not-allowed" }} disabled={true} toggle checked={(modal.action !== null && modal.action === "add") ? false : true} />
                                </FlexItem>
                            </FlexRow>
                        ))}
                    </Modal.Content>
                    <Modal.Actions>
                        <ModalCancel onClose={() => setModal({ ...modal, open: false })} />
                        <Button primary onClick={() => handlePermission(permission, modal.dependencies)}>
                            {t('confirm')}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }

    return (
        <>
            <Grid>
                <Grid.Row columns='2' style={{ paddingBottom: 0, cursor: "pointer", borderBottom: "1px solid var(--light-grey)" }} onClick={() => setVisible(!visible)}>
                    <Grid.Column mobile={14} tablet={14} computer={14}>
                        <Header as="h3" >{label}</Header>
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={2} computer={2}>
                        {!visible ?
                            <Button floated="right" size="medium" icon={<Icon name="chevron-down-outline"/>} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                            :
                            <Button floated="right" size="medium" icon={<Icon name="chevron-up-outline"/>} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {visible &&
                <FlexTable stripped={false}>
                    <FlexRow background="transparent" padding="0" />
                    {permissions.map(permission => (
                        <FlexRow key={permission.id} borders>
                            <FlexItem>
                                <Header as='h4'>
                                    <Header.Content>
                                        {splitValue(permission.name)[0]}
                                        <Header.Subheader>
                                            {splitValue(permission.name)[1]}
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                            </FlexItem>
                            <FlexItem basis="20%" textAlign="right">
                                <PermissionToggle permission={permission} />
                            </FlexItem>
                        </FlexRow>
                    ))}
                </FlexTable>
            }

        </>
    )
}

export default PermissionsList