import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider, Header, Loader } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';

const InvoiceApprovalForm = ({ selected, setSelected, connection, onClose }) => {
    const { t } = useTranslation()
    const [form, setForm] = useState({
        responsible_person: "",
        second_responsible_person: "",
        reviewer: "",
        second_reviewer: ""
    })

    const [employees, setEmployees] = useState([])
    const [processedInvoices, setProcessedInvoices] = useState(0)
    const [isProcessing, setIsProcessing] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect( () => {
        async function fetchEmployees(){
            setLoading(true)
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true&is_active=true&has_user=true")
            if( request.status === 200 ){
                const requestAllowedUsers = await requests.get(API.INVOICES + "allowed_users/?connection=" + connection.connection_id)

                if( requestAllowedUsers.status === 200 ){
                    // loop over employees and create options based on allowedUsers collection
                    const matchedEmployees = request.response.filter(employee => requestAllowedUsers.response.filter(allowedUser => (allowedUser.user.id === employee.user.id && allowedUser.can_view)).length > 0)

                    let employeesOptions = []
                    for (let i = 0; i < matchedEmployees.length; i++) {
                        employeesOptions.push({
                            key: matchedEmployees[i].id,
                            value: matchedEmployees[i].id,
                            text: matchedEmployees[i].fullname,
                        })
                    }
                    setEmployees(employeesOptions)
                }
            }
            setLoading(false)
        }

        fetchEmployees()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setIsProcessing(true)
        for (let i = 0; i < selected.length; i++) {
            const request = await requests.post(API.APPROVALS + "invoices/", {
                ...form,
                // create reference number separated by semicolons: expense;invoice_id;invoice_number;supplier_registration_id;supplier_name;
                invoice_reference: `expense;${selected[i].id};${selected[i].number};${selected[i].supplier_name || "--"};${selected[i].date_due};${selected[i].price_to_pay || "--"} ${selected[i].currency || "--"}`,
                supplier_registration_id: selected[i].supplier_registration_id ? selected[i].supplier_registration_id : null
            })
            
            if( request.status === 201 ){
                await requests.patch(API.INVOICES + selected[i].id + "/?connection=" + connection.connection_id, { approval: 2 })
                setProcessedInvoices(prevState => prevState += 1)
            }
        }

        setTimeout(function() {
            setIsProcessing(false)
            setSelected([])
            onClose()
        }, 1000);
    }

    return (
        <Form onSubmit={handleSubmit} loading={loading} style={{ minHeight: "10rem" }}>
            { !loading && 
                <>
                    <SuperField as="choice"
                        search
                        required 
                        label={t('responsible_person')}
                        customOptions={employees}
                        value={form.responsible_person}
                        onChange={ (e, { value }) => setForm({ ...form, responsible_person: value })}
                    />
                    <Divider/>
                    <Header as="h3" content={t('additional_configuration')}/>
                    <SuperField as="choice"
                        search
                        label={t('second_responsible_person')}
                        customOptions={employees}
                        value={form.second_responsible_person}
                        onChange={ (e, { value }) => setForm({ ...form, second_responsible_person: value })}
                    />
                    <Form.Group widths={"equal"}>
                        <SuperField as="choice"
                            search
                            label={t('reviewer_person')}
                            customOptions={employees}
                            value={form.reviewer}
                            onChange={ (e, { value }) => setForm({ ...form, reviewer: value })}
                        />
                        <SuperField as="choice"
                            search
                            label={t('second_reviewer_person')}
                            customOptions={employees}
                            value={form.second_reviewer}
                            onChange={ (e, { value }) => setForm({ ...form, second_reviewer: value })}
                        />
                    </Form.Group>

                    <Divider/>
                    <strong>{ t('number_of_selected_invoices') }:</strong> { selected.length }
                    <Divider/>
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalSubmit
                            size="large"
                            disabled={isProcessing || selected.length === 0 || form.responsible_person === "" }
                            text={
                                isProcessing 
                                ? processedInvoices !== selected.length 
                                    ? <> <Loader size="small" className='dark-loader' style={{ marginRight: "1rem" }} active inline/> {t('processing')}: { processedInvoices + " / " + selected.length }</> 
                                    : t('processed_confirming_operation') 
                                : t('confirm')
                            }
                        />
                    </Form.Field>
                </>
            }
        </Form>
    );
};

export default InvoiceApprovalForm;