import React from 'react';
import { useTranslation } from 'react-i18next';
// components 
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Header, Icon, Popup, Button } from 'semantic-ui-react';


const HeaderSection = ({ toggleSidebar, toggleView, view }) => {
    const { t } = useTranslation()
    return (
        <FlexRow padding="0">
            <FlexItem >
                <Button.Group basic size='small' style={{ borderRadius: 0 }} >
                    <Button style={{ color: 'var(--primary)' }} content={t('issue_management')} onClick={() => toggleView(0)} active={view === 0} />
                    <Button style={{ color: 'var(--primary)' }} content={t('issues_statistics')} onClick={() => toggleView(2)} active={view === 2} />
                    <Button style={{ color: 'var(--primary)' }} content={t('full_project_statistics')} onClick={() => toggleView(1)} active={view === 1} />
                </Button.Group>
            </FlexItem>
            <FlexItem textAlign="right" basis="20%">
                <Header as="h3">
                    <Popup
                        content="Show Information Panel"
                        position='left center'
                        inverted
                        trigger={
                            <Icon
                                onClick={toggleSidebar}
                                className="toggle-button"
                                name="chevron left"
                                circular
                            />
                        }
                    />
                </Header>
            </FlexItem>
        </FlexRow>
    );
};

export default HeaderSection;