import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { generateNumberCombination, thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Button, Grid, Header, Message, Table } from 'semantic-ui-react';
import CommodityForm from './CommodityForm';


const LogisticRecordForm = ({ record, initialOrder, onClose, setTotal, setData, employees, setLogisticRecords, type, setSize }) => {
    const { t } = useTranslation()
    const allow_sequential_numbering_logistic_record = useSelector(state => state.allow_sequential_numbering_logistic_record)

    const [processing, setProcessing] = useState(false)
    const [fetchingOrders, setFetchingOrders] = useState(false)
    const [fetchingOrder, setFetchingOrder] = useState(record?.id !== undefined ? true : false)
    const [view, setView] = useState(initialOrder?.id ? 1 : 0)
    const [orders, setOrders] = useState([])
    const [order, setOrder] = useState(initialOrder?.id || "")
    const [selectedOrder, setSelectedOrder] = useState(record?.id ? null : initialOrder || null)
    const [selectedCommodities, setSelectedCommodities] = useState(record?.id !== undefined 
        ?   [{
                order: record?.order.id,
                order_commodity: record?.order_commodity?.id,
                quantity: record?.quantity || 1,
                group: record?.group || "",
                transport_info: record?.transport_info || "",
                type_of_wagon: record?.type_of_wagon || "",
                quality: record?.quality || "",
                is_fragile: record?.is_fragile || false,
                measure_unit: record?.measure_unit?.id || "",
                average_package_weight: record?.average_package_weight || "",
                measurement_z: record?.measurement_z || "",
                measurement_x: record?.measurement_x || "",
                measurement_y: record?.measurement_y || "",
                pallet_count: record?.pallet_count || "",
                weight_without_pallet: record?.weight_without_pallet || "",
                package_total: record?.package_total || "",
                weight_total: record?.weight_total || "",
                temperature_in_celsius: record?.temperature_in_celsius || "",
                addition_description_info: record?.addition_description_info || "",
                loading_price: record?.loading_price || "",
                price_paid: record?.price_paid || "",
                transport_price: record?.transport_price || "",
                currency: record?.currency || "EUR",
                note: record?.note || "",
            }] 
        : []
    )
    const [measureUnits, setMeasureUnits] = useState([])
    const [transportTypes, setTransportTypes] = useState([])

    const [labels, setLabels] = useState(record?.labels?.map(item => item.id) || [])
    const [assignedTo, setAssignedTo] = useState(record?.assigned_to?.map(item => item.id) || [])

    const [sharedForm, setSharedForm] = useState({
        reference_number: allow_sequential_numbering_logistic_record ? null : record?.id ? record.reference_number : generateNumberCombination(),
        estimated_delivery_date: record?.estimated_delivery_date || moment().format("YYYY-MM-DD"),
        type_of_transport: record?.type_of_transport?.id || "",
        carrier: record?.carrier?.id || "",
        // transport_info: "", // trans number
        // type_of_wagon: "",  // vagon number
        // group: "",          // rwb
        info_for_driver: record?.info_for_driver || "",
        loading_date: record?.loading_date || "",
        delivery_date: record?.delivery_date || "",
        distance: record?.distance || "",
        contact_person: record?.contact_person || "",
        contact_person_phone: record?.contact_person_phone || "",
        contact_person_email: record?.contact_person_email || "",
    })

    // const [sameTransportDetails, setSameTransportDetails] = useState(false)
    const [showRouteInfo, setShowRouteInfo] = useState(false)

    const [showMoreLoadingAddress, setShowMoreLoadingAddress] = useState(false)
    const [loadingAddress, setLoadingAddress] = useState({
        street: record?.loading_address?.street || "",
        postcode: record?.loading_address?.postcode || "",
        city:  record?.loading_address?.city || "",
        country: record?.loading_address?.country || "",
        address_line: record?.loading_address?.address_line || "",
    })
    
    const [showMoreDestinationAddress, setShowMoreDestinationAddress] = useState(false)
    const [destinationAddress, setDestinationAddress] = useState({
        street: record?.destination_address?.street || "",
        postcode: record?.destination_address?.postcode || "",
        city:  record?.destination_address?.city || "",
        country: record?.destination_address?.country || "",
        address_line: record?.destination_address?.address_line || "",
    })

    useEffect(() => {
        async function fetchOrders() {
            setFetchingOrders(true)
            let query = ""
            if (type === 1) {
                query += "&is_purchase_order=true"
            } else {
                query += "&is_purchase_order=false"
            }

            const request = await requests.get(API.ORDERS + "?only_basic_info=true&is_active=true&resource_type=saleorder" + query)
            if (request.status === 200) {
                setOrders(request.response)
            }
            setFetchingOrders(false)
        }

        async function fetchMeasureUnits() {
            const request = await requests.get(API.COMMON + "measure_units/")
            if (request.status === 200) setMeasureUnits(request.response)
        }

        async function fetchTransportTypes() {
            const request = await requests.get(API.LOGISTICS + "transport_types/")
            if (request.status === 200) {
                setTransportTypes(request.response)
                if (request.response.length === 1) {
                    setSharedForm(prev => ({
                        ...prev,
                        type_of_transport: request.response?.[0]?.id || ""
                    }))
                }
            }
        }
        
        fetchOrders()
        fetchMeasureUnits()
        fetchTransportTypes()
        if (record?.id !== undefined) {
            handleOrderSelect()
        }
        // eslint-disable-next-line
    }, [])

    const handleOrderSelect = async () => {
        setSize("small")
        setView(1)

        if (order !== "") {
            setFetchingOrder(true)
            const request = await requests.get(API.ORDERS + parseInt(order) + "/")
            if (request.status === 200) setSelectedOrder(request.response)
            setFetchingOrder(false)
        }
    }

    const isFormValid = () => {
        if (sharedForm.reference_number === "" && allow_sequential_numbering_logistic_record !== true) return false
        if (sharedForm.estimated_delivery_date === "") return false
        if (sharedForm.type_of_transport === "") return false
        if (selectedCommodities.length === 0) return false

        return true
    }

    const onRemove = (index) => {
        setSelectedCommodities(prev => prev.filter((item, idx) => idx !== index))
    }

    const handleSubmit = async () => {
        setProcessing(true)
        let records = []
        for (let i = 0; i < selectedCommodities.length; i++) {
            // create dataset and create logistic record
            const isPurchase = selectedOrder.is_purchase_order

            let data = {
                ...sharedForm,
                ...selectedCommodities[i],
                delivery_date: sharedForm.delivery_date || null,
                loading_date: sharedForm.loading_date || null,
                loading_price: selectedCommodities[i].loading_price || null,
                transport_price: selectedCommodities[i].transport_price || null,
                price_paid: selectedCommodities[i].price_paid || null,
                average_package_weight: selectedCommodities[i].average_package_weight || null,
                weight_without_pallet: selectedCommodities[i].weight_without_pallet || null,
                pallet_count: selectedCommodities[i].pallet_count || 1,
                package_total: selectedCommodities[i].package_total || null,
                weight_total: selectedCommodities[i].weight_total || null,
                temperature_in_celsius: selectedCommodities[i].temperature_in_celsius || null,
                transport_info: selectedCommodities[i].transport_info || null,
                type_of_wagon: selectedCommodities[i].type_of_wagon || null,
                group: selectedCommodities[i].group || null,
                order: selectedOrder.id,
                business_contract_reference: selectedOrder.business_contract_reference,
                unit: selectedOrder.owner.id,
                account: selectedOrder.business_detail?.id,
                type: isPurchase ? 1 : 2,
                cost_center: selectedOrder.cost_center?.id || null,
                loading_address: {
                    ...loadingAddress,
                    country: loadingAddress.country || null
                },
                destination_address: {
                    ...destinationAddress,
                    country: destinationAddress.country || null
                }
            }

            if (labels.length > 0) {
                if (record?.id) {
                    // remove old add new
                    data['labels'] = { remove: record.labels.map(item => item.id), add: labels }
                } else {
                    data['labels'] = { add: labels }
                }
            }
            if (assignedTo.length > 0) {
                if (record?.id) {
                    // remove old add new
                    data['assigned_to'] = { remove: record.assigned_to.map(item => item.id), add: assignedTo }
                } else {
                    data['assigned_to'] = { add: assignedTo }
                }
            }

            if (record?.id === undefined) {
                const request = await requests.post(API.LOGISTICS + "records/", data)
                if (request.status === 201) {
                    records.push(request.response)
                }
            } else {
                const request = await requests.patch(API.LOGISTICS + "records/" + record?.id + "/", data)
                if (request.status === 200) {
                    records.push(request.response)
                }
            }
        }

        if (record?.id === undefined) {
            if (setLogisticRecords) {
                setLogisticRecords(prev => ([...prev, ...records]))
            }
    
            if (setData) {
                setData(prev => ([...records, ...prev]))
            }
    
            if (setTotal) {
                setTotal(prev => prev + records.length)
            }
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('logistic_records_created'),
                animation: 'pulse',
                time: 2000,
            })
        } else {
            if (setData) {
                const response = records.length === 1 ? records[0] : undefined
                if (response) {
                    setData(prev => prev.map(item => {
                        if (item.id === response.id) {
                            item = response
                        }
                        return item
                    }))
                }
            }
        }

        setProcessing(false)
        onClose()
    }

    return (
        <Form onSubmit={handleSubmit}>
            { view === 0 && 
                <SuperField as="choice"
                    search
                    required
                    value={order}
                    label={t('select_order')}
                    onChange={(e, { value }) => setOrder(value)}
                    loading={fetchingOrders}
                    disabled={fetchingOrders}
                    customOptions={orders.map(item => ({
                        key: item.id,
                        value: item.id,
                        text: `${item.order_number} - ${item.name} - ${item.business_detail?.name || ""}`
                    }))}
                />
            }
            { view === 1 && 
            <SpinnerSegment loading={fetchingOrder}>
                { record?.id !== undefined &&
                    <>
                        <Header as="h3" content={t('update_record')}/>
                        <Divider/>
                    </> 
                }
                { !fetchingOrder && 
                    <>
                        <Message info visible={true}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                                <div>
                                    <strong>{t('order')}:</strong> { selectedOrder?.order_number } - { selectedOrder?.name }
                                </div>
                                <div style={{ marginRight: "1rem", marginLeft: "1rem" }}> · </div>
                                <div>
                                    <strong>{t('type')}:</strong> { type === 1 ? t('buy') : t('sell') }
                                </div>
                                <div style={{ marginRight: "1rem", marginLeft: "1rem" }}> · </div>
                                <div>
                                    <strong>{ type === 1 ? t('supplier') : t('account')}:</strong> {" "}
                                    { selectedOrder?.business_detail?.name } 
                                </div>
                            </div>
                            { record?.id === undefined && 
                                <p>
                                    { t('logistic_record_create_hint') }
                                </p>
                            }
                        </Message>
                        <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                required
                                autoComplete="off"
                                name="estimated_delivery_date"
                                label={t('estimated_delivery_date')}
                                value={sharedForm.estimated_delivery_date}
                                onChange={(e, { value }) => setSharedForm(prev => ({...prev, estimated_delivery_date: value}))}
                            />
                            <SuperField as="datepicker"
                                autoComplete="off"
                                name="loading_date"
                                label={t('loading_date')}
                                value={sharedForm.loading_date}
                                onChange={(e, { value }) => setSharedForm(prev => ({...prev, loading_date: value}))}
                            />
                            {/* { allow_sequential_numbering_logistic_record === false && 
                                <SuperField as="input"
                                    required
                                    autoComplete="off"
                                    name="reference_number"
                                    label={t('reference_number')}
                                    value={sharedForm.reference_number}
                                    onChange={(e, { value }) => setSharedForm(prev => ({...prev, reference_number: value}))}
                                />
                            } */}
                        </Form.Group>
        
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                search
                                required
                                clearable={false}
                                name="type_of_transport"
                                label={t('transport_type')}
                                value={sharedForm.type_of_transport}
                                onChange={(e, { value }) => setSharedForm(prev => ({...prev, type_of_transport: value}))}
                                customOptions={transportTypes.map(item => ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.title
                                }))}
                            />

                            <SuperField as="input"
                                name="info_for_driver"
                                label={t('info_for_driver')}
                                value={sharedForm.info_for_driver}
                                onChange={(e, { value }) => setSharedForm(prev => ({...prev, info_for_driver: value}))}
                            />
                        </Form.Group>
                        <Divider/>
                        
                        {/* <SuperField as="checkbox"
                            checked={sameTransportDetails}
                            onChange={() => setSameTransportDetails(!sameTransportDetails)}
                            label={t('add_to_all_transported_commodities')}
                        /> */}
                        {/* <Divider/> */}

                        
                        {/* <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                name="delivery_date"
                                label={t('delivery_date')}
                                value={sharedForm.delivery_date}
                                onChange={(e, { value }) => setSharedForm(prev => ({...prev, delivery_date: value}))}
                            />
                        </Form.Group> */}

                        <SuperField as="choice"
                            search
                            multiple
                            value={assignedTo}
                            customOptions={employees}
                            label={t('responsible_persons')}
                            onChange={(e, { value }) => setAssignedTo(value)}
                        />

                        <SuperField as="choice"
                            search
                            multiple
                            value={labels}
                            settings={"types/logistic-labels/"}
                            text="name"
                            endpoint={API.LOGISTICS + "labels/"}
                            label={t('labels')}
                            onChange={(e, { value }) => setLabels(value)}
                        />

                        <Divider/>
                        <Header as="h3" style={{ marginTop: "0.5rem", marginBottom: "0.5rem", cursor: "pointer", opacity: "0.8" }} onClick={() => setShowRouteInfo(!showRouteInfo)}>
                            <Icon name={`chevron-${showRouteInfo ? "down" : "forward" }-outline`} style={{ marginRight: "0.5rem", position: "relative", top: "0.2rem" }}/>
                            <span>
                                { t("information_about_transport") }
                            </span>
                        </Header>
                        <Divider/>
                        { showRouteInfo && 
                            <>
                                <SuperField as="choice"
                                    search
                                    text="name"
                                    name="carrier"
                                    label={t('carrier')}
                                    value={sharedForm.carrier}
                                    onChange={(e, { value }) => setSharedForm(prev => ({...prev, carrier: value}))}
                                    endpoint={API.ACCOUNTS + `business_details/?query={id, name}&is_supplier=true&is_active=true&exclude_unit=true`}
                                />
                                <Grid stackable>
                                    <Grid.Row columns={2}>
                                        <Grid.Column style={{ paddingLeft: 0 }}>
                                            <Header as="h3" content={t('loading_address')}/>
                                            <Divider/>
                                            <Form.Group widths="equals" style={{ marginBottom: "0.5rem" }}>
                                                <SuperField as="input"
                                                    label={t('city')}
                                                    placeholder={t('city')}
                                                    value={loadingAddress.city}
                                                    onChange={(e, { value }) =>setLoadingAddress(prev => ({
                                                        ...prev,
                                                        city: value
                                                    }))}
                                                />
                                                <SuperField as="choice"
                                                    search
                                                    type="countries"
                                                    label={t('country')}
                                                    value={loadingAddress.country}
                                                    onChange={(e, { value }) => setLoadingAddress(prev => ({
                                                        ...prev,
                                                        country: value
                                                    }))}
                                                />
                                            </Form.Group>
                
                                            <div 
                                                className="ref-link"
                                                style={{ 
                                                    color: "var(--primary)",
                                                    marginBottom: "0.5rem"
                                                }}
                                                onClick={() => setShowMoreLoadingAddress(!showMoreLoadingAddress)}
                                            >
                                                { showMoreLoadingAddress ? t('hide_address_details') : t('add_address_details') }
                                            </div>
                                            
                                            { showMoreLoadingAddress && 
                                                <>
                                                    <Form.Group>
                                                        <SuperField as="input"
                                                            width="10"
                                                            placeholder={t('street')}
                                                            value={loadingAddress.street}
                                                            onChange={(e, { value }) => setLoadingAddress(prev => ({
                                                                ...prev, street: value
                                                            }))}
                                                        />
                                                        <SuperField as="input"
                                                            width="6"
                                                            placeholder={t('postcode')}
                                                            value={loadingAddress.postcode}
                                                            onChange={(e, { value }) => setLoadingAddress(prev => ({
                                                                ...prev,
                                                                postcode: value
                                                            }))}
                                                        />
                                                    </Form.Group>
                                                    <SuperField as="input"
                                                        placeholder={t('additional_information')}
                                                        value={loadingAddress.address_line}
                                                        onChange={(e, { value }) => setLoadingAddress(prev => ({
                                                            ...prev,
                                                            address_line: value
                                                        }))}
                                                    />
                                                </>
                                            }
                
                
                                        </Grid.Column>
                
                                        <Grid.Column style={{ paddingRight: 0 }}>
                                            <Header as="h3" content={t('delivery_address')}/>
                                            <Divider/>
                                            <Form.Group widths="equal" style={{ marginBottom: "0.5rem" }}>
                                                <SuperField as="input"
                                                    label={t('city')}
                                                    placeholder={t('city')}
                                                    value={destinationAddress.city}
                                                    onChange={(e, { value }) =>
                                                        setDestinationAddress(prev => ({
                                                            ...prev,
                                                            city: value
                                                        }))
                                                    }
                                                />
                                                <SuperField as="choice"
                                                    search
                                                    label={t('country')}
                                                    type="countries"
                                                    value={destinationAddress.country}
                                                    onChange={(e, { value }) =>
                                                        setDestinationAddress(prev => ({
                                                            ...prev,
                                                            country: value
                                                        }))
                                                    }
                                                />
                                            </Form.Group>
                
                                            <div 
                                                className="ref-link"
                                                style={{ 
                                                    color: "var(--primary)",
                                                    marginBottom: "0.5rem"
                                                }}
                                                onClick={() => setShowMoreDestinationAddress(!showMoreDestinationAddress)}
                                            >
                                                { showMoreDestinationAddress ? t('hide_address_details') : t('add_address_details') }
                                            </div>
                
                                            { showMoreDestinationAddress && 
                                                <>
                                                    <Form.Group>
                                                        <SuperField as="input"
                                                            width="10"
                                                            placeholder={t('street')}
                                                            value={destinationAddress.street}
                                                            onChange={(e, { value }) =>
                                                                setDestinationAddress(prev => ({
                                                                    ...prev,
                                                                    street: value
                                                                }))
                                                            }
                                                        />
                                                        <SuperField as="input"
                                                            width="6"
                                                            placeholder={t('postcode')}
                                                            value={destinationAddress.postcode}
                                                            onChange={(e, { value }) =>
                                                                setDestinationAddress(prev => ({
                                                                    ...prev,
                                                                    postcode: value
                                                                }))
                                                            }
                                                        />
                                                    </Form.Group>
                                                    
                                                    <SuperField as="input"
                                                        placeholder={t('additional_information')}
                                                        value={destinationAddress.address_line}
                                                        onChange={(e, { value }) => setDestinationAddress(prev => ({
                                                            ...prev,
                                                            address_line: value
                                                        }))}
                                                    />
                                                </>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <SuperField as="input"
                                    name="distance"
                                    label={t('distance')}
                                    value={sharedForm.distance}
                                    onChange={(e, { value }) => setSharedForm(prev => ({...prev, distance: value.replace(",", ".")}))}
                                />

                                <SuperField as="input"
                                    name="contact_person"
                                    label={t('contact_person')}
                                    value={sharedForm.contact_person}
                                    onChange={(e, { value }) => setSharedForm(prev => ({...prev, contact_person: value}))}
                                />

                                <Form.Group widths="equal">
                                    <SuperField as="input"
                                        name="contact_person_phone"
                                        label={t('phone')}
                                        value={sharedForm.contact_person_phone}
                                        onChange={(e, { value }) => setSharedForm(prev => ({...prev, contact_person_phone: value}))}
                                    />
                                    <SuperField as="input"
                                        name="contact_person_email"
                                        label={t('email')}
                                        value={sharedForm.contact_person_email}
                                        onChange={(e, { value }) => setSharedForm(prev => ({...prev, contact_person_email: value}))}
                                    />
                                </Form.Group>
                                <Divider/>
                            </>
                        }                        

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Header 
                                as="h3"
                                style={{ margin: 0 }}
                                content={
                                    <div>
                                        {t('transported_commodities')} { record?.id === undefined && <sup style={{ color: "var(--danger)" }}>*</sup> }
                                    </div>
                                } 
                            />
                        </div>
                        <Divider/>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('title')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('quantity')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('paid_price')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('actions')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body style={{ fontSize: "0.9rem" }}>
                                { selectedCommodities.length === 0 && 
                                    <Table.Row>
                                        <Table.Cell colSpan="4" style={{ textAlign: "center" }}>
                                            <strong>{ t('no_data') }</strong>
                                        </Table.Cell>
                                    </Table.Row>
                                }
                                { selectedCommodities.map((item, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>{ `${selectedOrder.items.find(orderedItem => orderedItem.id === item.order_commodity)?.item?.code ? selectedOrder.items.find(orderedItem => orderedItem.id === item.order_commodity)?.item?.code + " - " : ""} ${selectedOrder.items.find(orderedItem => orderedItem.id === item.order_commodity)?.item?.title}` }</Table.Cell>
                                        <Table.Cell>{ item.quantity } { (selectedOrder.items.find(orderedItem => orderedItem.id === item.order_commodity)?.item?.weight_is_primary_measurement === true) ? measureUnits.find(measureUnit => measureUnit.id === item.measure_unit)?.abbreviation || "" : "x" }</Table.Cell>
                                        <Table.Cell>{ thousandsSeparators(item.price_paid || 0) } { item.currency }</Table.Cell>
                                        <Table.Cell style={{ display: "flex" }}>
                                            <SuperDuperModal
                                                size="small"
                                                header={t('update_commodity')}
                                                trigger={
                                                    <Icon name="pencil-outline" style={{ cursor: "pointer", marginRight: "1rem" }}/>
                                                }
                                                content={
                                                    <CommodityForm
                                                        index={index}
                                                        selectedCommodity={item}
                                                        order={selectedOrder}
                                                        sharedForm={sharedForm}
                                                        setSharedForm={setSharedForm}
                                                        setSelectedCommodities={setSelectedCommodities}
                                                    />
                                                }
                                            />

                                            { record?.id === undefined && 
                                                <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }} onClick={() => onRemove(index)}/>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                )) }
                            </Table.Body>
                        </Table>
                        { record?.id === undefined && 
                            <SuperDuperModal
                                size="small"
                                header={t('add_commodity')}
                                trigger={
                                    <Button type="button" size="tiny" primary content={t('add_commodity')}/>
                                }
                                content={
                                    <CommodityForm
                                        order={selectedOrder}
                                        setSelectedCommodities={setSelectedCommodities}
                                        sharedForm={sharedForm}
                                        setSharedForm={setSharedForm}
                                    />
                                }
                            />
                        }
                    </>
                }
            </SpinnerSegment>
            }

            { !fetchingOrder && 
                <>
                    <Divider/>
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel onClose={onClose} />
                        { view === 0 &&
                            <Button 
                                primary
                                type="button"
                                disabled={order === "" || fetchingOrders}
                                onClick={() => handleOrderSelect()}
                            >
                                { t('confirm') }
                            </Button>
                        }

                        { view === 1 && 
                            <ModalSubmit
                                loading={processing}
                                disabled={processing || !isFormValid()}
                                text={t('confirm')}
                            />
                        }
                    </Form.Field>
                </>
            }
        </Form>
    );
};

export default LogisticRecordForm;