import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Button, Grid, Header, Divider, Segment, Card } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Action from '@components/general/Action';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific
import ForecastRecordForm from '../components/forms/ForecastRecordForm';

const ForecastRecordsList = ({ forecast }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)
    const [records, setRecords] = useState([])
    const [stats, setStats] = useState({
        plan: 0,
        reality: 0,
        difference: 0
    })

    useEffect(() => {
        async function fetchRecords(){
            setIsLoading(true)
            const request = await requests.get(API.FORECASTS + "records/?forecast=" + forecast.id)
            if( request.status === 200 ){
                setRecords(request.response)
            }
            setIsLoading(false)
        }

        fetchRecords()
    }, [forecast])

    useEffect(() => {
        let totalPlan = 0
        let totalReality = 0
        for (let i = 0; i < records.length; i++) {
            totalPlan += records[i].plan_value;
            totalReality += records[i].reality_value !== null ? records[i].reality_value : 0;
        }

        setStats({
            plan: totalPlan,
            reality: totalReality,
            difference: calcDiff(totalPlan, totalReality)
        })
        // eslint-disable-next-line
    }, [records])

    const calcDiff = (plan, reality) => {
        if(reality === null) reality = 0

        return reality - plan
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.FORECASTS + "records/" + id + '/');
        if (request.status === 204) {
            setRecords(prevState => prevState.filter(item => item.id !== id))
        }
    }

    return (
        <Segment loading={isLoading} style={{ border: "none", background: "transparent", minHeight: "10rem", boxShadow: "none", padding: 0, margin: 0 }}>
            { !isLoading && 
                <>
                    <Grid centered>
                        <Grid.Row columns={3} verticalAlign='middle'>
                            <Grid.Column as={Card} style={{ textAlign: "center", padding: "0.5rem", borderRadius: 0 }}>
                                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{ t('plan') }</div>
                                <div style={{ fontSize: "2rem" }}>{ stats.plan }</div>
                            </Grid.Column>
                            <Grid.Column as={Card} style={{ textAlign: "center", padding: "0.5rem", borderRadius: 0 }}>
                                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{ t('reality') }</div>
                                <div style={{ fontSize: "2rem" }}>{ stats.reality }</div>
                            </Grid.Column>
                            <Grid.Column as={Card} style={{ textAlign: "center", padding: "0.5rem", borderRadius: 0 }}>
                                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{ t('difference') }</div>
                                <div style={{ fontSize: "2rem", color: stats.difference >= 0 ? "var(--black)" : "var(--danger)" }}>{ stats.difference }</div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider/>
                    <Grid>
                        <Grid.Row style={{ padding: 0 }} verticalAlign="middle">
                            <Grid.Column computer={8} mobile={16} tablet={16}>
                                <Header as="h3" content={t('records')}/>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16} tablet={16} style={{ textAlign: "right" }}>
                                <SuperDuperModal 
                                    header={ t('add_record') }
                                    size="small"
                                    trigger={
                                        <Button size="small" primary>{ t('add_record') }</Button>
                                    }
                                    content={
                                        <ForecastRecordForm
                                            forecast={forecast}
                                            setRecords={setRecords}
                                        />
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider/>
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader basis="150%" content={t('date_from') + " - " + t('date_to')}/>
                            <FlexHeader textAlign="center" content={t('plan')}/>
                            <FlexHeader textAlign="center" content={t('reality')}/>
                            <FlexHeader textAlign="center" content={t('difference')}/>
                            <FlexHeader basis="50%" content={t("actions")}/>
                        </FlexRow>
                        <EmptyRow length={records.length} />
                        {records.map(record => (
                            <FlexRow key={record.id} fontSize="0.9rem" borders>
                                <FlexItem bold basis="150%" content={ record.date_from + " - " + record.date_to }/>
                                <FlexItem textAlign="center" content={ record.plan_value }/>
                                <FlexItem textAlign="center" content={ record?.reality_value !== null ? record.reality_value : 0 }/>
                                <FlexItem textAlign="center" content={ 
                                    <span style={{ color: calcDiff(record.plan_value, record.reality_value) >= 0 ? "var(--dark)" : "var(--danger)" }}>
                                        { calcDiff(record.plan_value, record.reality_value) }
                                    </span>
                                }/>
                                <FlexItem basis="50%">
                                    <FlexRow background="transparent" padding="0">
                                    <FlexItem textAlign="center">
                                        <SuperDuperModal
                                            size="small"
                                            trigger={
                                                <Button icon={<Icon name="pencil-outline"/>} style={{
                                                    background: "none",
                                                    color: "var(--dark)",
                                                }} />
                                            }
                                            content={
                                                <ForecastRecordForm 
                                                    forecast={forecast}
                                                    record={record}
                                                    setRecords={setRecords}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                        <FlexItem textAlign="center">
                                            <Action as='delete' 
                                                tooltip={t('delete')} 
                                                text={t('delete_forecast_record')} 
                                                onClick={() => onDelete(record.id)}
                                            />
                                        </FlexItem>
                                    </FlexRow>
                                </FlexItem>
                            </FlexRow>
                        ))}
                    </FlexTable>
                </>
            }
        </Segment>
    );
};

export default ForecastRecordsList;