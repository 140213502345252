import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import EmailField from '@components/forms/common/EmailField';
import { Form, Button, Message, Divider, Header, Icon, Label } from 'semantic-ui-react';

const UserForm = ({ user, onConfirm, setData, setTotal, onClose }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState([]);

    const determinateProfile = () => {
        let profile = '';
        if (parseInt(user.role) === 4 && user.account !== null) {
            profile = user.account.id;
        } else if ((parseInt(user.role) === 2 || parseInt(user.role) === 3) && user.profile !== null) {
            profile = user.profile.id;
        }

        return profile;
    };

    const [form, setForm] = useState({
        email: user ? user.email : '',
        role: user ? user.role.toString() : '',
        profile: user ? determinateProfile() : '',
        groups: user ? user.groups.map((item) => item.id) : [],
    });

    const isRoleEqual = (oldRole, newRole) => {
        return parseInt(oldRole) === parseInt(newRole);
    };

    useEffect(() => {
        if (user !== undefined) {
            // check if account
            if (parseInt(user.role) === 4 && user.account !== null) {
                setProfiles((prevState) => {
                    // console.log([
                    //     ...prevState,
                    //     { key: user.account.id, value: user.account.id, text: user.account.name },
                    // ]);
                    return [...prevState, { key: user.account.id, value: user.account.id, text: user.account.name }];
                });
            } else if ((parseInt(user.role) === 2 || parseInt(user.role) === 3) && user.profile !== null) {
                setProfiles((prevState) => {
                    return [...prevState, { key: user.profile.id, value: user.profile.id, text: user.profile.name }];
                });
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        async function fetchProfiles() {
            const areRoleEqual = isRoleEqual(user?.role, form.role);
            if (user !== undefined && !areRoleEqual) {
                setForm((prevState) => ({ ...prevState, profile: '' }));
            }

            if (parseInt(form.role) === 1 || form.role === '') return;

            setLoading(true);
            let request = undefined;

            if (parseInt(form.role) === 2) {
                // candidates
                request = await requests.get(API.CANDIDATES + '?query={id, fullname}&has_user=false');
            } else if (parseInt(form.role) === 3) {
                // employees
                request = await requests.get(API.EMPLOYEES + '?query={id, fullname}&has_user=false');
            } else if (parseInt(form.role) === 4) {
                // accounts
                request = await requests.get(API.ACCOUNTS + 'business_details/?query={id, name}&has_user=false');
            }

            const newOptions = request?.response?.map((item) => {
                return {
                    key: item.id,
                    value: item.id,
                    text: parseInt(form.role) === 4 ? item.name : item.fullname,
                };
            });

            if (request !== undefined && request?.status === 200) {
                if (parseInt(user?.role) === 4 && user?.account !== null && areRoleEqual) {
                    setProfiles((prevState) => {
                        return [
                            { key: user.account.id, value: user.account.id, text: user.account.name },
                            ...newOptions,
                        ];
                    });
                } else if (
                    (parseInt(user?.role) === 2 || parseInt(user?.role) === 3) &&
                    user?.profile !== null &&
                    areRoleEqual
                ) {
                    setProfiles((prevState) => {
                        return [
                            { key: user.profile.id, value: user.profile.id, text: user.profile.name },
                            ...newOptions,
                        ];
                    });
                } else {
                    setProfiles(newOptions);
                }
            }

            setLoading(false);
        }

        fetchProfiles();
        // eslint-disable-next-line
    }, [form.role]);

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response?.email) {
            errorsArray.push(response?.email);
        }
        if (response?.detail) {
            errorsArray.push(response?.detail);
        }
        if (response?.field_name) {
            errorsArray.push(response?.field_name);
        }
        if (response?.non_field_errors) {
            errorsArray.push(response?.non_field_errors);
        }

        setErrors(errorsArray);
    };

    const setupLinkedProfile = async (user, profile) => {
        let request = undefined;
        if (parseInt(user.role) === 4) {
            // account
            request = await requests.patch(API.ACCOUNTS + "business_details/" + profile + '/?query={id, name}', {
                user: user.id,
            });
        } else if (parseInt(user.role) === 2) {
            // candidate
            request = await requests.patch(API.CANDIDATES + profile + '/?query={id, fullname}', {
                user: user.id,
            });
        } else if (parseInt(user.role) === 3) {
            // employees
            request = await requests.patch(API.EMPLOYEES + profile + '/?query={id, fullname}', {
                user: user.id,
            });
        }

        return request;
    };

    const unlinkAssignedProfile = async () => {
        if (user !== undefined) {
            // verify update action
            // unlink existing assigned profile if exist
            if (user.profile !== null) {
                // unlink profile:
                if (parseInt(user.role) === 2) {
                    // candidate
                    await requests.patch(API.CANDIDATES + user.profile.id + '/?query={id, fullname}', {
                        user: null,
                    });
                } else if (parseInt(user.role) === 3) {
                    // employees
                    await requests.patch(API.EMPLOYEES + user.profile.id + '/?query={id, fullname}', {
                        user: null,
                    });
                }
            }

            if (user.account !== null) {
                // unlink profile:
                await requests.patch(API.ACCOUNTS + "business_details/" + user.account.id + '/?query={id, name}', {
                    user: null,
                });
            }
        }
    };

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        let request = undefined;
        if (user === undefined) {
            // create
            request = await requests.post(API.USERS, {
                email: form.email,
                role: form.role,
                groups: { add: form.groups },
            });
        } else {
            // update
            let dataset = {
                role: form.role,
                groups: {
                    remove: user.groups.map((group) => group.id),
                    add: form.groups,
                },
            };

            if (user.email !== form.email) {
                dataset = {
                    ...dataset,
                    email: form.email,
                };
            }

            request = await requests.patch(API.USERS + user.id + '/', dataset);
        }

        if (request !== undefined) {
            let userInstance = undefined;
            if (request.status === 201 || request.status === 200) {
                userInstance = request.response;
                await unlinkAssignedProfile(); // only for update action to avoid conflicts while changing profiles
                if (form.profile !== '' && parseInt(form.role) !== 1) {
                    // link profile if exist
                    const requestLinkProfile = await setupLinkedProfile(request.response, form.profile);

                    if (requestLinkProfile.status === 200) {
                        // request user
                        const requestUser = await requests.get(API.USERS + request.response.id + '/');
                        if (requestUser.status === 200) {
                            userInstance = {
                                ...requestUser.response,
                                profile_id: requestLinkProfile.response.id,
                                profile:
                                    parseInt(form.role) === 4
                                        ? null
                                        : {
                                              id: requestLinkProfile.response.id,
                                              name: requestLinkProfile.response.fullname,
                                          },
                                account:
                                    parseInt(form.role) === 4
                                        ? { id: requestLinkProfile.response.id, name: requestLinkProfile.response.name }
                                        : null,
                            };
                        }
                    }
                } else if (request.status === 201 || request.status === 200) {
                    userInstance = {
                        ...request.response,
                        profile_id: null,
                        profile: null,
                        account: null,
                    };
                }

                if (user === undefined) {
                    // new user
                    onConfirm(userInstance, setData, setTotal);
                } else {
                    // existing user
                    onConfirm(userInstance, setData);
                }
                onClose();
            } else {
                handleErrors(request.response);
            }
        }

        setIsProcessing(false);
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Message error visible={errors.length > 0} header={t('error_submission_message')} list={errors} />

            <Form.Group widths="equal">
                <EmailField
                    required
                    placeholder={t('enter_email')}
                    value={form.email}
                    setValue={(e, { value }) =>
                        setForm({
                            ...form,
                            email: value,
                        })
                    }
                />

                <SuperField
                    as="choice"
                    required
                    search
                    type="roles"
                    label={t('role')}
                    value={form.role}
                    onChange={(e, { value }) => {
                        if (value === '') {
                            setForm({
                                ...form,
                                role: value,
                                profile: '',
                            });
                        } else {
                            setForm({
                                ...form,
                                role: value,
                            });
                        }
                    }}
                />
            </Form.Group>

            <Divider />
            <Header as="h3" content={t('permissions')} />
            <p style={{ marginBottom: '0.5rem' }}> {t('you_can_manage_permissions_in_settings_hint')} </p>
            <Link to={routes.SETTINGS + 'permissions'}>
                <Label basic style={{ color: 'var(--primary)' }}>
                    <Icon name="cogs" /> {t('manage_permission_group')}
                </Label>
            </Link>
            <Divider />

            <SuperField
                as="choice"
                search
                multiple
                endpoint={API.PERMISSION_GROUPS + '?query={id, name}'}
                text="name"
                label={t('permissions')}
                value={form.groups}
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        groups: value,
                    })
                }
            />

            <Divider />
            <Header as="h3" content={t('profile_assignment')} />
            <p style={{ marginBottom: '0.5rem' }}>
                {t('selected_role')}:{' '}
                <strong>
                    {form.role === '' ? (
                        '--'
                    ) : (
                        <>
                            {parseInt(form.role) === 1 && t('root')}
                            {parseInt(form.role) === 2 && t('candidate')}
                            {parseInt(form.role) === 3 && t('employee')}
                            {parseInt(form.role) === 4 && t('account')}
                        </>
                    )}
                </strong>
                <br />
                <span style={{ fontWeight: 'bold', color: 'var(--dark)' }}>
                    {parseInt(form.role) !== 1
                        ? t('select_available_profile_down_below')
                        : t('you_cant_assign_any_profile_to_this_role')}
                </span>
            </p>
            <Divider />

            <SuperField
                as="choice"
                search
                loading={loading}
                disabled={parseInt(form.role) === 1 || form.role === '' || loading}
                customOptions={profiles}
                label={t('profile')}
                value={form.profile}
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        profile: value,
                    })
                }
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <Button
                    primary
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(form.email) || isEmpty(form.role)}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    );
};

export default UserForm;
