import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime, convertTime, handleTimeInput, isTimeValid } from '@helpers/dates'
import { getRandomColor } from '@helpers/functions'
// components
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const ShiftForm = ({ onClose, setShifts, setSelectedAction }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        title: '',
        custom_id: '',
        time_from: '',
        time_to: '',
        pause_length: '30', // in minutes
        second_pause_length: '', // in minutes
        is_night_shift: false, // day shift by default
        color: getRandomColor(),
        // length: "" -> difference from Expr. "time_from - time_to"
    })

    const calculateTime = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let day = moment().format('YYYY-MM-DD')
        let start_time = start ? tzDateTime(moment(day + ' ' + start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(day + ' ' + end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day')
            }

            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    const handleBreakDeduction = (time_from, time_to) => {
        let duration = calculateTime(time_from, time_to, 'minutes')
        if (form.pause_length !== '' && parseFloat(form.pause_length) > 0) {
            duration = duration - parseFloat(form.pause_length)
        }

        if (form.second_pause_length !== '' && parseFloat(form.second_pause_length) > 0) {
            duration = duration - parseFloat(form.second_pause_length)
        }

        return duration > 0 ? parseFloat(duration / 60) : 0
    }

    const isFormValid = () => {
        if (form.title === '') return false
        if (form.time_from === '' || !isTimeValid(form.time_from)) return false
        if (form.time_to === '' || !isTimeValid(form.time_to)) return false
        return true
    }

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)
        const data = {
            ...form,
            pause_length: form.pause_length === '' ? null : form.pause_length,
            second_pause_length: form.second_pause_length === '' ? null : form.second_pause_length,
            length: handleBreakDeduction(form.time_from, form.time_to),
            time_from: convertTime(form.time_from, true),
            time_to: convertTime(form.time_to, true),
        }
        const request = await requests.post(API.ATTENDANCE_BASE + 'shifts/', data)
        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            setSelectedAction({
                id: request.response.id,
                icon: 'add-outline',
                displayName: request.response.title,
            })
            setShifts((prev) => [
                ...prev,
                {
                    ...request.response,
                    time_from: form.time_from,
                    time_to: form.time_to,
                },
            ])
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField
                    as="input"
                    required
                    autoFocus
                    width="10"
                    label={t('title')}
                    value={form.title}
                    error={errors !== null ? errors?.title?.[0] : false}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            title: value,
                            custom_id: form.custom_id === '' ? value.charAt(0) : form.custom_id,
                        }))
                    }
                />
                <SuperField
                    as="input"
                    width="6"
                    required
                    label={t('custom_id')}
                    value={form.custom_id}
                    error={errors !== null ? errors?.custom_id?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, custom_id: value }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="timepicker"
                    required
                    label={t('time_from')}
                    value={form.time_from}
                    error={!isTimeValid(form.time_from) ? true : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, time_from: handleTimeInput(value) }))}
                />
                <SuperField
                    as="timepicker"
                    required
                    label={t('time_to')}
                    value={form.time_to}
                    error={!isTimeValid(form.time_to) ? true : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, time_to: handleTimeInput(value) }))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    required
                    label={t('first_break_duration') + ' (' + t('in_minutes') + ')'}
                    value={form.pause_length}
                    error={errors !== null ? errors?.pause_length?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, pause_length: value }))}
                />
                <SuperField
                    as="input"
                    label={t('second_break_duration') + ' (' + t('in_minutes') + ')'}
                    value={form.second_pause_length}
                    error={errors !== null ? errors?.second_pause_length?.[0] : false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, second_pause_length: value }))}
                />
            </Form.Group>

            <p>
                {' '}
                {t('duration')}:{' '}
                <strong>
                    {handleBreakDeduction(form.time_from, form.time_to)} {t('hours_shortcut')}.
                </strong>{' '}
            </p>

            <SuperField
                as="checkbox"
                label={t('mark_as_night_shift')}
                checked={form.is_night_shift}
                onChange={() => setForm((prev) => ({ ...prev, is_night_shift: !form.is_night_shift }))}
            />

            <SuperField
                as="colorpicker"
                label={t('color')}
                help={t('color_to_improve_visual_in_graphs')}
                color={form.color}
                setColor={(value) => setForm((prev) => ({ ...prev, color: value }))}
            />

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} disabled={isProcessing || !isFormValid()} />
            </Form.Field>
        </Form>
    )
}

export default ShiftForm
