import React from 'react';
import { useTranslation } from "react-i18next";
// components
import { Card, Container, Segment } from 'semantic-ui-react';

const StatCard = ({ number, description, onClick }) => {
    return (
        <Card style={{ borderRadius: 0 }} onClick={onClick}>
            <Card.Content>
                <div style={{ display: "flex", marginBottom: "2rem" }}>
                    <div style={{ textAlign: "left", fontWeight: "bold", width: "70%", fontSize: "1.15rem", paddingTop: "0.25rem", color: "var(--dark)" }}>
                        { number }
                    </div>
                </div>

                <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                    { description }
                </div>
            </Card.Content>
        </Card>
    )
}

const ReactionsStats = ({ data, publisherChoiceList, total, filters, rejected, setFilters, fetchData }) => {
    const { t } = useTranslation()

    const transformNameToKey = (name) => {
        let key = name;
        key = key.replace(/\./g, "_")
        key = key.toLowerCase()

        return key
    }

    const renderOnClickAll = async () => {
        setFilters(prev => ({...prev, publishers: "", is_rejected: ""}))
        await fetchData("", true, "&recruitment=" + filters.recruitment)
    }

    const renderOnClick = async (value) => {
        setFilters(prev => ({...prev, publishers: value, is_rejected: ""}))
        await fetchData("&publishers=" + value + "&is_rejected=", true)
    }

    const renderOnClickRejected = async () => {
        setFilters(prev => ({...prev, publishers: "", is_rejected: true}))
        await fetchData("", true, "&is_rejected=" + true + "&recruitment=" + filters.recruitment)
    }

    return (
        <Container fluid>
            <Segment style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none", marginBottom: "1rem" }}>
                <Card.Group stackable doubling itemsPerRow={publisherChoiceList.length + 2}>
                    <StatCard
                        number={total}
                        description={t("all")}
                        onClick={() => renderOnClickAll()}
                    />
                    {publisherChoiceList.map((publisher, index) => (
                        <StatCard
                            key={index}
                            number={data?.responses_count?.[transformNameToKey(publisher.text)]}
                            description={publisher.text}
                            onClick={() => renderOnClick(publisher.value)}
                        />
                    ))}
                    <StatCard
                        number={rejected?.count || 0}
                        description={t("rejected")}
                        onClick={() => renderOnClickRejected()}
                    />
                </Card.Group>
            </Segment>
        </Container>
    );
};

export default ReactionsStats;