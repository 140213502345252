import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import moment from 'moment';
// store
import { API } from '@store/config';
//components
import SuperField from '@components/forms/SuperField';
import CustomFormDivider from '@components/general/CustomFormDivider';
import { Divider, Form  } from 'semantic-ui-react';

const TerminationFilterForm = ({ filters, setFilters }) => {
    const { t } = useTranslation()
    const [invalidDateRange, setInvalidDateRange] = useState(false)

    // Validate Form
    useEffect( () => {
        if( filters.notice_period_after !== "" && filters.notice_period_before){
            if( moment(filters.notice_period_before).isAfter(filters.notice_period_after) ){
                setInvalidDateRange(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRange(false)
            }
        }
        // eslint-disable-next-line
    }, [filters])

    return (
        <>
            <SuperField as="choice"
                search
                text="fullname"
                label={t('employee')}
                value={filters.employee}
                onChange={ (e, {value}) => setFilters({ ...filters, employee: value }) }
                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
            />

            <Divider/>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    type="contract_termination_types"
                    label={t('type')}
                    value={filters.type}
                    onChange={(e, { value }) => setFilters({ ...filters, type: value })}
                />
                <SuperField as="choice"
                    type="contract_termination_actors"
                    label={t('terminated_by')}
                    value={filters.terminated_by}
                    onChange={(e, { value }) => setFilters({ ...filters, terminated_by: value })}
                />

            </Form.Group>

            <CustomFormDivider
                header={t('notice_period_range')}
                divider={true}
                content={
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={filters.notice_period_after}
                            onChange={ (e, { value }) => setFilters({...filters, notice_period_after: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRange}
                            value={filters.notice_period_before}
                            onChange={ (e, { value }) => setFilters({...filters, notice_period_before: value})}
                        />
                    </Form.Group>
                }
            />
        </>
    );
};

export default TerminationFilterForm;