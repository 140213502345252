import React from 'react';
import { Link } from 'react-router-dom';
//store
import { routes } from '@store/routes';
//components
import { List, Grid } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';

const BenefitAssignee = ({ employees }) => {
    return (
        <>
            {employees?.map((item, index) =>
                <Grid textAlign='left' columns={2} as={Link} to={routes.EMPLYOEES_DETAIL + item.id} key={index}>
                    <Grid.Row>
                    <Grid.Column computer={2} tablet={2} mobile={2}>
                        <AvatarIcon size="30" name={item.fullname} src={item.profile_picture} />

                    </Grid.Column>
                    <Grid.Column computer={6} tablet={6} mobile={6}>
                        <List relaxed>
                        <List.Item>
                            <List.Content>
                            <List.Header as='a'>{item.fullname}</List.Header>
                            <List.Description>
                                {item.position?.name}
                            </List.Description>
                            </List.Content>
                        </List.Item>
                        </List>

                    </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </>
    )
}

export default BenefitAssignee