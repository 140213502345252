import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
//store
import { icons } from '@icons'
import { routes } from '@routes'
import { requests } from '@helpers/requests'
import { API } from '@store/config'
import { useHasPermissions } from '@helpers/hooks'
//components
import BreadcrumbNav from '@components/BreadcrumbNav'
import Loading from '@components/general/Loading'
import { Container, Grid, Tab } from 'semantic-ui-react'
import CanView from '@components/perms/CanView'
// related
import ProfileHeader from './ProfileHeader'
import IdentityDocuments from './IdentityDocuments'
import ProfileCardInfoTab from './ProfileCardInfoTab'
import ProfileCardWorkTab from './ProfileCardWorkTab'
import FilesTab from '@components/documents/FilesTab'
import ContractDocuments from '../../documents/ContractDocuments'

const ProfileCard = ({ isCandidate, modalID }) => {
    const { t } = useTranslation()
    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    // eslint-disable-next-line
    const [id] = modalID === undefined ? useState(params.id) : useState(modalID)
    const endpoint = isCandidate ? API.CANDIDATES : API.EMPLOYEES

    const [data, setData] = useState({})
    const [view, setView] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const canViewEmployeeTab = useHasPermissions([
        'common.c_view_all_employees',
        'common.c_view_user_employees',
        'common.c_manage_only_agency_employees',
        'common.c_manage_only_internal_employees',
        'common.c_manage_only_external_employees',
    ])

    const canManage = useHasPermissions(
        isCandidate
            ? ['common.c_manage_all_candidates']
            : ['common.c_manage_all_employees', 'company.c_manage_only_assigned_unit_employees']
    )

    const breadcrumb_items =
        isCandidate === true
            ? [
                  { name: t('candidates'), icon: icons.CANDIDATES, href: routes.CANDIDATES_LIST },
                  { name: data.fullname, icon: 'user outline', href: '' },
              ]
            : [
                  // Put: name, icon and href data inside to create breadcrumb item
                  { name: t('employees'), icon: icons.EMPLOYEES, href: routes.EMPLYOEES_LIST },
                  { name: data.fullname, icon: 'user outline', href: '' },
              ]

    useEffect(() => {
        async function fetchData() {
            const request = await requests.get(endpoint + id + '/')

            if (request.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if (request.status === 200) {
                setData(request.response)
            }

            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('view')
            setView(param !== null ? param : '')

            setIsLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [id, history])

    const panes = [
        {
            menuItem: canViewEmployeeTab && t('personal'),
            render: () => (
                <ProfileCardInfoTab data={data} setData={setData} patchEndpoint={endpoint} canManage={canManage} />
            ),
        },
        { menuItem: t('identity'), render: () => <IdentityDocuments profile={data?.id} canManage={canManage} /> },
        canManage && {
            menuItem: t('work'),
            render: () => (
                <ProfileCardWorkTab
                    data={data}
                    setData={setData}
                    patchEndpoint={endpoint}
                    isCandidate={isCandidate}
                    canManage={canManage}
                />
            ),
        },
        {
            menuItem: t('documents'),
            render: () => (
                <FilesTab
                    data={data}
                    id={data.id}
                    module={'profile'}
                    updateEndpoint={endpoint}
                    getEndpoint={endpoint}
                    canManage={canManage}
                />
            ),
        },
        canManage && {
            menuItem: t('contract_documents'),
            render: () => (
                <ContractDocuments
                    data={data}
                    id={data.id}
                    module={'profile_contracts'}
                    // updateEndpoint={endpoint}
                    getEndpoint={API.CONTRACTS + '?employee=' + id}
                    canManage={canManage}
                />
            ),
        },
        // { menuItem: t('activity'), render: () => <ProfileCardInfoTab data={data} /> },
        // <ProfileCardHeader data={data} setData={setData} patchEndpoint={endpoint} />
    ]

    return (
        <CanView
            permissions={
                isCandidate
                    ? [
                          'common.c_view_all_candidates',
                          'common.c_view_user_profile',
                          'company.c_manage_only_assigned_unit_employees',
                      ]
                    : [
                          'common.c_view_all_employees',
                          'common.c_view_user_employees',
                          'company.c_manage_only_assigned_unit_employees',
                          'common.c_manage_only_agency_employees',
                          'common.c_manage_only_internal_employees',
                          'common.c_manage_only_external_employees',
                      ]
            }
            redirect
        >
            {modalID === undefined && <BreadcrumbNav items={breadcrumb_items} style={{ marginBottom: 0 }} />}
            {isLoading ? (
                <Loading />
            ) : (
                <Container fluid>
                    <Grid>
                        <ProfileHeader data={data} setData={setData} patchEndpoint={endpoint} canManage={canManage} />
                        <Grid.Row style={{ paddingTop: '2rem' }}>
                            <Grid.Column computer={16} tablet={16} mobile={16}>
                                <Tab
                                    menu={{ secondary: true, pointing: true }}
                                    panes={panes}
                                    defaultActiveIndex={view === 'identity' ? 1 : 0}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            )}
        </CanView>
    )
}

export default ProfileCard
