import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import ListView from '@components/ListView';
import { Rating, Label } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
import SuperField from '@components/forms/SuperField';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
// specific comps
import RecruitersList from './RecruitersList';
import StartOnboarding from '../forms/StartOnboarding';
import { MoveStageForm } from "../forms/MoveStageForm";
import NewCandidateForm from '../forms/NewCandidateForm';
import HiringRoomStats from '../../views/HiringRoomStats';
import FullHistoryLog from '../activity-log/FullHistoryLog';
import ListSelectedCandidates from './ListSelectedCandidates';
import RejectBlackListForm from '../forms/RejectBlackListForm';
import ContactCell from '../../../candidates/components/tables/ContactCell';

const StagesListView = ({ params, room, stages, setStages, rejected, setRejected, blacklisted, setBlacklisted, totalCandidates, setListView, setIsAdded }) => {
    const { t } = useTranslation();

    const allow_jump_over_stages = useSelector(state => state.allow_jump_over_stages)
    const reduxUser = useSelector(state => state.user.profile_id)

    const [selectedStage, setSelectedStage] = useState("all")

    const isLastStage = () => {
        return stages?.[stages?.length - 1]?.id === selectedStage
    }

    const onCreate = async (response, setData, setTotal) => {
        if(response.length > 0){
            for(let i = 0; i < response.length; i++){
                const dataObject = {
                    candidate: response[i].id,
                    recruiter: reduxUser,
                    recruitment: params.id
                }
    
                const result = await requests.post(API.RECRUITMENT_ASSIGNMENTS, dataObject)
                if (result.status === 201) {
                    setData((prev) => [{...response[i], candidate_recruiters: [result.response]}, ...prev])
                    setTotal((prev) => prev + 1)
                } else{
                    setData((prev) => [response[i], ...prev])
                    setTotal((prev) => prev + 1)
                }
                
            } 
        } else{
            const dataObject = {
                candidate: response.id,
                recruiter: reduxUser,
                recruitment: params.id
            }

            const result = await requests.post(API.RECRUITMENT_ASSIGNMENTS, dataObject)
            if (result.status === 201) {
                setData((prev) => [{...response, candidate_recruiters: [result.response]}, ...prev])
                setTotal((prev) => prev + 1)
            } else{
                setData((prev) => [response, ...prev])
                setTotal((prev) => prev + 1)
            }
        }
        setIsAdded(true)
    }

    const PutAvatar = ({ row }) => {
        return (
            <FlexItem basis="80%">
                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                    <FlexItem basis="10%">
                        <AvatarIcon size={35} name={row?.fullname} src={row?.profile_picture}/>
                    </FlexItem>
                    <FlexItem>
                        <div style={{ paddingLeft: "0.5rem" }}>
                            <Link to={routes.CANDIDATES_CARD + row.id} target="_blank"><strong>{row?.fullname}</strong></Link>
                            <div>
                                <Rating rating={row?.average_rating || 0} maxRating={5} disabled />
                                {row?.average_rating || ' 0.0'}
                            </div>
                        </div>
                    </FlexItem>
                </FlexRow>
            </FlexItem>
        );
    }

    const actionUp = (candidate, setData) => {
        const roomID = params.id
        const currentID = candidate?.current_stage?.id;
        
        return (
            <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                <Action
                    as="modal"
                    key={1}
                    type="icon"
                    className="stage-actions"
                    disabled={candidate?.current_stage?.id === stages[stages?.length - 1]?.id}
                    iconColor='var(--dark)'
                    paddingLeft="0.5rem"
                    icon="chevron-up-outline"
                    tooltip={t('stage_up')}
                    modalSize="tiny"
                    header={t('update_candidate_stage')}
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={roomID}
                            stages={stages}
                            candidateID={candidate.id}
                            isDown={false}
                            onConfirm={
                                (response) => {
                                    setStages(prev => prev.filter(item => {
                                        if (item.id === currentID) {
                                            item.candidate_count = item.candidate_count - 1
                                        }
                                        else if (item.id === response.id) {
                                            item.candidate_count = item.candidate_count + 1
                                        }
                    
                                        return item
                                    }))
                                    if(selectedStage !== "all"){
                                        setData(prev => prev.filter(data => data.id !== candidate.id))
                                    } else {
                                        setData(prev => prev.filter(data => {
                                            if (data.id === candidate.id) {
                                                data.current_stage = response
                                            }
                        
                                            return data
                                        }))
                                    }
                                }
                            }
                        />
                    }
                />
            </CanView>
        )
    }

    const actionDown = (candidate, setData) => {
        const currentID = candidate?.current_stage?.id;
        return (
            <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                <Action
                    as="modal"
                    key={2}
                    type="icon"
                    className="stage-actions"
                    disabled={candidate?.current_stage?.id === stages[0]?.id}
                    iconColor='var(--dark)'
                    paddingLeft="0.5rem"
                    icon="chevron-down-outline"
                    tooltip={t('stage_down')}
                    modalSize="tiny"
                    header={t('downgrade_candidate_stage')}
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={params.id}
                            stages={stages}
                            isDown={true}
                            candidateID={candidate?.id}
                            onConfirm={
                                (response) => {
                                    setStages(prev => prev.filter(item => {
                                        if (item.id === currentID) {
                                            item.candidate_count = item.candidate_count - 1
                                        }
                                        else if (item.id === response.id) {
                                            item.candidate_count = item.candidate_count + 1
                                        }
                    
                                        return item
                                    }))
                                    if(selectedStage !== "all"){
                                        setData(prev => prev.filter(data => data.id !== candidate.id))
                                    }
                                    else{
                                        setData(prev => prev.filter(data => {
                                            if (data.id === candidate.id) {
                                                data.current_stage = response
                                            }
                        
                                            return data
                                        }))
                                    }
                                }
                            }
                        />
                    }
                />
            </CanView>
        )
    }

    const actionSelect = (candidate, setData) => {
        const currentID = candidate?.current_stage?.id;
        return (
            <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                <Action
                    as="modal"
                    key={2}
                    type="icon"
                    className="stage-actions"
                    iconColor='var(--dark)'
                    paddingLeft="0.5rem"
                    paddingBottom="0"
                    paddingTop="0"
                    icon="swap-vertical-outline"
                    tooltip={t('select_stage')}
                    header={t('move_candidate_to_stage')}
                    modalSize="tiny"
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={params.id}
                            jumpStage={true}
                            candidateID={candidate.id}
                            stages={stages}
                            position={room.job_position}
                            onConfirm={
                                (response) => {
                                    if(selectedStage !== "all"){
                                        setData(prev => prev.filter(data => data.id !== candidate.id))
                                    }
                                    else{
                                        setData(prev => prev.filter(data => {
                                            if (data.id === candidate.id) {
                                                data.current_stage = response
                                            }
                        
                                            return data
                                        }))
                                    }
                                    setStages(prev => prev.filter(item => {
                                        if (item.id === currentID) {
                                            item.candidate_count = item.candidate_count - 1
                                        }
                                        else if (item.id === response.id) {
                                            item.candidate_count = item.candidate_count + 1
                                        }
                    
                                        return item
                                    }))
                                }
                            }
                        />
                    }
                />
            </CanView>
        )
    }

    async function performSelectionAction(type, note, setData, selectedCandidates) {
        let endpoint = ""
        if (type === "rejected") endpoint = API.CANDIDATES + "rejections/";
        if (type === "blacklisted") endpoint = API.CANDIDATES + "blacklist_records/";

        for (let i = 0; i < selectedCandidates.length; i++) {
            if ((rejected?.results?.filter(item => item === selectedCandidates[i].id).length === 0) && (blacklisted?.results?.filter(item => item === selectedCandidates[i].id).length === 0)) {
                
                let currentStage = null;
                currentStage = selectedCandidates[i].current_stage.id;

                let data = {
                    profile: selectedCandidates[i].id,
                    note: note || ""
                }

                if (type === "rejected") {
                    data['recruitment'] = params.id
                    data['rejected_by_candidate'] = false
                }

                const request = await requests.post(endpoint, data)
                if (request.status === 201) {
                    setData(prev => prev.filter(data => data.id !== selectedCandidates[i].id))

                    setStages(prev => prev.filter(item => {
                        if (item.id === currentStage) {
                            item.candidate_count = item.candidate_count - 1
                        }
    
                        return item
                    }))

                    if (type === "rejected") {
                        setRejected(prev => ({
                            ...prev,
                            count: prev.count + 1,
                            results: [...prev.results, selectedCandidates[i].id]
                        }))
                    }

                    if (type === "blacklisted") {
                        setBlacklisted(prev => ({
                            ...prev,
                            count: prev.count + 1,
                            results: [...prev.results, selectedCandidates[i].id]
                        }))
                    }
                }
            }
        }

        // setSelectedCandidates([])
        // setCheckedAll(false)
    }

    const acceptAsEmployee = async (id, setData) => {
        const request = await requests.patch(API.CANDIDATES + id + "/convert/", { profile_type: 2 });

        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('candidate_was_accepted_as_employee'),
                animation: 'bounce',
                time: 5000,
            });
            setData(prevState => (
                prevState.filter(candidate => {
                    if (candidate.id === id) {
                        candidate.profile_type = 2
                    }
                    return candidate
                })
            ))
        }
    }

    async function convertToEmployee(selectedCandidates, setSelectedCandidates) {

        for (let i = 0; i < selectedCandidates.length; i++) {
            if (selectedCandidates[i].current_stage.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id && selectedCandidates[i].profile_type === 1 && selectedCandidates[i].onboardings.length === 0) {
                acceptAsEmployee(selectedCandidates[i].id)
            }
        }

        setSelectedCandidates([])
    }

    async function launchOnboarding(template, deadline, selectedCandidates, setSelectedCandidates, setData) {

        for (let i = 0; i < selectedCandidates.length; i++) {
            if (selectedCandidates[i].profile_type === 1) {
                acceptAsEmployee(selectedCandidates[i].id)
            }


            const requestPatch = await requests.post(API.ONBOARDING_CHECKLIST_CONFIG + template + "/instantiate/", { employee: selectedCandidates[i].id, deadline: deadline })

            if (requestPatch.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('started_onboarding'),
                    animation: 'bounce',
                    time: 5000,
                });
                setData(prevState => (
                    prevState.filter(candidate => {
                        if (candidate.id === selectedCandidates[i].id) {
                            candidate.onboardings = requestPatch.response
                        }
                        return candidate
                    })
                ))
            }
            else {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('error'),
                    description: t('employee_can_not_be_onboarded'),
                    animation: 'bounce',
                    time: 5000,
                });
            }
        }
        setSelectedCandidates([])
    }

    function removeSelectedCandidate(candidate, selectedCandidates, setSelected) {
        setSelected(selectedCandidates.filter(item => item.id !== candidate.id))
    }

    return (
        <>
            <ListView
                as="table"
                allowSearch
                allowSelection
                actionsCellWidth="2"
                endpoint={API.CANDIDATES}
                initialFilters={{
                    recruitment: params.id,
                    exclude_rejected: true,
                    exclude_blacklisted: true,
                    include_employees: true,
                    current_stage: "",
                    gdpr_valid_until_after: "",
                    gdpr_valid_until_before: "",
                    gender: "",
                    assigned_to_recruiter: ""
                }}
                query={"&query={id, fullname, contacts, onboardings{id, title, status_display}, profile_picture, current_stage, average_rating, date_of_assignment, current_stage_date, is_active, is_foreigner, profile_type}" }                
                renderFilterFields={(filters, setFilters) => (
                    <>
                        {/* <Form.Group widths={"equal"}>
                            <SuperField 
                                as="datepicker"
                                clearable
                                label={t('gdpr_valid_until_after')}
                                value={filters.gdpr_valid_until_after}
                                onChange={(e, { value }) => setFilters({ 
                                    ...filters, 
                                    gdpr_valid_until_after: value 
                                })}
                            />
                            <SuperField 
                                as="datepicker"
                                clearable
                                label={t('gdpr_valid_until_before')}
                                value={filters.gdpr_valid_until_before}
                                onChange={(e, { value }) => setFilters({ 
                                    ...filters, 
                                    gdpr_valid_until_before: value 
                                })}
                            />
                        </Form.Group> */}

                        <SuperField
                            as="choice"
                            search
                            label={t('recruiter')}
                            endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                            text="fullname"
                            value={filters.assigned_to_recruiter}
                            onChange={(e, { value }) => setFilters({
                                ...filters,
                                assigned_to_recruiter: value
                            })}
                        />
                        <SuperField as="checkbox"
                            label={t('assigned_to_me')}
                            checked={filters.assigned_to_recruiter === reduxUser}
                            onChange={() => setFilters({
                                ...filters,
                                assigned_to_recruiter: filters.assigned_to_recruiter === reduxUser ? "" : reduxUser
                            })}
                        />

                        <SuperField as="choice"
                            type="genders"
                            label={t('gender')}
                            value={filters.gender}
                            onChange={(e, { value }) => setFilters({ 
                                ...filters, 
                                gender: value 
                            })}
                        />
                        
                    </>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('add_candidate'),
                        modalSize: 'small',
                        permissions: ['candidates.c_manage_recruitment'],
                        modal: (
                            <NewCandidateForm
                                btnName={t('confirm')}
                                roomId={params.id}
                                onConfirm={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                            />
                        ),
                    },
                    {
                        as: "link",
                        type: "detail",
                        icon: "eye-outline",
                        name: t('view_detail'),
                        redirect: (item) => routes.HIRING_ROOM + params.id + routes.CANDIDATES_DETAIL + item.id,
                    },
                    {
                        as: "modal",
                        type: "custom",
                        icon: "albums-outline",
                        name: t('history'),
                        modalSize: "tiny",
                        modal: (candidate) => <FullHistoryLog room={params.id} candidate={candidate.id} />
                    },
                    {
                        as: "confirm",
                        type: "custom",
                        name: t('accept_as_employee'),
                        icon: "id-card-outline",
                        text: t('confirm_as_employee'),
                        onClick: async (item, setData) => acceptAsEmployee(item.id, setData),
                        isHidden: (item) => item.profile_type === 2 || !isLastStage(),
                    },
                    {
                        as: "modal",
                        type: "custom",
                        name: t('start_onboarding'),
                        icon: "list-outline",
                        modal: (item, setData) => <StartOnboarding 
                            id={item.id} 
                            setCandidates={setData} 
                            acceptAsEmployee={acceptAsEmployee} 
                            converted={item.profile_type === 1 ? 'no' : 'yes'} 
                        />,
                        isHidden: (item) => !isLastStage(),
                    },
                ]}
                bulkActions={(selected, setData, setTotal, filters, queryParams, setSelected) => [
                    {
                        as: "modal",
                        name: t('reject_candidate'),
                        icon: "close-circle-outline",
                        modal: <RejectBlackListForm type="rejected" setData={setData} onPerformAction={performSelectionAction} selectedCandidates={selected} />
                    },
                    {
                        as: "modal",
                        name: t('blacklist_candidate'),
                        icon: "warning-outline",
                        modal: <RejectBlackListForm type="blacklisted" setData={setData} onPerformAction={performSelectionAction} selectedCandidates={selected} />
                    },
                    isLastStage() && {
                        as: "modal",
                        name: t('start_onboarding'),
                        icon: "list-outline",
                        modal: <ListSelectedCandidates
                            type={'onboarding'}
                            room={room}
                            convertToEmployee={convertToEmployee}
                            launchOnboarding={launchOnboarding}
                            selectedCandidates={selected}
                            setSelected={setSelected}
                            removeSelectedCandidate={removeSelectedCandidate}
                            setData={setData}
                        />
                    },
                    isLastStage() && {
                        as: "modal",
                        name: t('accept_as_employee'),
                        icon: "id-card-outline",
                        modal: <ListSelectedCandidates
                            type={'convert'}
                            room={room}
                            convertToEmployee={convertToEmployee}
                            launchOnboarding={launchOnboarding}
                            selectedCandidates={selected}
                            setSelected={setSelected}
                            removeSelectedCandidate={removeSelectedCandidate}
                            setData={setData}
                        />
                    },
                    // {
                    //     as: "modal",
                    //     name: t('update_candidate_stage'),
                    //     icon: "chevron-up-outline",
                    //     modal: 
                    //         <MoveStageForm
                    //             roomID={params.id}
                    //             stages={stages}
                    //             isDown={false}
                    //             selectedCandidates={selected}
                    //             onConfirm={
                    //             (candidate, current, stage, response) => {
                    //                 setSelected(0)
                    //                 setStages(prev => prev.filter(item => {
                    //                     if (item.id === current) {
                    //                         item.candidate_count = item.candidate_count - 1
                    //                     }
                    //                     else if (item.id === response.id) {
                    //                         item.candidate_count = item.candidate_count + 1
                    //                     }
                    
                    //                     return item
                    //                 }))
                    //                 if(selectedStage !== "all"){
                    //                     setData(prev => prev.filter(data => data.id !== candidate))
                    //                 }
                    //                 else{
                    //                     setData(prev => prev.filter(data => {
                    //                         if (data.id === candidate) {
                    //                             data.current_stage = response
                    //                         }
                        
                    //                         return data
                    //                     }))
                    //                 }
                    //             }
                    //         }
                    //         />
                    // },
                    // {
                    //     as: "modal",
                    //     name: t('move_candidate_to_stage'),
                    //     icon: "swap-vertical-outline",
                    //     disabled: () => !allow_jump_over_stages,
                    //     modal: 
                    //         <MoveStageForm
                    //             roomID={params.id}
                    //             stages={stages}
                    //             jumpStage={true}
                    //             selectedCandidates={selected}
                    //             onConfirm={
                    //             (candidate, current, stage, response) => {
                    //                 setStages(prev => prev.filter(item => {
                    //                     if (item.id === current) {
                    //                         item.candidate_count = item.candidate_count - 1
                    //                     }
                    //                     else if (item.id === response.id) {
                    //                         item.candidate_count = item.candidate_count + 1
                    //                     }
                    
                    //                     return item
                    //                 }))
                    //                 if(selectedStage !== "all"){
                    //                     setData(prev => prev.filter(data => data.id !== candidate))
                    //                 }
                    //                 else{
                    //                     setData(prev => prev.filter(data => {
                    //                         if (data.id === candidate) {
                    //                             data.current_stage = response
                    //                         }
                        
                    //                         return data
                    //                     }))
                    //                 }
                    //             }
                    //         }
                    //         />
                    // },
                    // {
                    //     as: "modal",
                    //     name: t('downgrade_candidate_stage'),
                    //     icon: "chevron-down-outline",
                    //     modal: 
                    //         <MoveStageForm
                    //             roomID={params.id}
                    //             stages={stages}
                    //             isDown={true}
                    //             selectedCandidates={selected}
                    //             onConfirm={
                    //             (candidate, current, stage, response) => {
                    //                 setStages(prev => prev.filter(item => {
                    //                     if (item.id === current) {
                    //                         item.candidate_count = item.candidate_count - 1
                    //                     }
                    //                     else if (item.id === response.id) {
                    //                         item.candidate_count = item.candidate_count + 1
                    //                     }
                    
                    //                     return item
                    //                 }))
                    //                 if(selectedStage !== "all"){
                    //                     setData(prev => prev.filter(data => data.id !== candidate))
                    //                 }
                    //                 else{
                    //                     setData(prev => prev.filter(data => {
                    //                         if (data.id === candidate) {
                    //                             data.current_stage = response
                    //                         }
                        
                    //                         return data
                    //                     }))
                    //                 }
                    //             }
                    //         }
                    //         />
                    // }
                ]}
                renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                    <HiringRoomStats
                        data={data}
                        rejected={rejected}
                        blacklisted={blacklisted}
                        total={totalCandidates}
                        params={params}
                        filters={filters}
                        setFilters={setFilters}
                        fetchData={fetchData}
                        stages={stages}
                        setListView={setListView}
                        setSelectedStage={setSelectedStage}
                    />
                )}
                tableHeaders={[
                    { title: t('name') },
                    { title: t('contact') },
                    { title: t('stage') },
                    { title: t('applied') },
                    { title: t('recruiters') },
                    isLastStage() && { title: t('status') },
                ]}
                renderCells={(candidate, setData) => ([
                    { content: <PutAvatar row={candidate} /> },
                    { content: <ContactCell row={{ phone: candidate?.contacts?.[0]?.phone, email: candidate?.contacts?.[0]?.email}} /> },
                    { content: 
                        <>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ display: "inline-block" }}>
                                    {actionUp(candidate, setData)}
                                    <br />
                                    { allow_jump_over_stages && actionSelect(candidate, setData)}
                                    { allow_jump_over_stages && <br /> }
                                    {actionDown(candidate, setData)}
                                </span>
                                <span style={{ textAlign: "left", display: "flex", flexDirection: "column", alignItems: "flex-start", paddingTop: "1.2rem" }}>
                                    <Label
                                    size="medium"
                                    style={{ background: candidate?.current_stage?.color }}>
                                    {candidate?.current_stage?.name}
                                    </Label>
                                    <small>{moment(candidate?.current_stage_date).format("DD.MM.YYYY HH:mm")}</small>
                                </span>
                            </div>
                        </>
                    },
                    { content: moment(candidate?.date_of_assignment).format("DD.MM.YYYY HH:mm") },
                    { content: 
                        <RecruitersList 
                            candidate={candidate.id} 
                            recruitment={params.id} 
                            recruiters={candidate?.candidate_recruiters} 
                            room={room} 
                            key={candidate.id}
                        />
                    },
                    isLastStage() && { content: 
                        <>
                            { candidate.profile_type === 1 && candidate.current_stage?.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id ?
                                t('non_converted_candidate')
                                : candidate.profile_type === 2 && candidate.current_stage?.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id && candidate.onboardings.length !== 0 ?
                                    t('onboarding') + ' - ' + candidate?.onboardings[0]?.status_display
                                    : candidate.profile_type === 2 && candidate.current_stage?.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id && candidate.onboardings.length === 0 ?
                                        t('converted')
                                        :
                                        candidate?.profile_type === 1
                                        && <div style={{ fontWeight: 'lighter', fontSize: 12 }}>{t('candidate')}</div>
                            }
                        </>
                    },
                ])}
            />
        </>
    );
};

export default StagesListView;