import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, Link } from 'react-router-dom'
//store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { routes } from '@routes'
import { useHasPermissions } from '@helpers/hooks'
//components
import Icon from '@components/Icon'
import AvatarIcon from '@components/AvatarIcon'
import { Button, Grid, Header } from 'semantic-ui-react'
import CanView from '@components/perms/CanView'
import Action from '@components/general/Action'
import Address from '@components/forms/Address'
import DocPreview from '@components/DocPreview'
import SuperDuperModal from '@components/modals/SuperDuperModal'
// import ProfileHistory from './ProfileHistory';
import ProfileCardHeaderForm from './forms/ProfileCardHeaderForm'
import LabelsList from '@components/lists/LabelsList'
import StartOnOff from '../../../pages/dashboard/hr/onboarding/components/forms/StartOnOff'

const ProfileHeader = ({ data, setData, patchEndpoint, canManage }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const canViewUnit = useHasPermissions(['company.c_view_units'])
    // const canManage = useHasPermissions(['common.c_manage_all_employees', 'common.c_manage_user_employees'])

    const uploadPicture = async (image) => {
        const formData = new FormData()
        formData.append('profile_picture', image)
        const result = await requests.post(patchEndpoint + data.id + '/picture/', formData)

        return result
    }

    const deletePicture = async () => {
        const result = await requests.del(patchEndpoint + data.id + '/picture/')

        return result
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.EMPLOYEES + id + '/')

        if (request.status === 204) {
            history.push(routes.EMPLYOEES_LIST)
        }
    }

    const phones = data.contacts.filter((item) => item.phone !== '')
    const emails = data.contacts.filter((item) => item.email !== '')

    return (
        <>
            <Grid.Row columns={2}>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Header as="h3">
                        <div>
                            {data.is_active ? (
                                <>
                                    {' '}
                                    <Icon
                                        style={{ marginRight: '0.8rem', color: 'var(--success)' }}
                                        name="ellipse"
                                    />{' '}
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>{t('active')}</span>
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <Icon
                                        style={{ marginRight: '0.8rem', color: 'var(--danger)' }}
                                        name="ellipse"
                                    />{' '}
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>{t('inactive')}</span>
                                </>
                            )}
                            <span style={{ paddingLeft: '1rem' }}>
                                {data?.gdpr_valid_until ? (
                                    <>
                                        {' '}
                                        <Icon
                                            style={{ marginRight: '0.8rem', color: 'var(--success)' }}
                                            name="ellipse"
                                        />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {t('gdpr_valid_until')}: <strong>{data?.gdpr_valid_until}</strong>
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        <Icon
                                            style={{ marginRight: '0.8rem', color: 'var(--danger)' }}
                                            name="ellipse"
                                        />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {t('gdpr_required')}
                                        </span>
                                    </>
                                )}
                            </span>
                            <span style={{ paddingLeft: '1rem' }}>
                                {data?.user ? (
                                    <>
                                        {' '}
                                        <Icon
                                            style={{ marginRight: '0.8rem', color: 'var(--success)' }}
                                            name="person"
                                        />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {t('has_user')}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        <Icon
                                            style={{ marginRight: '0.8rem', color: 'var(--danger)' }}
                                            name="person-outline"
                                        />
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                                            {t('do_not_have_user')}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </Header>
                </Grid.Column>
                <Grid.Column textAlign="right" mobile={16} tablet={8} computer={4}>
                    {data?.cv && (
                        <SuperDuperModal
                            size="large"
                            trigger={
                                <Button basic size="tiny" style={{ marginRight: '1rem' }}>
                                    <Icon name="document-text-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.05rem' }}>{t('show_CV')}</span>
                                </Button>
                            }
                            content={<DocPreview uri={data.cv} />}
                        />
                    )}
                    <Action
                        as="modal"
                        type="icon"
                        modalSize="tiny"
                        iconColor="var(--primary)"
                        icon="qr-code-outline"
                        header={<div style={{ textAlign: 'center' }}> {t('attendance_qr_code')} </div>}
                        tooltip={t('attendance_qr_code')}
                        modal={
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    src={`https://chart.googleapis.com/chart?cht=qr&chl=${data.attendance_code}&chs=250x250&choe=UTF-8&chld=L|2`}
                                    rel="nofollow"
                                    alt="qr code"
                                />{' '}
                                <br />
                                <Header as="h1">{data.attendance_code}</Header>
                            </div>
                        }
                    />
                    {canManage && (
                        <Action
                            as="modal"
                            type="icon"
                            iconColor="black"
                            icon="pencil-outline"
                            tooltip={t('edit_header')}
                            header={t('edit_header')}
                            modal={
                                <ProfileCardHeaderForm data={data} onConfirm={setData} patchEndpoint={patchEndpoint} />
                            }
                        />
                    )}
                    {canManage && (
                        <Action
                            as="modal"
                            type="icon"
                            iconColor="black"
                            icon="barcode-outline"
                            tooltip={'Start On/Off-Boarding'}
                            modal={<StartOnOff id={data.id} btnName="Start" />}
                        />
                    )}
                    <CanView permissions={['common.c_delete_all_employees']}>
                        <Action
                            as="delete"
                            tooltip={t('delete')}
                            text={t('delete_employee_message')}
                            onClick={() => onDelete(data.id)}
                        />
                    </CanView>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                    <AvatarIcon
                        size={150}
                        name={data.fullname ? data.fullname : 'undefined'}
                        src={data?.profile_picture}
                        handleUpload={uploadPicture}
                        handleDelete={deletePicture}
                        uploadable={canManage}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={10}>
                    <Header as="h2" style={{ marginBottom: '3px' }}>
                        {' '}
                        {data?.titles_before} {data.fullname} {data?.titles_after}{' '}
                    </Header>
                    {data?.active_contracts.map((contract, index) =>
                        data?.active_contracts.length === index + 1 ? (
                            <span>{contract.work_position_title}</span>
                        ) : (
                            <span>
                                {contract.work_position_title} {', '}
                            </span>
                        )
                    )}
                    <br />
                    {data?.profile_type !== 1 && (
                        <Grid.Row style={{ paddingTop: '5px' }}>
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                <Icon style={{ marginRight: '0.5rem' }} name="briefcase-outline" />
                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                    {data?.active_contracts.length === 0 && data?.profile_type !== 1 && (
                                        <strong style={{ fontSize: '0.9rem', color: 'var(--danger)' }}>
                                            {t('no_active_contract')}
                                        </strong>
                                    )}
                                    {data?.active_contracts.map((contract, index) =>
                                        data?.active_contracts.length === index + 1 ? (
                                            <strong style={{ fontSize: '0.9rem' }}>{contract.employer_name}</strong>
                                        ) : (
                                            <strong style={{ fontSize: '0.9rem' }}>
                                                {contract.employer_name} {', '}
                                            </strong>
                                        )
                                    )}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row style={{ paddingTop: '5px' }}>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <Icon style={{ marginRight: '0.5rem' }} name="mail-outline" />
                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                {emails.map(
                                    (item, idx) =>
                                        idx <= 2 && (
                                            <span key={idx} style={{ paddingRight: '5px' }}>
                                                <a href={'tel:' + item?.email}>{item?.email}</a>
                                                {idx < 2 && emails.length >= 3 && ','}
                                            </span>
                                        )
                                )}
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: '5px' }}>
                        <Icon style={{ marginRight: '0.5rem' }} name="call-outline" />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {phones.map(
                                (item, idx) =>
                                    idx <= 2 && (
                                        <span key={idx} style={{ paddingRight: '5px' }}>
                                            <a href={'mailto:' + item?.phone}>{item?.phone}</a>
                                            {idx < 2 && phones.length >= 3 && ','}
                                        </span>
                                    )
                            )}
                        </span>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: '5px' }}>
                        <Icon style={{ marginRight: '0.5rem' }} name="location-outline" />
                        <Address isForm={false} address={data?.permanent_address} />
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: '1.5rem' }}>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <LabelsList
                                rowID={data.id}
                                labels={data?.contact_labels}
                                endpoint={patchEndpoint}
                                label_key="contact_labels"
                                managePerms={['common.c_manage_all_employees', 'common.c_manage_all_candidates']}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                    {data?.profile_type !== 1 && (
                        <>
                            {t('department')}:<br />
                        </>
                    )}
                    {canViewUnit ? (
                        <Link to={routes.UNIT_DETAIL + data?.unit?.id} target="_blank">
                            <strong style={{ fontSize: '1rem', marginBottom: '3px' }}> {data?.unit?.name} </strong>
                            <br />
                        </Link>
                    ) : (
                        <strong style={{ fontSize: '1rem', marginBottom: '3px' }}>{data?.unit?.name}</strong>
                    )}
                    <strong>
                        {data?.custom_id_prefix} - {data?.custom_id}
                    </strong>
                    <br />
                    {data?.start_date ? (
                        <>
                            {t('start_day')}:<br />
                            <strong>{data?.start_date || '~'}</strong>{' '}
                        </>
                    ) : (
                        <>
                            {t('expected_start_date')}:<br />
                            <strong>{data?.expected_start_date || '~'}</strong>{' '}
                        </>
                    )}{' '}
                    <br />
                    {t('end_date')}:<br />
                    <strong>{data?.end_date || '~'}</strong> <br />
                    {t('identity_card_number')}:<br />
                    <strong>{data?.id_card_number || '~'}</strong>
                    <br />
                    {t('authorization_chip')}:<br />
                    <strong>{data?.authorization_chip || '~'}</strong>
                </Grid.Column>
            </Grid.Row>
        </>
    )
}

export default ProfileHeader
