import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { patchRequest } from '@services/ServiceCommon';
// components
import { Form, Divider, Header } from 'semantic-ui-react';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const ApprovalForm = ({ approval, approvals, onClose, permission }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [responsiblePerson, setResponsiblePerson] = useState("")

    let permissionParam = ""
    if (permission !== undefined) {
        permissionParam += "&permission_code=" + permission
    }

    const handleSubmit = async () => {
        setIsProcessing(true)

        if (!approvals) {
            const request = await patchRequest(API.APPROVALS + approval.id + "/responsible_person/", {
                responsible_person: responsiblePerson
            })
            if (request.status === 200) {
                if (onClose !== undefined) onClose()
            }
        }
        else {
            for (let i = 0; i < approvals.length; i++) {
                await patchRequest(API.APPROVALS + approvals[i].id + "/responsible_person/", {
                    responsible_person: responsiblePerson
                })
            }
            onClose()
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={t('approval_select_responsible_person')} />
            <Divider />
            <SuperField as="choice"
                text="fullname"
                label={t('employees')}
                value={responsiblePerson}
                onChange={(e, { value }) => setResponsiblePerson(value)}
                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true" + permissionParam}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalSubmit
                    disabled={isProcessing || responsiblePerson === ""}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default ApprovalForm;