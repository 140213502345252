import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// store 
import { API } from '@store/config';
import { getRequest, patchRequest } from '@services/ServiceCommon';
import { addCompany } from "@store/actions";
// components
import CanView from '@components/perms/CanView';
import { Grid, Image, Header, Card } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { CompanyForm } from '@components/common/CompanyForm';
import Loading from '@components/general/Loading';
// module specific
import CompanyDetail from '../views/CompanyDetail';

const Company = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [company, setCompany] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchActiveCompany(){
            setLoading(true)
            const request = await getRequest(API.COMPANIES + "?is_active=true")
            
            if( request.status === 200 ){
                if( request.response.length > 0 ){
                    setCompany(request.response[0])
                }
            }
            setLoading(false)
        }
        fetchActiveCompany()
    }, [])

    const handleCompanyAdd = async (response) => {
        const request =  await patchRequest(API.COMPANIES + response.id + "/", { is_active: true })

        if( request.status === 200 ){
            dispatch(addCompany(request.response))
            setCompany(request.response)
        }
    }

    return (
        <CanView permissions={["company.c_view_active_company"]}>
            {/* Check and allow to create company details if not exists */}
            { loading ? <Loading /> : 
                <>
                    { company?.id === undefined 
                        ?
                        <Grid style={{ marginTop: "6rem" }}>
                            <Grid.Row verticalAlign='middle' style={{ paddingTop: 0 }}>
                                <Grid.Column computer={8}>
                                    <Image centered src="/images/company.svg" width="80%"/>
                                </Grid.Column>
                                <Grid.Column computer={8}>
                                    <Header as="h2" style={{ marginTop: "2rem", marginBottom: "3rem", fontWeight: "bold", textAlign: "center", textTransform: "uppercase"}}>
                                        { t('We coudn\'t find your organization profile') }
                                    </Header>
                                    
                                    <SuperDuperModal 
                                        trigger={
                                            <Card centered className="active" fluid style={{ 
                                                cursor: "pointer",
                                                textAlign: "center",
                                                borderRadius: 0,
                                                color: "var(--primary)",
                                                width: "30%",
                                                marginBottom: "2rem"
                                            }}>
                                                <Card.Content as="h2">
                                                    { "Create organization profile" }
                                                </Card.Content>
                                            </Card>
                                        }
                                        content={
                                            <CompanyForm
                                                btnName={t('confirm')}
                                                onConfirm={(response) => handleCompanyAdd(response)}
                                            />
                                        }
                                    />
                                </Grid.Column>
                                
                            </Grid.Row>
                        </Grid>
                        : <CompanyDetail company={company}/>
                    }
                </>
            }
        </CanView>
    );
};

export default Company;