import React from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import InfoRow from '@components/InfoRow'
import Action from '@components/general/Action'
import DocPreview from '@components/DocPreview'
import CanView from '@components/perms/CanView'
import { Grid, Divider } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
// specific components
import IdentityDocForm from './IdentityDocForm'

const IdentityDocument = ({ document, profile, setIdentityDocs, canManage }) => {
    const { t } = useTranslation()

    const onDelete = async (id) => {
        const request = await requests.del(API.IDENTITY_DOCUMENTS + id + '/')
        if (request.status === 204) {
            setIdentityDocs((prev) => prev.filter((item) => item.id !== id))
        }
    }

    return (
        <>
            <Grid.Column>
                <InfoRow
                    isVisible={document?.document_number !== undefined}
                    text={t('document_number')}
                    content={document?.document_number || '--'}
                />
                <InfoRow
                    isVisible={document?.valid_until !== undefined}
                    text={t('valid_until')}
                    content={document?.valid_until || '--'}
                />
                <InfoRow
                    isVisible={document?.issued_date !== undefined}
                    text={t('issued_date')}
                    content={document?.issued_date || '--'}
                />
                <InfoRow
                    isVisible={document?.issued_country !== undefined}
                    text={t('issued_country')}
                    content={document?.issued_country || '--'}
                />
            </Grid.Column>
            <Grid.Column>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {document?.image_front && (
                        <SuperDuperModal
                            size="large"
                            trigger={
                                <div
                                    className="ref-link"
                                    style={{
                                        color: 'var(--primary)',
                                        fontWeight: 'bold',
                                        padding: '2rem',
                                        borderRadius: '5px',
                                        border: '1.5px dotted var(--dark)',
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('show_preview') + ' 1'}
                                </div>
                            }
                            content={<DocPreview uri={document.image_front} />}
                        />
                    )}
                    {document?.image_back && (
                        <SuperDuperModal
                            size="large"
                            trigger={
                                <div
                                    className="ref-link"
                                    style={{
                                        color: 'var(--primary)',
                                        fontWeight: 'bold',
                                        padding: '2rem',
                                        borderRadius: '5px',
                                        border: '1.5px dotted var(--dark)',
                                        textAlign: 'center',
                                        marginTop: '0.2rem',
                                    }}
                                >
                                    {t('show_preview') + ' 2'}
                                </div>
                            }
                            content={<DocPreview uri={document.image_back} />}
                        />
                    )}
                    {document.image_back === null && document.image_front === null && (
                        <div style={{ fontWeight: 'bold', color: 'var(--danger)' }}>{t('no_document_attached')}</div>
                    )}
                </div>
            </Grid.Column>
            <Grid.Column width="2">
                <CanView
                    permissions={[
                        'common.c_manage_all_employees',
                        'common.c_manage_user_employees',
                        'common.c_manage_all_candidates',
                        'common.c_manage_user_profile',
                    ]}
                >
                    {canManage && (
                        <Action
                            as="modal"
                            type="icon"
                            icon="pencil-outline"
                            iconColor="var(--dark)"
                            tooltip={t('update')}
                            size="huge"
                            header={t('update') + ' ' + t('document')}
                            modal={
                                <IdentityDocForm
                                    type={document.type}
                                    profile_id={profile}
                                    setData={setIdentityDocs}
                                    record={document}
                                />
                            }
                        />
                    )}
                </CanView>
                <CanView permissions={['common.c_delete_all_employees', 'common.c_delete_all_candidates']}>
                    <Action
                        as="delete"
                        size="huge"
                        tooltip={t('delete')}
                        text={t('are_you_sure_that_you_want_to_remove_identity_document')}
                        onClick={() => onDelete(document.id)}
                    />
                </CanView>
            </Grid.Column>
            <Divider />
        </>
    )
}

export default IdentityDocument
