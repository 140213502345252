import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from "react-router-dom";
// store
import { icons } from '@icons';
import { routes } from '@routes';
import { API } from '@store/config';
import { addJournal } from '@store/actions';
import { requests } from '@helpers/requests'; 
// components
import { Grid } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SpinnerSegment from '@components/SpinnerSegment';
// module specific
import ConnectSetup from './components/ConnectSetup';
import NavigationPanel from './components/NavigationPanel';
import BlockedViewAccess from './components/BlockedViewAccess';
import Overview from './views/Overview';
import InvoiceList from './views/InvoiceList';

const Invoices = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const reduxUser = useSelector(state => state.user)
    const reduxJournal = useSelector(state => state.journal)
    const [user, setUser] = useState({
        ...reduxUser,
        canView: false,
        canManage: false,
        canDelete: false,
        journal: reduxJournal
    })

    
    const [refetch, setRefetch] = useState(true)
    const [isVerifying, setIsVerifying] = useState(true)
    // const [connections, setConnections] = useState([])
    const [connection, setConnection] = useState(null)
    const [choices, setChoices] = useState(null)

    useEffect(() => {
        /* 
            ** 1. fetch connections,
            ** 2. set first as active...
            // ** 3. fetch premium api connection
        */
        async function fetchConnections(){
            if( refetch === false ) return;
            setIsVerifying(true)
            
            const request = await requests.get(API.INVOICES_CONNECTIONS)
            if( request.status === 200 ){
                // setConnections(request.response)
                if( request.response.length > 0 ){
                    let response = request.response
                    setConnection(response[0])
                    let currentJournal = response[0].accounts.find(item => item.allowed_users.find(allowedUser => allowedUser.user.username === user.username) !== undefined)
                    if( user.journal !== null && reduxJournal !== null ){
                        currentJournal = response[0].accounts.find(item => item.account_id === reduxJournal.account_id)
                    }

                    if( currentJournal !== undefined ) {
                        // get ibans for current journal
                        const requestIbans = await requests.get(API.COMMON + "ibans/?id_number=" + currentJournal.id_number)
                        if (requestIbans.status === 200){
                            currentJournal['ibans'] = requestIbans.response
                        } 
                        
                        dispatch(addJournal(currentJournal))
                    }
                    let allowedUser = currentJournal?.allowed_users?.find(item => item.user.username === user.username )
                    if (allowedUser){
                        setUser(prev => ({
                            ...prev,
                            canView: allowedUser.can_view,
                            canManage: allowedUser.can_manage,
                            canDelete: allowedUser.can_manage,
                            journal: currentJournal
                        }))
                    }

                    const requestChoices = await requests.get(API.GET_INVOICES + "choices/?connection=" + response[0].connection_id)
                    if( requestChoices.status === 200 ){
                        setChoices(requestChoices.response)
                    }
                } else {
                    dispatch(addJournal(null))
                }
            }

            setTimeout(function() {
                setIsVerifying(false)
                setRefetch(false)
            }, 500);
        }

        fetchConnections()
        // eslint-disable-next-line
    }, [refetch])

    return (
        <>
            <BreadcrumbNav items={[{ 'name': t('invoices'), 'icon': icons.INVOICES, 'href': ''}]}/>
            <SpinnerSegment loading={isVerifying} loadingMessage={t('message_performing_verification')}>
                { connection !== null && user.canView ? (
                    <>
                        <NavigationPanel
                            user={user}
                            setUser={setUser}
                            setRefetch={setRefetch}
                            connection={connection}
                            premiumConnection={null}
                        />
                        <Switch>
                            <Route exact path={routes.INVOICES}>
                                <Overview connection={connection} user={user}/>
                            </Route>
                            <Route path={routes.INVOICES + "incomes/"}>
                                <InvoiceList type="issued" connection={connection} user={user} choices={choices}/>
                            </Route>
                            <Route path={routes.INVOICES + "expenses/"}>
                                <InvoiceList type="received" connection={connection} user={user} choices={choices}/>
                            </Route>
                        </Switch>
                    </>
                ) : (
                    <>
                        { connection === null 
                            ?   // when no connection is created allow user to create one if have proper permissions for it
                                <Grid style={{ paddingTop: 0 }}>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column>
                                            { user.role === 1 
                                                ? <ConnectSetup setRefetch={setRefetch}/>
                                                : <p style={{ textAlign: "center", fontWeight: "bold" }}> {t('no_permissions_to_manage_invoices')} </p>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            :   <BlockedViewAccess/>
                        }
                    </>
                )}
            </SpinnerSegment>
        </>
    );
};

export default Invoices;