import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { icons } from '@store/icons';
// components
import { Card, Container, Segment } from 'semantic-ui-react';
import Icon from '@components/Icon';

const StatCard = ({ color, isCustomColor, number, description, icon, onClick, totalStages, index }) => {
    const { t } = useTranslation()
    return (
        <Card style={isCustomColor ? { borderRadius: 0, boxShadow: `0 0 0 1px #d4d4d5,0 2px 0 0 ${color},0 1px 3px 0 #d4d4d5` } : { borderRadius: 0 }} color={isCustomColor ? undefined : color} onClick={onClick}>
            <Card.Content>
                <div style={{ display: "flex", marginBottom: "2rem" }}>
                    <div style={{ textAlign: "left", fontWeight: "bold", width: "70%", fontSize: "1.15rem", paddingTop: "0.25rem", color: "var(--dark)" }}>
                        { number }
                    </div>
                    <div style={{ textAlign: "right", width: "30%" }}>
                        <Icon name={icon} style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "left", color: color }}/>
                    </div>
                </div>

                <div style={{ position: "absolute", bottom: "1.3rem", marginTop: "1rem", color: "var(--dark)", opacity:"0.8" }}>
                    { description }
                </div>
                <div style={{ position: "absolute", color: "var(--dark)", opacity:"0.5", bottom: "0.3rem" }}>
                    { index ? totalStages === index ? <small>{ t('final_stage') }</small> : <small>{ t('stage') + " " + index }</small> : "" }
                </div>
            </Card.Content>
        </Card>
    )
}

const HiringRoomStats = ({ total, fetchData, setFilters, stages, rejected, blacklisted, setListView, setSelectedStage }) => {
    const { t } = useTranslation()

    let all_candidates = 0
    for (const stage of stages) {
        all_candidates += stage.candidate_count || 0
    }

    const renderOnClick = async (stage) => {
        setListView(0)
        setFilters(prev => ({...prev, current_stage: stage}))
        if(stage === "") setSelectedStage("all") 
        else{setSelectedStage(stage)}
        if (stage === "") {
            await fetchData("&current_stage=", true)
        } else {
            await fetchData("&current_stage=" + stage, true)
        }
    }

    const renderOnClickRejected = async () => {
        setSelectedStage("rejected") 
        setListView(1)
    }

    const renderOnClickBlacklisted = async () => {
        setSelectedStage("blacklisted") 
        setListView(2)
    }

    return (
        <Container fluid>
            <Segment style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none", marginBottom: "1rem" }}>
                <Card.Group stackable doubling itemsPerRow={stages.length + 3}>
                <StatCard
                    color="black"
                    number={all_candidates}
                    icon={icons.ORDERS}
                    description={t("all")}
                    onClick={() => renderOnClick("")}
                />
                {stages.map((stage, index) => (
                    <StatCard
                        key={index}
                        index={index + 1}
                        isCustomColor
                        color={stage.color}
                        number={stage.candidate_count}
                        icon={stage.icon}
                        description={stage.name}
                        onClick={() => renderOnClick(stage.id)}
                        totalStages={stages.length}
                    />
                ))}
                <StatCard
                    color={"red"}
                    number={rejected?.count || rejected?.length || 0}
                    icon={'close-circle-outline'}
                    description={t("rejected")}
                    onClick={() => renderOnClickRejected()}
                />
                <StatCard
                    color="black"
                    number={blacklisted?.count || blacklisted?.length || 0}
                    icon={icons.ORDERS}
                    description={t("blacklisted")}
                    onClick={() => renderOnClickBlacklisted()}
                />
                </Card.Group>
            </Segment>
        </Container>
    );
};

export default HiringRoomStats;