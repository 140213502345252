import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// components
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// styling
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from 'semantic-ui-react';

const TemplateEditor = ({ value, onChange, readOnly, raw }) => {
    const { t } = useTranslation();
    const html = value || '<p></p>'
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))
    const [showRaw, setShowRaw] = useState(false)

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    const styleMap = {
        'PAGEBREAK': {
            pageBreakAfter: 'always',
        },
    };

    return (
        <>
            {!raw ?
                <>
                    <Button.Group style={{ marginBottom: "0.5rem" }} size="tiny">
                        <Button type="button" primary={!showRaw} onClick={() => setShowRaw(false)}> {t('editor_view')} </Button>
                        <Button type="button" primary={showRaw} onClick={() => setShowRaw(true)}> {t('raw_view')} </Button>
                    </Button.Group>
                    {!showRaw &&
                        <Editor
                            editorState={editorState}
                            toolbarClassName="template-toolbar"
                            wrapperClassName="wrapperClassName"
                            editorClassName="template-editor"
                            onEditorStateChange={onEditorStateChange}
                            // toolbarCustomButtons={[<Placeholders />, <PageBreak/>]}
                            customStyleMap={styleMap}
                            stripPastedStyles={true}
                            readOnly={readOnly || false}
                        />
                    }
                    {showRaw && <textarea disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />}
                </>
                :
                <>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="rich-text__toolbar"
                        onEditorStateChange={onEditorStateChange}
                        customStyleMap={styleMap}
                        readOnly={true}
                    />
                </>
            }
        </>
    );
};

export default TemplateEditor;