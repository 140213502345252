import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { Header, Container } from 'semantic-ui-react';
import Loading from '@components/general/Loading';
import TemplateEditor from '@components/editors/template-editor/TemplateEditor';

const GDPRView = () => {
    const params = useParams();

    // eslint-disable-next-line
    const [id] = useState(params.id);
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function fetchGdpr() {
            setLoading(true)
            const request = await getRequest(API.GDPRS_PUBLIC + "?id=" + id, false)

            if (request.status === 200) {
                setData(request.response[0])
            }

            setLoading(false)
        }

        fetchGdpr()
        // eslint-disable-next-line
    }, [])

    return (
        <div style={styles}>
            {loading ? <Loading /> :
                <Container>
                    <Header as='h1' content={data.title || '--'} style={{ textAlign: "center" }}/>
                    <TemplateEditor value={data.text} readOnly={true} raw={true} />
                </Container>
            }
        </div>
    );
};

const styles = {
    margin: "2rem"
};

export default GDPRView;