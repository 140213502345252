import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { setLocaleLanguage } from '@helpers/dates'
// components
import { QrReader } from 'react-qr-reader'
import SuperField from '@components/forms/SuperField'
import { Grid, Container, Header, Form, Icon, Image } from 'semantic-ui-react'
import LanguageSelector from './LanguageSelector'

const AttendanceVerification = ({ buildVersion, setMode, setAllowBreakTracking, setEmployee, setInterruptions }) => {
    const { t } = useTranslation()
    const [code, setCode] = useState('')
    const [processing, setProcessing] = useState(false)
    const [invalid, setInvalid] = useState('')
    const logoFromRedux = useSelector((state) => state.logo)
    const [logo] = useState(logoFromRedux)

    const allowQRScanner = useSelector((state) => state.allow_qr_scanner)

    const handleSubmit = async (codeParam) => {
        codeParam = codeParam || code || ''
        if (codeParam !== '') {
            setInvalid('')
            setProcessing(true)

            const request = await requests.get(`${API.ATTENDANCE_BASE}track/${codeParam}/`)
            if (request.status === 404) {
                setInvalid(codeParam)
                setCode('')
            }

            if (request.status === 200) {
                // fetch active record if exist
                const fetchActiveRecord = await requests.get(
                    `${API.ATTENDANCE_BASE}records/employee/?employee=${request.response.id}`
                )

                let record = null
                if (fetchActiveRecord.status === 200) {
                    if (fetchActiveRecord.response.length > 0) {
                        record = fetchActiveRecord.response[0]
                    }
                }

                // fetch interruption reasons if exists
                const fetchInterruptions = await requests.get(`${API.ATTENDANCE_INTERRUPTION_REASONS}`)
                if (fetchInterruptions.status === 200) setInterruptions(fetchInterruptions.response)

                const fetchBreakTrackingPreference = await requests.get(
                    `${API.PREFERENCES}attendance_preferences__attendance_employee_user_pause_assign/`
                )
                if (fetchBreakTrackingPreference?.status === 200) {
                    setAllowBreakTracking(fetchBreakTrackingPreference.response.value)
                }

                setEmployee({
                    ...request.response,
                    record: record,
                })
                setMode(2)
            }
            setProcessing(false)
        }
    }

    return (
        <Container>
            <Grid centered stackable>
                <Grid.Row verticalAlign="middle" style={{ height: '100vh' }}>
                    <Grid.Column width="4" />
                    <Grid.Column width="8">
                        <Image centered size="small" src={logo} />
                        <Header as="h1" textAlign="center" style={{ marginBottom: '0' }}>
                            {t('attendance_tracker')} <br />
                            {allowQRScanner && <small>{t('scan_your_qr_code')}</small>}
                        </Header>
                        {allowQRScanner && (
                            <QrReader
                                containerStyle={{ width: '350px', height: '350px', margin: '0 auto' }}
                                scanDelay="200"
                                constraints={{
                                    facingMode: 'user',
                                }}
                                onResult={async (result, error) => {
                                    if (!!result) {
                                        setCode(result?.text || '')
                                        await handleSubmit(result?.text || '')
                                    }

                                    if (!!error) {
                                        // console.info(error);
                                    }
                                }}
                            />
                        )}
                        <Form
                            autoComplete="off"
                            onSubmit={async (e) => {
                                e.preventDefault()
                                await handleSubmit()
                            }}
                        >
                            <SuperField
                                as="input"
                                fluid
                                size="huge"
                                id="verify-input"
                                value={code}
                                loading={processing}
                                autoFocus={!allowQRScanner}
                                autoComplete="off"
                                style={{ marginTop: '1rem' }}
                                onChange={(e, { value }) => setCode(value)}
                                placeholder={t('enter_verification_code') + '...'}
                                icon={
                                    <Icon
                                        name="angle right"
                                        link
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleSubmit(code)}
                                    />
                                }
                            />
                            {invalid !== '' && (
                                <p style={{ color: 'var(--danger)', fontSize: '1.5rem', textAlign: 'center' }}>
                                    {t('verification_code')}{' '}
                                    <u>
                                        <strong>{invalid}</strong>
                                    </u>{' '}
                                    {t('is_invalid')}!
                                </p>
                            )}
                        </Form>
                        <LanguageSelector />
                        <p style={{ textAlign: 'center', marginTop: '2rem' }}>
                            Revision:{' '}
                            <strong>
                                {moment(buildVersion).locale(setLocaleLanguage()).format('DD. MMMM YYYY, HH:mm')}
                            </strong>
                        </p>
                    </Grid.Column>
                    <Grid.Column width="4" />
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default AttendanceVerification
