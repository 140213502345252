import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasPermission } from '@helpers/hooks';
// components
import CanView from '@components/perms/CanView';
import { Form, Divider, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';

const ServiceOrderForm = ({ customSubmitHandler, record, setData, setTotal, onClose, accounts, projects, employees, owners }) => {
    const { t } = useTranslation()
    const user = useSelector(state => state.user)

    const canManageCC = useHasPermission('cost_centers.c_manage_cost_centers')

    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [createCCFromOrder, setCreateCCFromOrder] = useState(false)
    const [costCenters, setCostCenters] = useState([])
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: record?.name || "",
        order_number: record?.order_number || "",
        business_detail: record?.business_detail?.id || "",
        project: record?.project?.id || "",
        order_manager: record?.order_manager?.id || "",
        order_type: record?.order_type || 2,
        owner: record?.owner?.id || "",
        cost_center: record?.cost_center?.id || "",

        // labels: record?.labels?.map(item => item.id) || [],
        workers: record?.workers?.map(item => item.id) || [],
        assigned_to: record?.assigned_to?.map(item => item.id) || [],
        order_status: record?.order_status || 1,
        is_active: record?.is_active !== undefined ? record?.is_active : true,
    })

    useEffect(() => {
        async function fetchCostCenters() {
            setLoading(true)
            const request = await requests.get(API.COST_CENTERS + "?query={id, title}")
            if (request.status === 200) setCostCenters(request.response)
            setLoading(false)
        }

        fetchCostCenters()
    }, [])

    useEffect(() => {
        if (owners.length === 1) {
            setForm(prev => ({...prev, owner: owners?.[0]?.value || ""}))
        }
    }, [owners])

    const createCCAndUpdateOrder = async (response) => {
        const request = await requests.post(API.COST_CENTERS, {
            title: response.name,
            unit: response?.owner?.id || null
        })

        if (request.status === 201) {
            // update record
            const updateRequest = await requests.patch(API.ORDERS + response.id + "/", {
                cost_center: request.response?.id,
                resourcetype: "ServiceOrder",
            }) 

            if (updateRequest.status === 200) {
                return updateRequest.response
            }
        }

        return response
    } 

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let data = {
            name: form.name,
            order_number: form.order_number,
            order_type: form.order_type,
            resourcetype: "ServiceOrder",
            order_manager: form.order_manager !== "" ? form.order_manager : null ,
            project: form.project !== "" ? form.project : null,
            business_detail: form.business_detail !== "" ? form.business_detail : null,
            cost_center: form.cost_center !== "" ? form.cost_center : null,
            is_active: form.is_active,
            order_status: form.order_status,
            owner: form.owner !== "" ? form.owner : null,
        }

        // handle data processing and api calls
        if (record === undefined) { // its create request
            // if (form.labels.length > 0) data['labels'] = { add: form.labels }
            if (form.workers.length > 0) data['workers'] = { add: form.workers }
            if (form.assigned_to.length > 0) data['assigned_to'] = { add: form.assigned_to }

            const request = await requests.post(API.ORDERS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                let response = null
                if (createCCFromOrder) {
                    response = await createCCAndUpdateOrder(request.response)
                } else {
                    response = request.response
                }

                if (customSubmitHandler) {
                    customSubmitHandler(response)
                } else {
                    setTotal(prev => prev + 1)
                    setData(prev => ([response, ...prev]))
                    onClose()
                }
            }

        } else { // its update request
            // data['labels'] = { remove: record.labels.map(item => item.id), add: form.labels }
            data['workers'] = { remove: record.workers.map(item => item.id), add: form.workers }
            data['assigned_to'] = { remove: record.assigned_to.map(item => item.id), add: form.assigned_to }

            const request = await requests.patch(API.ORDERS + record.id + "/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setData(prev => prev.map(item => {
                        if (item.id === record.id) {
                            item = request.response
                        }
                        return item
                    }))
                    onClose()
                }
            }
        }

        setProcessing(false)
    }

    const handleAddCostCenter = async (value) => {
        setLoading(true)
        const request = await requests.post(API.COST_CENTERS, {
            title: value,
            unit: form.owner?.id || null
        })

        if (request.status === 201) {
            setForm(prev => ({...prev, cost_center: request.response.id}))
            setCostCenters(prev => [...prev, request.response])
        }
        setLoading(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            { record !== undefined && (
                <>
                    <Header as="h3" content={t('update_order')}/>
                    <Divider/>
                </>
            )}

            <NonFieldErrors errors={errors} />

            <Form.Group>
                <SuperField as="input"
                    required
                    autoFocus
                    width="12"
                    label={t('name')}
                    value={form.name}
                    error={errors?.name?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, name: value}))}
                />
                <SuperField as="input"
                    width="4"
                    label={t('order_number')}
                    value={form.order_number}
                    error={errors?.order_number?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, order_number: value}))}
                />
            </Form.Group>

            { (record === undefined && canManageCC) && // allow to create CC from order name when creating new service order
                <SuperField as="checkbox"
                    label={t('create_cost_center_from_order')}
                    checked={createCCFromOrder}
                    onChange={() => setCreateCCFromOrder(!createCCFromOrder)}
                />
            }

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    required
                    value={form.owner}
                    label={t('supplier')}
                    customOptions={owners}
                    error={errors?.owner?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, owner: value}))}
                />
                <SuperField as="choice"
                    search
                    required
                    value={form.business_detail}
                    label={t('account')}
                    customOptions={accounts}
                    error={errors?.business_detail?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, business_detail: value}))}
                />
            </Form.Group>
            <Divider/>

            <Form.Group widths="equal">
                <CanView permissions={["orders.c_assign_order_manager"]}>
                    <Form.Field>
                        <SuperField as="choice"
                            search
                            label={t('order_manager')}
                            value={form.order_manager}
                            customOptions={employees}
                            error={errors?.order_manager?.[0] || false}
                            onChange={(e, { value }) => setForm(prev => ({...prev, order_manager: value}))}
                        />
                        { user.profile?.id && 
                            <SuperField as="checkbox"
                                style={{ marginTop: "1rem" }}
                                label={t('assign_me_as_order_manager')}
                                checked={form.order_manager === user.profile?.id}
                                onChange={() => {
                                    if (form.order_manager !== user.profile?.id) {
                                        setForm(prev => ({
                                            ...prev,
                                            order_manager: user.profile?.id || "",
                                        }))
                                    } else {
                                        setForm(prev => ({ ...prev, order_manager: ""}))
                                    }
                                }}
                            />
                        }
                    </Form.Field>
                </CanView>
                <SuperField as="choice"
                    search
                    loading={loading}
                    label={t('cost_center')}
                    value={form.cost_center}
                    disabled={createCCFromOrder || loading}
                    allowAdditions={canManageCC}
                    onAddItem={(e, { value }) => handleAddCostCenter(value)}
                    onChange={(e, { value }) => setForm(prev => ({...prev, cost_center: value}))}
                    customOptions={costCenters.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                />
            </Form.Group>

            <SuperField as="choice"
                search
                multiple
                label={t('workers')}
                value={form.workers}
                customOptions={employees}
                onChange={(e, { value }) => setForm({ ...form, workers: value })}
            />

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    multiple
                    label={t('responsible_persons')}
                    value={form.assigned_to}
                    customOptions={employees}
                    onChange={(e, { value }) => setForm({ ...form, assigned_to: value })}
                />
            </Form.Group>

            <Divider/>
            <SuperField as="choice"
                label={t('project')}
                value={form.project}
                customOptions={projects}
                error={errors?.project?.[0] || false}
                onChange={(e, { value }) => setForm(prev => ({...prev, project: value}))}
            />
            <Form.Group widths="equal">
                <SuperField as="choice"
                    label={t('state')}
                    disabled={record?.id === undefined || [undefined, null, ""].includes(record?.confirmed_on) }
                    type="order_status_choices"
                    value={form.order_status?.toString() || "1"}
                    onChange={(e, { value }) => setForm({ ...form, order_status: value })}
                />
                <SuperField as="choice"
                    label={t('status')}
                    value={form.is_active}
                    customOptions={[
                        { key: 0, value: true, text: t('active_order') },
                        { key: 1, value: false, text: t('closed_order') },
                    ]}
                    onChange={(e, { value }) => setForm({ ...form, is_active: value })}
                />
            </Form.Group>

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    loading={processing}
                    disabled={processing || form.name === "" || form.account === "" || form.owner === ""}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default ServiceOrderForm;