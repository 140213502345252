import React from 'react';
import { useTranslation } from "react-i18next"
// Components
import Icon from '@components/Icon';
import { Form, Header, Button } from 'semantic-ui-react';
import EmailField from '@components/forms/common/EmailField'
import PhoneField from '@components/forms/common/PhoneField'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import WorkplaceForm from '../WorkplaceForm';

const OfferConfiguration = ({ jobOffer, setJobOffer, workplace, setWorkplace }) => {
    const { t } = useTranslation();

    return (
        <>
            <Form.Group widths={3}>
                <Form.Field>
                    <Form.Input
                        type="number"
                        min="0"
                        label={t('open_position')}
                        placeholder="10"
                        value={jobOffer.number_of_openings}
                        onChange={(e) => setJobOffer({
                            ...jobOffer,
                            number_of_openings: e.target.value
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label={t('boarding_when')}
                        placeholder={t('boarding_when_placeholder')}
                        value={jobOffer.boarding_when}
                        onChange={(e) => setJobOffer({
                            ...jobOffer,
                            boarding_when: e.target.value
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label={t('note_for_candidate')}
                        placeholder={t('note_for_candidate_placeholder')}
                        value={jobOffer.note_for_candidate}
                        onChange={(e) => setJobOffer({
                            ...jobOffer,
                            note_for_candidate: e.target.value
                        })}
                    />
                </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Checkbox
                        inline
                        label={t('login_required')}
                        checked={jobOffer.is_login_required}
                        onChange={() => setJobOffer({
                            ...jobOffer,
                            is_login_required: !jobOffer.is_login_required
                        })}
                    />
                    <Form.Checkbox
                        inline
                        label={t('cv_required')}
                        checked={jobOffer.is_cv_required}
                        onChange={() => setJobOffer({
                            ...jobOffer,
                            is_cv_required: !jobOffer.is_cv_required
                        })}
                    />
                    <Form.Checkbox
                        inline
                        label={t('is_valid_for_graduate')}
                        checked={jobOffer.valid_for_graduate}
                        onChange={() => setJobOffer({
                            ...jobOffer,
                            valid_for_graduate: !jobOffer.valid_for_graduate
                        })}
                    />
                    <Form.Checkbox
                        inline
                        label={t('is_valid_for_students')}
                        checked={jobOffer.valid_for_student}
                        onChange={() => setJobOffer({
                            ...jobOffer,
                            valid_for_student: !jobOffer.valid_for_student
                        })}
                    />
                    <Form.Checkbox
                        inline
                        label={t('is_valid_for_disabled_persons')}
                        checked={jobOffer.valid_for_disabled_person}
                        onChange={() => setJobOffer({
                            ...jobOffer,
                            valid_for_disabled_person: !jobOffer.valid_for_disabled_person
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{t('workplace')}</label>
                    {jobOffer.workplace === 0
                        ?
                        <SuperDuperModal
                            header={t('add_workplace')}
                            trigger={
                                <Button type="button" fluid basic> 
                                    <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('add_workplace')} 
                                    </span>
                                </Button>
                            }
                            content={
                                <WorkplaceForm
                                    jobOffer={jobOffer}
                                    setJobOffer={setJobOffer}
                                    workplace={workplace}
                                    setWorkplace={setWorkplace}
                                />
                            }
                        />
                        :
                        <SuperDuperModal
                            header={t('update_workplace')}
                            trigger={
                                <Button type="button" fluid basic>
                                    <Icon name="pencil-outline"  style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {t('update_workplace')}: {' '}
                                        <strong>
                                            {
                                                workplace?.street + ' ,' +
                                                workplace?.postcode + ' ' +
                                                workplace?.city + ', ' +
                                                workplace?.state + ', ' +
                                                workplace?.country
                                            }
                                        </strong>
                                    </span>
                                </Button>
                            }
                            content={
                                <WorkplaceForm
                                    jobOffer={jobOffer}
                                    setJobOffer={setJobOffer}
                                    workplace={workplace}
                                    setWorkplace={setWorkplace}
                                />
                            }
                        />

                    }
                </Form.Field>
            </Form.Group>

            <Header style={{ paddingBottom: "2rem", paddingTop: "2rem" }} as="h3" content={t('contact_person')}/>

            <Form.Group widths={3}>
                <Form.Input
                    label={t('person_name')}
                    placeholder="ex. John Doe"
                    value={jobOffer.contact_person}
                    onChange={(e) => setJobOffer({
                        ...jobOffer,
                        contact_person: e.target.value
                    })}
                />
                <EmailField
                    placeholder={t('enter_valid_email_address')}
                    value={jobOffer.contact_email}
                    setValue={(e) => setJobOffer({
                        ...jobOffer,
                        contact_email: e.target.value
                    })}
                />
                <PhoneField
                    hideType
                    placeholder={t('enter_valid_phone')}
                    value={jobOffer.contact_phone}
                    setValue={(e) => setJobOffer({
                        ...jobOffer,
                        contact_phone: e.target.value
                    })}
                />
            </Form.Group>

        </>
    );
};

export default OfferConfiguration;