import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store 
import { patchRequest, createRequest } from '@services/ServiceCommon';
// components
import NonFieldErrors from '@components/NonFieldErrors';
import SuperField from '@components/forms/SuperField';
import { Form, Button } from 'semantic-ui-react';

const UploadFile = ({ endpoint, field, callback, onClose, isInvoiceImport }) => {
    const { t } = useTranslation();
    const [scannedFile, setScannedFile] = useState(null)
    const [isUploading, setIsUploading] = useState(false)
    const [errors, setErrors] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors(null)
        setIsUploading(true)

        const formData = new FormData()
        formData.append(field, scannedFile)
        let request = undefined
        if(isInvoiceImport){
            request = await createRequest(endpoint, formData)
        } else {
            request = await patchRequest(endpoint, formData)
        }

        if (request !== undefined && request.status === 400) setErrors(request.response)
        if( request !== undefined && request?.status === 200 ){
            callback(request.response)
            onClose()
        }

        setIsUploading(false)
    }
    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors}/>
            <Form.Group>
                <SuperField as="input" 
                    type="file" 
                    width={12}
                    label={ t('upload_scanned_file') }
                    error={errors?.[field]?.[0] || false}
                    onChange={(event) => {
                        if (event.target.files.length > 0) {
                            setScannedFile(event.target.files[0]);
                        }
                    }}
                />
                <Form.Field width={4}>
                    <Button 
                        fluid primary 
                        size="large"
                        loading={isUploading} 
                        content={ t('upload') }
                        style={{ marginTop: "1.6rem" }} 
                        disabled={isUploading || scannedFile === null} 
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    )
};

export default UploadFile;