import React from 'react';
// components 
import { Header } from 'semantic-ui-react';

const SuperFieldSet = ({ legend, children, ...rest }) => {
    return (
        <fieldset {...rest} style={{ border: "2px solid var(--light)", marginBottom: "1rem" }}>
            <legend>
                <Header style={{ paddingLeft: "1rem", paddingRight: "1rem" }} as="h3" content={legend}/>
            </legend>
            <div style={{ marginTop: "0.8rem" }}>
            { children }
            </div>
        </fieldset>
    );
};

export default SuperFieldSet;