import React from 'react';
import { useTranslation } from "react-i18next";
// component
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer/dist";
import { Grid, Icon, Divider } from 'semantic-ui-react';

const SortedDocPreview = ({ uri, additionalActions, fileType }) => {
    const { t } = useTranslation()

    // const splittedURI = uri.split('.')
    // const fileType = splittedURI[splittedURI.length - 1] || "pdf"

    return (
        <div>
            <Grid>
                <Grid.Row verticalAlign="middle" columns={2} style={{ padding: 0 }}>
                    <Grid.Column style={{ textAlign: 'left', fontWeight: "bold" }}>{ t('document_preview') }</Grid.Column>
                    <Grid.Column style={{ textAlign: 'right' }}>
                        { additionalActions }
                        <a href={uri} target="_blank" rel="noopener noreferrer" download style={{ marginLeft: "1rem" }}>
                            <Icon name="download" style={{ cursor: 'pointer' }}/>
                        </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <DocViewer
                prefetchMethod="GET"
                pluginRenderers={DocViewerRenderers}
                documents={[ { uri: uri, fileType: fileType.toLowerCase() }]}
            />
        </div>
    );
};

export default SortedDocPreview;