import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
// components
import Icon from '@components/Icon';
import { Button, Header, Divider, Form } from 'semantic-ui-react';
import SuperDuperModal from '../modals/SuperDuperModal';

const Phone = ({ phone, noContent, ...rest }) => {
    const { t } = useTranslation();
    // states
    const [sms, setSMS] = useState(false)

    const SMSForm = ({ phone, onClose }) => {
        const [message, setMessage] = useState("")
        const [isSending, setIsSending] = useState(false)

        const onSubmit = async (e) => {
            setIsSending(true)

            // TODO: Implement backend logic for sending SMS

            // TODO: Add proper error or success message to determinate if request was done successfully

            alert(message) // temporary alert :rofl:

            setIsSending(false)
            setSMS(false)
        }

        return (
            <Form onSubmit={(e) => onSubmit(e)} style={{ paddingBottom: "2rem" }}>
                <Form.TextArea
                    autoFocus
                    label={t('message')}
                    placeholder={t('message')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

                <Button floated="left" type="button" primary onClick={() => setSMS(false)}>
                    <Icon name="arrow-back-outline" style={{ marginRight: "0.5rem" }}/> 
                    <span style={{ position: "relative", top: "-0.2rem" }}>
                        { t('back') }
                    </span>
                </Button>
                <Button floated="left" type="button" onClick={() => onClose()}> {t('close')} </Button>
                <Button disabled={message === "" || isSending} loading={isSending} floated="right" primary> {t('send')} </Button>
            </Form>
        )
    }

    const PhoneChoice = ({ phone, onClose }) => {
        return (
            <>
                <Header as="h2" style={{ textAlign: "center" }} content={!sms ? t('select_option') : t('write_your_message')} />
                <Divider />
                {!sms
                    ?
                    <Button.Group fluid>
                        <Button as="a" href={"tel:" + phone}> {t('make_a_call')} </Button>
                        <Button disabled onClick={() => setSMS(true)}> {t('send_sms')} </Button>
                    </Button.Group>
                    : <SMSForm onClose={onClose} phone={phone} />
                }
            </>
        )
    }

    return (
        <SuperDuperModal
            trigger={
                <span style={{ color: "var(--primary)", cursor: "pointer" }}>
                    { !noContent
                    ? 
                        <>
                            <Icon name="call-outline" style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.2rem" }}>{phone}</span>
                        </>
                    : 
                        <div style={{ color: 'var(--white)' }}>
                            <Icon name='call-outline' style={{ marginRight: "0.5rem" }} />
                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                { t('call') }
                            </span>
                        </div>
                    }
                </span>
            }
            content={<PhoneChoice phone={phone} />}
        />
    );
};

export default Phone;