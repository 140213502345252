import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// stored
import { API } from '@store/config';
// components
import { Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const HriisPublicationForm = ({ offerId, setData, setIsDisabled }) => {
    const { t } = useTranslation();
    const [hriisData, setHriisData] = useState({
        is_active: true,
        is_featured: false,
        valid_until: "",
        job_offer: offerId,
        application_form_config: null,
        resourcetype: "JobPublication"
    })

    useEffect( () => {
        setData(hriisData)

        // eslint-disable-next-line
    }, [hriisData])

    useEffect(() => {
        let isValid = true
        if(
            hriisData.valid_until === "" ||
            hriisData.valid_until === null
        ){
            isValid = false
        }

        setIsDisabled(!isValid)
        // eslint-disable-next-line
    }, [hriisData])

    return (
        <>
            <Form.Group widths='equal'>
                <SuperField as="datepicker"
                    required
                    label={t('valid_until')}
                    value={hriisData.valid_until}
                    onChange={(e, data) => setHriisData({
                        ...hriisData,
                        valid_until: data.value
                    })}
                />
                <SuperField as="choice"
                    endpoint={API.APPLICATION + "form_configs/"}
                    text="name"
                    label={ t('application_form') }
                    value={ hriisData.application_form_config }
                    onChange={(e, { value }) => setHriisData({
                        ...hriisData,
                        application_form_config: value
                    })}
                />
            
            </Form.Group>
            <SuperField as="checkbox"
                style={{ marginTop: "2.2rem" }}
                label={t('mark_as_featured')}
                checked={ hriisData.is_featured }
                onChange={ () => setHriisData({
                    ...hriisData,
                    is_featured: !hriisData.is_featured
                })}
            />
        </>
    );
};

export default HriisPublicationForm;