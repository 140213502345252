import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import { Button, Form, Header, Divider, List, Icon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuperFieldSet from '@components/forms/SuperFieldSet';

const Languages = ({ setData, languageList, languageLevels }) => {
    const { t } = useTranslation();
    
    const languageChoices = languageList.map(item => ({key: item.id, value: item.id, text: item.title})) || []

    const languageInit = {
        language_name: "",
        level: "",
        nameDisplay: "",
        levelDisplay: ""
    }

    const [languages, setLanguages] = useState([])
    const [language, setLanguage] = useState(languageInit)

    const [isHovered, setIsHovered] = useState(false)
    const [isFormHovered, setIsFormHovered] = useState(false)
    const [isFormFocused, setIsFormFocused] = useState(false)

    useEffect(() => {
        let languagesList = []
        if (languages.length > 0) {
            for (let i = 0; i < languages.length; i++) {
                languagesList.push({
                    language_name: languages[i].language_name, 
                    level: parseInt(languages[i].level),
                })
            }
        }

        let languagesArray = {
            create: languagesList
        }

        setData(prevState => ({
            ...prevState,
            languages: languagesArray
        }))

    }, [languages, setData])


    const addLanguage = () => {
        if (language.language_name !== "" && language.level !== "") {
            let languageData = language
            languageData.levelDisplay = languageLevels.find(item => item.value === language.level)?.text || ""
            languageData.nameDisplay = languageChoices.find(item => item.value === language.language_name)?.text || ""

            setLanguages([...languages, languageData])
            setLanguage(languageInit)
        }
    }

    return (
        <SuperFieldSet legend={t('languages')} onClick={() => setIsFormHovered(true)} onMouseOver={() => setIsFormHovered(true)} onMouseLeave={() => setIsFormHovered(false)} >
            {/* Generate List of added languages */}
            {
                languages.length > 0
                    ?
                    <List selection verticalAlign='middle'>
                        {languages.map((language, index) => (
                            <List.Item key={index} style={{ padding: "1rem" }} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                                <List.Icon name='language' size='large' verticalAlign='middle' />
                                <List.Content>
                                    <List.Content verticalAlign='middle' floated='right'>
                                        <Icon
                                            size="large"
                                            name="close"
                                            style={{
                                                display: isHovered ? "block" : "none",
                                                color: "var(--danger)",
                                                marginTop: "0.2rem"
                                            }}
                                            onClick={() => setLanguages(languages.filter((item, listIndex) => listIndex !== index))}
                                        />
                                    </List.Content>
                                    <List.Header>{language.nameDisplay} - {language.levelDisplay}</List.Header>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                    :
                    <Header
                        textAlign="center"
                        as="h4"
                        content={t('mouse_or_click_languages')}
                        style={{
                            display: (isFormHovered && languages.length === 0) || isFormFocused ? "none" : "block",
                            opacity: ".7"
                        }}
                    />
            }

            {/* Create simple form to add language */}
            <div style={{ display: isFormHovered || isFormFocused ? "block" : "none" }}>
                <Form.Group widths="equal">
                    <SuperField as="choice"
                        label={t('name')}
                        customOptions={languageChoices}
                        value={language.language_name}
                        onChange={(e, { value }) => setLanguage({
                            ...language,
                            language_name: value
                        })}
                        onClick={() => setIsFormFocused(true)}
                    />
                    <SuperField as="choice"
                        label={t('level')}
                        // type="language_level_choices"
                        value={language.level}
                        customOptions={languageLevels}
                        onChange={(e, { value }) => setLanguage({
                            ...language,
                            level: value
                        })}
                        onClick={() => setIsFormFocused(true)}
                    />
                </Form.Group>
                <Divider />
                <Button.Group>
                    <Button
                        primary
                        size="small"
                        type="button"
                        content={t('add')}
                        disabled={language.language_name === "" || language.level === ""}
                        onClick={(e) => addLanguage()}
                    />
                    <Button
                        style={{ marginLeft: "0.1rem" }}
                        size="small"
                        type="button"
                        content={t('close')}
                        onClick={(e) => {
                            setIsFormHovered(false)
                            setIsFormFocused(false)
                        }}
                    />
                </Button.Group>
            </div>
        </SuperFieldSet>

    );
};

export default Languages;