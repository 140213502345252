import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Message } from 'semantic-ui-react';

const UpdateOrderStatusForm = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()

    const [state, setState] = useState("")
    const [processing, setProcessing] = useState(false)


    const handleSubmit = async () => {
        setProcessing(true)
        let updated = []
        for (let i = 0; i < selected.length; i++) {
            if (selected[i]?.order_display?.confirmed_on) {
                const request = await requests.patch(API.ORDERS + selected[i]?.order_display?.id + "/", {
                    order_status: parseInt(state),
                    resourcetype: selected[i].type.order_type_display
                })
    
                if (request.status === 200) {
                    updated.push({
                        stage: selected[i].id,
                        response: request.response
                    }) 
                }
            }
        }

        setData(prev => prev.filter(item => {
            const itemToUpdate = updated.find(updatedItem => updatedItem.stage === item.id)
            if (item.id === itemToUpdate?.stage) {
                item.order_display = itemToUpdate.response
            }
            return item
        }))

        onClose()
        setSelected([])
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon name="information-circle" style={{ marginRight: "0.5rem", fontSize: "1.2rem", position: "relative", top: "0.2rem" }}/> 
                        { t('only_confirmed_orders_will_be_updated')}.
                    </strong>
                }
            />
            
            <SuperField as="choice"
                value={state}
                label={t('state')}
                type="order_status_choices"
                onChange={(e, { value }) => setState(value)}
            />
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={state === "" || processing}
                />
            </Form.Field>
        </Form>
    );
};

export default UpdateOrderStatusForm;