import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { setLocaleLanguage, handleTimeInput, isTimeValid, tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import { Form, Divider, Message, Table, Label } from 'semantic-ui-react'

const findAbsenceType = (type, absenceTypes) => {
    return absenceTypes.find((item) => item.id === type)
}

const AttendanceRecordForm = ({ onClose, shifts, setContracts, setAttendanceRecords, data }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const [absenceTypes, setAbsenceTypes] = useState([])
    const [breakPreferences, setBreakPreferences] = useState({
        firstBreak: 4,
        secondBreak: 8,
    })

    const [absenceData, setAbsenceData] = useState({
        type: '',
        value: '',
        vacation_hours: '',
    })

    const [isArrival, setIsArrival] = useState(true)
    const [isHoursBank, setIsHoursBank] = useState(false)
    const [allowNightBank, setAllowNightBank] = useState(false)
    const [allowAbsenceTypes, setAllowAbsenceTypes] = useState(false)
    const [banks, setBanks] = useState({
        bank_holiday: '',
        bank_saturday: '',
        bank_sunday: '',
        bank_night: '',
        // bank_overtime: '',
    })

    const [processing, setProcessing] = useState(false)
    const [hours, setHours] = useState('')
    const [form, setForm] = useState({
        date: data.day.date,
        time: '06:00',
        employee: data.contract.profile_id,
        contract: data.contract.id,
    })

    const handleSubmit = async () => {
        setProcessing(true)

        let start = null
        let end = null

        if (isArrival) {
            start = moment(data.day.date + ' ' + form.time).format('YYYY-MM-DD HH:mm')
        } else {
            start = createdStartTime()
        }

        if (!isArrival) {
            end = moment(data.day.date + ' ' + form.time).format('YYYY-MM-DD HH:mm')
        } else {
            end = createdEndTime()
        }

        if (end) {
            // convert to UTC
            end = tzDateTime(end, true).format('YYYY-MM-DD HH:mm')
        }

        if (start) {
            // convert to UTC
            start = tzDateTime(start, true).format('YYYY-MM-DD HH:mm')
        }

        // handle absence if exists
        const additionalData = {}
        let source = absenceData.type !== '' ? findAbsenceType(absenceData.type, absenceTypes)?.source : null
        if (source !== null) {
            if (source === 'absence') source = 'absention'

            additionalData[source] = parseFloat(absenceData.value)
            additionalData['absence_type'] = absenceData.type
            if (source === 'vacation') {
                additionalData['vacation_hours'] = absenceData.vacation_hours
            }
        }

        const hoursDiff = hours
        let request = await requests.post(API.ATTENDANCE_BASE + 'records/manual/', {
            date: form.date,
            start: hoursDiff > 0 ? start : null,
            end: hoursDiff > 0 ? end : null,
            contract: form.contract,
            employee: form.employee,
            closed: true,
            is_corrected: true,
            ...additionalData,
        })

        if (request.status === 201) {
            // update contract bank + create history
            let latestContractVersion = null

            if (isHoursBank) {
                const updateBankHours = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 6, // hours
                    operation: 2, // decrease
                    attendance_record: request.response.id,
                    amount: parseFloat(hours),
                    contract: form.contract,
                    note: 'correction_record_create',
                })

                if (updateBankHours.status === 201) {
                    latestContractVersion = updateBankHours.response.contract
                }
            } else {
                const createHistoryOvertimeRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 1, // overtime
                    operation: 2, // decrease
                    attendance_record: request.response.id,
                    amount: parseFloat(hours),
                    contract: form.contract,
                    note: 'correction_record_create',
                })

                if (createHistoryOvertimeRequest.status === 201) {
                    latestContractVersion = createHistoryOvertimeRequest.response.contract
                }
            }

            // others
            if (parseFloat(banks.bank_holiday) > 0) {
                const createHistoryHolidayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 2, // holiday
                    operation: 2, // decrease
                    attendance_record: request.response.id,
                    amount: parseFloat(banks.bank_holiday),
                    contract: form.contract,
                    note: 'correction_record_create',
                })

                if (createHistoryHolidayRequest.status === 201) {
                    latestContractVersion = createHistoryHolidayRequest.response.contract
                }
            }

            if (parseFloat(banks.bank_saturday) > 0) {
                const createHistorySaturdayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 3, // saturday
                    operation: 2, // decrease
                    attendance_record: request.response.id,
                    amount: parseFloat(banks.bank_saturday),
                    contract: form.contract,
                    note: 'correction_record_create',
                })

                if (createHistorySaturdayRequest.status === 201) {
                    latestContractVersion = createHistorySaturdayRequest.response.contract
                }
            }

            if (parseFloat(banks.bank_sunday) > 0) {
                const createHistorySundayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 4, // sunday
                    operation: 2, // decrease
                    attendance_record: request.response.id,
                    amount: parseFloat(banks.bank_sunday),
                    contract: form.contract,
                    note: 'correction_record_create',
                })

                if (createHistorySundayRequest.status === 201) {
                    latestContractVersion = createHistorySundayRequest.response.contract
                }
            }

            if (parseFloat(banks.bank_night) > 0) {
                const createHistoryNightRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                    type: 5, // night
                    operation: 2, // decrease
                    attendance_record: request.response.id,
                    amount: parseFloat(banks.bank_night),
                    contract: form.contract,
                    note: 'correction_record_create',
                })

                if (createHistoryNightRequest.status === 201) {
                    latestContractVersion = createHistoryNightRequest.response.contract
                }
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('correction_record_created'),
                animation: 'pulse',
                time: 2000,
            })

            // update contract banks
            if (latestContractVersion !== null) {
                setContracts((prev) =>
                    prev.filter((item) => {
                        if (item.id === latestContractVersion.id) {
                            item['bank_hours'] = latestContractVersion.bank_hours
                            item['bank_overtime'] = latestContractVersion.bank_overtime
                            item['bank_holiday'] = latestContractVersion.bank_holiday
                            item['bank_saturday'] = latestContractVersion.bank_saturday
                            item['bank_sunday'] = latestContractVersion.bank_sunday
                            item['bank_night'] = latestContractVersion.bank_night
                        }
                        return item
                    })
                )
            }

            // update attendancerecords listing to include corrected record
            setAttendanceRecords((prev) => [
                ...prev,
                {
                    id: request.response.id,
                    employee: request.response.employee?.id,
                    contract: request.response.contract?.id,
                    date: request.response.date,
                    start: request.response.start,
                    end: request.response.end,
                    night_work_hours: request.response?.night_work_hours || 0,
                    duration: request.response?.work_hours || 0,
                    duration_real: request.response?.duration || 0,
                    closed: request.response.closed,
                    vacation: request.response.vacation,
                    vacation_hours: request.response.vacation_hours,
                    paragraph: request.response.paragraph,
                    sick_day: request.response.sick_day,
                    absention: request.response.absention,
                    care_for_a_family_member: request.response.care_for_a_family_member,
                    family_member_paragraph: request.response.family_member_paragraph,
                    absence_type: request.response.absence_type,
                    original_record: request.response.original_record,
                    correction: request.response.correction,
                    is_corrected: request.response.is_corrected,
                    is_saturday: request.response.is_saturday,
                    is_sunday: request.response.is_sunday,
                    is_holiday: request.response.is_holiday,
                    week: moment(request.response.date).isoWeek(),
                },
            ])
            onClose()
        }

        setProcessing(false)
    }

    useEffect(() => {
        const fetchAbsenceTypes = async () => {
            const request = await requests.get(API.ATTENDANCE_BASE + 'absence_types/?is_active=true')
            if (request.status === 200) setAbsenceTypes(request.response)
        }

        async function fetchPreferences() {
            const request = await requests.get(API.PREFERENCES + '?section=attendance_preferences')
            if (request.status === 200) {
                const preferences = request.response
                const firstBreakPref = preferences.find((item) => item.name === 'global_first_break_requirement')
                const secondBreakPref = preferences.find((item) => item.name === 'global_second_break_requirement')
                setBreakPreferences({
                    firstBreak: firstBreakPref !== undefined ? firstBreakPref.value : 4,
                    secondBreak: secondBreakPref !== undefined ? secondBreakPref.value : 8,
                })
            }
        }

        fetchPreferences()
        fetchAbsenceTypes()
    }, [])

    const createdEndTime = () => {
        let end = null
        const hourDiff = parseFloat(hours || 0)
        const minDiff = hourDiff * 60
        const start = form.time ? moment(form.date + ' ' + form.time, 'YYYY-MM-DD HH:mm') : null

        let breakAddition = 0
        if (hourDiff >= parseFloat(breakPreferences.firstBreak)) {
            breakAddition += 30 // 30 min. break addition
        }

        if (parseFloat(breakPreferences.secondBreak) !== 0 && hourDiff >= parseFloat(breakPreferences.secondBreak)) {
            breakAddition += 15 // 15 min. second addition
        }

        if (start) {
            if (parseFloat(minDiff) > 0) {
                end = start.add(parseFloat(minDiff + breakAddition), 'minutes')
            }

            if (parseFloat(minDiff) === 0) {
                end = start
            }
        }

        return end ? end.format('YYYY-MM-DD HH:mm') : null
    }

    const createdStartTime = () => {
        let start = null
        const hourDiff = parseFloat(hours || 0)
        const minDiff = hourDiff * 60
        const end = form.time ? moment(form.date + ' ' + form.time, 'YYYY-MM-DD HH:mm') : null

        let breakAddition = 0
        if (hourDiff >= parseFloat(breakPreferences.firstBreak)) {
            breakAddition += 30 // 30 min. break addition
        }

        if (parseFloat(breakPreferences.secondBreak) !== 0 && hourDiff >= parseFloat(breakPreferences.secondBreak)) {
            breakAddition += 15 // 15 min. second addition
        }

        if (parseFloat(minDiff) > 0) {
            start = end.subtract(parseFloat(minDiff + breakAddition), 'minutes')
        }

        if (parseFloat(minDiff) === 0) {
            start = end
        }

        return start ? start.format('YYYY-MM-DD HH:mm') : null
    }

    const isHourValid = (hours, bankCount) => {
        if (hours === '') return true
        if (isNaN(hours)) return false
        if (parseFloat(hours) <= 0) return false
        if (parseFloat(hours) > bankCount) return false
        return true
    }

    // eslint-disable-next-line
    const isFormValid = () => {
        if (!isTimeValid(form.time)) return false
        if (form.hours <= 0 || isNaN(form.hours) || parseFloat(form.hours) > parseFloat(data.contract.bank_hours))
            return false

        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                info
                visible
                content={
                    <div>
                        {t('selected_day')}:{' '}
                        <strong>
                            {moment(data.day.date).format(dateFormat)} -{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                                {moment(data.day.date).locale(setLocaleLanguage()).format('dddd')}
                            </span>{' '}
                            {data.day.holiday !== null && '(' + t('holiday') + ')'}
                            <br />
                        </strong>
                        {t('employee')}: <strong>{data.contract.fullname}</strong>
                    </div>
                }
            />

            <Table size="tiny" fixed>
                <Table.Row>
                    <Table.Cell
                        style={{
                            width: '80px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        <span
                            onClick={() => setIsHoursBank(true)}
                            className="ref-link"
                            style={{
                                color: isHoursBank ? 'var(--primary)' : 'var(--dark)',
                                textDecoration: isHoursBank ? 'underline' : undefined,
                                cursor: 'pointer',
                            }}
                        >
                            {t('bank_hours')}
                        </span>
                    </Table.Cell>
                    <Table.Cell
                        style={{
                            width: '80px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        <span
                            onClick={() => setIsHoursBank(false)}
                            className="ref-link"
                            style={{
                                color: !isHoursBank ? 'var(--primary)' : 'var(--dark)',
                                textDecoration: !isHoursBank ? 'underline' : undefined,
                                cursor: 'pointer',
                            }}
                        >
                            {t('overtime')}
                        </span>
                    </Table.Cell>
                    <Table.Cell
                        style={{
                            width: '80px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('holiday')}
                    </Table.Cell>
                    <Table.Cell
                        style={{
                            width: '80px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('saturday')}
                    </Table.Cell>
                    <Table.Cell
                        style={{
                            width: '80px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('sunday')}
                    </Table.Cell>
                    <Table.Cell
                        style={{
                            width: '80px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('night')}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{ width: '80px', textAlign: 'center' }}>
                        {data.contract.bank_hours || 0}
                    </Table.Cell>
                    <Table.Cell style={{ width: '80px', textAlign: 'center' }}>
                        {data.contract.bank_overtime || 0}
                    </Table.Cell>
                    <Table.Cell style={{ width: '80px', textAlign: 'center' }}>
                        {data.contract.bank_holiday || 0}
                    </Table.Cell>
                    <Table.Cell style={{ width: '80px', textAlign: 'center' }}>
                        {data.contract.bank_saturday || 0}
                    </Table.Cell>
                    <Table.Cell style={{ width: '80px', textAlign: 'center' }}>
                        {data.contract.bank_sunday || 0}
                    </Table.Cell>
                    <Table.Cell style={{ width: '80px', textAlign: 'center' }}>
                        {data.contract.bank_night || 0}
                    </Table.Cell>
                </Table.Row>
            </Table>

            {isHoursBank && (
                <>
                    <Divider />
                    <Icon
                        name="information-circle-outline"
                        style={{ marginRight: '0.5rem', position: 'relative', top: '0.2rem' }}
                    />{' '}
                    <span>{t('bank_hours_can_store_negative_values')}.</span>
                </>
            )}

            <Divider />

            <Form.Group widths="equal">
                <SuperField
                    as="timepicker"
                    required
                    label={
                        <div style={{ fontWeight: 'bold', marginBottom: '0.3rem' }}>
                            <span
                                onClick={() => setIsArrival(true)}
                                className="ref-link"
                                style={{
                                    color: isArrival ? 'var(--primary)' : 'var(--dark)',
                                    textDecoration: isArrival ? 'underline' : undefined,
                                    cursor: 'pointer',
                                }}
                            >
                                {t('arrival')}
                            </span>{' '}
                            /{' '}
                            <span
                                onClick={() => setIsArrival(false)}
                                className="ref-link"
                                style={{
                                    color: !isArrival ? 'var(--primary)' : 'var(--dark)',
                                    textDecoration: !isArrival ? 'underline' : undefined,
                                    cursor: 'pointer',
                                }}
                            >
                                {t('departure')}
                            </span>
                            <sup style={{ color: 'var(--danger)', marginLeft: '0.5rem' }}>*</sup>
                        </div>
                    }
                    value={form.time}
                    error={!isTimeValid(form.time) ? true : false}
                    onChange={(e, { value }) =>
                        setForm((prev) => ({
                            ...prev,
                            time: handleTimeInput(value),
                        }))
                    }
                />
                <SuperField
                    as="input"
                    required
                    autoFocus
                    value={hours}
                    placeholder={t('enter_hours')}
                    label={isHoursBank ? t('bank_hours') : t('overtime')}
                    onChange={(e, { value }) => setHours(value.replace(',', '.'))}
                    error={
                        (hours <= 0 ||
                            isNaN(hours) ||
                            (!isHoursBank &&
                                parseFloat(hours) >
                                    parseFloat(
                                        isHoursBank ? data.contract.bank_hours : data.contract.bank_overtime
                                    ))) &&
                        hours !== ''
                            ? t('invalid_entry')
                            : false
                    }
                />
            </Form.Group>

            <Table size="small">
                {data.day.isSaturday && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_saturday')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                placeholder={t('enter_hours')}
                                style={{ width: '240px' }}
                                value={banks.bank_saturday}
                                // disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setBanks((prev) => ({ ...prev, bank_saturday: value.replace(',', '.') }))
                                }
                                error={
                                    !isHourValid(banks.bank_saturday, data.contract.bank_saturday)
                                        ? t('invalid_entry')
                                        : false
                                }
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
                {data.day.isSunday && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_sunday')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                style={{ width: '240px' }}
                                placeholder={t('enter_hours')}
                                value={banks.bank_sunday}
                                // disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setBanks((prev) => ({ ...prev, bank_sunday: value.replace(',', '.') }))
                                }
                                error={
                                    !isHourValid(banks.bank_sunday, data.contract.bank_sunday)
                                        ? t('invalid_entry')
                                        : false
                                }
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
                {data.day.holiday !== null && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_holiday')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                placeholder={t('enter_hours')}
                                value={banks.bank_holiday}
                                style={{ width: '240px' }}
                                // disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setBanks((prev) => ({ ...prev, bank_holiday: value.replace(',', '.') }))
                                }
                                error={
                                    !isHourValid(banks.bank_holiday, data.contract.bank_holiday)
                                        ? t('invalid_entry')
                                        : false
                                }
                            />
                        </Table.Cell>
                    </Table.Row>
                )}

                {allowNightBank && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_night')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                placeholder={t('enter_hours')}
                                value={banks.bank_night}
                                style={{ width: '240px' }}
                                // disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setBanks((prev) => ({ ...prev, bank_night: value.replace(',', '.') }))
                                }
                                error={
                                    !isHourValid(banks.bank_night, data.contract.bank_night)
                                        ? t('invalid_entry')
                                        : false
                                }
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table>

            <SuperField
                as="checkbox"
                label={t('allow_to_use_night_hours')}
                checked={allowNightBank}
                onChange={() => {
                    setAllowNightBank(!allowNightBank)
                    setBanks((prev) => ({ ...prev, bank_night: '' }))
                }}
            />

            <SuperField
                as="checkbox"
                label={t('allow_to_assign_work_absence')}
                checked={allowAbsenceTypes}
                onChange={() => {
                    setAllowAbsenceTypes(!allowAbsenceTypes)
                    setAbsenceData({
                        type: '',
                        value: '',
                        vacation_hours: '',
                    })
                }}
            />

            {allowAbsenceTypes && (
                <>
                    <Divider />
                    <SuperField
                        as="choice"
                        search
                        required
                        value={absenceData.type}
                        label={t('absence_type')}
                        customOptions={absenceTypes
                            .filter((item) => item.source !== 'vacation')
                            .map((item) => ({
                                key: item.id,
                                value: item.id,
                                text: item.title,
                            }))}
                        onChange={(e, { value }) => {
                            setAbsenceData((prev) => ({ ...prev, type: value, value: '', vacation_hours: '' }))
                        }}
                    />

                    {findAbsenceType(absenceData.type, absenceTypes)?.source !== 'vacation' ? (
                        <Form.Field>
                            <SuperField
                                as="input"
                                required
                                label={t('number_of_hours')}
                                value={absenceData.value}
                                error={
                                    isNaN(absenceData.value) ||
                                    parseFloat(absenceData.value) > 24 ||
                                    parseFloat(absenceData.value) <= 0
                                        ? t('invalid_number')
                                        : false
                                }
                                onChange={(e, { value }) =>
                                    setAbsenceData((prev) => ({ ...prev, value: value.replace(',', '.') }))
                                }
                            />
                            <Label.Group simple>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.value.toString() === '7.5'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, value: 7.5 }))}
                                >
                                    7.5h
                                </Label>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.value.toString() === '8'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, value: 8 }))}
                                >
                                    8h
                                </Label>
                                <Label
                                    style={{ cursor: 'pointer' }}
                                    active={absenceData.value.toString() === '12'}
                                    onClick={() => setAbsenceData((prev) => ({ ...prev, value: 12 }))}
                                >
                                    12h
                                </Label>
                            </Label.Group>
                        </Form.Field>
                    ) : (
                        <>
                            <SuperField
                                as="choice"
                                required
                                label={t('choose_option')}
                                value={absenceData.value !== '0.00' ? absenceData.value || '' : ''}
                                customOptions={[
                                    { key: 1, value: '1.00', text: t('fullday_vacation') },
                                    { key: 2, value: '0.50', text: t('halfday_vacation') },
                                ]}
                                onChange={(e, { value }) => {
                                    if (parseFloat(value) === 0.5) {
                                        setAbsenceData((prev) => ({ ...prev, value: value, vacation_hours: 4 }))
                                    } else {
                                        setAbsenceData((prev) => ({ ...prev, value: value, vacation_hours: 8 }))
                                    }
                                }}
                            />

                            <Form.Field>
                                <SuperField
                                    as="input"
                                    required
                                    label={t('number_of_hours')}
                                    value={absenceData.vacation_hours}
                                    error={
                                        isNaN(absenceData.vacation_hours) ||
                                        parseFloat(absenceData.vacation_hours) > 24 ||
                                        parseFloat(absenceData.vacation_hours) <= 0
                                            ? t('invalid_number')
                                            : false
                                    }
                                    onChange={(e, { value }) =>
                                        setAbsenceData((prev) => ({ ...prev, vacation_hours: value.replace(',', '.') }))
                                    }
                                />
                                <Label.Group simple>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={absenceData.vacation_hours.toString() === '4'}
                                        onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 4 }))}
                                    >
                                        4h
                                    </Label>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={absenceData.vacation_hours.toString() === '6'}
                                        onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 6 }))}
                                    >
                                        6h
                                    </Label>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={absenceData.vacation_hours.toString() === '7.5'}
                                        onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 7.5 }))}
                                    >
                                        7.5h
                                    </Label>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={absenceData.vacation_hours.toString() === '8'}
                                        onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 8 }))}
                                    >
                                        8h
                                    </Label>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={absenceData.vacation_hours.toString() === '10'}
                                        onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 10 }))}
                                    >
                                        10h
                                    </Label>
                                    <Label
                                        style={{ cursor: 'pointer' }}
                                        active={absenceData.vacation_hours.toString() === '12'}
                                        onClick={() => setAbsenceData((prev) => ({ ...prev, vacation_hours: 12 }))}
                                    >
                                        12h
                                    </Label>
                                </Label.Group>
                            </Form.Field>
                        </>
                    )}

                    <Divider />
                </>
            )}

            <p>
                <strong>{t('corrected_record')}:</strong>
                <br />
                <>
                    {t('arrival')}:{' '}
                    <strong>
                        {form.time !== '' && isArrival
                            ? moment(data.day.date + ' ' + form.time).format(dateFormat + ' HH:mm')
                            : createdStartTime() !== null
                            ? moment(createdStartTime()).format(dateFormat + ' HH:mm')
                            : '--:--'}
                        <br />
                    </strong>
                    {t('departure')}:{' '}
                    <strong>
                        {form.time !== '' && !isArrival
                            ? moment(data.day.date + ' ' + form.time).format(dateFormat + ' HH:mm')
                            : createdEndTime() !== null
                            ? moment(createdEndTime()).format(dateFormat + ' HH:mm')
                            : '--:--'}
                        <br />
                    </strong>
                </>
                {t('working_hours')}:{' '}
                <strong>
                    {hours || 0} {t('hours_shortcut')}
                </strong>
            </p>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={
                        (allowAbsenceTypes && (absenceData.type === '' || absenceData.value === '')) ||
                        (allowAbsenceTypes &&
                            findAbsenceType(absenceData.type, absenceTypes)?.source === 'vacation' &&
                            absenceData.vacation_hours === '') ||
                        processing ||
                        (!isHoursBank ? parseFloat(data.contract.bank_overtime) === 0 : false) ||
                        form.time === '' ||
                        hours <= 0 ||
                        isNaN(hours) ||
                        (!isHoursBank
                            ? parseFloat(hours) > parseFloat(data.contract.bank_overtime) && hours !== ''
                            : false)
                    }
                />
            </Form.Field>
        </Form>
    )
}

export default AttendanceRecordForm
