import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store
import { routes } from '@store/routes';
import { companyService } from '@store/services/ServiceCompany';
// components
import { Icon } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
import Loading from '@components/general/Loading';
import DataTable from '@components/tables/DataTable';

const EmployeesAssignedList = ({ unitID }) => {
    const { t } =useTranslation()

    const [employees, setEmployees] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const fetchEmployees = async () => {
        const result = await companyService.getEmployees(unitID)

        if(result.status === 200){
            setEmployees(result.response.map(employee => {
                return {
                    id: employee.id,
                    name: employee?.name,
                    profile_picture: employee?.profile_picture,
                    position: employee?.position?.name,
                    unitID: employee?.unit?.id,
                    unit: employee?.unit?.name
                }
            }));
        }

        setIsLoading(false)
    }

    useEffect( () => {
        fetchEmployees()
        // eslint-disable-next-line
    }, [])

    const orderedHeaders = [
        'name',
        'position',
        'unit',
    ];

    const headers = {
        id: {
            invisible: true,
        },
        unitID: {
            invisible: true,
        },
        profile_picture: {
            invisible: true,
        },
        name: {
            text: t('fullname'),
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return (
                    <Link to={routes.EMPLYOEES_DETAIL + row.id}>
                        <span style={{ display: "flex", flexDirection: "row" }}>
                            <Icon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                <AvatarIcon size="30" name={row.name} src={row.profile_picture}/>
                            </Icon.Group>
                            <span style={{ display: "flex", flexDirection: "column", marginTop: "0.8rem" }}>
                                <strong>{row.name}</strong>
                            </span>
                        </span>
                    </Link>
                )
            }
        },
        position: {
            text: t('position'),
            sortable: true,
            filterable: true,
        },
        unit: {
            text: t('unit'),
            sortable: true,
            filterable: true,
        }
    }

    return (
        <>
            { isLoading ? <Loading/> :
                <DataTable
                    data={employees}
                    orderedHeaders={orderedHeaders}
                    headers={headers}
                />
            }
        </>
    );
};

export default EmployeesAssignedList;