import React from 'react';
import { useTranslation } from 'react-i18next';
// store
import { routes } from '@routes';
import { icons } from '@icons';
// components
import { Container } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import LogisticBulkImport from './components/LogisticBulkImport';

const LogisticImports = () => {
    const { t } = useTranslation()
    const accessPerms = ['logistics.c_view_all_transports', 'logistics.c_view_user_transports']

    return (
        <CanView permissions={accessPerms} redirect>
            <BreadcrumbNav items={[
                { 'name': t('logistics'), 'icon': icons.LOGISTICS, 'href': routes.LOGISTICS },
                { 'name': t('bulk_import'), 'icon': icons.LOGISTICS, 'href': "" }
            ]}/>

            <Container fluid>
                <LogisticBulkImport/>
            </Container>
        </CanView>
    );
};

export default LogisticImports;