import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import {  Form } from 'semantic-ui-react';
import SuperFieldSet from '@components/forms/SuperFieldSet';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';

const ContactInfo = ({ setData }) => {
    const { t } = useTranslation();

    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")

    useEffect( () => {
        let contacts = null;
        if( phone !== "" || email !== "" ){
            contacts = {
                create: [{is_default: true, phone: phone || "", email: email || ""}]
            }
        }

        setData(prev => ({
            ...prev,
            contacts: contacts
        }))

    }, [phone, email, setData])

    return (
        <SuperFieldSet legend={ t('contact_information') }>
            <Form.Group widths="equal">
                <PhoneField
                    hideType
                    value={phone}
                    placeholder={t('enter_number')}
                    setValue={(e) => setPhone(e.target.value)}
                />
                <EmailField
                    label={t('email')}
                    value={email}
                    setValue={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
        </SuperFieldSet>
    );
};

export default ContactInfo;