import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import { Table, Popup } from 'semantic-ui-react'

const CellEvent = ({
    records,
    shifts,
    determinateRecordType,
    setRecords,
    config,
    setModal,
    index,
    contract,
    day,
    selectedAction,
}) => {
    // eslint-disable-next-line
    const { t } = useTranslation()

    const [selectedCell, setSelectedCell] = useState(null)
    const planner_record_types = useSelector((state) => state.choices?.attendance_record_planner_types)

    const record = records.find(
        (item) =>
            item.contract === contract.id &&
            item.employee === contract.profile_id &&
            item.date === day.date &&
            item.is_deleted === false
    )

    const calculateTime = (start, end, as) => {
        let time = 0
        as = as || 'hours'
        let start_time = start ? tzDateTime(moment(start).format('YYYY-MM-DD HH:mm'), true) : null
        let end_time = end ? tzDateTime(moment(end).format('YYYY-MM-DD HH:mm'), true) : null

        if (start_time !== null && end_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day')
            }

            // Calculate the difference
            if (as === 'minutes') {
                time = moment.duration(end_time.diff(start_time)).asMinutes()
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours()
            }
        }

        return as === 'hours' ? time.toFixed(2) : time
    }

    const handleBreakDeduction = (time_from, time_to, shift) => {
        let duration = calculateTime(time_from, time_to, 'minutes')
        if (shift?.pause_length !== '' && parseFloat(shift?.pause_length) > 0) {
            duration = duration - parseFloat(shift.pause_length || 0)
        }

        if (shift?.second_pause_length !== '' && parseFloat(shift?.second_pause_length) > 0) {
            duration = duration - parseFloat(shift?.second_pause_length || 0)
        }

        return duration > 0 ? parseFloat(duration / 60) : 0
    }

    const handleActionOnClick = () => {
        if (selectedAction?.id === 'remove_record' && record !== undefined) {
            if (record?.id > 0) {
                setRecords((prev) =>
                    prev.filter((item) => {
                        if (item.id === record.id) {
                            item.is_deleted = true
                        }
                        return item
                    })
                )
            } else {
                setRecords((prev) =>
                    prev.filter((item) => {
                        const sameDate = moment(item.date, 'YYYY-MM-DD').isSame(moment(record.date, 'YYYY-MM-DD'))
                        const sameContract = item.contract === record.contract
                        const sameEmployee = item.employee === record.employee

                        if (sameDate === true && sameContract === true && sameEmployee === true) {
                            return false
                        }

                        return true
                    })
                )
            }
            return
        }

        if (selectedAction !== null && selectedAction?.id !== 'remove_record' && record === undefined) {
            const shift = shifts.find((item) => item.id === selectedAction?.id)
            const dateTimeFrom = shift?.time_from
                ? moment(day.date + ' ' + shift.time_from).format('YYYY-MM-DD HH:mm')
                : null
            const dateTimeTo = shift?.time_to ? moment(day.date + ' ' + shift.time_to).format('YYYY-MM-DD HH:mm') : null
            const duration = handleBreakDeduction(dateTimeFrom, dateTimeTo, shift)
            setRecords((prev) => [
                ...prev,
                {
                    id: 0,
                    is_deleted: false,
                    contract: contract.id,
                    employee: contract.profile_id,
                    date: day.date,
                    week: day.week,
                    type: determinateRecordType(selectedAction),
                    type_display: planner_record_types?.[determinateRecordType(selectedAction)] || null,
                    shift: shift?.id || null,
                    color: shift?.color || '#000000',
                    time_from: shift?.time_from ? shift?.time_from : null,
                    time_to: shift?.time_to ? shift?.time_to : null,
                    duration: parseFloat(duration),
                    duration_display: duration.toString().replace('.00', ''),
                },
            ])
            return
        }

        if (selectedAction === null) {
            setModal((prev) => ({ ...prev, open: true, selectedDay: day, contract: contract }))
            return
        }
    }

    useEffect(() => {
        setSelectedCell(null)
        // eslint-disable-next-line
    }, [selectedAction, records])

    return (
        <Table.Cell
            key={index}
            verticalAlign="middle"
            onMouseEnter={() => setSelectedCell(contract.id + day.date)}
            onMouseLeave={() => setSelectedCell(null)}
            className={
                selectedCell === contract.id + day.date
                    ? selectedAction?.id === 'remove_record'
                        ? record !== undefined
                            ? 'hovered-cell-remove'
                            : ''
                        : record === undefined
                        ? 'hovered-cell'
                        : ''
                    : ''
            }
            style={{
                padding: 0,
                minWidth: '50px',
                background: !config.highlightedWeeks.includes(day.week) ? 'transparent' : 'var(--light)',
            }}
            onClick={() => handleActionOnClick()}
        >
            <OverlayCellAction
                record={record}
                shifts={shifts}
                selectedAction={selectedAction}
                isHovered={selectedCell === contract.id + day.date}
            />
        </Table.Cell>
    )
}

const OverlayCellAction = ({ isHovered, shifts, selectedAction, record }) => {
    const { t } = useTranslation()

    return (
        <>
            {record === undefined && selectedAction?.id !== 'remove_record' ? (
                <div
                    style={{
                        display: isHovered ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 auto',
                        cursor: 'pointer',
                        background: 'transparent',
                        color: 'var(--dark)',
                    }}
                >
                    <Icon name={'add-outline'} />
                </div>
            ) : record !== undefined ? (
                isHovered && selectedAction?.id === 'remove_record' ? (
                    <div
                        style={{
                            display: isHovered ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto',
                            cursor: 'pointer',
                            background: 'transparent',
                            color: 'var(--dark)',
                        }}
                    >
                        <Icon name={'remove-outline'} />
                    </div>
                ) : (
                    <Popup
                        position="top center"
                        style={{ marginBottom: '1rem' }}
                        trigger={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '0 auto',
                                    cursor: 'pointer',
                                    background:
                                        record.type === 3
                                            ? record?.color || 'transparent'
                                            : record.type === 2
                                            ? 'orange'
                                            : record.type === 1
                                            ? 'var(--danger)'
                                            : '#000000',
                                    color: 'var(--light)',
                                    fontWeight: 'bold',
                                    padding: '0.2rem',
                                }}
                            >
                                <div style={{ fontSize: '11px' }}>
                                    {record.type === 3
                                        ? record?.duration_display + 'h.'
                                        : record.type === 2
                                        ? t('doctor')
                                        : record.type === 1
                                        ? t('vacation_shortcut')
                                        : ''}
                                </div>
                            </div>
                        }
                        content={
                            <div style={{ textAlign: 'center' }}>
                                {record.type === 3 && (
                                    <>
                                        <div>{shifts.find((item) => item.id === record.shift)?.title || ''}</div>
                                        {record.time_from} - {record.time_to} <br />
                                    </>
                                )}
                                <small>
                                    <strong>{record.type_display}</strong>
                                </small>
                            </div>
                        }
                    />
                )
            ) : null}
        </>
    )
}

export default CellEvent
