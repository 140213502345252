import React from 'react';
import { useTranslation } from "react-i18next"
// components
import { Grid, Header } from 'semantic-ui-react';
import Icon from '@components/Icon';
import ContactDetails from '@components/ContactDetails';

const HeaderInfo = ({ accommodation }) => {
    const { t } = useTranslation();
    
    return (
        <>
            <Grid centered className="header-block" style={{ padding: "1rem", borderRadius: "5px" }}>
                <Grid.Row>
                    <Grid.Column textAlign='left' width={8}>
                        <Header as={'h2'}>
                            {accommodation.name}
                            <Header.Subheader content={accommodation.accommodation_type_display} />
                        </Header>
                        <div style={{ paddingTop: "1rem" }}>
                            <Header as={'h4'} content={t('contact_person')} style={{marginBottom: '0.2rem'}} />
                            <div style={{paddingBottom: "0.5rem"}}>
                                <>{accommodation?.contact_person_name}</>
                                <ContactDetails
                                    phone={accommodation?.phone}
                                    email={accommodation?.email}
                                />
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8} style={{paddingTop: "1rem"}}>
                        <div>
                            {accommodation?.price_per_night_per_person ? 
                                <>
                                    <Icon name="logo-euro" style={{ marginRight: "0.5rem" }} />
                                    <span>{accommodation?.price_per_night_per_person + " " + accommodation?.currency + " " + t("per_night")}</span>
                                </>
                                : ''
                            }
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                            {accommodation?.address ?
                                <>
                                    <Icon name="location-outline" style={{ marginRight: "0.5rem" }} />
                                    <strong>{accommodation?.address?.city}</strong> 
                                    <br />
                                    <div style={{paddingLeft: "1.5rem"}}>{accommodation?.address?.street + " " + accommodation?.address?.number + ", " + accommodation?.address?.postcode}</div>
                                    <div style={{paddingLeft: "1.5rem"}}>{ accommodation?.address?.state 
                                        ? (accommodation?.address?.state + ", ") + accommodation?.address?.country_display 
                                        : accommodation?.address?.country_display }
                                    </div>
                                </>
                                : "-"
                            }
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                            {accommodation?.website ?
                                <>
                                    <Icon name="globe-outline" style={{ marginRight: "0.5rem" }}/><a href={accommodation.website} target="_blank" rel="noopener noreferrer">{accommodation.website}</a>
                                </>
                                : "-"
                            }
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default HeaderInfo;