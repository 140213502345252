import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useIsMount } from '@helpers/hooks';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuggestionField from '@components/forms/common/SuggestionField';

const CandidateFilters = ({ recruitments, filters, setFilters }) => {
    const { t } = useTranslation()
    
    const isMount = useIsMount()

    const reduxUser = useSelector(state => state.user.profile_id)
    const seniority = useSelector((state) => state.choices.seniority_level_types)
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [isExact, setIsExact] = useState(false)

    const [stages, setStages] = useState([])

    useEffect(() => {
        async function fetchRecruitmentStages(){
            let stageList = []
            if (filters.recruitment !== "") {
                const recruitment = recruitments.find(item => item.id === filters.recruitment)
                if (recruitment !== undefined) {
                    stageList = recruitment.archived_recruitment_stages
                }
            }

            setStages(stageList)
        }

        fetchRecruitmentStages()
        // eslint-disable-next-line
    }, [filters.recruitment])

    useEffect(() => {
        async function fetchSkills(){
            setLoading(true)
            const request = await requests.get(API.CANDIDATES + "filter_skills/")

            if( request.status === 200 ){
                const skills = request.response
                const skillSeniorityOptions = []
                for (let i = 0; i < skills.length; i++) {
                    skillSeniorityOptions.push({ key: skills[i].id, value: skills[i].name, text: skills[i].name });
                    for (const property in seniority) {
                        skillSeniorityOptions.push({ key: skills[i].id, value: skills[i].name + "__" + property, text: skills[i].name + " - " + seniority[property] });
                    }
                }

                setOptions(skillSeniorityOptions)
            }

            setLoading(false)
        }

        fetchSkills()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isMount) {
            if (isExact) {
                handleSkillAndSeniorityFilter(filters.skill_and_seniority, false, true)
            } else {
                handleSkillAndSeniorityFilter(filters.skill_and_seniority_conjoined, false, true)
            }
        }
        // eslint-disable-next-line
    }, [isExact])

    const handleSkillAndSeniorityFilter = (filterValue, isValue, isExactChanged) => {
        let value = []
        if (isValue) {
            const splitValue = filterValue.split('--')
            for (let i = 0; i < splitValue.length; i++) {
                if (splitValue[i] !== "") {
                    value.push(splitValue[i])
                }
            }
        } else {
            if (isExactChanged) {
                let values = []
                const splitValue = filterValue.split('--')
                for (let i = 0; i < splitValue.length; i++) {
                    if (splitValue[i] !== "") {
                        values.push(splitValue[i])
                    }
                }

                filterValue = values
            }

            let skillString = ""
            let skill_and_seniority = ""
            let skill_and_seniority_conjoined = ""
            for (let i = 0; i < filterValue.length; i++) {
                if (i + 1 === filterValue.length) {  // last item
                    skillString += filterValue[i];
                } else {
                    skillString += filterValue[i] + "--";
                }
            }
            if (isExact) {
                skill_and_seniority = ""
                skill_and_seniority_conjoined = skillString
            } else {
                skill_and_seniority_conjoined = ""
                skill_and_seniority = skillString
            }

            setFilters(prev => ({...prev, 
                skill_and_seniority: skill_and_seniority,
                skill_and_seniority_conjoined: skill_and_seniority_conjoined
            }))
        }
    
        return value
    }

    return (
        <>
            <Form.Group widths="equal">
                <SuggestionField
                    label={t('city')}
                    endpoint={API.CANDIDATES + "filter_city"}
                    text="city"
                    search="city"
                    value={filters.city}
                    onSelect={(value) => setFilters({
                        ...filters,
                        city: value
                    })}
                />

                <SuggestionField
                    label={t('region')}
                    endpoint={API.CANDIDATES + "filter_state"}
                    text="state"
                    search="state"
                    value={filters.state}
                    onSelect={(value) => setFilters({
                        ...filters,
                        state: value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    type="countries"
                    label={t('country')}
                    value={filters.country}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        country: value
                    })}
                />

                <SuperField as="choice"
                    search
                    value={filters.education}
                    type="education_type_choices"
                    label={t('education')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        education: value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    value={filters.language}
                    text="title"
                    endpoint={API.COMMON + "language_names/"}
                    label={t('language')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        language: value
                    })}
                />

                <SuperField as="choice"
                    search
                    value={filters.languageLevel}
                    type="language_level_choices"
                    label={t('language_level')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        language_level: value
                    })}
                />
            </Form.Group>


            <SuperField as="choice"
                search
                multiple
                disabled={loading}
                loading={loading}
                customOptions={options}
                label={ t('skills') }
                value={handleSkillAndSeniorityFilter(isExact ? filters.skill_and_seniority_conjoined : filters.skill_and_seniority, true)}
                onChange={(e, { value }) => handleSkillAndSeniorityFilter(value, false)}
            />
            <Form.Field>
                <SuperField as="checkbox"
                    label={t('all_selected_skills_must_match')}
                    checked={isExact}
                    onChange={() => setIsExact(!isExact)}
                />
            </Form.Field>

            <SuperField as="choice"
                search
                multiple
                value={filters.contact_labels}
                label={t('labels')}
                endpoint={API.CONTACT_LABELS}
                text="name"
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    contact_labels: value
                })}
            />
            <SuperField as="choice"
                value={filters.gender}
                type="genders"
                label={t('gender')}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    gender: value
                })}
            />

            <SuperField as="choice"
                label={t('show_duplicates')}
                value={filters.show_duplicates}
                customOptions={[
                    { key: 1, value: true, text: t('yes') },
                    { key: 2, value: false, text: t('no') },
                ]}
                onChange={(e, { value }) => setFilters(prev => ({
                    ...prev,
                    show_duplicates: value
                }))}
            />

            <SuperField as="checkbox"
                label={ t('show_candidates_that_are_not_in_active_hiring') }
                checked={filters.in_active_recruitment === false}
                onChange={() => setFilters(prev => ({
                    ...prev, 
                    in_active_recruitment: (filters.in_active_recruitment === "" || filters.in_active_recruitment === true) ? false : ""
                }))}
            />

            <SuperField as="checkbox"
                label={ t('show_candidates_with_multiple_active_hiring_rooms') }
                checked={filters.has_multiple_active_recruitments === false}
                onChange={() => setFilters(prev => ({
                    ...prev, 
                    has_multiple_active_recruitments: (filters.has_multiple_active_recruitments === "" || filters.has_multiple_active_recruitments === true) ? false : ""
                }))}
            />

            <Divider/>

            <SuperField
                as="choice"
                search
                label={t('recruiter')}
                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                text="fullname"
                value={filters.assigned_to_recruiter}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    assigned_to_recruiter: value
                })}
            />
            <SuperField as="checkbox"
                label={t('assigned_to_me')}
                checked={filters.assigned_to_recruiter === reduxUser}
                onChange={() => setFilters({
                    ...filters,
                    assigned_to_recruiter: filters.assigned_to_recruiter === reduxUser ? "" : reduxUser
                })}
            />

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    label={t('hiring_room')}
                    customOptions={recruitments.map(item => ({ key: item.id, value: item.id, text: item.name }))}
                    value={filters.recruitment}
                    onChange={(e, { value }) => {
                        if (value === "") {
                            setFilters({
                                ...filters,
                                recruitment: "",
                                exclude_blacklisted: "",
                                exclude_rejected: "",
                                current_stage: ""
                            })
                        } else {
                            setFilters({
                                ...filters,
                                recruitment: value
                            })
                        }

                    }}
                />
                <SuperField as="choice"
                    search
                    label={t('stage')}
                    customOptions={stages?.map(item => ({ key: item.id, value: item.id, text: item.name })) || []}
                    disabled={filters.recruitment === ""}
                    value={filters.current_stage}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        current_stage: value
                    })}
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={ t('exclude_rejected') }
                disabled={filters.recruitment === ""}
                checked={filters.exclude_rejected === true}
                onChange={() => setFilters(prev => ({
                    ...prev, 
                    exclude_rejected: filters.exclude_rejected === true ? "" : true
                }))}
            />

            <SuperField as="checkbox"
                label={ t('exclude_blacklisted') }
                disabled={filters.recruitment === ""}
                checked={filters.exclude_blacklisted === true}
                onChange={() => setFilters(prev => ({
                    ...prev, 
                    exclude_blacklisted: filters.exclude_blacklisted === true ? "" : true
                }))}
            />
        </>
    );
};

export default CandidateFilters;