import React from 'react';
import { useTranslation } from "react-i18next";
import { Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
// import BonusesFieldGroup from './BonusesFieldGroup';
// import SuplementsFieldGroup from './SuplementsFieldGroup';

const SalariesFieldGroup = ({ salary, setSalary }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Form.Group widths='equal'>
                <SuperField as="choice"
                    type="job_salary_type"
                    label={t('salary_type')}
                    value={salary.salary_type}
                    onChange={(e, data) => setSalary({
                        ...salary,
                        salary_type: data.value
                    })}
                />
                <SuperField as="choice"
                    search
                    type="currency_codes"
                    label={t('currency')}
                    value={salary.currency}
                    onChange={(e, data) => setSalary({
                        ...salary,
                        currency: data.value
                    })}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField as="input"
                    label={t('min')}
                    placeholder={t('min')}
                    value={salary.min}
                    error={ salary.min === "" ? false : salary.min < 0 ? t('value_higher_then_zero')
                        : parseFloat(salary.min) >= parseFloat(salary.max)
                        ? t('min_lower_then_max')
                        : false
                    }
                    onChange={(e) => setSalary({
                        ...salary,
                        min: e.target.value.replace(/,/g, '.')
                    })
                    }
                />
                <SuperField as="input"
                    label={t('max')}
                    placeholder={t('max')}
                    value={salary.max}
                    error={ salary.max === "" ? false : salary.max < 0 ? t('value_higher_then_zero')
                        : parseFloat(salary.max) <= parseFloat(salary.min)
                        ? t('max_higher_then_min')
                        : false
                    }
                    onChange={(e) => setSalary({
                        ...salary,
                        max: e.target.value.replace(/,/g, '.')
                    })
                    }
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField as="input"
                    label={t('variable_remuneration_ratio')}
                    value={salary.variable_remuneration_ratio}
                    error={salary.variable_remuneration_ratio === "" ? false : salary.variable_remuneration_ratio < 0 ? t('value_higher_then_zero') : false}
                    onChange={(e, { value }) => setSalary({ ...salary, variable_remuneration_ratio: value })}
                    />
                <SuperField as="input"
                    label={t('variable_remuneration_min_value')}
                    value={salary.variable_remuneration_min_value}
                    error={salary.variable_remuneration_min_value === "" ? false : salary.variable_remuneration_min_value < 0 ? t('value_higher_then_zero') 
                        : parseFloat(salary.variable_remuneration_min_value) > parseFloat(salary.variable_remuneration_max_value)
                        ? t('min_lower_then_max')
                        : false
                    }
                    onChange={(e, { value }) => setSalary({ ...salary, variable_remuneration_min_value: value })}
                    />
                <SuperField as="input"
                    label={t('variable_remuneration_max_value')}
                    value={salary.variable_remuneration_max_value}
                    error={salary.variable_remuneration_max_value === "" ? false : salary.variable_remuneration_max_value < 0 ? t('value_higher_then_zero') 
                        : parseFloat(salary.variable_remuneration_min_value) > parseFloat(salary.variable_remuneration_max_value)
                        ? t('max_higher_then_min')
                        : false
                    }
                    onChange={(e, { value }) => setSalary({ ...salary, variable_remuneration_max_value: value })}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField as="textarea"
                    label={t('additional_information')}
                    value={salary.additional_info}
                    onChange={(e) => setSalary({
                        ...salary,
                        additional_info: e.target.value
                    })
                    }
                />
            </Form.Group>
        </div>
    );
};

export default SalariesFieldGroup;