import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isValidEmail, isValidRepeatPassword, isEmpty } from '@helpers/validation';
// components 
import { Form, Divider, Icon, Header, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';

const CreateAccountForm = ({ setVerifyEmail, setEmail }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [usernameExist, setUsernameExist] = useState(null)
    const [emailExist, setEmailExist] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    // eslint-disable-next-line
    const [accountErrors, setAccountErrors] = useState([])
    const [accounts, setAccounts] = useState([{
        name: "",
        id_number: ""
    }])
    const [form, setForm] = useState({
        email: "",
        username: "",
        password: "",
        repeatPassword: ""
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        setUsernameExist(null)
        setEmailExist(null)
        setPasswordError(null)

        const request = await requests.post(API.INVOICES_CREATE_USER_WITH_ACCOUNTS + "?origin="+ window.location.href, {
            email: form.email,
            username: form.username,
            password: form.password,
            accounts: accounts.filter(item => (item.name !== "" && item.id_number !== ""))
        })

        if( request.status === 204 ){
            setVerifyEmail(true)
            setEmail(form.email)
        } else {
            if( request.response.email ){
                setEmailExist(request.response.email[0])
            }

            if( request.response.username ){
                setUsernameExist(request.response.username[0])
            }

            if( request.response.password ){
                setPasswordError(request.response.password[0])
            }

            if( request.response.accounts ){
                setAccountErrors(request.response.accounts)
            }

        }

        setIsProcessing(false)
    }

    const addAccount = () => {
        setAccounts(prevState => [...prevState, {
            name: "",
            id_number: ""
        }])
    }

    const isAccountsValid = () => {
        return accounts.filter(account => (account.name === "" || account.id_number === "")).length === 0
    }

    const removeAccount = (index) => {
        setAccounts(prevState => prevState.filter((item, idx) =>  idx !== index))
    }

    const onChange = (index, updatedKey, value) => {
        setAccounts(prevState => prevState.filter((account, key) => {
            if( index === key ){
                account[updatedKey] = value
            }

            return account
        }))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('email')}
                    required
                    icon="mail"
                    value={ form.email }
                    placeholder={t('enter_email')}
                    error={ emailExist !== null 
                        ? emailExist
                        : (!isValidEmail(form.email) && !isEmpty(form.email)) ? t('enter_email') + "!" : null
                     }
                    onChange={ (e, { value }) => {
                        setEmailExist(null)
                        setForm({...form, email: value})
                    }}
                />
                <SuperField as="input"
                    required
                    icon="user"
                    label={t('username')}
                    placeholder={t('username')}
                    error={usernameExist}
                    value={ form.username }
                    onChange={ (e, { value }) => {
                        setUsernameExist(null)
                        setForm({...form, username: value})
                    }}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input" type="password"
                    required
                    icon="lock"
                    label={t('password')}
                    placeholder={t('password')}
                    error={passwordError}
                    value={ form.password }
                    onChange={ (e, { value }) => {
                        setPasswordError(null)
                        setForm({...form, password: value})
                    }}
                />
                <SuperField as="input" type="password"
                    required
                    icon="lock"
                    label={t('repeat_password')}
                    placeholder={t('repeat_password')}
                    error={ !isValidRepeatPassword(form.password, form.repeatPassword) 
                        ? form.repeatPassword === "" 
                            ? null 
                            : t('the_passwords_you_entered_do_not_match') 
                        : null 
                    }
                    value={ form.repeatPassword }
                    onChange={ (e, { value }) => setForm({...form, repeatPassword: value})}
                />
            </Form.Group>

            <Divider/>

            <Header as="h3" content={t('accounting_journals')}/>
            <p>
                <span style={{ color: "var(--danger)" }}>{ t('at_least_one_accounting_journal_has_to_be_created') }</span><br/>
                { t('create_one_or_multiple_accounting_journals') }. 
            </p>
            <Divider/>

            <Form.Group style={{ marginBottom: "0" }}>
                <Form.Field width="10" label={t('name')} required/>
                <Form.Field width="4" label={t('id_number')} required/>
                <Form.Field width="2" label={""}/>
            </Form.Group>
            { accounts.map((account, index) => (
                <Form.Group key={index}>
                    <SuperField
                        as="input"
                        width="10"
                        placeholder={t('name')}
                        value={account.name}
                        onChange={ (e, { value }) => onChange(index, "name", value)}
                    />
                    <SuperField
                        as="input"
                        width="4"
                        placeholder={t('id_number')}
                        value={account.id_number}
                        onChange={ (e, { value }) => onChange(index, "id_number", value)}
                    />
                    <Form.Field width="2" style={{ textAlign: "center", marginTop: "0.7rem" }}>
                        <Icon name="close" style={{ cursor: "pointer", color: "var(--danger)" }} onClick={ () => removeAccount(index) }/>
                    </Form.Field>
                </Form.Group>
            )) }

            <Button
                primary
                size="small"
                type="button"
                content={t('add')}
                onClick={ () => addAccount() }
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalSubmit 
                    size="large"
                    disabled={
                        isProcessing || 
                        isEmpty(form.username) ||
                        isEmpty(form.password) ||
                        isEmpty(form.name) ||
                        !isValidEmail(form.email) ||
                        !isValidRepeatPassword(form.password, form.repeatPassword) ||
                        !isAccountsValid()
                    }
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default CreateAccountForm;