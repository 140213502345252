import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { handleURL } from '@helpers/functions';
import { useSelectOptions, useHasPermission } from '@helpers/hooks';
// components 
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Header, Message } from 'semantic-ui-react';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';

const OpportunityForm = ({ isOpportunity, data, setData, setTotal, handleRequest, onClose }) => {
    const { t } = useTranslation()

    const canManageSources = useHasPermission('orders.c_manage_sale_source')
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [sourceTypes, setSourceTypes] = useSelectOptions(API.ORDERS + "sale_sources/?query={id, title}", "title")

    const [form, setForm] = useState({
        id: data?.id || "",
        title: data?.title || "",
        firstname: data?.firstname || "",
        lastname: data?.lastname || "",
        company_id: data?.company_id || "",
        company_name: data?.company_name || "",
        email: data?.email || "",
        phone: data?.phone || "",
        url: data?.url || "",
        source: data?.source?.id || "",
        assigned_to: data?.assigned_to?.map(item => item.id) || [],
        desription: data?.desription || "",
        // note: data?.note || "",
        unit: data?.unit?.id || "",
        stage: data?.stage?.toString() || "1",
        state: data?.state?.toString() || "1",
    })

    const isFormValid = () => {
        if (form.firstname !== "" || form.lastname !== "" || form.company_name !== "") return true

        return false
    }

    const handleAddItem = async (value) => {
        setSourceTypes({ ...sourceTypes, isLoading: true })
        const request = await requests.post(API.ORDERS + "sale_sources/", {
            title: value
        })

        if (request.status === 201) {
            setSourceTypes(prev => {
                return {
                    isLoading: false,
                    options: [{
                        key: request.response.id,
                        value: request.response.id,
                        text: request.response.title
                    }, ...prev.options]
                }
            })
            setForm(prev => ({...prev, source: request.response.id}))
        }
        
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)

        let assign = {
            remove: data?.assigned_to ? data.assigned_to.map(item => item.id): [],
            add: form.assigned_to
        }

        if (data?.id === undefined) {
            delete assign.remove
        }

        let formData = {
            title: form.title,
            description: form.description,
            firstname: form.firstname,
            lastname: form.lastname,
            company_id: form.company_id,
            company_name: form.company_name,
            email: form.email,
            phone: form.phone,
            url: handleURL(form.url),
            unit: form.unit !== "" ? form.unit : null,
            stage: form.stage !== "" ? form.stage : null,
            state: form.state !== "" ? form.state : null,
            source: form.source,
            assigned_to: assign,
            // note: form.note,
        }

        if (!isOpportunity) {
            // formData['is_opportunity'] = false;

            if (form.assigned_to.length > 0 && parseInt(form.state) === 1) {
                formData['state'] = 2;
            }
            
            if (form.assigned_to.length === 0 && parseInt(form.state) === 2) {
                formData['state'] = 1;
            }
        } else{
            formData['is_opportunity'] = true;
            formData['state'] = 5;
        }

        let request = undefined

        if (!form.id || form.id === 0) {
            request = await requests.post(API.LEADS, formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            }
        } else {
            request = await requests.patch(API.LEADS + data.id + "/", formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            { data?.id !== undefined &&
                <>
                    <Header as="h3" content={t('update')}/>
                    <Divider/>
                </>
            }
            <Message
                info
                visible
                content={
                    <strong>
                        <Icon name="information-circle" style={{ marginRight: "0.5rem", fontSize: "1.2rem", position: "relative", top: "0.2rem" }}/> 
                        { t('lead_form_hint')}.
                    </strong>
                }
            />
            <NonFieldErrors errors={errors} />
            <SuperField as="input"
                autoFocus
                label={t('position')}
                value={form.title}
                error={errors?.title?.[0]}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />
            <Form.Group widths='equal'>
                <SuperField as="input"
                    label={t('firstname')}
                    value={form.firstname}
                    error={errors?.firstname?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, firstname: value })}
                />
                <SuperField as="input"
                    label={t('lastname')}
                    value={form.lastname}
                    error={errors?.lastname?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, lastname: value })}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField as="input"
                    label={t('company_name')}
                    value={form.company_name}
                    error={errors?.company_name?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, company_name: value })}
                />
                <SuperField as="input"
                    label={t('registration_id')}
                    value={form.company_id}
                    error={errors?.company_id?.[0]}
                    onChange={(e, { value }) => setForm({ ...form, company_id: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={form.phone}
                    error={errors?.phone?.[0]}
                    setValue={(e, { value }) => setForm({
                        ...form,
                        phone: value.replaceAll(" ", "")
                    })}
                />
                <EmailField
                    label={t('email')}
                    placeholder={t('enter_email')}
                    value={form.email}
                    error={errors?.email?.[0]}
                    setValue={(e, { value }) => setForm({
                        ...form,
                        email: value
                    })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('url')}
                    value={form.url}
                    error={errors?.url?.[0]}
                    onChange={ (e, { value }) => setForm({ ...form, url: value })}
                />
                
                <SuperField 
                    as="choice"
                    search
                    allowAdditions={canManageSources}
                    label={t('source')}
                    disabled={sourceTypes.isLoading}
                    loading={sourceTypes.isLoading}
                    customOptions={sourceTypes.options}
                    onAddItem={(e, { value }) => handleAddItem(value)}
                    value={form.source}
                    onChange={(e, { value }) => setForm({ 
                        ...form, 
                        source: value 
                    })}
                />
            </Form.Group>

            <SuperField as="textarea"
                label={t('description')}
                value={form.description}
                error={errors?.description?.[0]}
                onChange={ (e, { value }) => setForm({ ...form, description: value })}
            />

            <Form.Group widths='equal'>
                {!isOpportunity 
                    ? 
                        <SuperField 
                            search
                            key={isOpportunity}
                            as="choice"
                            label={t('state')}
                            type="lead_state_choices"
                            value={form.state || ""}
                            error={errors?.stage?.[0]}
                            onChange={(e, { value }) => setForm({ 
                                ...form, 
                                state: value 
                            })}
                        />
                    : 
                        <SuperField
                            search
                            key={isOpportunity}
                            as="choice"
                            label={t('stage')}
                            type="opportunity_stage_choices"
                            error={errors?.stage?.[0]}
                            value={form.stage || ""}
                            onChange={(e, { value }) => setForm({ 
                                ...form, 
                                stage: value 
                            })}
                        />
                }

                <SuperField as="choice"
                    search
                    value={form.unit}
                    endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                    text="name"
                    label={t('unit')}
                    error={errors?.stage?.[0]}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        unit: value
                    })}
                />
            </Form.Group>

            <SuperField
                as="choice"
                search
                multiple
                label={t('assigned_to')}
                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                text="fullname"
                error={errors?.assigned_to?.[0]}
                value={form.assigned_to}
                onChange={(e, { value }) => setForm({
                    ...form,
                    assigned_to: value
                })}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t("confirm")}
                    disabled={isProcessing || !isFormValid()}
                    loading={isProcessing} 
                />
            </Form.Field>
        </Form>
    );
};

export default OpportunityForm;