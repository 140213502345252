import React from 'react';
// components
// import InfoRow from '../../../../../components/general/InfoRow';
import { Button/*, Header, Grid, Label */, Divider, Message } from 'semantic-ui-react';
// others
// import moment from 'moment';
import { useTranslation } from "react-i18next"

const SummaryView = ({ person, birthInfo, permanentAddress, temporaryAddress, visible, isProcessing, errors }) => {
    const { t } = useTranslation();

    return (
        <div>
            { visible &&
                <>
                    {/* <Header textAlign="center" as="h2">Confirm submission</Header> */}
                    {/* <Divider/> */}

                    {/* <Grid>
                    <Grid.Row>
                        <Grid.Column computer={8}>
                            <Header as="h4">Personal Information</Header>
                            <InfoRow desc="Personal Number:" value={person.personalNumber}/>
                            <InfoRow desc="Firstname:" value={person.firstname}/>
                            <InfoRow desc="Lastname:" value={person.lastname}/>
                            <InfoRow desc="Middlename:" value={person.middlename}/>
                            <InfoRow desc="Titles:" value={person.titleBefore + ", " + person.titleAfter}/>
                            <InfoRow desc="Gender:" value={person.gender}/>
                            <InfoRow desc="Marital Status:" value={person.maritalStatus}/>

                            <Header as="h4">Birth Information</Header>
                            <InfoRow desc="Date of birth:" value={moment(birthInfo.dateOfBirth).format("DD.MM.YYYY")}/>
                            <InfoRow desc="Place of birth:" value={birthInfo.placeOfBirth}/>
                            <InfoRow desc="Nationality:" value={birthInfo.nationality}/>
                            <InfoRow desc="Country of Birth:" value={birthInfo.countryOfBirth}/>
                            <InfoRow desc="Citizenship:" value={birthInfo.citizenship}/>
                            <InfoRow desc="Name at Birth:" value={birthInfo.nameAtBirth}/>
                            <InfoRow desc="All Previous Surnames:" value={birthInfo.allPreviousSurnames}/>
                        </Grid.Column>
                        <Grid.Column computer={8}>
                            <Header as="h4">Permanent Address</Header>
                            <Header as="h4">Temporary Address</Header>
                            { temporaryAddress.street ? (
                                ""
                            ) : <Label> Employee doesn't have Temporary Address </Label>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid> */}

                    <Message
                        error
                        visible={errors.length > 0}
                        header={t("message_issue")}
                        list={errors}
                    />

                    <Message style={{ textAlign: "center" }} positive>
                        <Message.Header>{t("header")}</Message.Header>
                        <Message.Content>
                            {t("content_message")}
                        </Message.Content>
                    </Message>

                    <Divider />

                    <Button.Group style={{ width: "100%" }}>
                        <Button
                            primary
                            loading={isProcessing}
                            disabled={isProcessing}
                            type="submit"
                            size="big"
                            style={{ marginLeft: "auto", marginRight: "auto" }}>
                            {t("btn_create")}
                        </Button>
                    </Button.Group>
                </>
            }
        </div>
    );
};

export default SummaryView;