import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
// store
import { routes } from '@routes';
import { authService } from '@services/ServiceAuth';
// components
import CanAccess from '@components/perms/CanAccess';
import Loading from '../general/Loading';


const PrivateRoute = ({component: Component, modules, ...rest}) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPath] = useState(window.location.pathname);

    useEffect( () => {
        async function checkUser() {
            const result = await authService.validateToken();
            setIsLoading(false);

            if( result === false ){
                history.push(routes.LOGIN);
            }
        };

        checkUser();
    }, [history, currentPath]);


    return (
        <CanAccess allowedModules={modules} redirect>
            { !isLoading ?
                <Route {...rest} render={props => (
                    <Component {...props} />
                )} />
            : <Loading/>}
        </CanAccess>
    );
};

export default PrivateRoute;