import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { isEmpty } from '@helpers/validation';
import { patchRequest } from '@services/ServiceCommon';
// components
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Divider, Form } from 'semantic-ui-react';
import EmailField from "@components/forms/common/EmailField"

const ContactEmailForm = ({ onClose, endpoint, isProfile, updateState, data }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)

    // eslint-disable-next-line
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        id: data?.id || 0,
        email: data?.email || "",
    })

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)

        const contact_info = {
            emails: form.id  === 0 ? {
                create: [form]
            } : {
                update: {[form.id]: form}
            }
        }
        
        const request = await patchRequest(endpoint, isProfile ? {
            personal_information: {
                contact_info: contact_info
            }
        } : {
            contact_info: contact_info
        })
        if( request.status === 200 ){
            if (isProfile) {
                updateState(prevState => ({
                    ...prevState,
                    personal_information: {
                        ...prevState.personal_information,
                        contact_info: request.response.personal_information.contact_info
                    }
                }))
            } else {
                updateState(prevState => ({
                    ...prevState,
                    contact_info: request.response.contact_info
                }))
            }
            onClose()
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <EmailField
                autoFocus
                label={t('email')}
                value={form.email}
                setValue={(e, { value }) => setForm({...form, email: value})}
            />
                
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(form.email)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default ContactEmailForm;