import React from 'react'
import { useTranslation } from 'react-i18next'
//components
import Icon from '@components/Icon'
import { Button, Grid } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ConfrimModal from '@components/modals/ConfrimModal'
import SortedFileRenderer from '@components/SortedFileRenderer'
import MoveFileForm from './MoveFileForm'

const Attachment = ({
    docs,
    canMove,
    moduleName,
    options,
    setGroupOptions,
    setGroups,
    handleAddGroup,
    onDelete,
    setDocsWithGroup,
    setDocsWithoutGroup,
    canManage,
}) => {
    const { t } = useTranslation()

    const updateFolders = (fileID, previous, current) => {
        if (current?.group === null || !current?.group) {
            setDocsWithoutGroup((prev) => [current, ...prev])
            setDocsWithGroup((prev) => prev.filter((attachment) => attachment.id !== fileID))
        } else if (previous?.group === null || !previous?.group) {
            setDocsWithGroup((prev) => [current, ...prev])
            setDocsWithoutGroup((prev) => prev.filter((attachment) => attachment.id !== fileID))
        } else {
            setDocsWithGroup((prev) =>
                prev.filter((item) => {
                    if (item.id === fileID) {
                        item.group = current.group
                    }

                    return item
                })
            )
        }
    }

    return (
        <Grid>
            <Grid.Row style={{ padding: 0 }}>
                {docs.file && <SortedFileRenderer name={docs.name} file={docs?.file} />}
                {canMove && (
                    <SuperDuperModal
                        size="tiny"
                        header={t('move_file') + ' - ' + docs.name || docs.file}
                        trigger={
                            <Button
                                style={{
                                    padding: '0.5',
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    marginRight: 0,
                                    background: 'none',
                                }}
                                size="small"
                            >
                                <Icon name="move" style={{ marginRight: 0 }} />
                            </Button>
                        }
                        content={
                            <MoveFileForm
                                options={options}
                                setGroups={setGroups}
                                setGroupOptions={setGroupOptions}
                                file={docs}
                                moduleName={moduleName}
                                onConfirm={(fileID, previous, current) => updateFolders(fileID, previous, current)}
                                handleAddGroup={handleAddGroup}
                            />
                        }
                    />
                )}
                {canManage !== false && canManage !== undefined && (
                    <ConfrimModal
                        description={t('delete_attachments')}
                        onConfirm={() => onDelete(docs.id)}
                        button={
                            <Button style={{ marginLeft: '5px', paddingTop: '0px', backgroundColor: 'transparent' }}>
                                <Icon name="close" color="red" />
                            </Button>
                        }
                    />
                )}
            </Grid.Row>
        </Grid>
    )
}

export default Attachment
