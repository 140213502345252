import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { patchRequest } from '@services/ServiceCommon';
// components
import { Button, Divider, Dropdown, Form, Grid, Header, Label, Menu } from 'semantic-ui-react';
import UserAvatar from 'react-user-avatar';
import SuperField from '@components/forms/SuperField';
import Action from '@components/general/Action';

const CaseDetail = ({ record, setData, setTotal, onUpdateStatus, options }) => {
    const { t } = useTranslation();
    const dateFormat = useSelector((state) => state.date_format);

    const [notes, setNotes] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [caseNote, setCaseNote] = useState('');
    const [selected, setSelected] = useState(record.status);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        async function fetchNotes() {
            const request = await requests.get(API.CASES_NOTES + `?ordering=-created_on&case=${record.id}`);
            if (request.status === 200) {
                setNotes(request.response);
            }
        }
        fetchNotes();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async () => {
        setProcessing(true);
        const request = await requests.post(API.CASES_NOTES, {
            content: caseNote,
            case: record.id,
        });

        if (request.status === 201) {
            setNotes((prev) => [request.response, ...prev]);
            setCaseNote('');
        }
        setProcessing(false);
    };

    const onDelete = async (id) => {
        const request = await requests.del(API.CASES_NOTES + id + '/');
        if (request.status === 204) {
            setNotes((prev) => prev.filter((item) => item.id !== id));
        }
    };

    return (
        <div style={{ padding: '2rem' }}>
            <Header as={'h3'}>
                <Grid style={{ padding: 0 }}>
                    <Grid.Row columns="2" style={{ padding: 0 }}>
                        <Grid.Column style={{ padding: 0 }}>
                            {record.title}
                            {record.label.map((label) => (
                                <Label
                                    style={{
                                        fontSize: '0.9rem',
                                        padding: '0.4rem',
                                        paddingTop: '0.6rem',
                                        marginLeft: '2rem',
                                        color: 'white',
                                        backgroundColor: label.color,
                                    }}
                                    key={label.id}
                                >
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>{label.name}</span>
                                </Label>
                            ))}
                            <br />
                            <Header.Subheader>
                                <div>{record.type?.title || ''}</div>
                            </Header.Subheader>
                        </Grid.Column>
                        <Grid.Column textAlign="right" style={{ padding: 0 }}>
                            <Menu compact style={{ border: 'none', boxShadow: 'none', width: '100px' }}>
                                <Dropdown
                                    simple
                                    compact
                                    selection
                                    options={options}
                                    value={selected}
                                    onChange={async (event, data) => {
                                        setSelected(data.value);
                                        const request = await patchRequest(`${API.CASES}${record.id}/`, {
                                            status: data.value,
                                        });
                                        if (request.status === 200) {
                                            toast({
                                                type: 'success',
                                                icon: 'check circle',
                                                title: t('status_changed'),
                                                description: '',
                                                animation: 'bounce',
                                                time: 2000,
                                            });
                                            onUpdateStatus(record?.status, request.response, setData, setTotal);
                                        }
                                    }}
                                    style={{
                                        boxShadow: 'none',
                                        background: 'transparent',
                                        width: '100px',
                                        zIndex: 1,
                                        fontSize: '12px',
                                        marginTop: '0.3rem',
                                        position: 'absolute',
                                    }}
                                />
                            </Menu>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider style={{ marginBottom: 0 }} />
                <Grid>
                    <Grid.Row columns={'2'}>
                        <Grid.Column
                            textAlign="left"
                            style={{ borderRight: '1px solid silver', paddingBottom: '1rem' }}
                        >
                            <Header as={'h4'}>
                                {t('priority')}
                                <Header.Subheader>
                                    <div>{record.priority_display || '--'}</div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign="left" style={{ paddingBottom: '1rem' }}>
                            <Header as={'h4'}>
                                {t('created_by')}
                                <Header.Subheader>
                                    <div>{record?.created_by?.name || '--'}</div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign="left" style={{ borderRight: '1px solid silver' }}>
                            <Header as={'h4'}>
                                {t('created')}
                                <Header.Subheader>
                                    <div>
                                        {record?.created_on ? moment(record?.created_on).format(dateFormat) : '--'}
                                    </div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                        <Grid.Column textAlign="left">
                            <Header as={'h4'}>
                                {t('due_to')}
                                <Header.Subheader>
                                    <div>{record?.due_date ? moment(record?.due_date).format(dateFormat) : '--'}</div>
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider style={{ marginTop: 0 }} />
                <Header as={'h4'}>{t('description')}</Header>
                <Header.Subheader>
                    <div style={{ marginTop: '1rem', paddingLeft: '1rem' }}>
                        {record.description || t('no_description')}
                    </div>
                </Header.Subheader>
            </Header>
            <Header as={'h4'}>{t('assigned')}</Header>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {record?.assigned_to?.map((assigned, index) => (
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '1rem', paddingTop: '0.5rem' }}>
                        <UserAvatar
                            key={index}
                            style={{ paddingLeft: '0rem', fontSize: 30 / 2.5 + 'px' }}
                            size={30}
                            name={assigned?.fullname || '--'}
                            color="var(--variant5)"
                            src={assigned?.profile_picture || ''}
                        />
                        <div style={{ fontWeight: 'normal', paddingLeft: '0.5rem', paddingTop: '0.4rem' }}>
                            {assigned?.fullname || '--'}
                        </div>
                    </div>
                ))}
            </div>

            <Grid>
                <Grid.Row verticalAlign="middle" columns="2" style={{ paddingTop: '1.5rem', paddingBottom: 0 }}>
                    <Grid.Column style={{ textAlign: 'left' }}>
                        <Header as={'h4'}>{t('notes')}</Header>
                    </Grid.Column>
                    <Grid.Column style={{ textAlign: 'right' }}>
                        <Action
                            as="custom"
                            type="button"
                            buttonText={isVisible ? t('close') : t('add_note')}
                            iconColor={isVisible ? 'var(--danger)' : 'var(--primary)'}
                            paddingLeft="1rem"
                            paddingRight="1rem"
                            onClick={() => setIsVisible(!isVisible)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider style={{ marginTop: '0.3rem' }} />

            <div>
                {isVisible && (
                    <Form
                        onSubmit={handleSubmit}
                        style={{ paddingLeft: '1rem', paddingTop: '0.5rem', paddingBottom: '1rem' }}
                    >
                        <SuperField
                            autoFocus
                            value={caseNote}
                            label={t('add_note')}
                            fluid
                            as="textarea"
                            onChange={(e, { value }) => setCaseNote(value)}
                        />
                        <Button
                            primary
                            size="tiny"
                            disabled={processing || caseNote === ''}
                            loading={processing}
                            content={t('confirm')}
                        />
                    </Form>
                )}
                {notes.length > 0 ? (
                    notes?.map((note, index) => (
                        <div style={{ display: 'flex', paddingLeft: '1rem', paddingTop: '1rem' }}>
                            <UserAvatar
                                key={index}
                                style={{ paddingLeft: '0rem', fontSize: 55 / 2.5 + 'px' }}
                                size={55}
                                name={note?.created_by?.name || '--'}
                                color="var(--variant5)"
                                src={note?.created_by?.profile_picture || ''}
                            />
                            <div style={{ fontWeight: 'normal', paddingLeft: '0.8rem', paddingTop: '0.4rem' }}>
                                <strong>{note?.created_by?.name || '--'}</strong>
                                <span style={{ paddingLeft: '0.5rem', fontSize: '13px' }}>
                                    {note?.created_on
                                        ? moment(note?.created_on).format(dateFormat) +
                                          ' · ' +
                                          moment(note?.created_on).format('HH:mm')
                                        : '--'}
                                </span>
                                <br />
                                {note?.content || '--'}
                            </div>
                            <div style={{ paddingLeft: '0.8rem', paddingTop: '0.5rem' }}>
                                <Action
                                    as="delete"
                                    tooltip={t('delete')}
                                    text={t('delete')}
                                    onClick={() => onDelete(note.id)}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>{t('no_notes')}</div>
                )}
            </div>
        </div>
    );
};

export default CaseDetail;
