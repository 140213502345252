import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// store
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import { Button, Form, Segment, Divider, Header } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';

const CostCenterAllowedUsersManagement = ({ currentUser, costCenter }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(true)
    const [allowedUsers, setAllowedUsers] = useState([])
    // eslint-disable-next-line
    const [users, setUsers] = useState([])
    const [userOptions, setUserOption] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        user: "",
        permissions: [],
        cost_center: costCenter.id
    })

    useEffect( () => {
        async function fetchData(){
            setIsLoading(true)
            const request = await requests.get(API.COST_CENTERS_ALLOWED_USERS + `?cost_center=${costCenter.id}`)
            const requestUsers = await requests.get(API.USERS + "?query={id, username}&is_active=true&role=3&role=1")

            if( request.status === 200 ){
                setAllowedUsers(request.response)
            }

            if( requestUsers.status === 200 ){
                setUsers(requestUsers.response)

                let options = []
                for (let i = 0; i < requestUsers.response.length; i++){
                    if( request.response.find(allowedUser => allowedUser.user.username === requestUsers.response[i].username) === undefined ){
                        options.push({ key: requestUsers.response[i].id, value: requestUsers.response[i].id, text: requestUsers.response[i].username })
                    }
                }

                setUserOption(options)
            }
            setIsLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async () => {
        setIsProcessing(true)
        const canView = form.permissions.includes(1)
        const canManage = form.permissions.includes(2)
        const canDelete = form.permissions.includes(3)
        const request = await requests.post(API.COST_CENTERS_ALLOWED_USERS, {
            cost_center: costCenter.id,
            user: form.user,
            can_view: (canManage === true || canDelete === true) ? true : canView,
            can_manage: canDelete === true ? true : canManage,
            can_delete: canDelete
        })

        if( request.status === 201 ){
            setUserOption(prevState => prevState.filter(item => item.value !== request.response.user))
            setAllowedUsers(prev => ([...prev, request.response]))
            setForm({
                user: "",
                permissions: []
            })
        }
        setIsProcessing(false)
    }

    const PermissionAction = ({ name, isActive, allowedUser, perm}) => {
        const [isProcessing, setIsProcessing] = useState(false)

        const handleUserPermissionChange = async (allowedUser, perm) => {
            setIsProcessing(true)
            let data = undefined
            if( perm === "can_view" ){
                const viewStatus = !allowedUser.can_view
                data = {
                    can_view: viewStatus,
                    can_manage: (viewStatus === false && allowedUser.can_manage === true) ? false : allowedUser.can_manage,
                    can_delete: (viewStatus === false && allowedUser.can_delete === true) ? false : allowedUser.can_delete,
                }
            } else if( perm === "can_manage" ){
                const manageStatus = !allowedUser.can_manage
                data = {
                    can_view: manageStatus === true ? true : allowedUser.can_view,
                    can_manage: manageStatus,
                    can_delete: manageStatus === false ? false : allowedUser.can_delete
                }
            } else if( perm === "can_delete" ){
                const viewStatus = allowedUser.can_view
                const manageStatus = allowedUser.can_manage
                const deleteStatus = !allowedUser.can_delete
                data = {
                    can_view: (viewStatus === false && (manageStatus === true || deleteStatus === true)) ? true : allowedUser.can_view,
                    can_manage: deleteStatus === true ? true : false,
                    can_delete: deleteStatus
                }
            }

            const request =  await requests.patch(API.COST_CENTERS_ALLOWED_USERS + allowedUser.id + '/', data)
            if( request.status === 200 ){
                // perform update on allowed user
                setAllowedUsers(prevState => prevState.filter(item => {
                    if( item.id === allowedUser.id ){
                        item.can_view = request.response.can_view
                        item.can_manage = request.response.can_manage
                        item.can_delete = request.response.can_delete
                    }

                    return item
                }))
            }

            setIsProcessing(false)
        }

        return (
            <Button
                type="button"
                active={isActive}
                disabled={isProcessing}
                loading={isProcessing}
                onClick={ () => handleUserPermissionChange(allowedUser, perm) }
                style={{ fontWeight: "bold" }}
            >
                <Icon
                    name={ isActive ? "checkmark-outline" : "close-outline" }
                    style={{ color: isActive ? "var(--blue)" : "var(--danger)" }}
                /> { name }
            </Button>
        )
    }

    // eslint-disable-next-line
    const DeleteAction = ({ allowedUser }) => {
        // eslint-disable-next-line
        const [isProcessing, setIsProcessing] = useState(false)

        return (
            <Icon
                name="close-outline"
                loading={isProcessing}
                disabled={isProcessing}
                style={{ color: "var(--danger)", cursor: "pointer" }}
            />
        )
    }

    return (
        <div>
            <Segment loading={isLoading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
                { !isLoading &&
                    <>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group widths={"equal"}>
                                <SuperField as="choice"
                                    label={t('user')}
                                    help={t('if_the_list_is_empty_hint')}
                                    customOptions={userOptions}
                                    value={form.user}
                                    onChange={ (e, { value }) => setForm({ ...form, user: value })}
                                />
                                <SuperField as="choice"
                                    label={t('permissions')}
                                    multiple
                                    customOptions={[
                                        { key: 1, value: 1, text: t('can_view')},
                                        { key: 2, value: 2, text: t('can_manage')},
                                        { key: 3, value: 3, text: t('can_delete')}
                                    ]}
                                    value={form.permissions}
                                    onChange={ (e, { value }) => setForm({ ...form, permissions: value })}
                                />
                                <Form.Field style={{ marginTop: "1.7rem" }}>
                                    <Button
                                        fluid
                                        primary
                                        size="small"
                                        content={t('confirm')}
                                        loading={isProcessing}
                                        disabled={form.user === "" || form.permissions.length === 0 || isProcessing}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                        <Divider/>
                        <Header as="h3" content={ t('allowed_users') }/>
                        <FlexTable stripped={false}>
                            <FlexRow>
                                <FlexHeader basis="80%" content={t('user')}/>
                                <FlexHeader textAlign="left" content={t('permissions')}/>
                                {/* <FlexHeader textAlign="center" content={""}/> */}
                            </FlexRow>
                            <EmptyRow length={allowedUsers.length}/>
                            { allowedUsers.map(allowedUser => (
                                <FlexRow background="transparent" fontSize="0.9rem" borders>
                                    <FlexItem basis="80%" bold>
                                        { allowedUser.user?.name }
                                    </FlexItem>
                                    <FlexItem textAlign="left">
                                        { currentUser.username !== allowedUser.user.username &&
                                            <Button.Group basic size="small" style={{ borderRadius: 0 }}>
                                                <PermissionAction
                                                    name={ t('can_view') }
                                                    isActive={allowedUser.can_view}
                                                    allowedUser={allowedUser}
                                                    perm="can_view"
                                                />
                                                <PermissionAction
                                                    name={ t('can_manage') }
                                                    isActive={allowedUser.can_manage}
                                                    allowedUser={allowedUser}
                                                    perm="can_manage"
                                                />
                                                <PermissionAction
                                                    name={ t('can_delete') }
                                                    isActive={allowedUser.can_delete}
                                                    allowedUser={allowedUser}
                                                    perm="can_delete"
                                                />
                                            </Button.Group>
                                        }
                                    </FlexItem>
                                    {/* <FlexItem textAlign="center">
                                        { currentUser.id !== allowedUser.user &&
                                            <DeleteAction allowedUser={allowedUser}/>
                                        }
                                    </FlexItem> */}
                                </FlexRow>
                            )) }
                        </FlexTable>
                    </>
                }
            </Segment>
        </div>
    );
};

export default CostCenterAllowedUsersManagement;