import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
// components
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import { Divider, Header, Form } from 'semantic-ui-react';
// specific comps
import CCRecordForm from './CCRecordForm';
import ServiceFulFillmentRecords from './ServiceFulFillmentRecords';
import ServiceOrderFulfillmentsStats from './ServiceOrderFulfillmentsStats';

const ServiceOrderDetail = ({ record, onClose }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    const [filters, setFilters] = useState({})

    return (
        <>
            <Header as="h3" content={t('fulfillment_overview')}/>
            <Divider/>

            <CanView permissions={['reports.c_view_order_reports']}>
                <div style={{ marginTop: "2rem" }}>
                    <ServiceOrderFulfillmentsStats filters={filters}/>
                </div>
            </CanView>

            <ListView
                as="table"
                isModal
                // allowSearch
                isSoftDeleteAllowed
                actionsCellWidth="2"
                forceReloadOnPagination={false}
                endpoint={API.ORDERS + "fulfillments/"}
                tableHeaders={[
                    { title: t('worker') },
                    { title: t('date_from') + " / " + t('date_to') },
                    { title: t('activities') },
                    { title: t('total_hours') },
                    { title: t('total_fee') },
                    { title: t('cost_center') },
                ]}
                onResponse={(response, filters) => {
                    setFilters(filters)
                }}
                exports={[
                    {
                        endpoint: API.EXPORTS + "dynamic_service_fulfillment/",
                        permissions: ['orders.c_view_order_fulfillment'],
                        apply_filters: true,
                        type: "xlsx",
                        filename: "service_fulfillments"
                    },
                ]}
                listAdditionActions={(data, setData, total, setTotal, response) => ([
                    {
                        as: "filter",
                        index: 0,
                        name: t('all'),
                        filters: {
                            assigned_to_cost_center: "",
                        },
                    },
                    {
                        as: "filter",
                        index: 1,
                        name: t('assigned_to_cost_center'),
                        filters: {
                            assigned_to_cost_center: true,
                        },
                    },
                    {
                        as: "filter",
                        index: 2,
                        name: t('not_assigned'),
                        filters: {
                            assigned_to_cost_center: false,
                        },
                    },
                ])}
                initialFilters={{
                    service_profile: "",
                    service_date_from: "",
                    service_date_to: "",
                    assigned_to_cost_center: "",
                    resource_type: "servicefulfillment",
                    order: record.id,
                }}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                label={t('date_from')}
                                value={filters.service_date_from}
                                onChange={(e, { value }) => setFilters({ ...filters, service_date_from: value })}
                            />
                            <SuperField as="datepicker"
                                label={t('date_to')}
                                value={filters.service_date_to}
                                onChange={(e, { value }) => setFilters({ ...filters, service_date_to: value })}
                            />
                        </Form.Group>
                        <SuperField as="choice"
                            search
                            multiple
                            label={t('workers')}
                            value={filters.service_profile}
                            customOptions={record.workers?.map(item => ({ key: item.id, value: item.id, text: item.fullname }))}
                            onChange={(e, { value }) => setFilters({ ...filters, service_profile: value })}
                        />
                    </>
                )}
                renderCells={(item, setData) => ([
                    { width: 3, content: <strong>{ item.profile.fullname }</strong> },
                    { content: moment(item.date_from).format(dateFormat) + " - " + moment(item.date_to).format(dateFormat) },
                    { content: item.timesheet_records?.length || 0 },
                    { content: item.total_hours },
                    { content: item.total_fee },
                    { content: 
                        <>
                            { item.cost_center_records.length === 0 && t('not_assigned') }
                            { item.cost_center_records.length > 0 && (
                                <>
                                    { item.cost_center_records[0].cost_center_title }<br/>
                                    <smal>{ moment(item.cost_center_records[0].date).format(dateFormat) }</smal>
                                </>
                            )}
                        </>
                    },
                ])}
                actions={[
                    {
                        as: "modal",
                        type: "custom",
                        icon: "swap-horizontal-outline",
                        name: t('assign_to_cost_center'),
                        modalSize: "tiny",
                        customIconColor: "var(--dark)",
                        modal: (item, setData) => <CCRecordForm record={item} setData={setData} costCenter={record.cost_center}/>,
                        isHidden: (item) => item.cost_center_records.length > 0,
                        permissions: ['cost_centers.c_manage_incomes_expenses', 'cost_centers.c_view_cost_centers']
                    },
                    {
                        as: "modal",
                        type: "custom",
                        icon: "eye-outline",
                        name: t('overview'),
                        modalSize: "small",
                        customIconColor: "var(--primary)",
                        modal: <ServiceFulFillmentRecords/>,
                        permissions: ['orders.c_view_order_fulfillment']
                    },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('delete_fulfillment'),
                        permissions: ['orders.c_manage_order_fulfillment'],
                    },
                ]}
            />
        </>
    );
};

export default ServiceOrderDetail;