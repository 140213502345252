import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { renderAddress } from '@helpers/functions';
import { patchRequest } from '@services/ServiceCommon';
// components
import InfoRow from '@components/InfoRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import AddressFillForm from '@components/forms/AddressFillForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Divider, Header, Form } from 'semantic-ui-react';

const BusinessDetailForm = ({ onClose, endpoint, updateState, data }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)

    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: data?.name || "",
        tax_mode: data?.tax_mode?.toString() || "1",
        id_number: data?.identification_number || "",
        dit_number: data?.dit_number || "",
        vat_number: data?.vat_number || "",
        executive_manager: data?.executive_manager || "",
        folder_details: data?.business_register_folder || "",
        nace_code: data?.nace_code?.id || null,
        billing_address: data?.billing_address || null,
        shipping_address: data?.shipping_address || null,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)
        let data = {
            business_detail: {
                name: form.name, // required
                tax_mode: parseInt(form.tax_mode), // required
                identification_number: form.id_number, // required
                dit_number: form.dit_number || "",
                vat_number: form.vat_number || "",
                executive_manager: form.executive_manager || "",
                business_register_folder: form.folder_details || "",
                nace_code: form.nace_code || null,
                billing_address: form.billing_address,
                shipping_address: form.shipping_address
            }
        }
        
        const request = await patchRequest(endpoint, data)
        if( request.status === 200 ){
            updateState(prevState => ({
                ...prevState,
                business_detail: request.response.business_detail
            }))
            onClose()
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false)
    }


    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    autoFocus
                    label={ t('company_name')}
                    error={errors === null ? false : errors?.business_detail?.name}
                    value={ form.name }
                    onChange={(e, { value }) => setForm({...form, name: value})}
                />

                <SuperField as="choice"
                    required
                    label={t('tax_mode')}
                    type="tax_mode"
                    value={form.tax_mode}
                    onChange={(e, { value }) => setForm({ ...form, tax_mode: value })}
                />

            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('id_number')}
                    placeholder={t('id_number')}
                    value={form.id_number}
                    error={errors === null ? false : errors?.business_detail?.identification_number}
                    onChange={(e, { value }) => setForm({ ...form, id_number: value })}
                />
                <SuperField as="input"
                    label={t('dit_number')}
                    placeholder={t('dit_number')}
                    value={form.dit_number}
                    onChange={(e, { value }) => setForm({ ...form, dit_number: value })}
                />
                <SuperField as="input"
                    label={t('vat_number')}
                    placeholder={t('vat_number')}
                    value={form.vat_number}
                    onChange={(e, { value }) => setForm({ ...form, vat_number: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('register_folder')}
                    placeholder={t('register_folder')}
                    value={form.folder_details}
                    onChange={(e, { value }) => setForm({ ...form, folder_details: value })}
                />
                <SuperField as="choice"
                    search
                    label={t('nace_code')}
                    endpoint={API.NACE_CODE}
                    text="name"
                    value={form.nace_code}
                    onChange={(e, { value }) => setForm({ ...form, nace_code: value })}
                />
            </Form.Group>

            <SuperField as="input"
                label={ t('executive_manager')}
                error={errors === null ? false : errors?.business_detail?.executive_manager}
                value={ form.executive_manager }
                onChange={(e, { value }) => setForm({...form, executive_manager: value})}
            />

            <Divider/>

            <Header as="h3" content={t('addresses')}/>

            <div style={{ paddingBottom: "1rem" }}>
                { form.billing_address === null
                    ? 
                    <SuperDuperModal 
                        trigger={
                            <span className="ref-link" style={{ color: "var(--primary)", fontWeight: "bold", marginLeft: "0.5rem"  }}>
                                {t('add_billing_address')}
                            </span>
                        }
                        content={
                            <AddressFillForm
                                address={form.billing_address}
                                addressType="billing_address"
                                addressTypeDisplay={t("billing_address")}
                                updateState={setForm}
                            />
                        }
                    />
                    : 
                    <InfoRow 
                        allowEdit
                        isVisible={true}
                        text={t('billing_address')}
                        content={renderAddress(form?.billing_address)}
                        modal={
                            <AddressFillForm
                                address={form.billing_address}
                                addressType="billing_address"
                                addressTypeDisplay={t("billing_address")}
                                updateState={setForm}
                            />
                        }
                    />
                }
                
                
            </div>
            <div style={{ paddingBottom: "1rem" }}>
                { form.shipping_address === null 
                    ? 
                    <SuperDuperModal 
                        trigger={
                            <span className="ref-link" style={{ color: "var(--primary)", fontWeight: "bold", marginLeft: "0.5rem" }}>
                                {t('add_shipping_address')}
                            </span>
                        }
                        content={
                            <AddressFillForm
                                address={form.shipping_address}
                                addressType="shipping_address"
                                addressTypeDisplay={t("shipping_address")}
                                updateState={setForm}
                            />
                        }
                    />
                    : 
                    <InfoRow 
                        allowEdit
                        isVisible={true}
                        text={t('shipping_address')}
                        content={renderAddress(form?.shipping_address)}
                        modal={
                            <AddressFillForm
                                address={form.shipping_address}
                                addressType="shipping_address"
                                addressTypeDisplay={t("shipping_address")}
                                updateState={setForm}
                            />
                        }
                    /> 
                }
            </div>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default BusinessDetailForm;