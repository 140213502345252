import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { isEmpty } from '@services/helpers/validation'
// components
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import PayrollDetails from '../components/PayrollDetails'

const PayrollForm = ({ setData, setTotal, onClose, setSize, setHeader }) => {
    const { t } = useTranslation()

    // get me current day and first day in month from previous month
    const currentDay = moment()
    const previousMonth = currentDay.clone().subtract(1, 'months')
    const contract_date_to = currentDay.format('YYYY-MM-DD')
    const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

    const firstDayOfPreviousMonth = moment().clone().subtract(1, 'months').startOf('month')
    const [view, setView] = useState(1)
    const [config, setConfig] = useState({
        month: firstDayOfPreviousMonth.format('MM'),
        year: firstDayOfPreviousMonth.format('YYYY'),
        contract: '',
        use_timesheet_data: '',
    })

    const date_from = moment(`${config.year}-${config.month}-${'01'}`).startOf('month').format('YYYY-MM-DD')
    const date_to = moment(`${config.year}-${config.month}-${'01'}`).endOf('month').format('YYYY-MM-DD')

    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = () => {
        setIsProcessing(true)
        setView(2)
        setSize("small")
        setHeader(undefined)
        setIsProcessing(false)
    }

    const monthList = [
        { key: 1, value: '01', text: t('january') },
        { key: 2, value: '02', text: t('february') },
        { key: 3, value: '03', text: t('march') },
        { key: 4, value: '04', text: t('april') },
        { key: 5, value: '05', text: t('may') },
        { key: 6, value: '06', text: t('june') },
        { key: 7, value: '07', text: t('july') },
        { key: 8, value: '08', text: t('august') },
        { key: 9, value: '09', text: t('september') },
        { key: 10, value: '10', text: t('october') },
        { key: 11, value: '11', text: t('november') },
        { key: 12, value: '12', text: t('december') },
    ]

    return (
        <>
            { view === 1 
                ? 
                    <Form onSubmit={handleSubmit}>
                        <SuperField
                            search
                            required
                            as="choice"
                            label={t('employee')}
                            endpoint={
                                API.CONTRACTS +
                                `?query={id, employee{id, fullname}}&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}`
                            }
                            text="employee.fullname"
                            value={config.contract}
                            onChange={(e, { value }) => setConfig({ ...config, contract: value })}
                        />
                        <Form.Group widths="equal">
                            <SuperField
                                as="choice"
                                search
                                width="2"
                                required
                                label={t('month')}
                                clearable={false}
                                value={config.month}
                                customOptions={monthList}
                                onChange={(e, { value }) => setConfig((prev) => ({ ...prev, month: value }))}
                            />
                            <SuperField
                                as="yearpicker"
                                label={t('year')}
                                required
                                value={config.year}
                                width="2"
                                dateFormat="YYYY"
                                onChange={(e, { value }) => setConfig((prev) => ({ ...prev, year: value }))}
                            />
                        </Form.Group>
                        {/* <SuperField
                            as="checkbox"
                            label={t('use_timesheet_data')}
                            checked={config.use_timesheet_data}
                            onChange={(e) => setConfig({ ...config, use_timesheet_data: !config.use_timesheet_data })}
                        /> */}
                        <Divider />
                        <Form.Field style={{ textAlign: 'right' }}>
                            <ModalCancel onClose={onClose} />
                            <ModalSubmit
                                loading={isProcessing}
                                disabled={isProcessing || isEmpty(config.contract) || isEmpty(config.month) || isEmpty(config.year)}
                                text={t('confirm')}
                            />
                        </Form.Field>
                    </Form>
                : 
                    <PayrollDetails
                        contractID={config.contract}
                        dateFrom={date_from}
                        dateTo={date_to}
                        setData={setData}
                        setTotal={setTotal}
                        onClose={onClose}
                    />
            }
        </>
    )
}

export default PayrollForm
