import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactGrid } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import moment from 'moment'
// store
import { isEmailValid, isPhoneNumberValid } from '@helpers/validation'
// components
import SpinnerSegment from '@components/SpinnerSegment'
import { Divider, Form, Message, Modal } from 'semantic-ui-react'
import Icon from '@components/Icon'

const getDataset = (data, headers) => {
    let dataset = []
    // let config = selectedScheme?.config?.headers;
    let config = headers

    for (let i = 0; i < data.length; i++) {
        let newItem = {}
        let item = data[i]

        for (let y = 0; y < config?.length; y++) {
            const title = config[y].title
            const dateType = config[y].dataType
            const dateFormat = config[y].dateFormat || 'YYYY-MM-DD'
            const datetimeFormat = config[y].datetimeFormat || 'YYYY-MM-DD HH:mm'

            // if empty assign empty string and continue
            if ([undefined, null, ''].includes(item[title])) {
                if (dateType === 'date') {
                    newItem[title] = null
                } else {
                    newItem[title] = ''
                }

                continue
            }

            // if not empty the convert to proper datatypes
            if (dateType === 'number') {
                if (isNaN(item[title])) {
                    newItem[title] = item[title]
                } else {
                    newItem[title] = parseInt(item[title])
                }
            }
            if (dateType === 'floatNumber') newItem[title] = parseFloat(item[title])
            if (dateType === 'date') {
                if (moment(item[title]).isValid()) {
                    newItem[title] = moment(item[title]).format(dateFormat)
                } else {
                    newItem[title] = item[title]
                }
            }
            if (dateType === 'datetime') {
                if (moment(item[title]).isValid()) {
                    newItem[title] = moment(item[title]).format(datetimeFormat)
                } else {
                    newItem[title] = item[title]
                }
            }
            if (dateType === 'time') {
                if (moment(item[title]).isValid()) {
                    newItem[title] = moment(item[title]).format('HH:mm')
                } else {
                    newItem[title] = item[title]
                }
            }

            if (!['number', 'floatNumber', 'date', 'time', 'datetime'].includes(dateType)) {
                newItem[title] = item[title]
            }
        }

        dataset.push(newItem)
    }

    return dataset
}

const ExcelLikeTable = ({
    selectedScheme,
    onChange,
    isCleared,
    setIsCleared,
    wasSchemeChanged,
    setWasSchemeChanged,
    isProcessing,
}) => {
    const { t } = useTranslation()

    const tableHeaders = selectedScheme?.config?.headers
    const [data, setData] = useState([])
    const [rowsNum, setRowsNum] = useState(0)
    const [number, setNumber] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(isProcessing ? true : false)

    useEffect(() => {
        addEmptyRows(50)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (data) {
            onChange(data)
        }
        // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        if (isCleared || wasSchemeChanged) {
            setData([])
            addEmptyRows(50)
            setIsCleared(false)
            setRowsNum(50)
            setWasSchemeChanged(false)
        }
        // eslint-disable-next-line
    }, [isCleared, wasSchemeChanged])

    const addEmptyRows = (rows) => {
        setIsLoading(true)
        const headers = tableHeaders
        const newEmptyRows = []

        for (let row = 0; row < rows; row++) {
            let item = {}
            // item["#"] = ""

            for (let i = 0; i < headers?.length; i++) {
                if (!headers[i].type || headers[i].type === 'text') {
                    item[headers[i].title] = ''
                } else if (headers[i].type === 'date') {
                    item[headers[i].title] = null
                } else if (headers[i].type === 'number') {
                    item[headers[i].title] = ''
                } else if (headers[i].type === 'email') {
                    item[headers[i].title] = ''
                } else if (headers[i].type === 'time') {
                    item[headers[i].title] = ''
                } else if (headers[i].type === 'checkbox') {
                    item[headers[i].title] = false
                }
            }
            newEmptyRows.push(item)
        }
        setData((prev) => [...prev, ...newEmptyRows])
        setIsLoading(false)
    }

    const addNewRows = (number) => {
        const sum = parseInt(data.length) + parseInt(number)
        if (sum < 1001) {
            addEmptyRows(number)
        } else {
            setRowsNum(sum)
            setIsOpen(true)
        }
    }

    const columns = [
        { columnId: '#', width: 30, className: 'num-columns' },
        ...tableHeaders.map((header) => ({
            columnId: header.title,
            width: 160,
        })),
    ]

    const headerRow = {
        rowId: 'header',
        cells: [
            {
                type: 'header',
                text: '#',
            },
            ...tableHeaders.map((header) => ({
                type: 'header',
                text: t(header.title),
                className: header.required && 'requiredHeader',
            })),
        ],
    }

    const getRows = (data) => [
        headerRow,
        ...data?.map((item, idx) => ({
            rowId: idx,
            cells: [
                {
                    type: 'number',
                    value: idx + 1 + 1, // start from row with number 2 because header row is already first one
                    nonEditable: true,
                },
                ...tableHeaders?.map((header) =>
                    header?.type === 'dropdown'
                        ? {
                              type: header.type,
                              isOpen: item[`${header.title}_isOpen`],
                              selectedValue: item[header.title],
                              inputValue: item[header.title],
                              values: header?.options,
                          }
                        : {
                              type: header.type || 'text',
                              text: item[header.title],
                              dataType: header.dataType,
                              dateFormat: header?.dateFormat || 'YYYY-MM-DD',
                              datetimeFormat: header?.datetimeFormat || 'YYYY-MM-DD HH:mm',
                              checkedText: '1',
                              uncheckedText: '0',
                              [header.valueVariable ? header.valueVariable : 'text']: item[header.title],
                              validator: (cellValue) => {
                                  if (header.dataType === 'date' && cellValue) {
                                      return moment(cellValue, header.dateFormat || 'YYYY-MM-DD', true).isValid()
                                  }
                                  if (header.dataType === 'datetime' && cellValue) {
                                      return moment(
                                          cellValue,
                                          header.datetimeFormat || 'YYYY-MM-DD HH:mm',
                                          true
                                      ).isValid()
                                  }
                                  if (header.dataType === 'time' && cellValue) {
                                      return moment(cellValue, 'HH:mm', true).isValid()
                                  }
                                  if (header.dataType === 'email' && cellValue) {
                                      return isEmailValid(cellValue)
                                  }
                                  if (header.dataType === 'phone' && cellValue) {
                                      return isPhoneNumberValid(cellValue)
                                  } else {
                                      return true
                                  }
                              },
                          }
                ),
            ],
        })),
    ]

    const handleChanges = (changes) => {
        const dataset = data.slice()
        const cellsThatChanged = changes.map((item) => item.rowId + '__' + item.columnId)
        for (let i = 0; i < cellsThatChanged.length; i++) {
            const values = cellsThatChanged[i].split('__')
            const rowId = parseInt(values[0])
            const columnId = values[1]

            const changedCell = changes.find((change) => change.rowId === rowId && change.columnId === columnId)
            if (changedCell) {
                let cell = dataset?.[rowId]?.[columnId]
                if (changedCell.type === 'text' || !changedCell.type) {
                    const value = changedCell.newCell.text
                    cell = value
                    if (changedCell.newCell.dataType === 'date') {
                        let dateFormat = changedCell.newCell?.dateFormat || 'YYYY-MM-DD'
                        if (moment(value).isValid()) {
                            cell = moment(value).format(dateFormat)
                        } else {
                            cell = value
                        }
                    }
                    if (changedCell.newCell.dataType === 'datetime') {
                        let datetimeFormat = changedCell.newCell?.datetimeFormat || 'YYYY-MM-DD HH:mm'
                        if (moment(value).isValid()) {
                            cell = moment(value).format(datetimeFormat)
                        } else {
                            cell = value
                        }
                    }
                    if (changedCell.newCell.dataType === 'time') {
                        if (moment(value).isValid()) {
                            cell = moment(value).format('HH:mm')
                        } else {
                            cell = value
                        }
                    }
                }
                if (changedCell.type === 'number') cell = changedCell.newCell.value
                if (changedCell.type === 'time') cell = changedCell.newCell.time
                if (changedCell.type === 'email') cell = changedCell.newCell.text
                if (changedCell.type === 'checkbox') cell = changedCell.newCell.checked
                if (changedCell.type === 'dropdown') {
                    let filledValue = changedCell.newCell.selectedValue
                    if (![undefined, ''].includes(filledValue)) {
                        cell = filledValue
                    }
                }

                dataset[rowId][columnId] = cell
                setData(dataset)
            }
        }
    }

    const handleSubmit = async () => {
        if (number > 0) {
            addNewRows(number)
            setNumber(0)
        }
    }

    return (
        <div style={{ paddingLeft: '0', width: '100%' }}>
            <SpinnerSegment loading={isLoading || wasSchemeChanged} marginBottom={isLoading ? '1rem' : 0}>
                <div style={{ height: '600px', overflowY: 'auto', overflowX: 'auto' }}>
                    <ReactGrid
                        stickyTopRows={1}
                        stickyLeftColumns={1}
                        enableFillHandle
                        enableVirtualization
                        enableFullWidthHeader
                        enableVirtualScrolling
                        enableRangeSelection
                        columns={columns}
                        rows={getRows(data)}
                        onCellsChanged={(changes) => handleChanges(changes)}
                    />
                </div>
                <div style={{ paddingTop: '0.5rem' }}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Input
                                inline
                                min="0"
                                value={number}
                                type="number"
                                width={2}
                                error={number < 0 || number > 5000}
                                onChange={(e) => setNumber(e.target.value)}
                            />
                            <Form.Button disabled={number < 0 || number > 5000} content={t('add_rows')} primary />
                        </Form.Group>
                    </Form>
                </div>
                <Modal
                    size={'tiny'}
                    open={isOpen}
                    closeIcon
                    centered={false}
                    onClose={() => {
                        setIsOpen(false)
                    }}
                >
                    <ModalTooManyRows num={rowsNum} />
                </Modal>
            </SpinnerSegment>
        </div>
    )
}

const ModalTooManyRows = ({ num }) => {
    const { t } = useTranslation()

    return (
        <Modal.Content>
            <SpinnerSegment>
                <Message visible error style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                    <Icon
                        name="close-circle-outline"
                        style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                    />
                    <span style={{ position: 'relative', top: '-0.2rem' }}>{t('too_many_rows') + ': ' + num}</span>
                </Message>
                <Divider />
                <p>{t('max_limit_of_rows_in_table_is_1000')}</p>
            </SpinnerSegment>
        </Modal.Content>
    )
}

export { ExcelLikeTable, getDataset }
