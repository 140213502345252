import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { tzDateTime } from '@helpers/dates';
import { requests } from '@helpers/requests';
// components
import CanView from '@components/perms/CanView';
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import SpinnerSegment from '@components/SpinnerSegment';
import ConfrimModal from '@components/modals/ConfrimModal';
import { Divider, Button, Header, Label, Table, Popup } from 'semantic-ui-react';
// specific components
import ProcessItemUnloadingForm from './ProcessItemUnloadingForm'

const ProcessOverview = ({ process, setData, onClose }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(0) // status

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])

    useEffect(() => {
        async function fetchStats(){
            setLoading(true)
            // fetch stats
            const request = await requests.get(API.REPORTS + "assets/warehouse_stats/?id=" + process.id)
            if (request.status === 200) {
                if (request.response.length > 0) {
                    setItems(request.response[0].items)
                }
            }

            setLoading(false)
        }

        fetchStats()
    }, [process])

    const onDelete = async (item) => {
        const request = await requests.del(API.ASSETS + "warehouse_process_items/" + item.id + "/")
        if (request.status === 204) {
            const fetchStats = await requests.get(API.REPORTS + "assets/warehouse_stats/?id=" + process.id)
            if (fetchStats.status === 200) {
                if (fetchStats.response.length > 0) {
                    setItems(fetchStats.response[0].items)

                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('commodity_was_removed_from_process'),
                        animation: 'pulse',
                        time: 2000,
                    })
                }
            }
        }
    }

    const onCloseProcess = async () => {
        const request = await requests.patch(API.ASSETS + "warehouse_processes/" + process.id + "/", { is_active: false })
        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('process_has_been_closed'),
                animation: 'pulse',
                time: 2000,
            })
            setData(prev => prev.map(item => {
                if (item.id === process.id) {
                    item = request.response
                }
                return item
            }))
            onClose()
        }
    }

    const onReOpenProcess = async () => {
        const request = await requests.patch(API.ASSETS + "warehouse_processes/" + process.id + "/", { is_active: true })
        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('process_has_been_reopened'),
                animation: 'pulse',
                time: 2000,
            })
            setData(prev => prev.map(item => {
                if (item.id === process.id) {
                    item = request.response
                }
                return item
            }))
            onClose()
        }
    }

    return (
        <SpinnerSegment loading={loading}>
            { !loading && 
                <>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Header as="h3" style={{ marginBottom: "0.2rem" }}>
                            { t('process_name') }: <span style={{ fontWeight: "normal" }}> { process.title } </span>
                        </Header>

                        <CanView permissions={['assets.c_manage_warehouse_processes']}>
                            { process.is_active 
                                ? 
                                    <ConfrimModal
                                        description={t('close_process_delete')}
                                        onConfirm={() => onCloseProcess()}
                                        button={
                                            <Button size="tiny" style={{ background: "var(--danger)", color: "var(--white)" }}>{t('close_process')}</Button>
                                        }
                                    />
                                : 
                                    <ConfrimModal
                                        description={t('reopen_process_message')}
                                        onConfirm={() => onReOpenProcess()}
                                        button={
                                            <Button size="tiny" style={{ background: "var(--primary)", color: "var(--white)" }}>{t('reopen_process')}</Button>
                                        }
                                    />
                            }
                        </CanView>
                    </div>
                    <p> { process.note } </p>

                    <Divider/>
                    <Button.Group fluid>
                        <Button primary={view === 0} content={t('overview')} onClick={() => setView(0)}/>
                        <Button primary={view === 1} content={t('history')} onClick={() => setView(1)}/>
                    </Button.Group>
                    <Divider/>

                    { view === 0 && 
                        <>
                            <Table striped unstackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{ t('item') }</Table.HeaderCell>
                                        <Table.HeaderCell>{ t('measurement_type') } / { t('measure_unit') }</Table.HeaderCell>
                                        <Table.HeaderCell>{ t('processed') } / { t('expected') }</Table.HeaderCell>
                                        {/* <Table.HeaderCell>{ t('current_stock') }</Table.HeaderCell> */}
                                        <Table.HeaderCell>{ t('state') }</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: "150px" }}>{ t('actions') }</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    { items.length === 0 && 
                                        <Table.Row>
                                            <Table.Cell colSpan="5" style={{ fontweight: "bold", textAlign: "center" }}> { t('no_data') } </Table.Cell>
                                        </Table.Row>
                                    }
                                    { items.map(item => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell> 
                                                { item.code ? item.code + " - " : "" }{ item.item } 
                                            </Table.Cell>
                                            <Table.Cell> 
                                                { item.weight_is_primary_measurement ? t('weight') : t('packages_pieces')} / { item.measure_unit || "--" } 
                                                <Popup
                                                    trigger={
                                                        <Icon name="information-circle-outline" style={{ marginLeft: "0.5rem" }}/>
                                                    }
                                                    content={
                                                        <div>
                                                            { item.weight_is_primary_measurement &&
                                                                <>
                                                                    { t("unloading_quantity") }: <strong>{item.processing_quantity}</strong> <br/>
                                                                    { t("average_unit_weight") }: <strong>{item.average_weight}</strong>
                                                                </>
                                                            }
                                                        </div>
                                                    }
                                                />
                                            </Table.Cell>
                                            <Table.Cell> { item.reality } / { item.plan } </Table.Cell>
                                            {/* <Table.Cell> { item.stock_quantity } </Table.Cell> */}
                                            <Table.Cell> <Label basic color={item.reality === item.plan ? "green" : undefined} size="tiny">{ item.percentage }%</Label> </Table.Cell>
                                            <Table.Cell style={{ width: "150px" }}> 
                                                <CanView permissions={['assets.c_manage_warehouse_processes']}>
                                                    { item.percentage < 100 && 
                                                        <Action as="modal" icon="cube-outline" type="icon"
                                                            size="huge"
                                                            tooltip={t('unload_commodity')}
                                                            modalSize="tiny"
                                                            modal={
                                                                <ProcessItemUnloadingForm
                                                                    item={item}
                                                                    process={process}
                                                                    setItems={setItems}
                                                                />
                                                            }
                                                        />
                                                    }
                                                    { item.percentage === 0 && 
                                                        <Action as="delete"
                                                            size="huge"
                                                            tooltip={t('delete_assigned_commodity')}
                                                            text={t('are_you_sure_that_you_want_to_remove_assigned_commodity_from_process')}
                                                            onClick={() => onDelete(item)}
                                                        />
                                                    }   
                                                </CanView>
                                            </Table.Cell>
                                        </Table.Row>
                                    )) }
                                </Table.Body>
                                { items.length > 0 && 
                                    <Table.Footer>
                                        <Table.Row style={{ fontWeight: "bold", background: "#f0f0f0" }}>
                                            <Table.Cell colSpan="3">
                                                { t('total_items') }: { items.length }
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Label basic size="tiny">{ process.percentage }%</Label>
                                            </Table.Cell>
                                            <Table.Cell/>
                                        </Table.Row>
                                    </Table.Footer>
                                }
                            </Table>
                        </>
                    }
                    { view === 1 && <ProcessHistory process={process}/> }
                </>
            }
        </SpinnerSegment>
    );
};

const ProcessHistory = ({ process }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getHistory() {
            setLoading(true)
            const request = await requests.get(API.ASSETS + "history/?query={id, operation, operation_display, quantity, warehouse_position, stored_item{id, code, catalogue_item}, performed_by, created_on}&warehouse_process=" + process.id)
            if (request.status === 200) {
                setHistory(request.response)
            }
            setLoading(false)
        }

        getHistory()
        // eslint-disable-next-line
    }, [])

    return (
        <SpinnerSegment loading={loading}>
            { !loading && 
                <Table striped unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{ t('operation') }</Table.HeaderCell>
                            <Table.HeaderCell>{ t('warehouse_position') }</Table.HeaderCell>
                            <Table.HeaderCell>{ t('item') }</Table.HeaderCell>
                            <Table.HeaderCell>{ t('quantity') }</Table.HeaderCell>
                            <Table.HeaderCell>{ t('performed_by') }</Table.HeaderCell>
                            <Table.HeaderCell>{ t('datetime') }</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { history.length === 0 && 
                            <Table.Row>
                                <Table.Cell colSpan="6" style={{ fontWeight: "bold", textAlign: "center" }}>
                                    { t('no_data') }
                                </Table.Cell>
                            </Table.Row>
                        }
                        { history.map(item => (
                            <Table.Row key={item.id}>
                                <Table.Cell> <Label size="small" color={item.operation === 6 ? "orange" : "info"}>{ item.operation_display }</Label> </Table.Cell>
                                <Table.Cell> { item.warehouse_position ? (item.warehouse_position.title + (item.warehouse_position.section && " / " + item.warehouse_position.section ) +  (item.warehouse_position.row && " / " + item.warehouse_position.row)) : "--" } </Table.Cell>
                                <Table.Cell> { item.stored_item?.code ? item.stored_item?.code + " - " : item.stored_item?.catalogue_item?.code ? item.stored_item?.catalogue_item?.code + " - " : "" }{ item.stored_item.catalogue_item.title } </Table.Cell>
                                <Table.Cell> { item.quantity } </Table.Cell>
                                <Table.Cell> { item.performed_by?.fullname } </Table.Cell>
                                <Table.Cell> { tzDateTime(item.created_on).format(dateFormat + " HH:mm") } </Table.Cell>
                            </Table.Row>
                        )) }
                    </Table.Body>
                </Table>
            }
        </SpinnerSegment>
    )
}

export default ProcessOverview;