import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
// store
import { API } from '@store/config';
import { fileDownload } from '@helpers/requests';
// components
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider } from 'semantic-ui-react';

const GenerateBirthDayList = ({ onClose }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    // const dateFormat = useSelector(state => state.date_format)
    
    const firstDay = moment().startOf('month').format("YYYY-MM-DD")
    const lastDay = moment().endOf('month').format("YYYY-MM-DD")
    
    const initialValues = {
        from: firstDay,
        to: lastDay,
    }

    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState(initialValues)

    const handleSubmit = async () => {
        setProcessing(true)
        await fileDownload("GET", API.EXPORTS + `employees_birthday/?type=pdf&from=${form.from}&to=${form.to}`, "application/pdf", `birthdays_${form.from}__${form.to}`)
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={t('date_from')}
                    value={form.from}
                    onChange={(e, { value }) => setForm({ ...form, from: value })}
                />
                <SuperField as="datepicker"
                    required
                    label={t('date_to')}
                    value={form.to}
                    onChange={(e, { value }) => setForm({ ...form, to: value })}
                />
            </Form.Group>
            <DatePresets
                open={true}
                from={form.from}
                to={form.to}
                onSelection={ (firstMonthDay, lastMonthDay) => {
                    setForm({ 
                        ...form, 
                        from: firstMonthDay,
                        to: lastMonthDay,
                    })
                }}
            />
            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || form.from === "" || form.to === ""}
                />
            </Form.Field>
        </Form>
    );
};

export default GenerateBirthDayList;