import React from "react";


// components
import LoginForm from "./LoginForm";


function Login() {

    return (
        <div className="center-element">
            <LoginForm />
        </div>
        
    );
}

export default Login;
