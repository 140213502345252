import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { Card } from 'semantic-ui-react';

const Skills = ({ skills }) => {
    const { t } = useTranslation()

    return (
        <Card.Group>
            { skills.length === 0 && <Card fluid content={t('no_data')} style={{ textAlign: 'center', padding: "1rem", fontWeight: "bold" }}/>}
            { skills.map(skill => (
                <Card fluid key={skill.id} size="tiny">
                    <Card.Content>
                        <Icon name="library-outline" style={{ marginRight: "1rem" }}/> 
                        <span style={{ position: "relative", top: "-0.2rem" }}>
                            { skill.name }{ skill.seniority_level !== null && " - " + skill.seniority_level_display }
                        </span>
                    </Card.Content>
                </Card>
            )) }
        </Card.Group>
    );
};

export default Skills;