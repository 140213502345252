import { APIRequest } from './helpers/requests';
import { API } from "../config";

export const serviceCompany = {
    createContactInfo,
    createCompany,
    createContactInfoFirst
}
export const companyService = {
    getCompanies,
    getEmployees,
    getTree,
    setCompany,
    deleteCompany,
    getUnitTypes,
    deleteUnit,
    updateUnit,
    createUnit,
    updateUnitData,
    deleteBankAccount,
    updateBank,
    createBank,
    updatePosition,
    createPosition,
    updateBusinessDetail,
    updateCompanyHeader,
    updateBusinessDetailBank,
    updateEmail,
    createEmailInfo,
    deleteEmail,
    updateContactInfoEmail,
    deletePhone,
    updatePhone,
    createPhoneInfo,
    getPhoneTypes,
    updateContactInfoPhone,
    getLogo,
    updateLogo,
    deleteLogo,
    getCompanyStats,
    getUnit,
    deleteUnitData,
    updateCompany
};

async function createContactInfo(numbers, emails) {
    return await APIRequest({
        url: API.CREATE_CONTACT_INFO,
        method: "post",
        data: {
            "phones": {
                "add": numbers
            },
            "emails": {
                "add": emails
            }
        },
        private: true
    })
}
async function createContactInfoFirst(data) {
    return await APIRequest({
        url: API.CREATE_CONTACT_INFO,
        method: "post",
        data: data,
        private: true
    })
}
async function createCompany(data) {
    return await APIRequest({
        url: API.COMPANIES,
        method: "post",
        data: data,
        private: true
    })
}
async function getCompanies(params) {
    params = params || ""
    return await APIRequest({
        url: API.COMPANIES + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getEmployees(id) {
    return await APIRequest({
        url: API.UNITS + id + "/employees/",
        method: 'GET',
        data: {},
        private: true
    });
}

async function getUnit(id, params) {
    params = params || ""
    return await APIRequest({
        url: API.COMPANIES + "units/" + id + '/' + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function setCompany(id, is_active) {
    return await APIRequest({
        url: API.COMPANIES + id + '/',
        method: 'PATCH',
        data: {
            is_active: is_active
        },
        private: true
    });
}
async function updateCompany(id, data) {
    return await APIRequest({
        url: API.COMPANIES + id + '/',
        method: 'PATCH',
        data: { contact_information: { add: data } },
        private: true
    });
}
async function updateCompanyHeader(id, data) {
    return await APIRequest({
        url: API.COMPANIES + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function deleteCompany(id) {
    return await APIRequest({
        url: API.COMPANIES + id + '/',
        method: 'DELETE',
        // data: data,
        private: true
    });
}

async function getTree(id) {
    return await APIRequest({
        url: API.COMPANIES + id + API.TREE,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getUnitTypes() {
    return await APIRequest({
        url: API.UNIT_TYPES,
        method: 'GET',
        data: {},
        private: true
    });
}

async function deleteBankAccount(id) {
    return await APIRequest({
        url: API.BANK_ACCOUNTS + id + '/',
        method: 'DELETE',

        private: true
    });
}

async function updateBank(id, data) {
    return await APIRequest({
        url: API.BANK_ACCOUNTS + id + '/',
        method: "PATCH",
        data: data,
        private: true
    });
}
async function createBank(data) {
    return await APIRequest({
        url: API.BANK_ACCOUNTS,
        method: "POST",
        data: data,
        private: true
    });
}
async function updatePosition(id, data) {
    return await APIRequest({
        url: API.POSITION_TYPES + id + '/',
        method: "PATCH",
        data: data,
        private: true
    });
} async function createPosition(data) {
    return await APIRequest({
        url: API.POSITION_TYPES,
        method: "POST",
        data: data,
        private: true
    });
} async function updateUnit(id, data) {
    return await APIRequest({
        url: API.UNIT_TYPES + id + '/',
        method: "PATCH",
        data: data,
        private: true
    });
} async function createUnit(data) {
    return await APIRequest({
        url: API.UNIT_TYPES,
        method: "POST",
        data: data,
        private: true
    });
}
async function deleteUnit(id) {
    return await APIRequest({
        url: API.UNIT_TYPES + id + '/',
        method: 'DELETE',

        private: true
    });
}
async function updateBusinessDetail(id, data) {
    return await APIRequest({
        url: API.CREATE_BUSINESS_DETAIL + id + '/',
        method: "PATCH",
        data: data,
        private: true
    })
}
async function updateBusinessDetailBank(id, data) {
    return await APIRequest({
        url: API.CREATE_BUSINESS_DETAIL + id + '/',
        method: "patch",
        data: { bank_accounts: data },
        private: true
    })
}
async function updateEmail(id, data) {
    return await APIRequest({
        url: API.CREATE_EMAIL + id + '/',
        method: "patch",
        data: data,
        private: true
    })
}
async function updateContactInfoEmail(id, data) {
    return await APIRequest({
        url: API.CREATE_CONTACT_INFO + id + '/',
        method: "patch",
        data: { emails: data },
        private: true
    })
}
async function updateContactInfoPhone(id, data) {
    return await APIRequest({
        url: API.CREATE_CONTACT_INFO + id + '/',
        method: "patch",
        data: { phones: data },
        private: true
    })
}

async function createEmailInfo(email) {
    return await APIRequest({
        url: API.CREATE_EMAIL,
        method: 'POST',
        data: { email: email },
        private: true
    });
}
async function deleteEmail(id) {
    return await APIRequest({
        url: API.CREATE_EMAIL + id + '/',
        method: 'DELETE',
        private: true
    });
}

async function createPhoneInfo(data) {
    return await APIRequest({
        url: API.CREATE_PHONE,
        method: 'POST',
        data: data,
        private: true
    });
}
async function deletePhone(id) {
    return await APIRequest({
        url: API.CREATE_PHONE + id + '/',
        method: 'DELETE',
        private: true
    });
}
async function updatePhone(id, data) {
    return await APIRequest({
        url: API.CREATE_PHONE + id + '/',
        method: "patch",
        data: data,
        private: true
    })
}

async function getPhoneTypes() {
    return await APIRequest({
        url: API.CREATE_PHONE_TYPE,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getLogo(id) {
    return await APIRequest({
        url: API.COMPANIES + id + API.LOGO,
        method: 'GET',
        data: {},
        private: true
    });
}

async function updateLogo(id, data) {
    return await APIRequest({
        url: API.COMPANIES + id + API.LOGO,
        method: 'POST',
        data: data,
        private: true
    });
}
async function deleteLogo(id) {
    return await APIRequest({
        url: API.COMPANIES + id + API.LOGO,
        method: 'DELETE',
        private: true
    });
}
async function getCompanyStats(id) {
    return await APIRequest({
        url: API.COMPANY_STATS + id + API.STATS,
        method: 'GET',
        data: {},
        private: true
    });
}


async function updateUnitData(id, data) {
    return await APIRequest({
        url: API.COMPANIES + API.UNIT + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function deleteUnitData(id) {
    return await APIRequest({
        url: API.COMPANIES + API.UNIT + id + '/',
        method: 'DELETE',
        data: {},
        private: true
    });
}




