const allowedModulesReducer = (allowedModules = [], action) => {
    switch (action.type) {
        case "ADD_ALLOWED_MODULES":
            return action.allowedModules;
        default:
            return allowedModules;
    }
};

export default allowedModulesReducer;
