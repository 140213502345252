import React from 'react';
import { Link } from 'react-router-dom';
// store
import { routes } from '@store/routes/index';
// components
import Icon from '@components/Icon';
import { List } from 'semantic-ui-react';

const SettingsSidebarItem = (props) => {

    return (
        <List.Item
            as={Link}
            to={routes.SETTINGS + props.slug}
            onClick={props.onClick}
            className={window.location.href.includes(routes.SETTINGS + props.slug) ? "sidebar-item is-active" : "sidebar-item"}
        >
            <Icon
                name={props.icon}
                style={{ fontSize: "1.3rem" }}
                className={window.location.href.includes(routes.SETTINGS + props.slug) ? "is-active" : ""} 
            />
            <span style={{ position: 'relative', top: "-0.2rem", paddingLeft: "0.8rem" }}>
                { props.text }
            </span>
        </List.Item>
    );

};

export default SettingsSidebarItem;