import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { routes } from '@routes';
import { useHasPermission } from '@helpers/hooks';
// components
import { Label, Form, Popup } from 'semantic-ui-react';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import BreadcrumbNav from '@components/BreadcrumbNav';
import ContactDetails from '@components/ContactDetails';
import Attachments from '@components/Attachments';
import AccommodationForm from '../components/AccommodationForm';
import Icon from '@components/Icon';
import LabelsList from '@components/lists/LabelsList';

const Accommodations = () => {
    const { t } = useTranslation();
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)
    const canManage = useHasPermission('accommodations.c_manage_accommodation')
    const [labels, setLabels] = useState([])

    useEffect(() => {
        async function fetchLabels(){
            const request = await requests.get(API.ACCOMMODATIONS + "labels/?query={id, name}")
            if (request.status === 200) {
                setLabels(request.response.map(item => ({ key: item.id, value: item.id, text: item.name })))
            }
        }

        fetchLabels()
    }, [])
    
    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev]);
        setTotal((prev) => prev + 1);
    };

    const onUpdate = (response, setData) => {
        setData((prev) =>
            prev.map((accommodation) => {
                if (accommodation.id === response.id) {
                    accommodation = response;
                }
                return accommodation;
            })
        );
    };

    return (
        <CanView permissions={['accommodations.c_view_accommodation']} redirect>
            <BreadcrumbNav items={[{ 'name': t('accommodation'), 'icon': '', 'href': '' }]} />
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.ACCOMMODATIONS}
                initialFilters={{
                    accommodate_relation: "",
                    accommodation_type: "",
                    is_archived: false,
                    total_bed_count: "",
                    unit: "",
                    labels: [],
                    city: "",
                    country: "",
                    guest: ""
                }}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths={'equal'}>
                            <SuperField as="choice"
                                label={t('accommodate_relation')}
                                customOptions={[
                                    { key: 1, value: 'co-owner', text: t('co-owner') },
                                    { key: 2, value: 'co-renter', text: t('co-renter') },
                                    { key: 3, value: 'owner', text: t('owner') },
                                    { key: 4, value: 'renter', text: t('renter') },
                                    { key: 5, value: 'subtenant', text: t('subtenant') }
                                ]}
                                value={filters.accommodate_relation}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    accommodate_relation: value
                                })}
                            />
                            <SuperField as="choice"
                                label={t('accommodation_type')}
                                customOptions={[
                                    { key: 1, value: 'family_house', text: t('family_house') },
                                    { key: 2, value: 'flat', text: t('flat') },
                                    { key: 3, value: 'flat_house', text: t('flat_house') },
                                    { key: 4, value: 'hostel', text: t('hostel') }
                                ]}
                                value={filters.accommodation_type}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    accommodation_type: value
                                })}
                            />
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                            <SuperField
                                as="input"
                                label={t('total_bed_count')}
                                placeholder={t('total_bed_count')}
                                value={filters.total_bed_count}
                                onChange={(e, { value }) => setFilters(prev => ({...prev, total_bed_count: value}))}
                            />
                            <SuperField as="choice"
                                label={t('unit')}
                                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                                text="name"
                                value={filters.unit}
                                onChange={(e, { value }) => setFilters({...filters, unit: value })} 
                            />
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                            <SuperField as="input"
                                label={t('city')}
                                placeholder={t('city')}
                                value={filters.city}
                                onChange={(e, { value }) => setFilters(prev => ({...prev, city: value}))}
                            />
                            <SuperField as="choice"
                                search
                                value={filters.country}
                                type="countries"
                                label={t('country')}
                                onChange={(e, { value }) => setFilters({...filters, country: value})}
                            />
                        </Form.Group>
                        <SuperField as="input"
                            label={t('guest')}
                            placeholder={t('guest')}
                            value={filters.guest}
                            onChange={(e, { value }) => setFilters(prev => ({...prev, guest: value}))}
                        />
                        <SuperField as="choice"
                            search
                            multiple
                            label={t('labels')}
                            value={filters.labels}
                            customOptions={labels}
                            onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                        />
                        <SuperField as="checkbox"
                            label={t('archived')}
                            checked={filters.is_archived}
                            onChange={() => setFilters(prev => ({...prev, is_archived: !filters.is_archived}))}
                        />
                    </>
                )}
                listAdditionActions={(data, setData, total, setTotal, response) => ([
                    {
                        as: "filter",
                        index: 0,
                        name: t('active'),
                        filters: {
                            is_archived: false
                        }
                    },
                    {
                        as: "filter",
                        index: 1,
                        name: t('archived'),
                        filters: {
                            is_archived: true
                        }
                    },
                    {
                        as: "filter",
                        index: 2,
                        name: t('all'),
                        filters: {
                            is_archived: ""
                        }
                    },
                ])}
                actions={[
                    {
                        as: "modal",
                        type: "add",
                        name: t('add_accommodation'),
                        modal: <AccommodationForm handleRequest={(response, setData, setTotal) => onCreate(response, setData, setTotal)} />,
                        permissions: ['accommodations.c_manage_accommodation'],
                    },
                    {
                        name: t('archive'),
                        type: "custom",
                        as: "confirm",
                        icon: "archive-outline",
                        text: t('archive'),
                        permissions: ['accommodations.c_manage_accommodation'],
                        isHidden: (item) => item.is_archived,
                        onClick: async (item, setData, setTotal) => {
                            let data = {
                                is_archived: true
                            }
                            const request = await requests.patch(API.ACCOMMODATIONS + item.id + "/", data)

                            if (request.status === 200) {
                                setTotal(prev => prev - 1)
                                setData(prev => prev.filter(data => data.id !== item.id))
                            }
                        }
                    },
                    {
                        name: t('unarchive_item'),
                        type: "custom",
                        as: "confirm",
                        icon: "checkmark-circle-outline",
                        text: t('are_you_sure_that_you_want_to_unarchive'),
                        permissions: ['accommodations.c_manage_accommodation'],
                        isHidden: (item) => !item.is_archived,
                        onClick: async (item, setData, setTotal) => {
                            let data = {
                                is_archived: false
                            }
                            const request = await requests.patch(API.ACCOMMODATIONS + item.id + "/", data)

                            if (request.status === 200) {
                                setTotal(prev => prev - 1)
                                setData(prev => prev.filter(data => data.id !== item.id))
                            }
                        }
                    },
                    {
                        as: "link",
                        type: "detail",
                        icon: "eye-outline",
                        name: t('view_detail'),
                        redirect: (item) => routes.ACCOMMODATIONS + item.id,
                    },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'small',
                        permissions: ['accommodations.c_manage_accommodation'],
                        modal: (item, setData) => (
                            <AccommodationForm
                                accommodation={item}
                                setData={setData}
                                handleRequest={(response, setData) => onUpdate(response, setData)}
                            />
                        ),
                    },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('are_you_sure'),
                        permissions: ['accommodations.c_delete_accommodation']
                    },
                ]}
                tableHeaders={[
                    { title: t('name'), orderBy: "name" },
                    { title: t('accommodation_type') },
                    { title: t('unit') },
                    { title: t('relation') },
                    { title: t('contact_person') },
                    { title: t('price_per_night_per_person')},
                    { title: t('free_beds_today')},
                    { title: t('total_bed_count')},
                    { title: t('address') },
                    { title: t('labels') },
                    { title: t('attachments') },
                    { title: t('status') },
                ]}
                renderCells={(accommodation, setData) => ([
                    { content: accommodation.name },
                    { content: accommodation?.accommodation_type_display },
                    { content: accommodation?.unit?.name },
                    { content: accommodation?.accommodate_relation },
                    { content: 
                        <div>
                            <div style={{paddingBottom: "0.5rem"}}><strong>{accommodation?.contact_person_name}</strong></div>
                            <ContactDetails
                                phone={accommodation?.phone}
                                email={accommodation?.email}
                            />
                        </div>
                    },
                    { content: accommodation?.price_per_night_per_person ? accommodation?.price_per_night_per_person + " " + accommodation?.currency : '' },
                    { content: accommodation?.free_beds_today },
                    { content: accommodation?.total_bed_count },
                    { content: 
                        <div>
                            {accommodation?.address ?
                                <>
                                    <strong>{accommodation?.address?.city}</strong> 
                                    <br/>
                                    <span>{accommodation?.address?.street + " " + accommodation?.address?.number + ", " + accommodation?.address?.postcode}</span>
                                    <br/>
                                    <span>{ accommodation?.address?.state 
                                        ? (accommodation?.address?.state + ", ") + accommodation?.address?.country_display 
                                        : accommodation?.address?.country_display}
                                    </span>
                                </>
                                : "-"
                            }
                        </div>
                    },
                    { content: 
                        <LabelsList
                            rowID={accommodation?.id}
                            labels={accommodation?.labels}
                            endpoint={API.ACCOMMODATIONS}
                            endpointLabels={API.ACCOMMODATIONS + "labels/"}
                            label_key="labels"
                        />
                    },
                    { content: 
                        <Label basic>

                            <Popup
                                style={{ zIndex: "1000", minWidth: "450px" }}
                                open={attachmentsOpen === accommodation.id}
                                onOpen={() => setAttachmentsOpen(accommodation.id)}
                                hoverable
                                position='left center'
                                trigger={
                                    <div style={{ cursor: "pointer" }}>
                                        <Icon name="reader-outline" style={{ marginRight: "0.5rem" }}/> 
                                        <span style={{ position: "relative", top: "-0.1rem" }}>{ accommodation.attachments.length }</span>
                                    </div>
                                }
                                content={
                                    <Attachments 
                                        viewOnly={!canManage}
                                        record={accommodation}
                                        setData={setData}
                                        sourceEndpoint={API.ACCOMMODATIONS}
                                        closeView={() => setAttachmentsOpen(0)}
                                    />
                                }
                            />
                        </Label>
                    },
                    { content:
                        <div style={{ display: "flex" }}>
                            <Icon name='ellipse' style={{ color: !accommodation.is_archived ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }} />
                            <span style={{ position: "relative", top: "-0.3rem" }}> {!accommodation.is_archived  ? t('active') : t('archived')} </span>
                        </div>
                    }
                ])}
            />
        </CanView>
    )
}

export default Accommodations;