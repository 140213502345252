import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { createRequest, updateRequest} from '@services/ServiceCommon';
// components
import { Form, Divider, Header, Button, Popup } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ItemForm = ({ setResponse, onClose, item, setItems }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        title: item?.title ? item.title : "",
        description: item?.description ? item.description : "",
        is_vehicle: item?.is_vehicle === undefined ? false : item.is_vehicle,
        is_safety_equipment: item?.is_safety_equipment === undefined ? false : item.is_safety_equipment,
        lifetime_in_months: item?.lifetime_in_months ? item.lifetime_in_months : 0,
        category: item?.category ? item.category.id : null,
        measure_unit: item?.measure_unit ? item.measure_unit.id : "",
        type: item?.type?.toString() || "",
        tax: item?.tax || "",
        tax_rate: item?.tax_rate || "",
        price_excluding_wat: item?.price_excluding_wat || "",
        total_price: item?.total_price || "",
        currency: item?.currency || "EUR",
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        if( item?.id ){ // update
            const request = await updateRequest(API.ASSET_ITEMS + item.id + "/", {
                ...formData,
                lifetime_in_months: formData.lifetime_in_months === "" ? 0 : formData.lifetime_in_months,
                category: formData.category !== "" ? formData.category : null,
                type: formData.type !== "" ? formData.type : null,
                tax: formData.tax !== "" ? formData.tax : null,
                tax_rate: formData.tax_rate !== "" ? formData.tax_rate : null,
                price_excluding_wat: formData.price_excluding_wat !== "" ? formData.price_excluding_wat : null,
                total_price: formData.total_price !== "" ? formData.total_price : null,
                measure_unit: formData.measure_unit !== "" ? formData.measure_unit : null
            })

            if( request.status === 200 ){
                setItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.map(filteredItem => {
                            if( filteredItem.id === item.id ){
                                filteredItem = request.response
                            }
                            
                            return filteredItem;
                        })
                    }
                }))
                onClose()
            }
        } else { // create
            const request = await createRequest(API.ASSET_ITEMS, {
                ...formData,
                lifetime_in_months: formData.lifetime_in_months === "" ? 0 : formData.lifetime_in_months,
                category: formData.category !== "" ? formData.category : null,
                type: formData.type !== "" ? formData.type : null,
                measure_unit: formData.measure_unit !== "" ? formData.measure_unit : null,
                tax: formData.tax !== "" ? formData.tax : null,
                tax_rate: formData.tax_rate !== "" ? formData.tax_rate : null,
                price_excluding_wat: formData.price_excluding_wat !== "" ? formData.price_excluding_wat : null,
                total_price: formData.total_price !== "" ? formData.total_price : null,
            })
            
            if(request.status === 201){
                setResponse({
                    type: "item_create",
                    result: request.response
                })
                
                onClose()
            }
        }
        setIsProcessing(false)
    }

    const isValidForCalculation = () => {
        if (formData.tax_rate === "" || formData.total_price === "") return false
        if (isNaN(formData.tax_rate) || isNaN(formData.total_price)) return false
        if (parseFloat(formData.tax_rate) < 0) return false
        if (parseFloat(formData.total_price) < 0) return false

        return true
    }

    const calculatePrice = () => {
        if (!isValidForCalculation()) return

        let tax = 0
        let price_excluding_vat = 0
        let total_price = parseFloat(formData.total_price)
        let tax_rate = parseFloat(formData.tax_rate)

        if (tax_rate > 0) {
            tax = (total_price / 100) * tax_rate

            if (tax > 0) {
                price_excluding_vat = total_price - tax
            }
        }

        setFormData(prev => ({
            ...prev,
            tax: parseFloat(tax).toFixed(2),
            price_excluding_wat: parseFloat(price_excluding_vat).toFixed(2),
            total_price: parseFloat(total_price).toFixed(2),
            tax_rate: parseFloat(tax_rate).toFixed(2)
        }))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={item?.id ? t('update') : t('create') }/>
            <Divider/>

            <SuperField as="input"
                autoFocus
                label={t('title')}
                required
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />

            <Form.Group widths="equal">
                <SuperField as="choice"
                    label={t('type')}
                    type="item_types"
                    value={formData.type}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        type: value
                    }) }
                />
                <SuperField as="input"
                    type="number"
                    min="0"
                    label={t('lifetime_in_months')}
                    value={formData.lifetime_in_months}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        lifetime_in_months: value
                    }) }
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    label={t('category')}
                    endpoint={API.ASSET_CATEGORIES + "?query={id, title}"}
                    text="title"
                    value={formData.category}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        category: value
                    }) }
                />
                <SuperField as="choice"
                    search
                    label={t('measure_unit')}
                    endpoint={API.COMMON + "measure_units/?query={id, abbreviation}"}
                    text="abbreviation"
                    value={formData.measure_unit}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        measure_unit: value
                    }) }
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={t('is_vehicle')}
                checked={formData.is_vehicle}
                onChange={ () => setFormData({
                    ...formData,
                    is_vehicle: !formData.is_vehicle
                }) }
            />

            <SuperField as="checkbox"
                label={t('is_safety_equipment')}
                checked={formData.is_safety_equipment}
                onChange={ () => setFormData({
                    ...formData,
                    is_safety_equipment: !formData.is_safety_equipment
                }) }
            />

            {/* TODO: Add Rich Text Editor */}
            <SuperField as="textarea"
                label={t('description')}
                value={formData.description}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    description: value
                }) }
            />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Header as="h3" content={t('price')} style={{ marginBottom: 0, paddingTop: "1rem" }}/>
                <Popup
                    hoverable
                    position="left center"
                    trigger={
                        <Button type="button" primary size="small" onClick={() => calculatePrice()}>{ t('calculate') }</Button>
                    }
                    content={t('item_price_calculation_hint')}
                />
            </div>
            <Divider/>

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('total_sum') + " / " + t('price_including_vat')}
                    value={formData.total_price}
                    onChange={(e, {value}) => setFormData(prev => ({...prev, total_price: value.replace(",", ".")}))}
                />
                <SuperField as="input"
                    label={t('tax_rate') + " - %"}
                    value={formData.tax_rate}
                    onChange={(e, { value }) => setFormData(prev => ({...prev, tax_rate: value.replace(",", ".")}))}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('price_excluding_vat')}
                    value={formData.price_excluding_wat}
                    onChange={(e, {value}) => setFormData(prev => ({...prev, price_excluding_wat: value.replace(",", ".")}))}
                />
                <SuperField as="input"
                    label={t('tax')}
                    value={formData.tax}
                    onChange={(e, {value}) => setFormData(prev => ({...prev, tax: value.replace(",", ".")}))}
                />
            </Form.Group>
            <SuperField as="choice"
                search
                clearable={false}
                type="currency_codes"
                label={t('currency')}
                value={formData.currency}
                onChange={(e, {value}) => setFormData(prev => ({...prev, currency: value}))}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === ""}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default ItemForm;