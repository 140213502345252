import React, { useRef } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store
import { icons } from '@icons';
import { routes } from '@routes';
import { API } from '@store/config';
import { dates } from '@helpers/dates';
import { useSelectOptions } from '@helpers/hooks';
import { deleteRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import AvatarIcon from '@components/AvatarIcon';
import ListView from '@components/ListView';
import SuperField from '@components/forms/SuperField';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Icon as SemanticIcon, Ref, Form, Popup } from 'semantic-ui-react';
// module specific components
import AgreementForm from './components/AgreementForm';

const GDPRAgreements = () => {
    const { t } = useTranslation()

    const segmentRef = useRef()
    const [employees] = useSelectOptions(API.EMPLOYEES + "?query={id, fullname}", "fullname")
    const [candidates] = useSelectOptions(API.CANDIDATES + "?query={id, fullname}", "fullname")

    const IconMark = ({ icon, color, title }) => {
        return (
            <Popup content={title} position='top center' trigger={
                <Icon name={icon} style={{ color: color, cursor: "pointer", fontSize: "1.2rem" }} />
            } />
        )
    }

    // eslint-disable-next-line
    // const onExtend = async (uuid) => {
    //     const request = await createRequest(API.API_URL + "/gdpr/" + uuid + "/send_extendation/")
    //     if (request.status === 201) {
    //         toast({
    //             type: 'success',
    //             icon: 'check circle',
    //             title: t('success'),
    //             description: t('extend_request_successfull'),
    //             animation: 'bounce',
    //             time: 5000,
    //         });
    //     }
    // }

    const onDelete = async (uuid, setData, setTotal) => {
        const request = await deleteRequest(API.GDPR_AGREEMENTS + uuid + '/');
        if (request.status === 204) {
            setData(prev => prev.filter(data => data?.uuid !== uuid))
            setTotal(prev => prev - 1)
        }
    }

    const onCreate = (response, setData, setTotal) => {
        setData(prev => [response, ...prev])
        setTotal(prev => prev + 1)
    }

    return (
        <CanView permissions={['common.c_view_gdpr_agreements']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("gdpr_agreements"), 'icon': icons.GDPR_AGREEMENTS, 'href': '' }
                ]}
            />
            <Ref innerRef={segmentRef}>
                <ListView
                    as="table"
                    allowSearch
                    endpoint={API.GDPR_AGREEMENTS}
                    initialFilters={{
                        profile: "",
                        is_gdpr_expired: "",
                        valid_until_after: "",
                        valid_from_before: ""
                    }}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <SuperField as="choice"
                                search
                                label={t('person')}
                                customOptions={[...candidates.options, ...employees.options]}
                                loading={candidates.isLoading || employees.isLoading}
                                disabled={candidates.isLoading || employees.isLoading}
                                value={filters.profile}
                                onChange={(e, { value }) => setFilters({ ...filters, profile: value })}
                            />
                            <Form.Group widths="equal">
                                <SuperField as="datepicker"
                                    clearable
                                    label={t('valid_from')}
                                    value={filters.valid_from_before}
                                    onChange={(e, { value }) => setFilters({ ...filters, valid_from_before: value })}
                                />
                                <SuperField as="datepicker"
                                    clearable
                                    label={t('valid_to')}
                                    value={filters.valid_until_after}
                                    onChange={(e, { value }) => setFilters({ ...filters, valid_until_after: value })}
                                />
                            </Form.Group>
                            <SuperField as="choice"
                                label={t('show_expired')}
                                customOptions={[
                                    { key: 1, value: "true", text: t('yes') },
                                    { key: 2, value: "false", text: t('no') },
                                ]}
                                value={filters.is_gdpr_expired}
                                onChange={(e, { value }) => setFilters({ ...filters, is_gdpr_expired: value })}
                            />
                        </>
                    )}
                    actions={[
                        {
                            as: "modal",
                            type: "add",
                            name: t('add_agreement'),
                            modal: <AgreementForm
                                employees={employees}
                                candidates={candidates}
                                onConfirm={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                            />,
                            permissions: ['common.c_manage_all_candidates'],
                        },
                        {
                            name: t('delete'),
                            type: "delete",
                            as: "delete",
                            text: t('are_you_sure'),
                            permissions: ['common.c_delete_gdpr_agreements'],
                            customDelete: (item, setData, setTotal) => onDelete(item.uuid, setData, setTotal)
                        },
                    ]}
                    tableHeaders={[
                        { title: t('person'), orderBy: "person" },
                        { title: t('gdpr') },
                        { title: t('checked_when') },
                        { title: t('valid_until') },
                        { title: t('status') },
                    ]}
                    renderCells={(agreement, setData) => ([
                        {
                            content:
                                <>
                                    <FlexItem>
                                        <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                            {agreement.imported_object &&
                                                <IconMark icon="pin-outline" color="orange" title={t('Imported')} />
                                            }
                                            <FlexItem basis="10%">
                                                <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                                    {agreement?.profile &&
                                                        <AvatarIcon size="30" name={agreement?.profile?.fullname} src={agreement?.profile?.profile_picture} />
                                                    }
                                                </SemanticIcon.Group>
                                            </FlexItem>
                                            <FlexItem>
                                                <strong>{agreement?.profile?.fullname || agreement?.email}</strong><br />
                                            </FlexItem>
                                        </FlexRow>
                                    </FlexItem>
                                </>
                        },
                        {
                            content:
                                <FlexItem>
                                    <Link to={routes.GDPRS + agreement.gdpr?.id} target="_blank">
                                        <strong>{agreement.gdpr?.title}</strong>
                                    </Link>
                                    <br />
                                    {agreement.gdpr?.type_display}
                                </FlexItem>
                        },
                        { content: moment(dates.convertUTCDateToLocalDate(agreement.checked_when)).format('DD.MM.YYYY HH:mm') },
                        { content: moment(dates.convertUTCDateToLocalDate(agreement.valid_until)).format('DD.MM.YYYY HH:mm') },
                        {
                            content:
                                <>
                                    {moment().isAfter(agreement.valid_until)
                                        ? <span style={{ color: "var(--danger)", fontWeight: "bold" }}> <Icon name="warning-outline" style={{ marginRight: "0.5rem" }} /> <span style={{ position: "relative", top: "-0.2rem" }}>{t('expired')}</span></span>
                                        : dates.diffDays(agreement.checked_when, agreement.valid_until) + " " + t('days_left_gdpr')
                                    }
                                </>
                        },
                    ])}
                />
            </Ref>
        </CanView>
    );
};

export default GDPRAgreements;