import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from '@services/helpers/validation';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Message, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const RoomForm = ({ data, accommodationID, setRooms, setRoomsFiltered, onClose }) => {
    const { t } = useTranslation();

    const [room, setRoom] = useState({
        id: data?.id || "",
        number: data?.number || "",
        gender: data?.gender || "",
        floor: data?.floor || "",
        bed_count: data?.bed_count || 0,
        accommodation: accommodationID || "",
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);

    const onAddRoom = (response) => {
        setRooms((prev) => [...prev, response]);
        setRoomsFiltered((prev) => [...prev, response]);
    };

    const onUpdateRoom = (response) => {
        setRoomsFiltered((prev) => prev.map((item) => {
            if (item.id === response.id) {
                item = response
            }
            return item
        }))
    }

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }
        if (response.detail) {
            errorsArray.push(response.detail)
        }
        setErrors(errorsArray);
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        const data = {
            number: room.number,
            gender: room.gender,
            floor: room.floor,
            bed_count: room.bed_count,
            accommodation: room.accommodation
        }

        if (!room.id || room.id === 0) {
            const request = await requests.post(API.ACCOMMODATIONS + 'rooms/', data);

            if (request.status === 201) {
                onAddRoom(request.response)
            } else {
                handleErrors(request.response)
            }
        } else {
            const request = await requests.patch(API.ACCOMMODATIONS + 'rooms/' + room.id + '/', data);

            if (request.status === 200) {
                onUpdateRoom(request.response)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />
            <Form.Input
                autoFocus
                required
                label={t('number')}
                placeholder={t('number')}
                value={room.number}
                onChange={(e, { value }) => setRoom({
                    ...room,
                    number: value
                })}
            />
            <Form.Group widths="equal">
                <Form.Input
                    label={t('floor')}
                    placeholder={t('floor')}
                    value={room.floor}
                    onChange={(e, { value }) => setRoom({
                        ...room,
                        floor: value
                    })}
                />
                <Form.Input
                    label={t('bed_count')}
                    type="number"
                    required
                    placeholder={t('bed_count')}
                    min={0}
                    value={room.bed_count}
                    onChange={(e, { value }) => setRoom({
                        ...room,
                        bed_count: value
                    })}
                />
            </Form.Group>
            <SuperField as="choice"
                required
                customOptions={[
                    { key: 1, value: 1, text: t('male')},
                    { key: 2, value: 2, text: t('female')},
                    { key: 3, value: 3, text: t('combined')}
                ]}
                label={t('preferred_for')}
                value={room.gender}
                onChange={(e, { value }) => setRoom({
                    ...room,
                    gender: value
                })}
            />
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(room.number) || isEmpty(room.gender) || isEmpty(room.bed_count)}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default RoomForm;