const dashboard = '/dashboard/'; // this is main prefix for dashboard pages

export const routes = {
    // Public
    HOME: '/',

    // Auth/Users
    LOGIN: '/login/',
    PASSWORD_RESET: '/password-reset',

    // Dashboard
    DASHBOARD: dashboard,
    COMPONENTS: dashboard + 'components/',

    // User Profile
    PROFILE: dashboard + 'profile/',

    // Contracts
    CONTRACTS: dashboard + 'contracts/',
    TERMINATIONS: dashboard + 'terminations/',

    // Company Pages
    COMPANY: dashboard + 'organization/',
    COMPANIES: dashboard + 'companies/',
    COMPANY_LIST: dashboard + 'company/list/',
    COMPANY_DETAIL: dashboard + 'company/',
    UNIT_DETAIL: dashboard + 'organization/unit/',
    COMPANY_CERTIFICATES: dashboard + 'companies/certificates/',
    CERTIFICATE_TYPES: dashboard + 'companies/certificate_types/',
    UNIT: '/unit/',

    // Employees Pages
    EMPLYOEES_LIST: dashboard + 'employees/',
    EMPLYOEES_DETAIL: dashboard + 'employees/card/',
    // EMPLYOEES_CARD: dashboard + 'employees/detail/',
    CREATE_EMPLOYEE: dashboard + 'employees/create/',
    PERSON_TYPE: dashboard + 'common/person_types/',

    // Payrolls
    PAYROLLS: dashboard + 'payrolls/',

    // Hiring Pages
    HIRING: dashboard + 'hiring/',
    HIRING_ROOMS: dashboard + 'hiring/rooms/',
    HIRING_PROCESSES: dashboard + 'hiring/processes/',
    HIRING_LIST: dashboard + 'hiring/list/',
    HIRING_ROOM: dashboard + 'hiring/room/',

    // Candidates Pages
    CANDIDATES_LIST: dashboard + 'candidates/',
    CANDIDATES_CARD: dashboard + 'candidates/detail/',
    CANDIDATES_DETAIL: '/candidate/',
    SOURCES: dashboard + 'candidates/hiring_sources',

    // Benefits Pages
    BENEFITS_LIST: dashboard + 'benefits/',
    CREATE_BENEFITS: dashboard + 'benefits/create/',

    // Teams Pages
    TEAMS_LIST: dashboard + 'teams/',
    TEAMS_DETAIL: dashboard + 'teams/members/',
    MEMBER_DETAIL: dashboard + 'teams/card/member',

    // Projects Pages
    PROJECT_LIST: dashboard + 'projects/',
    PROJECT_OLD_DETAIL: dashboard + 'projects/old-detail/',
    PROJECT_DETAIL: dashboard + 'projects/detail/',

    // Invoices Pages
    INVOICES: dashboard + 'invoices/',
    INVOICES_FORM: dashboard + 'invoices/create',

    // Payments Pages
    PAYMENTS: dashboard + 'payments/',

    // Orders Pages
    ORDERS: dashboard + 'orders/',
    RECEIVED_ORDERS: dashboard + 'orders/received/',
    ISSUED_ORDERS: dashboard + 'issued_orders/',
    ORDERS_DETAIL: dashboard + 'orders/card/',
    ORDERS_CREATE: dashboard + 'orders/create/',
    ORDER_PROCESSES: dashboard + 'order_processes',

    // Timesheets Pages
    TIMESHEETS: dashboard + 'timesheets/',
    TIMESHEETSV2: dashboard + 'timesheets/v2/',

    // Jobs Pages
    JOBS: dashboard + 'jobs/',
    JOBS_APPLY_FORMS: dashboard + 'jobs/apply-forms/',
    PUBLIC_APPLICATION_FORM: '/jobs/apply/',
    JOBS_DETAIL: dashboard + 'jobs/card/',
    CREATE_JOB_OFFER: dashboard + 'jobs/create/',
    UPDATE_JOB_OFFER: dashboard + 'jobs/update/', // :id

    // Jobs Positions Pages
    JOBS_POSITIONS: dashboard + 'positions/',
    CREATE_JOB_POSITION: dashboard + 'positions/create/',
    UPDATE_JOB_POSITION: dashboard + 'positions/update/', // :id

    // Settings Pages
    SETTINGS: dashboard + 'settings/',
    SETTINGS_TYPES: dashboard + 'settings/types',

    // Accounts Pages
    ACCOUNTS: dashboard + 'accounts/',
    ACCOUNTS_DETAIL: dashboard + 'accounts/',

    // Onboarding:
    ONBOARDING: dashboard + 'onboarding/',

    // Users Pages
    USERS: dashboard + 'users/',
    CREATE_USER: dashboard + 'users/create/',

    // Reports:
    REPORTS: dashboard + 'reports/',

    // Courses:
    COURSES: dashboard + 'courses/',

    // Cases:
    CASES: dashboard + 'cases/',
    CASES_TYPES: dashboard + 'cases/types/',
    // Calendar
    CALENDAR: dashboard + 'calendar/',
    // Attendance:
    ATTENDANCE: dashboard + 'attendance/',
    ATTENDANCE_DETAIL: dashboard + 'attendance/',
    ATTENDANCE_RECORDS: dashboard + 'attendance/records/',
    // Documents:
    DOCUMENTS: dashboard + "documents/",
    // Contacts:
    CONTACTS: dashboard + "contacts/",

    // Approvals:
    APPROVALS: dashboard + 'approvals/',

    // Assets:
    ASSETS: dashboard + 'assets/',

    // Warehousing
    WAREHOUSING: dashboard + 'warehousing/',

    // Cost centers
    COST_CENTERS: dashboard + 'cost-centers/',

    // Suppliers
    SUPPLIERS: dashboard + 'suppliers/',

    // Forecasts
    FORECASTS: dashboard + 'forecasts/',

    // GDPR Agreements
    GDPR_AGREEMENTS: dashboard + 'gdpr-agreements/',
    // Gdprs
    GDPRS: '/gdpr/',

    // Vehicles
    VEHICLES: dashboard + 'vehicles/',
    
    // Trackers
    ATTENDANCE_TRACKER: "/attendance/",
    WAREHOUSE_TRACKER: "/warehousing/",
    TASKS: dashboard + 'tasks/',
    LOGISTICS: dashboard + 'logistics/',

    // Identity documents
    IDENTITY_DOCUMENTS: dashboard + 'identity-documents',

    // Leads
    LEADS: dashboard + 'leads/',
    OPPORTUNITIES: dashboard + 'opportunities/',

    // Imports
    IMPORTS: dashboard + 'settings/imports/',

    // Accommodations
    ACCOMMODATIONS: dashboard + "accommodations/",
};
