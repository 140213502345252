import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { routes } from '@store/routes';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Card, Header, Grid, Label } from 'semantic-ui-react';
import SpinnerSegment from '@components/SpinnerSegment';

const ManagerTotalStatistics = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const initialState = {
        accounts: 0,
        candidates: 0,
        orders: 0,
        projects: 0,
        cases: 0,
        contracts: 0,
        terminations: 0,
    }

    const initialStateTotals = {
        accounts: 0,
        candidates: 0,
        orders: 0,
        projects: 0,
        cases: 0,
        contracts: 0,
        terminations: 0,
    }

    const [stats, setStats] = useState(initialState)
    const [totalStats, setTotalStats] = useState(initialStateTotals)

    useEffect(() => {
        async function fetchUserStats () {
            const today = moment().format("YYYY-MM-DD")
            const minus_30_date = moment(today).subtract(30, 'days').format("YYYY-MM-DD")
            setLoading(true)
            const fetchAccounts = await requests.get(API.REPORTS + `dashboard/total_accounts/?date_from=${minus_30_date}&date_to=${today}`)
            const fetchCandidates = await requests.get(API.REPORTS + `dashboard/total_candidates/?date_from=${minus_30_date}&date_to=${today}`)
            const fetchOrders = await requests.get(API.REPORTS + `dashboard/total_orders/?date_from=${minus_30_date}&date_to=${today}`)
            const fetchProjects = await requests.get(API.REPORTS + `dashboard/total_active_projects/?date_from=${minus_30_date}&date_to=${today}`)
            const fetchCases = await requests.get(API.REPORTS + `dashboard/total_cases/?date_from=${minus_30_date}&date_to=${today}`)
            const fetchContracts = await requests.get(API.REPORTS + `dashboard/total_contracts/?date_from=${minus_30_date}&date_to=${today}`)
            const fetchTerminations = await requests.get(API.REPORTS + `dashboard/total_terminations/?date_from=${minus_30_date}&date_to=${today}`)

            let totals = initialState

            if (fetchAccounts.status === 200) totals['accounts'] = fetchAccounts.response.total_accounts || 0
            if (fetchCandidates.status === 200) totals['candidates'] = fetchCandidates.response.total_candidates || 0
            if (fetchProjects.status === 200)  totals['projects'] = fetchProjects.response.total_active_projects || 0
            if (fetchOrders.status === 200)  totals['orders'] = fetchOrders.response.total_orders || 0
            if (fetchCases.status === 200)  totals['cases'] = fetchCases.response.total_cases || 0
            if (fetchContracts.status === 200)  totals['contracts'] = fetchContracts.response.total_contracts || 0
            if (fetchTerminations.status === 200)  totals['terminations'] = fetchTerminations.response.total_terminations || 0

            // pt. 2 - get totals
            const fetchTotalAccounts = await requests.get(API.REPORTS + `dashboard/total_accounts/`)
            const fetchTotalCandidates = await requests.get(API.REPORTS + `dashboard/total_candidates/`)
            const fetchTotalOrders = await requests.get(API.REPORTS + `dashboard/total_orders/`)
            const fetchTotalProjects = await requests.get(API.REPORTS + `dashboard/total_active_projects/`)
            const fetchTotalCases = await requests.get(API.REPORTS + `dashboard/total_cases/`)
            const fetchTotalContracts = await requests.get(API.REPORTS + `dashboard/total_contracts/`)
            const fetchTotalTerminations = await requests.get(API.REPORTS + `dashboard/total_terminations/`)

            let data_totals = initialStateTotals

            if (fetchTotalAccounts.status === 200) data_totals['accounts'] = fetchTotalAccounts.response.total_accounts || 0
            if (fetchTotalCandidates.status === 200) data_totals['candidates'] = fetchTotalCandidates.response.total_candidates || 0
            if (fetchTotalProjects.status === 200)  data_totals['projects'] = fetchTotalProjects.response.total_active_projects || 0
            if (fetchTotalOrders.status === 200)  data_totals['orders'] = fetchTotalOrders.response.total_orders || 0
            if (fetchTotalCases.status === 200)  data_totals['cases'] = fetchTotalCases.response.total_cases || 0
            if (fetchTotalContracts.status === 200)  data_totals['contracts'] = fetchTotalContracts.response.total_contracts || 0
            if (fetchTotalTerminations.status === 200)  data_totals['terminations'] = fetchTotalTerminations.response.total_terminations || 0

            setStats(totals)
            setTotalStats(data_totals)
            setLoading(false)
        }

        fetchUserStats()
    // eslint-disable-next-line
    }, [])


    const StatView = ({ icon, text, value, to, total }) => {
        return (
            <div style={{ padding: "1rem" }}>
                <Link to={to}>
                    <div style={{ display: "flex", marginTop: "1rem", marginBottom: "1.5rem", color: "var(--dark)" }}>
                        <div style={{ textAlign: "left", width: "30%" }}>
                            <Icon name={icon} style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "left" }}/>
                        </div>
                        <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.15rem" }}>
                            <span style={{ textTransform: "lowercase" }}>{ total }</span><br/>
                            
                        </div>
                    </div>

                    <div style={{ position: "relative", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", bottom: "0.5rem", textTransform: "uppercase", color: "var(--dark)", opacity: "0.8" }}>
                        <div style={{ fontSize: "0.9rem", textAlign: "left" }}>{ text }</div>
                        { value > 0 && 
                            <div style={{ textAlign: "right" }}>
                                <Label size="tiny" style={{ background: value > 0 ? "var(--success)" : "var(--dark)", color: "var(--white)" }}>
                                   <Icon name="arrow-up-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative" }}>{ value > 0 ? `+ ${value}` : value }</span>
                                </Label>
                            </div>
                        }
                    </div>
                </Link>
            </div>
        )
    }

    return (
        <SpinnerSegment loading={loading} marginBottom={loading ? "1rem" : 0}>
            <Grid columns={3} divided stackable>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column style={{ borderBottom: "1px solid #e1e1e1" }}>
                        <StatView
                            to={routes.ACCOUNTS}
                            icon={icons.ACCOUNTS}
                            text={t('accounts')}
                            value={stats.accounts}
                            total={totalStats.accounts}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ borderBottom: "1px solid #e1e1e1" }}>
                        <StatView
                            to={routes.CANDIDATES_LIST}
                            icon={icons.CANDIDATES}
                            text={t('candidates')}
                            value={stats.candidates}
                            total={totalStats.candidates}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ borderBottom: "1px solid #e1e1e1" }}>
                        <StatView
                            to={routes.ORDERS}
                            icon={icons.ORDERS}
                            text={t('orders')}
                            value={stats.orders}
                            total={totalStats.orders}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column style={{ borderBottom: "1px solid #e1e1e1" }}>
                        <StatView
                            to={routes.PROJECT_LIST}
                            icon={icons.PROJECTS}
                            text={t('projects')}
                            value={stats.projects}
                            total={totalStats.projects}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ borderBottom: "1px solid #e1e1e1" }}>
                        <StatView
                            to={routes.CASES}
                            icon={icons.CASES}
                            text={t('cases')}
                            value={stats.cases}
                            total={totalStats.cases}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ borderBottom: "1px solid #e1e1e1" }}>
                        <StatView
                            to={routes.CONTRACTS}
                            icon={icons.CONTRACTS}
                            text={t('contracts')}
                            value={stats.contracts}
                            total={totalStats.contracts}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column style={{ borderRight: "1px solid #e1e1e1" }}>
                        <StatView
                            to={routes.TERMINATIONS}
                            icon={icons.TERMINATIONS}
                            text={t('terminations')}
                            value={stats.terminations}
                            total={totalStats.terminations}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SpinnerSegment>
    )

}

const DashboardTotalStatsView = () => {
    const { t } = useTranslation()

    return (
        <Card fluid style={{ borderRadius: "2px" }}>
            <Card.Content>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5rem" }}>
                    <div>
                        <Header as="h4" style={{ marginBottom: "0.1rem" }}>
                            {t('growth_reports_from_the_last_30_days')}.
                        </Header>
                    </div>
                </div>

                <ManagerTotalStatistics/>
            </Card.Content>
        </Card>
    );
};

export default DashboardTotalStatsView;