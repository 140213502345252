import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { thousandsSeparators, setDotSeparator } from '@helpers/functions';
// components
import { Form, Button, Divider, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';

const OrderItemForm = ({ record, idx, setRecords, catalogue, onClose }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [selectedItem, setSelectedItem] = useState("")
    const [item, setItem] = useState({
        id: record?.id || 0,
        item: record?.item || "",
        item_title: record?.item_title || "",
        quantity: record?.quantity || 1,
        currency: record?.currency || "EUR",
        price_without_tax: record?.price_without_tax || 0,
        price_with_tax: record?.price_with_tax || "",
        tax: record?.tax || "",
        note: record?.note || "",
    })

    useEffect(() => {
        if (item.item !== "") {
            const catalogueItem = catalogue.find(catalogueItem => catalogueItem.id === item.item)
            setSelectedItem(catalogueItem)
        } else {
            setSelectedItem("")
        }

        // eslint-disable-next-line
    }, [item.item])

    const handleSubmit = () => {
        setProcessing(true)

        if (record?.id === undefined) {
            setRecords(prev => ([...prev, item]))
        } else {
            setRecords(prev => prev.map((orderItem, index) => {
                if (index === idx) {
                    orderItem = item
                }

                return orderItem
            }))
        }
        
        onClose()
        setProcessing(false)
    }

    const isPriceValid = (price) => {
        if (isNaN(price)) return false
        if (price === "") return false
        if (price < 0) return false

        return true
    }

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Header as="h3" content={t('manage_item')}/>
            <Divider/>

            <SuperField as="choice"
                search
                required
                label={t('item')}
                value={item.item}
                onChange={(e, { value }) => {
                    const catalogueItem = catalogue.find(item => item.id === value)
                    // create item title:
                    let title = ""
                    let currency = ""
                    let price_without_tax = ""
                    if (catalogueItem) {
                        title = (catalogueItem?.code ? catalogueItem?.code + " - " : "") + catalogueItem.title

                        if (catalogueItem?.buy_price_currency) currency = catalogueItem?.buy_price_currency
                        if (isPriceValid(catalogueItem?.sell_price_without_tax)) price_without_tax = catalogueItem?.sell_price_without_tax
                    }

                    let additionalData = {}
                    if (currency !== "") additionalData.currency = currency
                    if (price_without_tax !== "") additionalData.price_without_tax = price_without_tax

                    setItem(prev => ({
                        ...prev, 
                        item: value,
                        item_title: title,
                        weight_is_primary_measurement: catalogueItem?.weight_is_primary_measurement,
                        abbreviation: catalogueItem?.measure_unit?.abbreviation,
                        ...additionalData
                    }))

                }}
                customOptions={catalogue.map(item => ({ key: item.id, value: item.id, text: (item.code ? item.code + " - " : "") + item.title + " - " + (item.is_service ? t("service") : t('product')) }))}
            />
            <Divider/>

            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('unit_price')}
                    value={item.price_without_tax}
                    onChange={(e, { value }) => setItem(prev => ({...prev, price_without_tax: value.replace(",", ".") })) }
                />
                <SuperField as="input"
                    required
                    label={selectedItem?.weight_is_primary_measurement === true ? `${t('quantity')}${ selectedItem?.measure_unit?.abbreviation ? ` (${selectedItem?.measure_unit?.abbreviation})` : "" }` : t('packages_pieces')}
                    value={item.quantity}
                    onChange={(e, { value }) => setItem(prev => ({...prev, quantity: setDotSeparator(value)})) }
                />
            </Form.Group>
            <div style={{ marginBottom: "0.5rem" }}> 
                <strong>{ t("total_sum") }:</strong> { thousandsSeparators(parseFloat(item.price_without_tax * item.quantity)?.toFixed(2) || 0) } { item.currency || "EUR" } 
            </div>

            <Divider/>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    required
                    clearable={false}
                    label={t('currency')}
                    value={item.currency}
                    type="currency_codes"
                    onChange={(e, { value }) => setItem(prev => ({...prev, currency: value })) }
                />
            </Form.Group>
            {/* <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('price_with_tax')}
                    value={item.price_with_tax}
                    onChange={(e, { value }) => setItem(prev => ({...prev, price_with_tax: value.replace(",", ".") })) }
                />
                <SuperField as="input"
                    label={t('tax')}
                    value={item.tax}
                    onChange={(e, { value }) => setItem(prev => ({...prev, tax: value.replace(",", ".") })) }
                />
            </Form.Group> */}
            <SuperField as="textarea"
                label={t('note')}
                value={item.note}
                onChange={(e, { value }) => setItem(prev => ({...prev, note: value})) }
            />
            
            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <Button
                    primary
                    type="button"
                    loading={processing}
                    content={t('confirm')}
                    onClick={() => handleSubmit()}
                    disabled={processing || item.item === "" || item.quantity <= 0 || item.quantity === "" || !isPriceValid(item.price_without_tax)}
                />
            </Form.Field>
        </Form>
    );
};

export default OrderItemForm;