import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// store
import { APIRequest } from '@services/helpers/requests';
// components
import { Form, Search } from 'semantic-ui-react';

const SuggestionField = ({ label, disabled, endpoint, text, search, limit, offset, value, onSelect,  ...rest }) => {
    const { t } = useTranslation();
    const [searching, setSearching] = useState(false);
    const [results, setResults] = useState([]);

    const searchParam = search || "search"
    const limitParam = limit || 10
    // const offsetParam = offset || 50

    const onSelectingValue = (e, { result }) => {
        onSelect(result.title)
    }

    const handleSearchChange = async (e, { value }) => {
        setSearching(true);
        onSelect(value)

        if( !searching ){
            // handle api call
            const result = await APIRequest({
                url: endpoint + "?"+searchParam+"=" + value + "&paginate=true&limit=" + limitParam,
                method: "GET",
                private: true
            })

            let resultArray = [];
            if( result.status === 200 ){
                for (let i = 0; i < result.response.results.length; i++) {
                    resultArray.push({title: result.response.results[i][text]})
                }

                setResults(resultArray)
            }
        }
        setSearching(false);
    }

    const resultRenderer = ({ title }) => {
        return (
            <div key='content' style={{ width: "100%" }} className='content'>
                {title && <div className='title'>{title}</div>}
            </div>
        );
    };

    return (
        <Form.Field>
            <label>{ label }</label>
            <Search
                {...rest}
                input={{
                    placeholder: t('search'),
                    icon: 'search',
                    iconPosition: 'left'
                }}
                showNoResults={false}
                disabled={disabled}
                loading={searching}
                onResultSelect={(e, result) => onSelectingValue(e, result)}
                onSearchChange={_.debounce(handleSearchChange, 2000, {leading: true,})}
                value={value}
                results={results}
                resultRenderer={resultRenderer}
            />
        </Form.Field>
    );
};

export default SuggestionField;