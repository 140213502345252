import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput } from 'semantic-ui-calendar-react';
import { Form } from 'semantic-ui-react';

const DateField = ({ label, value, onChange, ...rest }) => {
    const { t } = useTranslation();

    return (
        <Form.Field>
            <DateInput
                {...rest}
                dateFormat="YYYY-MM-DD"
                placeholder={t('select_date')}
                iconPosition="left"
                label={label}
                value={value}
                onChange={onChange}
            />
        </Form.Field>
    );
};

export default DateField;