import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { routes } from '@routes';
// components
import ListView from '@components/ListView';
import { Label } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
// import FullHistoryLog from '../activity-log/FullHistoryLog';
import HiringRoomStats from '../../views/HiringRoomStats';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Link } from 'react-router-dom';

const RejectedListView = ({ params, stages, setStages, rejected, setRejected, blacklisted, setBlacklisted, totalCandidates, setListView }) => {
    const { t } = useTranslation();
    const [selectedStage, setSelectedStage] = useState("rejected")

    async function undoAction(id, lastStage, setData) {
        let endpoint = ""
        if (selectedStage === "rejected") endpoint = API.CANDIDATES + "rejections/" + id + "/delete/";
        const request = await requests.post(endpoint, { note: "" })
        if (request.status === 204) {
            setData(prev => prev.filter(data => data.id !== id))

            setStages(prev => prev.filter(item => {
                if (item.id === lastStage) {
                    item.candidate_count = item.candidate_count + 1
                }

                return item
            }))
        }
    }

    async function performSelectionUndoAction(selected, setData) {
        for (let i = 0; i < selected.length; i++) {
            await undoAction(selected[i].id, selected[i]?.last_stage?.id || stages[0], setData)
        }
    }

    const PutAvatar = ({ row }) => {
        return (
            <FlexItem basis="80%">
                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                    <FlexItem basis="10%">
                        <AvatarIcon size={35} name={row?.fullname} src={row?.profile_picture}/>
                    </FlexItem>
                    <FlexItem>
                        <div style={{ paddingLeft: "0.5rem" }}>
                            <Link to={routes.HIRING_ROOM + params.id + routes.CANDIDATES_DETAIL + row.id}><strong>{row?.fullname}</strong></Link>
                        </div>
                    </FlexItem>
                </FlexRow>
            </FlexItem>
        );
    }

    return (
        <>
            <ListView
                as="table"
                allowSearch
                endpoint={API.REJECTED}
                initialFilters={{
                    recruitment: params.id
                }}               
                actionsCellWidth="2"
                allowSelection
                actions={[
                    // {
                    //     as: "link",
                    //     type: "detail",
                    //     icon: "id-card-outline",
                    //     name: t('view_detail'),
                    //     redirect: (item) => routes.HIRING_ROOM + params.id + routes.CANDIDATES_DETAIL + item.id,
                    // },
                    // {
                    //     as: "modal",
                    //     type: "custom",
                    //     icon: "albums-outline",
                    //     name: t('history'),
                    //     modalSize: "tiny",
                    //     modal: (candidate) => <FullHistoryLog room={params.id} candidate={candidate.id} />
                    // },
                ]}
                bulkActions={(selected, setData) => [
                    { 
                        as: "custom",
                        name: t('undo_rejection'),
                        icon: "arrow-undo-outline",
                        onClick: () => performSelectionUndoAction(selected, setData)
                    },
                ]}
                onResponse={(response) => {
                    setRejected(response)
                } }
                renderStatisticView={(filters, data, total, params, setFilters, setTotal, setData, fetchData) => (
                    <HiringRoomStats
                        rejected={data}
                        blacklisted={blacklisted}
                        total={totalCandidates}
                        params={params}
                        filters={filters}
                        setFilters={setFilters}
                        fetchData={fetchData}
                        stages={stages}
                        setListView={setListView}
                        setSelectedStage={setSelectedStage}
                    />
                )}
                tableHeaders={
                    [
                        { title: t('name') },
                        { title: t('last_stage') },
                        { title: t('reason') },
                        { title: t('created_on') },
                    ]
                }
                renderCells={(candidate, setData) => ([
                    { content: <PutAvatar row={candidate?.profile} /> },
                    { content: 
                        <>
                            {candidate?.last_stage ?
                                <Label
                                    size="medium"
                                    style={{ background: candidate?.last_stage?.color }}>
                                    {candidate?.last_stage?.name}
                                </Label>
                                : "-"
                            }
                        </>
                    },
                    { content: candidate?.note },
                    { content: moment(candidate?.created_on).format("DD.MM.YYYY HH:mm")},
                ])}
            />
        </>
    );
};

export default RejectedListView;