import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from "@helpers/validation"
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Message, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';

const IntegrationForm = ({ integration, result, setResult, onClose }) => {
    const { t } = useTranslation();

    const [integrationID] = useState(integration?.configuration ? integration.configuration.id : 0);

    const [username, setUsername] = useState(integration?.configuration ? integration.configuration.username : "");
    const [password, setPassword] = useState("");
    const [integr] = useState(integration.id);

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleError = (response) => {
        let errorList = [];
        if (response.non_field_errors) {
            for (let i = 0; i < response.non_field_errors.length; i++) {
                errorList.push(response.non_field_errors[i]);
            }
        }

        if (response.detail) {
            for (let i = 0; i < response.detail.length; i++) {
                errorList.push(response.detail[i]);
            }
        }

        setErrors(errorList);
    }

    const deactivate = async (id) => {
        const request = await requests.del(API.AUTH_CONFIGS + integrationID + '/');
        if (request.status === 204) {
            setResult(prevState => prevState.filter((item) => {
                if (id === item.id) {
                    item.configuration = '';
                    item.is_active = false
                }
                return item;
            }));

            onClose()
        }
    }

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrors([])
        let request = null;

        const data = {
            username: username,
            password: password
        }

        if (!integration?.configuration) {
            request = await requests.post(API.AUTH_CONFIGS, { ...data, integration: integr })
            if (request.status === 201) {
                setResult(prevState => prevState.filter((item) => {
                    if (item.id === request.response.integration) {
                        item.configuration = request.response;
                        item.is_active = true
                    }
                    return item;
                }));
                onClose()
            } else {
                handleError(request.response);
            }
        } else {
            request = await requests.put(API.AUTH_CONFIGS + integrationID + "/", data)
            if (request.status === 200) {
                setResult(prevState => prevState.filter((item) => {
                    if (item?.configuration?.id === request.response.id) {
                        item.configuration.username = request.response.username;
                    }
                    return item;
                }));
                onClose()
            } else {
                handleError(request.response);
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t('error_submission_message')}
                list={errors}
            />

            <SuperField as="input"
                label={t('username')}
                autoFocus
                required
                value={username}
                onChange={(e, { value }) => setUsername(value)}
            />
            <SuperField as="input"
                type="password"
                required
                label={t('password')}
                value={password}
                onChange={(e, { value }) => setPassword(value)}
            />

            <Divider />
            <Form.Group style={{ marginBottom: 0 }}>
                {integration?.configuration &&
                    <CanView permissions={['integrations.c_delete_third_party_auth']}>
                        <Form.Field width="8" style={{ textAlign: "left" }}>
                            <Action as='custom' type='button' buttonText={t('delete')} backgroundColor="red" iconColor='var(--white)' paddingLeft="1rem" paddingRight="1rem"
                                disabled={isProcessing}
                                onClick={() => deactivate(integration.configuration.integration)}
                            />
                        </Form.Field>
                    </CanView>
                }
                <Form.Field width={integration.configuration ? "8" : '16'} style={{ textAlign: "right" }}>
                    <ModalCancel
                        onClose={onClose}
                        disabled={isProcessing}
                    />
                    <ModalSubmit
                        loading={isProcessing}
                        disabled={isProcessing || isEmpty(username) || isEmpty(password)}
                        text={t('save')}
                    />
                </Form.Field>
            </Form.Group>

        </Form >
    )
}
export default IntegrationForm;