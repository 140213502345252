import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { createRequest } from '@services/ServiceCommon';
import { isEmpty, isPhoneNumberValid, isEmailValid } from '@helpers/validation';
// components
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Header, Divider } from 'semantic-ui-react';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';

const EmployeeForm = ({ onClose }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [form, setForm] = useState({
        firstname: "",
        lastname: "",
        gender: "",
        phone: "", // atleast one
        email: "", // atleast one
        personal_number: "", // rodne číslo
        marital_status: "",
        date_of_birth: "",

        // business details
        name: "",
        id_number: "",          // IČO (identification_number)
        dit_number: "",         // DIČ (DIT Number)
        vat_number: "",         // IČ DPH (VAT Number) - optional
        nace_code: "",
        folder_details: "",     // business folder identifier

        // additional_info
        is_external: false,
        is_foreigner: false,
        is_freelancer: false,
        is_employed_by_agencies: false,
        approve_automatically: false,
        unit: null,
    })
    const [errors, setErrors] = useState({
        date_of_birth_error: null,
        personal_number_error: null
    })
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async () => {
        setIsProcessing(true)

        setErrors({
            date_of_birth_error: null,
            personal_number_error: null
        })

        let data = {
            is_external: form.is_external,
            is_foreigner: form.is_foreigner,
            approve_automatically: form.approve_automatically,
            marital_status: form.marital_status !== "" ? form.marital_status : null,
            personal_number: form.personal_number !== "" ? form.personal_number : null,
            firstname: form.firstname,
            lastname: form.lastname,
            gender: form.gender ? form.gender : 3,
            date_of_birth: form.date_of_birth === "" ? null : form.date_of_birth,
            business_detail: !form.is_freelancer ? null : {
                name: form.name,
                identification_number: form.id_number,
                dit_number: form.dit_number,
                vat_number: form.vat_number,
                business_register_folder: form.folder_details,
                nace_code: form.nace_code !== "" ? form.nace_code : null,
            },
            unit: (form.unit === null || form.unit === "") ? null : form.unit
        }

        if (!isEmpty(form.phone) || !isEmpty(form.email)) {
            data['contacts'] = { 
                create: [{
                    phone: form?.phone || "",
                    email: form?.email || "",
                    is_default: true,
                }]
            }
        }
        
        const request = await createRequest(API.EMPLOYEES, data)

        if( request.status === 201 ){
            history.push(routes.EMPLYOEES_DETAIL + request.response.id)
        }

        if( request.status === 400 ){
            if( request.response?.date_of_birth ){
                setErrors(prevState => ({ ...prevState, date_of_birth_error: request.response?.date_of_birth[0] }))
            }

            if( request.response?.personal_number ){
                setErrors(prevState => ({ ...prevState, personal_number_error: request.response?.personal_number[0] }))
            }
        }

        setIsProcessing(false)
    }

    const isContactInfoValid = (phone, email) => {
        let isValid = false
        if( phone !== "" && email === "" ) {
            if( isPhoneNumberValid(phone) ){
                isValid = true
            }
        } else if( email !== "" && phone === "" ) {
            if( isEmailValid(email) ){
                isValid = true
            }
        } else if( email !== "" && phone !== "" ){
            if( isPhoneNumberValid(phone) && isEmailValid(email) ){
                isValid = true
            }
        }

        return isValid
    }

    const isFilledRequiredFields = () => {
        let isValid = true

        // validate if business info are filled property
        if( isEmpty(form.firstname) || isEmpty(form.lastname) ){
            return false
        }

        // validate if contact information are filled properly
        if( !isContactInfoValid(form.phone, form.email)){
            return false
        }

        if( form.is_freelancer ){
            if( isEmpty(form.id_number) || isEmpty(form.name) ){
                return false
            }
        }

        return isValid
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={t('personal_information')}/>
            <Divider/>

            <Form.Group widths={2}>
                <SuperField as="input"
                    autoFocus
                    required
                    label={t("firstname")}
                    placeholder={t("enter_firstname")}
                    value={form.firstname}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        firstname: value
                    })}
                />

                <SuperField as="input"
                    required
                    label={t("lastname")}
                    placeholder={t("enter_lastname")}
                    value={form.lastname}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        lastname: value
                    })}
                />
            </Form.Group>
            <Form.Group widths={2}>
                <SuperField as="datepicker"
                    label={t("date_of_birth")}
                    startMode="year"
                    value={form.date_of_birth}
                    error={errors.date_of_birth_error}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        date_of_birth: value
                    })}
                />
                <SuperField as="input"
                    label={t("personal_number")}
                    value={form.personal_number}
                    error={errors.personal_number_error}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        personal_number: value
                    })}
                />
            </Form.Group>
            <Form.Group widths={2}>
                <SuperField as="choice"
                    type="genders"
                    label={t('gender')}
                    value={form.gender}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        gender: value
                    })}
                />
                <SuperField as="choice"
                    type="marital_statuses"
                    label={t("marital_status")}
                    value={form.marital_status}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        marital_status: value
                    })}
                />
            </Form.Group>

            <Header as="h3" content={ t('contact_info') }/>
            <p style={{ color: "var(--danger)" }}> { t('contact_info_hint_message') }. </p>
            <Divider/>

            <Form.Group widths="equal">
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={form.phone}
                    setValue={(e, { value }) => setForm({ 
                        ...form,
                        phone: value
                    })}
                    />
                <EmailField
                    label={t('email')}
                    placeholder={t('enter_email')}
                    value={form.email}
                    setValue={(e, { value }) => setForm({ 
                        ...form,
                        email: value
                    })}
                />
            </Form.Group>

            <Divider/>

            <Form.Group widths='equal'>
                <Form.Field>
                    <Header as="h4" content={ t('additional_configuration') }/>
                    <Divider/>
                    <SuperField as="checkbox"
                        label={t('set_is_freelancer')}
                        checked={form.is_freelancer}
                        onChange={(e) => setForm({
                            ...form,
                            is_freelancer: !form.is_freelancer,
                        })}
                    />
                    <br/>
                    <SuperField as="checkbox"
                        label={t('set_is_external')}
                        checked={form.is_external}
                        onChange={(e) => setForm({...form, is_external: !form.is_external })}
                    />
                    <br/>
                    <SuperField as="checkbox"
                        label={t('set_is_foreigner')}
                        checked={form.is_foreigner}
                        onChange={(e) => setForm({...form, is_foreigner: !form.is_foreigner })}
                    />
                    <br/>
                    <SuperField as="checkbox"
                        label={t('set_as_agency_employee')}
                        checked={form.is_employed_by_agencies}
                        onChange={(e) => setForm({...form, is_employed_by_agencies: !form.is_employed_by_agencies })}
                    />
                </Form.Field>
                <CanView permissions={["company.c_view_units"]}>
                    <Form.Field>
                        <Header as="h4" content={ t('assign_to_unit') }/>
                        <Divider/>
                        <SuperField
                            as="choice"
                            endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                            text="name"
                            value={form.unit}
                            onChange={(e, { value }) => setForm({...form, unit: value })} 
                        />
                    </Form.Field>
                </CanView>
            </Form.Group>

            { form.is_freelancer &&
                <>
                    <Header as="h3" content={ t('business_detail') }/>
                    <Divider/>

                    <SuperField as="input"
                        required
                        label={t('name')}
                        placeholder={t('name')}
                        value={form.name}
                        onChange={(e, { value }) => setForm({ ...form, name: value })}
                    />
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            required
                            label={t('id_number')}
                            placeholder={t('id_number')}
                            value={form.id_number}
                            onChange={(e, { value }) => setForm({ ...form, id_number: value })}
                        />
                        <SuperField as="input"
                            label={t('dit_number')}
                            placeholder={t('dit_number')}
                            value={form.dit_number}
                            onChange={(e, { value }) => setForm({ ...form, dit_number: value })}
                        />
                        <SuperField as="input"
                            label={t('vat_number')}
                            placeholder={t('vat_number')}
                            value={form.vat_number}
                            onChange={(e, { value }) => setForm({ ...form, vat_number: value })}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <SuperField as="input"
                            label={t('register_folder')}
                            placeholder={t('register_folder')}
                            value={form.folder_details}
                            onChange={(e, { value }) => setForm({ ...form, folder_details: value })}
                        />
                        <SuperField as="choice"
                            search
                            label={t('nace_code')}
                            endpoint={API.NACE_CODE}
                            text="name"
                            value={form.nace_code}
                            onChange={(e, { value }) => setForm({ ...form, nace_code: value })}
                        />
                    </Form.Group>

                    <Header as="h3" content={ t('invoice_approval') }/>
                    <p> { t('invoice_approval_hint_message') }. </p>
                    <Divider/>

                    <SuperField as="checkbox"
                        label={ t('automatic') }
                        checked={form.approve_automatically}
                        onChange={() => setForm({...form, approve_automatically: !form.approve_automatically })}
                    />
                </>
            }
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || !isFilledRequiredFields()}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default EmployeeForm;