import React from 'react';
import { useTranslation } from "react-i18next";
// components
import { Form } from 'semantic-ui-react';
import ChoiceField from '@components/forms/common/ChoiceField';

const JobOfferRequirements = ({ requirements, setRequirements}) => {
    const { t } = useTranslation();

    return (
        <div>
            <Form.Group widths="equal">
                <ChoiceField
                    multiple
                    type="education_type_choices"
                    label={t('educations')}
                    value={requirements.educations.map(String)}
                    onChange={ (e, { value }) => setRequirements({
                        ...requirements,
                        educations: value
                    }) }
                />
                <ChoiceField
                    multiple
                    type="driving_licence_choices"
                    label={t('driver_licences')}
                    value={requirements.drivers_licences.map(String)}
                    onChange={ (e, { value }) => setRequirements({
                        ...requirements,
                        drivers_licences: value
                    }) }
                />
            </Form.Group>

            <Form.Group widths="equal">
                <ChoiceField
                    multiple
                    search
                    type="citizenship"
                    label={t('citizenship')}
                    value={requirements.citizenships}
                    onChange={ (e, { value }) => setRequirements({
                        ...requirements,
                        citizenships: value
                    }) }
                />
                <ChoiceField
                    multiple
                    type="eligibility_choices"
                    label={t('eligibility')}
                    value={requirements.eligibilities.map(String)}
                    onChange={ (e, { value }) => setRequirements({
                        ...requirements,
                        eligibilities: value
                    }) }
                />
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        label={t('age_lower')}
                        type="number"
                        value={requirements.age_lower ? requirements.age_lower : ""}
                        error={ requirements.age_lower < 15 && requirements.age_lower > 0 ?
                            "Age lower should be higher then 15"
                            : false
                        }
                        onChange={ (e) => setRequirements({
                            ...requirements,
                            age_lower: e.target.value
                        }) }
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label={t('age_higher')}
                        type="number"
                        error={ requirements.age_higher < 15 && requirements.age_lower > 0 ?
                            "Age higher should be higher then 15"
                            : requirements.age_higher >= requirements.age_lower ? false
                            :"Age higher should be more then " + requirements.age_lower
                        }
                        value={requirements.age_higher ? requirements.age_higher : ""}
                        onChange={ (e) => setRequirements({
                            ...requirements,
                            age_higher: e.target.value
                        }) }
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label={t('practice_years')}
                        type="number"
                        min="1"
                        value={requirements.practice_years ? requirements.practice_years : ""}
                        onChange={ (e) => setRequirements({
                            ...requirements,
                            practice_years: e.target.value
                        }) }
                    />
                </Form.Field>

                <Form.Field>
                    <ChoiceField
                        type="job_gender_choices"
                        label={t('gender')}
                        value={requirements.gender}
                        onChange={ (e, { value }) => setRequirements({
                            ...requirements,
                            gender: value
                        }) }
                    />
                </Form.Field>

            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        label={t('specialization')}
                        value={requirements.specialization}
                        onChange={ (e) => setRequirements({
                            ...requirements,
                            specialization: e.target.value
                        }) }
                    />
                </Form.Field>
            </Form.Group>
        </div>
    );
};

export default JobOfferRequirements;