import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { tzDateTime } from '@helpers/dates';
// import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Header, Container, Divider, Popup } from 'semantic-ui-react';
import ListView from '@components/ListView';

const StoredItemHistory = ({ record }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    return (
        <>
            <Container fluid>
                <Header as="h3"
                    content={
                        <>
                            {t('stored_item_history') + ": "}
                            <span style={{ fontWeight: "normal" }}>
                                {( record.catalogue_item?.code ? record.catalogue_item.code + " - " : "" ) + record.catalogue_item.title + " (" + record.warehouse.title + ")"}
                            </span>
                        </>
                    }
                />
                <Divider/>
            </Container>
            <ListView as="table"
                allowSearch
                endpoint={API.ASSETS + "history/"}
                initialFilters={{
                    stored_item: record.id,
                    operation: [1, 5, 6],
                    ordering: "-created_on"
                }}
                listAdditionActions={() => ([
                    {
                        as: "filter",
                        index: 0,
                        name: t('all'),
                        filters: {
                            operation: [1, 5, 6],
                        },
                    },
                    {
                        as: "filter",
                        index: 1,
                        name: t('stock_loading'),
                        filters: {
                            operation: [5],
                        },
                    },
                    {
                        as: "filter",
                        index: 2,
                        name: t('stock_unloading'),
                        filters: {
                            operation: [6],
                        },
                    },
                    {
                        as: "filter",
                        index: 3,
                        name: t('correction'),
                        filters: {
                            operation: [1],
                        },
                    },
                ])}
                tableHeaders={[
                    { title: t('operation'), orderBy: "operation" },
                    { title: t('quantity'), orderBy: "quantity" },
                    { title: t('warehouse_position'), orderBy: "warehouse_position__title" },
                    { title: t('unit_price'), orderBy: "unit_price" },
                    { title: t('supplier'), orderBy: "supplier__name" },
                    { title: t('performed_by'), orderBy: "performed_by__fullname" },
                    { title: t('created_on'), orderBy: "created_on" },
                    { title: t('note') },
                ]}
                renderCells={(history) => (
                    [
                        { content: history.operation_display },
                        { content: 
                            <>
                                { history.quantity }
                                { history.weight_is_primary_measurement 
                                    ? 
                                        <Popup
                                            position='right center'
                                            trigger={
                                                <Icon name="information--circle-outline" style={{ marginLeft: "0.5rem", fontSize: "1.2rem" }}/>
                                            }
                                            content={t("quantity_as_weight") }
                                        />
                                    : ""
                                }
                            </>
                        },
                        { content: history.warehouse_position ? (history.warehouse_position.title + (history.warehouse_position.section && " / " + history.warehouse_position.section ) +  (history.warehouse_position.row && " / " + history.warehouse_position.row)) : "--" },
                        { content: history?.unit_price ? (history?.unit_price + " " + history?.currency) : "--" },
                        { content: history?.supplier ? history?.supplier?.name : "--" },
                        { content: history.performed_by?.fullname || "--" },
                        { content: tzDateTime(history.created_on).format(dateFormat + " HH:mm") },
                        { content: (history.reason || history.note) },
                    ]
                )}
            />
        </>
    );
};

export default StoredItemHistory;