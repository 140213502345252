import React from 'react'

const HeaderWithButton = (props) => {
    return (
        <div style={styles.headerContent}>
            <span style={styles.headerText}>{props.textLabel}</span> 
            {props.button}
        </div>
    );
};
const styles = {
    headerText: { fontSize: 28 },
    headerContent: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        marginBottom: "0.5rem",
        paddingTop: "2rem",
        width: "100%",
    },
};
export default HeaderWithButton;
