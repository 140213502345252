import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isValidNumber, setDotSeparator } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import { Form, Divider, Message } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';


const ProcessItemForm = ({ record, setData, onClose, setHeader }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [storedItems, setStoredItems] = useState([])
    const [form, setForm] = useState({
        quantity: "",
        processing_quantity: "",
        average_weight: "",
        item: ""
    })

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.post(API.ASSETS + "warehouse_process_items/" , {
            quantity: form.quantity,
            average_weight: form.average_weight || null,
            item: form.item,
            processing_quantity: form.processing_quantity || null
        })

        if (request.status === 201) {
            let processItem = request.response?.id
            const processRequest = await requests.patch(API.ASSETS + "warehouse_processes/"+ record.id +"/", {
                items: {
                    add: [processItem]
                }
            })

            if (processRequest.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record.id) item = processRequest.response
                    return item
                }))

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('commodity_was_assigned_for_processing'),
                    animation: 'pulse',
                    time: 2000,
                })

                onClose()
            }
        }
        setProcessing(false)
    }

    const isWeightMeasurement = (storedItem) => {
        const findItem = storedItems.find(item => item?.id === storedItem)
        if (findItem?.catalogue_item?.weight_is_primary_measurement === true) return true

        return false
    }

    const isFormValid = () => {
        if (record === undefined) return false
        if (form.item === "") return false
        if (!isValidNumber(form.quantity, false, false)) return false
        if (isWeightMeasurement(form.item) && !isValidNumber(form.average_weight, false, false)) return false
        return true
    }

    useEffect(() => {
        async function fetchStoredItems() {
            setLoading(true)
            const request = await requests.get(API.ASSET_STORED_ITEMS + "?query={id, code, catalogue_item{id, title, code, weight_is_primary_measurement}, warehouse{id, title}}&exclude_asset_item=true")
            if (request.status === 200) setStoredItems(request.response)
            setLoading(false)
        }

        setHeader(t('assign_commodity'))
        fetchStoredItems()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (form.item !== "") {
            let totalUnits = ""
            if (form.quantity > 0 && form.average_weight > 0) {
                totalUnits = parseFloat(form.quantity / form.average_weight)
            }
            setForm(prev => ({...prev, processing_quantity: totalUnits}))
        } else {
            setForm(prev => ({...prev, average_weight: "", processing_quantity: ""}))
        }
    }, [form.quantity, form.item, form.average_weight])

    

    return (
        <Form onSubmit={handleSubmit}>
            { form.item !== "" &&
                isWeightMeasurement(form.item) && (
                    <Message visible info>
                        <Icon name="information-circle" style={{ marginRight: "0.5rem" }}/>
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            {t('stored_item_measurement_is_set_to_weight_set_average_weight_for_unloading_unit')}.
                        </span>
                    </Message>
                )
            }

            <SuperField as="choice"
                required
                search
                value={form.item}
                loading={loading}
                disabled={loading}
                label={t('stored_item')}
                customOptions={storedItems.map(storedItem => ({
                    key: storedItem.id,
                    value: storedItem.id,
                    text: `${storedItem.code ? storedItem.code + ": " : storedItem?.catalogue_item?.code ? storedItem?.catalogue_item?.code + ": " : ""}${storedItem?.catalogue_item?.title} - ${storedItem.warehouse.title}`
                }))}
                onChange={(e, { value }) => setForm(prev => ({...prev, item: value}))}
            />

            { form.item !== "" && 
                <>
                    <SuperField as="input"
                        required
                        autoFocus
                        label={t('quantity')}
                        help={t('stored_item_quantity_to_process')}
                        error={!isValidNumber(form.quantity, true, false) ? t('invalid_quantity') : false}
                        value={form.quantity}
                        onChange={(e, { value }) => setForm(prev => ({...prev, quantity: setDotSeparator(value)}))}
                    />
                
                    { isWeightMeasurement(form.item) && 
                        <Form.Group widths="equal">
                            <SuperField as="input"
                                required
                                help={t('average_weight_for_unloadin_hint')}
                                label={t('average_unit_weight')}
                                value={form.average_weight}
                                error={!isValidNumber(form.quantity, true, false) ? t('not_a_number') : false}
                                onChange={(e, { value }) => setForm(prev => ({...prev, average_weight: setDotSeparator(value)}))}

                            />
                            <SuperField as="input"
                                readOnly={true}
                                help={t('processing_units_for_unloadin_hint')}
                                label={t('unloading_quantity')}
                                value={form.processing_quantity}
                            />
                        </Form.Group>
                    }
                </>
            }
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing || !isFormValid()}
                    loading={processing}
                />
            </Form.Field>
        </Form>
    );
};

export default ProcessItemForm;