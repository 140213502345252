import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { requests } from '@helpers/requests';
// components
import { Grid, Icon as SemanticIcon } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';


const ProjectMembersTable = ({ projectMembers, setProjectMembers, project }) => {
    const { t } = useTranslation()

    const onDelete = async (id) => {
        const request = await requests.del(API.PROJECT_MEMBERS + id + "/")
        if(request.status === 204){
            setProjectMembers(projectMembers.filter(item => item.id !== id))
        }
    }

    const PutAvatar = ({ row }) => {
        return (
            <Grid style={{ padding: 0 }} >
                <Grid.Row style={{ padding: 0 }}>
                    <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                        <AvatarIcon
                            size={35}
                            name={row.profile?.fullname}
                            src={row.profile?.profile_picture}
                        />
                    </SemanticIcon.Group>
                    { row?.profile?.user?.username ?
                    <span style={{ display: "flex", flexDirection: "column", paddingTop: "0.5rem" }}>
                        <Link to={routes.EMPLYOEES_DETAIL + row?.profile?.id} target="_blank">
                            <strong>{row?.profile?.fullname}</strong>
                        </Link>
                            <div>{row?.profile?.user?.username}</div>
                    </span> :
                    <span style={{ display: "flex", flexDirection: "column", paddingTop: "0.9rem" }}>
                        <Link to={routes.EMPLYOEES_DETAIL + row?.profile?.id} target="_blank">
                            <strong>{row?.profile?.fullname}</strong>
                        </Link>
                    </span>
                    }

                </Grid.Row>
            </Grid>
        );
    }

    return (
        <>
            <FlexTable>
                <FlexRow>
                    <FlexHeader basis="120%" content={t('employee')} />
                    <FlexHeader content={t('phone')} />
                    <FlexHeader content={t('email')} />
                    <FlexHeader content={t('external_employee')} />
                    <FlexHeader content={t('access_granted')} />
                    { project?.allow_gitlab &&
                        <FlexHeader content={t('gitlab_role')} />
                    }
                    <FlexHeader basis="30%" content={t('actions')} />
                </FlexRow>
                <EmptyRow length={projectMembers?.length} />
                {projectMembers?.map((projectMember, index) => (
                    <FlexRow key={index} fontSize="0.9rem" borders>
                        <FlexItem basis="120%" content={<PutAvatar row={projectMember} />} />

                        <FlexItem content={projectMember?.profile?.personal_information?.contact_info?.phones?.[0]?.phone} />
                        <FlexItem content={projectMember?.profile?.personal_information?.contact_info?.emails?.[0]?.email} />
                        <FlexItem content={projectMember?.profile?.is_external === true ? t('yes'): t('no')} />
                        <FlexItem>
                            <span><strong>{moment(projectMember?.created_on).format("YYYY-MM-DD")}</strong> {t('by')} </span> <br />
                                <Link to={routes.EMPLYOEES_DETAIL + projectMember?.created_by?.profile_id} target="_blank">
                                    <strong>{projectMember?.created_by?.name}</strong>
                                </Link>
                            <strong>{projectMember?.milestone?.name}</strong>  <br />
                            { projectMember?.milestone?.from_date && projectMember?.milestone?.due_date &&
                                <span>{projectMember?.milestone?.from_date} - {projectMember?.milestone?.due_date}</span>
                            }
                        </FlexItem>
                        { project?.allow_gitlab &&
                            <FlexItem content={projectMember?.gitlab_role_display} />
                        }
                        <FlexItem basis="30%">
                            <CanView permissions={['projects.c_manage_all_projects', 'projects.c_manage_user_projects']}>
                                <FlexRow background="transparent" padding="0">
                                    {/* TODO: G. add action to edit gitlab integration or update table */}
                                    {/* <FlexItem textAlign="center">
                                        <Action
                                            as="modal"
                                            type="icon"
                                            icon="pencil"
                                            size="small"
                                            popupText={t('edit')}
                                            modal={
                                                <></>
                                            }
                                        />
                                    </FlexItem> */}
                                        <FlexItem textAlign="center">
                                            <Action
                                                as='delete'
                                                text={t('remove_project_member')}
                                                onClick={() => onDelete(projectMember.id)}
                                            />
                                    </FlexItem>
                                </FlexRow>
                            </CanView>
                        </FlexItem>
                    </FlexRow>
                ))}
            </FlexTable>
        </>
    );
};

export default ProjectMembersTable;