import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//components
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import Loading from '@components/general/Loading'
import Attachment from './Attachment'
import GroupForm from './GroupForm'
import DeleteGroupForm from './DeleteGroupForm'

const AttachmentGroups = ({
    group,
    docs,
    onDelete,
    hideCreate,
    module,
    moduleName,
    setDocsWithGroup,
    setDocsWithoutGroup,
    options,
    setGroupOptions,
    handleAddGroup,
    setGroups,
    canManage,
}) => {
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)
    const [groupDocs, setGroupDocs] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isHover, setIsHover] = useState(false)
    const [groupName, setGroupName] = useState(group.name)

    useEffect(() => {
        async function fetchData() {
            setGroupDocs(docs.filter((doc) => doc.group?.id === group.id))
            setIsLoading(false)
        }

        fetchData()
    }, [docs, group.id])

    const onHover = () => {
        setIsHover(true)
    }
    const onHoverOut = () => {
        setIsHover(false)
    }

    const updateName = async (newName) => {
        setGroupName(newName.name)
    }
    const deleteGroup = (groupID, removeDocs, groupDocs) => {
        if (!removeDocs) {
            setDocsWithoutGroup((prev) => [...groupDocs, ...prev])
        }
        setGroups((prev) => prev.filter((item) => item.id !== groupID))
        setGroupOptions((prev) => prev.filter((item) => item.value !== groupID))
    }

    return (
        <Grid>
            <Grid.Row style={{ paddingTop: 0 }}>
                <Button.Group onMouseOver={onHover} onMouseOut={onHoverOut}>
                    <Button
                        icon
                        labelPosition="left"
                        style={{ backgroundColor: 'white' }}
                        onClick={() => setOpen(!open)}
                    >
                        <Icon
                            name={open ? 'folder open' : 'folder'}
                            style={{ backgroundColor: 'white', color: 'var(--primary)' }}
                            size="large"
                        />
                        {groupName + ' (' + groupDocs.length + ')'}
                    </Button>
                    {hideCreate !== true && (
                        <Dropdown
                            button
                            inline
                            icon="pencil alternate"
                            style={
                                isHover
                                    ? { backgroundColor: 'var(--default)', color: 'var(--primary)', padding: 0 }
                                    : { display: 'none' }
                            }
                        >
                            <Dropdown.Menu>
                                <Dropdown.Header content={t('update_att_group')} />
                                <Dropdown.Divider />
                                <SuperDuperModal
                                    trigger={<Dropdown.Item icon="pencil" text={t('rename_group')} />}
                                    size="small"
                                    header={t('rename_group')}
                                    content={
                                        <GroupForm
                                            module={module}
                                            groups={group}
                                            onConfirm={(item) => updateName(item)}
                                        />
                                    }
                                />
                                <SuperDuperModal
                                    header={t('delete_group')}
                                    content={
                                        <DeleteGroupForm
                                            group={group}
                                            groupDocs={groupDocs}
                                            setGroupDocs={setGroupDocs}
                                            deleteGroup={(groupID, removeDocs, groupDocs) =>
                                                deleteGroup(groupID, removeDocs, groupDocs)
                                            }
                                        />
                                    }
                                    trigger={<Dropdown.Item icon="trash alternate" text={t('delete_group')} />}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </Button.Group>
            </Grid.Row>
            {open && (
                <div>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            {docs &&
                                groupDocs.map((doc) => (
                                    <Attachment
                                        key={doc.id}
                                        docs={doc}
                                        onDelete={(id) => onDelete(id)}
                                        canMove={canManage}
                                        canManage={canManage}
                                        moduleName={moduleName}
                                        setDocsWithGroup={setDocsWithGroup}
                                        setDocsWithoutGroup={setDocsWithoutGroup}
                                        options={options}
                                        setGroupOptions={setGroupOptions}
                                        setGroups={setGroups}
                                        handleAddGroup={(item) => handleAddGroup(item)}
                                    />
                                ))}
                        </>
                    )}
                </div>
            )}
        </Grid>
    )
}

export default AttachmentGroups
