import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import { Segment, Header } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import BarGraph from '@components/graphs/BarGraph';

const PublishedByRecruiter = () => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const colors = useSelector(state => state.global_pref.colors)
    const [loading, setLoading] = useState(true)
    const [recruiters, setRecruiters] = useState([])
    const [filters, setFilters] = useState({
        publish_when_after: '',
        publish_when_before: '',
        recruiter: null,
        publishers: [1, 2, 3, 4, 5, 6],
    })

    const publishers = {
        1: "Profesia.sk",
        2: "Profesia.cz",
        3: "Kariera.sk",
        4: "Rabota.ua",
        5: "Work.ua",
        6: "Proffesion.hu",
    }
    const graphOptions = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        plugins: {
            legend: {
                title: 's'
            }
        }

    }

    useEffect(() => {
        async function fetchRecruiters(){
            const request = await getRequest(API.EMPLOYEES + "?query={id, fullname, user{ id, name }}&is_recruiter=true")

            if(request.status === 200){
                let recruiterList = []
                for (let i = 0; i < request.response.length; i++) {
                    if( request.response[i].user !== null ){
                        recruiterList.push({
                            id: request.response[i]?.user?.id,
                            text: request.response[i].fullname,
                            value: request.response[i]?.user?.id,
                        })
                    }
                }

                setRecruiters(recruiterList)
            }
        }

        fetchRecruiters()
    }, [])

    useEffect(() => {
        async function fetchRecruitingStats() {
            if (filters.recruiter) {
                setLoading(true)
                let labels = []
                let dataCount = []
                let endpoint = `${API.JOBS}publications/?published_by=${filters.recruiter}`
                if (filters.publish_when_after) endpoint += `&publish_when_after=${filters.publish_when_after}`
                if (filters.publish_when_before) endpoint += `&publish_when_before=${filters.publish_when_before}`
                let request = await getRequest(endpoint)
                if (request.status === 200) {

                    labels.push("All")
                    labels.push(publishers[1])
                    labels.push(publishers[2])
                    labels.push(publishers[3])
                    labels.push(publishers[4])
                    labels.push(publishers[5])
                    labels.push(publishers[6])

                    let profesia_sk = 0;
                    let profesia_cz = 0;
                    let kariera_sk = 0;
                    let rabota_ua = 0;
                    let work_ua = 0;
                    let proffesion_hu = 0;
                    for (let i = 0; i < request.response.length; i++) {
                        if( request.response[i].publishers[0] === 1 ){
                            profesia_sk += 1
                        }
                        if( request.response[i].publishers[0] === 2 ){
                            profesia_cz += 1
                        }
                        if( request.response[i].publishers[0] === 3 ){
                            kariera_sk += 1
                        }
                        if( request.response[i].publishers[0] === 4 ){
                            rabota_ua += 1
                        }
                        if( request.response[i].publishers[0] === 5 ){
                            work_ua += 1
                        }
                        if( request.response[i].publishers[0] === 6 ){
                            proffesion_hu += 1
                        }

                    }
                    dataCount.push(request.response.length)
                    dataCount.push(profesia_sk)
                    dataCount.push(profesia_cz)
                    dataCount.push(kariera_sk)
                    dataCount.push(rabota_ua)
                    dataCount.push(work_ua)
                    dataCount.push(proffesion_hu)
                    setData({
                        labels: labels,
                        datasets: [{
                            label: "Publication count",
                            data: dataCount,
                            backgroundColor: colors.primary
                        }]
                    })
                }
                setLoading(false)
            }
        }
        fetchRecruitingStats()
        // eslint-disable-next-line
    }, [filters])

    return (
        <>
            <FlexRow padding="1rem">
                <FlexItem>
                    <Header as="h2" textAlign="left" content={t('publication_count')} />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField
                        as='datepicker'
                        placeholder={t('date_from')}
                        value={filters.publish_when_after}
                        onChange={(e, { value }) => setFilters({ ...filters, publish_when_after: value })}
                    />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField
                        as='datepicker'
                        placeholder={t('date_to')}
                        value={filters.publish_when_before}
                        onChange={(e, { value }) => setFilters({ ...filters, publish_when_before: value })}
                    />
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField as="choice"
                        customOptions={recruiters}
                        value={filters.recruiter}
                        onChange={(e, { value }) => setFilters({ ...filters, recruiter: value })}
                    />
                </FlexItem>
            </FlexRow>


            {filters.recruiter &&
                <Segment basic loading={loading}>
                    {!loading &&
                        <BarGraph data={data} options={graphOptions} />
                    }
                </Segment>
            }

        </>
    );
};

export default PublishedByRecruiter;