import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import '@store/languages';
// components
import Icon from '@components/Icon';
import { Grid, Divider, Button, Container } from 'semantic-ui-react';
// component specific
import MonthlyCCStats from './MonthlyCCStats';

const CostCenterStatistic = ({ isDetail, filters, data, setVisible }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format) 
    
    return (
        <Container fluid>
            <Grid stackable>
                <Grid.Row style={{ padding: 0, marginBottom: "1rem" }} columns={2}>
                    <Grid.Column>
                        <Button.Group size="tiny" style={{ marginLeft: "1.5rem" }}>
                            <Button primary>{t('type')}</Button>
                            <Button disabled basic>{t('category')}</Button>
                            <Button disabled basic>{t('assigned_to')}</Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon name="funnel-outline" style={{ marginRight: "1rem", cursor: "pointer" }} onClick={() => setVisible(true)}/>
                        {/* <Icon name="download-outline" style={{ marginRight: "1rem", cursor: "not-allowed" }}/>  */}
                        <Button size="tiny" simple style={{ cursor: "default" }}>
                            <Icon name="calendar-outline" style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                <strong>
                                    <span style={{ textTransform: "capitalize" }}>{ moment(filters.date_from).format(dateFormat) }</span>
                                    { " - " }
                                    <span style={{ textTransform: "capitalize" }}>{ moment(filters.date_to).format(dateFormat) }</span>
                                    { " " }
                                    - {filters.currency || "EUR"}
                                </strong>
                            </span>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <MonthlyCCStats isDetail={isDetail} filters={filters} listData={data}/>
            <Divider/>
        </Container>
    );
};

export default CostCenterStatistic;