import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { useHasPermissions } from '@helpers/hooks';
// components
import { Form } from 'semantic-ui-react';

const ColorPickerField = ({ setEditableInput, cube, label, color, setColor, onValueSet, ...rest }) => {
    const [visible, setVisible] = useState(false)
    const isManageable = useHasPermissions(['benefifsfsts.c_manage_all_benefits']);

    // const isHexColor = (s) => {
    //     var regExp = /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/;
    //     return (typeof s === 'string' && regExp.test(s));
    // }
    return (
        <Form.Field>
            <Form.Input
                {...rest}
                style={{ display: cube && "none" , border: "2px solid " + color }}
                label={label}
                value={color}
                icon="theme"
                iconPosition="right"
                onClick={(e) => setVisible(true)}
            />

            {cube &&
                <span
                    onClick={(e) => {
                        setVisible(isManageable)
                        setEditableInput()
                    }}
                    style={{ 
                        cursor: "pointer",
                        padding:"0.8rem",
                        position: "relative", 
                        top: "1.5rem", 
                        marginRight: "1rem", 
                        backgroundColor: color
                    }}
                > 
                </span>}
            {visible &&
                <div style={{position: 'absolute', zIndex: '2', top:cube&&'44px', left: cube&&"22px"}} >
                    <div style={styles.cover} onClick={(e) => setVisible(false)} />
                    <SketchPicker
                        color={color}
                        onChange={(color, event) => setColor(color.hex)}
                    />
                </div>
            }
        </Form.Field>
    );
};

const styles = {
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
}

export default ColorPickerField;