import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { API } from "@store/config"
import { requests } from '@helpers/requests';
import { isEmpty } from "@services/helpers/validation"
// components
import { Form, Button, Checkbox, Message } from 'semantic-ui-react'
import ChoiceField from '@components/forms/common/ChoiceField'


function LanguageForm({ language, onConfirm, onClose }) {
    const { t } = useTranslation();

    const [data, setData] = useState({
        id: language ? language.id : 0,
        language_name: language ? language.language_name?.id : '',
        level: language ? language.level : '',
        isPrimary: language ? language.is_primary : false,
        isNative: language ? language.is_native : false
    })

    const [errors, setErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.languages) {
            errorsArray.push(response.languages)
        }
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }
        setErrors(errorsArray);
    }

    const handleSuccess = (response) => {
        onConfirm(response);
        onClose()
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        let dataset = {
            language_name: data.language_name,
            level: data.level,
            is_primary: data.isPrimary,
            is_native: data.isNative
        }

        if (!data.id || data.id === 0) {
            const request = await requests.post(API.LANGUAGES, dataset);

            if (request.status === 201) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }

        } else {
            const request = await requests.patch(API.LANGUAGES + data.id + '/', dataset);

            if (request.status === 200) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }


    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />

            <Form.Field>
                <ChoiceField
                    required
                    search
                    text="title"
                    endpoint={API.COMMON + "language_names/"}
                    label={t('language')}
                    placeholder={t('language')}
                    value={data.language_name}
                    onChange={(e, { value }) => setData({
                        ...data,
                        language_name: value
                    })}
                />

                <ChoiceField
                    required
                    type="language_level_choices"
                    label={t('level')}
                    placeholder={t('level')}
                    value={data.level ? data.level.toString() : data.level}
                    onChange={(e, { value }) => setData({
                        ...data,
                        level: value
                    })}
                />
                
                <Form.Group inline>
                    <Form.Field
                        control={Checkbox}
                        label={t('primary')}
                        checked={data.isPrimary}
                        onChange={() => setData({
                            ...data,
                            isPrimary: !data.isPrimary
                        })}
                    />

                    <Form.Field
                        control={Checkbox}
                        label={t('native')}
                        checked={data.isNative}
                        onChange={() => setData({
                            ...data,
                            isNative: !data.isNative
                        })}
                    />
                </Form.Group>

            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    basic
                    type="button"
                    onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    disabled={
                        isProcessing ||
                        isEmpty(data.name) ||
                        isEmpty(data.level)
                    }
                >   {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default LanguageForm