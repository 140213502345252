import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config'
import { patchRequest } from '@services/ServiceCommon';
// components
import { Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ResponsiblePersonAssignForm = ({ unit, setUnit, onClose }) => {
    const { t } = useTranslation()
    const [form, setForm] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)
        const request = await patchRequest(API.UNITS + unit.id + '/', {
                responsible_persons: {
                    add: form
                }
            });
            if (request.status === 200) {
                setUnit(prevState => ({
                    ...prevState,
                    responsible_persons: request.response.responsible_persons
                }))
                onClose()
        setIsProcessing(false)
    }}

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                as="choice"
                fluid
                search
                required
                multiple
                exclude={unit.responsible_persons.map(item => item.id)}
                text="fullname"
                label={t('responsible_persons')}
                value={form}
                onChange={(e, { value }) => setForm(value)}
                endpoint={API.EMPLOYEES + "?query={id, fullname}&only_basic_info=true"}
            />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>

    );
};

export default ResponsiblePersonAssignForm;