import React from 'react';
import { useTranslation } from "react-i18next"
// store
import { isEmptyErrorList } from '@helpers/validation';
// components
import { Button, Divider, Grid, Header, Icon } from 'semantic-ui-react';
import AddressFieldGroup from '@components/forms/common/AddressFieldGroup';

const AddressesView = ({
    visible, setView,
    temporaryAddress, setTemporaryAddress,
    permanentAddress, setPermanentAddress
}) => {
    const { t } = useTranslation();
    return (
        <div>
            {visible &&
                <>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as="h3" textAlign="center">
                                    {t("pernament")} <small style={{ color: "var(--danger)" }}>*</small>

                                </Header>
                                <AddressFieldGroup address={permanentAddress} setAddress={setPermanentAddress} />
                                <Button size="tiny" basic type="button" onClick={() => setTemporaryAddress(permanentAddress)} fluid>
                                    {t("btn_same_temp")}
                                </Button>
                            </Grid.Column>

                            <Grid.Column>
                                <Header as="h3" textAlign="center">
                                    {t("temp")}
                                </Header>
                                <AddressFieldGroup address={temporaryAddress} setAddress={setTemporaryAddress} isDisabled={!isEmptyErrorList([
                                    permanentAddress.street,
                                    permanentAddress.city,
                                    permanentAddress.country,
                                    permanentAddress.postcode,
                                ])} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />

                    <Button.Group floated="right">
                        <Button
                            type="button"
                            style={{ border: "1px solid var(--light-grey)" }}
                            onClick={() => setView(2)}>
                            <Icon name="arrow left" /> {t("back")}
                        </Button>
                        <Button.Group primary>
                            <Button
                                onClick={() => setView(4)}
                                type="button"
                                disabled={!isEmptyErrorList([
                                    permanentAddress.street,
                                    permanentAddress.city,
                                    permanentAddress.country,
                                    permanentAddress.postcode,
                                ])}>
                                {t("continue")}
                            </Button>
                        </Button.Group>
                    </Button.Group>
                </>
            }
        </div>
    );
};

export default AddressesView;