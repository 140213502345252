import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { tzDateTime } from '@helpers/dates'
// components
import { Form, Divider, Table } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const calculateDiffFromPlanAndReality = (plan, reality) => {
    let hoursDiff = null

    if (plan === undefined) return hoursDiff
    if (reality === undefined) return hoursDiff
    if (parseFloat(reality.duration) <= parseFloat(plan.duration)) return hoursDiff

    return parseFloat(parseFloat(reality.duration) - parseFloat(plan.duration)).toFixed(2)
}

// eslint-disable-next-line
const determinateBankType = (day) => {
    if (day.holiday !== null) return 'bank_holiday'
    if (day.isSunday) return 'bank_sunday'
    if (day.isSaturday) return 'bank_saturday'

    return 'bank_overtime'
}

// eslint-disable-next-line
const calculateBreakAddition = (record) => {
    const realDuration = record.duration_real
    const originalDuration = record.duration
    let breakAddition = parseFloat(parseFloat(realDuration || 0) - parseFloat(originalDuration || 0)) * 60

    return breakAddition
}

const TransferHoursForm = ({ day, contract, onClose, record, planRecord, setContracts, setAttendanceRecords }) => {
    const { t } = useTranslation()

    const hourDiff = calculateDiffFromPlanAndReality(planRecord, record)
    const [processing, setProcessing] = useState(false)
    const [breakPreferences, setBreakPreferences] = useState({
        firstBreak: 4,
        secondBreak: 8,
    })
    const [mode, setMode] = useState(1)
    const [form, setForm] = useState({
        hours_overtime: hourDiff !== null ? hourDiff : record.duration,
        // hours_overtime: '',
        hours_saturday: '',
        hours_sunday: '',
        hours_holiday: '',
        hours_night: parseFloat(record?.night_work_hours) > 0 ? parseFloat(record.night_work_hours) : '',
    })

    useEffect(() => {
        async function fetchPreferences() {
            const request = await requests.get(API.PREFERENCES + '?section=attendance_preferences')
            if (request.status === 200) {
                const preferences = request.response
                const firstBreakPref = preferences.find((item) => item.name === 'global_first_break_requirement')
                const secondBreakPref = preferences.find((item) => item.name === 'global_second_break_requirement')
                setBreakPreferences({
                    firstBreak: firstBreakPref !== undefined ? firstBreakPref.value : 4,
                    secondBreak: secondBreakPref !== undefined ? secondBreakPref.value : 8,
                })
            }
        }

        fetchPreferences()
    }, [])

    const calculateHourDiff = () => {
        const diff = parseFloat(parseFloat(record.duration) - parseFloat(form.hours_overtime)).toFixed(2)

        if (!isNaN(diff)) return parseFloat(diff)

        return 0
    }

    const handleSubmit = async () => {
        setProcessing(true)

        let start = mode === 1 ? record.start : createdStartTime()
        let end = mode === 1 ? createdEndTime() : record.end

        const hoursDiff = calculateHourDiff()
        let request = null
        if (!record.is_corrected && record.original_record?.id === undefined) {
            if (hoursDiff <= 0) {
                // 100% transfer to bank and its original records then mark as "is_resolved"
                request = await requests.patch(
                    API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true',
                    {
                        is_resolved: true,
                    }
                )
            } else {
                request = await requests.post(API.ATTENDANCE_BASE + 'records/manual/', {
                    date: record.date,
                    start: hoursDiff > 0 ? start : null,
                    end: hoursDiff > 0 ? end : null,
                    // duration: hoursDiff === 0 ? 0 : duration,
                    contract: record.contract,
                    employee: record.employee,
                    closed: true,
                    is_corrected: true,
                })
            }
        } else {
            let data = {
                start: hoursDiff > 0 ? start : null,
                end: hoursDiff > 0 ? end : null,
            }

            if (hoursDiff === 0) data['duration'] = 0
            if (hoursDiff === 0) data['absence_type'] = null
            request = await requests.patch(
                API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true',
                data
            )
        }

        if (request.status === 201 || request.status === 200) {
            let requestUpdate = null
            if (request.status === 200) {
                requestUpdate = {
                    status: 200,
                    response: request.response,
                }
            } else {
                requestUpdate = await requests.patch(
                    API.ATTENDANCE_BASE + 'records/' + record.id + '/?is_manager_edit=true',
                    {
                        correction: request.response.id,
                    }
                )
            }

            if (requestUpdate.status === 200) {
                // update contract bank + create history
                let latestContractVersion = null
                if (parseFloat(form.hours_overtime) > 0) {
                    const createHistoryOvertimeRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 1, // overtime
                        operation: 1, // increase
                        attendance_record: record.id,
                        amount: parseFloat(form.hours_overtime),
                        contract: record.contract,
                    })

                    if (createHistoryOvertimeRequest.status === 201) {
                        latestContractVersion = createHistoryOvertimeRequest.response.contract
                    }
                }

                if (parseFloat(form.hours_holiday) > 0) {
                    const createHistoryHolidayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 2, // holiday
                        operation: 1, // increase
                        attendance_record: record.id,
                        amount: parseFloat(form.hours_holiday),
                        contract: record.contract,
                    })

                    if (createHistoryHolidayRequest.status === 201) {
                        latestContractVersion = createHistoryHolidayRequest.response.contract
                    }
                }

                if (parseFloat(form.hours_saturday) > 0) {
                    const createHistorySaturdayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 3, // saturday
                        operation: 1, // increase
                        attendance_record: record.id,
                        amount: parseFloat(form.hours_saturday),
                        contract: record.contract,
                    })

                    if (createHistorySaturdayRequest.status === 201) {
                        latestContractVersion = createHistorySaturdayRequest.response.contract
                    }
                }

                if (parseFloat(form.hours_sunday) > 0) {
                    const createHistorySundayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 4, // sunday
                        operation: 1, // increase
                        attendance_record: record.id,
                        amount: parseFloat(form.hours_sunday),
                        contract: record.contract,
                    })

                    if (createHistorySundayRequest.status === 201) {
                        latestContractVersion = createHistorySundayRequest.response.contract
                    }
                }

                if (parseFloat(form.hours_night) > 0) {
                    const createHistorySundayRequest = await requests.post(API.CONTRACTS + 'bank_history/', {
                        type: 5, // night
                        operation: 1, // increase
                        attendance_record: record.id,
                        amount: parseFloat(form.hours_night),
                        contract: record.contract,
                    })

                    if (createHistorySundayRequest.status === 201) {
                        latestContractVersion = createHistorySundayRequest.response.contract
                    }
                }

                // fetch corrected record
                const requestCorrectedRecord = await requests.get(
                    API.ATTENDANCE_BASE + 'records/' + request.response.id + '/'
                )
                if (requestCorrectedRecord.status === 200) {
                    // update contract banks
                    if (latestContractVersion !== null) {
                        setContracts((prev) =>
                            prev.filter((item) => {
                                if (item.id === latestContractVersion.id) {
                                    item['bank_hours'] = latestContractVersion.bank_hours
                                    item['bank_overtime'] = latestContractVersion.bank_overtime
                                    item['bank_holiday'] = latestContractVersion.bank_holiday
                                    item['bank_saturday'] = latestContractVersion.bank_saturday
                                    item['bank_sunday'] = latestContractVersion.bank_sunday
                                    item['bank_night'] = latestContractVersion.bank_night
                                }
                                return item
                            })
                        )
                    }

                    // update attendancerecords listing to include corrected record
                    if (requestCorrectedRecord.response.is_resolved === true) {
                        setAttendanceRecords((prev) =>
                            prev.filter((item) => item.id !== requestCorrectedRecord.response.id)
                        )
                    } else {
                        // all done, now check if its correction and it is correction and has 0 values then remove
                        if (
                            requestCorrectedRecord.response.is_corrected === true &&
                            [0, '0', '0.00', null, undefined].includes(requestCorrectedRecord?.response?.work_hours) &&
                            requestCorrectedRecord?.response?.absence_type === null
                        ) {
                            const removeRecord = await requests.post(
                                API.ATTENDANCE_BASE + 'records/' + request.response.id + '/delete/'
                            )
                            if ([200, 201].includes(removeRecord.status)) {
                                setAttendanceRecords((prev) =>
                                    prev.filter((item) => item.id !== requestCorrectedRecord.response.id)
                                )
                            }
                        } else {
                            setAttendanceRecords((prev) =>
                                prev.map((item) => {
                                    if (item.id === record.id) {
                                        item = {
                                            id: requestCorrectedRecord.response.id,
                                            employee: requestCorrectedRecord.response.employee?.id,
                                            contract: requestCorrectedRecord.response.contract?.id,
                                            date: requestCorrectedRecord.response.date,
                                            start: requestCorrectedRecord.response.start,
                                            end: requestCorrectedRecord.response.end,
                                            night_work_hours: requestCorrectedRecord.response?.night_work_hours || 0,
                                            duration: requestCorrectedRecord.response?.work_hours || 0,
                                            duration_real: requestCorrectedRecord.response?.duration || 0,
                                            closed: requestCorrectedRecord.response.closed,
                                            vacation: requestCorrectedRecord.response.vacation,
                                            vacation_hours: requestCorrectedRecord.response.vacation_hours,
                                            paragraph: requestCorrectedRecord.response.paragraph,
                                            sick_day: requestCorrectedRecord.response.sick_day,
                                            absention: requestCorrectedRecord.response.absention,
                                            care_for_a_family_member:
                                                requestCorrectedRecord.response.care_for_a_family_member,
                                            family_member_paragraph:
                                                requestCorrectedRecord.response.family_member_paragraph,
                                            absence_type: requestCorrectedRecord.response.absence_type,
                                            original_record: requestCorrectedRecord.response.original_record,
                                            correction: requestCorrectedRecord.response.correction,
                                            is_corrected: requestCorrectedRecord.response.is_corrected,
                                            is_saturday: requestCorrectedRecord.response.is_saturday,
                                            is_sunday: requestCorrectedRecord.response.is_sunday,
                                            is_holiday: requestCorrectedRecord.response.is_holiday,
                                            week: moment(requestCorrectedRecord.response.date).isoWeek(),
                                        }
                                    }
                                    return item
                                })
                            )
                        }
                    }

                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('hours_were_moved_to_attendance_bank'),
                        animation: 'pulse',
                        time: 2000,
                    })
                    onClose()
                }
            } else {
                await requests.del(API.ATTENDANCE_BASE + 'records/' + request.response.id + '/')
            }
        }

        setProcessing(false)
    }

    const isHourValid = (hours) => {
        if (isNaN(hours)) return false
        if (parseFloat(hours) <= 0) return false
        if (parseFloat(hours) > record.duration) return false
        return true
    }

    const createdEndTime = () => {
        let end = null
        const hourDiff = parseFloat(calculateHourDiff() || 0)
        const minDiff = hourDiff * 60
        const start = record.start ? moment(record.start, 'YYYY-MM-DD HH:mm:ss') : null

        let breakAddition = 0
        if (hourDiff >= parseFloat(breakPreferences.firstBreak)) {
            breakAddition += 30 // 30 min. break addition
        }

        if (parseFloat(breakPreferences.secondBreak) !== 0 && hourDiff >= parseFloat(breakPreferences.secondBreak)) {
            breakAddition += 15 // 15 min. second addition
        }

        if (parseFloat(minDiff) > 0) {
            end = start.add(parseFloat(minDiff + breakAddition), 'minutes')
        }

        if (parseFloat(minDiff) === 0) {
            end = start
        }

        return end ? end.format('YYYY-MM-DD HH:mm') : null
    }

    const createdStartTime = () => {
        let start = null
        const hourDiff = parseFloat(calculateHourDiff() || 0)
        const minDiff = hourDiff * 60
        const end = record.end ? moment(record.end, 'YYYY-MM-DD HH:mm') : null

        let breakAddition = 0
        if (hourDiff >= parseFloat(breakPreferences.firstBreak)) {
            breakAddition += 30 // 30 min. break addition
        }

        if (parseFloat(breakPreferences.secondBreak) !== 0 && hourDiff >= parseFloat(breakPreferences.secondBreak)) {
            breakAddition += 15 // 15 min. second addition
        }

        if (parseFloat(minDiff) > 0) {
            start = end.subtract(parseFloat(minDiff + breakAddition), 'minutes')
        }

        if (parseFloat(minDiff) === 0) {
            start = end
        }

        return start ? start.format('YYYY-MM-DD HH:mm') : null
    }

    const isFormValid = () => {
        if (parseFloat(record?.duration) <= 0) return false
        if (!isHourValid(form.hours_overtime)) return false
        if (!isHourValid(form.hours_saturday)) return false
        if (!isHourValid(form.hours_sunday)) return false
        if (!isHourValid(form.hours_holiday)) return false
        if (!isHourValid(form.hours_night)) return false

        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Field style={{ textAlign: 'left', display: 'flex' }}>
                <div>
                    {t('working_hours')}:{' '}
                    <strong>
                        {record?.duration || 0} {t('hours_shortcut').toLocaleLowerCase()}.
                    </strong>
                </div>
                {parseFloat(record?.night_work_hours) > 0 && (
                    <div style={{ marginLeft: '1rem' }}>
                        {t('night_hours')}:{' '}
                        <strong>
                            {record?.night_work_hours || 0} {t('hours_shortcut').toLocaleLowerCase()}.
                        </strong>
                    </div>
                )}
            </Form.Field>
            <Table size="small">
                <Table.Row disabled={parseFloat(record?.duration) <= 0}>
                    <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_overtime')}</Table.Cell>
                    <Table.Cell width="2">
                        <SuperField
                            as="input"
                            disabled={parseFloat(record?.duration) <= 0}
                            placeholder={t('enter_hours')}
                            value={form.hours_overtime}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({ ...prev, hours_overtime: value.replace(',', '.') }))
                            }
                            error={!isHourValid(form.hours_overtime) ? t('invalid_entry') : false}
                        />
                    </Table.Cell>
                </Table.Row>
                {day.isSaturday && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_saturday')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                placeholder={t('enter_hours')}
                                value={form.hours_saturday}
                                disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({ ...prev, hours_saturday: value.replace(',', '.') }))
                                }
                                error={!isHourValid(form.hours_saturday) ? t('invalid_entry') : false}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
                {day.isSunday && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_sunday')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                placeholder={t('enter_hours')}
                                value={form.hours_sunday}
                                disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({ ...prev, hours_sunday: value.replace(',', '.') }))
                                }
                                error={!isHourValid(form.hours_sunday) ? t('invalid_entry') : false}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
                {day.holiday !== null && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_holiday')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                placeholder={t('enter_hours')}
                                value={form.hours_holiday}
                                disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({ ...prev, hours_holiday: value.replace(',', '.') }))
                                }
                                error={!isHourValid(form.hours_holiday) ? t('invalid_entry') : false}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}

                {record?.night_work_hours > 0 && (
                    <Table.Row>
                        <Table.Cell style={{ fontWeight: 'bold' }}>{t('bank_night')}</Table.Cell>
                        <Table.Cell width="2">
                            <SuperField
                                as="input"
                                placeholder={t('enter_hours')}
                                value={form.hours_night}
                                disabled={parseFloat(record?.duration) <= 0}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({ ...prev, hours_night: value.replace(',', '.') }))
                                }
                                error={!isHourValid(form.hours_night) ? t('invalid_entry') : false}
                            />
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table>

            <Form.Group>
                <SuperField
                    as="checkbox"
                    label={t('adjust_departure')}
                    checked={mode === 1}
                    onChange={() => setMode(1)}
                    disabled={parseFloat(form.hours_overtime) === parseFloat(record.duration)}
                />
                <SuperField
                    as="checkbox"
                    label={t('adjust_arrival')}
                    checked={mode === 2}
                    onChange={() => setMode(2)}
                    disabled={parseFloat(form.hours_overtime) === parseFloat(record.duration)}
                />
            </Form.Group>

            <p>
                <strong>{t('corrected_record')}:</strong>
                <br />
                {calculateHourDiff() > 0 && (
                    <>
                        {t('arrival')}:{' '}
                        {mode === 1 ? (
                            <strong>{record.start ? tzDateTime(record.start).format('HH:mm') : '--:--'}</strong>
                        ) : (
                            <strong>
                                {createdStartTime() !== null ? tzDateTime(createdStartTime()).format('HH:mm') : '--:--'}
                            </strong>
                        )}
                        <br />
                        {t('departure')}:{' '}
                        {mode === 1 ? (
                            <strong>
                                {createdEndTime() !== null ? tzDateTime(createdEndTime()).format('HH:mm') : '--:--'}
                            </strong>
                        ) : (
                            <strong>{record.end ? tzDateTime(record.end).format('HH:mm') : '--:--'}</strong>
                        )}
                        <br />
                    </>
                )}
                {t('working_hours')}:{' '}
                <strong>
                    {calculateHourDiff()} {t('hours_shortcut')}
                </strong>
            </p>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={processing} disabled={processing || !isFormValid()} />
            </Form.Field>
        </Form>
    )
}

export default TransferHoursForm
