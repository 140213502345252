import React from 'react';
import { Link } from 'react-router-dom';
// components 
import Icon from '@components/Icon';
import { Grid, Segment, Header } from 'semantic-ui-react';

const ReportCard = ({ linkTo, name, category, isActive }) => {
    return (
        <Grid.Column computer={4} tablet={8} mobile={16} style={{ paddingBottom: "1rem" }}>
            <Link to={linkTo}>
                <Segment className="segment-hover" style={{ textAlign: "center", cursor: isActive ? "cursor" : "not-allowed", opacity: isActive ? "1" : ".5"}}>
                    <Icon name="pie-chart-outline" style={{ fontSize: "2.5rem", color: "var(--primary)" }}/>
                    <Header as="h3" content={name} style={{ marginTop: "1rem", marginBottom: "0.5rem" }}/>
                    <span>{category}</span>
                </Segment>
            </Link>
        </Grid.Column>
    );
};

export default ReportCard;