import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { setDotSeparator, isValidNumber } from '@helpers/functions';
// components
import { Form, Divider, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';

const WarehousePositionForm = ({ onClose, warehouse, setPositions, setForm }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        title: "",
        section: "",
        row: "",
        quantity_limit: "",
        warehouse: warehouse?.id,
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await requests.post(API.ASSETS_WAREHOUSE_POSITIONS, {
            title: formData.title,
            section: formData.section,
            row: formData.row,
            warehouse: formData.warehouse || null,
            quantity_limit: formData.quantity_limit || null,
        })

        if(request.status === 201){
            const response = request.response
            setPositions(prev => [...prev, {
                id: response.id,
                title: response.title,
                section: response.section,
                row: response.row,
                warehouse: response.warehouse,
                quantity_limit: response.quantity_limit,
                is_partially_occupied: false,
                occupied_amount: null,
                occupied_item: null
            }])
            setForm(prev => ({
                ...prev,
                warehouse_position: response.id
            }))
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form>
            <SuperField as="input"
                required
                autoFocus
                label={t('title')}
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('section')}
                    value={formData.section}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        section: value
                    }) }
                />
                <SuperField as="input"
                    label={t('row')}
                    value={formData.row}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        row: value
                    }) }
                />
            </Form.Group>

            <SuperField as="input"
                label={t('max_storage_capacity')}
                help={t('leave_it_blank_for_unlimited')}
                value={formData.quantity_limit}
                error={!isValidNumber(formData.quantity_limit, true) ? t('not_a_number') : false}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    quantity_limit: setDotSeparator(value)
                })}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <Button
                    primary
                    type="button"
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === "" || !isValidNumber(formData.quantity_limit, true)}
                    onClick={() => handleSubmit()}
                    content={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default WarehousePositionForm