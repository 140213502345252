// react
import React, { Fragment } from "react";
// router
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../../store/routes/index";

// styles
import { Button, Header, Icon } from "semantic-ui-react";

// component
function ConfirmSuccessMessage() {
    // template
    return (
        <Fragment>
            <Icon name="checkmark" size="huge" color="primary" circular />
            <Header
                as="h3"
                style={{
                    marginTop: "0.5em",
                }}
                content="Your password change operation was done."
            />
            <Header as="h4" content="Sign in with new password." />

            <Link to={routes.LOGIN}>
                <Button fluid color="primary" size="big">
                    <Icon name="sign in"/> Sign in
                </Button>
            </Link>
        </Fragment>
    );
}

export default ConfirmSuccessMessage;
