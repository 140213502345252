import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { addJournal } from '@store/actions';
import { isEmpty } from '@helpers/validation';
import { createRequest } from '@services/ServiceCommon';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const AccountingJournalForm = ({ onClose, connection, setRefetch }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        name: "",
        id_number: ""
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await createRequest(API.INVOICES + "invoice_accounts/?connection=" + connection.connection_id, form)
        
        if( request.status === 201 ){
            dispatch(addJournal(request.response))
            history.push(routes.INVOICES)
            setRefetch(true)
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                required
                label={ t('name')}
                value={form.name}
                onChange={(e, { value }) => setForm({ ...form, name: value })}
            />

            <SuperField as="input"
                required
                label={ t('id_number')}
                value={form.id_number}
                onChange={(e, { value }) => setForm({ ...form, id_number: value })}
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={isProcessing || isEmpty(form.name) || isEmpty(form.id_number)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default AccountingJournalForm;