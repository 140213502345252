import React from 'react';
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import DocumentTable from './DocumentTable';

const DocumentTemplates = () => {
    const [result, setResult] = useFetchData(API.TEMPLATING);

    const onCreate = async (new_item) => {
        setResult({
            ...result,
            data: [...result.data, new_item],
        });
    };

    const onDelete = async (id) => {
        const request = await requests.del(API.TEMPLATING + id + '/');
        if (request.status === 204) {
            const newList = result.data.filter((item) => item.id !== id);
            setResult({
                ...result,
                data: newList,
            });
        }
    };

    const onUpdate = (updateItem) => {
        setResult({
            ...result,
            data: result.data.filter((item) => {
                if (item.id === updateItem.id) {
                    item.title = updateItem.title;
                    item.content = updateItem.content;
                    item.source = updateItem.source;
                    item.category = updateItem.category;
                }
                return item;
            }),
        });
    };

    return (
        <>
            <DocumentTable
                result={result}
                setResult={setResult}
                onDelete={onDelete}
                onUpdate={(item) => onUpdate(item)}
                onCreate={(item) => onCreate(item)}
            />
        </>
    );
};

export default DocumentTemplates;
