import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { isEmpty, isPhoneNumberValid } from '@services/helpers/validation';
// components
import SuperField from '../SuperField';
import { API } from '@store/config';

const PhoneField = ({ value, setValue, phoneType, setPhoneType, hideType, ...rest }) => {
    const { t } = useTranslation();

    return (
        <SuperField as="input"
            {...rest}
            help={ hideType ? undefined : t("hint_text") }
            label={rest.label === null ? null : t('phone')}
            value={value}
            onChange={setValue}
            error={!isPhoneNumberValid(value) && !isEmpty(value) ? t('phone_validation_error') : false}
            action={hideType ? null :
                <SuperField as="choice"
                    button
                    basic
                    floating
                    endpoint={API.PHONE_TYPES}
                    text="name"
                    value={phoneType}
                    onChange={setPhoneType}
                />
            }
            style={{
                marginBottom: "0.5rem"
            }}
        />
    );
};

export default PhoneField;