import React from "react";
import { useTranslation } from 'react-i18next';
//components
import { Button, Grid, Icon } from "semantic-ui-react";
import ConfrimModal from '@components/modals/ConfrimModal';
import DocPreview from '@components/DocPreview';
import SuperDuperModal from '@components/modals/SuperDuperModal'

const Attachment = ({ docs, docName, docFile, onDelete }) => {
    const { t } = useTranslation()

    return (
        <Grid>
            <Grid.Row style={{ padding: 0 }}>
                {onDelete &&
                    <ConfrimModal
                        description={t('delete_attachments')}
                        onConfirm={() => onDelete(docs.id)}
                        button={
                            <Button style={{ padding: 0, backgroundColor: "transparent" }}>
                                <Icon name="close" color="red" />
                            </Button>
                        }
                    />
                }
                <SuperDuperModal
                    size="large"
                    trigger={
                        <Button icon labelPosition='left' style={{ backgroundColor: 'white' }}  >
                            <Icon name='file outline' style={{ backgroundColor: 'white', color: 'var(--primary)' }} size='large' />
                            {docName || docs.name}
                        </Button>
                        // <span className="ref-link" style={{ color: "var(--primary)", fontWeight: "bold", marginLeft: "0.5rem"  }}>
                        //     { doc.name }
                        // </span>
                    }
                    content={
                        <DocPreview uri={docFile || docs.file} />
                    }
                />

            </Grid.Row>
        </Grid>
    );
};


export default Attachment;