import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
// components
import { Table, Divider, Header } from 'semantic-ui-react';

const ServiceFulFillmentRecords = ({ record, onClose }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    return (
        <>
            <Header as="h3" content={t("included_activities")}/>
            <Divider/>
            <Table striped selectable unstackable className="table">
                <Table.Header>
                    <Table.Row verticalAlign="middle">
                        <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('hours')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('total')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { record.timesheet_records.length > 0 
                    ?
                        <>
                            { record.timesheet_records.map(item => (
                                <Table.Row verticalAlign="middle" key={item.id}>
                                    <Table.Cell width="2" style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }} >{ moment(item.date).format(dateFormat) }</Table.Cell>
                                    <Table.Cell style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }} >
                                        <strong>{ item.category.title }</strong>
                                        { item.activity !== "" && 
                                            <>
                                                <br/>
                                                <small> { item.activity } </small>
                                            </> 
                                        }
                                    </Table.Cell>
                                    <Table.Cell width="3" style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem" }} >{ item.hours }</Table.Cell>
                                    <Table.Cell width="3" style={{ paddingTop: "1.3rem", paddingBottom: "1.3rem", fontWeight: "bold" }}> 
                                        { item.total_fee }
                                    </Table.Cell>
                                </Table.Row>
                            )) }
                        </>
                    : 
                    <Table.Row verticalAlign="middle">
                        <Table.Cell colspan={5} textAlign="center" style={{ fontWeight: 'bold' }}>
                            {t('no_data')}
                        </Table.Cell>
                    </Table.Row>
                    }
                    <Table.Row>
                        <Table.Cell colspan={2}><strong>{t("total")}</strong></Table.Cell>
                        <Table.Cell><strong>{record.total_hours}</strong></Table.Cell>
                        <Table.Cell><strong>{record.total_fee}</strong></Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </>
    );
};

export default ServiceFulFillmentRecords;