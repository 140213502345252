import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import SmartDataTable from 'react-smart-data-table';
import { Container, Pagination, Search, Grid, Header, Form } from 'semantic-ui-react';
import Loading from '../general/Loading';
// others
import 'react-smart-data-table/dist/react-smart-data-table.css'

const DataTable = (props) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const [perPage, setPerPage] = useState(10);

    const perPageOptions = [
        { key: '10', value: '10', text: '10 ' + t('per_page') },
        { key: '25', value: '25', text: '25 ' + t('per_page') },
        { key: '50', value: '50', text: '50 ' + t('per_page') },
        { key: '100', value: '100', text: '100 ' + t('per_page') },
        { key: 'all', value: props.data.length.toString(), text: t('all_records') },
    ]

    const onSearch = (e, data) => {
        setQuery(data.value);
    }

    const setDataPerPage = (e, value) => {
        setPerPage(parseInt(value));
    }

    const CustomPagination = ({ activePage, totalPages, onPageChange}) => (
        <Grid>
            <Grid.Column computer={8} table={8} mobile={16} style={{ padding: "0" }}>
                <Pagination defaultActivePage={activePage} totalPages={totalPages} onPageChange={onPageChange} />
            </Grid.Column>

            <Grid.Column computer={8} table={8} mobile={16} style={{ paddingRight: "1rem", textAlign: "right" }}>
                <Header as="h4">{t('total_records')}: {props.data.length}</Header>
            </Grid.Column>
        </Grid>
    );


    return (
        <Container fluid>
            { props.data.length ?
            <Grid style={props.hideFilters ? { display: "none" } : { marginTop: "1rem", marginBottom: "1rem" }}>
                <Grid.Column computer={8} table={8} mobile={16} style={{ padding: "0", marginBottom: "0.2rem" }}>
                    <Form.Select
                        onChange={(e, data) => setDataPerPage(e, data.value)}
                        defaultValue={perPage.toString()}
                        options={perPageOptions} />
                </Grid.Column>
                <Grid.Column computer={4} table={4} mobile={16} style={{ padding: "0" }}/>
                <Grid.Column style={{ padding: "0" }} computer={4} table={4} mobile={16}>
                    <Search style={{ marginBottom: "0.2rem" }} className="table-search" fluid placeholder={t('search')} onSearchChange={onSearch} value={query} open={false}/>
                </Grid.Column>
            </Grid>
            : '' }
            <SmartDataTable
                data={props.data}
                dataKey=''
                headers={props.headers}
                orderedHeaders={props.orderedHeaders}
                //hideUnordered={props.hideUnordered}
                name='table'
                className="ui striped selectable unstackable table"
                filterValue={query}
                perPage={perPage}
                sortable
                //withToggles
                withLinks
                withHeader
                loader={<Loading/>}
                parseBool={{
                    yesWord: t('yes'),
                    noWord: t('no'),
                }}
                dynamic
                paginator={CustomPagination}
                emptyTable={props.onEmpty ? props.onEmpty : (
                    <div className="ui message" style={{ textAlign: "center" }}>
                        {t('no_data')}
                    </div>
                )}
            />

        </Container>
    );
};

export default DataTable;