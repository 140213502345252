import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { Form, Header, Divider } from 'semantic-ui-react'
import ChoiceField from '@components/forms/common/ChoiceField'
import { 
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    // BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg';

const BaseInformation = ({ jobOffer, setJobOffer, setJobsValues }) => {
    const { t } = useTranslation();

    const [positions, setPositions] = useState([])
    const [choices, setChoices] = useState([])

    useEffect(() => {
        async function fetchPositions() {
            const result = await getRequest(API.JOB_POSITIONS)

            if (result.status === 200) {
                setPositions(result.response)

                const choiceList = []
                result.response.forEach(item => {
                    choiceList.push({
                        id: item.id, text: item.title, value: item.id
                    })
                })

                setChoices(choiceList)
            }
        }

        fetchPositions()
    }, [])

    return (
        <>
            <Header style={{ paddingBottom: "2rem", paddingTop: "0rem" }} as="h2" content={"1. " + t('base_information')} />
            <Form.Group widths="equal">
                <ChoiceField
                    search
                    label={t('select_template')}
                    customOptions={choices}
                    value={jobOffer.job_position}
                    onChange={(e, { value }) => {
                        positions.filter(item => {
                            if (item.id === value) {
                                setJobsValues(item, value)
                            }

                            return item
                        })
                    }}
                />

                <ChoiceField
                    search
                    label={t('select_hiring_room')}
                    endpoint={API.HIRING_ROOM}
                    text="name"
                    value={jobOffer.recruitment}
                    onChange={(e, { value }) => setJobOffer({
                        ...jobOffer,
                        recruitment: value,
                    })
                    }
                />

                <ChoiceField
                    search
                    required
                    label={t('select_language')}
                    type="language_choices"
                    value={jobOffer.in_language}
                    onChange={(e, { value }) => setJobOffer({
                        ...jobOffer,
                        in_language: value,
                    })
                    }
                />
            </Form.Group>

            <Divider />

            <Form.Input
                required
                label={t('title')}
                placeholder={t('title')}
                value={jobOffer.title}
                onChange={(e) => setJobOffer({
                    ...jobOffer,
                    title: e.target.value
                })}
            />

            <Form.Field>
                <label style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{ t('job_description') }</label>
                <EditorProvider>
                    <Editor 
                        containerProps={{ style: { resize: 'vertical', width: '100%', background: "white", minHeight: "250px" } }}
                        value={jobOffer.description}
                        onChange={(e) => setJobOffer(prev => ({...prev, description: e.target.value}))}
                    >
                        <Toolbar>
                            <BtnUndo />
                            <BtnRedo />
                            <Separator />
                            <BtnBold />
                            {/* <BtnItalic /> */}
                            <BtnUnderline />
                            {/* <BtnStrikeThrough /> */}
                            <Separator />
                            <BtnNumberedList />
                            <BtnBulletList />
                            <Separator />
                            <BtnLink />
                            <Separator />
                            <BtnClearFormatting />
                            <HtmlButton />
                        </Toolbar>
                    </Editor>
                </EditorProvider>
                {/* <Form.TextArea
                    // autoFocus
                    rows={12}
                    label={t('job_description')}
                    placeholder={t('enter_job_description')}
                    value={jobOffer.description}
                    onChange={(e) => setJobOffer({
                        ...jobOffer,
                        description: e.target.value
                    })}
                /> */}
            </Form.Field>
        </>
    );
};

export default BaseInformation;