import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@components/Icon';
// import { Icon } from 'semantic-ui-react';

const HexagonLink = (props) => {
    return (
        <Link to={props.to}>
            <div style={styles.div}>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style={{ width: '8rem' }} >
                    <polygon fill={props.fill ? props.fill : "var(--primary)"} points="41.356,374.401 41.356,137.607 256,18.652 470.644,137.607 470.644,374.401 256,493.357 " />
                    <path fill={props.fill ? props.fill : "var(--primary)"} d="M256,37.303l198.333,109.919v217.555L256,474.697L57.667,364.778V147.222L256,37.303 M256,0
	                        L25.045,128v256L256,512l230.955-128V128L256,0z"/>
                </svg>
                <Icon className="hexagon-icon" style={{ fontSize: "4rem", position: "absolute", transform: 'rotate(-90deg) scale(0.8)', margin: 0 }} name={props.icon} />
            </div>
            <p className="hexagon-title" style={styles.p}>{props.name}</p>
        </Link >

    );
};

const styles = {
    svg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        transform: "scale(0.9)"
    },
    p: {
        textAlign: "center",
        fontSize: "1.2rem",
        width: "100%",
        fontWeight: "bold",
    },
    div: {
        textAlign: 'center',
        margin: '1rem 2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: "rotate(90deg)"
    }
}


export default HexagonLink;