import React, { useState, useEffect, useRef } from 'react';
// store
import { useLocation, useHistory } from 'react-router-dom';
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { useSelector } from 'react-redux';
import { useQueryPage } from '@helpers/hooks';
// components
import { Pie } from 'react-chartjs-2';
import { Segment, Grid, Ref, Sidebar, Menu, Container } from 'semantic-ui-react';
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';

import CallReportsHeader from './CallReportsHeader';
import CallReportsTable from './CallReportsTable';
import CallReportsFilterForm from './CallReportsFilterForm';


const CallReports = () => {
    const location = useLocation()
    const history = useHistory()
    let page = useQueryPage()
    const segmentRef = useRef()

    const [agentStatsData, setAgentStatsData] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const colors = useSelector(state => state.global_pref.colors)
    const availableColors = [colors.primary, colors.info, colors.success, colors.warning, colors.danger, colors.iceblue, colors.blue, colors.dark]

    const [limit, setLimit] = useState(10)
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState([])
    const [datas, setDatas] = useState([])

    const initState = {
        callType: "",
        callDirection: "",
        callStartTimeUtc: "",
        callEndTimeUtc: "",
        agent: "",
    }
    const [filters, setFilters] = useState(initState)

    const setParams = (params) => {
        params += "&search=" + search
        if( filters?.callType !== "") params += `&call_type=${filters?.callType}`
        if( filters?.callDirection !== "") params += `&call_direction=${filters?.callDirection}`
        if( filters?.callStartTimeUtc !== "") params += `&call_start_time_utc=${filters?.callStartTimeUtc}`
        if( filters?.callEndTimeUtc !== "") params += `&call_end_time_utc=${filters?.callEndTimeUtc}`

        if ( !params.includes('agent')){
            if( filters?.agent !== "") params += `&agent=${filters?.agent}`
        }

        return params
    }

    const fetchDatas = async (params, isFiltered) => {
        params = params || ""
        params = setParams(params)

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        setIsLoading(true)
        const request = await getRequest(API.CALL_JOURNALING + '?paginate=true' + params)

        if (request.status === 200) {
            setDatas({
                ...datas,
                data: request.response
            })
        }
        setIsLoading(false)

        }

    useEffect(() => {
        fetchDatas('', true)
        // eslint-disable-next-line
    }, [search])

    async function fetchPieDatas(){

        setIsLoading(true)
        const request = await getRequest(API.REPORTS + `3cx/agent_call_reports/stats/`)

        if (request.status === 200) {
            setAgentStatsData(request.response)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchPieDatas()
        // eslint-disable-next-line
    }, [])

    const pie_data = {
        labels: agentStatsData?.map(obj => (obj.firstname + " " + obj.lastname + "- " + obj.agent)),
        agents: agentStatsData?.map(obj => obj.agent),
        datasets: [
            {
                data: agentStatsData?.map(obj => (obj.total_count)),
                backgroundColor: agentStatsData?.map((obj, index) => availableColors[index])
            },
        ],
    };

    return (
        <CanView permissions={["reports.c_view_3cx_reports"]} redirect>
        <Ref innerRef={segmentRef} >
            <Container fluid >
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                <Sidebar
                    style={{ width: "40rem", }}
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    icon='labeled'
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    width='wide'
                    target={segmentRef}
                >
                <CallReportsFilterForm
                    setVisible={setVisible}
                    setDatas={setDatas}
                    fetchDatas={fetchDatas}
                    initState={initState}
                    filters={filters}
                    setFilters={setFilters}
                    search={search}
                />

                </Sidebar>
                <Sidebar.Pusher>
                    <CallReportsHeader />
                    <Grid>

                        <Grid.Row>
                            <Grid.Column style={{ padding: 0, marginTop: '70px' }} width={6}>
                                { datas?.data?.count > 0 &&
                                    <Pie

                                        onElementsClick={async (element) => {
                                            const agents = pie_data.agents
                                            const selected_agent = agents.find((agent, index) => index === element?.[0]?._index)
                                            if (selected_agent !== undefined && selected_agent !== null) {
                                                setFilters({
                                                    ...filters,
                                                    agent: selected_agent
                                                })
                                                history.replace({ pathname: location.pathname, search: `?page=${1}` });
                                                await fetchDatas("&agent=" + selected_agent, true)
                                            }

                                        }}
                                        options={{
                                            legend:{
                                                display: true,
                                                labels: {
                                                    fontSize: 12,
                                                },
                                                position:'left'
                                            }
                                        }}
                                        // options={...}
                                        data={pie_data}
                                    />
                                }
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }} width={10}>
                                <CallReportsTable datas={datas} isLoadingDatas={isLoading} setVisible={setVisible} visible={visible} search={search} setSearch={setSearch} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Paginator
                        forcePage={page || 1}
                        limit={limit}
                        setLimit={setLimit}
                        length={datas?.data?.count || 0}
                        onChange={(params) => fetchDatas(params)}
                    />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Container>
        </Ref>
    </CanView>
    );
};

export default CallReports;