import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import UserAvatar from 'react-user-avatar';
import Icon from '@components/Icon';
import { Button, Label, Popup } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';

const AvatarList = ({ persons, size, canManage, maxVisible, alignDirection, allowAdditions, addForm, onRemove }) => {
    const { t } = useTranslation();
    
    const length = persons.length

    maxVisible = maxVisible || 3
    alignDirection = alignDirection || "center"

    const determinateRightPush = (idx) => {
        if (idx === 1) return "10px"
        if (idx === 2) return "19px"
        return 0
    }

    const AvatarPopup = ({idx, person}) => {
        const [isHovered, setIsHovered] = useState(false);
        return(
            <Popup
                key={idx}
                size="tiny"
                flowing hoverable
                position="top center"
                trigger={
                        <span 
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)} 
                            key={idx} style={{ position: "relative", right: determinateRightPush(idx), zIndex: idx + 1, cursor: "pointer" }}
                        >
                            {isHovered && onRemove && canManage
                            ?
                            <Label circular onClick={() => onRemove(person.ID)} style={{ backgroundColor: "white", color: "red", paddingLeft: '0rem', fontSize: size / 2 + "px", borderRadius: "50%", border: "2px solid white" }} title={"x"}>
                                <Icon name="close"/>
                            </Label>
                            :
                            <UserAvatar
                                style={{ color: "white", paddingLeft: '0rem', fontSize: size / 2.5 + "px", borderRadius: "50%", border: "2px solid white" }}
                                size={size}
                                name={person?.name || idx.toString()}
                                src={person?.avatar}
                            />
                            }
                        </span>
                }
                content={person?.displayName || idx.toString()}
            />
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "left center", justifyContent: alignDirection, marginRight: length > maxVisible ? "0.5rem" : "1rem", width: "100px" }}>
            { length > 0 && persons.map((person, idx) => (
                (idx + 1) <= maxVisible && 
                <AvatarPopup idx={idx} person={person} />
            ))}

            { length > maxVisible &&
                <Popup
                    size="tiny"
                    position="top center"
                    flowing hoverable
                    trigger={
                        <span style={{ position: "relative", right: "28px", zIndex: length, cursor: "pointer" }} >
                            <UserAvatar
                                style={{ color: "white", paddingLeft: '0rem', fontSize: size / 2.5 + "px", borderRadius: "50%", border: "2px solid white", }}
                                size={size}
                                name={(length - maxVisible).toString()}
                                color='var(--dark)'
                            />
                        </span>
                    }
                    content={
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            { persons.map((person, idx) => (
                                idx + 1 > maxVisible && 
                                <div>
                                    { person?.displayName } 
                                    {onRemove && canManage && <Button icon='close' size='tiny' onClick={() => onRemove(person.ID) } style={{ background: "none", paddingRight: 0 }} />}
                                </div>
                            ))}
                        </div>
                    }
                />
            }
            {allowAdditions && canManage &&
                <Popup
                    size="tiny"
                    position="top center"
                    trigger={
                        <span style={{ position: "relative", right: length > maxVisible ? "38px" : length === maxVisible ? "28px" : "10px", zIndex: length+1, cursor: "normal" }}>
                            <SuperDuperModal
                                trigger={
                                    <Label circular style={{ cursor: "pointer", color: "grey", paddingLeft: '0rem', fontSize: size / 2 + "px", borderRadius: "50%", border: "2px solid white" }} title={"+"}>
                                        <Icon name="add"/>
                                    </Label>
                                }
                                content={addForm}
                            />
                        </span>
                    }
                    content={t('add')}
                />
            }
        </div>
    );
};

export default AvatarList;