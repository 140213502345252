const globalPrefReducer = (global_pref = {
    colors: {
        // base 0
        default: "#ffffff",
        white: "#ffffff",
        black: "#292929",
        dark: "#292929",
        light: "#F2F4F6",

        // base 1
        primary: "#6b48ff",
        info: "#31C6E8",
        success: "#1EE3CF",
        warning: "#EEE722",
        danger: "#FF4C78",

        // others
        blue: "#0D3F67",
        iceblue: "#1EE3CF",
    },  
}, action) => {
    switch (action.type) {
        case "ADD_GLOBAL_PREFERENCES":
            return {
                colors: {
                    // base 0
                    default: action.payload.colors.default,
                    white: action.payload.colors.white,
                    black: action.payload.colors.black,
                    dark: action.payload.colors.dark,
                    light: action.payload.colors.light,

                    // base 1
                    primary: action.payload.colors.primary,
                    info: action.payload.colors.info,
                    success: action.payload.colors.success,
                    warning: action.payload.colors.warning,
                    danger: action.payload.colors.danger,

                    // others
                    blue: action.payload.colors.blue,
                    iceblue: action.payload.colors.iceblue,
                },
            };
        default:
            return global_pref;
    }
};

export default globalPrefReducer;
