import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { setLocaleLanguage, tzDateTime } from '@helpers/dates'
// components
import { Form, Divider, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
// specific components
import TransferHoursForm from './TransferHoursForm'
import RemoveCorrectedRecord from './RemoveCorrectedRecord'
import TransferRecordToAnotherDayForm from './TransferRecordToAnotherDayForm'
import FillRemainingHoursForm from './FillRemainingHoursForm'
import UpdateTimesForCorrectionRecord from './UpdateTimesForCorrectionRecord'

const CorrectionForm = ({
    onClose,
    title,
    data,
    setSize,
    attendanceRecords,
    record,
    planRecord,
    setContracts,
    setAttendanceRecords,
}) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    const manageActions = [
        { key: 1, value: 1, text: t('transfer_hours_to_bank') },
        // { key: 2, value: 2, text: t('transfer_record_to_another_day') },
        { key: 3, value: 3, text: t('fill_remaining_hours_from_overtime') },
    ]

    if (record?.is_corrected || record.original_record !== null) {
        manageActions.push({
            key: 4,
            value: 4,
            text: t('update_working_time_for_correction_record'),
        })

        manageActions.push({
            key: 5,
            value: 5,
            text: t('remove_corrected_record'),
        })
    }

    const [action, setAction] = useState('')

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Message
                info
                visible
                content={
                    <div>
                        {t('selected_day')}:{' '}
                        <strong>
                            {moment(data.day.date).format(dateFormat)} -{' '}
                            <span style={{ textTransform: 'capitalize' }}>
                                {moment(data.day.date).locale(setLocaleLanguage()).format('dddd')}
                            </span>{' '}
                            {data.day.holiday !== null && '(' + t('holiday') + ')'}
                            <br />
                        </strong>
                        {t('employee')}: <strong>{data.contract.fullname}</strong>
                        <br />
                        <div style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>{t('record_details')}:</div>
                        <strong>{t('arrival')}</strong>:{' '}
                        {record.start ? tzDateTime(record.start).format(dateFormat + ' HH:mm') : '--'}
                        <br />
                        <strong>{t('departure')}</strong>:{' '}
                        {record.end ? tzDateTime(record.end).format(dateFormat + ' HH:mm') : '--'}
                    </div>
                }
            />

            <SuperField
                as="choice"
                label={t('select_action_to_perform')}
                action={action}
                customOptions={manageActions}
                onChange={(e, { value }) => setAction(value)}
            />

            {action !== null && <Divider />}
            {action === 1 && (
                <TransferHoursForm
                    day={data.day}
                    contract={data.contract}
                    onClose={onClose}
                    record={record}
                    planRecord={planRecord}
                    setAttendanceRecords={setAttendanceRecords}
                    setContracts={setContracts}
                />
            )}
            {action === 2 && (
                <TransferRecordToAnotherDayForm
                    day={data.day}
                    contract={data.contract}
                    onClose={onClose}
                    record={record}
                    planRecord={planRecord}
                    attendanceRecords={attendanceRecords}
                    setAttendanceRecords={setAttendanceRecords}
                    setContracts={setContracts}
                />
            )}

            {action === 3 && (
                <FillRemainingHoursForm
                    day={data.day}
                    contract={data.contract}
                    onClose={onClose}
                    record={record}
                    planRecord={planRecord}
                    setAttendanceRecords={setAttendanceRecords}
                    setContracts={setContracts}
                />
            )}
            {action === 4 && (
                <UpdateTimesForCorrectionRecord
                    day={data.day}
                    contract={data.contract}
                    onClose={onClose}
                    record={record}
                    planRecord={planRecord}
                    setAttendanceRecords={setAttendanceRecords}
                    setContracts={setContracts}
                />
            )}
            {action === 5 && (
                <RemoveCorrectedRecord
                    day={data.day}
                    contract={data.contract}
                    onClose={onClose}
                    record={record}
                    planRecord={planRecord}
                    setAttendanceRecords={setAttendanceRecords}
                    setContracts={setContracts}
                />
            )}
        </Form>
    )
}

export default CorrectionForm
