import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { routes } from '@routes';

// components
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Grid, Form, Ref, Divider } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import ListView from '@components/ListView';
// module specific
import ProjectCreateForm from '../components/forms/ProjectCreateForm';
import LabelsList from '@components/lists/LabelsList';
import ProjectUpdateForm from '../components/forms/ProjectUpdateForm';
import SuperField from '@components/forms/SuperField';
import ProjectCard from '../components/card-view/ProjectCard';

const ProjectList = () => {
    const { t } = useTranslation();
    const dateFormat = useSelector(state => state.date_format)
    const segmentRef = useRef();

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev]);
        setTotal((prev) => prev + 1);
    };

    const onUpdate = (response, setData) => {
        setData((prev) =>
            prev.map((project) => {
                if (project.id === response.id) {
                    project = response;
                }
                return project;
            })
        );
    };

    return (
        <CanView permissions={['projects.c_view_all_projects', 'projects.c_view_user_projects']} redirect>
            <BreadcrumbNav items={[{ name: t('projects'), icon: icons.PROJECTS, href: '' }]} />
            <Ref innerRef={segmentRef}>
                <ListView
                    as="table"
                    allowSearch
                    allowViews
                    isSoftDeleteAllowed
                    endpoint={API.PROJECTS}
                    initialFilters={{
                        project_member: '',
                        project_manager: '',
                        is_active: true,
                        labels: '',
                        from_date: '',
                        due_date: '',
                        end_date: '',
                    }}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <SuperField
                                as="choice"
                                search
                                text="fullname"
                                label={t('project_member')}
                                value={filters.project_member}
                                onChange={(e, { value }) => setFilters({ ...filters, project_member: value })}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                            />
                            <SuperField
                                as="choice"
                                search
                                text="fullname"
                                label={t('project_manager')}
                                value={filters.project_manager}
                                onChange={(e, { value }) => setFilters({ ...filters, project_manager: value })}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                            />
                            <Divider />

                            <Form.Group widths="equal">
                                <SuperField
                                    as="checkbox"
                                    label={t('is_active')}
                                    width={10}
                                    checked={filters.is_active}
                                    onChange={() => setFilters({ ...filters, is_active: !filters.is_active })}
                                />
                            </Form.Group>

                            <SuperField
                                as="choice"
                                search
                                multiple
                                value={filters.labels}
                                label={t('labels')}
                                endpoint={API.PROJECT_LABELS}
                                text="name"
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        labels: value,
                                    })
                                }
                            />
                            <Divider />
                            <Form.Group widths="3">
                                <SuperField
                                    as="datepicker"
                                    label={t('start_date')}
                                    value={filters.from_date}
                                    onChange={(e, { value }) => setFilters({ ...filters, from_date: value })}
                                />
                                <SuperField
                                    as="datepicker"
                                    label={t('due_date')}
                                    value={filters.due_date}
                                    onChange={(e, { value }) => setFilters({ ...filters, due_date: value })}
                                />
                                <SuperField
                                    as="datepicker"
                                    label={t('end_date')}
                                    value={filters.end_date}
                                    onChange={(e, { value }) => setFilters({ ...filters, end_date: value })}
                                />
                            </Form.Group>
                        </>
                    )}
                    actions={[
                        {
                            as: 'modal',
                            type: 'add',
                            name: t('create_project'),
                            modal: <ProjectCreateForm onConfirm={(response, type) => onCreate(response, type)} />,
                            permissions: ['projects.c_manage_all_projects'],
                        },
                        {
                            as: 'link',
                            type: 'custom',
                            icon: 'eye-outline',
                            customIconColor: 'var(--primary)',
                            name: t('show_detail'),
                            redirect: (item) => routes.PROJECT_DETAIL + item.id,
                        },
                        {
                            as: 'modal',
                            type: 'edit',
                            name: t('edit'),
                            modalSize: 'small',
                            permissions: ['projects.c_manage_all_projects', 'projects.c_manage_user_projects'],
                            modal: (item, setData) => (
                                <ProjectUpdateForm
                                    project={item}
                                    listView={true}
                                    setData={setData}
                                    handleRequest={(response, setData) => onUpdate(response, setData)}
                                />
                            ),
                        },
                        {
                            name: t('delete'),
                            type: 'delete',
                            as: 'delete',
                            text: t('delete_project'),
                            permissions: ['projects.c_delete_all_projects'],
                        },
                    ]}
                    tableHeaders={[
                        { title: t('name'), orderBy: 'name' },
                        { title: t('labels') },
                        { title: t('start_date') },
                        { title: t('due_date') },
                    ]}
                    renderCells={(project) => [
                        {
                            content: (
                                <>
                                    <strong>{project?.name}</strong> <br />
                                    <small>{project?.identifier}</small>
                                </>
                            ),
                        },
                        {
                            content: (
                                <LabelsList
                                    rowID={project.id}
                                    labels={project?.labels}
                                    endpoint={API.PROJECTS}
                                    label_key="labels"
                                />
                            ),
                        },
                        { content: project.from_date ? moment(project.from_date).format(dateFormat) : "" },
                        { content: project.due_date ? moment(project.due_date).format(dateFormat) : "" },
                    ]}
                    renderCard={(data) => (
                        <>
                            <Grid style={{ margin: 0 }} centered stretched>
                                {data?.map((item, index) => (
                                    <Grid.Column
                                        mobile={16}
                                        tablet={8}
                                        computer={5}
                                        as={Link}
                                        to={routes.PROJECT_DETAIL + item.id}
                                        key={index}
                                        style={{ padding: '1rem', minWidth: '300px' }}
                                    >
                                        <ProjectCard
                                            dateFormat={dateFormat}
                                            id={item.id}
                                            name={item.name}
                                            description={item.description}
                                            startDate={item.from_date}
                                            endDate={item.due_date}
                                            teams={item.teams}
                                            is_active={item.is_active}
                                            project_manager={item.project_manager}
                                        />
                                    </Grid.Column>
                                ))}
                            </Grid>
                        </>
                    )}
                />
            </Ref>
        </CanView>
    );
};

export default ProjectList;
