import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
// components 
import SuperField from '@components/forms/SuperField';
import { Form } from 'semantic-ui-react';
import ListView from '@components/ListView';
// module specific components
import ForecastForm from '../components/forms/ForecastForm';
import ForecastRecordsList from '../views/ForecastRecordsList';

const ForecastList = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    const onCreate = (response, setData, setTotal) => {
        setData(prev => [response, ...prev])
        setTotal(prev => prev + 1)
    }

    const onUpdate = (response, setData) => {
        setData(prev => prev.map(forecast => {
            if (forecast.id === response.id) {
                forecast = response
            }
            return forecast
        }))
    }

    return (
        <ListView
            as="table"
            allowSearch
            endpoint={API.FORECASTS}
            initialFilters={{
                category: "",
                date_from_after: "",
                date_to_before: ""
            }}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField as="choice"
                        label={t('category')}
                        endpoint={API.FORECASTS + "categories/"}
                        text="title"
                        value={filters.category}
                        onChange={(e, { value }) => setFilters({
                            ...filters,
                            category: value
                        })}
                    />
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            clearable
                            label={t('date_from')}
                            value={filters.date_from_after}
                            onChange={(e, { value }) => setFilters({ ...filters, date_from_after: value })}
                        />
                        <SuperField as="datepicker"
                            clearable
                            label={t('date_to')}
                            value={filters.date_to_before}
                            onChange={(e, { value }) => setFilters({ ...filters, date_to_before: value })}
                        />
                    </Form.Group>
                </>
            )}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    name: t('create_supplier'),
                    modalSize: "small",
                    permissions: ['forecasts.c_manage_forecasts'],
                    modal: <ForecastForm onConfirm={(response, setData, setTotal) => onCreate(response, setData, setTotal)}/>
                },
                {
                    as: "modal",
                    type: "custom",
                    icon: "eye-outline",
                    customIconColor: "var(--primary)",
                    name: t("show_detail"),
                    modal: (item) => <ForecastRecordsList forecast={item}/>,
                    permissions: ['forecasts.c_view_forecasts']
                },
                {
                    as: "modal",
                    type: "edit",
                    name: t('edit'),
                    modalSize: "small",
                    permissions: ['forecasts.c_manage_forecasts'],
                    modal: (item, setData) => <ForecastForm
                        forecast={item}
                        setData={setData}
                        onConfirm={(response, setData) => onUpdate(response, setData)}
                    />
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('delete_forecast_record'),
                    permissions: ['forecasts.c_delete_forecasts'],
                },
            ]}
            tableHeaders={[
                { title: t('name'), orderBy: "title" },
                { title: t('date_from'), orderBy: "date_from" },
                { title: t('date_to'), orderBy: "date_to"  },
                { title: t('category'), orderBy: "category__title"  }
            ]}
            renderCells={(forecast) => ([
                { content: forecast?.title },
                { content: moment(forecast?.date_from).format(dateFormat) },
                { content: moment(forecast?.date_to).format(dateFormat) },
                { content: forecast?.category?.title },
            ])}
        />
    );
};

export default ForecastList;