import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests'
// components
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { Checkbox, Segment } from 'semantic-ui-react';

const FluctuationYearlyViewGraph = ({ year }) => {
    const { t } = useTranslation()
    const colors = useSelector(state => state.global_pref.colors)

    const firstDayOfYear = moment([year]).startOf('year').format("YYYY-MM-DD")
    const lastDayOfYear = moment([year]).endOf('year').format("YYYY-MM-DD")

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const [allowToCompare, setAllowToCompare] = useState(false)
    const [currentYear] = useState(year)
    // eslint-disable-next-line
    const [comparisonYear] = useState(parseInt(year) - 1)


    useEffect(() => {
        async function fetchReport (){
            setLoading(true)
            const request = await requests.get(API.REPORTS + `dashboard/employees_montly_report/?date_from=${firstDayOfYear}&date_to=${lastDayOfYear}&compare_year=${allowToCompare}`)
            
            if (request.status === 200) {
                let reportData = []
                let response = request.response
                for(let i = 0; i < response.length; i++){
                    let dataObj = {}

                    dataObj['name'] = response[i].month_display
                    dataObj[response[i].current_year] = response[i].current_year_total
                    
                    if (allowToCompare) {
                        dataObj[response[i].comparison_year] = response[i].comparison_year_total
                    }

                    reportData.push(dataObj)
                }
                
                setData(reportData)
            }
            setLoading(false)
        }

        fetchReport()
    }, [firstDayOfYear, lastDayOfYear, year, allowToCompare])

    return (
        <Segment 
            loading={loading}
            style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
            }}
        >
            <Checkbox 
                label={t('compare_with_previous_year')}
                checked={allowToCompare}
                onChange={() => setAllowToCompare(!allowToCompare)}
            />
            { loading && 
                <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                { t('loading_yearly_overview') } 
                </p>
            }
            { !loading && 
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart
                        data={data}
                        margin={{
                            top: 0,
                            right: 0,
                            left: -40,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36}/>
                        <Bar dataKey={currentYear} name={currentYear} stackId="a" fill={colors.primary} />
                        { allowToCompare && <Bar dataKey={comparisonYear} name={comparisonYear} stackId="b" fill={colors.dark} />}
                    </BarChart>
                </ResponsiveContainer>
            }
        </Segment>
        
    )

};

export default FluctuationYearlyViewGraph;