import React, { useState } from 'react';
// store
import { companyService } from '../../../store/services/ServiceCompany';
import { isEmpty } from '../../../store/services/helpers/validation';
// components
import { Form, Message, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const AddTeamForm = (props) => {
    const [id] = useState(props.id ? props.id : 0);
    const [teamId, setTeamId] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [options] = useState(props.options ? props.options : []);
    const {t}=useTranslation()

    const array = options.map((item, index) => ( { key: index, text: item.name, value: item.id } ));

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrorList([]);

        const result = await companyService.updateUnitData(id, {
            teams: { add: [teamId] }
        });


        if( result.status === 200 ) {
            for (let i = 0; i < result.response.teams.length; i++) {
                if( result.response.teams[i].id === teamId ){
                    props.onConfirm(result.response.teams[i]);
                    props.onClose()
                    break;
                }
            }

        } else {
            const error_message = result.response.teams;
            setErrorList([...errorList, error_message]);
        }
        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            { errorList.length > 0 &&
                (<Message negative>
                    { errorList.map( (error, index) =>
                        <p key={index}> {error} </p>
                    )}
                </Message>)
            }
            <Form.Select
                fluid
                value={teamId}
                options={array}
                placeholder='---------'
                onChange={(e, data) => setTeamId(data.value)}
            />
           <Form.Field style={{ textAlign: "right", margin:"1rem" }}>
                <Button
                    type="button"
                    style={{ backgroundColor: "var(--variant5)" }}
                    onClick={props.onClose}
                    disabled={isProcessing}>
                   {t('cancel')}
                </Button>
                <Button
                    primary
                    style={{ backgroundColor: "var(--success)" }}
                    disabled={isProcessing || isEmpty(id)   }

                    loading={isProcessing}
                >
                    {props.btnName}
                </Button>
            </Form.Field>
        </Form>
    );
};

export default AddTeamForm;