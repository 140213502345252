import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { tzDateTime } from '@helpers/dates';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const LastContactedForm = ({ onClose, lead, setData}) => {
    const { t } = useTranslation()

    const dateFormat = useSelector(state => state.date_format)
    const [isProcessing, setIsProcessing] = useState(false)
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
    const [time, setTime] = useState("")

    const handleSubmit = async () => {
        setIsProcessing(true)
        let lastContacted = date
        if (time !== "") {
            lastContacted += " " + time
            lastContacted = tzDateTime(lastContacted, true).format("YYYY-MM-DD HH:mm")
        } else {
            lastContacted += " 00:00"
        }
        const request = await requests.patch(API.ORDERS + "leads/" + lead.id + "/", { last_contacted: lastContacted })
        if (request.status === 200) {
            setData(prev => prev.filter(item => {
                if (item.id === lead.id) {
                    item.last_contacted = request.response.last_contacted
                }
                return item
            }))

            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={t('date')}
                    value={date}
                    onChange={(e, { value }) => setDate(value)}
                />
                <SuperField as="timepicker"
                    value={time}
                    label={t('time')}
                    onChange={(e, { value }) => setTime(value)}
                />
            </Form.Group>

            <div>
                {t('last_contacted')}: { "" }
                <strong>
                    { lead?.last_contacted 
                        ? moment(lead.last_contacted).format("HH:mm") === "00:00" ? moment(lead?.last_contacted).format(dateFormat) : tzDateTime(lead?.last_contacted).format(dateFormat + " HH:mm") 
                        : t('not_contacted') 
                    }
                </strong>
            </div>

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t("confirm")}
                    disabled={isProcessing || date === ""}
                    loading={isProcessing} 
                />
            </Form.Field>
        </Form>
    );
};

export default LastContactedForm;