import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { requests } from '@helpers/requests';
import { useHasPermissions, useHasPermission } from '@helpers/hooks';
import { dates as dateHelper } from '@helpers/dates';
// Components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import { Form, Popup } from 'semantic-ui-react';
import ShortenText from '@components/ShortenText';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';
import TariffForm from '@components/forms/TariffForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// specific comps
import ActivityForm from '../components/forms/ActivityForm';
import ApprovalRequestForm from '../components/forms/ApprovalRequestForm';
import StatsRow from '../components/stats/StatsRow';

const DayOverview = () => {
    const { t } = useTranslation();
    const today = moment().format("YYYY-MM-DD");
    const dateFormat = useSelector(state => state?.date_format || "YYYY-MM-DD")
    const canManageAll = useHasPermissions(['timesheets.c_manage_all_records', 'company.c_manage_only_assigned_unit_employees'])
    const canViewTariff = useHasPermission('timesheets.c_view_tariff')
    const canManageTariffs = useHasPermissions(['orders.c_view_order_tariffs', 'orders.c_manage_order_tariffs'])

    const [orders, setOrders] = useState([])
    const [clients, setClients] = useState([])

    const canManageTariff = (record) => {
        if (!canManageAll) return false
        if (!canViewTariff) return false
        if (!canManageTariffs) return false
        if (record.order_fulfillment !== null) return false

        return true
    }

    useEffect(() => {
        async function fetchOrders(){
            const request = await requests.get(API.ORDERS + "?query={id,name,account}&only_basic_info=true")

            if (request.status === 200) {
                setOrders(request.response)
            }
        }
        
        async function fetchClients(){
            const request = await requests.get(API.ACCOUNTS + "business_details/?query={id, name}&is_account=true&exclude_unit=true")
            if (request.status === 200) {
                setClients(request.response)
            }
        }

        fetchOrders()
        fetchClients()
    }, [])

    return (
        <>
            <ListView
                as="table"
                allowSearch
                allowSelection
                isSoftDeleteAllowed
                actionsCellWidth="1"
                endpoint={API.TIMESHEETS + "records/"}
                initialFilters={{
                    profile: "",
                    project: "",
                    category: "",
                    business_detail: "",
                    order: "",
                    start_date: today,
                    end_date: today
                }}
                exports={[
                    {
                        endpoint: canViewTariff ? API.EXPORTS + "dynamic_timesheet_records_with_tariff/" : API.EXPORTS + "dynamic_timesheets/",
                        permissions: ['timesheets.c_view_all_records'],
                        apply_filters: true,
                        type: "xlsx",
                        filename: "timesheets"
                    },
                ]}
                renderStatisticView={(filters, data, total, params, setTotal, setData, setFilters) => (
                    <CanView permissions={['reports.c_view_timesheets_reports']}>
                        <div style={{ marginTop: "2rem" }}>
                            <StatsRow data={data} filters={filters} params={params}/>
                        </div>
                    </CanView>
                )}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                size="small"
                                label={t('start_date')}
                                value={filters.start_date}
                                style={{ height: "2.75rem" }}
                                onChange={(e, { value }) => setFilters({ ...filters, start_date: value })}
                            />
                            <SuperField as="datepicker"
                                size="small"
                                label={t('end_date')}
                                value={filters.end_date}
                                style={{ height: "2.75rem" }}
                                onChange={(e, { value }) => setFilters({ ...filters, end_date: value })}
                            />
                        </Form.Group>
                        <DatePresets
                            from={filters.start_date}
                            to={filters.end_date}
                            onSelection={ (firstMonthDay, lastMonthDay, year) => {
                                setFilters(prev => ({
                                    ...prev,
                                    start_date: firstMonthDay,
                                    end_date: lastMonthDay,
                                }))
                            }}
                        />

                        { canManageAll &&
                            <SuperField as="choice"
                                search
                                text="fullname_with_titles"
                                label={t('profile')}
                                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                                value={filters.profile}
                                onChange={(e, { value }) => setFilters({ ...filters, profile: value })}
                            />
                        }

                        <SuperField as="choice"
                            search
                            text="title"
                            label={t('activity')}
                            endpoint={API.TIMESHEETS + "categories/"}
                            value={filters.category}
                            onChange={(e, { value }) => setFilters({ ...filters, category: value })}
                        />
                        
                        <SuperField as="choice"
                            search
                            label={t('order')}
                            value={filters.order}
                            customOptions={orders.map(item => ({ key: item.id, value: item.id, text: item.name }))}
                            onChange={(e, { value }) => setFilters({ 
                                ...filters, 
                                order: value,
                            })}
                        />
                        <SuperField as="choice"
                            search
                            label={t('client')}
                            value={filters.business_detail}
                            customOptions={clients.map(item => ({ key: item.id, value: item.id, text: item.name }))}
                            onChange={(e, { value }) => setFilters({ ...filters, business_detail: value })}
                        />
                        <SuperField as="choice"
                            search
                            text="name"
                            label={t('project')}
                            value={filters.project}
                            endpoint={API.PROJECTS + "?query={id, name}&is_active=true"}
                            onChange={(e, { value }) => setFilters({ ...filters, project: value })}
                        />
                    </>
                )}
                bulkActions={(selected, setData) => [
                    {
                        as: "modal",
                        name: t('request_approval'),
                        icon: "document-text-outline",
                        modal: <ApprovalRequestForm selected={selected} setData={setData}/>
                    }
                ]}
                actions={[
                    {
                        as: "modal",
                        type: "edit",
                        name: t('edit'),
                        modalSize: "small",
                        permissions: ['timesheets.c_manage_all_records', 'timesheets.c_manage_user_records', 'company.c_manage_only_assigned_unit_employees'],
                        modal: <ActivityForm orders={orders} clients={clients}/>,
                        isHidden: (item) => item.order_fulfillment !== null
                    },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('delete_record_message'),
                        permissions: ['timesheets.c_manage_all_records', 'timesheets.c_manage_user_records', 'company.c_manage_only_assigned_unit_employees'],
                        isHidden: (item) => item.order_fulfillment !== null
                    },
                ]}
                tableHeaders={[
                    { title: t('employee') },
                    { title: t('date') },
                    { title: t('activity') },
                    { title: t('hours') + " / " + t('amount') },
                    { title: t('client') },
                    { title: t('project') },
                    { title: t('order') },
                    canViewTariff && { title: t('sum') },
                    { title: t('approval') },
                ]}
                renderCells={(record, setData) => ([
                    { content: record.profile.fullname },
                    { content: 
                        <>
                            <strong>{ moment(record.date).format(dateFormat) }</strong> <br/>
                            <small>{t('week')} { record.week }</small>
                        </>
                    },
                    { width: 4, content: 
                        <>
                            <strong>{ record?.category?.title }</strong> <br/>
                            <small>
                                <ShortenText text={record.activity} textLength={50} />    
                            </small>
                        </>
                    },
                    { width: 2, content: 
                        <>
                            <strong style={{ textTransform: "lowercase" }}>{ `${record.hours} ${t('hours_shortcut')}.` } { record.piece_work_count > 0 && ` / ${record.piece_work_count}x` }</strong> <br/>
                            <small>
                                {(record.datetime_from !== null && record.datetime_to !== null) && (
                                    `${moment(dateHelper.convertUTCDateToLocalDate(record?.datetime_from)).format("HH:mm")} - ${moment(dateHelper.convertUTCDateToLocalDate(record?.datetime_to)).format("HH:mm")}`
                                )}
                            </small>
                        </>
                    },
                    {
                        content: record?.business_detail ?
                            <>
                                <Link target="_blank" rel="noopener noreferrer" to={routes.ACCOUNTS + record?.business_detail?.id}>
                                    <span>{record?.business_detail?.name}</span>
                                </Link>
                            </>
                            : "--"
                    },
                    {
                        content:
                            <>
                                <Link to={routes.PROJECT_DETAIL + record?.project?.id}>
                                    <span>{record?.project?.name}</span>
                                </Link>
                                <br/>
                                { record?.milestone?.name }
                            </>
                    },
                    { content: <span>{record?.order?.name}</span> },
                    canViewTariff && { content: 
                        <>
                            { record?.tariff?.id 
                                ? 
                                    <div style={{ display: "flex" }}>
                                        <Popup
                                            position="left center"
                                            trigger={
                                                <span>{record?.total_fee || ""} { record?.tariff?.currency || "" }</span>
                                            }
                                            content={`${record.tariff.type_display} - ${record.tariff.amount} ${record.tariff.currency}`}
                                        />
                                        { canManageTariff(record) &&  
                                            <SuperDuperModal 
                                                size="tiny"
                                                trigger={
                                                    <Icon className="action-hover" name="pencil-outline" style={{ color: "var(--dark)", marginLeft: "1rem", marginTop: "0.25rem" }}/>
                                                }
                                                content={
                                                    <TariffForm
                                                        record={record}
                                                        currentTariff={record?.tariff}
                                                        activity={record?.category.id}
                                                        profile={record.profile.id}
                                                        setData={setData}
                                                    />
                                                }
                                            />
                                        }
                                    </div>
                                : "--"
                            }
                        </>
                    },
                    { content: 
                        <>
                            { (record?.approval === null && record?.is_approved === false) && <><Icon name="ellipse" style={{ marginRight: "0.5rem", color: "var(--dark)" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{t('not_requested_approval')}</span></> }
                            { (record?.approval?.is_approved === null && record?.is_approved === false) && <><Icon name="ellipse" style={{ marginRight: "0.5rem", color: "orange" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{t('awaiting_approval')}</span></> }
                            { (record?.approval?.is_approved === true || record?.is_approved === true) && <><Icon name="ellipse" style={{ marginRight: "0.5rem", color: "var(--success)" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{t('approved')}</span></> }
                            { (record?.approval?.is_approved === false && record?.is_approved === false) && <><Icon name="ellipse" style={{ marginRight: "0.5rem", color: "var(--danger)" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{t('denied')}</span></> }
                        </>
                    },
                ])}
            />
        </>
    );
};

export default DayOverview;