import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
// store
import { API } from '@store/config';
import { tzDateTime } from '@helpers/dates';
import { fileDownload } from '@helpers/requests';
import { useHasPermission } from '@helpers/hooks';
import { renderAddress } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import Attachments from '@components/Attachments';

import GenerateDocuments from '@components/GenerateDocuments';
import { Label, Popup, Segment, Grid } from 'semantic-ui-react';
// module specific
import EmployedPersonForm from './EmployedPersonForm';
import EmployedPersonsFilter from './EmployedPersonsFilter';

const EmployedPersons = ({ order, onClose }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)
    const canManage = useHasPermission('orders.c_manage_order')

    function convertSelectedToIds(selected){
        let ids = ""
        for (let i = 0; i < selected.length; i++) {
            if( selected.length - 1 === i ){
                ids += selected[i].id
            } else {
                ids += selected[i].id + ","
            }
        }

        return ids
    }

    const exportData = async (selected, queryParam) => {
        queryParam = queryParam || ""
        let endpoint = API.EXPORTS + "employment_item/"
        let fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        let filename = "xlsx-export.xlsx"

        toast({
            type: 'success',
            icon: 'check circle',
            title: t('generating_export') + "...",
            description: t("wait_a_moment_until_its_finished") + ".",
            animation: 'bounce',
            time: 3000,
        })

        // when there are no selected invoices then export all based on filters specified
        if (selected.length === 0){
            await fileDownload("GET", endpoint + queryParam, fileType, filename)
        } else {
            await fileDownload("GET", endpoint + "?ids=" + convertSelectedToIds(selected), fileType, filename)
        }
    }

    const isActive = (person) => {
        if (person.valid_to === "" || person.valid_to === null) return true
        if (moment(moment().format("YYYY-MM-DD")).isSameOrBefore(moment(person.valid_to))) return true

        return false
    }

    return (
        <>        
            <ListView as="table"
                isModal
                allowSearch
                allowSelection
                actionsCellWidth="1"
                endpoint={API.ORDERS + "employment_items/"}
                initialFilters={{
                    order: order.id,
                    is_expired: false,
                    valid_from_after: "",
                    valid_from_before: "",
                    valid_to_after: "",
                    valid_to_before: "",
                    ordering: "-created_on"
                }}
                tableHeaders={[
                    { title: t('employee') },
                    { title: `${t('valid_from')} / ${t('valid_to')}` },
                    { title: t('fee') },
                    { title: t('workplace') },
                    { title: t('status') },
                    { title: t('documents') },
                    { title: t('created') },
                ]}
                bulkActions={ (selected, setData, setTotal, filters, queryParams, setSelected) => [
                    { 
                        as: "modal",
                        name: t('generate_documents'),
                        icon: "document-text-outline",
                        modal: <GenerateDocuments source="orders.EmploymentItem" selected={selected} />
                    },
                    { 
                        as: "custom",
                        icon: "download-outline",
                        name: t('export_to_xlsx'),
                        onClick: () => exportData(selected, queryParams)
                    },
                ]}
                renderStatisticView={() => (
                    <Segment style={{ marginBottom: "0.5rem" }}>
                        <Grid>
                            <Grid.Row columns="3">
                                <Grid.Column>
                                    <strong>{t('name') + " / " + t('order_number')}</strong><br/>
                                    <span>{order.name} / {order.order_number}</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <strong>{t('account')} / {t('id_number')}</strong><br/>
                                    <span>{order.business_detail.name} / {order.business_detail.identification_number}</span>
                                </Grid.Column>
                                <Grid.Column>
                                    <strong>{t('employed_persons')}</strong><br/>
                                    <span>
                                        {
                                            `${order.active_employed_person_count} / ${order.amount || 0} (${(order.amount > 0 && order.active_employed_person_count > 0) ? parseFloat((order.active_employed_person_count/order.amount)*100).toFixed(2).replace(".00", "") : "0"}%)`
                                        }
                                    </span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                )}
                actions={[
                    {
                        modalSize: "tiny",
                        as: "modal",
                        type: "edit",
                        name: t('edit'),
                        permissions: ['orders.c_manage_order'],
                        modal: <EmployedPersonForm/>
                    },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('delete_employed_person'),
                        permissions: ['orders.c_delete_order'],
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <EmployedPersonsFilter filters={filters} setFilters={setFilters} />
                )}
                listAdditionActions={(data, setData, total, setTotal, response) => ([
                    {
                        as: "filter",
                        index: 0,
                        name: t('active'),
                        filters: {
                            is_expired: false,
                        },
                    },
                    {
                        as: "filter",
                        index: 1,
                        name: t('expired'),
                        filters: {
                            is_expired: true,
                        },
                    },
                    {
                        as: "filter",
                        index: 2,
                        name: t('all'),
                        filters: {
                            is_expired: "",
                        },
                    },
                ])}
                renderCells={(person, setData) => ([
                    { content: person.contract.employee.fullname_with_titles },
                    { content: `${person.valid_from ? moment(person.valid_from).format(dateFormat) : "--"} / ${person.valid_to ? moment(person.valid_to).format(dateFormat) : "--"}` },
                    { content: `${person.fee_per_hour} ${person.currency}` },
                    { content: renderAddress(person.workplace_address) },
                    { content: 
                        <div style={{ display: "flex" }}>
                            <Icon name='ellipse' style={{ color: isActive(person) ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }} />
                            <span style={{ position: "relative", top: "-0.2rem" }}> {isActive(person) ? t('active') : t('expired')} </span>
                        </div>
                    },
                    { content: 
                        <Label basic>
                            <Popup
                                style={{ zIndex: "1000", minWidth: "450px" }}
                                open={attachmentsOpen === person.id}
                                onOpen={() => setAttachmentsOpen(person.id)}
                                hoverable
                                position='left center'
                                trigger={
                                    <div style={{ cursor: "pointer" }}>
                                        <Icon name="reader-outline" style={{ marginRight: "0.5rem" }}/> 
                                        <span style={{ position: "relative", top: "-0.1rem" }}>{ person.files.length }</span>
                                    </div>
                                }
                                content={
                                    <Attachments 
                                        attribute="files"
                                        viewOnly={!canManage}
                                        record={person}
                                        setData={setData}
                                        sourceEndpoint={API.ORDERS + "employment_items/"}
                                        closeView={() => setAttachmentsOpen(0)}
                                        // additionalData={{ resourcetype: "EmploymentOrder" }}
                                    />
                                }
                            />
                        </Label>
                    },
                    { content: 
                        <div>
                            { tzDateTime(person.created_on).format(dateFormat + " HH:mm") } <br/>
                            <span>{t('created_by')} <strong>{person.created_by?.name || "--"}</strong></span>
                        </div>
                    },
                ])}
            />
        </>
            
    );
};

export default EmployedPersons;