import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { candidatesService } from '@services/ServiceCandidates';
import { isEmpty } from '@helpers/validation';
import { API } from '@store/config';
// components
import { Form, Message } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const NewManagerForm = ({ managersList, onClose, onConfirm }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [profile, setProfile] = useState(0);
    const [validUntil, setValidUntil] = useState('');

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrorList([]);

        const data = {
            profile: profile,
            valid_until: validUntil ? validUntil : null
        }

        const result = await candidatesService.addManager(data);

        if (result && result.status === 201) {
            onConfirm(result.response)
            onClose()
        }
        else {
            const error_message = result.response.detail;
            setErrorList([...errorList, error_message]);
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errorList.length > 0 &&
                (<Message negative>
                    {errorList.map((error, index) =>
                        <p key={index}> {error} </p>
                    )}
                </Message>)
            }

            <Form.Group widths={"equal"}>
                <SuperField as="choice"
                    search
                    required
                    placeholder={t('managers')}
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                    exclude={managersList}
                    text="fullname"
                    label={t('managers')}
                    value={profile}
                    onChange={(e, { value }) => setProfile(value)}

                />

                <SuperField as="datepicker"
                    dateFormat="YYYY-MM-DD"
                    label={t('valid_until')}
                    name={t('valid_until')}
                    value={validUntil}
                    iconPosition="left"
                    onChange={(e, data) => setValidUntil(data.value)}
                />
            </Form.Group>


            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(profile)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>

        </Form>
    );
};

export default NewManagerForm;