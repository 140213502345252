import React, { Fragment, useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { isEmpty, isEmailError } from "@helpers/validation"
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//components
import { Form, Button, Message } from 'semantic-ui-react'

function ChangeEmailForm(props) {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState([]); // empty array for error messages
    const [isProcessing, setIsProcessing] = useState(false); // this is for handling if form is currently processing our request

    // Form HandleSubmit Function (Async/Await):
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsProcessing(true);
        let errorsArray = [];
        const data = {
            email: email
        }

        const result = await requests.patch(API.SET_EMAIL, data);

        if (result.status === 204) {
            props.onConfirm(data.email);
            props.onClose()
        } else {
            if (result.response.email) {
                errorsArray.push(result.response.email)
            }

            setErrors(errorsArray);
        }

        setIsProcessing(false);
    }

    // Simple Component for error message handling (just for example... usage could be improved)
    const ErrorMessages = () => {
        return (
            <Fragment>
                {errors.length > 0 ?
                    <Message negative>
                        {   // this is like forloop cycle we are looping over error array to print errors
                            errors.map((error, index) =>
                                <p key={index}>
                                    {error}
                                </p>
                            )
                        }
                    </Message>
                    : ''}
            </Fragment>
        );
    }

    return (

        <Form onSubmit={handleSubmit}>
            {errors.length > 0 // print error messages if there are any
                ? <ErrorMessages />
                : ''
            }

            <Form.Field>
                <Form.Input
                    autoFocus
                    required
                    label={props.label}
                    placeholder={props.placeholder}
                    value={email}
                    onChange={(e) =>
                        setEmail(e.target.value)
                    }
                    error={
                        !isEmpty(email)
                            ? isEmailError(email)
                            : false
                    }
                />
            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    basic
                    type="button"
                    onClick={props.onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    //onClick={props.onConfirm}
                    disabled={isProcessing || isEmpty(email) || isEmailError(email)}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default ChangeEmailForm