import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
// import { Link } from 'react-router-dom';
// store
import { icons } from '@icons'
// import { routes } from '@routes';
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import UserAvatar from 'react-user-avatar'
import ListView from '@components/ListView'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import SuperField from '@components/forms/SuperField'
import { Container, Form, Popup } from 'semantic-ui-react'
import GenerateDocuments from '@components/GenerateDocuments'
// specific components
import VehicleForm from './components/VehicleForm'
import VehicleDetailView from './views/VehicleDetailView'
import VehicleDocuments from './components/VehicleDocuments'
import VehiclesDocumentView from './views/VehiclesDocumentView'

const Vehicles = () => {
    const { t } = useTranslation()
    const [owners, setOwners] = useState([])
    const [employees, setEmployees] = useState([])
    const [contracts, setContracts] = useState([])
    const dateFormat = useSelector((state) => state.date_format)
    const [forceModalOpen, setForceModalOpen] = useState(0)

    async function fetchActiveContracts() {
        const request = await requests.get(
            API.CONTRACTS +
                '?query={id, status, employer{id, name, identification_number}, work_position{id, title}, employee{id, fullname, fullname_with_titles}}&status=1'
        )
        if (request.status === 200) {
            setContracts(
                request.response?.results?.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text:
                        (item?.employee?.fullname_with_titles || '') +
                        ' - ' +
                        (item?.employer?.name || '') +
                        ' (' +
                        (item?.work_position?.title || '') +
                        ')',
                }))
            )
        }
    }

    async function fetchOwners() {
        const request = await requests.get(
            API.BUSINESS_DETAIL + '?query={id, name, identification_number}&is_employer=true'
        )
        if (request.status === 200) {
            setOwners(
                request.response.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name + ' - ' + item.identification_number,
                }))
            )
        }
    }

    async function fetchEmployees() {
        const request = await requests.get(API.EMPLOYEES + '?only_basic_info=true&is_active=true')
        if (request.status === 200) {
            setEmployees(
                request.response.map((item) => ({ key: item.id, value: item.id, text: item.fullname_with_titles }))
            )
        }
    }

    useEffect(() => {
        fetchOwners()
        fetchEmployees()
        fetchActiveContracts()
        // eslint-disable-next-line
    }, [])

    return (
        <CanView permissions={['vehicles.c_view_all_vehicles', 'vehicles.c_view_assigned_vehicles']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[{ name: t('vehicles'), icon: icons.VEHICLES, href: '' }]}
            />
            <Container fluid style={{ marginBottom: '2.5rem' }}>
                <ListView
                    as="table"
                    allowSearch
                    allowSelection
                    actionsCellWidth="2"
                    isSoftDeleteAllowed
                    endpoint={API.VEHICLES}
                    initialFilters={{
                        car_plate_number: '',
                        fuel_type: '',
                        owner: '',
                        tss_uuid: '',
                        type: '',
                        vin: '',
                        responsible_persons: '',
                        ordering: 'title',
                    }}
                    bulkActions={(selected) => [
                        {
                            as: 'modal',
                            name: t('generate_documents_for_vehicle'),
                            icon: 'document-text-outline',
                            modal: <GenerateDocuments source="vehicles.Vehicle" selected={selected} />,
                        },
                        {
                            as: 'modal',
                            name: t('generate_documents_for_responsible_person'),
                            icon: 'document-text-outline',
                            modal: <VehiclesDocumentView selectedVehicles={selected} />,
                        },
                    ]}
                    tableHeaders={[
                        { title: t('title') },
                        { title: t('owner') },
                        { title: t('fuel_type') },
                        { title: t('car_plate_number') },
                        { title: t('vignette') },
                        { title: t('inspections') },
                        { title: t('responsible_persons') },
                        { title: t('documents') },
                    ]}
                    renderFilterFields={(filters, setFilters) => (
                        <>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="input"
                                    label={t('car_plate_number')}
                                    value={filters.car_plate_number}
                                    onChange={(e, { value }) => setFilters({ ...filters, car_plate_number: value })}
                                />
                                <SuperField
                                    as="input"
                                    label={t('vin')}
                                    value={filters.vin}
                                    onChange={(e, { value }) => setFilters({ ...filters, vin: value })}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <SuperField
                                    as="choice"
                                    label={t('fuel_type')}
                                    type="fuel_type"
                                    value={filters.fuel_type?.toString() || ''}
                                    onChange={(e, { value }) => setFilters({ ...filters, fuel_type: value })}
                                />
                                <SuperField
                                    as="choice"
                                    search
                                    label={t('vehicle_type')}
                                    endpoint={API.VEHICLES + 'types/'}
                                    text="title"
                                    value={filters.type}
                                    onChange={(e, { value }) => setFilters({ ...filters, type: value })}
                                />
                            </Form.Group>
                            <SuperField
                                as="choice"
                                search
                                label={t('owner')}
                                value={filters?.owner || ''}
                                customOptions={owners}
                                onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
                            />
                            <SuperField
                                as="choice"
                                search
                                label={t('responsible_person')}
                                value={filters?.responsible_persons || ''}
                                customOptions={employees}
                                onChange={(e, { value }) => setFilters({ ...filters, responsible_persons: value })}
                            />
                        </>
                    )}
                    actions={[
                        {
                            as: 'modal',
                            type: 'add',
                            name: t('add_vehicle'),
                            modal: <VehicleForm owners={owners} />,
                            permissions: ['vehicles.c_manage_all_vehicles'],
                        },
                        {
                            as: 'modal',
                            type: 'custom',
                            icon: 'eye-outline',
                            name: t('detail'),
                            modalSize: 'large',
                            customIconColor: 'var(--primary)',
                            modal: (item) => <VehicleDetailView vehicle={item} contracts={contracts} />,
                            permissions: ['vehicles.c_view_all_vehicles', 'vehicles.c_view_assigned_vehicles'],
                        },
                        {
                            as: 'modal',
                            type: 'edit',
                            name: t('edit'),
                            icon: 'pencil-outline',
                            permissions: ['vehicles.c_manage_all_vehicles'],
                            modal: <VehicleForm owners={owners} />,
                        },
                        {
                            name: t('delete'),
                            type: 'delete',
                            as: 'delete',
                            text: t('are_you_sure_that_you_want_to_delete_this_vehicle'),
                            permissions: ['vehicles.c_delete_all_vehicles'],
                        },
                    ]}
                    renderCells={(item, setData) => [
                        {
                            content: (
                                <>
                                    <strong>{item.title}</strong>
                                    <br />
                                    <span>{item.type?.title || ''}</span>
                                </>
                            ),
                        },
                        {
                            content: (
                                <>
                                    {item.owner ? (
                                        // <Link to={routes.UNIT_DETAIL + item.owner?.id} target="_blank">
                                        <>
                                            {item.owner?.name} <br /> {item.owner?.identification_number}
                                        </>
                                    ) : (
                                        // </Link>
                                        '--'
                                    )}
                                </>
                            ),
                        },
                        { content: item.fuel_type_display },
                        { content: item.car_plate_number },
                        {
                            content: (
                                <>
                                    {item.vignettes.length === 0 && t('no_valid_vignette')}
                                    {item.vignettes.map((vignette) => (
                                        <>
                                            <span>
                                                {vignette.country}: {moment(vignette.date_from).format(dateFormat)} -{' '}
                                                {moment(vignette.date_to).format(dateFormat)}
                                            </span>
                                            <br />
                                        </>
                                    ))}
                                </>
                            ),
                        },
                        {
                            content: (
                                <>
                                    {item.inspections.length === 0 && t('no_inspections')}
                                    {item.inspections
                                        .sort((a, b) => (a.valid_to < b.valid_to ? 1 : -1))
                                        .map((inspection) => (
                                            <>
                                                <span>
                                                    {inspection.type_display} -{' '}
                                                    <span style={{ textTransform: 'lowercase' }}>{t('ends')}</span>:{' '}
                                                    {inspection.valid_to
                                                        ? moment(inspection.valid_to).format(dateFormat)
                                                        : '--'}
                                                </span>
                                                <br />
                                            </>
                                        ))}
                                </>
                            ),
                        },
                        {
                            content: (
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {item?.allowed_users?.map((responsiblePerson, index) => (
                                        <Popup
                                            key={index}
                                            style={{ cursor: 'pointer' }}
                                            position="top center"
                                            trigger={
                                                <div style={{ cursor: 'pointer' }}>
                                                    <UserAvatar
                                                        key={index}
                                                        style={{ paddingLeft: '0rem', fontSize: 30 / 2.5 + 'px' }}
                                                        size={30}
                                                        name={responsiblePerson?.employee?.fullname || '--'}
                                                        color="var(--variant5)"
                                                        src={responsiblePerson?.employee?.profile_picture || ''}
                                                    />
                                                </div>
                                            }
                                            content={responsiblePerson?.employee?.fullname || '--'}
                                        />
                                    ))}
                                </div>
                            ),
                        },
                        {
                            content: (
                                <Action
                                    as="modal"
                                    modalSize="tiny"
                                    tooltip={t('documents')}
                                    open={item.id === forceModalOpen}
                                    onClose={() => setForceModalOpen(0)}
                                    trigger={
                                        <span
                                            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                                            onClick={() => setForceModalOpen(item.id)}
                                        >
                                            <Icon name="document-outline" />
                                            <sup style={{ fontWeight: 'bold', marginLeft: '0.5rem' }}>
                                                {item.attachments.length}
                                            </sup>
                                        </span>
                                    }
                                    content={
                                        <VehicleDocuments
                                            vehicleID={item.id}
                                            attachments={item.attachments}
                                            setData={setData}
                                        />
                                    }
                                />
                            ),
                        },
                    ]}
                />
            </Container>
        </CanView>
    )
}

export default Vehicles
