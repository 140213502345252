import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { Funnel } from 'funnel-react';
import SuperField from '@components/forms/SuperField';
import { Segment, Header, Grid } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';

const CandidatesByStage = ({ room }) => {
    const { t } = useTranslation()
    const [data, setData] = useState([])
    const [selectedRoom, setSelectedRoom] = useState(room ? room : "")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function fetchHiringStats(){
            if(selectedRoom !== ""){
                setLoading(true)
                const request = await getRequest(API.CANDIDATE_STAGE_RECRUITMENT_STATS + "?id="+selectedRoom)

                if(request.status === 200){
                    const funnelData = []
                    for (let i = 0; i < request.response.length; i++) {
                        let hiring = request.response[i].archived_recruitment_stages
                        for (let x = 0; x < hiring.length; x++ ){

                            funnelData.push({
                                label: hiring[x].name,
                                count: hiring[x].all_time_candidate_count
                            })
                        }
                    }

                    setData(funnelData)
                }
                setLoading(false)
            }
        }

        fetchHiringStats()
    }, [selectedRoom])


    return (
        <>
            <FlexRow padding="0,5rem">
                <FlexItem>
                    <Header as="h2" textAlign="left" content={ t('candidates_by_stage') }/>
                </FlexItem>
                <FlexItem textAlign="right" basis="50%">
                    <SuperField as="choice"
                        search
                        endpoint={API.HIRING_ROOM + "?query={id, name}"}
                        text={"name"}
                        value={ parseInt(selectedRoom) }
                        onChange={ (e, { value }) => setSelectedRoom(value) }
                    />
                </FlexItem>
            </FlexRow>

            { selectedRoom === "" && <Grid centered> <Header as="h2" content="Select Hiring Room first" textAlign="center"/> </Grid> }
            <Segment basic loading={loading}>
                { !loading &&
                    <>
                        <Funnel
                            labelKey='label'
                            height={252}
                            colors={{
                                graph: [ '#552fe4', '#6b48ff' ],
                                percent: '#FF4C78',
                                label: '#6b48ff',
                                value: '#292929'
                            }}
                            valueKey='count'
                            displayPercent={false}
                            data={data}
                        />
                    </>
                }
            </Segment>
        </>
    );
};

export default CandidatesByStage;