/* USER */
export const addUser = (user) => ({
    type: "ADD_USER",
    user,
});

export const logoutUser = () => ({
    type: "LOGOUT_USER",
});

export const addGlobalPreferences = (payload) => ({
    type: "ADD_GLOBAL_PREFERENCES",
    payload,
});

export const addFilters = (payload) => ({
    type: "ADD_FILTERS",
    payload,
});

export const addLogo = (payload) => ({
    type: "ADD_LOGO",
    payload,
});

export const addJournal = (payload) => ({
    type: "ADD_JOURNAL",
    payload,
});

export const addCompany = (payload) => ({
    type: "ADD_COMPANY",
    payload,
});

export const addLanguage = (payload) => ({
    type: "ADD_LANGUAGE",
    payload,
});

export const addAutoTimesheetApproval = (payload) => ({
    type: "ADD_AUTO_TIMESHEET_APPROVAL",
    payload,
});

export const addAllowQRScanner = (payload) => ({
    type: "ADD_ALLOW_QR_SCANNER",
    payload,
});

export const addAllowSequentialNumberingLogisticRecord = (payload) => ({
    type: "ADD_ALLOW_SEQUENTIAL_NUMBERING_LOGISTIC_RECORD",
    payload,
});

export const addChoices = (choices) => ({
    type: "ADD_CHOICES",
    choices,
});

export const addUserPermissions = (permissions) => ({
    type: "ADD_PERMISSIONS",
    permissions,
});

export const addAllowedModules = (allowedModules) => ({
    type: "ADD_ALLOWED_MODULES",
    allowedModules,
});

export const setSettingsURL = (payload) => ({
    type: "SETTINGS_RETURN_URL",
    payload,
});

export const addInvoiceCurrency = (payload) => ({
    type: "ADD_INVOICE_CURRENCY",
    payload,
});

export const addDateFormat = (payload) => ({
    type: "ADD_DATE_FORMAT",
    payload,
});

export const addTimezoneFormat = (payload) => ({
    type: "ADD_TIMEZONE",
    payload,
});

export const addAllowJumpOverStages = (payload) => ({
    type: "ADD_ALLOW_JUMP_OVER_STAGES",
    payload,
});

export const addAllowEmailNotifications = (payload) => ({
    type: "ADD_ALLOW_EMAIL_NOTIFICATIONS",
    payload,
});

export const addPreferrableWidget = (payload) => ({
    type: "ADD_PREFERRABLE_WIDGET",
    payload,
});

export const addInvoicePairingStatus = (payload) => ({
    type: "ADD_INVOICE_PAIRING_STATUS",
    payload,
});

export const setTheme = (payload) => ({
    type: "SET_THEME",
    payload,
});

export const addCurrentBankAccount = (payload) => ({
    type: "ADD_CURRENT_BANK_ACCOUNT",
    payload,
});

export const addPrefSwitchToCandidate = (payload) => ({
    type: "ADD_SWITCH_TO_CANDIDATE",
    payload
})

export const addAllowedOrderTypes = (payload) => ({
    type: "ADD_ALLOWED_ORDER_TYPES",
    payload
})

export const addDefaultOrderTypes = (payload) => ({
    type: "ADD_DEFAULT_ORDER_TYPES",
    payload
})
