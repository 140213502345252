import React, { useEffect, useState } from 'react';
import UserAvatar from 'react-user-avatar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { projectsService } from '@services/ServiceProjects';
// components
import { Grid, Modal, Icon } from 'semantic-ui-react';
import ProjectGraph from './ProjectGraph';
import AvatarList from '@components/general/AvatarList';
import CanView from '@components/perms/CanView';

const ProjectCard = (props) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [projectStats, setProjectStats] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [members, setMembers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const result = await projectsService.getProjectStats(props.id);

            if (result.status === 200) {
                setProjectStats(result.response);

                let membersArray = [];
                for (let i = 0; i < result.response?.people_on_project.length; i++) {
                    membersArray.push(result.response?.people_on_project[i]?.fullname);
                }
                setMembers(membersArray);
            }
            setIsLoading(false);
        }

        fetchData();
    }, [props.id]);

    let b = null;
    let momento_end = null;
    let momento_now = moment();
    let diff = null;
    if (props.endDate) {
        b = props.endDate.split('-');
        momento_end = moment(b[0] + '-' + b[1] + '-' + b[2]);

        diff = momento_end.diff(momento_now, 'days');
    }

    return (
        <Grid style={styles.container} className="project-card">
            <Grid.Row style={styles.content}>
                <Grid.Column mobile={16} tablet={16} computer={11}>
                    <Grid.Row>
                        <span style={styles.header}>{props.name}</span>
                        <br />
                    </Grid.Row>

                    <Grid.Row style={{ paddingTop: 10 }}>
                        <span style={styles.header}>
                            {props.startDate ? moment(props.startDate).format(props.dateFormat) : ""}
                            {' - '}
                            {props.endDate ? moment(props.endDate).format(props.dateFormat) : ""}
                        </span>
                        <br />
                        {diff && diff <= 0 ? (
                            <span>
                                {diff * -1}
                                {' ' + t('days_out_dated')}
                            </span>
                        ) : (
                            <span>
                                {diff}
                                {' ' + t('days_left')}
                            </span>
                        )}
                    </Grid.Row>
                </Grid.Column>
                <CanView permissions={['projects.c_view_project_stats']}>
                    <Grid.Column
                        className="project-graph"
                        mobile={16}
                        tablet={16}
                        computer={5}
                        style={{ display: 'flex', justifyContent: 'center', paddinggRight: '2rem' }}
                    >
                        {!isLoading && (
                            <ProjectGraph
                                all={projectStats.open_issues_count + projectStats.closed_issues_count}
                                done={projectStats.closed_issues_count}
                                width={100}
                            />
                        )}
                    </Grid.Column>
                </CanView>
            </Grid.Row>
            <Grid.Row style={styles.footer} columns={4}>
                <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={4}
                    style={{
                        fontWeight: 'bolder',
                        padding: 0,
                        marginLeft: '1rem',
                        width: 'auto',
                        fontSize: '1.2rem',
                        textAlign: 'center',
                    }}
                >
                    {props.is_active ? (
                        <>
                            <span style={{ color: 'var(--success)' }}>•</span>
                            {' ' + t('active')}
                        </>
                    ) : (
                        <>
                            <span style={{ color: 'var(--danger)' }}>•</span>
                            {' ' + t('inactive')}
                        </>
                    )}
                </Grid.Column>
                <Grid.Column
                    mobile={8}
                    tablet={8}
                    computer={3}
                    style={{ display: 'flex', justifyContent: 'center', padding: 0, paddingTop: 5 }}
                >
                    {props.project_manager?.fullname && (
                        <>
                            <Icon name="chess king" style={{ marginTop: 5 }} />
                            <UserAvatar size={30} name={props.project_manager?.fullname} color="var(--variant5)" />
                        </>
                    )}
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={3} style={{}}>
                    <AvatarList size={30} members={members} />
                </Grid.Column>
            </Grid.Row>

            <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
                <Modal.Header>{props.name}</Modal.Header>
                <Modal.Content>{props.description}</Modal.Content>
            </Modal>
        </Grid>
    );
};
const light_blue_grey = '#f1f5f8';
const styles = {
    container: {
        borderColor: 'var(--border)',
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 0,
    },
    content: {
        backgroundColor: 'var(--default)',
        margin: 0,
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    footer: {
        borderTopColor: 'var(--border)',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        backgroundColor: light_blue_grey,
        display: 'flex',
        alignItems: 'center',
    },
    header: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        paddingBottom: '0.5rem',
    },
    description: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
};

export default ProjectCard;
