import React from 'react';
import { Header } from 'semantic-ui-react';

const SidebarCategory = (props) => {
    return (
        <Header className="sidebar-category" as="h4">
            <Header.Content>{props.text}</Header.Content>
            <Header.Subheader>{props.shorttext}</Header.Subheader>
        </Header>
    );
};

export default SidebarCategory;