import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//components
import {FlexItem, FlexRow} from '@components/tables/FlexTable';
import Loading from '@components/general/Loading';
import { Grid, Header, Icon, Card, Divider } from 'semantic-ui-react';
import Action from '@components/general/Action';


const BenefitSection = ({ data, setData, patchEndpoint, isManageable }) => {
    const { t } = useTranslation()
    const [benefitsHistory, setBenefitsHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onDelete = async (id) => {
        const request = await requests.patch(patchEndpoint + data.id + "/?query={id, benefits}", {
            benefits: {
                remove: [id]
            }
        });

        if (request.status === 200) {
            setData(prevState => ({
                ...prevState,
                benefits: request.response.benefits
            }))
        }
    }

    useEffect(() => {

        async function fetchData() {
            const request = await requests.get(API.BENEFITS + `assignments/?assignee=${data.id}`)

            if (request.status === 200) {
                setBenefitsHistory(request.response);
            }
            setIsLoading(false);
        };

        fetchData();
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
        <Grid>
            {isLoading ? <Loading /> :
            <>
            {benefitsHistory.length > 0
            ?
            benefitsHistory.map((benefitHistory, index) => (
                <>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Card
                            fluid style={{
                            opacity: benefitHistory.is_archived ? 0.5 : 1,
                            borderBottom: "solid " + (benefitHistory?.benefit?.category?.color || "var(--primary)") + " 0.8rem",
                            marginBottom:"0.5rem" }}
                        >
                            <Grid >
                                <Grid.Row verticalAlign="middle">
                                    <Grid.Column computer={16} tablet={16} mobile={16}>
                                        <FlexRow padding="0.78571429rem" background="transparent">
                                            <FlexItem textAlign="left">
                                                {benefitHistory.is_archived ?
                                                  <span><Icon name="circle" color="grey" /> {t('archived')}</span>
                                                : <span><Icon name="circle" color="green" /> {t('active')} </span>
                                                }
                                            </FlexItem>
                                            <FlexItem textAlign="right">
                                                {benefitHistory.is_archived ?
                                                    ""
                                                :  <Action padding="0" as='delete' tooltip={t('remove')} text={t('delete_benefit')} onClick={() => onDelete(benefitHistory?.benefit?.id)} />}
                                            </FlexItem>
                                        </FlexRow>
                                        <FlexRow padding="0" background="transparent">
                                            <FlexItem textAlign="center">
                                                <Icon size="huge" name={benefitHistory?.benefit?.icon ? benefitHistory?.benefit?.icon : "heartbeat"} style={{ margin: "1rem", color: `${benefitHistory?.benefit?.category?.color ? benefitHistory?.benefit?.category?.color : "var(--primary)"}` }} /> <br />
                                                <strong>{benefitHistory?.benefit?.name} </strong>
                                            </FlexItem>
                                        </FlexRow>
                                        <FlexRow padding="0" background="transparent">
                                            <FlexItem textAlign="center">
                                                <br />
                                                {t('valid_from')}: {benefitHistory?.valid_until ? benefitHistory?.valid_until: t('undefined')} <br />
                                                {t('assigned_at')}: {benefitHistory?.assigned_on} <br />
                                                {t('assigned_by')}: {benefitHistory?.assigner?.profile ? benefitHistory?.assigner?.profile?.name : benefitHistory?.assigner?.name}
                                                <br />
                                            </FlexItem>
                                        </FlexRow>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card>
                    </Grid.Column>
                </>
            ))  :

            <Grid.Column textAlign="center" computer="16" tablet="16" mobile="16">
                <Header as="h4">{t('nothing')}</Header>
            </Grid.Column>}
        </>}
        </Grid>
        <Divider />
        </>

    );
};

export default BenefitSection;