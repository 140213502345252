import request from 'umi-request'
import Cookies from 'js-cookie'
import {
    addChoices,
    addLanguage,
    addUser,
    logoutUser,
    addUserPermissions,
    addAllowedModules,
    addCompany,
    addDateFormat,
    addTimezoneFormat,
    addPrefSwitchToCandidate,
    addAutoTimesheetApproval,
    addAllowJumpOverStages,
    addAllowEmailNotifications,
    addAllowQRScanner,
    addAllowedOrderTypes,
    addDefaultOrderTypes,
} from '../actions'
import { APIRequest, requests } from './helpers/requests'
import { API } from '../config'
import { userService } from './ServiceUser'
import { commonService, getRequest } from './ServiceCommon'

/*
 * Service functions
 */
export const authService = {
    login,
    logout,
    refreshLogin,
    register,
    resendActivation,
    getAccessToken,
    validateToken,
    getUserPermissions,
    getAllowedModules,
}

function setAuthTokens(response) {
    let tmpExpiration = response.expiresIn
    let expiresIn = new Date(new Date().getTime() + tmpExpiration * 1000)

    if (document.location.protocol === 'https:') {
        Cookies.set('accessToken', response.access, {
            expires: expiresIn,
            secure: true,
        })
        Cookies.set('refreshToken', response.refresh, { secure: true })
    } else {
        Cookies.set('accessToken', response.access, { expires: expiresIn })
        Cookies.set('refreshToken', response.refresh)
    }
}

/* Auth Services */
async function getUserPermissions() {
    let result = await APIRequest({
        url: API.USERS_ME + 'permissions/',
        method: 'GET',
        private: true,
    })

    return result
}

async function getAllowedModules() {
    let result = await APIRequest({
        url: API.CONFIG + 'allowed_modules/',
        method: 'GET',
        private: true,
    })

    return result
}

async function login(username, password, dispatch, i18n) {
    let result = await APIRequest({
        url: API.LOGIN,
        method: 'POST',
        data: {
            username: username,
            password: password,
        },
        private: true,
    })

    if (result.status === 200) {
        // OK
        setAuthTokens(result.response)
        const userMe = await userService.getUserProfile()
        if (userMe.status === 200) {
            dispatch(addUser(userMe.response))
            result['role'] = userMe.response.role
        } else {
            dispatch(addUser(result.response.user))
        }

        const permissions = await getUserPermissions()
        if (permissions.status === 200) {
            dispatch(addUserPermissions(permissions.response))

            if (permissions.response.includes('users.c_terminal_warehouse')) {
                result['can_access_warehouse'] = true
            }
        }

        const allowedModules = await getAllowedModules()
        if (allowedModules.status === 200) {
            dispatch(addAllowedModules(allowedModules.response))
        }

        const language = await userService.getPreference('language__user_language_preferences')
        if (language.status === 200) {
            let fetchedLanguage = language.response.value
            if (fetchedLanguage === 'en-us') {
                fetchedLanguage = 'en'
            } else if (fetchedLanguage === 'cs') {
                fetchedLanguage = 'cz'
            }
            dispatch(addLanguage(fetchedLanguage))
            i18n.changeLanguage(fetchedLanguage)
        }

        const date_format = await requests.get(API.PREFERENCES + 'date_format__global_date_format_preferences/')
        if (date_format.status === 200) {
            dispatch(addDateFormat(date_format.response.value))
        }

        const order_types = await requests.get(API.PREFERENCES + 'display_orders__display_orders/')
        if (order_types.status === 200) {
            dispatch(addAllowedOrderTypes(order_types.response.value))
        }

        const order_default_types = await requests.get(API.PREFERENCES + 'display_orders__display_type_orders/')
        if (order_default_types.status === 200) {
            dispatch(addDefaultOrderTypes(order_default_types.response.value))
        }

        const allow_qr_scanner = await requests.get(API.PREFERENCES + 'allow_to_scan_qr_codes__allow_to_scan_qr_codes/')
        if (allow_qr_scanner.status === 200) {
            dispatch(addAllowQRScanner(allow_qr_scanner.response.value))
        }

        const timezone_format = await requests.get(API.USER_PREFERENCES + 'timezone__timezone/')
        if (timezone_format.status === 200) {
            dispatch(addTimezoneFormat(timezone_format.response.value))
        }

        const allowEmailNotifications = await requests.get(
            API.USER_PREFERENCES + 'allow_sending_email_notification__allow_sending_email_notification/'
        )
        if (allowEmailNotifications.status === 200) {
            dispatch(addAllowEmailNotifications(allowEmailNotifications.response.value))
        }

        const switch_to_employee = await requests.get(
            API.PREFERENCES + 'switch_employee_to_candidate__switch_employee_to_candidate/'
        )
        if (switch_to_employee.status === 200) {
            dispatch(addPrefSwitchToCandidate(switch_to_employee.response.value))
        }

        const auto_timesheet_approval = await requests.get(
            API.PREFERENCES + 'approve_record_automatically__approve_timesheet_record_automatically/'
        )
        if (auto_timesheet_approval.status === 200) {
            dispatch(addAutoTimesheetApproval(auto_timesheet_approval.response.value))
        }

        const allowJumpOverStages = await requests.get(API.PREFERENCES + 'candidates__allow_jump_over_stages/')
        if (allowJumpOverStages.status === 200) {
            dispatch(addAllowJumpOverStages(allowJumpOverStages.response.value))
        }

        const request = await getRequest(API.COMPANIES + '?is_active=true&query={id, logo, name}')
        if (request.status === 200) {
            if (request.response.length > 0) {
                dispatch(addCompany(request.response[0]))
            }
        }

        const choices = await commonService.getChoices()
        if (choices.status === 200) {
            dispatch(addChoices(choices.response))
        }
    }

    return result
}

async function refreshLogin(refreshToken) {
    // Extend user session based on refreshToken if exist else redirect user to login
    let result = await request
        .post(API.REFRESH, {
            data: {
                refresh: refreshToken,
            },
        })
        .then(function (response) {
            // store OLD Access & Refresh Token from Cookies,
            Cookies.remove('accessToken')
            Cookies.remove('refreshToken')

            setAuthTokens(response)

            return true
        })
        .catch(function (error) {
            return false
        })

    return result
}

function logout(dispatch) {
    // Perform user logout and remove user from redux store and cookies
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    dispatch(logoutUser())
}

async function validateToken() {
    const token = Cookies.get('accessToken')
    let response = {}

    if (token) {
        response = await APIRequest({
            url: API.VERIFY,
            method: 'POST',
            data: {
                token: token,
            },
        })
    }

    if (response && response.status === 200) {
        return token
    } else {
        const refresh = Cookies.get('refreshToken')
        // attempt to refreshLogin with refresh token, if true then return new access token
        if (refresh) {
            const result = await refreshLogin(refresh)
            if (result) {
                return Cookies.get('accessToken')
            }
        }
        return false
    }
}

async function getAccessToken() {
    return await validateToken()
}

/* Register */
async function register(data) {
    let created
    created = await request(API.REGISTER, {
        method: 'post',
        data: data,
    })
        .then(function (response) {
            return true
        })
        .catch(function (error) {
            return false
        })

    return created
}

async function resendActivation(data) {
    let sended
    sended = await request(API.RESEND_ACTIVATION, {
        method: 'post',
        data: data,
    })
        .then(function (response) {
            return true
        })
        .catch(function (error) {
            return false
        })

    return sended
}

export async function checkUser() {
    const result = await authService.getAccessToken()
    return !result ? false : true
}
