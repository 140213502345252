import { APIRequest } from './helpers/requests';
import { API } from '../config';


/*
 * Service functions
 */
export const userService = {
    getUserProfile,
    getPreference,
    setPreference,
    getUsers
};

// getUserProfile:
async function getUserProfile() {
    return await APIRequest({
        url: API.GET_USER_ME, // .../api/users/me/
        method: 'GET',
        data: {},
        private: true
    });
}

async function getPreference(preference) {
    return await APIRequest({
        url: API.USER_PREFERENCES + preference + "/",
        method: 'GET',
        data: {},
        private: true
    });
}

// setPreferences:
async function setPreference(preference, value) {
    return await APIRequest({
        url: API.USER_PREFERENCES + preference + "/",
        method: 'PUT',
        data: {
            value: value
        },
        private: true
    });
}

async function getUsers(query_params) {
    query_params = query_params || "";
    return await APIRequest({
        url: API.USERS + query_params,
        method: 'GET',
        data: {},
        private: true
    });
}
