import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Header, Divider } from 'semantic-ui-react';

const ImportInvalidReport = ({ errors, source }) => {
    const { t } = useTranslation()

    return (
        <>
            { errors.map(row => (
                <>
                    <Header as="h3">{source === 1 ? t('invalid_row') : t('invalid_invoice')}: { source === 1 ? (row?.invalid_row || row?.row) : row?.number }</Header>

                    { source === 1 && 
                        <>
                            { row?.errors?.map(error => (
                                <>
                                    { typeof error === 'object' 
                                        ? 
                                        <>
                                        { Object.keys(error).map(key => (
                                            <>
                                                <strong> {key}: </strong> <br/>
                                                <ul>
                                                    { error[key].map(message => (
                                                        <li> { message } </li>
                                                    )) }
                                                </ul>
                                            </>
                                        )) }
                                        </>
                                        :
                                        <>
                                            <li style={{ marginLeft: "1.5rem" }}> { error } <br/></li>
                                        </>
                                    }
                                </>
                            )) }
                        </>
                    }

                    { source !== 1 && 
                        <>
                            { typeof row?.errors === 'object' && 
                                <>
                                    { Object.keys(row?.errors).map(key => (
                                        <>
                                            <strong> {key}: </strong> <br/>
                                            <ul>
                                                { row?.errors[key].map(message => (
                                                    <li> { message } </li>
                                                )) }
                                            </ul>
                                        </>
                                    )) }
                                </>
                            }
                        </>
                    }

                    <Divider/>
                </>
            )) }
        </>
    );
};

export default ImportInvalidReport;