import React from 'react';

const NewLabel = ({ name, color, outline, style }) => {
    const dot = "0.8rem"
    return (
        <span style={style}>
            <span style={{
                display: 'inline-flex',
                alignItems: 'center',
                background: outline ? 'transparent' : 'var(--variant5)',
                padding: '0.25rem 0.5rem',
                margin: '0.1rem',
                border: outline ? '1.5px solid var(--variant4)' : 'none',
                style
            }}>
                {color &&
                    <span className='circular-label' style={{ background: color, marginRight: '0.5rem', width: dot, height: dot }} />}
                {name}
            </span>
        </span>

    )
}


export default NewLabel;