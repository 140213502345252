import React from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { icons } from '@icons';
// components
import BreadcrumbNav from '@components/BreadcrumbNav';
import CanView from '@components/perms/CanView';
// module specific
import ContactsListing from './ContactsListing';

const Contacts = () => {
    const { t } = useTranslation()
    const breadcrumb_items = [
        { 'name': t("contacts"), 'icon': icons.ORDERS }
    ]

    return (
        <CanView permissions={['contacts.c_view_contacts']}>
            <BreadcrumbNav items={breadcrumb_items} />
            <ContactsListing/>
        </CanView>
    );
};

export default Contacts;