import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from "@store/config";
import { routes } from '@store/routes';
import { requests } from '@helpers/requests';
//components
import Icon from '@components/Icon';
import AvatarIcon from '@components/AvatarIcon';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import Phone from '@components/general/Phone';
import ContactForm from '../components/ContactForm'
import LabelsList from '@components/lists/LabelsList';
import SuperField from '@components/forms/SuperField';
import ListView from '@components/ListView';

const ContactsListing = ({ openDetail }) => {
    const { t } = useTranslation()

    function getColor(type) {
        switch (type) {
            case 1: return 'var(--variant3)'
            case 2: return 'var(--primary)'
            case 3: return 'var(--blue)'
            case 4: return 'var(--info)'
            default: return 'var(--black)'
        }
    }

    const onAdd = (response, setData) => {
        setData(prev => [response, ...prev])
    }

    const onUpdate = (response, setData) => {
        setData(prev => prev.map(item => {
            if (item.id === response.id) {
                item = response
            }
            return item;
        }))
    }

    async function convertContact(id, contact_type, setData) {
        const request = await requests.post(`${API.CONTACTS}${id}/convert/?query={id}`)
        if (request.status === 200) {
            if (contact_type === 2 || contact_type === 3) {
                setData(prev => prev.filter(contact => {
                    if (contact.id === id) {
                        contact.profile = request.response.id
                    }
                    return contact;
                }))
            }
            if (contact_type === 4) {
                setData(prev => prev.filter(contact => {
                    if (contact.id === id) {
                        contact.account = request.response.id
                    }
                    return contact;
                }))
            }
        }
    }

    return (
        <ListView
            as="table"
            // allowSearch
            endpoint={API.CONTACTS}
            initialFilters={{
                contact_type: "",
                roles_label: []
            }}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField as="choice"
                        label={t('type')}
                        value={filters.contact_type}
                        onChange={(e, { value }) => setFilters({
                            ...filters,
                            contact_type: value
                        })}
                        customOptions={[
                            { key: 2, value: 2, text: t('candidates') },
                            { key: 3, value: 3, text: t('employees') },
                            { key: 4, value: 4, text: t('accounts') },
                            { key: 1, value: 1, text: t('custom') },
                        ]}
                    />
                    <SuperField as="choice"
                        multiple
                        search
                        label={t('labels')}
                        value={filters.roles_label}
                        onChange={(e, { value }) => setFilters({
                            ...filters,
                            roles_label: value
                        })}
                        endpoint={API.CONTACT_LABELS}
                        text="name"
                    />
                </>
            )}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    modalSize: "small",
                    name: t('add_contact'),
                    modal: <ContactForm onConfirm={(response, setData) => onAdd(response, setData)} />,
                    permissions: ['contacts.c_manage_contacts'],
                },
                {
                    as: "link",
                    type: "custom",
                    icon: "id-card-outline",
                    customIconColor: "var(--blue)",
                    redirect: (item) =>
                        item.contact_type !== 1 && item.profile !== null && item.contact_type === 2
                            ? routes.CANDIDATES_CARD + item.profile
                            : routes.EMPLYOEES_DETAIL + item.profile,
                    permissions: ['contacts.c_manage_contacts'],
                    isHidden: (item) => item.contact_type === 1 || item.profile === null || (item.contact_type !== 2 && item.contact_type !== 3)
                },
                {
                    as: "confirm",
                    type: "custom",
                    icon: "log-out-outline",
                    customIconColor: "var(--blue)",
                    text: t("contact_convert_popup"),
                    onClick: (item) => convertContact(item.id, item.contact_type),
                    permissions: ['contacts.c_manage_contacts'],
                    isHidden: (item) => (item.contact_type !== 2 || item.contact_type !== 4)
                },
                {
                    as: "link",
                    type: "custom",
                    icon: "id-card-outline",
                    customIconColor: "var(--blue)",
                    redirect: (item) => routes.ACCOUNTS_DETAIL + item.account,
                    permissions: ['contacts.c_manage_contacts'],
                    isHidden: (item) => item.account === null || item.contact_type !== 4
                },
                {
                    as: "modal",
                    type: "edit",
                    name: t('edit'),
                    modalSize: "small",
                    permissions: ['contacts.c_manage_contacts'],
                    modal: (item, setData) => 
                        <ContactForm
                            contact={item}
                            setData={setData}
                            onConfirm={(response, setData) => onUpdate(response, setData)}
                        />
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('delete_text_contacts'),
                    permissions: ['contacts.c_delete_contacts'],
                },
            ]}
            tableHeaders={[
                { title: t('name') },
                { title: t('gender') },
                { title: t('contact') },
                { title: t('labels') },
            ]}
            renderCells={(contact, setData) => ([
                {
                    content:
                        <>
                            <FlexItem basis="80%">
                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                    <FlexItem basis="10%">
                                        <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                            { contact.contact_type === 4 &&
                                                <AvatarIcon size={30} name={contact.contact_person.firstname + " " + contact.contact_person.lastname}/>
                                            }

                                            { contact.contact_type !== 4 && 
                                                <AvatarIcon size={30} name={contact.person.firstname + " " + contact.person.lastname} src={contact.profile_picture} />
                                            }
                                        </SemanticIcon.Group>
                                    </FlexItem>
                                    <FlexItem>
                                        { contact.contact_type === 4 
                                            ? <strong>{contact.contact_person.firstname + " " + contact.contact_person.lastname}</strong>
                                            : <strong>{contact.person.firstname + " " + contact.person.lastname}</strong>
                                        }
                                        <br />
                                        <strong style={{ color: getColor(contact.contact_type) }}>
                                            {contact.contact_type_display}
                                        </strong>
                                    </FlexItem>
                                </FlexRow>
                            </FlexItem>
                        </>
                },
                { content: contact.person?.gender_display },
                {
                    content:
                        <>
                            {contact?.contact_info?.phones[0]?.phone && <><Phone phone={contact.contact_info.phones[0]?.phone} /><br /></>}
                            {contact?.contact_info?.emails[0]?.email && <a href={"mailto:" + contact.contact_info.emails[0]?.email}> <Icon name="mail-outline" style={{ marginRight: "0.5rem" }} /> <span style={{ position: "relative", top: "-0.2rem" }}>{contact.contact_info.emails[0]?.email}</span> </a>}
                        </>
                },
                {
                    content:
                        <LabelsList
                            rowID={contact?.id}
                            labels={contact?.roles_label}
                            resourcetype={contact.resourcetype}
                            endpoint={API.CONTACTS}
                            label_key="roles_label"
                        />
                },
            ])}
        />

    )
}

export default ContactsListing
