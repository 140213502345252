import React, { useState } from 'react';
import UserAvatar from 'react-user-avatar';

const AvatarList = ({size, members}) => {
    const [avatarSize] = useState(size ? size : 30)

    if (members.length === 0) {
        return null
    } else if (members.length === 3) {
        return <>
            <UserAvatar className="avatar-project-row first" size={avatarSize} name={members[0]} color='var(--blue)'/>
            <UserAvatar className="avatar-project-row second" size={avatarSize} name={members[1]} color='var(--primary)'/>
            <UserAvatar className="avatar-project-row third" size={avatarSize} name={members[2]} color='var(--warning)'/>
        </>
    } else if (members.length === 2) {
        return <>
            <UserAvatar className="avatar-project-row first" size={avatarSize} name={members[0]} color='var(--blue)'/>
            <UserAvatar className="avatar-project-row second" size={avatarSize} name={members[1]} color='var(--primary)'/>
        </>
    } else if (members.length === 1) {
        return <>
            <UserAvatar className="avatar-project-row first" size={avatarSize} name={members[0]} color='var(--blue)'/>
        </>
    } else {
        return <>
            <UserAvatar className="avatar-project-row first" size={avatarSize} name={members[0]} color='var(--blue)'/>
            <UserAvatar className="avatar-project-row second" size={avatarSize} name={members[1]} color='var(--primary)'/>
            <UserAvatar className="avatar-project-row third" size={avatarSize} name={members[2]} color='var(--warning)'/>
            <UserAvatar className="avatar-project-row number" size={avatarSize} name={members.length.toString()} color='var(--default)' />
        </>
    }
};

export default AvatarList;