import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Segment, Grid, Header, Label } from 'semantic-ui-react';


const ChooseWorkingContract = ({ contracts, handleAction, setContract, onClose, displayWorkplace }) => {
    const { t } = useTranslation()

    const onSelection = (contract) => {
        setContract(contract)
        handleAction("start", contract)
    }
    
    return (
        <Grid>
            <Grid.Row columns="1">
                <Grid.Column centered>
                    <Header as="h3" content={t('select_workplace')} style={{ textAlign: "center" }}/>
                </Grid.Column>
            </Grid.Row>
            { contracts.map(contract => (
                <Grid.Row columns="1">
                    <Grid.Column onClick={() => onSelection(contract)}>
                        <Segment className="choose-workplace" style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <div>
                                <strong>{ contract.employer.name } - { displayWorkplace(contract) } </strong> <br/>
                                <span>{ contract.work_position.title }</span>

                                <br/>
                                <span style={{ opacity: "0.5" }}>{t('click_to_select_this_option')}</span>
                            </div>
                            <div>
                                { contract.status === 1 && 
                                    <Label style={{ background: "green", color: "var(--white)" }}>{t('active')}</Label>
                                }
                                { contract.status === 3 && 
                                    <Label style={{ background: "var(--blue)", color: "var(--white)", textAlign: "center" }}>
                                        {t('active') + " - " + t('in_notice_period')} 
                                    </Label>
                                }
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            )) }
        </Grid>
    );
};

export default ChooseWorkingContract;