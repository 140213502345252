import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider, Header, Icon as SemanticIcon } from 'semantic-ui-react';

const SignatureForm = ({ id, onUpload, onClose }) => {
    const { t } = useTranslation()

    const [file, setFile] = useState([])
    const [errors, setErrors] = useState(null)
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        const formData = new FormData()
        formData.append("signature", file[0])
        
        const request = await requests.post(API.ACCOUNTS + "business_details/" + id + "/signature/", formData)

        if (request.status === 400) setErrors(request.response)
        if (request.status === 200) {
            onUpload(request.response)
            onClose()
        }

        setProcessing(false)
    }

    const hiddenFileInput = React.useRef(null)
    const handleClick = event => {
        hiddenFileInput.current.click();
    }

    const handleFileInputChange = (event) => {
        const selectedFiles = event.target.files;
    
        const newFiles = Array.from(selectedFiles).filter(file => file.size <= 2 * 1024 * 1024)
    
        if (newFiles.length > 0) {
          setFile([...newFiles]);
        } else {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('Please select files with a size of 2 MB or less.'),
                description: "",
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors}/>
            <Form.Field>
                <div style={{ paddingLeft: "0.5rem" }}>
                    <input
                        accept='image/*'
                        type="file"
                        multiple
                        id="fileInput"
                        ref={hiddenFileInput}
                        onChange={handleFileInputChange}
                        style={{ display: "none" }}
                    />
                    <div 
                        style={{ 
                            padding: "0.7rem",
                            cursor: "pointer",
                            border: "1px dotted var(--dark)",
                        }}
                        onClick={handleClick}
                    >
                        <Header as="h4" style={{ marginTop: 0, textAlign: "center" }}>
                            <div>
                                <SemanticIcon name="upload" size='tiny' style={{ color: "var(--primary)", fontSize: "25px" }} />
                            </div>
                            <div style={{ fontSize: "17px", marginTop: "0.2rem" }}>
                                { file.length > 0 ? file.length + " " + t('file_selected') :  t('upload_file')}
                            </div>
                            <Header.Subheader content={ t('2mb_limit_per_file') + " - " + t('only_images_are_allowed')} />
                        </Header>
                    </div>
                </div>
                { errors?.signature?.[0] && 
                    <p style={{ color: "var(--danger)" }}> { errors?.signature?.[0] } </p>
                }
            </Form.Field>
            

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    loading={processing}
                    disabled={processing || file.length === 0}
                    content={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default SignatureForm;