import React from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import Loading from '@components/general/Loading';
import EmptyRow from '@components/tables/EmptyRow';
import { Divider, Grid, Header, Container } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import CanView from '@components/perms/CanView';
// module specific
import PermissionGroup from './PermissionGroup';
import PermissionGroupForm from './PermissionGroupForm';


const CustomPermissions = () => {
    const { t } = useTranslation();
    const [permissions] = useFetchData(API.PERMISSIONS)
    // eslint-disable-next-line
    const [groups, setGroups] = useFetchData(API.PERMISSION_GROUPS)

    const onSubmit = (response, action) => {
        if (action === "create") {
            setGroups(prevState => {
                return {
                    ...prevState,
                    data: [response, ...prevState.data]
                }
            })
        } else if (action === "update") {
            setGroups(prevState => {
                return {
                    ...prevState,
                    data: prevState.data.filter(group => {
                        if (group.id === response.id) {
                            group.name = response.name
                        }
                        return group
                    })
                }
            })
        }
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.PERMISSION_GROUPS + id + "/")
        if (request.status === 204) {
            setGroups(prevState => {
                return {
                    ...prevState,
                    data: prevState.data.filter(group => group.id !== id)
                }
            })
        }
    }

    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            <CanView permissions={['users.c_view_user_permissions']} redirect>
                <Grid>
                    <Grid.Row columns={2} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Grid.Column textAlign='left' style={{ paddingBottom: 0, paddingTop: "1rem" }}>
                            <Header as="h3">{t('permissions')}</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right' style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <SuperDuperModal
                                trigger={
                                    <OutlineAddButton
                                        size="small"
                                        color="violet"
                                        text={"Create permission group"}
                                    />
                                }
                                content={
                                    <PermissionGroupForm onSubmit={onSubmit} />
                                }
                            />

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider />
                {permissions.isLoading || groups.isLoading
                    ? <Loading />
                    :
                    <>
                        <EmptyRow length={groups.data.length} />

                        {groups.data.map(group => (
                            <PermissionGroup
                                key={group.id}
                                group={group}
                                permissions={permissions}
                                onDelete={onDelete}
                                onUpdate={onSubmit}
                            />
                        ))}
                    </>
                }
            </CanView>
        </Container>
    );
};

export default CustomPermissions;