
import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { requests } from '@helpers/requests';
import { tzDateTime } from '@helpers/dates';
import { routes } from '@routes';
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import { Button, Grid, Divider, Label, Form, Header, Message } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import LabelsForm from '@components/forms/LabelsForm';
import Action from '@components/general/Action';
import AvatarIcon from '@components/AvatarIcon';
import CustomFormDivider from '@components/general/CustomFormDivider';
import FilesTab from '@components/documents/FilesTab';
import { 
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    // BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    // BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    EditorProvider,
    Editor,
} from 'react-simple-wysiwyg';

import AssignMemberToIssue from '../forms/AssignMemberToIssue';


const IssueDetail = ({ issue, issues, setIssues, onLabelsAdd, onLabelRemove, readOnly, setReadOnly }) => {
    const dateFormat = useSelector(state => state.date_format)

    const { t } = useTranslation()
    const [errors, setErrors] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        name: issue?.name || null,
        description: issue?.description || null,
        milestone: issue?.milestone?.id || null,
        due_date: issue?.due_date || null,
        state: issue?.state || null,
        estimated_time: issue?.estimated_time || null,
        time_spent: issue?.time_spent || null,
        // group: issue?.group || null,
        // stage: issue?.stage || null,
        milestone_name: issue?.milestone?.name || null,
    })

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true);

        const result = await requests.patch(API.PROJECT_ISSUES + issue?.id + "/", form)
        if (result.status === 200) {
            setIssues(prevState => ({
                data: {
                    ...prevState.data,
                    results: prevState.data?.results?.map(item => {
                        if (item.id === issue.id) {
                            item = result?.response
                        }

                        return item
                    })
                }
            }))

            setReadOnly(!readOnly)
        } else if (result.status === 400) {
            setErrors(result.response)
        }
        setIsProcessing(false)

    }

    const onAssigneeAdd = (id, assigned) => {
        setIssues(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                results: issues.data.results.filter(issue => {
                    if (issue?.id === id) {
                        issue.assigned = assigned
                    }
                    return issue;
                })
            }
        }))
    }

    const onAssigneeRemove = async (id, assignedID) => {

        const request = await requests.patch(API.PROJECT_ISSUES + id + "/?query={id, assigned}", {
            assigned: {
                remove: [assignedID]
            }
        });

        if (request.status === 200) {
            setIssues(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: issues?.data?.results?.filter(issue => {
                        if (issue?.id === id) {
                            issue.assigned = request.response.assigned
                        }
                        return issue;
                    })
                }
            }))
        }

    }

    const PutAvatar = ({ user }) => {
        const [iconDisplay, setIconDisplay] = useState(false);

        return (
            <Grid style={{ padding: 0 }} >
                <Grid.Row
                    style={{ padding: 0 }}
                    onMouseEnter={() => setIconDisplay(true)}
                    onMouseLeave={() => setIconDisplay(false)}
                >
                    <Grid.Column style={{ padding: 0 }} computer={4} table={4} mobile={6}>
                        {iconDisplay === false ?
                            <AvatarIcon
                                size={34}
                                name={user?.fullname}
                                src={user?.profile_picture}
                            />
                            :
                            <Button
                                circular
                                size="small"
                                icon={<Icon name="close-outline" style={{ color: "var(--danger)" }} />}
                                style={{ marginLeft: '4px' }}
                                onClick={() => onAssigneeRemove(issue.id, user.id)}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0 }} computer={12} table={12} mobile={10}>
                        <span style={{ display: "flex", flexDirection: "column", paddingTop: "0.5rem" }}>
                            {user?.id ?
                                <Link to={routes.EMPLYOEES_DETAIL + user?.id} target="_blank">
                                    <strong>{user?.fullname}</strong>
                                </Link> :
                                <strong>{user?.fullname}</strong>
                            }

                        </span>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    return (
        <>
            <Form>
                <Message
                    error
                    visible={errors?.non_field_errors?.length > 0}
                    header={t("error_submission_message")}
                    list={errors?.non_field_errors}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column mobile={10} tablet={10} computer={10}>
                            <span>
                                <Icon name="ellipse" style={{ color: issue.state === 1 ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }} />
                                <strong style={{ position: "relative", top: "-0.1rem" }}> {issue.state === 1 ? t("open") : t("closed")} </strong>
                                {readOnly ?
                                    <h2 style={{ marginTop: "0px" }}> #{issue.number} {form.name}</h2>
                                    :
                                    <SuperField as="input"
                                        style={{ display: "flex", marginBottom: "0.5rem", marginTop: "0.5rem" }}
                                        value={form.name}
                                        readOnly={readOnly}
                                        error={errors === null ? false : errors?.name}
                                        onChange={(e, { value }) =>
                                            setForm({ ...form, name: value })
                                        }
                                    />}
                            </span>
                            <p>
                                {t('created_by')}
                                <Link to={routes.EMPLYOEES_DETAIL + issue?.created_by?.profile_id} target="_blank">
                                    <strong> {issue.created_by?.name}</strong>
                                </Link> {t('at')} <strong>{tzDateTime(issue.created_on).format(dateFormat + " HH:mm")}</strong>
                            </p>
                        </Grid.Column>
                        <Grid.Column mobile={5} tablet={5} computer={5}>
                            {readOnly ?
                                <>
                                    <div style={{ margin: '3px', padding: '3px', marginTop: "2rem" }}>
                                        {t('due_date')}: <strong>{form.due_date ? moment(form.due_date).format(dateFormat) : "--"}</strong>
                                    </div>
                                </> :
                                <Form.Group widths="equal">
                                    <SuperField as="datepicker"
                                        label={t('due_date')}
                                        value={form.due_date}
                                        readOnly={readOnly}
                                        error={errors === null ? false : errors?.due_date}
                                        onChange={(e, { value }) => {
                                            value === "" || value === null ?
                                            setForm({ ...form, due_date: null }) :
                                            setForm({ ...form, due_date: value })
                                        }}
                                    />
                                </Form.Group>

                            }
                        </Grid.Column>
                        <Grid.Column mobile={1} tablet={1} computer={1}>
                            <div style={{ marginTop: "1rem" }}>
                                {readOnly ?
                                    <Action
                                        as="custom"
                                        type="icon"
                                        size="huge"
                                        icon="pencil-outline"
                                        iconColor="black"
                                        popupText={t('edit')}
                                        tooltip={t('edit')}
                                        disabled={isProcessing}
                                        onClick={() => setReadOnly(!readOnly)}
                                    /> :
                                    <Action
                                        as="custom"
                                        type="icon"
                                        size="huge"
                                        icon="save-outline"
                                        iconColor="black"
                                        popupText={t('save')}
                                        tooltip={t('save')}
                                        disabled={isProcessing}
                                        onClick={() => handleSubmit()}
                                    />
                                }
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row>
                        <Grid.Column mobile={13} tablet={13} computer={13}>
                            <Grid.Row>

                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    { readOnly 
                                        ? 
                                        <>
                                            <label style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{ t('description') }</label>
                                            <p style={{ padding: "0.5rem", marginTop: "0.5rem", border: "1px solid var(--light)", minHeight: "250px" }} dangerouslySetInnerHTML={{__html: form?.description || ""}}></p>
                                        </>
                                        : 
                                        <Form.Field>
                                            <label style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>{ t('description') }</label>
                                            <EditorProvider>
                                                <Editor 
                                                    containerProps={{ style: { resize: 'vertical', width: '100%', background: "white", minHeight: "250px" } }}
                                                    value={form.description}
                                                    onChange={(e) => setForm(prev => ({...prev, description: e.target.value}))}
                                                >
                                                    <Toolbar>
                                                        <BtnUndo />
                                                        <BtnRedo />
                                                        <Separator />
                                                        <BtnBold />
                                                        {/* <BtnItalic /> */}
                                                        <BtnUnderline />
                                                        {/* <BtnStrikeThrough /> */}
                                                        <Separator />
                                                        <BtnNumberedList />
                                                        <BtnBulletList />
                                                        <Separator />
                                                        <BtnLink />
                                                        <Separator />
                                                        <BtnClearFormatting />
                                                        <HtmlButton />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        </Form.Field>
                                    }
                                    {/* <SuperField as="textarea"
                                        rows='10'
                                        label={<h4>{t('description')}</h4>}
                                        value={form.description}
                                        readOnly={readOnly}
                                        error={errors === null ? false : errors?.description}
                                        onChange={(e, { value }) =>
                                            setForm({ ...form, description: value })
                                        }
                                    /> */}

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <CustomFormDivider
                                        header={t('analytic_information')}
                                        divider={true}
                                        content={
                                            <>
                                                <Grid.Row>

                                                    <Form.Group widths="3">
                                                        <SuperField
                                                            as="input"
                                                            label={<h4>{t('estimated_time')}</h4>}
                                                            value={form.estimated_time}
                                                            error={errors === null ? false : errors?.estimated_time}
                                                            readOnly={readOnly}
                                                            onChange={(e, { value }) => {
                                                                value === "" || value === null ?
                                                                setForm({ ...form, estimated_time: null }) :
                                                                setForm({ ...form, estimated_time: value })
                                                            }}
                                                        />
                                                        <SuperField as="input"
                                                            label={<h4>{t('time_spent')}</h4>}
                                                            value={form.time_spent}
                                                            readOnly={readOnly}
                                                            error={errors === null ? false : errors?.time_spent}
                                                            onChange={(e, { value }) => {
                                                                value === "" || value === null ?
                                                                setForm({ ...form, time_spent: null }) :
                                                                setForm({ ...form, time_spent: value })
                                                            }}
                                                        />

                                                        <SuperField as="choice"
                                                            search={!readOnly}
                                                            disabled={readOnly}
                                                            closeable={!readOnly}
                                                            label={<h4 style={{ marginBottom: '1rem' }}>{t('milestone')}</h4>}
                                                            text="name"
                                                            className={readOnly && "milestone-choice"}

                                                            value={form?.milestone}
                                                            onChange={(e, { value }) => {
                                                                setForm({ ...form, milestone: value })
                                                            }

                                                            }
                                                            error={errors === null ? false : errors?.milestone}
                                                            endpoint={API.PROJECT_MILESTONES + `?project=${issue?.project?.id}`}
                                                        />


                                                    </Form.Group>
                                                </Grid.Row>

                                            </>
                                        }
                                    />


                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <FilesTab
                                        id={issue.id}
                                        module={'project'}
                                        updateEndpoint={API.PROJECT_ISSUES}
                                        getEndpoint={API.PROJECT_ISSUES}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {/* No time for this one .... TODO after groups and processes */}
                            {/* <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                <CustomFormDivider
                                    header = {t('subtasks')}
                                    divider = {true}
                                    content = {
                                        <>
                                            - issue 1 <br />
                                            - issue 2 <br />
                                                - - issue 2.1 <br />
                                            - issue 3 <br />
                                        </>
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row> */}
                        </Grid.Column>
                        <Grid.Column mobile={3} tablet={3} computer={3}>
                            <CustomFormDivider
                                header={
                                    <SuperDuperModal
                                        trigger={
                                            <Header as='h3'>
                                                <Header.Content>
                                                    <span style={{ position: "relative", top: "-0.1rem" }}>
                                                        {t('assigned_users')}
                                                    </span>
                                                    <Icon name='add-outline' style={{ marginLeft: '0.5rem' }} />
                                                </Header.Content>
                                            </Header>
                                        }
                                        content={
                                            <AssignMemberToIssue
                                                id={issue?.id}
                                                data={issue?.assigned}
                                                projectID={issue?.project?.id}
                                                onConfirm={onAssigneeAdd}
                                                fieldName={"assigned"}
                                                patchEndpoint={API.PROJECT_ISSUES}
                                            />
                                        }
                                    />
                                }
                                content={
                                    <>
                                        {issue?.assigned?.map((assigned, index) => (
                                            <>
                                                <PutAvatar user={assigned} />
                                            </>
                                        ))}
                                    </>
                                }
                            />
                            <CustomFormDivider
                                header={
                                    <SuperDuperModal
                                        trigger={
                                            <Header as='h3'>
                                                <Header.Content>
                                                    <span style={{ position: "relative", top: "-0.1rem" }}>
                                                        {t('labels')}
                                                    </span>
                                                    <Icon name='add-outline' style={{ marginLeft: '0.5rem' }} />
                                                </Header.Content>
                                            </Header>
                                        }
                                        content={
                                            <LabelsForm
                                                id={issue?.id}
                                                data={issue?.labels}
                                                onConfirm={onLabelsAdd}
                                                patchEndpoint={API.PROJECT_ISSUES}
                                                labelEndpoint={API.PROJECT_LABELS + `?project=${issue?.project?.id}`}
                                                fieldName={"labels"}
                                            />
                                        }
                                    />
                                }
                                content={
                                    <>
                                        {issue?.labels?.map((label, index) => (
                                            <>
                                                <Label style={{ margin: "3px" }} key={label.id}>
                                                    <Icon name="pricetag-outline" style={{ marginRight: "0.5rem", color: label.color === "" ? "" : label.color }} />
                                                    <span style={{ position: "relative", top: "-0.1rem" }}>
                                                        {label.name}
                                                    </span>
                                                    <Icon name="close-outline" style={{ marginLeft: "0.5rem", color: "var(--danger)" }} onClick={() => onLabelRemove(issue.id, label.id)} />
                                                </Label>
                                            </>
                                        ))}
                                    </>
                                }
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    );
};

export default IssueDetail;