import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
import { isEmpty } from '@services/helpers/validation';
import { API } from '@store/config';
// components
import { Form, Message, Divider, Button } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField'

const AttachmentForm = ({ doc, group, groups, setGroups, onClose, onConfirm, module, group_params }) => {
    const { t } = useTranslation();

    const [attachment, setAttachment] = useState({
        id: doc ? doc.id : 0,
        name: doc ? doc.name : "",
        group: doc?.group ? doc?.group.id : group ? group : null,
        file: null
    })

    const [files, setFiles] = useState([])
    const [allowMultiple, setAllowMultiple] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);
    const [groupOptions, setGroupOptions] = useState([])

    const handleSuccess = (response) => {
        onConfirm(response);
        onClose()
    }

    const fetchGroupOptions = async () => {
        const request = await requests.get(API.ATTACHMENT_GROUPS + `?module=${module}${group_params || ""}`)

        if (request.status === 200) {
            let output = []
            for (let item of request.response) {
                if (item) {
                    output.push({ key: item.id, value: item.id, text: item.name })
                }
            }
            setGroupOptions(output)
        }
    }

    const handleAddItem = async (newItem) => {
        const request = await requests.post(API.ATTACHMENT_GROUPS, {
            name: newItem,
            module: module
        })

        if (request.status === 201) {
            setGroupOptions([...groupOptions, { key: request.response.id, value: request.response.id, text: request.response.name }])
            setGroups([...groups, request.response])
            setAttachment({...attachment, group: request.response.id})
        }
    }

    const changeView = (status) => {
        setFiles([])
        setAllowMultiple(status)
        document.getElementById('fileInput').value = null
    }

    useEffect(() => {
        fetchGroupOptions()
        // eslint-disable-next-line
    }, [])

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }
        if (response.detail) {
            errorsArray.push(response.detail)
        }
        if (response.file) {
            errorsArray.push(response.file)
        }
        if (response.name) {
            errorsArray.push(response.name)
        }
        setErrors(errorsArray);
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);
        let request = null;

        const endpoint = !allowMultiple ? API.ATTACHMENTS : API.ATTACHMENTS_BULK

        const formData = new FormData()
        if( !allowMultiple ){
            if( files?.[0] !== undefined ){
                formData.append("file", files[0])
            }
            formData.append("name", attachment.name)
        } else {
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i])
            }
        }

        if( attachment.group !== "" && attachment.group !== null ){
            formData.append("group", attachment.group)
        }

        request = await requests.post(endpoint, formData)
        if (request.status === 201) {
            handleSuccess(request.response)
        } else {
            handleErrors(request.response)
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            { attachment.id === 0 &&
                <>
                    <Button.Group fluid basic size='small' style={{ borderRadius: 0 }}>
                        <Button type='button' style={{ fontWeight: "bold" }} active={allowMultiple === false} onClick={() => changeView(false)}>{t('upload_single_file')}</Button>
                        <Button type='button' style={{ fontWeight: "bold" }} active={allowMultiple === true} onClick={() => changeView(true)}>{t('upload_multiple_file')}</Button>
                    </Button.Group>
                    <Divider/>
                </>
            }

            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />
            <Form.Group widths="equal">
                { !allowMultiple &&
                    <SuperField as="input"
                        autoFocus
                        required
                        label={t('att_name')}
                        placeholder={t('att_name')}
                        value={attachment.name}
                        onChange={(e) => setAttachment({
                            ...attachment,
                            name: e.target.value
                        })}
                    />
                }
                <SuperField
                    as="choice"
                    allowAdditions
                    help={ t('help_group_create') }
                    search
                    onAddItem={(event, data) => handleAddItem(data.value)}
                    customOptions={groupOptions}
                    label={t('att_group')}
                    value={attachment.group}
                    onChange={(e, { value }) => setAttachment({
                        ...attachment,
                        group: value
                    })}
                />
            </Form.Group>
            <Form.Field>
                <Form.Input
                    required
                    multiple={allowMultiple}
                    type="file"
                    id="fileInput"
                    ref={(el) => console.log(el)}
                    label={allowMultiple ? t('files') : t('file')}
                    onChange={(event) => {
                        if( event.target?.files?.length > 0 ){
                            setFiles(event.target.files)
                        }
                    }}
                />
            </Form.Field>


            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || (!allowMultiple && isEmpty(attachment.name))}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default AttachmentForm;