import { useParams, useHistory, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { addFilters } from '@store/actions';
import { requests } from '@helpers/requests';
import { useHasPermissions } from '@helpers/hooks';
import { thousandsSeparators } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import DocPreview from '@components/DocPreview';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';
import LabelsList from '@components/lists/LabelsList';
import { Form, Segment/*, Divider, Header */ } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// specific components
import CostCenterStatistic from '../components/stats/CostCenterStatistic';
import InvoicePreviewPDF from '../../../sm/invoices/components/InvoicePreviewPDF';
import RecordForm from '../components/detail/RecordForm';
import ListDetailView from '../components/stats/ListDetailView';

const CCDetailView = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.user)
    const filterPref = useSelector(state => state.filters)
    const canManagePerm = useHasPermissions(['cost_centers.c_manage_cost_centers'])
    const dateFormat = useSelector((state) => state.date_format)

    const firstDay = moment().startOf('month').format("YYYY-MM-DD")
    const lastDay = moment().endOf('month').format("YYYY-MM-DD")

    const [allowedUser, setAllowedUser] = useState([])

    const [loading, setLoading] = useState(true)
    
    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState([])
    
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState()

    const initialFilters = {
        unit: filterPref?.cost_centers?.unit || "",
        currency: filterPref?.cost_centers?.currency || "",
        date_from: filterPref?.cost_centers?.date_from || firstDay,
        date_to: filterPref?.cost_centers?.date_to || lastDay,
        monthStart: filterPref?.cost_centers?.monthStart || 1,
        monthEnd: filterPref?.cost_centers?.monthEnd || 12,
        year: filterPref?.cost_centers?.year || moment().format("YYYY"),
        projects: filterPref?.cost_centers?.projects || [],
        cost_center: id,
        cost_type: filterPref?.cost_centers?.cost_type ||  "",
        account: filterPref?.cost_centers?.account || "",
        owner: filterPref?.cost_centers?.owner || "",
        category: filterPref?.cost_centers?.category || [],
        tags: filterPref?.cost_centers?.tags || [],
    }

    const [sharedFilters, setSharedFilters] = useState(initialFilters)

    // eslint-disable-next-line
    const months = [
        { key: 1, value: 1, text: t('january') },
        { key: 2, value: 2, text: t('february') },
        { key: 3, value: 3, text: t('march') },
        { key: 4, value: 4, text: t('april') },
        { key: 5, value: 5, text: t('may') },
        { key: 6, value: 6, text: t('june') },
        { key: 7, value: 7, text: t('july') },
        { key: 8, value: 8, text: t('august') },
        { key: 9, value: 9, text: t('september') },
        { key: 10, value: 10, text: t('october') },
        { key: 11, value: 11, text: t('november') },
        { key: 12, value: 12, text: t('december') },
    ]

    // eslint-disable-next-line
    const isValidMonthRange = () => {
        return sharedFilters.monthStart < sharedFilters.monthEnd
    }

    useEffect( () => {
        async function loadCategories(){
            setIsLoading(true)
            const request = await requests.get(API.COST_CENTERS_RECORD_CATEGORIES)
            
            if( request.status === 200 ) {
                setCategories(request.response)
            }
            setIsLoading(false)
        }

        loadCategories()
        // eslint-disable-next-line
    }, [])

    const handleCategoryOptions = () => {
        let options = []

        options = categories.map(category => {
            let textValue = category.title + " (" + category.type_display + ")"
            
            if( category.code !== "" && category.code !== null){
                textValue = category.code + " - " + category.title + " (" + category.type_display + ")"
            }

            return {
                id: category.id,
                value: category.id,
                text: textValue
            }
        })

        return options
    }

    useEffect( () => {
        async function validateAccess(){
            setLoading(true)
            const request = await requests.get(API.COST_CENTERS_ALLOWED_USERS + `?cost_center=${id}&user=${currentUser.id}`)

            if( request.status === 200 ){
                setAllowedUser(request.response)
                if (request.response?.[0]?.can_view || canManagePerm === true) {
                    // access granted
                } else {
                    history.push(routes.DASHBOARD)
                    return
                }
            }
            setLoading(false)
        }

        validateAccess()
        // eslint-disable-next-line
    }, [])

    const InvoicePreview = ({ item }) => {
        return (
            <>
            { item.invoice_reference !== null ?
                <SuperDuperModal
                    size="large"
                    trigger={
                        <span style={{ color: "var(--primary)" }} className="ref-link">{ item.invoice_reference.split('__')[1] }</span>
                    }
                    content={
                        <InvoicePreviewPDF id={item.invoice_reference.split('__')[0]}/>
                    }
                />
            : "--" }
            </>
        )
    }

    return (
        <Segment 
            loading={loading}
            style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
                marginBottom: "1rem",
                marginTop: "0",
            }}
        >
                { (loading) && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_performing_verification') } </p> }
                { !loading && (
                    <>
                    { (allowedUser?.[0]?.can_view || canManagePerm) &&
                        <>
                            <CanView permissions={['reports.c_view_cost_center_reports']}>
                                <CostCenterStatistic isDetail={true} filters={sharedFilters} data={data} setVisible={setVisible}/>
                            </CanView>
                            <ListView
                                as="table"
                                allowSearch
                                isSoftDeleteAllowed
                                forceLoading={loading}
                                forceVisibility={visible}
                                endpoint={API.COST_CENTERS_RECORDS}
                                query={"&ordering=-date"}
                                loadingMessage={t('loading_list')}
                                onResponse={(response, filters) => {
                                    setData(response)
                                    setSharedFilters(filters)
                                    dispatch(addFilters({
                                        ...filterPref,
                                        cost_centers: {
                                            ...filterPref.cost_centers,
                                            unit: filters.unit,
                                            currency: filters.currency,
                                            date_from: filters.date_from,
                                            date_to: filters.date_to,
                                            monthStart: filters.monthStart,
                                            monthEnd: filters.monthEnd,
                                            year: filters.year,
                                            projects: filters.projects,
                                            cost_type: filters.cost_type,
                                            account: filters.account,
                                            owner: filters.owner,
                                            category: filters.category,
                                            tags: filters.tags,
                                        }
                                    }))
                                }}
                                onVisibilityChange={(visibility) => {
                                    if (visibility !== visible) setVisible(visibility)
                                }}
                                actionsCellWidth="2"
                                initialFilters={initialFilters}
                                renderStatisticView={(filters, data, total, params, setTotal, setData, setFilters) => (
                                    <ListDetailView filters={filters} data={data} total={total} params={params}/>
                                )}
                                listAdditionActions={(data, setData, total, setTotal, response) => ([
                                    {
                                        as: "filter",
                                        index: 0,
                                        name: t('all'),
                                        filters: {
                                            type: "",
                                        },
                                        count: response?.all_count || 0
                                    },
                                    {
                                        as: "filter",
                                        index: 1,
                                        name: t('income'),
                                        filters: {
                                            type: 1,
                                        },
                                        count: response?.incomes_count || 0
                                    },
                                    {
                                        as: "filter",
                                        index: 2,
                                        name: t('expense'),
                                        filters: {
                                            type: 2,
                                        },
                                        count: response?.expenses_count || 0
                                    },
                                ])}
                                exports={[
                                    {
                                        endpoint: API.EXPORTS + "dynamic_cost_center_records/",
                                        permissions: ['cost_centers.c_view_cost_centers'],
                                        apply_filters: true,
                                        type: "xlsx",
                                        filename: "cost_center_records"
                                    },
                                ]}
                                actions={[
                                    {
                                        as: "modal",
                                        type: "add",
                                        name: t('create_record'),
                                        modal: <RecordForm />,
                                        isHidden: (item) => (!allowedUser?.[0]?.can_manage && !canManagePerm),
                                    },
                                    {
                                        as: "modal",
                                        type: "edit",
                                        name: t('edit'),
                                        modalSize: "small",
                                        modal: <RecordForm />,
                                        isHidden: (item) => (!allowedUser?.[0]?.can_manage && !canManagePerm), 
                                    },
                                    {
                                        name: t('delete'),
                                        type: "delete",
                                        as: "delete",
                                        text: t('do_you_really_want_to_delete_record'),
                                        isHidden: (item) => (!allowedUser?.[0]?.can_delete && !canManagePerm), 
                                    },
                                ]}
                                tableHeaders={[
                                    { title: t("week"), orderBy: "week" },
                                    { title: t("date"), orderBy: "date" },
                                    { title: t("category_type"), orderBy: "category__type" },
                                    { title: t("cost_type"), orderBy: "category__cost_type" },
                                    { title: t("category"), orderBy: "category__title" },
                                    { title: t("assigned_to") },
                                    { title: t("sum"), orderBy: "amount__sum" },
                                    { title: t("labels") },
                                    { title: t("document") },
                                    { title: t("note"), orderBy: "note" },
                                ]}
                                renderFilterFields={(filters, setFilters) => (
                                    <>
                                        <Form.Group widths="equal">
                                            <SuperField
                                                search
                                                as="datepicker"
                                                label={t('date_from')}
                                                value={filters.date_from}
                                                onChange={(e, { value }) => {
                                                    setFilters((prev) => ({ ...prev, date_from: value }))
                                                    setSharedFilters((prev) => ({ ...prev, date_from: value }))
                                                }} 
                                            />
                                            <SuperField
                                                search
                                                as="datepicker"
                                                label={t('date_to')}
                                                value={filters.date_to}
                                                onChange={(e, { value }) => {
                                                    setFilters((prev) => ({ ...prev, date_to: value }))
                                                    setSharedFilters((prev) => ({ ...prev, date_to: value }))
                                                }} 
                                            />
                                        </Form.Group>

                                        <DatePresets
                                            open={true}
                                            from={filters.date_from}
                                            to={filters.date_to}
                                            onSelection={ (firstMonthDay, lastMonthDay, year) => {
                                                setFilters(prev => ({
                                                    ...prev,
                                                    date_from: firstMonthDay,
                                                    date_to: lastMonthDay,
                                                    year: year
                                                }))
                                                setSharedFilters(prev => ({
                                                    ...prev,
                                                    date_from: firstMonthDay,
                                                    date_to: lastMonthDay,
                                                    year: year
                                                }))
                                            }}
                                        />

                                        <Form.Group widths="equal">
                                            <SuperField
                                                search
                                                as="choice"
                                                label={t('currency')}
                                                type="currency_codes"
                                                value={filters.currency}
                                                onChange={(e, { value }) => {
                                                    setFilters((prev) => ({ ...prev, currency: value }))
                                                    setSharedFilters((prev) => ({ ...prev, currency: value }))
                                                }} 
                                            />
                                            <SuperField
                                                search
                                                as="choice"
                                                label={t('cost_type')}
                                                value={filters.cost_type}
                                                customOptions={[
                                                    { key: 1, value: 1, text: t('direct') },
                                                    { key: 2, value: 2, text: t('indirect') }
                                                ]}
                                                onChange={(e, { value }) => {
                                                    setFilters((prev) => ({ ...prev, cost_type: value }))
                                                    setSharedFilters((prev) => ({ ...prev, cost_type: value }))
                                                }} 
                                            />
                                        </Form.Group>
                                        <SuperField
                                            search
                                            multiple
                                            as="choice"
                                            label={t('category')}
                                            loading={isLoading}
                                            disabled={isLoading}
                                            customOptions={handleCategoryOptions()}
                                            value={filters.category}
                                            onChange={(e, { value }) => {
                                                setFilters({ ...filters, category: value })
                                                setSharedFilters({ ...filters, category: value })
                                            }}
                                        />

                                        <Form.Group widths="equal">
                                            <SuperField
                                                search
                                                as="choice"
                                                label={t('employee')}
                                                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                                                text="fullname"
                                                value={filters.owner}
                                                onChange={(e, { value }) => {
                                                    setFilters({ ...filters, owner: value })
                                                    setSharedFilters({ ...filters, owner: value })
                                                }}
                                            />
                                            <SuperField
                                                search
                                                as="choice"
                                                label={t('account') + "/" + t('supplier')}
                                                endpoint={API.ACCOUNTS + "business_details/?query={id, name}&exclude_unit=true"}
                                                text="name"
                                                value={filters.account}
                                                onChange={(e, { value }) => {
                                                    setFilters({ ...filters, account: value })
                                                    setSharedFilters({ ...filters, account: value })
                                                }}
                                            />
                                        </Form.Group>

                                        <SuperField
                                            as="choice"
                                            multiple
                                            search
                                            text="name"
                                            label={t('labels')}
                                            value={filters.tags}
                                            endpoint={API.COST_CENTERS + `labels/?query={id,name}`}
                                            onChange={(e, { value }) => {
                                                setFilters((prev) => ({ ...prev, tags: value }))
                                                setSharedFilters((prev) => ({ ...prev, tags: value }))
                                            }} 
                                        />
                                    </>
                                )}
                                renderCells={(record) => [
                                    { width: 1, content: moment(record.date, "YYYY-MM-DD").isoWeek() },
                                    { width: 1, content: moment(record.date).format(dateFormat) },
                                    { width: 2, content: <span style={{ fontWeight: "bold", color: record?.category?.type === 2 ? "var(--danger)" : "var(--success)" }}> { record?.category?.type_display || t('undefined') } </span> },
                                    { width: 2, content: 
                                        (record?.cost_type !== null 
                                            ?  
                                                (record?.cost_type === 1 
                                                    ? t('direct') 
                                                    : t('indirect')
                                                )
                                            : t("unclassified")
                                        ) 
                                    },
                                    { width: 2, content: 
                                        record.category?.code 
                                            ? record.category?.code + " - " + record.category?.title 
                                            : record.category?.title
                                    },
                                    { width: 3, content: 
                                        <>
                                            { record.business_detail?.id && 
                                                <Link target="_blank" to={record?.business_detail?.is_supplier 
                                                    ? routes.SUPPLIERS + record?.business_detail?.id 
                                                    : routes.ACCOUNTS + record?.business_detail?.id  }
                                                >
                                                    { record?.business_detail?.name || "" } <br/>
                                                </Link>
                                            }
                                            { record.owner?.id && 
                                                <Link target="_blank"  to={routes.EMPLYOEES_DETAIL + record?.owner?.id}> 
                                                    { record.owner?.fullname }
                                                </Link>
                                            }
                                        </>
                                    },
                                    { width: 2, content: thousandsSeparators(record.amount.sum) + " " + record.amount.currency },
                                    {
                                        content:
                                            <LabelsList
                                                rowID={record.id}
                                                labels={record?.tags}
                                                endpoint={API.COST_CENTERS + "records/"}
                                                // additionalFilters="&lead_tag=true"
                                                // mark="lead_tag"
                                                endpointLabels={API.COST_CENTERS + "labels/"}
                                                label_key="tags"
                                            />
                                    },
                                    { width: 1, content: 
                                        <>
                                            { record.file !== null &&
                                                <SuperDuperModal
                                                    size="large"
                                                    trigger={
                                                        <Icon
                                                            title={t('scan')}
                                                            name="document-outline"
                                                            style={{ cursor: "pointer", fontSize: "1.4rem", color: "var(--dark)" }}
                                                        />
                                                    }
                                                    content={
                                                        <DocPreview uri={record.file}/>
                                                    }
                                                />
                                            }

                                            { (record.invoice_reference !== "" && record.invoice_reference !== null) &&
                                                <InvoicePreview item={record}/>
                                            }
                                        </>
                                    },
                                    { width: 3, content: record.title }
                                ]}
                            />
                        </>
                    }
                    </>
                )}
        </Segment>
    );
};

export default CCDetailView;