import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isValidNumber, setDotSeparator } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import { Form, Divider, Message} from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ProcessItemUnloadingForm = ({ onClose, setHeader, item, setItems, process }) => {
    const { t } = useTranslation()
    const profile = useSelector(state => state.user.profile?.id)
    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [stockPositions, setStockPositions] = useState([])
    const [form, setForm] = useState({
        quantity: "",
        warehouse_position: ""
    })


    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.post(API.ASSETS + "warehouse_process_operations/", {
            quantity: form.quantity,
            operation: 6,
            stored_item: item.stored_item_id,
            warehouse_process: process.id,
            warehouse_process_item: item.id,
            warehouse_position: form.warehouse_position,
            performed_by: profile || null
        })

        if (request.status === 201) {
            const fetchStats = await requests.get(API.REPORTS + "assets/warehouse_stats/?id=" + process.id)
            if (fetchStats.status === 200) {
                if (fetchStats.response.length > 0) {
                    setItems(fetchStats.response[0].items)
                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('stock_was_unloaded_for_selected_commodity'),
                        animation: 'pulse',
                        time: 2000,
                    })
                    onClose()
                }
            }
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (form.warehouse_position === "") return false
        if (!isValidNumber(form.quantity, false, false)) return false
        if (!canUnloadQuantity(form.quantity)) return false

        return true
    }

    useEffect(() => {
        async function fetchStockedPositions() {
            setLoading(true)
            const request = await requests.get(API.ASSETS + "stored_item_positions/?query={id, warehouse_position{id, title, section, row, quantity_limit}, quantity}&ordering=warehouse_position__title&stored_item="+ item.stored_item_id)
            if (request.status === 200) {
                if (request.response.length === 1) {
                    setForm(prev => ({...prev, warehouse_position: request.response[0].warehouse_position.id}))
                }
                setStockPositions(request.response)
            }
            setLoading(false)
        }

        fetchStockedPositions()
        setHeader(t('unload_commodity'))
        // eslint-disable-next-line
    }, [])

    const canUnloadQuantity = (quantity) => {
        if (parseFloat(form.quantity) === 0) return false

        quantity = parseFloat(quantity)
        const difference = parseFloat(parseFloat(item.plan - item.reality).toFixed(4))
        if (quantity <= difference) {
            if (quantity <= stockPositions.find(item => item.warehouse_position.id === form.warehouse_position)?.quantity) {
                return true
            }
        }

        return false
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message visible info>
                <Icon name="information-circle" style={{ marginRight: "0.5rem" }}/>
                <span style={{ position: "relative", top: "-0.1rem" }}>
                    {t('as_manager_you_are_allowed_to_specify_amount_of_stock_to_unload_from_selected_position')}.
                </span>
            </Message>
            <Form.Group widths="equal">
                <Form.Field>
                    <SuperField as="choice"
                        search
                        required
                        loading={loading}
                        disabled={loading}
                        label={t('select_stock_position_to_unload')}
                        value={form.warehouse_position}
                        customOptions={stockPositions.map(position => ({
                            key: position.warehouse_position.id,
                            value: position.warehouse_position.id,
                            text: position.warehouse_position.title
                        }))}
                        onChange={(e, { value }) => setForm(prev => ({...prev, warehouse_position: value}))}
                    />
                    { form.warehouse_position !== "" && 
                        <p style={{ marginTop: 0 }}>{t('current_stock')}: 
                            <strong> { stockPositions.find(item => item.warehouse_position.id === form.warehouse_position)?.quantity || 0 } </strong> {" "}
                            <span
                                className="ref-link"
                                style={{ color: "var(--primary)" }}
                                onClick={() => setForm(prev => ({...prev, quantity: stockPositions.find(item => item.warehouse_position.id === form.warehouse_position)?.quantity}))}
                            >
                                {t('unload_all_stock')}
                            </span>
                        </p>
                    }
                </Form.Field>
            </Form.Group>

            { form.warehouse_position !== "" && 
                <>
                    
                
                    <SuperField as="input"
                        required
                        autoFocus
                        label={t('quantity')}
                        help={t('stock_quantity_to_unload')}
                        error={
                            isValidNumber(form.quantity, true, false) 
                            ? ((form.quantity !== "" && !canUnloadQuantity(form.quantity)) ? t('selected_quantity_can_not_be_unloaded') : false) 
                            : t('invalid_quantity')
                    }
                        value={form.quantity}
                        onChange={(e, { value }) => setForm(prev => ({...prev, quantity: setDotSeparator(value)}))} 
                    />

                    <p> {t('current_processing_state')}: <strong style={{ marginLeft: "0.5rem" }}>{ item.reality } / { item.plan } </strong> </p>
                </>
            }

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing || !isFormValid() || parseInt(form.quantity) === 0}
                    loading={processing}
                />
            </Form.Field>
        </Form>
    );
};

export default ProcessItemUnloadingForm;