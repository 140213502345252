import React from 'react';
import { useTranslation } from "react-i18next"
//components
import { Grid, Button, Segment, Divider } from 'semantic-ui-react'
import SegmentDetail from './others/SegmentDetail';
import SegmentHeader from './others/SegmentHeader';
import ChangeEmailForm from './forms/ChangeEmailForm';
import ChangeUsernameForm from './forms/ChangeUsernameForm';
import ChangePasswordForm from './forms/ChangePasswordForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';

function AccountSettings({ data }) {
    const { t } = useTranslation();

    // States for data
    const [username, setUsername] = React.useState(data.username)
    const [email, setEmail] = React.useState(data.email)

    // this function is used to submit EmailModal Form
    const onConfirmEmailForm = (email) => {
        setEmail(email)
    }

    // this function is used to submit second form
    const onConfirmUsernameForm = (new_username) => {
        setUsername(new_username)
    }

    // this function is used to submit second form
    const onConfirmPasswordForm = (new_password) => {
        console.log("something")
    }

    return (
        <Grid columns={2} doubling>
            <Grid.Row>
                <Grid.Column style={{ paddingLeft: 0 }}>

                    <SegmentHeader
                        headerIconName='setting'
                        headerText={t('header_account')}
                    />

                    <Segment>
                        <div style={{ margin: "1rem" }}>

                            <SegmentDetail
                                icon='mail outline'
                                header={t('email')}
                                text={email}
                                modal={
                                    <SuperDuperModal
                                        trigger={
                                            <Button
                                                style={{
                                                    backgroundColor: "var(--white)", color: "var(--primary)",
                                                    fontWeight: "inherit", paddingTop: "0", paddingRight: "0",
                                                    textAlign: "right"
                                                }}>
                                                {t('change_email')}
                                            </Button>
                                        }
                                        size='tiny'
                                        className='changeEmailModal'
                                        header={t('change_email')}
                                        content={
                                            <ChangeEmailForm
                                                label={t('change_email')}
                                                placeholder={t('email')}
                                                onConfirm={(email) => onConfirmEmailForm(email)}
                                            />
                                        }
                                    />
                                }
                            />

                            <Divider section />

                            <SegmentDetail
                                icon='user'
                                header={t('name')}
                                text={username}
                            />

                        </div>
                    </Segment>
                </Grid.Column>

                <Grid.Column style={{ paddingRight: 0 }}>

                    <SegmentHeader />

                    <Segment>
                        <div style={{ margin: "1rem" }}>

                            <SegmentDetail
                                icon='user'
                                header={t('username')}
                                text={username}
                                modal={
                                    <SuperDuperModal
                                        trigger={
                                            <Button
                                                style={{
                                                    backgroundColor: "var(--white)", color: "var(--primary)",
                                                    fontWeight: "inherit", paddingTop: "0", paddingRight: "0",
                                                    textAlign: "right"
                                                }}>
                                                {t('change_username')}
                                            </Button>
                                        }
                                        size='tiny'
                                        className='changeUsernameModal'
                                        header={t('change_username')}
                                        content={
                                            <ChangeUsernameForm
                                                labelUsername={t('change_username')}
                                                placeholderUsername={t('username')}
                                                labelOldPassword={t('enter_pass')}
                                                placeholderOldPassword={t('pass')}
                                                onConfirm={(new_username) => onConfirmUsernameForm(new_username)}
                                            />
                                        }
                                    />
                                }
                            />

                            <Divider section />

                            <SegmentDetail
                                icon='lock'
                                header={t('pass')}
                                text='**********'
                                modal={
                                    <SuperDuperModal
                                        trigger={
                                            <Button
                                                style={{
                                                    backgroundColor: "var(--white)", color: "var(--primary)",
                                                    fontWeight: "inherit", paddingTop: "0", paddingRight: "0",
                                                    textAlign: "right"
                                                }}>
                                                {t('change_pass')}
                                            </Button>
                                        }
                                        size='tiny'
                                        className='changePasswordForm'
                                        header={t('change_pass')}

                                        content={
                                            <ChangePasswordForm
                                                labelNewPassword={t('new_pass')}
                                                placeholderNewPassword={t('new_pass')}
                                                labelRepeatPassword={t('repeat_pass')}
                                                placeholderRepeatPassword={t('new_pass')}
                                                labelOldPassword={t('old_pass')}
                                                placeholderOldPassword={t('old_pass')}
                                                onConfirm={(new_password) => onConfirmPasswordForm(new_password)}
                                            />
                                        }
                                    />
                                }
                            />

                        </div>
                    </Segment>


                </Grid.Column>

            </Grid.Row>
        </Grid>
    );
}

export default AccountSettings;