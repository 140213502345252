import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { tzDateTime } from '@helpers/dates';
// components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import ModalCancel from '@components/buttons/ModalCancel';
import { Table, Divider, Form, Button, Header, Message, Grid } from 'semantic-ui-react';

const RecordDuplicityMergeView = ({ day, records, setRecords, onClose }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)
    let current = moment() // in UTC

    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [correctedRecord, setCorrectedRecord] = useState({
        start_time: "",
        end_time: "",
        reason: "",
        auto_break: true,
        enter_break: false,
        pause_start: "",
        pause_end: "",
    })

    const [reasons, setReasons] = useState([])
    const [interruptions, setInterruptions] = useState([])

    useEffect(() => {
        detectData()
        // eslint-disable-next-line
    }, [])

    const isTimeValid = (value) => {
        if (value === "") return true
        if (value === null) return true
        if (value === undefined) return true

        return moment(value, "HH:mm", true).isValid()
    }

    const handleTimeValue = (value) => {
        value = value.replace(".", ":")
        value = value.replace(",", ":")
        value = value.replace(";", ":")
        // value = value.replace(" ", ":")

        return value
    }

    const detectData = async () => {
        setLoading(true)
        // fetch interruption reasons
        const request = await requests.get(API.ATTENDANCE_INTERRUPTION_REASONS)
        if (request.status === 200) setReasons(request.response)

        let firstRecord = records[0]
        let lastRecord = records[records.length - 1]
        
        if (firstRecord?.id !== lastRecord?.id) {
            setCorrectedRecord(prev => ({
                ...prev,
                start_time: firstRecord?.start ? tzDateTime(firstRecord?.start).format("HH:mm") : "",
                end_time: lastRecord?.end ? tzDateTime(lastRecord?.end).format("HH:mm") : "",
            }))
        }

        // TODO: loop over all existing records and create interruptions:
        let detectedInterruption = []
        for (let i = 0; i < records.length; i++) {
            if (records[i]?.id !== lastRecord?.id) {
                detectedInterruption.push({
                    index: i,
                    day: day,
                    start: records[i].end,
                    end: "",
                })
            }
        }

        for (let i = 0; i < detectedInterruption.length; i++) {
            // find next and grab start datetime as end datetime for interruption
            detectedInterruption[i]['end'] = records.find((item, index) => detectedInterruption[i].index + 1 === index)?.start || ""
        }

        setInterruptions(detectedInterruption)
        setLoading(false)
    }

    const calculateTime = (start, end, as) => {
        let time = 0
        let start_time = start ? tzDateTime(moment(day + " " + start).format("YYYY-MM-DD HH:mm"), true) : null
        let end_time = end ? tzDateTime(moment(day + " " + end).format("YYYY-MM-DD HH:mm"), true) : moment(current.format("YYYY-MM-DD HH:mm"))

        if (start_time !== null) {
            // Check if end_time is higher, add 1 day if true
            if (end_time.isBefore(start_time)) {
                end_time.add(1, 'day');
            }
            
            // Calculate the difference
            if (as === "minutes") {
                time = moment.duration(end_time.diff(start_time)).asMinutes();
            } else {
                time = moment.duration(end_time.diff(start_time)).asHours();
            }
        }

        return as === "hours" ? time.toFixed(2) : time
    }

    const calculateInterruptionTime = () => {
        let time = 0

        for (let i = 0; i < interruptions.length; i++) {
            let start = moment(interruptions[i].start).format("HH:mm")
            let end = moment(interruptions[i].end).format("HH:mm")
            time += calculateTime(start, end, "minutes")
        }

        return time // in minutes
    }

    const calculateWorkTime = () => {
        let interruption_time = calculateInterruptionTime() // in minutes
        let pause_time = correctedRecord?.auto_break ? 30 : calculateTime(correctedRecord.pause_start, correctedRecord.pause_end, "minutes")
        let duration_time = calculateTime(correctedRecord.start_time, correctedRecord.end_time, "minutes")

        return parseFloat((duration_time - pause_time - interruption_time)/60).toFixed(2) // in hours
    }

    const handleSubmit = async () => {
        setProcessing(true)
        // prepare data
        const start = tzDateTime(moment(`${day} ${correctedRecord.start_time}`), true)
        const end = correctedRecord.end_time ? tzDateTime(moment(`${day} ${correctedRecord.end_time}`), true) : null

        if (end !== null && end.isBefore(start)) {
            end.add(1, 'day');
        }

        // handle pause if exists
        let start_pause = null
        let end_pause = null
        if (!correctedRecord.auto_break) {
            start_pause = tzDateTime(moment(`${day} ${correctedRecord.pause_start}`), true)
            end_pause = correctedRecord.pause_end ? tzDateTime(moment(`${day} ${correctedRecord.pause_end}`), true) : null

            if (end_pause !== null && end_pause.isBefore(start_pause)) {
                end_pause.add(1, 'day');
            }
        }

        const data = {
            employee: records[0].employee?.id || null,
            contract: records[0].contract?.id || null,
            date: day,
            start: start.format("YYYY-MM-DD HH:mm"),
            end: end ? end.format("YYYY-MM-DD HH:mm") : null,
            start_pause: start_pause ? start_pause.format("YYYY-MM-DD HH:mm") : null,
            end_pause: end_pause ? end_pause.format("YYYY-MM-DD HH:mm") : null,
            auto_break: correctedRecord.auto_break
        }

        const request = await requests.post(API.ATTENDANCE_MANUAL, data)
        if (request.status === 201) {
            let response = request.response // new record
            // handle interruptions
            let interruptionCreated = 0
            for (let i = 0; i < interruptions.length; i++) {
                const requestInterruption = await requests.post(API.ATTENDANCE_INTERRUPTIONS, { 
                    date: day,
                    record: response.id,
                    end: interruptions[i].end,
                    start: interruptions[i].start,
                    reason: correctedRecord.reason,
                })

                if (requestInterruption.status === 201) {
                    interruptionCreated += 1
                }
            }

            if (interruptions.length === interruptionCreated) { // on success remove old records
                // handle old records (soft delete)
                for (let j = 0; j < records.length; j++) {
                    await requests.post(API.ATTENDANCE + records[j].id + "/delete/")
                }

                // fetch again:
                const requestRecord = await requests.patch(API.ATTENDANCE + response.id + "/", {})
                if (requestRecord.status === 200) {
                    response = requestRecord.response

                    toast({
                        type: 'success',
                        icon: 'check circle',
                        title: t('records_merged_successfully'),
                        animation: 'pulse',
                        time: 2000,
                    })
                }

                setRecords(prev => prev.map(item => {
                    if (item.day === day) {
                        let start_time = response?.start ? tzDateTime(response?.start).format("HH:mm") : ""
                        let end_time = response?.end ? tzDateTime(response?.end).format("HH:mm") : ""
                        let start_pause = response?.start_pause ? tzDateTime(response?.start_pause).format("HH:mm") : ""
                        let end_pause = response?.end_pause ? tzDateTime(response?.end_pause).format("HH:mm") : ""

                        item.multipleData = [response]
                        item.data = {
                            ...response,
                            start_time: start_time,
                            end_time: end_time,
                            start_pause: start_pause,
                            end_pause: end_pause,
                            shift: response?.shift?.id || ""
                        }
                    }

                    return item
                }))

                // close modal
                onClose()
            } else { // remove new record on failure 
                const requestDelete = await request.del(API.ATTENDANCE + response.id + "/") 
                if (requestDelete.status === 204) {
                    toast({
                        type: 'error',
                        icon: 'warning',
                        title: t('unable_to_perform_action'),
                        animation: 'pulse',
                        time: 5000,
                    })
                }
            }
        }

        setProcessing(false)
    }

    const onInterruptionDelete = (index) => {
        setInterruptions(prev => prev.filter((item, idx) => idx !== index))
    }

    const isFormValid = () => {
        if (correctedRecord.start_time === "" || correctedRecord.reason === "") return false

        return true
    }

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('checking_records') + "..."}>
            { !loading && 
                <>
                    <Message info visible={true}>
                        { t('duplicity_record_message_hint') }
                    </Message>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('day')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('start')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('end')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('working_hours')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('status')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { records.map(record => (
                                <Table.Row key={record.id}>
                                    <Table.Cell>{ moment(day).format(dateFormat) }</Table.Cell>
                                    <Table.Cell>{ record?.start ? tzDateTime(record?.start).format("HH:mm") : "" }</Table.Cell>
                                    <Table.Cell>{ record?.end ? tzDateTime(record?.end).format("HH:mm") : "" }</Table.Cell>
                                    <Table.Cell>{ record.work_hours || "0" }</Table.Cell>
                                    <Table.Cell style={{ fontWeight: "bold", color: record.closed ? "var(--danger)" : "var(--blue)" }}>
                                        { record.closed ? t('closed_record') : t('pending_record') }
                                    </Table.Cell>
                                </Table.Row>
                            )) }
                        </Table.Body>
                    </Table>
                    <Divider/>

                    <Header as="h3" content={t('correction_record')}/>
                    
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group widths={'equal'}>
                            <SuperField as="timepicker"
                                required
                                label={t('start')}
                                value={correctedRecord.start_time}
                                error={!isTimeValid(correctedRecord.start_time) ? true : false}
                                onChange={(e, { value }) => setCorrectedRecord(prev => ({...prev, start_time: handleTimeValue(value)}))}
                            />
                            <SuperField as="timepicker"
                                label={t('end')}
                                value={correctedRecord.end_time}
                                help={t('correction_end_time_hint')}
                                error={!isTimeValid(correctedRecord.end_time) ? true : false}
                                onChange={(e, { value }) => setCorrectedRecord(prev => ({...prev, end_time: handleTimeValue(value)}))}
                            />
                            <SuperField as="choice"
                                search  
                                required
                                value={correctedRecord.reason}
                                label={t('interruption_reason')}
                                onChange={(e, { value }) => setCorrectedRecord(prev => ({...prev, reason: value}))}
                                customOptions={reasons.map(item =>  ({ key: item.id, value: item.id, text: item.title }))}
                            />
                        </Form.Group>

                        <Form.Group>
                            <SuperField as="checkbox"
                                label={t('auto_break')}
                                checked={correctedRecord.auto_break}
                                onChange={() => setCorrectedRecord(prev => ({...prev, 
                                    auto_break: true, 
                                    enter_break: false,
                                    pause_start: "",
                                    pause_end: "",
                                }))}
                            />
                            <SuperField as="checkbox"
                                label={t('enter_real_break_time')}
                                checked={correctedRecord.enter_break}
                                onChange={() => setCorrectedRecord(prev => ({...prev, auto_break: false, enter_break: true }))}
                            />
                        </Form.Group>

                        { correctedRecord.enter_break && 
                            <>
                                <Form.Group widths={'equal'}>
                                    <SuperField 
                                        as="timepicker"
                                        label={t('start')}
                                        value={correctedRecord.pause_start}
                                        error={!isTimeValid(correctedRecord.pause_start) ? true : false}
                                        onChange={(e, { value }) => setCorrectedRecord(prev => ({...prev, pause_start: handleTimeValue(value)}))}
                                    />
                                    <SuperField 
                                        as="timepicker"
                                        label={t('end')}
                                        value={correctedRecord.pause_end}
                                        error={!isTimeValid(correctedRecord.pause_end) ? true : false}
                                        onChange={(e, { value }) => setCorrectedRecord(prev => ({...prev, pause_end: handleTimeValue(value)}))}
                                    />
                                    <Form.Field style={{ marginTop: "2.1rem" }}>
                                        { t('time') }: { calculateTime(correctedRecord.pause_start, correctedRecord.pause_end, "minutes") } min.
                                    </Form.Field>
                                </Form.Group>
                                
                            </>
                        }
                        <Divider/>
                    </Form>
                    <Header as="h3" content={t('summary') + " - " + moment(day).format(dateFormat)} style={{ marginTop: "0.5rem" }}/>
                    <Grid stackable>
                        <Grid.Row columns={3} style={{ padding: 0 }}>
                            <Grid.Column style={{ padding: 0 }}>
                                <div> { t("start") }:  { correctedRecord.start_time ? correctedRecord.start_time : "" }</div>
                                <div> { t("end") }:  { correctedRecord.end_time ? correctedRecord.end_time : "" }</div>
                                <div> { t("duration") }: { calculateTime(correctedRecord.start_time, correctedRecord.end_time, "hours") } {t('hours_shortcut')}.</div>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }}>
                                <div> { t("auto_break") }: { correctedRecord?.auto_break ? t('yes') : t('no') } </div>
                                <div> { t("pause_start") }:  { correctedRecord?.auto_break ? "--" : correctedRecord.pause_start ? correctedRecord.pause_start : "" }</div>
                                <div> { t("pause_end") }:  { correctedRecord?.auto_break ? "--" : correctedRecord.pause_end ? correctedRecord.pause_end : "" }</div>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }}>
                                <div> { t("pause_time") }: { correctedRecord?.auto_break ? "30" : calculateTime(correctedRecord.pause_start, correctedRecord.pause_end, "minutes") } min. </div>
                                <div> { t("interruption_time") }: { calculateInterruptionTime() } min. </div>
                                <div style={{ fontWeight: "bold" }}> { t("working_hours") }: { calculateWorkTime() } {t('hours_shortcut')}.</div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} style={{ padding: 0, marginTop: "1rem" }}>
                            <Grid.Column style={{ padding: 0 }}>
                                <div style={{ fontWeight: "bold" }}> { t("interruptions") }: </div>
                                { interruptions.length === 0 && <div>{t('not_specified')}</div> }
                                { interruptions.map((interruption, index) => (
                                    <div style={{ marginTop: "0.2rem" }}>
                                        <strong>{index + 1}:</strong> 
                                        { " " }
                                        { interruption?.start ? tzDateTime(interruption.start).format(dateFormat + " HH:mm:ss") : "--" } - { interruption?.end ? tzDateTime(interruption.end).format(dateFormat + " HH:mm:ss") : t('not_specified') } ({ reasons.find(item => item.id === correctedRecord.reason)?.title || t('not_specified') })
                                        <Icon
                                            name="close-outline"
                                            style={{ 
                                                color: "var(--danger)",
                                                cursor: "pointer",
                                                marginLeft: "0.5rem"
                                            }}
                                            onClick={() => onInterruptionDelete(index)}
                                        />
                                    </div>
                                )) }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider/>
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose}/>
                        <Button type="button" primary 
                            loading={processing}
                            onClick={() => handleSubmit()} disabled={!isFormValid()}
                        >
                            { t('confirm') }
                        </Button>
                    </Form.Field>
                </>
            }
        </SpinnerSegment>
    );
};

export default RecordDuplicityMergeView;