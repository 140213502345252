import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Header, Divider, Message, Segment, Checkbox, Card } from 'semantic-ui-react';

const TariffForm = ({ costCenter, profile, record, activity, currentTariff, setWorkers, setData, onClose }) => {
    const { t } = useTranslation()

    const [tariffs, setTariffs] = useState([])
    const [isVisible, setIsVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const isProductivity = record?.piece_work_count > 0
    
    const [form, setForm] = useState({
        amount: "",
        currency: "EUR",
        type: isProductivity ? 3 : 1,
        category: "",
    })

    useEffect(() => {
        async function fetchTariffs() {
            setLoading(true)
            const request = await requests.get(API.ORDERS + `tariffs/?is_archived=false&profile=${profile}&timesheet_category=${activity}`)
            if (request.status === 200) {
                let tariffsList = request?.response || []

                if (isProductivity) {
                    tariffsList = tariffsList.filter(item => item.type !== 1)
                } else {
                    tariffsList = tariffsList.filter(item => item.type !== 3)
                }

                if (currentTariff !== null) {
                    if (tariffsList.filter(item => item.id === currentTariff.id).length === 0) {
                        tariffsList.push(currentTariff)
                    }
                }

                setTariffs(tariffsList)
                if (request.response.length === 0) {
                    setIsVisible(true)
                }
            }
            setLoading(false)
        }

        fetchTariffs()
        // eslint-disable-next-line
    }, [])

    const onSubmit = async () => {
        setProcessing(true)
        // Usecase 2: Create new tariff (archive existing when matching with existing)
        if (currentTariff?.id !== undefined && currentTariff?.type === form.type) {
            await requests.patch(API.ORDERS + "tariffs/" + currentTariff?.id + "/", { is_archived: true })
        } else {
            // loop over existing based on type and archive that...
            const tariffToArchived = tariffs.find(item => item.type === form.type)
            if (tariffToArchived) {
                await requests.patch(API.ORDERS + "tariffs/" + tariffToArchived?.id + "/", { is_archived: true })
            }
        }

        const request = await requests.post(API.ORDERS + "tariffs/", {
            amount: form.amount,
            type: form.type,
            currency: form.currency,
            profile: profile,
            timesheet_category: activity,
            cost_center_category: form.category === "" ? null : form.category
        })

        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            let total_fee = calculateFee(request.response)

            const updateRecord = await requests.patch(API.TIMESHEETS_RECORDS + record.id + "/", {
                tariff: request.response.id,
                total_fee: total_fee
            })

            if (updateRecord.status === 200) {
                if (setWorkers !== undefined) {
                    setWorkers(prev => prev.filter(item => {
                        if (item.profile_id === profile){
                            item.activities = item.activities.filter(activity => {
                                if (activity.id === record.id) {
                                    activity.tariff = updateRecord.response.tariff
                                    activity.total_fee = total_fee
                                }
                                return activity
                            })
                        }
        
                        return item
                    }))
                }

                if (setData !== undefined) {
                    setData(prev => prev.filter(item => {
                        if (item.id === record.id) {
                            item.tariff = updateRecord.response.tariff
                            item.total_fee = total_fee
                        }

                        return item
                    }))
                }

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('tariff_changed'),
                    animation: 'pulse',
                    time: 2000,
                })

                if (setData !== undefined) {
                    onClose()
                }
            } else {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('unable_to_change_tariff'),
                    animation: 'pulse',
                    time: 5000,
                })
            }


        } else {
            // restore archived tariff if exists
            if (currentTariff?.id !== undefined && currentTariff.type === form.type) {
                await requests.patch(API.ORDERS + "tariffs/" + currentTariff?.id + "/", { is_archived: false })
            } else {
                // loop over existing based on type and restore archived state...
                const tariffToArchived = tariffs.find(item => item.type === form.type)
                if (tariffToArchived) {
                    await requests.patch(API.ORDERS + "tariffs/" + tariffToArchived?.id + "/", { is_archived: false })
                }
            }
        }

        setProcessing(false)
    }

    const calculateFee = (tariff) => {
        let total_fee = parseFloat(0)
        if (tariff.type === 1) total_fee = parseFloat(record.hours * tariff.amount)
        if (tariff.type === 2) total_fee = parseFloat(tariff.amount)
        if (tariff.type === 3) total_fee = parseFloat((record?.piece_work_count || 0) * tariff.amount)

        return total_fee.toFixed(2)
    }

    const handleSelectedTariff = async (tariff) => {
        setProcessing(true)
        let total_fee = calculateFee(tariff)

        const request = await requests.patch(API.TIMESHEETS_RECORDS + record.id + "/", {
            tariff: tariff.id,
            total_fee: total_fee
        })

        

        if (request.status === 200 ){
            if (setWorkers !== undefined) {
                setWorkers(prev => prev.filter(item => {
                    if (item.profile_id === profile){
                        item.activities = item.activities.filter(activity => {
                            if (activity.id === record.id) {
                                activity.tariff = request.response.tariff
                                activity.total_fee = total_fee
                            }
                            return activity
                        })
                    }
    
                    return item
                }))
            }

            if (setData !== undefined) {
                setData(prev => prev.filter(item => {
                    if (item.id === record.id) {
                        item.tariff = request.response.tariff
                        item.total_fee = total_fee
                    }

                    return item
                }))
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('tariff_changed'),
                animation: 'pulse',
                time: 2000,
            })

            if (setData !== undefined) {
                onClose()
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('unable_to_change_tariff'),
                animation: 'pulse',
                time: 5000,
            })
        }

        setProcessing(false)
    }

    const isCurrent = (tariff) => {
        return currentTariff?.id === tariff.id
    }

    const isFormValid = () => {
        let isValid = true
        if (form.amount <= 0 || isNaN(form.amount) || form.amount === "" || form.currency === "" || form.type === "") isValid = false
        if (form.category === "" && costCenter?.id !== undefined) isValid = false

        return isValid
    }

    return (
        <Segment loading={loading || processing} style={{ padding: 0, marginTop: 0, border: "none", boxShadow: "none" }}>
            { loading && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_tariffs')} </p>}
            { processing && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('processing_request')} </p>}
            { !loading && !processing && 
                <>
                    { tariffs.length > 0 && <Header as="h4" content={t('select_from_existing_tariffs')}/> }
                    { tariffs.map(tariff => (
                        <Card fluid key={tariff.id} 
                            onClick={isCurrent(tariff) ? null : () => handleSelectedTariff(tariff) }
                            style={{ 
                                cursor: isCurrent(tariff) ? "not-allowed" : "pointer",
                                background: isCurrent(tariff) ? "var(--primary)" : "var(--white)",
                                // opacity: isCurrent(tariff) ? 0.8 : 1
                            }}>
                            <Card.Content 
                                style={{ 
                                    textAlign: "center",
                                    color: isCurrent(tariff) ? "var(--white)" : "var(--dark)",
                                }}
                            >
                                <div>
                                    <strong>{tariff.type_display}: {tariff.amount} {tariff.currency}</strong> <br/>
                                    { 
                                        isCurrent(tariff) ? 
                                            <small>{t('currently_assigned_tariff')} { tariff.is_archived ? ` - ${t('old_archived_tariff')}` : ` - ${t('active_tariff')}`}</small> 
                                        :   <small>{ tariff.is_archived ? `${t('old_archived_tariff')}` : `${t('active_tariff')}`}</small> 
                                    }
                                </div>
                            </Card.Content>
                        </Card>
                    ))}

                    { tariffs.length > 0 && 
                        <>
                            <Divider/>
                            <Checkbox
                                style={{ fontWeight: "bold" }}
                                label={t('create_new_tariff')}
                                checked={isVisible}
                                onChange={() => setIsVisible(!isVisible)}
                            />
                        </> 
                    }

                    { isVisible && 
                        <Form onSubmit={onSubmit} style={{ marginTop: "1rem" }}>
                            <Message visible={tariffs.length > 0} warning>
                                <span style={{ color: "var(--dark)", fontWeight: "bold" }}>
                                    <Icon name="warning" style={{ marginRight: "0.5rem", fontSize: "1.2rem" }}/>
                                    <span style={{ position: "relative", top: "-0.15rem" }}>
                                        { t('tariff_with_the_same_type_will_be_replaced') + "!" }
                                    </span>
                                </span>
                            </Message>
                            <NonFieldErrors errors={errors}/>
                            <Form.Group widths="equal">
                                <SuperField as="input"
                                    required
                                    autoFocus
                                    label={t('sum')}
                                    value={form.amount.replace(',', '.')}
                                    error={((form.amount <= 0 || isNaN(form.amount)) && form.amount !== "") ? t('value_higher_then_zero') : false}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, amount: value.replace(',', '.')}))}
                                />
                                <SuperField as="choice"
                                    search
                                    required
                                    disabled
                                    type="currency_codes"
                                    label={t('currency')}
                                    value={form.currency}
                                    onChange={(e, { value }) => setForm(prev => ({...prev, currency: value === "" ? "EUR" : value}))}
                                />
                            </Form.Group>
                            <SuperField as="choice"
                                required
                                label={t('type')}
                                customOptions={!isProductivity 
                                    ? 
                                        [
                                            { key: 1, value: 1, text: t('per_hour_payments') },
                                            { key: 2, value: 2, text: t('one_time_payments') },
                                        ]
                                    : 
                                        [
                                            { key: 1, value: 3, text: t('piece_work_payments') },
                                            { key: 2, value: 2, text: t('one_time_payments') },
                                        ]
                                }
                                value={form.type}
                                onChange={(e, { value }) => setForm(prev => ({...form, type: value === "" ? 1 : value}))}
                            />
                            <SuperField
                                search
                                required={costCenter?.id}
                                as="choice"
                                label={t('cost_center_record_category')}
                                help={t('cost_center_record_category_hint')}
                                endpoint={API.COST_CENTERS_RECORD_CATEGORIES + "?type=2"}
                                text="title"
                                value={form.category}
                                onChange={(e, { value }) => setForm({ ...form, category: value })}
                            />
                            <Divider/>
                            <Form.Field style={{ textAlign: 'right' }}>
                                <ModalCancel onClose={onClose}/>
                                <ModalSubmit
                                    text={t('confirm')}
                                    loading={processing}
                                    disabled={processing || !isFormValid()}
                                />
                            </Form.Field>
                        </Form>
                    }
                </>
            }
        </Segment>
    )
}

export default TariffForm;