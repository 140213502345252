import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { dates } from '@store/services/helpers/dates'
// store
import { routes } from '@routes'
import { API } from '@store/config'
// components
import { Icon } from 'semantic-ui-react'
import ListView from '@components/ListView'
import SuperField from '@components/forms/SuperField'
import CanView from '@components/perms/CanView'
import {
    ProjectApproval,
    BudgetApproval,
    InvoiceApproval,
    InvoiceApprover,
    RecruitmentStageApproval,
    RecruitmentApproval,
    PlannedCourseApproval,
    TimesheetApproval,
    BonusPaymentApproval,
    AdvancePaymentApproval,
    DeductionPaymentApproval,
} from '../components/Approvals'

const DeniedApprovals = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    let stateOptions = [
        { key: 1, text: t('invoices'), value: 'invoice' },
        { key: 2, text: t('budget'), value: 'budget' },
        { key: 3, text: t('planned_courses'), value: 'plannedcourse' },
        { key: 4, text: t('projects'), value: 'project' },
        { key: 5, text: t('recruitments'), value: 'recruitment' },
        { key: 6, text: t('recruitments_stage_move'), value: 'recruitmentstage' },
        { key: 6, text: t('payroll__bonus'), value: 'bonuspayment' },
        { key: 8, text: t('payroll__advance'), value: 'advancepayment' },
        { key: 9, text: t('payroll__deduction'), value: 'deductionspayment' },
    ]

    const getResourceName = (name) => {
        if (name === 'RecruitmentApproval') return t('recruitment')
        if (name === 'PlannedCourseApproval') return t('planned_course')
        if (name === 'InvoiceApproval') return t('invoice')
        if (name === 'AttendanceRecordPlannerApproval') return t('attendance_record_planner')
        if (name === 'BenefitApproval') return t('benefits')
        if (name === 'CostCenterRecordApproval') return t('cost_center_record')
        if (name === 'EventAbsenceApproval') return t('event_absence')
        if (name === 'ProjectApproval') return t('projects')
        if (name === 'RecruitmentStageApproval') return t('recruitment_stage')
        if (name === 'TimesheetApproval') return t('timesheet')
        if (name === 'BonusPaymentApproval') return t('payroll__bonus')
        if (name === 'AdvancePaymentApproval') return t('payroll__advance')
        if (name === 'DeductionPaymentApproval') return t('payroll__deduction')
    }

    return (
        <CanView
            permissions={['approvals.c_view_all_approvals', 'approvals.c_view_user_approvals']}
            route={routes.APPROVALS + 'denied/'}
            redirect
        >
            <ListView
                as="table"
                endpoint={API.APPROVALS}
                query={`&awaiting_approval=false&is_approved=false`}
                initialFilters={{
                    resource_type: '',
                    responsible_person: '',
                    approved_by: '',
                    ordering: '-created_on',
                }}
                listAdditionActions={() => [
                    {
                        as: 'link',
                        name: t('pending'),
                        redirect: routes.APPROVALS,
                    },
                    {
                        as: 'link',
                        name: t('approved'),
                        redirect: routes.APPROVALS + 'approved/',
                    },
                    {
                        as: 'link',
                        name: t('denied'),
                        redirect: routes.APPROVALS + 'denied/',
                        isActive: true,
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <CanView permissions={['approvals.c_view_all_approvals']}>
                            <SuperField
                                as="choice"
                                search
                                label={t('responsible_person')}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                                text="fullname"
                                value={filters.responsible_person}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        responsible_person: value,
                                    })
                                }
                            />
                            <SuperField
                                as="choice"
                                label={t('resource')}
                                search
                                customOptions={stateOptions}
                                value={filters.resource_type}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        resource_type: value,
                                    })
                                }
                            />
                            <SuperField
                                as="choice"
                                search
                                label={t('approved_by')}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                                text="fullname"
                                value={filters.approved_by}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        approved_by: value,
                                    })
                                }
                            />
                        </CanView>
                    </>
                )}
                tableHeaders={[
                    { title: t('requested_approval') },
                    { title: t('resource') },
                    { title: t('requested_when') },
                    { title: t('approval_status') },
                    { title: t('responsible_person') },
                ]}
                renderCells={(approval) => [
                    {
                        content: (
                            <>
                                {approval.resourcetype === 'RecruitmentApproval' && (
                                    <RecruitmentApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'PlannedCourseApproval' && (
                                    <PlannedCourseApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'ProjectApproval' && <ProjectApproval approval={approval} />}
                                {approval.resourcetype === 'BudgetApproval' && <BudgetApproval approval={approval} />}
                                {approval.resourcetype === 'InvoiceApproval' && <InvoiceApproval approval={approval} />}
                                {approval.resourcetype === 'RecruitmentStageApproval' && (
                                    <RecruitmentStageApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'TimesheetApproval' && (
                                    <TimesheetApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'BonusPaymentApproval' && (
                                    <BonusPaymentApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'AdvancePaymentApproval' && (
                                    <AdvancePaymentApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'DeductionPaymentApproval' && (
                                    <DeductionPaymentApproval approval={approval} />
                                )}
                            </>
                        ),
                    },
                    { content: getResourceName(approval.resourcetype) },
                    {
                        content: moment(dates?.convertUTCDateToLocalDate(approval?.created_on)).format(
                            `${dateFormat} HH:mm`
                        ),
                    },
                    { content: t('denied') },
                    {
                        content:
                            approval.resourcetype === 'InvoiceApproval' ? (
                                <>
                                    <InvoiceApprover
                                        approver={approval.responsible_person}
                                        decision={approval.approved_by_responsible_person}
                                        note={approval.responsible_person_note}
                                        type={'responsible_person'}
                                    />
                                    <InvoiceApprover
                                        approver={approval.second_responsible_person}
                                        decision={approval.approved_by_second_responsible_person}
                                        note={approval.second_responsible_person_note}
                                        type={'second_responsible_person'}
                                    />
                                    <InvoiceApprover
                                        approver={approval.reviewer}
                                        decision={approval.approved_by_reviewer}
                                        note={approval.reviewer_note}
                                        type={'reviewer_person'}
                                    />
                                    <InvoiceApprover
                                        approver={approval.second_reviewer}
                                        decision={approval.approved_by_second_reviewer}
                                        note={approval.second_reviewer_note}
                                        type={'second_reviewer_person'}
                                    />
                                </>
                            ) : (
                                <>
                                    {approval.responsible_person?.fullname ? (
                                        <>
                                            <Icon name="circle outline" style={{ color: 'transparent' }} />
                                            <Link
                                                className="ref-link"
                                                to={routes.EMPLYOEES_DETAIL + approval?.responsible_person?.id}
                                                target="_blank"
                                            >
                                                {approval.responsible_person?.fullname}
                                            </Link>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ),
                    },
                ]}
            />
        </CanView>
    )
}

export default DeniedApprovals
