import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';

// components
import { Grid, Form, Message, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const ProjectMemberCreateForm = ({ onConfirm, onClose, project }) => {
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState(null);

    const [form, setForm] = useState({
        profile: null,
        project: project.id,
        gitlab_role: null
    })

     const handleSubmit = async () => {
        setIsProcessing(true);
        const result = await requests.post(API.PROJECT_MEMBERS, form)

        if( result.status === 201 ){
            onConfirm(result.response)
            onClose()
        } else if( result.status === 400 ) {
            setErrors(result.response)
        }
        setIsProcessing(false)

    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Message
                    error
                    visible={errors?.non_field_errors?.length > 0}
                    header={t("error_submission_message")}
                    list={errors?.non_field_errors}
                />
                <Grid.Row>
                    <Grid.Column>
                        <SuperField as="choice"
                            search
                            style={{marginTop: '4px'}}
                            text="fullname"
                            label={t('employee')}
                            value={form?.profile?.id}
                            error={ errors === null ? false : errors?.profile }
                            onChange={(e, { value }) =>
                                setForm({ ...form, profile: value})
                            }
                            endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                        />
                        { project?.allow_gitlab &&
                        <SuperField as="choice"
                            type="gitlab_roles"
                            label={t('gitlab_role')}
                            value={form.gitlab_role}
                            onChange={ (e, {value}) => setForm({ ...form, gitlab_role: value }) }
                        />
                        }
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field style={{ textAlign: "right", margin: "1rem" }}>
                            <Button disabled={isProcessing} loading={isProcessing} size="large" primary> {t('save')} </Button>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Form>

        </>
    )
}

export default ProjectMemberCreateForm;
