import React from 'react';
import Icon from '@components/Icon';
import { FlexItem, FlexRow } from '@components/tables/FlexTable';

const BackTo = ({ onClick, title, header }) => {
    return (
        <>
            <FlexRow>
                <FlexItem basis="150%">
                    <Icon name="arrow-back-outline" title={title} className="back-to" onClick={onClick} />
                </FlexItem>
                <FlexItem basis="150%">
                    <h2 style={{ textAlign: 'center' }}>{header}</h2>
                </FlexItem>
                <FlexItem basis="150%" />
            </FlexRow>
        </>
    );
};

export default BackTo;
