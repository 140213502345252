import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { commonService } from '@services/ServiceCommon';
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
import { Button, Divider, Form } from 'semantic-ui-react';
import AddressFieldGroup from '@components/forms/common/AddressFieldGroup';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ChoiceField from '@components/forms/common/ChoiceField';

const WorkplaceForm = ({ workplace, setWorkplace, jobOffer, setJobOffer, onClose }) => {
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true)

        if (workplace.id > 0) { // update
            const addressResult = await commonService.updateAddress(workplace.address, {
                street: workplace.street,
                city: workplace.city,
                postcode: workplace.postcode,
                country: workplace.country,
                state: workplace.state,
                address_Line: workplace.addressLine,
            })

            if (addressResult.status === 200) {
                // create workPlace

                const result = await requests.put(API.JOB_WORKPLACES + workplace.id + "/", {
                    workplace_type: workplace.workplace_type,
                    address: addressResult.response.id
                })

                if (result.status === 200) {
                    setWorkplace({
                        ...workplace,
                        workplace_type: workplace.workplace_type,
                        address: addressResult.response.id
                    })
                    onClose()
                }
            }
        } else { // create
            const addressResult = await commonService.createAddress({
                street: workplace.street,
                city: workplace.city,
                postcode: workplace.postcode,
                country: workplace.country,
                state: workplace.state,
                address_Line: workplace.addressLine,
            })

            if (addressResult.status === 201) {
                // create workPlace
                const result = await requests.post(API.JOB_WORKPLACES, {
                    workplace_type: workplace.workplace_type,
                    address: addressResult.response.id
                })

                if (result.status === 201) {
                    setJobOffer({
                        ...jobOffer,
                        workplace: result.response.id
                    })
                    setWorkplace({
                        ...workplace,
                        address: addressResult.response.id,
                        id: result.response.id
                    })

                    onClose()
                }
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form>
            <ChoiceField
                required
                label={t('workplace_type')}
                type="workplace_type"
                value={workplace.workplace_type}
                onChange={(e, { value }) => setWorkplace({
                    ...workplace,
                    workplace_type: value
                })}
            />

            <Divider horizontal>{t('address')}</Divider>
            <AddressFieldGroup address={workplace} setAddress={setWorkplace} />

            <Divider />
            <Button.Group floated="right" style={{ marginBottom: "1rem" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    text={t('save')}
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                    disabled={isProcessing}
                    loading={isProcessing}
                />
            </Button.Group>
        </Form>
    );
};

export default WorkplaceForm;