import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// store
import { routes } from '@routes';
// components
import { Button, Header, Image } from 'semantic-ui-react';

const GdprExtendSuccess = ({ logo }) => {
    const { t } = useTranslation()
    return (
        <div style={{ textAlign: 'center', marginTop: "10rem" }}>
            { logo &&
                <Image size="tiny" src={logo} style={{ textAlign: "center", margin: "0 auto", height: "100%", width: "100%", maxWidth: "250px"}} />
            }

            <Header as="h1">
                { t('your_gdpr_was_extended') }
            </Header>

            <div style={{ marginTop: "2.5rem", textAlign: "center" }}>
                <Button size="large" as={Link} to={routes.DASHBOARD} primary>
                    { t('enter_dashboard') }
                </Button>
            </div>
        </div>
    );
};

export default GdprExtendSuccess;