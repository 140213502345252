import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests'
// components
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line
} from "recharts";
import { Segment, Popup } from 'semantic-ui-react';

const getDates = (from, to) => {
    let dates = []

    from = moment(from)
    to = moment(to)

    // add logic to calculate days between two date range
    for (let day = from; day.isSameOrBefore(to); day.add(1, 'days')) {
        dates.push(day.format('YYYY-MM-DD'));
    }

    return dates
}

const TimesheetViewGraph = ({ view, employee, dateFrom, dateTo, params, legendPosition }) => {
    const { t } = useTranslation()
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(true)
    const colors = useSelector(state => state.global_pref.colors)
    const dateFormat = useSelector(state => state.date_format)

    // datakeys
    const [activities, setActivities] = useState([])
    const [accounts, setAccounts] = useState([])
    const [orders, setOrders] = useState([])
    const [projects, setProjects] = useState([])

    view = view || "hours"

    useEffect(() => {
        async function fetchRecords(){
            const dates = getDates(dateFrom, dateTo)
            setRecords([])
            setLoading(true)
            let customParams = ""
            
            if (params !== undefined) {
                customParams += params
            }  else {
                const profileFilter = employee?.id ? "&profile=" + employee.id : ""
                const dateRangeParams ="&start_date=" + dateFrom.format("YYYY-MM-DD") + "&end_date=" + dateTo.format("YYYY-MM-DD")

                customParams += dateRangeParams + profileFilter
            }

            let extraParam = ""
            if (view === "activities") {
                extraParam += "&include_categories=true"
            } else if(view === "clients") {
                extraParam += "&include_accounts=true"
            } else if(view === "orders") {
                extraParam += "&include_orders=true"
            } else if(view === "projects") {
                extraParam += "&include_projects=true"
            }
            
            const request = await requests.get(API.REPORTS + "timesheets/daily/stats/?paginate=false" + customParams + extraParam)

            if (request.status === 200) {
                let fetchedRecords = request.response
                const labels = fetchedRecords.shift()

                const activityList = []
                if (view === "activities") { 
                    let categories = labels?.category_labels || []
                    for (let i = 0; i < categories.length; i++) {
                        if (activityList.find(item => item.id === categories[i].id) === undefined) {
                            activityList.push({
                                id: categories[i].id,
                                title: categories[i].title,
                                color: categories[i].color
                            })
                        }
                    }
                }

                const accountList = []
                if (view === "clients") { 
                    let tmpAccounts = labels?.account_labels || []
                    for (let i = 0; i < tmpAccounts.length; i++) {
                        if (accountList.find(item => item.id === tmpAccounts[i].id) === undefined) {
                            accountList.push({
                                id: tmpAccounts[i].id,
                                title: tmpAccounts[i].name,
                                color: tmpAccounts[i].color
                            })
                        }
                    }
                }

                const orderList = []
                if (view === "orders") { 
                    let tmpOrders = labels?.order_labels || []
                    for (let i = 0; i < tmpOrders.length; i++) {
                        if (orderList.find(item => item.id === tmpOrders[i].id) === undefined) {
                            orderList.push({
                                id: tmpOrders[i].id,
                                title: tmpOrders[i].name,
                                color: tmpOrders[i].color
                            })
                        }
                    }
                }

                const projectList = []
                if (view === "projects") { 
                    let tmpProjects = labels?.project_labels || []
                    for (let i = 0; i < tmpProjects.length; i++) {
                        if (projectList.find(item => item.id === tmpProjects[i].id) === undefined) {
                            projectList.push({
                                id: tmpProjects[i].id,
                                title: tmpProjects[i].name,
                                color: tmpProjects[i].color
                            })
                        }
                    }
                }

                const tmpRecords = []
                for (let i = 0; i < dates.length; i++) {
                    const day = dates[i] 
                    let record = fetchedRecords.find(item => item.date === moment(day).format("YYYY-MM-DD"))

                    let tmpRecord = {
                        name: moment(day).format("DD"),
                        date: moment(day).format(dateFormat),
                        total: 0
                    }

                    if (view === "hours") {
                        tmpRecord['hours'] = record?.total_hours || 0
                        tmpRecord['total'] = record?.total_hours || 0
                    } else if (view === "activities") {
                        let totalHours =  0
                        for (let y = 0; y < activityList.length; y++) {
                            let category = record?.categories?.find(item => item.id === activityList[y].id)
                            tmpRecord[activityList[y].id] = category?.hours || 0
                            tmpRecord[activityList[y].title] = category?.title || t('undefined')

                            totalHours += parseFloat((category?.hours || 0) + totalHours)
                        }

                        tmpRecord['total'] = totalHours.toFixed(2)
                    } else if (view === "clients") {
                        let totalHours =  0
                        for (let y = 0; y < accountList.length; y++) {
                            let account = record?.accounts?.find(item => item.id === accountList[y].id)
                            tmpRecord[accountList[y].id] = account?.hours || 0
                            tmpRecord[accountList[y].title] = account?.name || t('undefined')

                            totalHours += parseFloat((account?.hours || 0) + totalHours)
                        }

                        tmpRecord['total'] = totalHours.toFixed(2)
                    } else if (view === "orders") {
                        let totalHours =  0
                        for (let y = 0; y < orderList.length; y++) {
                            let order = record?.orders?.find(item => item.id === orderList[y].id)
                            tmpRecord[orderList[y].id] = order?.hours || 0
                            tmpRecord[orderList[y].title] = order?.name || t('undefined')

                            totalHours += parseFloat((order?.hours || 0) + totalHours)
                        }

                        tmpRecord['total'] = totalHours.toFixed(2)
                    } else if (view === "projects") {
                        let totalHours =  0
                        for (let y = 0; y < projectList.length; y++) {
                            let project = record?.projects?.find(item => item.id === projectList[y].id)
                            tmpRecord[projectList[y].id] = project?.hours || 0
                            tmpRecord[projectList[y].title] = project?.name || t('undefined')

                            totalHours += parseFloat((project?.hours || 0) + totalHours)
                        }

                        tmpRecord['total'] = totalHours.toFixed(2)
                    }


                    tmpRecords.push(tmpRecord)  
                }

                setRecords(tmpRecords)
                setActivities(activityList)
                setAccounts(accountList)
                setOrders(orderList)
                setProjects(projectList)
            }
            setLoading(false)
        }

        fetchRecords()
        // eslint-disable-next-line
    }, [params, view])

    const CustomTooltip = ({ active, payload, label }) => {
        const { t } = useTranslation()
        if (active && payload && payload.length) {
            return (
                <div style={{ padding: "1rem", background: "var(--white)", border: "0.5px solid var(--dark)", textAlign: "left" }}>
                    <p style={{ fontWeight: "bold", paddingBottom: 0 }}>{payload[0].payload.date}</p>
                    { payload.map((item, idx) => (
                        <span key={idx}>
                            { item.value > 0 && 
                                <p style={{ display: "flex", flexDirection: "row", alignItems: "left", justifyContent: "left center", textAlign: "left" }}>
                                    <div style={{ width: "10px", height: "10px", background: item.fill, marginRight: "0.25rem" }}></div>
                                    <div style={{ position: "relative", top: "-0.35rem" }}>
                                        <span style={{ color: item.fill }}>{item.name}</span>: {" "}
                                        <strong>
                                            {item.value} <span style={{ textTransform: "lowercase" }}>{ t('hours_shortcut') }.</span>
                                        </strong>
                                    </div>
                                </p>
                            }
                        </span>
                    ))}
                </div>
            );
        }
      
        return null
    }

    const CustomLegend = ({ payload }) => {
        const { t } = useTranslation()

        const isOverflow = payload.length > 10

        return (
            <div style={{ textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                { payload.map((item, index) => (
                    <>
                        { index + 1 <= 10 && 
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left center", marginRight: "0.5rem" }}>
                                <div style={{ width: "10px", height: "10px", background: item.color, marginRight: "0.25rem" }}></div>
                                <div style={{ color: item.color, position: "relative", top: "-0.35rem" }}>
                                    { item.value }
                                </div>
                            </div>
                        }
                    </>
                ))}

                { isOverflow && 
                    <Popup
                        hoverable
                        position='left center'
                        trigger={
                            <div style={{ position: "relative", top: "-0.35rem", cursor: "pointer", fontWeight: "bold" }}>
                                ... { payload.length - 10 } { t('legend__more_labels') }
                            </div>
                        }
                        content={
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                { payload.map((item, index) => (
                                    <>
                                        { index + 1 > 10 && 
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left center" }}>
                                                <div style={{ width: "10px", height: "10px", background: item.color, marginRight: "0.25rem" }}></div>
                                                <div style={{ color: item.color, position: "relative", top: "-0.35rem" }}>
                                                    { item.value }
                                                </div>
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        }
                    />
                }
            </div>
        )
    }

    return (
        <Segment 
            loading={loading}
            style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
            }}
        >
            { loading && 
                <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                { t('loading_monthly_overview') } 
                </p>
            }
            { !loading && 
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart
                        data={records}
                        margin={{
                            top: 0,
                            right: 0,
                            left: -40,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend content={<CustomLegend/>} verticalAlign={legendPosition || "top"} height={36}/>
                        <>
                            { view === "hours" && (
                                <Bar dataKey="hours" name={t('hours')} stackId="a" fill={colors.primary} />
                            )}

                            { view === "activities" && (
                                <>
                                    { activities.map(item => (
                                        <Bar key={item.id} dataKey={item.id} name={item.title} stackId="1" fill={item.color || colors.primary} />
                                    )) }
                                </>
                            )}

                            { view === "clients" && (
                                <>
                                    { accounts.map(item => (
                                        <Bar key={item.id} dataKey={item.id} name={item.title} stackId="1" fill={item.color || colors.primary} />
                                    )) }
                                </>
                            )}

                            { view === "orders" && (
                                <>
                                    { orders.map(item => (
                                        <Bar key={item.id} dataKey={item.id} name={item.title} stackId="1" fill={item.color || colors.primary} />
                                    )) }
                                </>
                            )}

                            { view === "projects" && (
                                <>
                                    { projects.map(item => (
                                        <Bar key={item.id} dataKey={item.id} name={item.title} stackId="1" fill={item.color || colors.primary} />
                                    )) }
                                </>
                            )}
                        </>
                        <Line name={t('total')} type="monotone" dataKey="total" stroke={colors.primary} />
                    </BarChart>
                </ResponsiveContainer>
            }
        </Segment>
        
    );
};

export default TimesheetViewGraph;