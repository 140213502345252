import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    isEmpty,
    isPasswordError,
    isRepeatPasswordError,
} from "../../../store/services/helpers/validation";
import { 
    resetPasswordService
} from "../../../store/services/ServiceResetPassword";

// routes
import { routes } from "../../../store/routes/index";

// design
import { 
    Icon,
    Form, 
    Segment, 
    Container, 
    Grid, 
    Image,
} from "semantic-ui-react";

// components
import ConfirmSuccessMessage from "./helpers/ConfirmSuccessMessage";

// component
function ChooseYourPasswordForm(props) {
    const [newPassword, setNewPassword] = useState("");
    const [newAgainPassword, setNewAgainPassword] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [confirmSuccessful, setConfirmSuccessful] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;

        if (
            isPasswordError(newPassword) ||
            isRepeatPasswordError(newPassword, newAgainPassword)
        ) {
            formError = true;
        }

        if (!formError) {
            // create dataset:
            const data = {
                uid: props.data.uid,
                token: props.data.token,
                new_password: newPassword,
            };

            // todo send reset password confirmation
            if (await resetPasswordService.resetPasswordConfirm(data)) {
                setConfirmSuccessful(true);
            } else {
                alert("Chyba pri resetovaní hesla.");
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Container>
            <Grid centered>
                <Grid.Column style={{ maxWidth: 450 }}>
                    {!confirmSuccessful ? (
                        <Form onSubmit={handleSubmit}>
                            <Segment>
                                <Link to={routes.HOME}>
                                    <Image centered src="/images/logo.svg" width="40%"/>
                                </Link>
                                <h1 style={{textAlign: "center"}}>Enter new password</h1>
                                <Form.Field fluid>
                                    <Form.Input
                                        size="medium"
                                        type="password"
                                        icon="lock"
                                        iconPosition="left"
                                        required
                                        autoFocus
                                        fluid
                                        label="New password"
                                        placeholder="New password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        error={
                                            !isEmpty(newPassword)
                                                ? isPasswordError(newPassword)
                                                : false
                                        }
                                    />
                                </Form.Field>

                                <Form.Field fluid>
                                    <Form.Input
                                        size="medium"
                                        type="password"
                                        icon="lock"
                                        iconPosition="left"
                                        required
                                        fluid
                                        label="New password again"
                                        placeholder="New password again"
                                        value={newAgainPassword}
                                        onChange={(e) =>
                                            setNewAgainPassword(e.target.value)
                                        }
                                        error={
                                            !isEmpty(newAgainPassword)
                                                ? isRepeatPasswordError(
                                                    newPassword,
                                                    newAgainPassword
                                                )
                                                : false
                                        }
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Icon name="info" circular />
                                    <label style={{ display: "inline-block" }}>
                                        Requirements: 
                                        <strong style={{paddingLeft: ".3rem"}}>
                                            1 capital letter, 1 number, min. 8 characters
                                        </strong>
                                    </label>
                                </Form.Field>

                                <Form.Button
                                    fluid primary
                                    size="huge"
                                    type="submit"
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        isEmpty(newPassword) ||
                                        isEmpty(newAgainPassword)
                                    }
                                >
                                    Reset my password
                                </Form.Button>
                            </Segment>
                        </Form>
                    ) : (
                        <Segment className="center-segment" textAlign="center">
                            <ConfirmSuccessMessage />
                        </Segment>
                    )}
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default connect()(ChooseYourPasswordForm);
