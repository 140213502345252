import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
//store
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
import { patchRequest } from '@services/ServiceCommon';
// components
import { Form } from 'semantic-ui-react';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const EmployeeBenefitsForm = ({ excludeBenefits, onClose, employeeID, setData }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false);
    const [benefit, setBenefit] = useState("");
    const [validUntil, setValidUntil] = useState("");

    const handleSubmit = async () => {
        setIsProcessing(true);

        const request = await patchRequest(API.EMPLOYEES + employeeID + "/", {
            benefits: {
                add: [benefit]
            },
            benefit_valid_until: validUntil
        })
        if (request.status === 200) {
            setData(prevState => ({
                ...prevState,
                benefits: request.response.benefits
            }))
            onClose()
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="choice"
                required
                placeholder={t('benefits')}
                exclude={excludeBenefits}
                endpoint={API.BENEFITS + "?query={id, name}"}
                text="name"
                label={t('benefits')}
                value={benefit}
                onChange={(e, { value }) => setBenefit(value)}
            />
            <SuperField as="datepicker"
                label={ t('valid_from') }
                required
                value={validUntil}
                onChange={(e, { value }) => setValidUntil(value)}
            />

            <Form.Field style={{ textAlign: "right" }}>
                <ModalSubmit
                    disabled={isProcessing || isEmpty(benefit) || benefit === 0}
                    loading={isProcessing}
                    text={t('add_benefit')}
                />
            </Form.Field>

        </Form>
    );
};

export default EmployeeBenefitsForm;