import React from 'react'
import { useTranslation } from "react-i18next"
//components
import { Card } from 'semantic-ui-react';
import PermissionCategories from './PermissionCategories';

const CATEGORIES = [
    { name: 'core' },
    { name: 'human_resources' },
    { name: 'projects' },
    { name: 'finance' },
    { name: 'operations' },
]

const CustomRulesetModules = ({ permissions, groupPermissions, setGroupPermissions, group }) => {
    const { t } = useTranslation();

    return (
        <Card fluid style={{ borderRadius: 0, padding: '1rem', margin: 0 }}>
            {CATEGORIES.map(category => (
                <PermissionCategories
                    key={category.name}
                    label={t(category.name)}
                    group={group}
                    category={category.name}
                    permissions={permissions}
                    groupPermissions={groupPermissions}
                    setGroupPermissions={setGroupPermissions}
                />
            ))}
        </Card>
    )
}

export default CustomRulesetModules