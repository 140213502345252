import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { setDotSeparator, isValidNumber, isDescendantElement } from '@helpers/functions';
// components
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import SpinnerSegment from '@components/SpinnerSegment';
import ModalCancel from '@components/buttons/ModalCancel';
import { Divider, Form, Button, Message, Header, Dropdown, Input, Label, Icon as SemanticIcon } from 'semantic-ui-react';

const StockItemCorrectionForm = ({ storedItem, setData, onClose }) => {
    const { t } = useTranslation()
    const dropdownRef = useRef(null);
    const profile = useSelector(state => state.user?.profile?.id || null)

    const [errors, setErrors] = useState(null)
    const [focus, setFocus] = useState(false)

    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [search, setSearch] = useState("")
    const [searching, setSearching] = useState(false)
    const [positions, setPositions] = useState([])
    const [form, setForm] = useState({
        quantity: 1,
        note: "",
        stored_item: storedItem.id,
        warehouse_position: "",
    })

    const selectedPosition = positions.find(item => item.warehouse_position.id === form.warehouse_position)

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)
        
        const request = await requests.post(API.ASSETS + "warehouse_process_operations/", {
            operation: 1,
            note: form.note,
            quantity: form.quantity,
            stored_item: storedItem.id,
            performed_by: profile,
            warehouse_position: form.warehouse_position || null,
        })

        const requestStoredItem = await requests.get(API.ASSET_STORED_ITEMS + storedItem.id + "/")
        if (request.status === 400) setErrors(request.response)
        if (request.status === 201) {
            setData(prev => prev.map(item => {
                if (item.id === requestStoredItem.response.id) {
                    item = requestStoredItem.response
                }
                return item
            }))
            onClose()
        }

        setProcessing(false)
    }

    const isFormValid = () => {
        if (!isValidNumber(form.quantity, false, true)) return false

        return true
    }

    const handleGlobalClick = (event) => {
        if (dropdownRef.current?.ref?.current !== event.target && !isDescendantElement(dropdownRef.current?.ref?.current, event.target)) {
            setFocus(false)
        }
    }

    async function fetchAvailablePositions(search, forceSearch) {
        let searchFilter = ""
        if (search !== "" || forceSearch) {
            setSearching(true)
            searchFilter += "&search=" + search
        } else {
            setLoading(true)
        }
        const request = await requests.get(API.ASSETS + "stored_item_positions/?stored_item=" + storedItem.id + searchFilter)
        if (request.status === 200) setPositions(request.response)

        setLoading(false)
        setSearching(false)
    }

    useEffect(() => {
        // Attach the global click event listener when the component mounts
        document.addEventListener('mousedown', handleGlobalClick)
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('mousedown', handleGlobalClick)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === 'Enter') {
                await fetchAvailablePositions(search, true)
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        fetchAvailablePositions(search)
        // eslint-disable-next-line
    }, [])

    return (
        <SpinnerSegment loading={loading}>
            { !loading && 
                <Form onSubmit={handleSubmit}>
                    <Message
                        info
                        visible
                        content={
                            <div>
                                <Header as="h4"><strong>{ t("item_to_correct") }:</strong></Header>
                                { t('name') }: <strong>{ storedItem.catalogue_item?.code ? storedItem.catalogue_item?.code + " - " : "" }{ storedItem.catalogue_item.title }</strong><br/>
                                { t('warehouse') }: <strong>{ storedItem.warehouse.title }</strong><br/>
                            </div>
                        }
                    />   

                    <NonFieldErrors errors={errors}/>
                    <Divider/>
                    <Form.Field>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "0.2rem" }}>
                            <label style={{ fontWeight: "bold" }}>{ t('select_warehouse_position') } <sup style={{ color: "var(--danger)" }}>*</sup></label>
                            { form.warehouse_position !== "" && 
                                <div
                                    className="ref-link"
                                    style={{ color: "var(--primary)" }}
                                    onClick={() => {
                                        setForm(prev => ({...prev, warehouse_position: ""}))
                                        setFocus(false)
                                    }}
                                >
                                    { t('clear_selection') }
                                </div> 
                            }
                        </div>
                        <Dropdown 
                            fluid
                            basic
                            scrolling
                            open={focus}
                            onOpen={() => setFocus(true)}
                            ref={dropdownRef}
                            as={Button}
                            type="button"
                            style={{ height: "40px" }}
                            text={
                                selectedPosition 
                                    ? (selectedPosition.warehouse_position.title + (selectedPosition.warehouse_position.section && " / " + selectedPosition.warehouse_position.section ) +  (selectedPosition.warehouse_position.row && " / " + selectedPosition.warehouse_position.row)) 
                                    : t('select_an_option')
                            }
                        >
                                <Dropdown.Menu style={{ borderRadius: "0" }}>
                                    <Input
                                        loading={searching}
                                        size="small"
                                        icon={
                                            <SemanticIcon
                                                link
                                                name="search"
                                                onClick={() => fetchAvailablePositions(search, true)}
                                            />
                                        }
                                        style={{ margin: "1rem", height: "40px" }}
                                        value={search}
                                        placeholder={t('search')}
                                        onChange={(e, { value }) => setSearch(value)}
                                    />
                                    <EmptyRow length={positions.length} align="left" message={t('there_is_no_stocked_position_for_this_item')}/>
                                    { positions.length > 0 && 
                                        <>
                                            <Dropdown.Divider/>
                                            <Dropdown.Header style={{ textTransform: "capitalize", opacity: "0.7" }} content={t('occupied_positions') + " ("+ positions.length +") "}/>
                                            <Dropdown.Divider/>
                                        </>
                                    }

                                    { positions.map(item => (
                                        <Dropdown.Item active={form.warehouse_position === item.warehouse_position.id} onClick={() => {
                                            setForm(prev => ({...prev, warehouse_position: item.warehouse_position.id}))
                                            setFocus(false)
                                        }}>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <div style={{ fontWeight: "bold" }}>
                                                    { item.warehouse_position.title } { item.warehouse_position.section && " / " + item.warehouse_position.section } { item.warehouse_position.row && " / " + item.warehouse_position.row }
                                                </div>
                                                <Label basic style={{ opacity: "0.7" }}> { item.quantity || 0 } / { item.warehouse_position.quantity_limit || t('unlimited') } </Label>
                                            </div>
                                        </Dropdown.Item>
                                    )) }
                                </Dropdown.Menu>
                        </Dropdown>
                        
                    </Form.Field>
                    { form.warehouse_position !== "" &&
                        <>
                            <SuperField as="input"
                                key={form.warehouse_position}
                                required
                                autoFocus
                                label={t('quantity')}
                                help={t('enter_correction_quantity_hint')}
                                value={form.quantity}
                                error={errors !== null ? errors?.[0]?.quantity : !isValidNumber(form.quantity, true, true) ? t('invalid_quantity') : false}
                                onChange={(e, { value }) => setForm(prev => ({
                                    ...prev,
                                    quantity: setDotSeparator(value),
                                }))}
                            />
                            <p>
                                { t('current_stock') }:  { " " }
                                <Label basic>
                                    { selectedPosition.quantity || 0 } / { selectedPosition.warehouse_position.quantity_limit || t('unlimited') }
                                </Label>
                                
                            </p>

                            <Divider/>

                            <SuperField as="textarea"
                                label={t('reason')}
                                value={form.note}
                                onChange={(e, { value }) => setForm(prev => ({
                                    ...prev,
                                    note: value,
                                }))}
                            />
                        </>
                    }

                    <Divider/>
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel onClose={onClose}/>
                        <Button
                            primary
                            loading={processing}
                            content={t('confirm')}
                            disabled={!isFormValid() && form.quantity !== ""}
                        />
                    </Form.Field>
                </Form>
            }
        </SpinnerSegment>
    )
}

export default StockItemCorrectionForm