import React from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
// components
import ListView from '@components/ListView';
import GenerateDocuments from '@components/GenerateDocuments';

const StoredItemOverview = ({ storedItem, setHeader }) => {
    const { t } = useTranslation()

    setHeader((storedItem?.catalogue_item?.code  ? storedItem?.catalogue_item?.code + " - " : "") + storedItem?.catalogue_item?.title + (storedItem?.warehouse?.id ? " (" + storedItem?.warehouse?.title + ") " : ""))

    return (
        <ListView
            allowSearch
            allowSelection
            endpoint={API.ASSETS + "stored_item_positions/"}
            initialFilters={{
                stored_item: storedItem.id
            }}
            tableHeaders={[
                { title: t('warehouse_position') },
                { title: t('current_stock') },
                { title: t('max_storage_capacity') },
            ]}
            renderCells={(storedPosition) => (
                [
                    {
                        content: (storedPosition.warehouse_position.title + (storedPosition.warehouse_position.section && " / " + storedPosition.warehouse_position.section ) +  (storedPosition.warehouse_position.row && " / " + storedPosition.warehouse_position.row))
                    },
                    {
                        content: storedPosition.quantity
                    },
                    {
                        content: storedPosition.warehouse_position.quantity_limit || t('unlimited')
                    },
                ]
            )}
            bulkActions={ (selected) => [
                { 
                    as: "modal",
                    name: t('generate_documents'),
                    icon: "document-text-outline",
                    modal: <GenerateDocuments source="assets.StoredItemPosition" selected={selected} />
                }
            ]}
        />
            
    );
};

export default StoredItemOverview;