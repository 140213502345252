import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import CanView from '@components/perms/CanView'
import Action from '@components/general/Action'
import { Segment, Grid, Header } from 'semantic-ui-react'
// specific component
import IdentityDocForm from './identity-documents/IdentityDocForm'
import IdentityDocument from './identity-documents/IdentityDocument'

const IdentityDocuments = ({ profile, canManage }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [identityDocs, setIdentityDocs] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getIdentityDocuments() {
            setLoading(true)
            const request = await requests.get(API.IDENTITY_DOCUMENTS + `?profile=${profile}`)
            if (request.status === 200) {
                setIdentityDocs(request.response)
            }
            setLoading(false)
        }

        getIdentityDocuments()
    }, [profile])

    const idCard = identityDocs.find((item) => item.type === 4)

    return (
        <Segment
            loading={loading}
            style={{
                padding: 0,
                margin: 0,
                backgroundColor: 'transparent',
                minHeight: '10rem',
                border: 'none',
                boxShadow: 'none',
            }}
        >
            {loading && (
                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                    {' '}
                    {t('loading_identity_documents')}{' '}
                </p>
            )}
            {!loading && (
                <Grid stackable>
                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column style={{ display: 'flex' }}>
                            <Header
                                as="h3"
                                content={t('id_card')}
                                style={{ position: 'relative', top: '0.4rem', marginBottom: 0 }}
                            />
                            {canManage && (
                                <CanView
                                    permisions={[
                                        'common.c_manage_all_employees',
                                        'common.c_manage_user_employees',
                                        'common.c_manage_all_candidates',
                                        'common.c_manage_user_profile',
                                    ]}
                                >
                                    {idCard === undefined && (
                                        <Action
                                            as="modal"
                                            type="icon"
                                            icon="add-outline"
                                            iconColor="var(--dark)"
                                            header={t('add') + ' ' + t('id_card')}
                                            modal={
                                                <IdentityDocForm
                                                    type={4}
                                                    profile_id={profile}
                                                    setData={setIdentityDocs}
                                                />
                                            }
                                        />
                                    )}
                                </CanView>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        {idCard?.id === undefined && <Grid.Column>{t('no_data')}</Grid.Column>}
                        {idCard?.id && (
                            <IdentityDocument
                                profile={profile}
                                document={idCard}
                                setIdentityDocs={setIdentityDocs}
                                canManage={canManage}
                            />
                        )}
                    </Grid.Row>

                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column style={{ display: 'flex' }}>
                            <Header
                                as="h3"
                                content={t('driver_licences')}
                                style={{ position: 'relative', top: '0.4rem', marginBottom: 0 }}
                            />
                            <CanView
                                permisions={[
                                    'common.c_manage_all_employees',
                                    'common.c_manage_user_employees',
                                    'common.c_manage_all_candidates',
                                    'common.c_manage_user_profile',
                                ]}
                            >
                                {canManage && (
                                    <Action
                                        as="modal"
                                        type="icon"
                                        icon="add-outline"
                                        iconColor="var(--dark)"
                                        header={t('add') + ' ' + t('driver_licence')}
                                        modal={
                                            <IdentityDocForm type={1} profile_id={profile} setData={setIdentityDocs} />
                                        }
                                    />
                                )}
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        {identityDocs.filter((item) => item.type === 1).length === 0 && (
                            <Grid.Column>{t('no_data')}</Grid.Column>
                        )}
                        {identityDocs
                            .filter((item) => item.type === 1)
                            .map((item) => (
                                <IdentityDocument
                                    profile={profile}
                                    document={item}
                                    setIdentityDocs={setIdentityDocs}
                                    canManage={canManage}
                                />
                            ))}
                    </Grid.Row>

                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column style={{ display: 'flex' }}>
                            <Header
                                as="h3"
                                content={t('passports')}
                                style={{ position: 'relative', top: '0.4rem', marginBottom: 0 }}
                            />
                            <CanView
                                permisions={[
                                    'common.c_manage_all_employees',
                                    'common.c_manage_user_employees',
                                    'common.c_manage_all_candidates',
                                    'common.c_manage_user_profile',
                                ]}
                            >
                                {canManage && (
                                    <Action
                                        as="modal"
                                        type="icon"
                                        icon="add-outline"
                                        iconColor="var(--dark)"
                                        header={t('add') + ' ' + t('passport')}
                                        modal={
                                            <IdentityDocForm type={2} profile_id={profile} setData={setIdentityDocs} />
                                        }
                                    />
                                )}
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        {identityDocs.filter((item) => item.type === 2).length === 0 && (
                            <Grid.Column>{t('no_data')}</Grid.Column>
                        )}
                        {identityDocs
                            .filter((item) => item.type === 2)
                            .map((item) => (
                                <IdentityDocument
                                    profile={profile}
                                    document={item}
                                    setIdentityDocs={setIdentityDocs}
                                    canManage={canManage}
                                />
                            ))}
                    </Grid.Row>

                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column style={{ display: 'flex' }}>
                            <Header
                                as="h3"
                                content={t('residance_cards')}
                                style={{ position: 'relative', top: '0.4rem', marginBottom: 0 }}
                            />
                            <CanView
                                permisions={[
                                    'common.c_manage_all_employees',
                                    'common.c_manage_user_employees',
                                    'common.c_manage_all_candidates',
                                    'common.c_manage_user_profile',
                                ]}
                            >
                                {canManage && (
                                    <Action
                                        as="modal"
                                        type="icon"
                                        icon="add-outline"
                                        iconColor="var(--dark)"
                                        header={t('add') + ' ' + t('residance_card')}
                                        modal={
                                            <IdentityDocForm type={3} profile_id={profile} setData={setIdentityDocs} />
                                        }
                                    />
                                )}
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        {identityDocs.filter((item) => item.type === 3).length === 0 && (
                            <Grid.Column>{t('no_data')}</Grid.Column>
                        )}
                        {identityDocs
                            .filter((item) => item.type === 3)
                            .map((item) => (
                                <IdentityDocument
                                    profile={profile}
                                    document={item}
                                    setIdentityDocs={setIdentityDocs}
                                    canManage={canManage}
                                />
                            ))}
                    </Grid.Row>

                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column style={{ display: 'flex' }}>
                            <Header
                                as="h3"
                                content={t('temporary_shelter')}
                                style={{ position: 'relative', top: '0.4rem', marginBottom: 0 }}
                            />
                            <CanView
                                permisions={[
                                    'common.c_manage_all_employees',
                                    'common.c_manage_user_employees',
                                    'common.c_manage_all_candidates',
                                    'common.c_manage_user_profile',
                                ]}
                            >
                                {canManage && (
                                    <Action
                                        as="modal"
                                        type="icon"
                                        icon="add-outline"
                                        iconColor="var(--dark)"
                                        header={t('add') + ' ' + t('temporary_shelter')}
                                        modal={
                                            <IdentityDocForm type={5} profile_id={profile} setData={setIdentityDocs} />
                                        }
                                    />
                                )}
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        {identityDocs.filter((item) => item.type === 5).length === 0 && (
                            <Grid.Column>{t('no_data')}</Grid.Column>
                        )}
                        {identityDocs
                            .filter((item) => item.type === 5)
                            .map((item) => (
                                <IdentityDocument
                                    profile={profile}
                                    document={item}
                                    setIdentityDocs={setIdentityDocs}
                                    canManage={canManage}
                                />
                            ))}
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    )
}

export default IdentityDocuments
