import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests';
import { isEmpty } from "@services/helpers/validation"
// components
import { Form, Button, Message } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'



function ExperienceForm({ experience, onConfirm, onClose}) {
    const { t } = useTranslation();

    const [data, setData] = useState({
        id: experience ? experience.id : 0,
        name: experience.employer ? experience.employer : '',
        position: experience.job_title ? experience.job_title : '',
        description: experience.description ? experience.description : '',
        dateFrom: experience.date_from ?  experience.date_from : null,
        dateTo:  experience.date_to ?  experience.date_to : null
    })

    const [errors, setErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.date_to) {
            errorsArray.push(response.date_to)
        }
        if (response.date_from) {
            errorsArray.push(response.date_from)
        }
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }

        setErrors(errorsArray);
    }

    const handleSuccess = (response) => {
        onConfirm(response);
        onClose()
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        let dataset = {
            employer: data.name,
            job_title: data.position,
            description: data.description,
            date_from: data.dateFrom,
            date_to: data.dateTo ? data.dateTo : null,
        }

        if(!data.id || data.id === 0) {
            const request = await requests.post(API.EXPERIENCES, dataset);
            if (request.status === 201) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        } else {
            const request = await requests.patch(API.EXPERIENCES + data.id + '/', dataset);
            if (request.status === 200) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />

            <Form.Field>
                <Form.Input
                    required
                    autoFocus
                    label={t('position')}
                    placeholder={t('position')}
                    value={data.position}
                    onChange={(e) => setData({
                        ...data,
                        position: e.target.value
                    })}
                />
                <Form.Input
                    label={t('employer')}
                    placeholder={t('employer')}
                    value={data.name}
                    onChange={(e) => setData({
                        ...data,
                        name: e.target.value
                    })}
                />
                <Form.TextArea
                    label={t('description')}
                    placeholder={t('description')}
                    value={data.description}
                    onChange={(e) => setData({
                        ...data,
                        description: e.target.value
                    })}
                />
                <DateInput
                    dateFormat="YYYY-MM-DD"
                    name='date'
                    label={t('date_from')}
                    placeholder="2020-01-01"
                    value={data.dateFrom}
                    iconPosition="left"
                    onChange={(e, { value }) =>
                        { value === "" || value === null ?
                        setData({ ...data, dateFrom: null}) :
                        setData({ ...data, dateFrom: value})
                    }}
                />
                <DateInput
                    dateFormat="YYYY-MM-DD"
                    name='date'
                    label={t('date_until')}
                    placeholder="2020-12-30"
                    value={data.dateTo}
                    iconPosition="left"
                    onChange={(e, { value }) =>
                        { value === "" || value === null ?
                        setData({ ...data, dateTo: null}) :
                        setData({ ...data, dateTo: value})
                    }}
                />
            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    basic
                    type="button"
                    onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        isEmpty(data.position)
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default ExperienceForm