import React, { Fragment, useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { isEmpty } from "@helpers/validation"
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//components
import { Form, Button, Message } from 'semantic-ui-react'

function ChangeUsernameForm(props) {
    const { t } = useTranslation();

    // Form States
    const [username, setUsername] = useState("")
    const [current_password, setCurrentPassword] = useState("");
    const [errors, setErrors] = useState([]); // empty array for error messages
    const [isProcessing, setIsProcessing] = useState(false); // this is for handling if form is currently processing our request

    // Form HandleSubmit Function (Async/Await):
    const handleSubmit = async (event) => {
        event.preventDefault(); // to prevent default behaviour for form submission

        setIsProcessing(true); // form processing started
        let errorsArray = [];  // for error response if there are any on failure
        // create data object for API Call:
        const data = {
            current_password: current_password,
            new_username: username
        }

        // call API Endpoint using async/await function from ServiceUser.js
        const result = await requests.post(API.SET_USERNAME, data);

        // DOC: https://djoser.readthedocs.io/en/latest/base_endpoints.html#set-username
        if (result.status === 204) { // 204 means success with empty response object (based on documentation)
            // on success return new username that was changed
            props.onConfirm(data.new_username);
            props.onClose() // close modal window
        } else {
            // username change wasn't successfull handle error response
            if (result.response.new_username) { // if there is error for new_username
                errorsArray.push(result.response.new_username)
            }

            if (result.response.current_password) { // if there is error for new_username
                errorsArray.push(result.response.current_password)
            }

            setErrors(errorsArray); // we are setting errors state with error messages if there are any
        }

        setIsProcessing(false); // form processing ended
    }

    // Simple Component for error message handling (just for example... usage could be improved)
    const ErrorMessages = () => {
        return (
            <Fragment>
                {errors.length > 0 ?
                    <Message negative>
                        {   // this is like forloop cycle we are looping over error array to print errors
                            errors.map((error, index) =>
                                <p key={index}>
                                    {error}
                                </p>
                            )
                        }
                    </Message>
                    : ''}
            </Fragment>
        );
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errors.length > 0
                ? <ErrorMessages />
                : ''
            }

            <Form.Field>
                <Form.Input
                    autoFocus
                    required
                    label={props.labelUsername}
                    placeholder={props.placeholderUsername}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                // error={
                //     !isEmpty(username)
                //         ? false
                //         : true
                // }
                />
                <Form.Input
                    required
                    type='password'
                    label={props.labelOldPassword}
                    placeholder={props.placeholderOldPassword}
                    value={current_password}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                // error={
                //     !isEmpty(current_password)
                //         ? false
                //         : true
                // }
                />
            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    type="button"
                    basic
                    onClick={props.onClose}
                >
                    {t('cancel')}
                </Button>
                <Button
                    loading={isProcessing}
                    primary
                    type="submit"
                    disabled={isProcessing || isEmpty(username) || isEmpty(current_password)}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default ChangeUsernameForm