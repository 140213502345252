import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { setDotSeparator } from '@helpers/functions'
// components
import CanView from '@components/perms/CanView'
import { Form, Divider, Header, Grid } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import NonFieldErrors from '@components/NonFieldErrors'

const TransportOrderForm = ({
    customSubmitHandler,
    isPurchase,
    record,
    setData,
    setTotal,
    onClose,
    accounts,
    employees,
    owners,
    vehicles,
    externalCarriers,
}) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [driverFields, setDriverFields] = useState(record?.external_carrier?.id ? 'external_carrier' : 'driver')
    const [form, setForm] = useState({
        name: record?.name || '',
        order_number: record?.order_number || '',
        business_detail: record?.business_detail?.id || '',
        order_manager: record?.order_manager?.id || '',
        order_type: record?.order_type || 2,
        owner: record?.owner?.id || '',
        order_status: record?.order_status || 1,
        description: record?.description || '',
        price: record?.price?.sum || '',
        currency: record?.price?.currency || 'EUR',
        truck: record?.truck?.id || '',
        trailer: record?.trailer?.id || '',
        driver: record?.driver?.id || '',
        external_carrier: record?.external_carrier?.id || '',
        external_truck: record?.external_truck || '',
        external_trailer: record?.external_trailer || '',
        loading_date: record?.loading_date || moment().format('YYYY-MM-DD'),
        expected_delivery_date: record?.expected_delivery_date || '',
        is_active: record?.is_active !== undefined ? record?.is_active : true,
        distance: record?.distance || '',
        loading_address: {
            street: record?.loading_address?.street || '',
            postcode: record?.loading_address?.postcode || '',
            city: record?.loading_address?.city || '',
            country: record?.loading_address?.country || 'SK',
        },
        destination_address: {
            street: record?.destination_address?.street || '',
            postcode: record?.destination_address?.postcode || '',
            city: record?.destination_address?.city || '',
            country: record?.destination_address?.country || 'SK',
        },
    })

    useEffect(() => {
        if (owners.length === 1) {
            setForm((prev) => ({ ...prev, owner: owners?.[0]?.value || '' }))
        }
    }, [owners])

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let data = {
            is_purchase_order: isPurchase,
            name: form.name,
            description: form.description,
            order_number: form.order_number,
            order_type: form.order_type,
            resourcetype: 'TransportOrder',
            order_manager: form.order_manager !== '' ? form.order_manager : null,
            external_carrier:
                form.external_carrier !== '' && driverFields === 'external_carrier' ? form.external_carrier : null,
            external_trailer: driverFields === 'external_carrier' ? form.external_trailer : '',
            external_truck: driverFields === 'external_carrier' ? form.external_truck : '',
            driver: form.driver !== '' && driverFields === 'driver' ? form.driver : null,
            truck: form.truck !== '' && driverFields === 'driver' ? form.truck : null,
            trailer: form.trailer !== '' && driverFields === 'driver' ? form.trailer : null,
            loading_date: form.loading_date !== '' ? form.loading_date : null,
            expected_delivery_date: form.expected_delivery_date !== '' ? form.expected_delivery_date : null,
            business_detail: form.business_detail !== '' ? form.business_detail : null,
            is_active: form.is_active,
            order_status: form.order_status,
            owner: form.owner !== '' ? form.owner : null,
            loading_address: form.loading_address,
            destination_address: form.destination_address,
            distance: form.distance,
            price: {
                sum: form.price,
                currency: form.currency,
            },
        }

        if (driverFields === 'external_carrier') {
            data.driver = null
            data.truck = null
            data.trailer = null
        } else if (driverFields === 'driver') {
            data.external_carrier = null
            data.external_truck = ''
            data.external_trailer = ''
        }

        // handle data processing and api calls
        if (record === undefined) {
            // its create request
            const request = await requests.post(API.ORDERS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setTotal((prev) => prev + 1)
                    setData((prev) => [request.response, ...prev])
                    onClose()
                }
            }
        } else {
            // its update request
            const request = await requests.patch(API.ORDERS + record.id + '/', data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item.id === record.id) {
                                item = request.response
                            }
                            return item
                        })
                    )
                    onClose()
                }
            }
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {record !== undefined && (
                <>
                    <Header as="h3" content={t('update_order')} />
                    <Divider />
                </>
            )}

            <NonFieldErrors errors={errors} />

            <Form.Group>
                <SuperField
                    as="input"
                    required
                    autoFocus
                    width="12"
                    label={t('name')}
                    value={form.name}
                    error={errors?.name?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, name: value }))}
                />
                <SuperField
                    as="input"
                    width="4"
                    label={t('order_number')}
                    value={form.order_number}
                    error={errors?.order_number?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, order_number: value }))}
                />
            </Form.Group>

            {isPurchase ? (
                <Form.Group widths="equal">
                    <SuperField
                        as="choice"
                        search
                        required
                        value={form.business_detail}
                        label={t('supplier')}
                        customOptions={accounts}
                        error={errors?.business_detail?.[0] || false}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, business_detail: value }))}
                    />
                    <SuperField
                        as="choice"
                        search
                        required
                        value={form.owner}
                        label={t('account')}
                        customOptions={owners}
                        error={errors?.owner?.[0] || false}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, owner: value }))}
                    />
                </Form.Group>
            ) : (
                <Form.Group widths="equal">
                    <SuperField
                        as="choice"
                        search
                        required
                        value={form.owner}
                        label={t('supplier')}
                        customOptions={owners}
                        error={errors?.owner?.[0] || false}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, owner: value }))}
                    />
                    <SuperField
                        as="choice"
                        search
                        required
                        value={form.business_detail}
                        label={t('account')}
                        customOptions={accounts}
                        error={errors?.business_detail?.[0] || false}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, business_detail: value }))}
                    />
                </Form.Group>
            )}

            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    required
                    label={t('price')}
                    value={form.price}
                    error={errors?.price?.sum?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, price: setDotSeparator(value) })}
                />
                <SuperField
                    as="choice"
                    search
                    required
                    type="currency_codes"
                    label={t('currency')}
                    value={form.currency}
                    error={errors?.price?.currency?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, currency: value })}
                />
            </Form.Group>

            <SuperField
                as="textarea"
                label={t('description')}
                value={form.description}
                error={errors?.description?.[0] || false}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, description: value }))}
            />
            <Divider />

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    label={t('date_of_load')}
                    value={form.loading_date}
                    error={errors?.loading_date?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, loading_date: value }))}
                />
                <SuperField
                    as="datepicker"
                    label={t('expected_delivery_date')}
                    value={form.expected_delivery_date}
                    error={errors?.expected_delivery_date?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, expected_delivery_date: value }))}
                />
            </Form.Group>
            <CanView permissions={['orders.c_assign_order_manager']}>
                <SuperField
                    as="choice"
                    search
                    label={t('order_manager')}
                    value={form.order_manager}
                    customOptions={employees}
                    error={errors?.order_manager?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, order_manager: value }))}
                />
            </CanView>

            <Divider />

            <Form.Group>
                <SuperField
                    as="radio"
                    label={t('driver')}
                    checked={driverFields === 'driver'}
                    onChange={() => setDriverFields('driver')}
                />
                <SuperField
                    as="radio"
                    label={t('external_carrier')}
                    checked={driverFields === 'external_carrier'}
                    onChange={() => setDriverFields('external_carrier')}
                />
            </Form.Group>

            {driverFields === 'external_carrier' && (
                <>
                    <SuperField
                        as="choice"
                        search
                        label={t('external_carrier')}
                        value={form.external_carrier}
                        customOptions={externalCarriers}
                        error={errors?.external_carrier?.[0] || false}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, external_carrier: value }))}
                    />
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="input"
                            label={t('truck_plate_number')}
                            value={form.external_truck}
                            error={errors?.external_truck?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, external_truck: value }))}
                        />
                        <SuperField
                            as="input"
                            label={t('trailer_plate_number')}
                            value={form.external_trailer}
                            error={errors?.external_trailer?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, external_trailer: value }))}
                        />
                    </Form.Group>
                    <Divider />
                </>
            )}

            {driverFields === 'driver' && (
                <>
                    <SuperField
                        as="choice"
                        search
                        label={t('driver')}
                        value={form.driver}
                        customOptions={employees}
                        error={errors?.driver?.[0] || false}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, driver: value }))}
                    />
                    <Form.Group widths={'equal'}>
                        <SuperField
                            as="choice"
                            search
                            label={t('truck')}
                            value={form.truck}
                            customOptions={vehicles}
                            error={errors?.truck?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, truck: value }))}
                        />
                        <SuperField
                            as="choice"
                            search
                            label={t('trailer')}
                            value={form.trailer}
                            customOptions={vehicles}
                            error={errors?.trailer?.[0] || false}
                            onChange={(e, { value }) => setForm((prev) => ({ ...prev, trailer: value }))}
                        />
                    </Form.Group>
                    <Divider />
                </>
            )}

            <Grid stackable>
                <Grid.Row columns={2}>
                    <Grid.Column style={{ paddingLeft: 0 }}>
                        <Header as="h4" content={t('loading_address')} />
                        <SuperField
                            as="input"
                            placeholder={t('street')}
                            value={form.loading_address.street}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    loading_address: {
                                        ...prev.loading_address,
                                        street: value,
                                    },
                                }))
                            }
                        />
                        <Form.Group>
                            <SuperField
                                as="input"
                                width="10"
                                placeholder={t('city')}
                                value={form.loading_address.city}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        loading_address: {
                                            ...prev.loading_address,
                                            city: value,
                                        },
                                    }))
                                }
                            />
                            <SuperField
                                as="input"
                                width="6"
                                placeholder={t('postcode')}
                                value={form.loading_address.postcode}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        loading_address: {
                                            ...prev.loading_address,
                                            postcode: value,
                                        },
                                    }))
                                }
                            />
                        </Form.Group>
                        <SuperField
                            as="choice"
                            search
                            type="countries"
                            value={form.loading_address.country}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    loading_address: {
                                        ...prev.loading_address,
                                        country: value,
                                    },
                                }))
                            }
                        />
                    </Grid.Column>
                    <Grid.Column style={{ paddingRight: 0 }}>
                        <Header as="h4" content={t('destination_address')} />
                        <SuperField
                            as="input"
                            placeholder={t('street')}
                            value={form.destination_address.street}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    destination_address: {
                                        ...prev.destination_address,
                                        street: value,
                                    },
                                }))
                            }
                        />
                        <Form.Group>
                            <SuperField
                                as="input"
                                width="10"
                                placeholder={t('city')}
                                value={form.destination_address.city}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        destination_address: {
                                            ...prev.destination_address,
                                            city: value,
                                        },
                                    }))
                                }
                            />
                            <SuperField
                                as="input"
                                width="6"
                                placeholder={t('postcode')}
                                value={form.destination_address.postcode}
                                onChange={(e, { value }) =>
                                    setForm((prev) => ({
                                        ...prev,
                                        destination_address: {
                                            ...prev.destination_address,
                                            postcode: value,
                                        },
                                    }))
                                }
                            />
                        </Form.Group>
                        <SuperField
                            as="choice"
                            search
                            type="countries"
                            value={form.destination_address.country}
                            onChange={(e, { value }) =>
                                setForm((prev) => ({
                                    ...prev,
                                    destination_address: {
                                        ...prev.destination_address,
                                        country: value,
                                    },
                                }))
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <SuperField
                as="input"
                label={t('distance')}
                value={form.distance}
                error={errors?.distance?.[0] || false}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, distance: value }))}
            />

            <Divider />
            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    label={t('state')}
                    disabled={record?.id === undefined || [undefined, null, ''].includes(record?.confirmed_on)}
                    type="order_status_choices"
                    value={form.order_status?.toString() || '1'}
                    onChange={(e, { value }) => setForm({ ...form, order_status: value })}
                />
                <SuperField
                    as="choice"
                    label={t('status')}
                    value={form.is_active}
                    customOptions={[
                        { key: 0, value: true, text: t('active_order') },
                        { key: 1, value: false, text: t('closed_order') },
                    ]}
                    onChange={(e, { value }) => setForm({ ...form, is_active: value })}
                />
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={processing}
                    disabled={
                        processing ||
                        form.name === '' ||
                        form.business_detail === '' ||
                        form.owner === '' ||
                        form.price === '' ||
                        form.currency === ''
                    }
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

export default TransportOrderForm
