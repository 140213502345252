import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useHasEveryPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Message, Table } from 'semantic-ui-react';
// order module component
import OrderDetail from '../../orders/shared/OrderDetail';

const AssignLogisticRecordAsFulfillment = ({ onClose, record, setData }) => {
    const { t } = useTranslation();
    const canViewOrder = useHasEveryPermissions(['orders.c_view_order']);
    const dateFormat = useSelector((state) => state.date_format);

    const [processing, setProcessing] = useState(false);
    const [form, setForm] = useState({
        delivery_date: record?.delivery_date || moment().format('YYYY-MM-DD'),
    });

    const handleSubmit = async () => {
        setProcessing(true);

        const request = await requests.post(API.ORDERS + 'fulfillments/', {
            order: record.order.id,
            resourcetype: 'SaleFulfillment',
            total_fee: record.price_paid,
            order_item: record.order_commodity.id,
            currency: record.currency,
            quantity: record.quantity,
            delivery_date: form.delivery_date,
            logistic_record: record.id,
        });

        if (record?.delivery_date === null) {
            const updateRecord = await requests.patch(API.LOGISTICS + 'records/' + record.id + '/', {
                delivery_date: form.delivery_date,
                status: 4,
            });

            if (updateRecord.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('record_status_changed'),
                    animation: 'pulse',
                    time: 2000,
                });
            }
        }

        if (request.status === 201) {
            const getRecord = await requests.get(API.LOGISTICS + 'records/' + record.id + '/');
            if (getRecord.status === 200) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('assign_as_fulfillment_to_order'),
                    animation: 'pulse',
                    time: 2000,
                });
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === record.id) item = getRecord.response;
                        return item;
                    })
                );
                onClose();
            }
        }

        setProcessing(false);
    };

    return (
        <Form onSubmit={handleSubmit}>
            {!record.delivery_date && (
                <Message
                    info
                    visible
                    content={
                        <strong>
                            <Icon
                                name="information-circle"
                                style={{
                                    marginRight: '0.5rem',
                                    fontSize: '1.2rem',
                                    position: 'relative',
                                    top: '0.2rem',
                                }}
                            />
                            {t('logistic_record_delivery_date_hint_order_assign')}
                        </strong>
                    }
                />
            )}

            {!record.delivery_date && (
                <Form.Group widths="equal">
                    <SuperField
                        as="datepicker"
                        required
                        label={t('delivery_date')}
                        value={form.delivery_date}
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, delivery_date: value }))}
                    />
                </Form.Group>
            )}

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell content={t('order_number')} />
                        <Table.HeaderCell content={t('commodity')} />
                        <Table.HeaderCell content={t('delivery_date')} />
                        <Table.HeaderCell content={t('quantity')} />
                        <Table.HeaderCell content={t('sum')} />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row key={record.id}>
                        <Table.Cell>
                            {record.order.is_deleted !== true ? (
                                canViewOrder ? (
                                    <SuperDuperModal
                                        size="large"
                                        trigger={
                                            <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                                {record?.order?.order_number || record?.order.original_order_number}
                                            </span>
                                        }
                                        content={<OrderDetail id={record.order.id} />}
                                    />
                                ) : (
                                    <span>{record?.order?.order_number || record?.order.original_order_number}</span>
                                )
                            ) : (
                                <span style={{ color: 'var(--danger)' }}> {t('order_was_deleted')} </span>
                            )}
                        </Table.Cell>
                        <Table.Cell>
                            <strong>
                                {record.order_commodity
                                    ? `${
                                          record.order_commodity?.item?.code ? record.order_commodity?.item?.code : ''
                                      } ${record.order_commodity?.item?.title}`
                                    : '--'}
                            </strong>
                        </Table.Cell>
                        <Table.Cell>{moment(form.delivery_date).format(dateFormat)}</Table.Cell>
                        <Table.Cell>
                            {record.quantity} {record.measure_unit ? record.measure_unit?.abbreviation : ''}
                        </Table.Cell>
                        <Table.Cell>
                            {record.price_paid} {record.currency}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} disabled={processing} />
                <ModalSubmit
                    loading={processing}
                    disabled={processing || (form.delivery_date === '' && !record.delivery_date)}
                />
            </Form.Field>
        </Form>
    );
};

export default AssignLogisticRecordAsFulfillment;
