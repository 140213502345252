import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// store
import { queryParams } from '@helpers/requests'
// components
import Icon from '@components/Icon';
import { Grid, Header, Card, Image } from 'semantic-ui-react';
// module specific components
import ConnectByCreatingUser from './ConnectByCreatingUser';
import ConnectByExistingUser from './ConnectByExistingUser';

const CardChoice = ({ content, onChoiceSelect, ...rest }) => {
    return (
        <Grid.Column {...rest}
            className="toggle-button"
            style={{
                textAlign: "center",
                cursor: "default"
            }}
        >
            <Card onClick={onChoiceSelect} centered className="active" fluid style={{ cursor: "pointer", borderRadius: 0, color: "var(--dark)", width: "50%", marginBottom: "2rem" }}>
                <Card.Content as="h2">
                    { content }
                </Card.Content>
            </Card>
        </Grid.Column>
    )
}

const ConnectSetup = ({ setRefetch }) => {
    const { t } = useTranslation()
    const query = queryParams(useLocation())
    const [view, setView] = useState(null)

    const connected = query.get("connected") === "True" || query.get("connected") === "true"

    return (
        <Grid style={{ paddingTop: 0 }}>
            <Grid.Row verticalAlign="middle" style={{ paddingTop: 0 }}>
                <Grid.Column computer={8}>
                    { view === 1 && <ConnectByCreatingUser setView={setView}/> }
                    { (view === 2 || connected === true) && <ConnectByExistingUser connected={connected} setView={setView} setRefetch={setRefetch}/> }
                    { ((view === null || view === undefined)) && (connected === false || connected === undefined) && 
                        <>
                            <Header as="h2" style={{ textAlign: "center", marginTop: "2rem", marginBottom: "3rem", fontWeight: "bold", textTransform: "uppercase"}}>
                                { t('connect_invoice_service') }
                            </Header>
                            <Grid centered>
                                <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <CardChoice onChoiceSelect={() => setView(1)} content={
                                        <> 
                                            <Icon name="log-in-outline" style={{ marginRight: "0.5rem" }}/>
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                { t('connect_by_creating_new') } 
                                            </span>
                                        </>
                                    }/>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <CardChoice onChoiceSelect={() => setView(2)} content={
                                        <> 
                                            <Icon name="log-in-outline" style={{ marginRight: "0.5rem" }}/> 
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                { t('connect_with_existing') }
                                            </span>
                                        </>
                                    }/>
                                </Grid.Row>
                            </Grid>
                        </>
                    }
                </Grid.Column>
                <Grid.Column computer={8}>
                    <Image centered src="/images/invoices/finvix_banner.svg" width="80%"/>
                </Grid.Column>
            </Grid.Row>
            
        </Grid>
    )
}

export default ConnectSetup;