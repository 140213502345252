import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import SpinnerSegment from '@components/SpinnerSegment';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Card, Divider, Header, Grid } from 'semantic-ui-react';

const UserDailyTasks = ({ user, dateFormat }) => {
    const { t } = useTranslation()

    // eslint-disable-next-line
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(false)

    const today = moment().format("YYYY-MM-DD")

    useEffect(() => {
        async function fetchTodaysTasks() {
            setLoading(true)
            const assigned_to = user.profile?.id
            if (assigned_to) {
                const request = await requests.get(API.TASKS + `?assigned_to=${assigned_to}&is_closed=false&ordering=title&ordering=-priority&date_from=${today}&date_to=${today}`)
                if (request.status === 200) setTasks(request.response)
            }
            setLoading(false)
        }

        fetchTodaysTasks()
        // eslint-disable-next-line
    }, [])

    const closeTask = async (task) => {
        const request = await requests.patch(API.TASKS + task.id + "/", { is_closed: true })
        if (request.status === 200) {
            setTasks(prev => prev.filter(item => item.id !== task.id))
        }
    }

    return (
        <SpinnerSegment loading={loading} marginBottom={loading ? "1rem" : 0}>
            <Card fluid style={{ borderRadius: "2px"  }}>
                { tasks.length > 0 
                    ? 
                    <Card.Content>
                        <Header as="h4" content={t('your_todays_tasks')}/>
                        <Divider/>
                        <Grid divided="vertically">
                            { tasks.map(task => 
                                <Grid.Row columns={2} verticalAlign='middle'>
                                    <Grid.Column width="14" style={{ marginBottom: 0 }}>
                                        <Card.Header>
                                            <strong>
                                                <SuperDuperModal
                                                    size="tiny"
                                                    trigger={<span className="ref-link">{task.title}</span>}
                                                    content={
                                                        <div>
                                                            <Header as="h3" content={task.title} style={{ marginBottom: 0 }}/>
                                                            <small style={{ opacity: 0.8 }}>#{task.id} · { moment(task.to_date).format(dateFormat) } · <strong>{ task.priority_display }</strong> { task.task_type && ` · ${task.task_type.title}` }</small>

                                                            <br/><br/><strong>{t('task_description')}</strong>
                                                            <Divider/>
                                                            <p dangerouslySetInnerHTML={{__html: task.description || "--"}}></p>
                                                        </div>
                                                    }
                                                />
                                            </strong>
                                        </Card.Header>
                                        <Card.Meta>
                                            <small>#{task.id} { task.to_date && ` · ${moment(task.to_date).format(dateFormat)}` } · <strong>{ task.priority_display }</strong> { task.task_type && ` · ${task.task_type.title}` }</small>
                                        </Card.Meta>
                                    </Grid.Column>
                                    <Grid.Column width="2" style={{ marginBottom: 0, textAlign: "right" }}>
                                        <Action as="confirm"
                                            type="icon"
                                            size="huge"
                                            // iconColor="var(--dark)"
                                            tooltip={t('close_task')}
                                            icon="checkmark-circle-outline"
                                            text={t('are_you_sure_to_close_this_task')}
                                            onClick={() => closeTask(task)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    </Card.Content>
                    : 
                    <Card.Content style={{ textAlign: "center", paddingTop: "2rem", paddingBottom: "2rem" }}>
                        <Icon name="shield-checkmark-outline" style={{ fontSize: "3rem" }}/> <br/>
                        <strong style={{ fontSize: "1.2rem" }}>{ t('looks_like_your_work_here_is_done') } <br/> { t('all_tasks_for_today_are_completed_great_job') }</strong>
                    </Card.Content>
                }
                <Card.Content extra>
                    <Link to={routes.TASKS} style={{ width: "100%", display: "block", textAlign: "center" }}>
                        {t('go_to_my_tasks')} 
                    </Link>
                </Card.Content>
        </Card>
        </SpinnerSegment>
    );
};

export default UserDailyTasks;