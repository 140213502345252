// request
import request from "umi-request";
// endpoints
import { API } from "../config";

/*
 * Service functions
 */
export const activationService = {
    getUserActivation,
};

async function getUserActivation(userId, token) {
    let activated;
    // create Async - Await request
    activated = await request(
        API.GET_USER_ACTIVATION,
        {
            method: "post",
            data: {
                "uid": userId,
                "token": token
            }
        }
    )
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 403) {
                    return true;
                }
            } else {
                return false;
            }
        });

    return activated;
}
