import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from '@helpers/validation';
import { API } from '@store/config';
import { createRequest, patchRequest } from '@services/ServiceCommon';
// components
import { Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const MemberForm = ({ onClose, member, setMembers, members }) => {
    const params = useParams()
    const { t } = useTranslation();

    const [gitlabRole /*, setGitlabRole*/] = useState(member ? member.gitlab_role : 1);
    const [profile, setProfile] = useState(member ? member.profile : "");
    const project = params.id;

    // Form states
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState([])

    const handleError = (response) => {
        let errorList = [];
        if (response.non_field_errors) {
            for (let i = 0; i < response.non_field_errors.length; i++) {
                errorList.push(response.non_field_errors[i]);
            }
        }

        if (response.detail) {
            for (let i = 0; i < response.detail.length; i++) {
                errorList.push(response.detail[i]);
            }
        }

        setErrors(errorList);
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors([])
        let request = null;

        const data = {
            gitlab_role: gitlabRole,
            profile: profile,
            project: project
        }

        const dataUpdate = {
            gitlab_role: gitlabRole
        }

        if (!member) {
            request = await createRequest(API.PROJECT_MEMBERS, data)
            if (request.status === 201) {
                setMembers(prevState => ({
                    ...prevState,
                    data: [request.response, ...prevState.data]
                }))
                onClose()
            } else {
                handleError(request.response);
            }
        } else {
            request = await patchRequest(API.PROJECT_MEMBERS + member.id + "/", dataUpdate)
            if (request.status === 200) {
                setMembers(prevState => ({
                    ...prevState,
                    data:
                        members.data.filter((item) => {
                            if (item.id === request.response.id) {
                                item.gitlab_role = request.response.gitlab_role;
                            }
                            return item;
                        }),

                }));
                onClose()
            } else {
                handleError(request.response);
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t('error_submission_message')}
                list={errors}
            />

            {!member
                &&
                <SuperField as="choice"
                    search
                    required
                    text="fullname"
                    label={t('employee')}
                    value={profile}
                    endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                    onChange={(e, { value }) =>
                        setProfile(value)
                    }
                />
            }

            {/* <SuperField as="choice"
                search
                required
                label={t('gitlab_role')}
                value={gitlabRole}
                type="gitlab_roles"
                onChange={(e, { value }) =>
                    setGitlabRole(value)
                }
            /> */}

            <Form.Field style={{ textAlign: "right", margin: " 1rem" }}>
                <ModalCancel
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(member)}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    );
};

export default MemberForm;