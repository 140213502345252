import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { useHasPermission } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import CertificateForm from './CertificateForm';
import Attachments from '@components/Attachments';
import AvatarList from '@components/AvatarList';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Popup, Label } from 'semantic-ui-react';

const CertificatesList = ({ unit }) => {
    const { t } = useTranslation();
    const dateFormat = useSelector(state => state.date_format)
    const canManage = useHasPermission('company.c_manage_certificates')
    const [attachmentsOpen, setAttachmentsOpen] = useState(0)

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev])
        setTotal((prev) => prev + 1)
    }

    const onUpdate = async (response, setData) => {
        setData((prev) =>
            prev.map((certificate) => {
                if (certificate.id === response.id) {
                    certificate = response
                }
                return certificate
            })
        )
    }

    return (
            <ListView
                as="table"
                allowSearch
                endpoint={API.COMPANY_CERTIFICATES}
                actionsCellWidth="2"
                initialFilters={{
                    type: "",
                    responsible_person: "",
                    unit: unit || "",
                    valid_from: "",
                    valid_to: "",
                    status: 1,
                    only_for_an_unknown_period: ""
                }}
                listAdditionActions={() => ([
                    {
                        as: "filter",
                        index: 0,
                        name: t('active'),
                        filters: {
                            status: 1,
                        },
                    },
                    {
                        as: "filter",
                        index: 1,
                        name: t('terminated'),
                        filters: {
                            status: 2,
                        },
                    },
                ])}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths={"equal"}>
                            <SuperField 
                                as="datepicker"
                                clearable
                                label={t('valid_from')}
                                value={filters.valid_from}
                                onChange={(e, { value }) => setFilters({ 
                                    ...filters, 
                                    valid_from: value 
                                })}
                            />
                            <SuperField 
                                as="datepicker"
                                clearable
                                label={t('valid_to')}
                                value={filters.valid_to}
                                onChange={(e, { value }) => setFilters({ 
                                    ...filters, 
                                    valid_to: value 
                                })}
                            />
                        </Form.Group>
                        <DatePresets
                            from={filters.valid_from}
                            to={filters.valid_to}
                            onSelection={ (firstMonthDay, lastMonthDay, year) => {
                                setFilters(prev => ({
                                    ...prev,
                                    valid_from: firstMonthDay,
                                    valid_to: lastMonthDay,
                                    year: year
                                }))
                            }}
                        />

                        <Divider/>

                        <Form.Group widths={"equal"}>
                            <SuperField
                                as="choice"
                                search
                                label={t('responsible_person')}
                                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                                text="fullname"
                                value={filters.responsible_person}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    responsible_person: value
                                })}
                            />
                            <SuperField
                                as="choice"
                                search
                                label={t('type')}
                                endpoint={API.COMPANY_CERTIFICATE_TYPES}
                                text="title"
                                value={filters.type}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    type: value
                                })}
                            />
                        </Form.Group>

                        {!unit &&
                            <SuperField
                                as="choice"
                                search
                                label={t('unit')}
                                endpoint={API.UNITS}
                                text="name"
                                value={filters.unit}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    unit: value
                                })}
                            />
                        }

                    <SuperField
                        as='checkbox'
                        label={t('only_for_an_unknown_period')}
                        checked={filters.only_for_an_unknown_period}
                        onChange={() => setFilters({ ...filters, only_for_an_unknown_period: filters.only_for_an_unknown_period === "" ? true : "" })}
                    />
                    </>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('add_certificate'),
                        modalSize: 'small',
                        permissions: ['company.c_manage_certificates'],
                        modal: (
                            <CertificateForm
                                unit={unit}
                                handleRequest={(response, setData, setTotal) => onCreate(response, setData, setTotal)}
                            />
                        ),
                    },
                    {
                        as: 'modal',
                        type: 'edit',
                        name: t('edit'),
                        modalSize: 'small',
                        permissions: ['company.c_manage_certificates'],
                        modal: (item, setData) => (
                            <CertificateForm
                                data={item}
                                setData={setData}
                                unit={unit}
                                handleRequest={(response, setData) => onUpdate(response, setData)}
                            />
                        ),
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('delete'),
                        permissions: ['company.c_delete_certificates'],
                    },
                ]}
                tableHeaders={
                    [
                        { title: t('name') },
                        { title: t('type') },
                        !unit && { title: t('unit') },
                        { title: t('valid_from') },
                        { title: t('valid_to') },
                        { title: t('responsible_person') },
                        { title: t('note') },
                        { title: t('attachments') },
                        { title: t('status') },
                    ]
                }
                renderCells={(certificate, setData) => (
                    [   { width: 2, content: certificate.title },
                        { content: certificate.type?.title },
                        !unit && { content: certificate.unit?.name },
                        { content: certificate.valid_from && moment(certificate.valid_from).format(dateFormat) },
                        { content: certificate.valid_to && moment(certificate.valid_to).format(dateFormat) },
                        { content: 
                            certificate.responsible_person && 
                            <AvatarList 
                                size="25"
                                alignDirection="start"
                                persons={[
                                    {
                                        id: certificate.responsible_person.id,
                                        name: certificate.responsible_person.fullname,
                                        avatar: certificate.responsible_person.profile_picture,
                                        displayName: certificate.responsible_person.fullname_with_titles,
                                    }
                                ]}
                            />
                        },
                        { content: certificate.additional_info ? 
                            <Popup
                                size="tiny"
                                position="left center"
                                trigger={
                                    <Icon name="document-text-outline" style={{ fontSize: "1.3rem" }}/>
                                }
                                content={ certificate.additional_info }
                            />
                            : ""
                        },
                        { 
                            content: (
                                <Label basic>
                                    <Popup
                                        style={{ zIndex: "1000", minWidth: "450px" }}
                                        open={attachmentsOpen === certificate.id}
                                        onOpen={() => setAttachmentsOpen(certificate.id)}
                                        hoverable
                                        position='left center'
                                        trigger={
                                            <div style={{ cursor: "pointer" }}>
                                                <Icon name="reader-outline" style={{ marginRight: "0.5rem" }}/> 
                                                <span style={{ position: "relative", top: "-0.1rem" }}>{ certificate.attachments.length }</span>
                                            </div>
                                        }
                                        content={
                                            <Attachments 
                                                viewOnly={!canManage}
                                                record={certificate}
                                                setData={setData}
                                                sourceEndpoint={API.COMPANY_CERTIFICATES}
                                                closeView={() => setAttachmentsOpen(0)}
                                            />
                                        }
                                    />
                                </Label>
                            ),
                        },
                        { content: 
                            <span>
                                <Icon
                                    name="ellipse"
                                    color={certificate.status === 1 ? "green" : "red"}
                                    style={{ marginRight: "0.5rem" }}
                                />
                                <span style={{ position: "relative", top: "-0.1rem" }}>
                                    { certificate?.status_display }
                                </span>
                            </span>
                        },
                    ]
                )}
            />
    );
};

export default CertificatesList;