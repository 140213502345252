import React from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
// store 
import { dates } from '@store/services/helpers/dates';
// components
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import { Segment } from 'semantic-ui-react';

const HistoryTable = ({ assetsHistory }) => {
    const { t } = useTranslation()

    const handleItemDisplay = (item) => {
        let itemName = item.stored_item.item.title
        return `${item.stored_item.code !== "" ? item.stored_item.code + " - " : ""}${itemName}`
    } 

    const TableHeadding = () => (
        <FlexRow fontSize="1.1rem">
            <FlexHeader content={t('operation')} />
            <FlexHeader content={t('item')} />
            <FlexHeader content={t('serial_number')} />
            <FlexHeader content={t('quantity')} />
            <FlexHeader content={t('warehouse')} />
            <FlexHeader content={t('person')} />
            <FlexHeader content={t('note')} />
            <FlexHeader content={t('date')} />
        </FlexRow>
    )
    const TableRow = ({ item }) => (
        <FlexRow borders fontSize="0.9rem">
            <FlexItem>{item.operation_display}</FlexItem>
            <FlexItem>
                {handleItemDisplay(item)}
                <strong>{ item?.issued_item && <> <br/> { item?.issued_item.status_display }</> }</strong>
            </FlexItem>
            <FlexItem>{item.stored_item.sn}</FlexItem>
            <FlexItem>{item.quantity}</FlexItem>
            <FlexItem>{item?.stored_item?.warehouse?.title}</FlexItem>
            <FlexItem>{item?.issued_item?.employee?.fullname || "--"}</FlexItem>
            <FlexItem>{item.note}</FlexItem>
            <FlexItem>
                {moment(dates.convertUTCDateToLocalDate(item.created_on)).format("DD.MM.YYYY HH:mm")}<br/>
                <small>by <strong>{ item.created_by?.name }</strong></small>
            </FlexItem>
        </FlexRow>
    )

    const EmptyRow = () => {
        return (
            <FlexRow fontSize="0.9rem" borders>
                <FlexItem bold textAlign="center" content={t('no_data')} />
            </FlexRow>
        )
    }

    return (
        <Segment loading={assetsHistory.isLoading} style={{ 
            padding: 0, 
            background: "transparent", 
            boxShadow: "none", 
            border: "none" 
        }}>
            <FlexTable>
                <TableHeadding />
                { assetsHistory?.data?.count === 0 && <EmptyRow/> }
                {assetsHistory?.data?.results?.map((item, key) => <TableRow item={item} key={item.id} />)}
            </FlexTable>
        </Segment>
    );
};

export default HistoryTable;