import React, { useState, useEffect } from 'react';
import { toast } from 'react-semantic-toasts';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useIsMount } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import { Icon as SemanticIcon, Button, Sidebar, Menu, Grid, Dropdown, Divider, Segment, Header, Label } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
import CanView from '@components/perms/CanView';
import SpinnerSegment from '@components/SpinnerSegment';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import LaunchNewRoomForm from '../components/forms/LaunchNewRoomForm';
import ConfirmModal from '@components/modals/ConfrimModal';
import StagesListView from '../components/lists/StagesListView';
import RejectedListView from '../components/lists/RejectedListView';
import BlacklistedListView from '../components/lists/BlacklistedListView';
import NewManagerForm from '../components/forms/NewManagerForm';
import NewRecruitersForm from '../components/forms/NewRecruitersForm';
import HiringRoomHeader from './HirirngRoomHeader';
import ReactionsListView from './ReactionsListView';

const HiringRoomDetail= ({ setItems }) => {
    const { t } = useTranslation();
    
    const isMount = useIsMount()

    const params = useParams();
    const history = useHistory();
    const [stages, setStages] = useState([])
    const [visible, setVisible] = useState(false)
    const [view, setView] = useState(0)
    const [listView, setListView] = useState(0)
    const [room, setRoom] = useState({})
    const [totalCandidates, setTotalCandidates] = useState(0)
    const [rejected, setRejected] = useState([])
    const [blacklisted, setBlacklisted] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isAdded, setIsAdded] = useState(false)

    async function fetchStages() {
        const request = await requests.get(API.HIRING_ROOM + params.id + "/")
        if (request.status === 200) {
            setRoom(request.response)
            setStages(request.response.archived_recruitment_stages)
            setTotalCandidates(request.response.candidate_count)
            if (isMount) {
                setItems(prev => ([...prev, { 'name': request.response.name }]))
            }
        }
        setIsLoading(false)
        setIsAdded(false)
    }

    useEffect(() => {
        async function fetchRejected() {
            const request = await requests.get(API.REJECTED + "?recruitment=" + params.id + "&paginate=true")
            if (request.status === 200) setRejected(request.response)
        }
        async function fetchBlacklisted(){
            const request = await requests.get(API.BLACKLISTED + "?recruitment=" + params.id + "&paginate=true")
            if( request.status === 200 ) setBlacklisted(request.response)
        }

        
        fetchStages()
        fetchRejected()
        fetchBlacklisted()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isMount) {
            if (isAdded) {
                fetchStages()
            }
        }
        // eslint-disable-next-line
    }, [isAdded])

    const closeRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + params.id + "/", { is_active: false });

        if (request.status === 200) {
            history.push(routes.HIRING)
        }
    }

    const reOpenRoom = async (id) => {
        const request = await requests.patch(API.HIRING_ROOM + id + "/", { is_active: true });
        if (request.status === 200) {
            setRoom(prev => ({
                ...prev,
                is_active: true
            }))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('hiring_room_was_reopened'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    const handlePersonRemove = async (type, id) => {
        if (type === "manager") {
            const request = await requests.patch(API.HIRING_ROOM + room.id + "/", {
                hiring_managers: { remove: [id] }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    hiring_managers: room.hiring_managers.filter(item => item.id !== id)
                })
            }

        } else if (type === "recruiter") {
            const request = await requests.patch(API.HIRING_ROOM + room.id + "/", {
                recruiters: { remove: [id] }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    recruiters: room.recruiters.filter(item => item.id !== id)
                })
            }
        }
    }

    const handlePersonAdd = async (type, id) => {
        if (type === "manager") {
            const request = await requests.patch(API.HIRING_ROOM + room.id + "/", {
                hiring_managers: { add: [id] }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    hiring_managers: request.response.hiring_managers
                })
            }

        } else if (type === "recruiter") {
            const request = await requests.patch(API.HIRING_ROOM + room.id + "/", {
                recruiters: { add: id }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    recruiters: request.response.recruiters
                })
            }
        }
    }

    const ManagersList = () => {
        return (
            <>
                {!isLoading &&
                    <CanView permissions={["candidates.c_view_hiring_managers"]}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: "2rem" }}>
                            <span style={{ margin: 0 }}>
                                <Header as="h3" content={t('managers')} />
                            </span>
                            <span style={{ margin: 0 }}>
                                <CanView permissions={["candidates.c_manage_hiring_managers"]}>
                                    <SuperDuperModal
                                        key={1}
                                        trigger={
                                            <Button icon style={{ background: "none", paddingTop: 0 }}>
                                                <Icon name='add-outline'/>
                                            </Button>
                                        }
                                        header={t('add_managers')}
                                        size="small"
                                        content={
                                            <NewManagerForm
                                                roomId={room.id}
                                                managersList={room.hiring_managers.map(manager => {
                                                    return manager.profile.id
                                                })}
                                                onConfirm={
                                                    (new_manager) => {
                                                        handlePersonAdd("manager", new_manager.id)
                                                    }
                                                }
                                            />
                                        }
                                    />
                                </CanView>
                            </span>
                        </div>
                        <Divider style={{ marginTop: 0 }} />
                        {room.hiring_managers?.map((item, index) => (
                            <Button
                                style={{ textAlign: "left", background: "none" }}
                                fluid key={index}
                            >
                                <SemanticIcon.Group style={{ marginRight: "1rem" }}>
                                    <AvatarIcon size="30" src={item.profile.profile_picture} name={item.profile.fullname} />
                                </SemanticIcon.Group>
                                {item.profile.fullname}
                                <CanView permissions={["candidates.c_manage_hiring_managers"]}>
                                    <Icon
                                        name="close-outline"
                                        onClick={() => handlePersonRemove("manager", item.id)}
                                        style={{ float: "right", color: "var(--danger)", marginTop: "0.5rem" }}
                                    />
                                </CanView>
                            </Button>
                        ))}
                    </CanView>
                }
            </>
        )
    }

    const RecruitersList = () => {
        return (
            <>
                {!isLoading &&
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: "2rem" }}>
                            <span style={{ margin: 0 }}>
                                <Header as="h3" content={t('recruiters')} />
                            </span>
                            <span style={{ margin: 0 }}>
                                <CanView permissions={["candidates.c_manage_recruitment"]}>
                                    <SuperDuperModal
                                        key={1}
                                        trigger={
                                            <Button icon style={{ background: "none", paddingTop: 0 }}>
                                                <Icon name='add-outline'/>
                                            </Button>
                                        }
                                        header={t('add_recruiters')}
                                        size="small"
                                        content={
                                            <NewRecruitersForm
                                                roomId={room.id}
                                                recruitersList={room.recruiters?.map(item => item.id)}
                                                onConfirm={
                                                    (response) => {
                                                        handlePersonAdd("recruiter", response.assignee)
                                                    }
                                                }
                                            />
                                        }
                                    />
                                </CanView>
                            </span>
                        </div>
                        <Divider style={{ marginTop: 0 }} />
                        {room.recruiters?.map((item, index) => (
                            <Button
                                style={{ textAlign: "left", background: "none" }}
                                fluid key={index}
                            >
                                <SemanticIcon.Group style={{ marginRight: "1rem" }}>
                                    <AvatarIcon size="30" src={item.profile_picture} name={item.fullname} />
                                </SemanticIcon.Group>
                                {item.fullname}
                                <CanView permissions={["candidates.c_manage_recruitment"]}>
                                    <Icon
                                        name="close-outline"
                                        onClick={() => handlePersonRemove("recruiter", item.id)}
                                        style={{ float: "right", color: "var(--danger)", marginTop: "0.5rem" }}
                                    />
                                </CanView>
                            </Button>
                        ))}
                        
                    </>
                }
            </>
        )
    }

    return (
        <SpinnerSegment loading={isLoading}>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Button.Group size="small" style={{ borderRadius: "0" }} basic>
                            <Button active={view === 0} onClick={() => { setView(0) }}> {t('recruitment')} </Button>
                            <Button active={view === 1} onClick={() => setView(1)}>
                                {t('reactions')}
                                {room?.new_reactions_count > 0 && (
                                    <Label color='red' floating circular style={{ left: 'auto', margin: "0!important" }}>{"+" + room.new_reactions_count}</Label>
                                )}
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16} style={{ textAlign: "right" }}>
                        <CanView permissions={['candidates.c_manage_recruitment']}>
                            <Button.Group size="small" style={{ borderRadius: '0' }}>
                                <Button.Group>
                                    <Button style={{ background: "var(--dark)", color: "var(--white)", cursor: "default" }}>
                                        {t('manage_hiring_room')}
                                    </Button>
                                    <Dropdown
                                        simple
                                        style={{ background: "var(--dark-hover)", color: "var(--white)", marginRight: "0.5rem" }}
                                        className='button icon'
                                        floating
                                        trigger={<Icon name="settings-outline" style={{ marginRight: "0.5rem" }} />}
                                    >
                                        <Dropdown.Menu style={{ marginTop: "0", zIndex: 1000 }}>
                                            <SuperDuperModal
                                                header={t('edit_room')}
                                                size="small"
                                                trigger={
                                                    <Dropdown.Item>
                                                        <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('edit_hiring_room') }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <LaunchNewRoomForm
                                                        room={room}
                                                        btnName={t('confirm')}
                                                        onConfirm={(response) => setRoom(response)}
                                                    />
                                                }
                                            />

                                            <Dropdown.Item onClick={() => setVisible(true)}>
                                                <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/>
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    { t('manage_recruiters_and_managers') }
                                                </span>
                                            </Dropdown.Item>
                                            {room.is_active ?
                                                <ConfirmModal
                                                    button={
                                                        <Dropdown.Item style={{ background: "var(--danger)", color: "var(--white)" }}>
                                                            <Icon name="close-outline" style={{ marginRight: "0.5rem" }}/>
                                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                { t('end_hiring') }
                                                            </span>
                                                        </Dropdown.Item>
                                                    }
                                                    description={t('end_hiring_message')}
                                                    onConfirm={() => closeRoom(room.id)}
                                                />
                                                :
                                                <ConfirmModal
                                                    button={
                                                        <Dropdown.Item style={{ background: "var(--dark)", color: "var(--white)" }}>
                                                            <Icon name="arrow-undo-circle-outline" style={{ marginRight: "0.5rem" }}/>
                                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                { t('reopen_room') }
                                                            </span>
                                                        </Dropdown.Item>
                                                    }
                                                    description={t('are_you_sure_that_you_want_to_reopen_this_room')}
                                                    onConfirm={() => reOpenRoom(room.id)}
                                                />
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Button.Group>
                            </Button.Group>
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
            </Grid>

            <Sidebar.Pushable as={Segment} style={{ boxShadow: "none", border: "none", marginTop: 0 }} className="fixed-sidebar">
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    icon='labeled'
                    vertical
                    visible={visible}
                    width='wide'
                >
                    <Grid.Column computer={4} tablet={8} mobile={16} textAlign="left">
                        <div style={{ padding: "1rem", width: "100%" }}>
                            <Button fluid type="button" size="small" content={t("close")} onClick={() => setVisible(false)} />
                            <ManagersList />
                            <RecruitersList />
                        </div>
                    </Grid.Column>
                </Sidebar>
                <Sidebar.Pusher/>
            </Sidebar.Pushable>
            <HiringRoomHeader room={room} />
            {view === 0 &&
                <>
                    {listView === 0 && 
                        <StagesListView 
                            params={params} 
                            room={room}
                            stages={stages} 
                            setStages={setStages}
                            rejected={rejected}
                            setRejected={setRejected}
                            blacklisted={blacklisted}
                            setBlacklisted={setBlacklisted}
                            totalCandidates={totalCandidates} 
                            listView={listView} 
                            setListView={setListView}
                            setIsAdded={setIsAdded}
                        />
                    }

                    {listView === 1 && 
                        <RejectedListView
                            params={params} 
                            stages={stages} 
                            setStages={setStages}
                            rejected={rejected}
                            setRejected={setRejected}
                            blacklisted={blacklisted}
                            setBlacklisted={setBlacklisted}
                            totalCandidates={totalCandidates} 
                            listView={listView} 
                            setListView={setListView}
                        />
                    }

                    {listView === 2 && 
                        <BlacklistedListView
                            params={params} 
                            stages={stages} 
                            setStages={setStages}
                            rejected={rejected}
                            setRejected={setRejected}
                            blacklisted={blacklisted}
                            setBlacklisted={setBlacklisted}
                            totalCandidates={totalCandidates} 
                            listView={listView} 
                            setListView={setListView}
                        />
                    }
                </>
            }
            {view === 1 &&
                <ReactionsListView params={params} />
            }
        </SpinnerSegment>
    );
};

export default HiringRoomDetail;