import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
//components
import { Header, Divider, Tab, Grid } from 'semantic-ui-react'
import OutlineAddButton from '@components/buttons/OutlineAddButton'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import Loading from '@components/general/Loading'
import AttachmentGroups from './AttachmentGroups'
import AttachmentForm from './AttachmentForm'
import Attachment from './Attachment'

const Documents = ({
    groups,
    docs,
    setAttachments,
    hideCreate,
    setGroups,
    update,
    module,
    group_params,
    canManage,
}) => {
    const { t } = useTranslation()

    const [docsWithoutGroup, setDocsWithoutGroup] = useState([])
    const [docsWithGroup, setDocsWithGroup] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [options, setOptions] = useState([])

    useEffect(() => {
        async function fetchData() {
            setDocsWithoutGroup(docs.filter((doc) => doc.group === null || !doc.group?.name))
            setDocsWithGroup(docs.filter((doc) => doc.group !== null))
            setIsLoading(false)
        }

        fetchData()
    }, [docs])

    useEffect(() => {
        async function getChoices() {
            setIsLoading(true);

            let optionsArray = []
            for (let i = 0; i < groups.length; i++) {
                optionsArray.push({ key: groups[i].id, value: groups[i].id, text: groups[i].name });
            }
            
            setOptions(optionsArray);
            setIsLoading(false);
        }
        getChoices();
    }, [groups])

    const onCreateAtt = async (response) => {
        if (response?.id === undefined) {
            setAttachments([...docs, ...response])
            update(response)
        } else {
            setAttachments([...docs, response])
            update(response)
        }
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.ATTACHMENTS + id + '/')

        if (request.status === 204) {
            const newList = docs.filter((item) => item.id !== id)
            setAttachments(newList)
        }
    }

    const onDeleteGroup = async (id) => {
        const request = await requests.post(API.ATTACHMENT_GROUPS + id + '/delete/')

        if (request.status === 200) {
            const newList = groups.filter((item) => item.id !== id)
            setGroups(newList)
        }
    }

    return (
        <Tab.Pane>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Grid columns="2">
                        <Grid.Column style={{ paddingTop: '0.8rem', paddingBottom: 0 }}>
                            <Header content={t('documents')} />
                        </Grid.Column>
                        <Grid.Column textAlign="right" style={{ padding: 0 }}>
                            {hideCreate !== true && canManage && (
                                <SuperDuperModal
                                    trigger={<OutlineAddButton color="violet" text={t('add_new_file')} size="small" />}
                                    size="small"
                                    header={t('add_new_file')}
                                    content={
                                        <AttachmentForm
                                            module={module}
                                            onConfirm={(item) => onCreateAtt(item)}
                                            groups={groups}
                                            setGroups={setGroups}
                                            group_params={group_params}
                                        />
                                    }
                                />
                            )}
                        </Grid.Column>
                    </Grid>
                    <Divider />
                    {groups &&
                        groups.map((group) => (
                            <AttachmentGroups
                                key={group.id}
                                group={group}
                                options={options}
                                docs={docsWithGroup}
                                onDelete={(item) => onDelete(item)}
                                onDeleteGroup={(item) => onDeleteGroup(item)}
                                onCreateAtt={(item) => onCreateAtt(item)}
                                module={module}
                                hideCreate={hideCreate || !canManage}
                                canManage={canManage}
                                setDocsWithGroup={setDocsWithGroup}
                                setDocsWithoutGroup={setDocsWithoutGroup}
                            />
                        ))}
                    {docsWithoutGroup &&
                        docsWithoutGroup.map((doc) => (
                            <Attachment
                                module={module}
                                options={options}
                                key={doc.id}
                                docs={doc}
                                onDelete={(item) => onDelete(item)}
                                canManage={canManage}
                                canMove={canManage}
                                setDocsWithGroup={setDocsWithGroup}
                                setDocsWithoutGroup={setDocsWithoutGroup}
                            />
                        ))}
                </>
            )}
        </Tab.Pane>
    )
}

export default Documents
