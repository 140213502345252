import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// components 
import { Grid, Card } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
// module specific components
import ItemForm from '../forms/ItemForm';
import CategoryForm from '../forms/CategoryForm';


const ManageItemsAndCategories = ({ setResponse, onClose }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(null);

    return (
        <> 
        { view === null && 
            <Grid>
                <Grid.Row columns={2}>
                    <CanView permissions={['assets.c_manage_items']}>
                        <Grid.Column
                            onClick={() => setView(0)}
                            className="toggle-button"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <Card fluid style={{ borderRadius: 0 }}>
                                <Card.Content as="h2">
                                    { t('add_item') }
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </CanView>
                    <CanView permissions={['assets.c_manage_item_categories']}>
                        <Grid.Column
                            onClick={() => setView(1)}
                            className="toggle-button"
                            style={{
                                textAlign: "center",
                            }}
                        >
                            <Card fluid style={{ borderRadius: 0 }}>
                                <Card.Content as="h2">
                                    { t('add_category') }
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </CanView>
                </Grid.Row>
            </Grid>
            }
        { view === 0 && <ItemForm setResponse={setResponse} onClose={onClose}/> }
        { view === 1 && <CategoryForm setResponse={setResponse} onClose={onClose}/> }
        </>
    );
}

export default ManageItemsAndCategories;