import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Message } from 'semantic-ui-react';

const NonFieldErrors = ({ errors }) => {
    const { t } = useTranslation()
    
    let list = []

    if (errors?.detail) {
        list.push(errors.detail)
    }

    if (errors?.non_field_errors) {
        list = [...list, ...errors?.non_field_errors]
    }


    return (
        <Message error 
            visible={list.length > 0}
            header={t('error_submission_message')} 
            list={list}
        />
    );
};

export default NonFieldErrors;