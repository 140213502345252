import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import { Popup, Form, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';

const NoteForm = ({ invoice, setData, connection, onClose }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [note, setNote] = useState(invoice.note || "")

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.patch(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id, { note: note })
        if (request.status === 200) {
            setData(prev => prev.filter(item => {
                if (item.id === invoice.id) item.note = request.response.note
                return item
            }))
            onClose()
        }
        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="textarea"
                label={t('note')}
                value={note}
                onChange={(e, { value }) => setNote(value)}
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing}
                />
            </Form.Field>
        </Form>
    )
}

const InvoiceNote = ({ invoice, canManage, setData, connection }) => {
    const { t } = useTranslation()

    return (
        <>
            { invoice.note 
                ? 
                    <Popup
                        position="left center"
                        hoverable
                        trigger={
                            <Icon name="chatbubble-ellipses-outline" style={{ fontSize: "1rem" }}/>
                        }
                        content={
                            <div style={{ whiteSpace: "pre-line" }}>
                                { invoice.note }
                            </div>
                        }
                    /> 
                : ""
            }

            { canManage && 
                <SuperDuperModal
                    size="tiny"
                    header={ invoice.note ? t('update_note') : t('add_note') }
                    trigger={
                        <div>
                            <small className="ref-link" style={{ color: "var(--primary" }}>{ invoice.note ? t('update_note') : t('add_note') }</small>
                        </div>
                    }
                    content={
                        <NoteForm
                            invoice={invoice}
                            setData={setData}
                            connection={connection}
                        />
                    }
                />
            }
        </>
    );
};

export default InvoiceNote;