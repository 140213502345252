import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { Link } from 'react-router-dom'
// store
import { requests, fileDownload } from '@helpers/requests'
import { removeURLParameter } from '@helpers/functions'
import { useQueryPage } from '@helpers/hooks'
// components
import Icon from '@components/Icon'
import {
    Container,
    Icon as SemanticIcon,
    Segment,
    Sidebar,
    Menu,
    Ref,
    Grid,
    Label,
    Input,
    Form,
    Card,
    Divider,
    Button,
    Header,
    Checkbox,
    // Popup,
    Table,
    Dropdown,
} from 'semantic-ui-react'
import Paginator from '@components/Paginator'
import Action from '@components/general/Action'
import CanView from '@components/perms/CanView'
import EmptyRow from '@components/tables/EmptyRow'
import SidebarPanel from '@components/SidebarPanel'
import SuperField from '@components/forms/SuperField'
import ConfrimModal from '@components/modals/ConfrimModal'
// eslint-disable-next-line
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexItem, FlexRow } from '@components/tables/FlexTable'

const ListView = ({
    as, // view type: table, card
    title, // custom string
    forceLoading, // allow to update loading state of the table (true/false)
    forceVisibility, // allow to update visible state for filter (true/false)
    onVisibilityChange, // allow to notify about visibility change
    onResponse, // custom onResponse handler
    query, // endpoint query param
    tableHeaders, // table headers
    endpoint, // backend endpoint used to fetch data
    externalData, // give data and not call backend
    allowSearch, // render search field when set to true
    allowViews, // render table/card switch actions when set to true
    allowSelection, // render checkbox for each table row or card to select item
    allowBulkNonSelected, // render dropdown for bulk action even that no records are selected (non-disabled state)
    renderCells, // custom renderer for table cells
    renderCard, // custom renderer for card view
    renderPanel, // custom renderer for panel view
    renderPanelHeader, // custom renderer for panel header
    renderFilterFields, // custom renderer for filter fields
    renderStatisticView, // custom renderer for statistic view
    itemsPerRow, // number of card to be rendered in single row (default: 3)
    loadingMessage, // custom loading message
    initialFilters, // initial filters
    searchKey, // custom search key
    forceReloadOnPagination, // force reload on pagination change
    exports, // list of available exports with structure: [{ title: "Export to PDF", type: "application/pdf", endpoint: API.EXPORTS_HTML_TO_PDF, allowFilters: true }, {...}]
    imports,
    actions, // list of available actions
    settings, // list of available settings options
    actionsCellWidth, // table specific configuration for width of actions cell (default: 3)
    customActionRenderer, // custom filter/action renderer
    setTableRowBackground, // custom row background for table view
    listAdditionActions, // additional filter actions
    bulkActions, // configuration for bulk actions
    isModal, // if List view is present as Modal to avoid sidebar pusher to be pushed from outside of modal container
    isSoftDeleteAllowed, // soft delete
    allowRowExport, // export selected rows
    activeLabelOnRender, // custom filter activate
}) => {
    const { t } = useTranslation()
    const target = useRef()
    const queryPage = useQueryPage()

    const [selected, setSelected] = useState([])
    const [view, setView] = useState(as || 'table')
    const [fetching, setFetching] = useState(true)
    const [visible, setVisible] = useState(false)
    const [numOfFilters, setNumOfFilters] = useState(0)
    const [simpleTable, setSimpleTable] = useState(false)
    const [page, setPage] = useState(parseInt(queryPage))
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [originalResponse, setOriginalResponse] = useState(null)
    const [data, setData] = useState([]) // pagination by default
    const [search, setSearch] = useState('')
    const [orderBy, setOrderBy] = useState({
        key: '',
        direction: 'desc',
    })
    const [queryParams, setQueryParams] = useState('')
    const [searching, setSearching] = useState(false)
    const [activeLabel, setActiveLabel] = useState(activeLabelOnRender || 0)
    const [IDStringArray, setIDStringArray] = useState('')

    // eslint-disable-next-line
    const [searchParam, setSearchParam] = useState(searchKey)
    const [filters, setFilters] = useState(initialFilters || {})

    const fileTypes = [
        { type: 'pdf', file_type: 'application/pdf' },
        { type: 'xlsx', file_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    ]

    const cleanupParams = (params) => {
        let cleanedQueryParams = params
        cleanedQueryParams = removeURLParameter(cleanedQueryParams, 'limit')
        cleanedQueryParams = removeURLParameter(cleanedQueryParams, 'page')
        cleanedQueryParams = removeURLParameter(cleanedQueryParams, 'ordering')
        cleanedQueryParams = removeURLParameter(cleanedQueryParams, 'paginate')
        setQueryParams(cleanedQueryParams)

        return cleanedQueryParams
    }

    const fetchData = async (params, isFiltered, overrideParams) => {
        setFetching(true)

        params = overrideParams !== undefined ? overrideParams : params || ''

        let pageParam = page

        if (params.includes('page')) {
            let searchParams = new URLSearchParams(params)
            const retrievedPage = searchParams.get('page')
            setPage(retrievedPage)
            pageParam = retrievedPage
        }

        if (isFiltered) {
            setPage(1)
            setSelected([])
            pageParam = 1
        }

        if (!params.includes('limit')) {
            params += '&limit=' + limit
        }

        if (!params.includes('page')) {
            params += '&page=' + pageParam
        }

        if (!params.includes('ordering') && orderBy.key) {
            params += `&ordering=${orderBy.direction === 'desc' ? '' : '-'}${orderBy.key}`
        }

        if (!externalData && endpoint) {
            const queryParameters = overrideParams !== undefined ? params : handleParams(params)
            const request = await requests.get(endpoint + `?paginate=true${query || ''}${queryParameters}`)
            if (request.status === 200) {
                setTotal(request.response.count)
                setData(request.response.results)
                setOriginalResponse(request.response)

                if (onResponse !== undefined) onResponse(request.response, filters, cleanupParams(queryParameters))
            }
        } else if (externalData) {
            setData(externalData)
            setTotal(externalData.length)
            setSimpleTable(true)
        }
        setFetching(false)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (forceVisibility !== undefined) {
            setVisible(forceVisibility)
        }
    }, [forceVisibility])

    useEffect(() => {
        if (onVisibilityChange !== undefined) {
            onVisibilityChange(visible)
        }
        // eslint-disable-next-line
    }, [visible])

    useEffect(() => {
        if (selected && selected.length > 0) {
            const idArray = selected.map((obj) => obj.id)
            const idString = idArray.join(',')
            setIDStringArray(idString)
        }

        // eslint-disable-next-line
    }, [selected])

    const addAction = actions?.find((action) => action.type === 'add')

    const handleParams = (params) => {
        params = params || ''

        if (initialFilters !== undefined) {
            const filterArray = Object.keys(filters)
            for (let i = 0; i < filterArray.length; i++) {
                if (
                    filters?.[filterArray[i]] !== '' ||
                    (Array.isArray(filters?.[filterArray[i]]) && filters?.[filterArray[i]].length !== 0)
                ) {
                    if (!params.includes(filterArray[i]) && filterArray[i] !== 'custom_filter') {
                        if (Array.isArray(filters?.[filterArray[i]]) === false) {
                            params += `&${filterArray[i]}=${filters[filterArray[i]]}`
                        } else {
                            let arr = filters?.[filterArray[i]] || []
                            for (let y = 0; y < arr.length; y++) {
                                params += `&${filterArray[i]}=${arr[y]}`
                            }
                        }
                    }
                }
            }

            if (!params.includes('custom_filter')) {
                params += filters?.['custom_filter'] || ''
            }
        }

        if (allowSearch && search !== '') {
            if (searchParam !== undefined) {
                if (!params.includes(searchParam)) {
                    params += `&${searchParam}=${search}`
                }
            } else {
                if (!params.includes('search')) {
                    params += `&search=${search}`
                }
            }
        }

        // if( !params.includes('ordering')) {
        //     params += `&ordering=${orderBy.direction === "asc" ? "" : "-"}${orderBy.key}`
        // }

        // clean params from `page, limit, paginate, ordering`
        cleanupParams(params)

        return params
    }

    useEffect(() => {
        handleParams('')

        // eslint-disable-next-line
    }, [filters])

    const onDelete = async (record) => {
        if (isSoftDeleteAllowed) {
            const request = await requests.post(endpoint + record.id + '/delete/', {})
            if (request.status === 201 || request.status === 200) {
                setTotal((prev) => prev - 1)
                setData((prev) => prev.filter((data) => data.id !== record.id))
            }
        } else {
            const request = await requests.del(endpoint + record.id + '/')
            if (request.status === 204) {
                setTotal((prev) => prev - 1)
                setData((prev) => prev.filter((data) => data.id !== record.id))
            }
        }
    }

    const isFunction = (attr) => {
        return attr instanceof Function
    }

    const onSearch = async () => {
        setSearching(true)
        await fetchData('', true)
        setSearching(false)
    }

    function countChanges(obj1, obj2) {
        let count = 0

        function compareProps(prop1, prop2) {
            if (typeof prop1 === 'object' && typeof prop2 === 'object') {
                const keys1 = Object.keys(prop1)

                for (const key of keys1) {
                    compareProps(prop1[key], prop2[key])
                }
            } else if (prop1 === '' && prop2 !== '') {
                count++
            }
        }

        compareProps(obj1, obj2)

        return count
    }

    useEffect(() => {
        setNumOfFilters(countChanges(initialFilters, filters))
        // eslint-disable-next-line
    }, [filters])

    return (
        <Container fluid>
            <Ref innerRef={target}>
                <Sidebar.Pushable
                    as={Segment}
                    style={{ border: 'none', background: 'transparent', boxShadow: 'none', padding: 0, margin: 0 }}
                    className={isModal ? '' : 'fixed-sidebar'}
                >
                    <Sidebar
                        direction="right"
                        style={{ width: '40rem', textAlign: 'left' }}
                        as={Menu}
                        animation="overlay"
                        icon="labeled"
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        target={target}
                    >
                        <FilterForm
                            filters={filters}
                            fetchData={fetchData}
                            setVisible={setVisible}
                            visible={visible}
                            setFilters={setFilters}
                            initialFilters={initialFilters}
                            renderFilterFields={renderFilterFields}
                            setNumOfFilters={setNumOfFilters}
                        />
                    </Sidebar>
                    <div style={{ marginBottom: '10rem' }}>
                        <div style={{ marginTop: '0.5rem' }}>
                            {
                                renderStatisticView !== undefined && (
                                    // <SpinnerSegment loading={fetching || forceLoading} loadingMessage={t('loading_statistics')}>
                                    <>
                                        {!fetching &&
                                            !forceLoading &&
                                            renderStatisticView(
                                                filters,
                                                data,
                                                total,
                                                queryParams,
                                                setFilters,
                                                setData,
                                                setTotal,
                                                fetchData
                                            )}
                                    </>
                                )
                                // </SpinnerSegment>
                            }
                        </div>
                        <Grid stackable>
                            <Grid.Row verticalAlign="middle" columns={2} style={{ padding: 0 }}>
                                <Grid.Column style={{ padding: 0, marginBottom: '0.5rem' }} width={10}>
                                    <FlexRow padding="0">
                                        <FlexItem>
                                            {title !== undefined && (
                                                <Label
                                                    style={{
                                                        position: 'relative',
                                                        top: '0.4rem',
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        background: 'transparent',
                                                        border: 'none',
                                                        color: 'var(--dark)',
                                                    }}
                                                >
                                                    {title}
                                                </Label>
                                            )}
                                            {bulkActions && (
                                                <Dropdown
                                                    as={Button}
                                                    basic
                                                    size="tiny"
                                                    text={t('bulk_actions')}
                                                    disabled={
                                                        selected.length === 0 &&
                                                        (allowBulkNonSelected === undefined ||
                                                            allowBulkNonSelected === false)
                                                    }
                                                    simple
                                                    style={{ boxShadow: 'none' }}
                                                >
                                                    <Dropdown.Menu direction="right">
                                                        {bulkActions(
                                                            selected,
                                                            setData,
                                                            setTotal,
                                                            filters,
                                                            queryParams,
                                                            setSelected,
                                                            orderBy
                                                        ).map((item) => (
                                                            <CanView permissions={item.permissions || []}>
                                                                {[undefined, false].includes(item.isHidden) && (
                                                                    <>
                                                                        {item.as === 'modal' && (
                                                                            <SuperDuperModal
                                                                                header={item?.name || null}
                                                                                trigger={
                                                                                    <Dropdown.Item
                                                                                        disabled={
                                                                                            item?.disabled?.(
                                                                                                selected
                                                                                            ) || false
                                                                                        }
                                                                                    >
                                                                                        {item.icon && (
                                                                                            <Icon
                                                                                                name={item.icon}
                                                                                                style={{
                                                                                                    marginRight:
                                                                                                        '0.5rem',
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                        <span
                                                                                            style={
                                                                                                item.icon && {
                                                                                                    position:
                                                                                                        'relative',
                                                                                                    top: '-0.2rem',
                                                                                                }
                                                                                            }
                                                                                        >
                                                                                            {item.name}
                                                                                        </span>
                                                                                    </Dropdown.Item>
                                                                                }
                                                                                content={item.modal}
                                                                            />
                                                                        )}
                                                                        {item.as === 'custom' && (
                                                                            <>
                                                                                {item.isConfirm ? (
                                                                                    <ConfrimModal
                                                                                        onConfirm={item.onClick}
                                                                                        description={
                                                                                            item.confirmDescription
                                                                                        }
                                                                                        button={
                                                                                            <Dropdown.Item>
                                                                                                {item.icon && (
                                                                                                    <Icon
                                                                                                        name={item.icon}
                                                                                                        style={{
                                                                                                            marginRight:
                                                                                                                '0.5rem',
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                                <span
                                                                                                    style={
                                                                                                        item.icon && {
                                                                                                            position:
                                                                                                                'relative',
                                                                                                            top: '-0.2rem',
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    {item.name}
                                                                                                </span>
                                                                                            </Dropdown.Item>
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <Dropdown.Item
                                                                                        onClick={item.onClick}
                                                                                    >
                                                                                        {item.icon && (
                                                                                            <Icon
                                                                                                name={item.icon}
                                                                                                style={{
                                                                                                    marginRight:
                                                                                                        '0.5rem',
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                        <span
                                                                                            style={
                                                                                                item.icon && {
                                                                                                    position:
                                                                                                        'relative',
                                                                                                    top: '-0.2rem',
                                                                                                }
                                                                                            }
                                                                                        >
                                                                                            {item.name}
                                                                                        </span>
                                                                                    </Dropdown.Item>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </CanView>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}

                                            {customActionRenderer &&
                                                customActionRenderer(filters, setFilters, fetchData, queryParams)}

                                            {listAdditionActions &&
                                                listAdditionActions(
                                                    data,
                                                    setData,
                                                    total,
                                                    setTotal,
                                                    originalResponse
                                                ).map((additionAction) => (
                                                    <>
                                                        {additionAction.as === 'filter' && (
                                                            <Label
                                                                basic
                                                                onClick={async () => {
                                                                    setActiveLabel(additionAction.index)
                                                                    const filter_keys = Object.keys(
                                                                        additionAction.filters
                                                                    )
                                                                    setFilters((prev) => {
                                                                        let filterArray = prev
                                                                        for (let i = 0; i < filter_keys.length; i++) {
                                                                            filterArray[filter_keys[i]] =
                                                                                additionAction.filters[filter_keys[i]]
                                                                        }

                                                                        return filterArray
                                                                    })

                                                                    let filterString = ''
                                                                    for (let i = 0; i < filter_keys.length; i++) {
                                                                        if (
                                                                            Array.isArray(
                                                                                additionAction.filters[filter_keys[i]]
                                                                            ) === false
                                                                        ) {
                                                                            filterString +=
                                                                                '&' +
                                                                                filter_keys[i] +
                                                                                '=' +
                                                                                additionAction.filters[filter_keys[i]]
                                                                        } else {
                                                                            let arr =
                                                                                additionAction.filters[
                                                                                    filter_keys[i]
                                                                                ] || []
                                                                            for (let y = 0; y < arr.length; y++) {
                                                                                filterString += `&${filter_keys[i]}=${arr[y]}`
                                                                            }
                                                                        }
                                                                    }

                                                                    await fetchData(filterString, true)
                                                                    if (additionAction?.additionalHandler) {
                                                                        additionAction.additionalHandler()
                                                                    }
                                                                }}
                                                                style={{
                                                                    fontWeight: 'normal',
                                                                    fontSize: '0.9rem',
                                                                    padding: additionAction.count ? '' : '0.9rem',
                                                                    cursor: 'pointer',
                                                                    border: 'none',
                                                                    backgroundColor:
                                                                        activeLabel === additionAction.index
                                                                            ? 'var(--primary)'
                                                                            : 'transparent',
                                                                    color:
                                                                        activeLabel === additionAction.index
                                                                            ? 'var(--light)'
                                                                            : 'var(--primary)',
                                                                }}
                                                            >
                                                                {additionAction.name}
                                                                {additionAction?.count >= 0 && (
                                                                    <Label
                                                                        size="tiny"
                                                                        circular
                                                                        style={{
                                                                            fontWeight: 'normal',
                                                                            background: 'var(--white)',
                                                                            color: 'var(--dark)',
                                                                            marginLeft: '0.5rem',
                                                                        }}
                                                                    >
                                                                        {additionAction.count}
                                                                    </Label>
                                                                )}
                                                            </Label>
                                                        )}
                                                        {additionAction.as === 'link' && (
                                                            <Link to={additionAction.redirect}>
                                                                <Label
                                                                    basic
                                                                    style={{
                                                                        fontWeight: 'normal',
                                                                        fontSize: '0.9rem',
                                                                        padding: '0.9rem',
                                                                        cursor: 'pointer',
                                                                        border: 'none',
                                                                        backgroundColor:
                                                                            activeLabel === additionAction.index ||
                                                                            additionAction.isActive
                                                                                ? 'var(--primary)'
                                                                                : 'transparent',
                                                                        color:
                                                                            activeLabel === additionAction.index ||
                                                                            additionAction.isActive
                                                                                ? 'var(--light)'
                                                                                : 'var(--primary)',
                                                                    }}
                                                                >
                                                                    {additionAction.name}
                                                                </Label>
                                                            </Link>
                                                        )}
                                                    </>
                                                ))}
                                        </FlexItem>
                                    </FlexRow>
                                </Grid.Column>
                                <Grid.Column style={{ padding: 0, marginBottom: '0.5rem' }} width={6}>
                                    <FlexRow padding="0">
                                        <FlexItem textAlign="right">
                                            <div style={{ display: 'flex', float: 'right' }}>
                                                {allowSearch && (
                                                    <Form onSubmit={(e) => onSearch()} style={{ width: 'auto' }}>
                                                        <Input
                                                            style={{ position: 'relative', top: '0.5rem' }}
                                                            loading={searching}
                                                            size="small"
                                                            icon={
                                                                <SemanticIcon
                                                                    link
                                                                    name="search"
                                                                    onClick={() => onSearch()}
                                                                />
                                                            }
                                                            placeholder="Search..."
                                                            value={search}
                                                            onChange={(e, { value }) => setSearch(value)}
                                                        />
                                                    </Form>
                                                )}
                                                {allowViews && (
                                                    <>
                                                        <span style={{ paddingRight: '1rem' }}>
                                                            <Action
                                                                as="custom"
                                                                type="icon"
                                                                tooltip={t('table_view')}
                                                                iconColor={
                                                                    view === 'table' ? 'var(--primary)' : 'var(--dark)'
                                                                }
                                                                icon="list-outline"
                                                                size="huge"
                                                                paddingLeft="1rem"
                                                                onClick={() => setView('table')}
                                                            />
                                                            <Action
                                                                as="custom"
                                                                type="icon"
                                                                tooltip={t('card_view')}
                                                                iconColor={
                                                                    view === 'card' ? 'var(--primary)' : 'var(--dark)'
                                                                }
                                                                icon="apps-outline"
                                                                size="huge"
                                                                paddingLeft="1rem"
                                                                onClick={() => setView('card')}
                                                            />
                                                        </span>
                                                        <div
                                                            style={{
                                                                color: 'var(--dark)',
                                                                opacity: '0.5',
                                                                marginRight: '0.2rem',
                                                                position: 'relative',
                                                                top: '1rem',
                                                            }}
                                                        >
                                                            {' '}
                                                            |{' '}
                                                        </div>
                                                    </>
                                                )}
                                                {/* <Action as="custom" type="icon"
                                                    tooltip={t('settings')}
                                                    iconColor="black"
                                                    size="huge"
                                                    icon="settings-outline"
                                                    paddingLeft="1rem"
                                                /> */}
                                                {renderFilterFields !== undefined && (
                                                    <>
                                                        <Action
                                                            as="custom"
                                                            type="icon"
                                                            tooltip={t('filter')}
                                                            iconColor={numOfFilters > 0 ? 'var(--primary)' : 'black'}
                                                            size="huge"
                                                            icon={numOfFilters > 0 ? 'funnel' : 'funnel-outline'}
                                                            paddingLeft={'1rem'}
                                                            onClick={() => setVisible(!visible)}
                                                        />
                                                        {numOfFilters > 0 && (
                                                            <small
                                                                style={{ fontWeight: 'bold', color: 'var(--primary)' }}
                                                            >
                                                                {numOfFilters}
                                                            </small>
                                                        )}
                                                    </>
                                                )}
                                                {exports !== undefined && (
                                                    <>
                                                        {
                                                            exports.length === 1 ? (
                                                                <CanView permissions={exports[0].permissions}>
                                                                    <Action
                                                                        as="custom"
                                                                        type="icon"
                                                                        tooltip={t('download')}
                                                                        iconColor="var(--dark)"
                                                                        size="huge"
                                                                        icon="download-outline"
                                                                        paddingLeft="1rem"
                                                                        onClick={async () => {
                                                                            toast({
                                                                                type: 'success',
                                                                                icon: 'check circle',
                                                                                title: t('generating_export') + '...',
                                                                                description:
                                                                                    t(
                                                                                        'wait_a_moment_until_its_finished'
                                                                                    ) + '.',
                                                                                animation: 'bounce',
                                                                                time: 3000,
                                                                            })
                                                                            await fileDownload(
                                                                                exports[0]?.method || 'GET',
                                                                                exports[0].endpoint +
                                                                                    '?query={*}' +
                                                                                    (selected.length > 0 &&
                                                                                    allowRowExport
                                                                                        ? '&ids=' + IDStringArray
                                                                                        : exports[0]?.apply_filters ===
                                                                                          true
                                                                                        ? handleParams()
                                                                                        : ''),
                                                                                fileTypes.find(
                                                                                    (item) =>
                                                                                        item.type === exports[0].type
                                                                                )?.file_type || '',
                                                                                `${exports[0].filename}.${exports[0].type}`
                                                                            )
                                                                        }}
                                                                    />
                                                                </CanView>
                                                            ) : (
                                                                ''
                                                            ) // loop over and create dropdown with export items!
                                                        }
                                                    </>
                                                )}

                                                {
                                                    settings?.length > 0 && (
                                                        <Menu
                                                            compact
                                                            style={{
                                                                background: 'transparent',
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                            }}
                                                        >
                                                            <Dropdown
                                                                icon={
                                                                    <Icon
                                                                        name="settings-outline"
                                                                        style={{
                                                                            fontSize: '1.3rem',
                                                                            marginLeft: '0.7rem',
                                                                            marginTop: '1.1rem',
                                                                        }}
                                                                    />
                                                                }
                                                                simple
                                                            >
                                                                <Dropdown.Menu
                                                                    direction="left"
                                                                    style={{ marginTop: '-1rem' }}
                                                                >
                                                                    {settings.map((setting, index) => (
                                                                        <CanView permissions={setting.permissions}>
                                                                            {setting.as === 'modal' && (
                                                                                <SuperDuperModal
                                                                                    size={setting?.size || 'small'}
                                                                                    header={
                                                                                        setting?.header || setting.name
                                                                                    }
                                                                                    trigger={
                                                                                        <Dropdown.Item key={index}>
                                                                                            <Icon
                                                                                                name={setting.icon}
                                                                                                style={{
                                                                                                    marginRight:
                                                                                                        '0.5rem',
                                                                                                    fontSize: '1.5em',
                                                                                                }}
                                                                                            />
                                                                                            <span
                                                                                                style={{
                                                                                                    position:
                                                                                                        'relative',
                                                                                                    top: '-0.4rem',
                                                                                                }}
                                                                                            >
                                                                                                {setting.name}
                                                                                            </span>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    content={React.cloneElement(
                                                                                        setting.modal,
                                                                                        {
                                                                                            fetchData: fetchData,
                                                                                            queryParams: queryParams,
                                                                                        }
                                                                                    )}
                                                                                />
                                                                            )}
                                                                            {setting.as === 'link' && (
                                                                                <Dropdown.Item
                                                                                    key={index}
                                                                                    as={Link}
                                                                                    to={setting.linkTo}
                                                                                    target={setting?.target}
                                                                                >
                                                                                    <Icon
                                                                                        name={setting.icon}
                                                                                        style={{
                                                                                            marginRight: '0.5rem',
                                                                                            fontSize: '1.5em',
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            position: 'relative',
                                                                                            top: '-0.4rem',
                                                                                        }}
                                                                                    >
                                                                                        {setting.name}
                                                                                    </span>
                                                                                </Dropdown.Item>
                                                                            )}
                                                                            {setting.as === 'onClick' && (
                                                                                <Dropdown.Item
                                                                                    key={index}
                                                                                    onClick={() =>
                                                                                        setting.onClick(
                                                                                            queryParams,
                                                                                            selected
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Icon
                                                                                        name={setting.icon}
                                                                                        style={{
                                                                                            marginRight: '0.5rem',
                                                                                            fontSize: '1.5em',
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            position: 'relative',
                                                                                            top: '-0.3rem',
                                                                                        }}
                                                                                    >
                                                                                        {setting.name}
                                                                                    </span>
                                                                                </Dropdown.Item>
                                                                            )}
                                                                        </CanView>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Menu>
                                                    )
                                                    // <Action as="modal" type="icon"
                                                    //     tooltip={t('add')}
                                                    //     size="huge"
                                                    //     iconColor="var(--dark)"
                                                    //     header={addAction?.name || null}
                                                    //     icon="add-outline"
                                                    //     modal={addAction?.modal ?
                                                    //         React.cloneElement(addAction?.modal, { setData: setData, setTotal: setTotal }) : null
                                                    //     }
                                                    // />
                                                }
                                                {imports && // ! TODO: readjust!!
                                                imports?.as === 'modal' ? (
                                                    <>
                                                        {imports?.modal !== undefined && imports?.canView === true && (
                                                            <Action
                                                                as="modal"
                                                                type="icon"
                                                                tooltip={t('import')}
                                                                size="huge"
                                                                iconColor="var(--dark)"
                                                                modalSize={imports?.modalSize || 'small'}
                                                                centered={
                                                                    imports?.modalCentered === undefined
                                                                        ? true
                                                                        : imports?.modalCentered
                                                                }
                                                                header={imports?.name || null}
                                                                paddingLeft="1rem"
                                                                icon="push-outline"
                                                                modal={
                                                                    imports?.modal
                                                                        ? React.cloneElement(imports?.modal, {
                                                                              setData: setData,
                                                                              setTotal: setTotal,
                                                                          })
                                                                        : null
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : imports?.as === 'link' ? (
                                                    imports?.canView === true ? (
                                                        <Link to={imports?.route}>
                                                            <Action
                                                                as="custom"
                                                                type="icon"
                                                                tooltip={t('import')}
                                                                size="huge"
                                                                iconColor="var(--dark)"
                                                                paddingLeft="1rem"
                                                                icon="push-outline"
                                                            />
                                                        </Link>
                                                    ) : null
                                                ) : (
                                                    imports && (
                                                        <Link to={imports}>
                                                            <Action
                                                                as="custom"
                                                                type="icon"
                                                                tooltip={t('import')}
                                                                size="huge"
                                                                iconColor="var(--dark)"
                                                                paddingLeft="1rem"
                                                                icon="push-outline"
                                                            />
                                                        </Link>
                                                    )
                                                )}

                                                {addAction !== undefined && (
                                                    <CanView permissions={addAction.permissions}>
                                                        {addAction.as === 'modal' && (
                                                            <Action
                                                                as="modal"
                                                                type="icon"
                                                                tooltip={t('add')}
                                                                size="huge"
                                                                modalSize={addAction?.modalSize || 'small'}
                                                                iconColor="var(--dark)"
                                                                centered={
                                                                    addAction?.modalCentered === undefined
                                                                        ? true
                                                                        : addAction?.modalCentered
                                                                }
                                                                header={addAction?.name || null}
                                                                icon="add-outline"
                                                                modal={
                                                                    addAction?.modal
                                                                        ? React.cloneElement(addAction?.modal, {
                                                                              setData: setData,
                                                                              setTotal: setTotal,
                                                                          })
                                                                        : null
                                                                }
                                                            />
                                                        )}
                                                        {addAction.as === 'link' && (
                                                            <Link to={addAction.redirect()}>
                                                                <Action
                                                                    as="custom"
                                                                    type="icon"
                                                                    tooltip={t('add')}
                                                                    size="huge"
                                                                    iconColor="var(--dark)"
                                                                    paddingLeft="1rem"
                                                                    icon="add-outline"
                                                                />
                                                            </Link>
                                                        )}
                                                    </CanView>
                                                )}
                                            </div>
                                        </FlexItem>
                                    </FlexRow>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Segment
                            loading={fetching || forceLoading}
                            style={{
                                padding: 0,
                                margin: 0,
                                backgroundColor: 'transparent',
                                minHeight: fetching || forceLoading ? '10rem' : 'auto',
                                border: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            {(fetching || forceLoading) && (
                                <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                                    {' '}
                                    {loadingMessage || t('loading')}{' '}
                                </p>
                            )}
                            {!fetching && !forceLoading && (
                                <>
                                    {view === 'table' && (
                                        <TableView
                                            setTableRowBackground={setTableRowBackground}
                                            filters={filters}
                                            isFunction={isFunction}
                                            onDelete={onDelete}
                                            data={data}
                                            setSelected={setSelected}
                                            selected={selected}
                                            setData={setData}
                                            total={total}
                                            setTotal={setTotal}
                                            actionsCellWidth={actionsCellWidth}
                                            renderCells={renderCells}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                            actions={actions}
                                            allowSelection={allowSelection}
                                            tableHeaders={tableHeaders}
                                            fetchData={fetchData}
                                        />
                                    )}
                                    {view === 'card' && (
                                        <CardView
                                            data={data}
                                            total={total}
                                            actions={actions}
                                            setData={setData}
                                            setTotal={setTotal}
                                            renderCard={renderCard}
                                            itemsPerRow={itemsPerRow}
                                        />
                                    )}
                                    {view === 'panel' && (
                                        <PanelView
                                            data={data}
                                            total={total}
                                            filters={filters}
                                            setData={setData}
                                            setTotal={setTotal}
                                            renderPanel={renderPanel}
                                            renderPanelHeader={renderPanelHeader}
                                            selected={selected}
                                            setSelected={setSelected}
                                            allowSelection={allowSelection}
                                        />
                                    )}
                                </>
                            )}
                        </Segment>

                        {total > 0 && !fetching && !forceLoading && !simpleTable && (
                            <Paginator
                                forcePage={page}
                                forceReload={forceReloadOnPagination}
                                limit={limit}
                                setLimit={setLimit}
                                length={total}
                                selected={selected}
                                setSelected={setSelected}
                                onChange={(params) => fetchData(params)}
                            />
                        )}
                    </div>
                </Sidebar.Pushable>
            </Ref>
        </Container>
    )
}

const FilterForm = ({ setVisible, visible, renderFilterFields, filters, setFilters, fetchData, initialFilters }) => {
    const { t } = useTranslation()

    return (
        <Form
            style={{ paddingLeft: '1rem', paddingRight: '1rem', textAlign: 'left' }}
            onSubmit={(e) => e.preventDefault()}
        >
            <Grid>
                <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column>
                        <Header as="h2" content={t('filters')} style={{ marginTop: '1rem', textAlign: 'left' }} />
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon
                            name="close-outline"
                            style={{
                                cursor: 'pointer',
                                color: 'var(--danger)',
                                marginTop: '1.5rem',
                                fontSize: '1.5rem',
                            }}
                            onClick={() => setVisible(false)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            {renderFilterFields !== undefined && renderFilterFields(filters, setFilters, visible)}
            <Divider />
            <Form.Field>
                <Button type="button" content={t('reset')} onClick={() => setFilters(initialFilters)} />
                <Button primary type="button" content={t('confirm')} onClick={async () => await fetchData('', true)} />
            </Form.Field>
        </Form>
    )
}

// eslint-disable-next-line
const PopupFilterForm = ({ fetchData, filter, filterValue, setFilters }) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(filterValue || '')

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <SuperField
                search={filter?.search}
                as={filter.as}
                text={filter?.name || null}
                value={value}
                onChange={(e, { value }) => setValue(value)}
                endpoint={filter?.endpoint || null}
                type={filter?.choiceType || null}
            />
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <Button
                    primary
                    size="tiny"
                    type="button"
                    content={t('confirm')}
                    onClick={async () => {
                        setFilters((prev) => ({ ...prev, [filter.filterBy]: value }))
                        await fetchData(`&${filter.filterBy}=${value}`, true)
                    }}
                />
            </Form.Field>
        </Form>
    )
}

const areAllObjectsInArray = (dataArray, selectedArray) => {
    if (dataArray.length === 0) return false
    for (const dataObject of dataArray) {
        const dataObjectId = dataObject.id
        const matchingObject = selectedArray.find((selectedObject) => selectedObject.id === dataObjectId)

        if (!matchingObject) {
            return false
        }
    }

    return true
}

// LISTVIEWS:
const TableView = ({
    data,
    actions,
    isFunction,
    onDelete,
    allowSelection,
    tableHeaders,
    setOrderBy,
    orderBy,
    fetchData,
    total,
    selected,
    setSelected,
    renderCells,
    setTableRowBackground,
    setData,
    setTotal,
    actionsCellWidth,
    filters,
}) => {
    const { t } = useTranslation()
    const tableActions = actions?.filter((action) => action.type !== 'add') || []
    return (
        <div style={{ padding: 0, overflow: 'auto', width: '100%' }}>
            <Table
                striped
                selectable
                unstackable
                className="table responsive"
                style={{ border: '1px solid rgb(230, 230, 230)' }}
            >
                <Table.Header style={{ fontSize: '0.9rem' }}>
                    <Table.Row verticalAlign="middle">
                        {allowSelection && (
                            <Table.HeaderCell
                                style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem', background: '#E4E4E4' }}
                            >
                                <Checkbox
                                    checked={areAllObjectsInArray(data, selected) ? true : false}
                                    onChange={() => {
                                        if (areAllObjectsInArray(data, selected)) {
                                            setSelected([])
                                        } else {
                                            let toAdd = []
                                            for (let i = 0; i < data.length; i++) {
                                                if (!selected.find((item) => item.id === data[i].id)) {
                                                    toAdd.push(data[i])
                                                }
                                            }
                                            setSelected((prev) => [...prev, ...toAdd])
                                        }
                                    }}
                                />
                            </Table.HeaderCell>
                        )}
                        {tableHeaders.map((header, index) => (
                            <Table.HeaderCell
                                singleLine
                                key={index}
                                style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem', background: '#E4E4E4' }}
                            >
                                <div style={header?.action ? { display: 'inline-block' } : { display: 'flex' }}>
                                    {header?.title}
                                    {header?.action && <>{header.action}</>}
                                    {header?.orderBy && (
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                fontSize: '0.6rem',
                                                opacity: '0.8',
                                                marginLeft: '0.2rem',
                                            }}
                                            onClick={async () => {
                                                setOrderBy({
                                                    key: header?.orderBy,
                                                    direction:
                                                        orderBy.direction === ''
                                                            ? 'asc'
                                                            : orderBy.direction === 'asc'
                                                            ? 'desc'
                                                            : 'asc',
                                                })
                                                await fetchData(
                                                    `&ordering=${orderBy.direction === 'asc' ? '' : '-'}${
                                                        header?.orderBy
                                                    }`,
                                                    true
                                                )
                                            }}
                                        >
                                            {orderBy.key === header?.orderBy && orderBy.direction === 'asc' && (
                                                <>
                                                    <SemanticIcon
                                                        name="sort amount down"
                                                        style={{ color: 'var(--primary)', fontSize: '0.7rem' }}
                                                    />
                                                </>
                                            )}
                                            {orderBy.key === header?.orderBy && orderBy.direction === 'desc' && (
                                                <>
                                                    <SemanticIcon
                                                        name="sort amount up"
                                                        style={{ color: 'var(--primary)', fontSize: '0.7rem' }}
                                                    />
                                                </>
                                            )}

                                            {orderBy.key !== header?.orderBy && (
                                                <>
                                                    <SemanticIcon
                                                        name="sort amount down"
                                                        style={{ fontSize: '0.7rem' }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {/* <FlexRow padding="0" fontSize="0.9rem">
                                    <FlexItem bold>
                                    </FlexItem>
                                    <FlexItem textAlign="right" basis="25%">
                                        <FlexRow padding="0">
                                            {header?.filter &&
                                                <FlexItem textAlign="right" basis="50%">
                                                    <Popup
                                                        style={{ width: "250px" }}
                                                        position="center bottom"
                                                        on="click"
                                                        hoverable
                                                        hideOnScroll={false}
                                                        trigger={
                                                            <Icon name="funnel-outline" style={{ fontSize: "1rem", position: "relative", top: "0.1rem", opacity: "0.8", cursor: "pointer" }} />
                                                        }
                                                        content={
                                                            <PopupFilterForm
                                                                fetchData={fetchData}
                                                                filter={header.filter}
                                                                filterValue={filters?.[header.filter.filterBy] || ""}
                                                                setFilters={setFilters}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                            }
                                        </FlexRow>
                                    </FlexItem>
                                </FlexRow> */}
                            </Table.HeaderCell>
                        ))}
                        {tableActions.length > 0 && (
                            <Table.HeaderCell style={{ background: '#E4E4E4' }}>{t('actions')}</Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontSize: '0.85rem' }}>
                    {total > 0 ? (
                        <>
                            {data?.map((item, idx) => (
                                <Table.Row
                                    verticalAlign="middle"
                                    key={idx}
                                    style={
                                        setTableRowBackground !== undefined
                                            ? { background: setTableRowBackground(item) }
                                            : undefined
                                    }
                                >
                                    {allowSelection && (
                                        <Table.Cell style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
                                            <Checkbox
                                                checked={
                                                    selected?.find((selectedItem) => selectedItem.id === item.id) !==
                                                    undefined
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    if (
                                                        selected.find((selectedItem) => selectedItem.id === item.id) ===
                                                        undefined
                                                    ) {
                                                        // select
                                                        setSelected((prev) => [...prev, item])
                                                    } else {
                                                        // remove
                                                        setSelected((prev) =>
                                                            prev.filter((selectedItem) => selectedItem.id !== item.id)
                                                        )
                                                    }
                                                }}
                                            />
                                        </Table.Cell>
                                    )}
                                    {renderCells(item, setData, setTotal, idx, filters)?.map((cell, index) => (
                                        <Table.Cell
                                            singleLine
                                            className={cell?.className || ''}
                                            width={cell?.width || null}
                                            style={{
                                                paddingTop: '0.7rem',
                                                paddingBottom: '0.7rem',
                                                width: cell?.customWidth || 'inherit',
                                            }}
                                            key={index}
                                        >
                                            {cell?.content}
                                        </Table.Cell>
                                    ))}
                                    {tableActions.length > 0 && (
                                        <Table.Cell
                                            // className="sticky-column-right"
                                            width={actionsCellWidth || 3}
                                            style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}
                                        >
                                            <FlexRow padding="0" background="transparent">
                                                {tableActions.map((action, index) => (
                                                    <CanView
                                                        permissions={action.permissions || []}
                                                        matchEveryPermission={action.matchEveryPermission}
                                                        key={index}
                                                    >
                                                        {/* {action?.isHidden?.(item) !== true && ( */}
                                                        <FlexItem>
                                                            <div
                                                                style={{
                                                                    opacity: action?.isHidden?.(item) !== true ? 1 : 0,
                                                                }}
                                                            >
                                                                {/* {(action.type !== 'delete' && action.type !== 'button' && action.type !== 'icon') ? */}
                                                                {action.type !== 'delete' &&
                                                                action.type !== 'button' &&
                                                                action.type !== 'icon' ? (
                                                                    action.type === 'custom' &&
                                                                    (action.as === 'confirm' ||
                                                                        action.as === 'custom') ? (
                                                                        <Action
                                                                            as={action.as}
                                                                            disabled={
                                                                                action.disabled !== undefined
                                                                                    ? action.disabled
                                                                                    : false
                                                                            }
                                                                            size="huge"
                                                                            icon={action?.icon || 'pencil-outline'}
                                                                            tooltip={
                                                                                action?.isHidden?.(item) !== true
                                                                                    ? isFunction(action?.name)
                                                                                        ? action?.name(item)
                                                                                        : action?.name
                                                                                    : undefined
                                                                            }
                                                                            text={action.text}
                                                                            paddingLeft={
                                                                                action.as === 'confirm' ? '1rem' : 0
                                                                            }
                                                                            paddingRight={
                                                                                action.as === 'confirm' ? '1rem' : 0
                                                                            }
                                                                            iconColor={
                                                                                (isFunction(action?.customIconColor)
                                                                                    ? action?.customIconColor(item)
                                                                                    : action?.customIconColor) ||
                                                                                (action.type === 'delete'
                                                                                    ? 'var(--danger)'
                                                                                    : 'var(--dark)')
                                                                            }
                                                                            onClick={() => {
                                                                                action.onClick(item, setData, setTotal)
                                                                            }}
                                                                        />
                                                                    ) : action.type === 'sidebar' ? (
                                                                        <SidebarPanel
                                                                            // width="850px"
                                                                            uniqueKey={item.id}
                                                                            trigger={
                                                                                <Action
                                                                                    as="custom"
                                                                                    size="huge"
                                                                                    type="icon"
                                                                                    tooltip={
                                                                                        action?.isHidden?.(item) !==
                                                                                        true
                                                                                            ? isFunction(action?.name)
                                                                                                ? action?.name(item)
                                                                                                : action?.name
                                                                                            : undefined
                                                                                    }
                                                                                    icon={
                                                                                        action?.icon || 'pencil-outline'
                                                                                    }
                                                                                    paddingLeft={
                                                                                        action.as === 'confirm'
                                                                                            ? '1rem'
                                                                                            : 0
                                                                                    }
                                                                                    paddingRight={
                                                                                        action.as === 'confirm'
                                                                                            ? '1rem'
                                                                                            : 0
                                                                                    }
                                                                                    iconColor={
                                                                                        (isFunction(
                                                                                            action?.customIconColor
                                                                                        )
                                                                                            ? action?.customIconColor(
                                                                                                  item
                                                                                              )
                                                                                            : action?.customIconColor) ||
                                                                                        'var(--dark)'
                                                                                    }
                                                                                />
                                                                            }
                                                                            content={
                                                                                action?.content instanceof Function
                                                                                    ? React.cloneElement(
                                                                                          action?.content(item),
                                                                                          {
                                                                                              record: item,
                                                                                              setData: setData,
                                                                                              setTotal: setTotal,
                                                                                          }
                                                                                      )
                                                                                    : React.cloneElement(
                                                                                          action?.content,
                                                                                          {
                                                                                              record: item,
                                                                                              setData: setData,
                                                                                              setTotal: setTotal,
                                                                                          }
                                                                                      )
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Action
                                                                            type="icon"
                                                                            size="huge"
                                                                            disabled={
                                                                                action.disabled !== undefined
                                                                                    ? action.disabled instanceof
                                                                                      Function
                                                                                        ? action.disabled(item)
                                                                                        : action.disabled
                                                                                    : false
                                                                            }
                                                                            icon={action?.icon || 'pencil-outline'}
                                                                            as={action.as}
                                                                            paddingTop="0"
                                                                            paddingBottom="0"
                                                                            target={
                                                                                action.as === 'link'
                                                                                    ? action.target
                                                                                    : null
                                                                            }
                                                                            centered={
                                                                                actions?.modalCentered === undefined
                                                                                    ? true
                                                                                    : actions?.modalCentered
                                                                            }
                                                                            paddingLeft={
                                                                                action.as === 'link' ? '1rem' : 0
                                                                            }
                                                                            paddingRight={
                                                                                action.as === 'link' ? '1rem' : 0
                                                                            }
                                                                            to={
                                                                                action?.redirect !== undefined
                                                                                    ? action.redirect(item)
                                                                                    : null
                                                                            }
                                                                            open={
                                                                                item.id === action?.forceModalOpen &&
                                                                                action?.forceModalOpen !== undefined
                                                                            }
                                                                            onClose={
                                                                                action?.setForceModalOpen
                                                                                    ? () => action?.setForceModalOpen(0)
                                                                                    : null
                                                                            }
                                                                            setForceModalOpen={
                                                                                action?.setForceModalOpen
                                                                                    ? () =>
                                                                                          action?.setForceModalOpen?.(
                                                                                              item.id
                                                                                          )
                                                                                    : undefined
                                                                            }
                                                                            tooltip={
                                                                                action?.isHidden?.(item) !== true
                                                                                    ? isFunction(action?.name)
                                                                                        ? action?.name(item)
                                                                                        : action?.name
                                                                                    : undefined
                                                                            }
                                                                            modalSize={action.modalSize || 'small'}
                                                                            modal={
                                                                                action?.modal !== undefined
                                                                                    ? action?.modal instanceof Function
                                                                                        ? React.cloneElement(
                                                                                              action?.modal(
                                                                                                  item,
                                                                                                  setData
                                                                                              ),
                                                                                              {
                                                                                                  record: item,
                                                                                                  setData: setData,
                                                                                                  setTotal: setTotal,
                                                                                              }
                                                                                          )
                                                                                        : React.cloneElement(
                                                                                              action?.modal,
                                                                                              {
                                                                                                  record: item,
                                                                                                  setData: setData,
                                                                                                  setTotal: setTotal,
                                                                                              }
                                                                                          )
                                                                                    : null
                                                                            }
                                                                            iconColor={
                                                                                (isFunction(action?.customIconColor)
                                                                                    ? action?.customIconColor(item)
                                                                                    : action?.customIconColor) ||
                                                                                (action.type === 'delete'
                                                                                    ? 'var(--danger)'
                                                                                    : 'var(--dark)')
                                                                            }
                                                                        />
                                                                    )
                                                                ) : action.type === 'delete' ? (
                                                                    <Action
                                                                        as="delete"
                                                                        size="huge"
                                                                        disabled={
                                                                            action.disabled !== undefined
                                                                                ? action.disabled
                                                                                : false
                                                                        }
                                                                        tooltip={
                                                                            action?.isHidden?.(item) !== true
                                                                                ? isFunction(action?.name)
                                                                                    ? action?.name(item)
                                                                                    : action?.name
                                                                                : undefined
                                                                        }
                                                                        text={action.text}
                                                                        onClick={() => {
                                                                            if (action?.customDelete !== undefined) {
                                                                                action.customDelete(
                                                                                    item,
                                                                                    setData,
                                                                                    setTotal
                                                                                )
                                                                            } else {
                                                                                onDelete(item)
                                                                            }
                                                                        }}
                                                                    />
                                                                ) : action.type === 'button' ? (
                                                                    <Action
                                                                        as="custom"
                                                                        type="button"
                                                                        disabled={
                                                                            action.disabled !== undefined
                                                                                ? action.disabled
                                                                                : false
                                                                        }
                                                                        iconColor={action.iconColor}
                                                                        buttonText={action.buttonText}
                                                                        onClick={() => {
                                                                            action.onClick(item, setData, setTotal)
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Action
                                                                        as="custom"
                                                                        type="icon"
                                                                        icon={action.icon}
                                                                        disabled={
                                                                            action.disabled !== undefined
                                                                                ? action.disabled
                                                                                : false
                                                                        }
                                                                        size="huge"
                                                                        iconColor={
                                                                            action.customIconColor ||
                                                                            (action.type === 'delete'
                                                                                ? 'var(--danger)'
                                                                                : 'var(--dark)')
                                                                        }
                                                                        onClick={() => {
                                                                            action.onClick(item, setData, setTotal)
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </FlexItem>
                                                        {/* )} */}
                                                    </CanView>
                                                ))}
                                            </FlexRow>
                                        </Table.Cell>
                                    )}
                                </Table.Row>
                            ))}
                        </>
                    ) : (
                        <Table.Row verticalAlign="middle">
                            <Table.Cell
                                colSpan={tableHeaders.length + 1 + (allowSelection ? 1 : 0)}
                                textAlign="center"
                                style={{ fontWeight: 'bold' }}
                            >
                                {t('no_data')}
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    )
}

const CardView = ({ data, itemsPerRow, total, setData, setTotal, actions, renderCard }) => {
    return (
        <Card.Group
            stackable
            itemsPerRow={itemsPerRow || 3}
            style={{ paddingLeft: '0.2rem', paddingRight: '0.2rem', marginBottom: '1rem' }}
        >
            <EmptyRow length={total} />
            {renderCard !== undefined && renderCard(data, setData, setTotal, actions)}
        </Card.Group>
    )
}

const PanelView = ({
    data,
    total,
    renderPanel,
    renderPanelHeader,
    setData,
    setTotal,
    allowSelection,
    setSelected,
    selected,
    filters,
}) => {
    return (
        <Grid stackable style={{ padding: 0, position: 'relative', top: '-1rem' }}>
            {renderPanelHeader !== undefined && (
                <Grid.Row columns="1" style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid.Column style={{ padding: 0 }}>{renderPanelHeader()}</Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row columns={1} style={{ paddingTop: 0, paddingBottom: 0 }}>
                {renderPanel !== undefined && (
                    <>
                        {data.map((item, idx) => (
                            <Grid.Column key={item.id} style={{ padding: 0 }}>
                                {renderPanel(item, setData, setTotal, allowSelection, setSelected, selected, filters)}
                            </Grid.Column>
                        ))}
                    </>
                )}
                {total === 0 && (
                    <Grid.Column>
                        <EmptyRow length={total} />
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    )
}

export default ListView
