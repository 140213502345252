import React from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
// module specific components
import HRProcessForm from '../components/forms/HRProcessForm';

const HiringProcessesListView = () => {
    const { t } = useTranslation()

    return (
        <CanView permissions={['processes.c_view_processes']} redirect>
            <ListView
                as="table"
                allowSearch
                endpoint={API.PROCESSES}
                actionsCellWidth="2"
                initialFilters={{
                    process_type: 1
                }}
                tableHeaders={[
                    { title: t("name") },
                    { title: t("stages") },
                    { title: t("status") },
                ]}
                renderCells={(process) => ([
                    { content: process.name },
                    { content: process.stages.length },
                    { width: 1, content: 
                        <div style={{ display: "flex" }}>
                            <Icon name="ellipse" style={{ color: process.is_active ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                { process.is_active ? t('active') : t('inactive') } 
                            </span>
                        </div>
                    },
                ])}
                actions={[
                    {
                        as: "modal",
                        type: "add",
                        modalSize: "large",
                        modal: <HRProcessForm/>,
                        permissions: ['processes.c_manage_processes']
                    },
                    {
                        name: t('edit'),
                        type: "edit",
                        as: "modal",
                        modalSize: "large",
                        icon: "pencil-outline",
                        customIconColor: "var(--dark)", 
                        permissions: ['processes.c_manage_processes'],
                        modal: <HRProcessForm/>
                    },
                    {
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('are_you_sure'),
                        permissions: ['processes.c_delete_processes']
                    },
                ]}
            />
        </CanView>
    );
};

export default HiringProcessesListView;