import React from 'react';
import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';
import Phone from '@components/general/Phone'

const ContactDetails = ({ phone, email }) => {
    const { t } = useTranslation();

    return (
        <>
            {phone || email ?
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {phone &&
                        <Phone phone={phone}/>
                    }

                    {email &&
                        <a href={"mailto:" + email}>
                            <Icon name="mail-outline"  style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.2rem" }}>{email}</span>
                        </a>
                    }
                </div>
                : <span style={{ color: "var(--danger)", fontWeight: "bold" }}>{t('no_active_contact')}</span>}
        </>
    );
};

export default ContactDetails;