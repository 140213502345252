import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests';
import { isEmpty } from "@services/helpers/validation"
// components
import { Form, Button, Message } from 'semantic-ui-react'



function InterestForm({ interest, onConfirm, onClose}) {
    const { t } = useTranslation();

    const [data, setData] = useState({
        name: interest?.name ? interest.name : '',
    })

    const [errors, setErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.name) {
            errorsArray.push(response.name)
        }
        setErrors(errorsArray);
    }

    const handleSuccess = (response) => {
        onConfirm(response);
        onClose()
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        let dataset = {
            name: data.name,
        }

        const request = await requests.post(API.INTERESTS, dataset);
        if (request.status === 201) {
            handleSuccess(request.response)
        } else {
            handleErrors(request.response)
        }

        setIsProcessing(false);
    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />

            <Form.Field>
                <Form.Input
                    autoFocus
                    required
                    label={t('name')}
                    placeholder={t('name')}
                    value={data.name}
                    onChange={(e) => setData({
                        ...data,
                        name: e.target.value
                    })}
                />

            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    basic
                    type="button"
                    onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        isEmpty(data.name)
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default InterestForm