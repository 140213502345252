import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
// store
import { isEmpty } from '@services/helpers/validation';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider, Label } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';
import SuperField from '@components/forms/SuperField';

const GuestsForm = ({ data, currency, accommodationID, accommodation, selectedDay, handleRequest, setData, setTotal, onClose }) => {
    const { t } = useTranslation()

    const firstDay = selectedDay
    const lastDay = selectedDay ? moment(selectedDay).add(1, 'days').format("YYYY-MM-DD") : null

    const price = accommodation?.data?.price_per_night_per_person ? parseFloat(accommodation?.data?.price_per_night_per_person) : ""
    currency = currency || "EUR"

    const [guest, setGuest] = useState({
        id: data?.id || "",
        purpose: data?.purpose || "",
        from_date: data?.from_date || firstDay || "",
        to_date: data?.to_date || lastDay || "",
        is_archived: data?.is_archived || false,
        price_per_night_per_person: price || "",
        accommodation: accommodationID || "",
        currency: currency || "EUR",
        profile: data?.profile || "",
        business_detail: data?.business_detail || "",
        room: data?.room || ""
    })

    const isValidRange = (from, to) => {
        let dateFrom = from ? moment(from) : null
        let dateTo = to ? moment(to) : null

        if (dateFrom && dateTo) {
            if (dateFrom.isSame(dateTo)) return false
            if (dateFrom.isAfter(dateTo)) return false

            return true
        }

        return false
    }

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleSuccess = (response) => {
        handleRequest(response, setData, setTotal);
        onClose()
    }

    const handleSubmit = async () => {
        setErrors(null);
        setIsProcessing(true);

        const newData = {
            purpose: guest.purpose,
            from_date: guest.from_date,
            to_date: guest.to_date,
            is_archived: guest.is_archived,
            price_per_night_per_person: guest.price_per_night_per_person !== "" ? guest.price_per_night_per_person : null,
            currency: guest.currency,
            accommodation: guest.accommodation,
            profile: guest.profile,
            business_detail: guest.business_detail,
            room: guest.room
        }

        if (!guest.id || guest.id === 0) {
            const request = await requests.post(API.ACCOMMODATIONS + 'guests/', newData);

            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                handleSuccess(request.response)
            }
        } else {
            const request = await requests.patch(API.ACCOMMODATIONS + 'guests/' + guest.id + '/', newData);

            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                handleSuccess(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors}/>
            <SuperField as="choice"
                search
                required
                autoFocus
                label={t('profile')}
                value={guest.profile}
                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                error={errors?.profile?.[0] || false}
                text="fullname"
                onChange={(e, { value }) => setGuest({
                    ...guest,
                    profile: value
                })}
            />
            <SuperField as="choice"
                search
                label={t('room')}
                value={guest.room}
                endpoint={API.ACCOMMODATIONS + `rooms/?accommodation=${accommodationID}`}
                error={errors?.room?.[0] || false}
                text="number"
                onChange={(e, { value }) => setGuest({
                    ...guest,
                    room: value
                })}
            />
            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    type="purpose_choices"
                    label={t('purpose')}
                    value={guest.purpose}
                    error={errors?.purpose?.[0] || false}
                    onChange={(e, { value }) => setGuest({
                        ...guest,
                        purpose: value
                    })}
                />
                <Form.Input
                    required
                    label={t('price_per_night_per_person')}
                    placeholder={t('price_per_night_per_person')}
                    value={guest.price_per_night_per_person}
                    error={errors?.price_per_night_per_person?.[0] || false}
                    onChange={(e, { value }) => setGuest({
                        ...guest,
                        price_per_night_per_person: value.replace(",", ".")
                    })}
                >
                    <input />
                    <Label basic style={{ paddingTop: "0.9rem" }}>{currency}</Label>
                </Form.Input>
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={ t('date_from') }
                    placeholder={t('date_from')}
                    error={guest.from_date !== "" && guest.to_date !== "" && !isValidRange(guest.from_date, guest.to_date) ? t("invalid_date_range") : false}
                    value={guest.from_date}
                    onChange={ (e, { value }) => setGuest({...guest, from_date: value})}
                    />
                <SuperField as="datepicker"
                    required
                    label={ t('date_to') }
                    placeholder={t('date_to')}
                    error={guest.from_date !== "" && guest.to_date !== "" && !isValidRange(guest.from_date, guest.to_date) ? t("invalid_date_range") : false}
                    value={guest.to_date}
                    onChange={ (e, { value }) => setGuest({...guest, to_date: value})}
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={t('archived')}
                checked={guest.is_archived}
                onChange={() => setGuest({...guest, is_archived: !guest.is_archived})}
            />
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(guest.profile) || isEmpty(guest.price_per_night_per_person) || !isValidRange(guest.from_date, guest.to_date)}
                    text={t('submit')} />
            </Form.Field>
        </Form>
    );
};

export default GuestsForm;