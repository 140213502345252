import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider } from 'semantic-ui-react';
import NonFieldErrors from '@components/NonFieldErrors';

const UnitChange = ({ selected, setData, onClose }) => {
    const { t } = useTranslation()

    const employees = selected.map(item => item.id)
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        unit: ""
    })

    const handleSubmit = async () => {
        setProcessing(true)

        const request = await requests.patch(API.UNITS + form.unit + "/bulk/assign_employees/",{ employees: employees })
        if (request.status === 200) {
            const updatedEmployees = request.response
            const updatedEmployeesIds = request.response.map(item => item.profile_id)
            setData(prev => prev.filter(item => {
                if (updatedEmployeesIds.includes(item.id)) {
                    const updatedEmployee = updatedEmployees.find(employee => employee.profile_id === item.id)
                    item.unit = updatedEmployee !== undefined ? {
                        id: updatedEmployee.unit,
                        name: updatedEmployee.unit_name,
                        company: updatedEmployee?.company_id,
                    } : null
                }
                return item
            }))  
            onClose()
        } else if (request.status === 400) {
            setErrors(request.response)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />
            <SuperField as="choice"
                label={t('unit')}
                search
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                text="name"
                value={form.unit}
                onChange={ 
                    (e, { value }) => setForm({
                        ...form,
                        unit: value
                    })
                }
            />
            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || form.unit === ""}
                />
            </Form.Field>
        </Form>
    );
};

export default UnitChange;