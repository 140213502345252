import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
// components
import { Label } from 'semantic-ui-react';
import ListView from '@components/ListView';
import AvatarList from '@components/AvatarList';
// import SuperField from '@components/forms/SuperField';
import ProcessForm from '../components/ProcessForm';
import ProcessItemForm from '../components/ProcessItemForm';
import ProcessOverview from '../components/ProcessOverview';

const ProcessList = () => {
    const { t } = useTranslation()

    const dateFormat = useSelector(store => store.date_format)

    return (
        <ListView as="table"
            allowSearch
            // isSoftDeleteAllowed
            actionsCellWidth="2"
            endpoint={API.ASSETS + "warehouse_processes/"}
            tableHeaders={[
                { title: t('title') },
                { title: t('start_date') },
                { title: t('responsible_persons') },
                { title: t('note') },
                { title: t('state') },
                { title: t('status') },
            ]}
            initialFilters={{
                is_active: true,
                is_template: false,
            }}
            listAdditionActions={(data, setData, total, setTotal, response) => ([
                {
                    as: "filter",
                    index: 0,
                    name: t('open'),
                    filters: {
                        is_active: true,
                    },
                    count: response?.total_active || 0
                },
                {
                    as: "filter",
                    index: 1,
                    name: t('closed'),
                    filters: {
                        is_active: false,
                    },
                    count: response?.total_inactive || 0
                },
                {
                    as: "filter",
                    index: 2,
                    name: t('all'),
                    filters: {
                        is_active: null,
                    },
                    count: response?.total_processes || 0
                },
            ])}
            renderCells={(process, setData, setTotal, idx, filters) => (
                [
                    {
                        content: process.title
                    },
                    {
                        content: process.start_date ? moment(process.start_date).format(dateFormat) : t('unspecified')
                    },
                    {
                        content:
                            <AvatarList
                                size="25"
                                alignDirection="start"
                                persons={
                                    process.responsible_persons.map(item => ({
                                        id: item.id,
                                        name: item.fullname,
                                        avatar: item.profile_picture,
                                        displayName: item?.fullname_with_titles || item.fullname,
                                    }))
                                }
                            />
                    },
                    {
                        content: process.note
                    },
                    {
                        content: process.percentage + "%"
                    },
                    {
                        content: <Label color={process.is_active ? "green" : "red"} size="small">{process.is_active ? t('opened') : t('closed')}</Label>
                    },
                ]
            )}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    name: t('launch_process'),
                    modalSize: "tiny",
                    modal: <ProcessForm />,
                    permissions: ['assets.c_manage_warehouse_processes'],
                },
                {
                    as: "modal",
                    type: "custom",
                    icon: "eye-outline",
                    name: t('show_details'),
                    modalSize: "large",
                    customIconColor: "var(--primary)",
                    modal: (item, setData) => <ProcessOverview process={item} setData={setData}/>,
                    permissions: ['assets.c_view_warehouse_processes']
                },
                {
                    as: "modal",
                    type: "custom",
                    modalSize: "tiny",
                    icon: "add--circle-outline",
                    name: t('assign_commodity'),
                    customIconColor: "var(--blue)",
                    modal: <ProcessItemForm/>,
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_warehouse_processes', 'assets.c_view_items']
                },
                {
                    as: "modal",
                    type: "edit",
                    name: t('edit'),
                    modalSize: "tiny",
                    permissions: ['assets.c_manage_warehouse_processes'],
                    modal: <ProcessForm />
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('process_delete_message'),
                    permissions: ['assets.c_manage_warehouse_processes'],
                },
            ]}
        />
    );
};

export default ProcessList;