import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components 
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Header } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';

const AccessCardForm = ({ data, setData, setTotal, onClose }) => {
    const { t } = useTranslation()
    
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        id: data?.id || "",
        profile: data?.profile?.id || "",
        code: data?.code || "",
        category: data?.category?.id || null,
        valid_from: data?.valid_from || null,
        valid_to: data?.valid_to || null,
        note: data?.note || "",
        allow_in_attendance_system: data?.allow_in_attendance_system || false,
        allow_in_warehousing_system: data?.allow_in_warehousing_system || false
    })

    const isFormValid = () => {
        if (form.profile !== "" || form.code !== "") return true

        return false
    }

    const onAdd = (response, setData, setTotal) => {
        setData(prev => [response, ...prev])
        setTotal((prev) => prev + 1)
    }

    const onUpdate = (response, setData) => {
        setData(prev => prev.map(item => {
            if (item.id === response.id) {
                item = response
            }
            return item;
        }))
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)

        let formData = {
            profile: form.profile,
            code: form.code,
            category: form.category,
            valid_from: form.valid_from,
            valid_to: form.valid_to,
            note: form.note,
            allow_in_attendance_system: form.allow_in_attendance_system,
            allow_in_warehousing_system: form.allow_in_warehousing_system
        }

        let request = undefined

        if (!form.id || form.id === 0) {
            request = await requests.post(API.ACCESS_CARD, formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                onAdd(request.response, setData, setTotal)
                onClose()
            }
        } else {
            request = await requests.patch(API.ACCESS_CARD + data.id + "/", formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                onUpdate(request.response, setData, setTotal)
                onClose()
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            { data?.id !== undefined &&
                <>
                    <Header as="h3" content={t('update')}/>
                    <Divider/>
                </>
            }
            <NonFieldErrors errors={errors} />

            <SuperField
                autoFocus
                as="choice"
                search
                label={t('employee')}
                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                text="fullname"
                value={form.profile}
                onChange={(e, { value }) => setForm({
                    ...form,
                    profile: value
                })}
            />
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t("code")}
                    value={form.code}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        code: value
                    })}
                />
                <SuperField as="choice"
                    search
                    endpoint={API.ACCESS_CARD_CATEGORIES}
                    text="title"
                    label={t('category')}
                    value={form.category}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        category: value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    clearable
                    label={t('valid_from')}
                    value={form.valid_from}
                    onChange={(e, { value }) => setForm({ ...form, valid_from: value })}
                />
                <SuperField as="datepicker"
                    clearable
                    label={t('valid_to')}
                    value={form.valid_to}
                    onChange={(e, { value }) => setForm({ ...form, valid_to: value })}
                />
            </Form.Group>
            <SuperField as="textarea"
                label={t("note")}
                value={form.note}
                onChange={(e, { value }) => setForm({ ...form, note: value })}
            />
            <Divider />
                <Header as="h4" content={t('permissions')} style={{ marginTop: 0, paddingTop: 0 }}/>
                <SuperField as="checkbox"
                    label={t('allow_in_attendance_system')}
                    checked={form.allow_in_attendance_system}
                    onChange={(e) => setForm({...form, allow_in_attendance_system: !form.allow_in_attendance_system })}
                />
                <SuperField as="checkbox"
                    label={t('allow_in_warehousing_system')}
                    checked={form.allow_in_warehousing_system}
                    onChange={(e) => setForm({...form, allow_in_warehousing_system: !form.allow_in_warehousing_system })}
                />
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    text={t("confirm")}
                    disabled={isProcessing || !isFormValid()}
                    loading={isProcessing} 
                />
            </Form.Field>
        </Form>
    );
};

export default AccessCardForm;