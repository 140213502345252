import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests, fileDownload } from '@helpers/requests';
// components
import { Form, Divider, Header, Segment } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const AllowedUserDocumentsForm = ({ onClose, selectedVehicles }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fetching, setFetching] = useState(true)
    const [templates, setTemplates] = useState([])
    const [allowedUsers, setAllowedUsers] = useState([])

    async function fetchTemplates(){
        setLoading(true)
        const request = await requests.get(API.TEMPLATING + "?source=vehicles.VehicleAllowedUser&query={id, title, source}&is_archived=false")
        if (request.status === 200) {
            let template_list = request.response.map(item => ({ checked: false, ...item }))
            if (template_list.length === 1) {
                template_list[0].checked = true
            }
            setTemplates(prev => ([...prev, ...template_list]))
        }
        setLoading(false)
    }

    async function fetchAllowedUsers(){
        setFetching(true)
        let persons = []
        for (let i = 0; i < selectedVehicles.length; i++) {
            const request = await requests.get(API.VEHICLES + "allowed_users/?query={id, contract{id, employee}}&vehicle=" + selectedVehicles[i].id)
            if (request.status === 200) {
                const responsible_persons = request.response.map(item => ({
                    id: item.id,
                    fullname: item.contract.employee.fullname,
                    vehicle: selectedVehicles[i].id,
                    checked: true
                }))

                persons = [...persons, ...responsible_persons]
            }
        }

        setAllowedUsers(persons)
        setFetching(false)
    }

    async function generate(e){
        e.preventDefault();
        setProcessing(true)
        
        const selected = allowedUsers.filter(item => item.checked)
        for (let y = 0; y < selected.length; y++) {
            for (let i = 0; i < templates.length; i++) {
                if(templates[i].checked === true){
                    if (templates[i].source === "vehicles.VehicleAllowedUser") {
                        const request = await requests.get(API.TEMPLATING + `${templates[i].id}/fill/vehicles.VehicleAllowedUser/${selected[y].id}`)
                        if( request.status === 200 ){
                            await fileDownload("POST", API.EXPORTS_HTML_TO_PDF, "application/pdf", `${templates[i].title}-${selected[y].fullname}-${selected[y].id}.pdf`, {
                                content: request.response.html
                            })
                        }
                    }
                }
            }
        }
        
        setProcessing(false)
        onClose()
    }

    useEffect(() => {
        fetchTemplates()
        fetchAllowedUsers()
        // eslint-disable-next-line
    }, [])

    return (
        <Form onSubmit={generate}>
            <Header as="h4">
                { t('choose_documents') }:
            </Header>

            <Segment loading={loading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none" }}>
                { loading && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_templates') } </p> }
                { !loading && 
                    <>
                        { templates.map(template => (
                            <SuperField as="checkbox" key={template.id}
                                label={t('template') + " - " + template.title}
                                checked={template.checked}
                                onChange={() => setTemplates(prev => prev.filter(item => {
                                    if (item.id === template.id) {
                                        item.checked = !template.checked
                                    }

                                    return item
                                }))}
                            />
                        )) }
                    </>
                }
            </Segment>

            <Divider/>

            <Header as="h3">
                { t('choose_responsible_persons_for_vehicles') }:
            </Header>

            <Segment loading={fetching} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none" }}>
                { fetching && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_responsible_persons') } </p> }
                { selectedVehicles.map(vehicle => (
                    <div key={vehicle.id} style={{ marginTop: "1rem" }}>
                        { !fetching && 
                            <>
                                <Header as="h5" content={ vehicle.title + " - " + vehicle.car_plate_number + ":" }/>
                                    
                                { allowedUsers.filter(item => item.vehicle === vehicle.id).map(item => (
                                    <SuperField as="checkbox" key={item.id}
                                        label={item.fullname}
                                        checked={item.checked}
                                        onChange={() => setAllowedUsers(prev => prev.filter(allowedUser => {
                                            if (allowedUser.id === item.id) {
                                                allowedUser.checked = !item.checked
                                            }
        
                                            return allowedUser
                                        }))}
                                    />
                                )) }
                            </>
                        }
                    </div>
                )) }
            </Segment>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={processing || templates.length === 0}
                    loading={processing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default AllowedUserDocumentsForm;