import React from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Label } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module specific components
import BenefitForm from '../components/BenefitForm';
import BenefitAssignee from '../components/BenefitAssignee'


const BenefitsList = () => {
    const { t } = useTranslation()

    return (
        <CanView permissions={["benefits.c_view_all_benefits", "benefits.c_view_user_benefits"]} redirect>
            <BreadcrumbNav items={[{ 'name': t('benefits_list'), 'icon': '', 'href': '' }]}/>
            <ListView
                as="table"
                isSoftDeleteAllowed
                // title="Benefits Table"
                endpoint={API.BENEFITS}
                tableHeaders={[
                    { 
                        title: t('name'),
                        orderBy: "name"
                    },
                    { 
                        title: t('category'),
                        orderBy: "category__name"
                    },
                    { title: t('assigned') },
                ]}
                initialFilters={{
                    category: "",
                }}
                renderFilterFields={(filters, setFilters) => (
                    <SuperField
                        as="choice"
                        label={t('category')}
                        endpoint={API.BENEFITS_CATEGORIES}
                        text="name"
                        value={filters.category}
                        onChange={(e, { value }) => setFilters(prev => ({...prev, category: value}))}
                    />
                )}
                actions={[
                    {
                        as: "modal",
                        type: "add",
                        name: t('add_benefit'),
                        permissions: ['benefits.c_manage_all_benefits'],
                        modal: <BenefitForm/>
                    },
                    {
                        as: "modal",
                        type: "edit",
                        name: t('edit'),
                        icon: "pencil-outline",
                        permissions: ['benefits.c_manage_all_benefits'],
                        modal: <BenefitForm/>
                    },
                    { 
                        name: t('delete'),
                        type: "delete",
                        as: "delete",
                        text: t('delete_benefit'),
                        permissions: ['benefits.c_manage_all_benefits'] 
                    },

                ]}
                renderCells={ (item, setData, setTotal) => ([
                    { content: item.name },
                    { content: item.category?.name 
                        ? <Label size="small" style={{ background: item.category?.color, color: "var(--light)" }}> { item.category?.name }</Label> 
                        : "--"
                    },
                    { content: 
                        <SuperDuperModal
                            trigger={
                                <Label basic style={{ cursor: "pointer" }}>
                                    <Icon name="people-outline" style={{ marginRight: "0.5rem", fontSize: "1.2rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.3rem" }}>{ item.employees.length }</span>
                                </Label>
                            }
                            content={<BenefitAssignee employees={item.employees}/>}
                        />     
                    }
                ])}
            />
        </CanView>
    )
}

export default BenefitsList;