import React, { useState, useEffect } from 'react'
import * as ExcelJS from 'exceljs'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import moment from 'moment'
// store
import { routes } from '@routes/index'
import { useHistory } from 'react-router-dom'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { capitalizeFirstLetter } from '@helpers/functions'
// components
import { Grid, Form, Divider, Container, Header, Modal, Dropdown, Button, Message } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import { ExcelLikeTable, getDataset } from '@components/ExcelLikeTable'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import Icon from '@components/Icon'
import imports from './ImportsConfig'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import SpinnerSegment from '@components/SpinnerSegment'

const Imports = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const permissions = useSelector((state) => state.permissions)

    let searchParams = new URLSearchParams(location.search)
    const category = searchParams.get('type')
    const scheme = searchParams.get('scheme')

    const [options, setOptions] = useState([])
    const [schemeOptions, setSchemeOptions] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(category || null)
    const [selectedScheme, setSelectedScheme] = useState(null)
    const [wasSchemeChanged, setWasSchemeChanged] = useState(false)

    const [isProcessing, setIsProcessing] = useState(false)
    const [isCleared, setIsCleared] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenValidation, setIsOpenValidation] = useState(false)
    const [validationError, setValidationError] = useState('')

    const [isFileUpload, setIsFileUpload] = useState(false)
    const [file, setFile] = useState(null)
    const [items, setItems] = useState([])
    const [response, setResponse] = useState(null)

    useEffect(() => {
        async function fetchData() {
            if (scheme && schemeOptions) {
                for (let i = 0; i < schemeOptions.length; i++) {
                    if (schemeOptions[i].key === scheme) {
                        setSelectedScheme(schemeOptions[i].value)
                    }
                }
            }
        }

        fetchData()
        // eslint-disable-next-line
    }, [schemeOptions])

    useEffect(() => {
        async function fetchOptions() {
            const listOfOptions = []
            const categs = []
            for (let i = 0; i < imports.length; i++) {
                const type = imports[i].type
                const canView =
                    imports[i]?.permissions?.length === 0 ||
                    imports[i]?.permissions?.some((perm) => permissions.includes(perm))
                if (!categs.includes(imports[i].type) && type && canView) {
                    listOfOptions.push({ key: i, value: imports[i].type, text: t(imports[i].type) })
                    categs.push(imports[i].type)
                }
            }
            setOptions(listOfOptions)
        }

        fetchOptions()
        // eslint-disable-next-line
    }, [])

    async function fetchSchemas(category) {
        const newData = []
        for (let i = 0; i < imports.length; i++) {
            if (selectedCategory === imports[i].type || category === imports[i].type) {
                if (imports[i].schemas) {
                    for (let j = 0; j < imports[i].schemas.length; j++) {
                        const canView =
                            imports[i]?.schemas[j]?.config?.permissions?.length === 0 ||
                            imports[i]?.schemas[j]?.config?.permissions?.some((perm) => permissions.includes(perm))
                        if (canView) {
                            newData.push({
                                key: imports[i].schemas[j].id,
                                value: imports[i].schemas[j],
                                text: t(imports[i].schemas[j].title),
                            })
                        }
                    }
                }
            }
        }
        setSchemeOptions(newData)
    }

    useEffect(() => {
        fetchSchemas()
        // eslint-disable-next-line
    }, [selectedCategory])

    const handleChangesSchemes = (value) => {
        setIsProcessing(true)
        setSelectedScheme(value)
        setIsProcessing(false)
        setWasSchemeChanged(true)
    }

    function excludeEmptyRows(items) {
        const list = items.filter((item) => {
            return Object.values(item).some((value) => value) !== false
        })

        return list
    }

    const isTableFilled = () => {
        return excludeEmptyRows(getDataset(items, selectedScheme?.config?.headers)).length > 0
    }

    function validateItem(item, headers) {
        return headers.every((header) => {
            if (header.required && !item[header.title]) setValidationError('required')
            if (
                (header.dataType === 'number' || header.dataType === 'floatNumber') &&
                typeof item[header.title] !== 'number' &&
                item[header.title]
            ) {
                setValidationError('number')
            }
            return (
                ((header.required && item[header.title]) || !header.required) &&
                (((header.dataType === 'number' || header.dataType === 'floatNumber') &&
                    typeof item[header.title] === 'number' &&
                    item[header.title]) ||
                    (header.dataType !== 'number' && header.dataType !== 'floatNumber') ||
                    ((header.dataType === 'number' || header.dataType === 'floatNumber') && !item[header.title]))
            )
        })
    }

    const handleImport = async (file) => {
        const headersConfig = selectedScheme?.config?.headers
        let isValid = true

        setIsProcessing(true)
        setIsOpen(true)
        setResponse(null)
        setIsFileUpload(false)

        file = file || undefined
        if (file === undefined) {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet(selectedScheme.sheetName || capitalizeFirstLetter(selectedCategory))
            const headers = Object.keys(items[0]).filter((columnId) => columnId !== '#')
            worksheet.addRow(headers)

            excludeEmptyRows(getDataset(items, headersConfig)).forEach((item) => {
                isValid = validateItem(item, headersConfig)

                const rowData = Object.values(item)
                worksheet.addRow(rowData)
            })

            if (!isValid) {
                setIsOpenValidation(true)
                setIsOpen(false)
                setIsProcessing(false)
            } else {
                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                })
                file = new File([blob], 'data.xlsx', {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                })
            }
        }
        if (isValid) {
            const formData = new FormData()
            formData.append('file', file)

            const request = await requests.post(API.IMPORTS + selectedScheme.config.endpoint, formData)
            setResponse(request)
            setIsProcessing(false)
        }
    }

    const handleDownload = async () => {
        const headersConfig = selectedScheme?.config?.headers
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet(selectedScheme.sheetName || capitalizeFirstLetter(selectedCategory))
        const headers = Object.keys(items[0]).filter((columnId) => columnId !== '#')
        worksheet.addRow(headers)

        excludeEmptyRows(getDataset(items, headersConfig)).forEach((item) => {
            const { '#': excludedColumn, ...newItem } = item
            const rowData = Object.values(newItem)
            worksheet.addRow(rowData)
        })

        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const file = new File([blob], 'data.xlsx', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(file)
        downloadLink.download =
            t(selectedCategory) + '__' + t(selectedScheme.title) + '__' + moment().format('DD.MM.YYYY') + '.xlsx'

        document.body.appendChild(downloadLink)
        downloadLink.click()
    }

    const handleFileFormSubmit = async () => {
        await handleImport(file)
    }

    return (
        <Container fluid style={{ marginTop: '1rem' }}>
            <Grid style={{ marginBottom: '1rem' }}>
                <Grid.Row columns={2} style={{ padding: 0 }}>
                    <Grid.Column style={{ padding: 0, textAlign: 'left' }}>
                        <Form style={{ paddingLeft: 0 }}>
                            <Form.Group style={{ margin: 0, paddingLeft: 0 }}>
                                <SuperField
                                    as="choice"
                                    label={t('evidence')}
                                    search
                                    width={5}
                                    value={selectedCategory}
                                    onChange={(e, { value }) => {
                                        setSelectedCategory(value)
                                        setSelectedScheme(null)
                                    }}
                                    customOptions={options}
                                />
                                <SuperField
                                    as="choice"
                                    label={t('template')}
                                    width={7}
                                    disabled={!selectedCategory}
                                    value={selectedScheme}
                                    onChange={(e, { value }) => {
                                        handleChangesSchemes(value)
                                    }}
                                    customOptions={schemeOptions}
                                />
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0, textAlign: 'right' }}>
                        <div style={{ paddingTop: '1.5rem' }}>
                            <Dropdown
                                simple
                                disabled={!selectedCategory || !selectedScheme}
                                style={{ background: 'var(--black)', color: 'var(--white)', marginRight: '0.5rem' }}
                                className="button icon"
                                floating
                                trigger={
                                    <span>
                                        {/* <Icon name="reorder-four" style={{ marginRight: "0.5rem" }}/> */}
                                        <span style={{ marginRight: '1rem', position: 'relative', top: '-0.1rem' }}>
                                            {t('imports_more_options')}
                                        </span>
                                    </span>
                                }
                            >
                                <Dropdown.Menu style={{ zIndex: 999 }}>
                                    <SuperDuperModal
                                        size="tiny"
                                        trigger={
                                            <Dropdown.Item disabled>
                                                <Icon name="book-outline" style={{ marginRight: '0.5rem' }} />
                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                    {t('show_columns_description')}
                                                </span>
                                            </Dropdown.Item>
                                        }
                                        content={<div>test</div>}
                                    />
                                    <Dropdown.Item onClick={() => handleDownload()}>
                                        <Icon name="download-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {' '}
                                            {t('download_table_as_xlsx')}{' '}
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={isProcessing}
                                        onClick={() => {
                                            setIsOpen(true)
                                            setIsFileUpload(true)
                                        }}
                                    >
                                        <Icon name="push-outline" style={{ marginRight: '0.5rem' }} />
                                        <span style={{ position: 'relative', top: '-0.1rem' }}>
                                            {' '}
                                            {t('import_your_file')}{' '}
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button disabled style={{ background: 'transparent', opacity: '0.8' }} content="|" />
                            <Button
                                disabled={!isTableFilled()}
                                style={{ background: 'var(--danger)', color: 'var(--white)' }}
                                size="small"
                                onClick={() => setIsCleared(true)}
                            >
                                <Icon name="trash-outline" style={{ marginRight: '0.5rem' }} />
                                <span style={{ position: 'relative', top: '-0.1rem' }}> {t('clear')} </span>
                            </Button>
                            <Button
                                primary
                                size="small"
                                disabled={!isTableFilled() || isProcessing}
                                onClick={() => handleImport()}
                            >
                                <Icon name="push-outline" style={{ marginRight: '0.5rem' }} />
                                <span style={{ position: 'relative', top: '-0.1rem' }}> {t('import')} </span>
                            </Button>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider style={{ marginTop: '0.5rem' }} />
            <Grid stretched style={{ paddingLeft: 0 }}>
                {selectedCategory && selectedScheme && selectedScheme.config && (
                    <ExcelLikeTable
                        selectedScheme={selectedScheme}
                        onChange={(items) => setItems(items)}
                        isCleared={isCleared}
                        setIsCleared={setIsCleared}
                        wasSchemeChanged={wasSchemeChanged}
                        setWasSchemeChanged={setWasSchemeChanged}
                        isProcessing={isProcessing}
                    />
                )}
            </Grid>
            {selectedCategory && (
                <>
                    <Modal
                        size={isFileUpload ? 'tiny' : 'small'}
                        open={isOpen}
                        centered={false}
                        closeIcon={!isProcessing}
                        closeOnDimmerClick={false}
                        closeOnDocumentClick={false}
                        closeOnEscape={false}
                        onClose={() => {
                            setResponse(null)
                            setFile(null)
                            setIsOpen(false)
                            setIsProcessing(false)
                        }}
                    >
                        <ModalContentView
                            isFileUpload={isFileUpload}
                            handleFileFormSubmit={handleFileFormSubmit}
                            setIsOpen={setIsOpen}
                            setIsFileUpload={setIsFileUpload}
                            isProcessing={isProcessing}
                            source={selectedScheme?.config?.source}
                            type={[capitalizeFirstLetter(selectedCategory)]}
                            newAdded={response?.response?.[capitalizeFirstLetter(selectedCategory)]?.totals?.new}
                            file={file}
                            setFile={setFile}
                            response={response}
                        />
                    </Modal>
                    <Modal
                        size={'tiny'}
                        open={isOpenValidation}
                        closeIcon
                        centered={false}
                        onClose={() => {
                            setIsOpenValidation(false)
                        }}
                    >
                        <ModalValidations error={validationError} />
                    </Modal>
                </>
            )}
        </Container>
    )
}

const ModalContentView = ({
    isFileUpload,
    handleFileFormSubmit,
    type,
    newAdded,
    setIsOpen,
    setIsFileUpload,
    isProcessing,
    source,
    file,
    setFile,
    response,
}) => {
    const { t } = useTranslation()

    const hasErrors = response?.status >= 400 || response?.response?.[type]?.invalid.length > 0

    const onClose = () => {
        setFile(null)
        setIsOpen(false)
        setIsFileUpload(false)
    }

    return (
        <Modal.Content>
            {isFileUpload ? (
                <Form onSubmit={handleFileFormSubmit}>
                    <SuperField
                        as="input"
                        type="file"
                        required
                        label={t('upload_file')}
                        id="fileInput"
                        // ref={(el) => console.log(el)}
                        onChange={(event) => {
                            if (event.target?.files?.length > 0) {
                                setFile(event.target.files[0])
                            }
                        }}
                    />

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel
                            onClose={() => {
                                setIsOpen(false)
                                setIsFileUpload(false)
                            }}
                        />
                        <ModalSubmit disabled={file === null} />
                    </Form.Field>
                </Form>
            ) : (
                <SpinnerSegment
                    loading={isProcessing}
                    loadingMessage={
                        <div style={{ fontSize: '1.5rem' }}>
                            {t('processing_import_this_may_take_a_while')} <br />
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {t('do_not_close_this_window_please')}
                            </p>
                        </div>
                    }
                >
                    {!isProcessing && (
                        <>
                            <Header as="h3" content={t('bulk_import_results')} />
                            <Divider />
                            {hasErrors ? (
                                <RenderErrors type={type} response={response?.response || null} />
                            ) : (
                                <AfterImportActions source={source} newAdded={newAdded} onClose={onClose} />
                            )}
                        </>
                    )}
                </SpinnerSegment>
            )}
        </Modal.Content>
    )
}

const RenderErrors = ({ type, response }) => {
    const { t } = useTranslation()
    const invalidRecords = response?.result?.invalid || []
    const invalidRecords2 = response?.[type]?.invalid || []

    return (
        <>
            {(response?.detail || response?.file) && (
                <Message visible error style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                    <Icon
                        name="warning-outline"
                        style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                    />
                    <span style={{ position: 'relative', top: '-0.2rem' }}>{response?.detail || response?.file}</span>
                </Message>
            )}

            {(invalidRecords?.length !== 0 || invalidRecords2?.length !== 0) && (
                <>
                    <Message visible info style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                        <Icon
                            name="information-circle-outline"
                            style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                        />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {t('some_records_contain_errors_see_list_down_bellow')}.
                        </span>
                    </Message>
                    <Divider />

                    {invalidRecords.map((error, idx) => (
                        <div key={idx}>
                            <strong>
                                {t('invalid_row')} {error.row}:
                            </strong>{' '}
                            <br />
                            <ul>
                                {error.errors.map((item, idx) => (
                                    <>
                                        {typeof item === 'object' ? (
                                            Array.isArray(item) ? (
                                                item?.map((message, index) => (
                                                    <li key={index}>
                                                        {' '}
                                                        {message} <br />
                                                    </li>
                                                ))
                                            ) : item !== undefined && item !== null ? (
                                                Object.keys(item)?.map((key) => (
                                                    <>
                                                        <strong> {key}: </strong> <br />
                                                        <ul>
                                                            {item[key].map((message) => (
                                                                <li> {message} </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                ))
                                            ) : null
                                        ) : (
                                            <li key={idx}>
                                                {' '}
                                                {item} <br />
                                            </li>
                                        )}
                                    </>
                                ))}
                            </ul>
                        </div>
                    ))}

                    {invalidRecords2.map((error, idx) => (
                        <div key={idx}>
                            <strong>
                                {t('invalid_row')} {error.row}:
                            </strong>{' '}
                            <br />
                            <ul>
                                {error.errors.map((item, idx) => (
                                    <>
                                        {typeof item === 'object' ? (
                                            Array.isArray(item) ? (
                                                item?.map((message, index) => (
                                                    <li key={index}>
                                                        {' '}
                                                        {message} <br />
                                                    </li>
                                                ))
                                            ) : item !== undefined && item !== null ? (
                                                Object.keys(item)?.map((key) => (
                                                    <>
                                                        <strong> {key}: </strong> <br />
                                                        <ul>
                                                            {item[key].map((message) => (
                                                                <li> {message} </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                ))
                                            ) : null
                                        ) : (
                                            <li key={idx}>
                                                {' '}
                                                {item} <br />
                                            </li>
                                        )}
                                    </>
                                ))}
                            </ul>
                        </div>
                    ))}
                </>
            )}
        </>
    )
}

const AfterImportActions = ({ source, newAdded, onClose }) => {
    const { t } = useTranslation()
    let history = useHistory()

    const [redirectToEvidence, setRedirectToEvidence] = useState(true)

    const handleSubmit = async () => {
        if (redirectToEvidence) {
            history.push(routes[source])
        }
    }

    return (
        <>
            <Message visible success style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                <Icon
                    name="checkmark-circle-outline"
                    style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>
                    {t('import_was_successful_total_newly_added_records')}: {newAdded || 0}
                </span>
            </Message>
            <Divider />
            <Header as="h3" content={t('select_action_to_peform')} />

            <Form onSubmit={handleSubmit}>
                <SuperField
                    as="radio"
                    label={t('redirect_to_evidence')}
                    checked={redirectToEvidence}
                    onChange={() => setRedirectToEvidence(true)}
                />

                <Divider />
                <Form.Field style={{ textAlign: 'right' }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit />
                </Form.Field>
            </Form>
        </>
    )
}

const ModalValidations = ({ error }) => {
    const { t } = useTranslation()

    return (
        <Modal.Content>
            <SpinnerSegment>
                <Message visible error style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                    <Icon
                        name="close-circle-outline"
                        style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                    />
                    <span style={{ position: 'relative', top: '-0.2rem' }}>
                        {(error === 'required' && t('required_cells_are_empty')) ||
                            (error === 'number' && t('incorrect_input'))}
                    </span>
                </Message>
            </SpinnerSegment>
        </Modal.Content>
    )
}

export default Imports
