import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Header, Divider, Button, Form, Table, Grid } from 'semantic-ui-react';


const PositionItemRow = ({ record, posItem, setData, readOnlyProp, view, setAllowAdd }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [items, setItems] = useState([])
    const [readOnly, setReadOnly] = useState(readOnlyProp === undefined ? true : readOnlyProp)
    const [form, setForm] = useState({
        item: posItem?.item.id || "",
        amount: posItem?.amount || ""
    })

    const toExclude = record.items.map(item => item.item.id)

    useEffect(() => {
        async function fetchItems() {
            let options = []
            const request = await requests.get(API.ASSET_ITEMS + "?query={id, title, is_safety_equipment}")
            if (request.status === 200) {
                options = request.response.map(item => ({ key: item.id, value: item.id, text: item.title, is_safety_equipment: item.is_safety_equipment }))
            }

            setItems(options)
        }

        fetchItems()
    }, [])

    const handleSave = async () => {
        setProcessing(true)

        let result = 400;
        if (posItem !== undefined) {
            const request = await requests.patch(API.ASSETS + "position_config/" + posItem.id + "/", {
                amount: form.amount,
                item: form.item
            })

            if (request.status === 200) result = 200
        } else {
            const request = await requests.post(API.ASSETS + "position_config/", {
                position: record.id,
                amount: form.amount,
                item: form.item
            })
            if (request.status === 201) result = 201
        }

        if ([200, 201].includes(result)) {
            const fetchPos = await requests.get(API.JOB_POSITIONS + record.id + "/")
            if (fetchPos.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record.id) {
                        item = fetchPos.response
                    }
                    return item
                }))
            }
        }

        setProcessing(false)
        setReadOnly(true)
    }

    const handleDelete = async (id) => {
        const request = await requests.del(API.ASSETS + "position_config/" + id + "/")
        if (request.status === 204) {
            // fetch position
            const fetchPos = await requests.get(API.JOB_POSITIONS + record.id + "/")
            if (fetchPos.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === record.id) {
                        item = fetchPos.response
                    }
                    return item
                }))
            }
        }
    }

    return (
        <Table.Row verticalAlignment="middle">
            <Table.Cell>
                { readOnly 
                    ? posItem.item.title
                    : 
                    <SuperField as="choice"
                        search
                        value={form.item}
                        customOptions={view === 1 
                            ? items.filter(item => item.is_safety_equipment === true).filter(item => !toExclude.filter(item => item !== form.item).includes(item.value)) 
                            : items.filter(item => item.is_safety_equipment === false).filter(item => !toExclude.filter(item => item !== form.item).includes(item.value))
                        }
                        onChange={(e, { value }) => setForm(prev => ({...prev, item: value}))}
                    />
                }
            </Table.Cell>
            <Table.Cell width="4">
                { readOnly 
                    ? posItem.amount
                    : 
                    <SuperField as="input"
                        value={form.amount}
                        onChange={(e, { value }) => setForm(prev => ({...prev, amount: value}))}
                    />
                }
            </Table.Cell>
            <CanView permissions={['assets.c_manage_items']}>
                <Table.Cell width="5">
                    <FlexRow padding="0" background="transparent">
                        { readOnly 
                            ? 
                            <>
                                <FlexItem>
                                    <Action
                                        as="custom"
                                        size="huge"
                                        icon="pencil-outline"
                                        iconColor="var(--dark)"
                                        onClick={() => setReadOnly(false)}
                                    />
                                </FlexItem>
                                <FlexItem>
                                    <Action
                                        as="delete"
                                        size="huge"
                                        text={t('are_you_sure')}
                                        onClick={() => handleDelete(posItem.id)}
                                    />
                                </FlexItem>
                            </>
                            : 
                            <FlexItem>
                                <Button.Group size="small" fluid>
                                    <Button
                                        primary
                                        size="small"
                                        type="button"
                                        content={t('save')}
                                        loading={processing}
                                        disabled={processing || form.item === "" || form.amount === ""}
                                        onClick={() => handleSave()}
                                    />
                                    { posItem === undefined && 
                                        <Button
                                            type="button"
                                            content={t('cancel')}
                                            onClick={() => setAllowAdd(false)}
                                        />
                                    }
                                </Button.Group>
                            </FlexItem>
                        }
                    </FlexRow>
                </Table.Cell>
            </CanView>
        </Table.Row>
    )
}


const PositionItemsConfig = ({ record, setData }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(1) // 1 - safety items, 2 - others (non-safety)
    const [allowAdd, setAllowAdd] = useState(false)

    const items = record.items

    return (
        <>
            <Header as="h3" content={t('linked_items')}/>
            <Divider/>

            <Grid stackable>
                <Grid.Row verticalAlign="middle" columns="2" style={{ padding: 0 }}>
                    <Grid.Column style={{ paddingLeft: 0 }}>
                        <Button.Group basic size="small" style={{ borderRadius: 0 }}>
                            <Button active={view === 1} style={{ fontWeight: view === 1 ? 'bold' : 'normal' }} content={t('safety_items')} onClick={() => setView(1)}/>
                            <Button active={view === 2} style={{ fontWeight: view === 2 ? 'bold' : 'normal' }} content={t('others')} onClick={() => setView(2)}/>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <CanView permissions={['assets.c_manage_items']}>
                            <Icon
                                name="add-outline"
                                style={{ 
                                    cursor: "pointer",
                                    fontSize: "1.5rem",
                                    paddingTop: "0.25rem"
                                }}
                                onClick={() => setAllowAdd(true)}
                            />
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Form style={{ marginTop: "1rem" }} onSubmit={(e) => e.preventDefault()}>

                { view === 1 && 
                    <>
                        <Table>
                            <Table.Header>
                                <Table.Row verticalAlign="middle">
                                    <Table.HeaderCell>{t('item')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('amount')}</Table.HeaderCell>
                                    <CanView permissions={['assets.c_manage_items']}>
                                        <Table.HeaderCell>{t('actions')}</Table.HeaderCell>
                                    </CanView>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { allowAdd &&
                                    <PositionItemRow
                                        record={record}
                                        setData={setData}
                                        readOnlyProp={false}
                                        view={view}
                                        setAllowAdd={setAllowAdd}
                                    />
                                }
                                { items.filter(posItem => posItem.item.is_safety_equipment === true).map(config => (
                                    <PositionItemRow
                                        key={config.id}
                                        record={record}
                                        posItem={config}
                                        setData={setData}
                                        view={view}
                                    />
                                ))}
                            </Table.Body>
                        </Table>
                        <EmptyRow length={items.filter(posItem => posItem.item.is_safety_equipment === true).length}/>
                    </>
                }

                { view === 2 && 
                    <>
                        <Table>
                            <Table.Header>
                                <Table.Row verticalAlign="middle">
                                    <Table.HeaderCell>{t('item')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('amount')}</Table.HeaderCell>
                                    <CanView permissions={['assets.c_manage_items']}>
                                        <Table.HeaderCell>{t('actions')}</Table.HeaderCell>
                                    </CanView>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { allowAdd &&
                                    <PositionItemRow
                                        record={record}
                                        setData={setData}
                                        readOnlyProp={false}
                                        view={view}
                                        setAllowAdd={setAllowAdd}
                                    />
                                }
                                { items.filter(posItem => posItem.item.is_safety_equipment === false).map(config => (
                                    <PositionItemRow
                                        key={config.id}
                                        record={record}
                                        posItem={config}
                                        setData={setData}
                                        view={view}
                                    />
                                )) }
                            </Table.Body>
                        </Table>
                        <EmptyRow length={items.filter(posItem => posItem.item.is_safety_equipment === false).length}/>
                    </>
                }
            </Form>
        </>
    );
};

export default PositionItemsConfig;