import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
//store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
//components
import { Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

export const PermissionsForm = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)

    const [form, setForm] = useState({
        allow_in_attendance_system: false,
        allow_in_warehousing_system: false,
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const dataset = {
            allow_in_attendance_system: form.allow_in_attendance_system,
            allow_in_warehousing_system: form.allow_in_warehousing_system,
        }

        for (let i = 0; i < selected.length; i++) {
            const request = await requests.patch(API.ACCESS_CARD + selected[i].id + "/", dataset)
            if (request.status === 200) {
                setData(prev => prev.filter(item => {
                    if (item.id === selected[i].id){
                        item.allow_in_attendance_system = request.response.allow_in_attendance_system
                        item.allow_in_warehousing_system = request.response.allow_in_warehousing_system
                    }
                    return item
                }))
            }
        }
        setSelected([])
        onClose()
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="checkbox"
                label={t('allow_in_attendance_system')}
                checked={form.allow_in_attendance_system}
                onChange={(e) => setForm({...form, allow_in_attendance_system: !form.allow_in_attendance_system })}
            />
            <SuperField as="checkbox"
                label={t('allow_in_warehousing_system')}
                checked={form.allow_in_warehousing_system}
                onChange={(e) => setForm({...form, allow_in_warehousing_system: !form.allow_in_warehousing_system })}
            />
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    text={t("confirm")} 
                    disabled={isProcessing}
                    loading={isProcessing} 
                />
            </Form.Field>
        </Form>
    )
}
        
        
export default PermissionsForm;