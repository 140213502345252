import React from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { renderAddress } from '@helpers/functions';
// components
import ListView from '@components/ListView';
import ContactDetails from '@components/ContactDetails';
import BranchesForm from './forms/BranchesForm';

const Branches = ({ account }) => {
    const { t } = useTranslation()

    const onCreate = (response, setData, setTotal) => {
        setData((prev) => [response, ...prev])
        setTotal((prev) => prev + 1)
    }
    const onUpdate = async (response, setData) => {
        setData((prev) =>
            prev.map((branche) => {
                if (branche.id === response.id) {
                    branche = response
                }
                return branche;
            })
        )
    };

    return (
        <ListView
            as="table"
            allowSearch
            endpoint={API.BRANCHES}
            query={"&business_detail=" + account}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    name: t('create_branch'),
                    modal: <BranchesForm 
                        account={account} 
                        onConfirm={(response, setData, setTotal) => onCreate(response, setData, setTotal)} 
                    />,
                },
                {
                    as: "modal",
                    type: "edit",
                    name: t('edit'),
                    modalSize: "small",
                    modal: (item, setData) => <BranchesForm
                        data={item}
                        account={account} 
                        setData={setData}
                        onConfirm={(response, setData) => onUpdate(response, setData)}
                    />
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('delete'),
                },
            ]}
            tableHeaders={[
                { title: t('title'), orderBy: "title" },
                { title: t('contact')},
                { title: t('address')},
                { title: t('note')},
            ]}
            renderCells={(account) => (
                [
                    { content: account?.title},
                    { content: 
                        <>
                            { account?.contact_person_name && <strong>{account?.contact_person_name}</strong>}
                            <ContactDetails
                                phone={account?.phone}
                                email={account?.email}
                            />
                        </>
                    },
                    { content: account?.address ? renderAddress(account.address) : null },
                    { content: account?.note }
            ])}
        />
    );
};

export default Branches;