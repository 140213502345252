import React, { useState, Fragment } from 'react';
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from '@helpers/validation';
import { projectsService } from '@services/ServiceProjects';
// components
import { Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ColorPickerField from '@components/forms/settings/ColorPickerField';

const LabelsForm = (props) => {
    const { t } = useTranslation();

    const [labelItem] = useState(props.labelItem ? props.labelItem : '')
    const [id] = useState(labelItem ? labelItem.id : 0)
    const [name, setName] = useState(labelItem ? labelItem.name : '')
    const [color, setColor] = useState(labelItem ? labelItem.color : '')

    const [errors, setErrors] = useState([]);

    // Form states
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async () => {
        setIsProcessing(true)
        let errorsArray = [];

        const data = {
            name: name,
            color: color
        }

        if (!id || id === 0) {
            const result = await projectsService.createLabel(data)

            if (result.status === 201) {
                props.onConfirm(result.response)
                props.onClose()
            }
            else {
                if (result.response.non_field_errors) {
                    errorsArray.push(result.response.non_field_errors)
                }
                if (result.response.color) {
                    errorsArray.push(result.response.color)
                }
                if (result.response.name) {
                    errorsArray.push(result.response.name)
                }

                setErrors(errorsArray);
            }
        } else {
            const result = await projectsService.updateLabel(data, labelItem.id)

            if (result.status === 200) {
                props.onConfirm(result.response)
                props.onClose()
            }
            else {
                if (result.response.non_field_errors) {
                    errorsArray.push(result.response.non_field_errors)
                }
                if (result.response.color) {
                    errorsArray.push(result.response.color)
                }
                if (result.response.name) {
                    errorsArray.push(result.response.name)
                }

                setErrors(errorsArray);
            }
        }

        setIsProcessing(false)
    }

    const ErrorMessages = () => {
        return (
            <Fragment>
                {errors.length > 0 ?
                    <Message negative>
                        {   // this is like forloop cycle we are looping over error array to print errors
                            errors.map((error, index) =>
                                <p key={index}>
                                    {error}
                                </p>
                            )
                        }
                    </Message>
                    : ''}
            </Fragment>
        );
    }

    return (
        <Form onSubmit={handleSubmit}>

            <ErrorMessages />

            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        autoFocus
                        required
                        label={t('name')}
                        placeholder={t('enter_name')}
                        value={name}
                        onChange={(e) =>
                            setName(e.target.value)
                        }
                    />
                </Form.Field>

                <ColorPickerField
                    label={t('color')}
                    color={color}
                    setColor={setColor}
                />
            </Form.Group>

            <Form.Field floated="right">
                <ModalCancel
                    disabled={isProcessing}
                    onClose={props.onClose}
                />
                <ModalSubmit
                    text={t('save')}
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(name)}
                />
            </Form.Field>
        </Form>
    );
};

export default LabelsForm;