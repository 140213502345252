import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from "@store/config";
import { requests } from "@helpers/requests";
// components
import Icon from '@components/Icon';
import { Container, Card } from 'semantic-ui-react';
import SpinnerSegment from '@components/SpinnerSegment';

const ServiceOrderFulfillmentsStats = ({ filters }) => {
    const { t } = useTranslation()

    // eslint-disable-next-line
    const [loading, setLoading] = useState(true)
    const [report, setReport] = useState(null)

    useEffect(() => {
        async function fetchStats() {
            setLoading(true)
            let params = "?empty="

            const filterArray = Object.keys(filters)
            for (let i = 0; i < filterArray.length; i++) {
                if (filters?.[filterArray[i]] !== "" || (Array.isArray(filters?.[filterArray[i]]) && filters?.[filterArray[i]].length !== 0)) {
                    if (!params.includes(filterArray[i]) && filterArray[i] !== "custom_filter" && filterArray[i] !== "resource_type" && filterArray[i] !== "ordering") {
                        if (Array.isArray(filters?.[filterArray[i]]) === false) {
                            params += `&${filterArray[i]}=${filters[filterArray[i]]}`
                        } else {
                            let arr = filters?.[filterArray[i]] || []
                            for (let y = 0; y < arr.length; y++) {
                                params += `&${filterArray[i]}=${arr[y]}`
                            }
                        }
                    }
                }
            }

            const request = await requests.get(`${API.REPORTS}order/service_fulfillment_stats/${params}`)
            if (request.status === 200) {
                setReport(request.response)
            }

            setLoading(false)
        }

        fetchStats()
        // eslint-disable-next-line
    }, [filters])

    return (
        <SpinnerSegment loading={loading} loadingMessage={t('loading_statistics')}>
            <Container fluid>
                <Card.Group stackable doubling itemsPerRow={4}>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="list-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_fulfillments || 0 }
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total") }
                            </div>
                        </Card.Content>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="people-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_workers || 0 }
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total_workers") }
                            </div>
                        </Card.Content>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="hourglass-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_hours || 0 }
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total_hours") }
                            </div>
                        </Card.Content>
                    </Card>
                    <Card style={{ borderRadius: 0 }}>
                        <Card.Content>
                            <div style={{ display: "flex", marginBottom: "2rem" }}>
                                <div style={{ textAlign: "left", width: "30%" }}>
                                    <Icon name="ribbon-outline" style={{ fontSize: "3rem", fontWeight: "bold", textAlign: "left" }}/>
                                </div>
                                <div style={{ textAlign: "right", fontWeight: "bold", width: "70%", fontSize: "1.25rem" }}>
                                    { report?.total_fee || 0 }
                                </div>
                            </div>

                            <div style={{ position: "absolute", bottom: "1rem", marginTop: "1rem", textTransform: "uppercase", color: "var(--dark)", opacity:"0.8" }}>
                                { t("total_fee") }
                            </div>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </Container>
        </SpinnerSegment>
    );
};

export default ServiceOrderFulfillmentsStats;