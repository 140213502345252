import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
import moment from 'moment'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Button, Popup, Icon as SemanticIcon } from 'semantic-ui-react'
import CanView from '@components/perms/CanView'
import ConfirmModal from '@components/modals/ConfrimModal'
import InvoiceReferenceIndicator from './InvoiceReferenceIndicator'
// import DeliveryDateForm from './DeliveryDateForm'
// import SuperDuperModal from '@components/modals/SuperDuperModal'

const OrderState = ({ order, setData, setTotal, filters, type }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector((store) => store.date_format)
    const [action, setAction] = useState(null)
    const [open, setOpen] = useState(null)

    const order_status_choices = useSelector((state) => state.choices.order_status_choices)
    const statuses = Object.entries(order_status_choices).map((choice, idx) => ({
        key: idx,
        text: choice[1],
        value: choice[0],
    }))

    const onOrderConfirm = async (id, setData) => {
        const request = await requests.put(API.ORDERS + id + '/confirm/')
        if (request.status === 200) {
            setData((prev) =>
                prev.filter((item) => {
                    if (item.id === id) {
                        item.confirmed_on = request.response.confirmed_on
                        item.confirmed_by = request.response.confirmed_by
                    }

                    return item
                })
            )
        }
    }

    const onStatusChange = async (status, order) => {
        const request = await requests.patch(API.ORDERS + order.id + '/', {
            order_status: status,
            resourcetype: type,
        })
        if (request.status === 200) {
            if (filters?.order_status?.includes(status) || filters.order_status === '') {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === order.id) {
                            item.order_status = request.response.order_status
                            item.order_status_display = request.response.order_status_display
                        }

                        return item
                    })
                )
            } else {
                setData((prev) => prev.filter((item) => item.id !== order.id))
                setTotal((prev) => prev - 1)
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('order_state_changed'),
                animation: 'pulse',
                time: 2000,
            })
        }
    }

    const displayOrderStatus = (status) => {
        let iconColor = 'var(--dark)'
        const value = status ? status.key + 1 : order.order_status
        const text = status ? status.text : order.order_status_display

        if (value === 1) iconColor = 'var(--primary)'
        if (value === 2) iconColor = 'var(--info)'
        if (value === 3) iconColor = 'var(--blue)'
        if (value === 4) iconColor = 'var(--success)'
        if (value === 5) iconColor = 'var(--warning)'
        if (value === 6) iconColor = 'var(--danger)'

        return (
            <span>
                <SemanticIcon
                    size="small"
                    name="circle"
                    style={{ color: iconColor, position: 'relative', top: '-0.1rem' }}
                />
                {text}
            </span>
        )
    }

    return (
        <>
            {order.confirmed_on === null ? (
                <>
                    <SemanticIcon
                        size="small"
                        name="circle"
                        style={{ color: 'var(--dark)', position: 'relative', top: '-0.1rem' }}
                    />{' '}
                    {t('not_confirmed')} <br />
                    {order.resourcetype === 'TransportOrder' && (
                        <InvoiceReferenceIndicator fulfillment={order.fulfillment} />
                    )}
                    <CanView permissions={['orders.c_confirm_order']}>
                        <ConfirmModal
                            description={t('confirm_message')}
                            onConfirm={() => onOrderConfirm(order.id, setData)}
                            button={
                                <small style={{ color: 'var(--primary)' }} className="ref-link">
                                    {' '}
                                    {t('add_confirmation')}{' '}
                                </small>
                            }
                        />
                    </CanView>
                </>
            ) : (
                <>
                    <Popup
                        basic
                        hoverable
                        position="bottom center"
                        style={{ padding: 0 }}
                        open={open === order?.id}
                        onOpen={() => setOpen(order?.id)}
                        onClose={() => {
                            if (open === order?.id && action === 'edit_status') {
                                setOpen(order?.id)
                            } else {
                                setOpen(null)
                                setAction(null)
                            }
                        }}
                        trigger={
                            <span>
                                <strong style={{ cursor: 'pointer' }}>
                                    {displayOrderStatus()}
                                    <Icon
                                        name="chevron-down"
                                        style={{
                                            marginLeft: '0.4rem',
                                            position: 'relative',
                                            top: '0.2rem',
                                        }}
                                    />
                                </strong>
                            </span>
                        }
                        content={
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {statuses.map(
                                    (item) => (
                                        // (item.value === '4' && order.resourcetype === "TransportOrder") ? (
                                        //     <SuperDuperModal
                                        //         key={item.key}
                                        //         size="mini"
                                        //         header={t('add_delivery_date')}
                                        //         trigger={
                                        //             <Button
                                        //                 basic
                                        //                 size="tiny"
                                        //                 type="button"
                                        //                 onClick={() => setAction('edit_status')}
                                        //                 style={{
                                        //                     marginRight: 0,
                                        //                     textAlign: 'left',
                                        //                 }}
                                        //                 active={
                                        //                     parseInt(item.value) ===
                                        //                     parseInt(order.order_status)
                                        //                 }
                                        //                 content={
                                        //                     <strong>
                                        //                         { displayOrderStatus(item) }
                                        //                     </strong>
                                        //                 }
                                        //             />
                                        //         }
                                        //         content={
                                        //             <DeliveryDateForm
                                        //                 setAction={setAction}
                                        //                 order={order}
                                        //                 setData={setData}
                                        //                 setTotal={setTotal}
                                        //                 filters={filters}
                                        //             />
                                        //         }
                                        //     />
                                        // ) : (
                                        <Button
                                            basic
                                            size="tiny"
                                            type="button"
                                            style={{ marginRight: 0 }}
                                            active={parseInt(item.value) === parseInt(order.order_status)}
                                            key={item.key}
                                            content={<strong>{displayOrderStatus(item)}</strong>}
                                            onClick={() => onStatusChange(item.key + 1, order)}
                                        />
                                    )
                                    // )
                                )}
                            </div>
                        }
                    />
                    <br />
                    {order.resourcetype === 'TransportOrder' && (
                        <InvoiceReferenceIndicator fulfillment={order.fulfillment} />
                    )}
                    <small>
                        {' '}
                        {t('confirmed_on')} {moment(order.confirmed_on).format(dateFormat)}{' '}
                    </small>
                </>
            )}
        </>
    )
}

export default OrderState
