import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { icons } from '@store/icons'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import BreadcrumbNav from '@components/BreadcrumbNav'
import { Container, Button, Divider } from 'semantic-ui-react'
// views
import EmployeesList from './views/EmployeesList'
import IdentityDocuments from './views/IdentityDocuments'
import AccessCards from './views/AccessCards'

const EmployeeGUI = () => {
    const { t } = useTranslation()
    const [view, setView] = useState(1) // employees

    return (
        <CanView
            permissions={[
                'common.c_view_all_employees',
                'common.c_view_user_employees',
                'company.c_view_only_assigned_unit_employees',
                'common.c_view_only_agency_employees',
                'common.c_view_only_internal_employees',
                'common.c_view_only_external_employees',
            ]}
            redirect
        >
            <BreadcrumbNav items={[{ name: t('employees'), icon: icons.EMPLOYEES, href: '' }]} />
            <Container fluid>
                <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                    <Button active={view === 1} onClick={() => setView(1)}>
                        <Icon name={'people-outline'} style={{ marginRight: '0.5rem' }} />
                        <span style={{ position: 'relative', top: '-0.1rem' }}>{t('employees')}</span>
                    </Button>
                    <CanView permissions={['common.c_view_all_employees']}>
                        <Button active={view === 2} onClick={() => setView(2)}>
                            <Icon name={'id-card-outline'} style={{ marginRight: '0.5rem' }} />
                            <span style={{ position: 'relative', top: '-0.1rem' }}>{t('identity_documents')}</span>
                        </Button>
                    </CanView>
                    <CanView permissions={['employees.c_view_access_cards', 'employees.c_view_user_access_cards']}>
                        <Button active={view === 3} onClick={() => setView(3)}>
                            <Icon name={'card-outline'} style={{ marginRight: '0.5rem' }} />
                            <span style={{ position: 'relative', top: '-0.1rem' }}>{t('access_cards')}</span>
                        </Button>
                    </CanView>
                </Button.Group>
                <Divider style={{ marginBottom: 0 }} />
            </Container>

            {view === 1 && <EmployeesList />}
            {view === 2 && <IdentityDocuments />}
            {view === 3 && <AccessCards />}
        </CanView>
    )
}

export default EmployeeGUI
