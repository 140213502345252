import React, { useState} from 'react'
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import {  getRequest } from '@services/ServiceCommon';
// components
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import { Container, Button, Header, Form, Divider } from 'semantic-ui-react';
import AvatarWithName from '@components/general/AvatarWithName';
import SuperField from '@components/forms/SuperField'
import Loading from '@components/general/Loading';
import moment from 'moment';

const CandidatesInteractions = () => {
    const { t } = useTranslation();
    const [result, setResult] = useState([])
    const [candidate, setCandidate] = useState("")
    const [recruiter, setRecruiter] = useState("")
    const [recruitment, setRecruitment] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [loading, setLoading] = useState(false)


    const fetchInteractions = async (params) => {
        const request = await getRequest(API.CANDIDATES_INTERACTIONS + params)
        if(request !== undefined ){
            if( request.status === 200 ){
                setResult({
                    ...result,
                    data: request.response
                })
            }
        }
    }

    function difference(start, end) {
        let startTime = moment(start);
        let endTime =  moment(end);
        let diff = null;
        diff = endTime.diff(startTime, 'days')
        return diff
    }

    async function filters() {
        setLoading(true)
        let params = "?start_time="+startDate

        if( candidate !== ""){
            params += "&id="+candidate
        }
        if( recruiter !== ""){
            params += "&recruiters="+recruiter
        }
        if( recruitment !== ""){
            params += "&recruitment="+recruitment
        }
        if( endDate !== ""){
            params += "&end_time="+endDate
        }

        await fetchInteractions(params)
        setLoading(false)
    }

    return (
        <>
            { result.isLoading ? <Loading/> :
                <Container fluid>
                    <Form padding="0.5rem">
                        <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                size="small"
                                dateFormat="YYYY-MM-DD"
                                label={t('start_date')+": "}
                                placeholder={t('start_date')}
                                value={startDate}
                                iconPosition="left"
                                onChange={(e, data) => setStartDate(data.value)}
                            />
                            <SuperField as="datepicker"
                                size="small"
                                dateFormat="YYYY-MM-DD"
                                label={t('end_date')+": "}
                                placeholder={t('end_date')}
                                value={endDate}
                                iconPosition="left"
                                onChange={(e, data) => setEndDate(data.value)}
                            />
                            <SuperField as="choice"
                                size="small"
                                label={t('candidate')+": "}
                                endpoint={API.CANDIDATES + "?query={id, fullname}"}
                                text={"fullname"}
                                search
                                value={candidate}
                                onChange={(e, data) => setCandidate(data.value)}
                            />
                            <SuperField as="choice"
                                size="small"
                                label={t('recruiter')+": "}
                                endpoint={API.EMPLOYEES + "?query={id, fullname}&is_recruiter=true"}
                                text={"fullname"}
                                search
                                value={recruiter}
                                onChange={(e, data) => setRecruiter(data.value)}
                            />
                            <SuperField as="choice"
                                size="small"
                                label={t('recruitment_')+": "}
                                endpoint={API.HIRING_ROOM + "?query={id, name}"}
                                text={"name"}
                                search
                                value={recruitment}
                                onChange={(e, data) => setRecruitment(data.value)}
                            />
                            <Form.Field style={{ marginTop: "1.5rem" }}>
                                <Button loading={loading} disabled={loading} primary content={t('generate_report')}  size="large" onClick={() => filters()}/>
                            </Form.Field>
                        </Form.Group>
                        <Divider/>
                    </Form>
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader content="#" basis="45%"/>
                            <FlexHeader content={t('candidate')} basis="200%"/>
                            <FlexHeader content={t('recruiter')}/>
                            <FlexHeader content={t('recruitment_')}/>
                            <FlexHeader content={t('start_time')}/>
                            <FlexHeader content={t('end_time')}/>
                            <FlexHeader content={t('difference')}/>
                        </FlexRow>

                        { result?.data?.map( (candidate, index) => (
                            <FlexRow key={candidate.id} fontSize="1rem" borders >
                                <FlexItem bold  content={ index + 1 + "." } basis="45%"/>
                                <FlexItem bold basis="200%">
                                    <AvatarWithName size={20} name={candidate?.profile?.fullname} avatar={candidate?.profile?.profile_picture}/>
                                </FlexItem>


                                <FlexItem bold>
                                    { candidate?.recruiters?.length > 0 &&
                                        candidate?.recruiters.map( (recruiter, index) => (
                                            <div>{recruiter.fullname}</div>
                                            ))
                                    }
                                </FlexItem>
                                <FlexItem bold basis="120%" >
                                    { candidate?.recruitment?.length > 0 &&
                                        candidate?.recruitment.map( (recruitment, index) => (
                                            <div>{recruitment.name}</div>
                                            ))
                                    }
                                </FlexItem>
                                <FlexItem bold >
                                    {candidate?.candidate_interaction_start_time!== null ?
                                    moment(candidate?.candidate_interaction_start_time).format('MMMM Do YYYY, h:mm:ss a')
                                    :
                                    <span>-</span>
                                    }
                                </FlexItem>
                                <FlexItem bold >
                                    {candidate?.candidate_interaction_end_time!== null ?
                                    moment(candidate?.candidate_interaction_end_time).format('MMMM Do YYYY, h:mm:ss a')
                                    :
                                    <span>-</span>
                                    }
                                </FlexItem>
                                <FlexItem bold >
                                    <span>
                                        {candidate?.candidate_interaction_start_time!== null
                                        && candidate?.candidate_interaction_end_time!== null ?
                                        difference(candidate.candidate_interaction_start_time,candidate.candidate_interaction_end_time)
                                        :
                                        <span>-</span>
                                        }
                                    </span>

                                </FlexItem>
                            </FlexRow>
                        ))}
                        {result?.data?.length===0 &&
                            <FlexRow>
                                <FlexItem textAlign="center">
                                    <Header as="h2" content={t('no_results')}/>
                                </FlexItem>
                            </FlexRow>
                        }
                    </FlexTable>
                </Container>
            }
        </>
    );
};

export default CandidatesInteractions