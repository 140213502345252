import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { candidatesService } from '@services/ServiceCandidates';
import { isEmpty, isPhoneNumberValid, isEmailValid } from '@helpers/validation';
// components
import { Form, Message, Button, Divider, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';


const NewCandidateForm = ({ roomId, onClose, onConfirm, setData, setTotal }) => {
    const { t } = useTranslation();

    const [view, setView] = useState(1)
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorList, setErrorList] = useState([])
    const [selectedCandidates, setSelectedCandidates] = useState({})

    const [candidate, setCandidate] = useState({
        firstname: "",
        lastname: "",
        gender: 0,
        email: "",
        phone: "",
        date_of_birth: "",
    });

    const [errors, setErrors] = useState({
        date_of_birth_error: null,
        personal_number_error: null
    })

    const isContactInfoValid = (phone, email) => {
        let isValid = false
        if( phone !== "" && email === "" ) {
            if( isPhoneNumberValid(phone) ){
                isValid = true
            }
        } else if( email !== "" && phone === "" ) {
            if( isEmailValid(email) ){
                isValid = true
            }
        } else if( email !== "" && phone !== "" ){
            if( isPhoneNumberValid(phone) && isEmailValid(email) ){
                isValid = true
            }
        }

        return isValid
    }

    const isFilledRequiredFields = () => {
        let isValid = true

        if( view === 1 && selectedCandidates.length > 0 ) return true

        // validate if business info are filled property
        if( isEmpty(candidate.firstname) || isEmpty(candidate.lastname) ){
            return false
        }

        // validate if contact information are filled properly
        if( !isContactInfoValid(candidate.phone, candidate.email)){
            return false
        }

        return isValid
    }

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrorList([]);

        if (view === 2 && selectedCandidates.length === 0) {
            let data = {
                firstname: candidate.firstname,
                lastname: candidate.lastname,
                gender: candidate.gender ? candidate.gender : 3,
                date_of_birth: candidate.date_of_birth || null,
            }

            if (!isEmpty(candidate.phone) || !isEmpty(candidate.email)) {
                data['contacts'] = {
                    create: [{ is_default: true, phone: candidate.phone || "", email: candidate.email || "" }]
                }
            }

            const request = await requests.post(API.CANDIDATES, data)

            if( request.status === 201 ){
                const assignRequest = await candidatesService.assignCandidate(request.response.id, roomId);

                if (assignRequest.status === 200) {
                    onConfirm(assignRequest.response, setData, setTotal)
                    onClose()
                } else {
                    const error_message = assignRequest.response.detail;
                    setErrorList([...errorList, error_message]);
                }
            }

            if( request.status === 400 ){
                if( request.response?.date_of_birth ){
                    setErrors(prevState => ({ ...prevState, date_of_birth_error: request.response?.date_of_birth[0] }))
                }

                if( request.response?.personal_number ){
                    setErrors(prevState => ({ ...prevState, personal_number_error: request.response?.personal_number[0] }))
                }
            }
        } else {
            const assignRequest = await requests.post(API.CANDIDATES + "recruitment/" + roomId + "/bulk_assign_to_recruitment/", {
                profiles: selectedCandidates
            })

            if ([200, 201].includes(assignRequest.status)) {
                onConfirm(assignRequest.response, setData, setTotal)
                onClose()
            } else {
                const error_message = assignRequest.response.detail;
                setErrorList([...errorList, error_message]);
            }
        }
        setIsProcessing(false);
    }

    function setCreateCandidate() {
        setView(2)
        setSelectedCandidates([])
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errorList.length > 0 &&
                (<Message negative>
                    {errorList.map((error, index) =>
                        <p key={index}> {error} </p>
                    )}
                </Message>)
            }

            <Button.Group fluid basic size='small'>
                <Button type='button' active={view === 1} onClick={() => setView(1)}>{t('add_candidate')}</Button>
                <Button type='button' active={view === 2} onClick={() => setCreateCandidate()}>{t('create_candidate')}</Button>
            </Button.Group>
            <Divider/>

            {view === 1 &&
                <SuperField as="choice"
                    key={view}
                    required
                    search
                    multiple
                    endpoint={API.CANDIDATES + `?query={id, fullname_with_titles}&not_in_recruitment=${roomId}&is_active=true&include_admins=false&exclude_blacklisted=true`}
                    text="fullname_with_titles"
                    label={t('candidates')}
                    value={selectedCandidates}
                    onChange={(e, { value }) => setSelectedCandidates(value)}
                />
            }

            {view === 2 &&
                <>
                    <Header as="h3" content={t('personal_information')}/>
                    <Divider/>
                    <Form.Group widths={2}>
                        <SuperField as="input"
                            autoFocus
                            required
                            label={t("firstname")}
                            placeholder={t("enter_firstname")}
                            value={candidate.firstname}
                            onChange={(e, { value }) => setCandidate({
                                ...candidate,
                                firstname: value
                            })}
                        />

                        <SuperField as="input"
                            required
                            label={t("lastname")}
                            placeholder={t("enter_lastname")}
                            value={candidate.lastname}
                            onChange={(e, { value }) => setCandidate({
                                ...candidate,
                                lastname: value
                            })}
                        />
                    </Form.Group>
                    <Form.Group widths={2}>
                        <SuperField as="datepicker"
                            label={t("date_of_birth")}
                            startMode="year"
                            value={candidate.date_of_birth}
                            error={errors.date_of_birth_error}
                            onChange={(e, { value }) => setCandidate({
                                ...candidate,
                                date_of_birth: value
                            })}
                        />

                        <SuperField as="choice"
                            type="genders"
                            label={t('gender')}
                            value={candidate.gender}
                            onChange={(e, { value }) => setCandidate({
                                ...candidate,
                                gender: value
                            })}
                        />

                    </Form.Group>

                    <Header as="h3" content={ t('contact_info') }/>
                    <p> { t('contact_info_hint_message') }. </p>
                    <Divider/>

                    <Form.Group widths="equal">
                        <PhoneField
                            hideType
                            placeholder={t('enter_number')}
                            value={candidate.phone}
                            setValue={(e, { value }) => setCandidate({ 
                                ...candidate,
                                phone: value
                            })}
                            />
                        <EmailField
                            label={t('email')}
                            placeholder={t('enter_email')}
                            value={candidate.email}
                            setValue={(e, { value }) => setCandidate({ 
                                ...candidate,
                                email: value
                            })}
                        />
                    </Form.Group>
                </>
            }

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || !isFilledRequiredFields()}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>

        </Form>
    );
};

export default NewCandidateForm;