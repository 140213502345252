import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
// import { useHasPermission } from '@helpers/hooks'
import { setLocaleLanguage, tzDateTime } from '@helpers/dates'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import DatePresets from '@components/DatePresets'
import SuperField from '@components/forms/SuperField'
import { Header, Label, Form, Popup } from 'semantic-ui-react'
import BonusPaymentForm from '../components/BonusPaymentForm'
import AddDateOfPayment from '../components/AddDateOfPayment'
import SuperDuperModal from '@components/modals/SuperDuperModal'

const Bonuses = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const planner_record_types = useSelector((state) => state.choices?.overtime_bank_record_type)

    let recordTypes = []
    Object.entries(planner_record_types).forEach(([key, value], index) => {
        if (!['1', '3'].includes(key)) {
            recordTypes.push({ id: key, value: key, text: value })
        }
    })

    return (
        <CanView permissions={['payrolls.c_view_all_bonus_payments', 'payrolls.c_manage_user_bonus_payments']} redirect>
            <ListView
                as="table"
                allowSearch
                isSoftDeleteAllowed
                endpoint={API.PAYROLLS + 'bonus_payments/'}
                actionsCellWidth="2"
                initialFilters={{
                    date_from: '',
                    date_to: '',
                    profile: '',
                    employer: '',
                    contract_type: '',
                    unit: '',
                    type: '',
                    is_approved: '',
                    ordering: ['-created_on'],
                }}
                listAdditionActions={(data, setData, total, setTotal, response) => [
                    {
                        as: 'filter',
                        index: 0,
                        name: t('all'),
                        filters: {
                            is_approved: null,
                        },
                        // count: response?.total_active || 0
                    },
                    {
                        as: 'filter',
                        index: 1,
                        name: t('approved'),
                        filters: {
                            is_approved: true,
                        },
                        // count: response?.total_inactive || 0
                    },
                    {
                        as: 'filter',
                        index: 2,
                        name: t('denied'),
                        filters: {
                            is_approved: false,
                        },
                        // count: response?.total || 0
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="datepicker"
                                label={t('date_from')}
                                value={filters.date_from}
                                onChange={(e, { value }) => {
                                    setFilters((prev) => ({ ...prev, date_from: value }))
                                }}
                            />
                            <SuperField
                                search
                                as="datepicker"
                                label={t('date_to')}
                                value={filters.date_to}
                                onChange={(e, { value }) => {
                                    setFilters((prev) => ({ ...prev, date_to: value }))
                                }}
                            />
                        </Form.Group>

                        <DatePresets
                            open={true}
                            from={filters.date_from}
                            to={filters.date_to}
                            onSelection={(firstMonthDay, lastMonthDay, year) => {
                                setFilters((prev) => ({
                                    ...prev,
                                    date_from: firstMonthDay,
                                    date_to: lastMonthDay,
                                    year: year,
                                }))
                            }}
                        />

                        <SuperField
                            as="choice"
                            label={t('record_type')}
                            type="overtime_bank_record_type"
                            value={filters.type}
                            customOptions={recordTypes}
                            onChange={(e, { value }) => {
                                setFilters({ ...filters, type: value })
                            }}
                        />

                        <Form.Group widths="equal">
                            <SuperField
                                search
                                as="choice"
                                label={t('employee')}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                                text="fullname"
                                value={filters.profile}
                                onChange={(e, { value }) => {
                                    setFilters({ ...filters, profile: value })
                                }}
                            />
                            <SuperField
                                as="choice"
                                search
                                label={t('employer')}
                                value={filters.employer}
                                text="name"
                                endpoint={API.BUSINESS_DETAIL + '?query={id, name}&is_employer=true'}
                                onChange={(e, { value }) => setFilters({ ...filters, employer: value })}
                            />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <SuperField
                                label={t('department')}
                                as="choice"
                                endpoint={
                                    API.UNITS + '?query={id, name}&only_basic_info=true&only_from_active_company=true'
                                }
                                text="name"
                                value={filters.unit}
                                onChange={(e, { value }) => setFilters({ ...filters, unit: value })}
                            />

                            <SuperField
                                as="choice"
                                label={t('contract_type')}
                                endpoint={API.CONTRACTS_TYPES}
                                text="title"
                                value={filters.contract_type}
                                onChange={(e, { value }) => setFilters({ ...filters, contract_type: value })}
                            />
                        </Form.Group>
                    </>
                )}
                exports={[
                    {
                        endpoint: API.EXPORTS + 'dynamic_bonus_payments/',
                        permissions: ['payrolls.c_view_all_bonus_payments'],
                        apply_filters: true,
                        type: 'xlsx',
                        filename: 'bonus_payments',
                    },
                ]}
                actions={[
                    {
                        as: 'modal',
                        type: 'add',
                        name: t('add_bonus'),
                        modalSize: 'tiny',
                        modal: <BonusPaymentForm />,
                        permissions: ['payrolls.c_manage_all_bonus_payments', 'payrolls.c_manage_user_bonus_payments'],
                    },
                    {
                        as: 'modal',
                        type: 'edit',
                        modalSize: 'tiny',
                        name: t('edit_bonus'),
                        permissions: ['payrolls.c_manage_all_bonus_payments', 'payrolls.c_manage_user_bonus_payments'],
                        modal: <BonusPaymentForm />,
                    },
                    {
                        name: t('delete'),
                        type: 'delete',
                        as: 'delete',
                        text: t('are_you_sure_that_you_want_to_remove_this_record'),
                        permissions: ['payrolls.c_delete_all_bonus_payments'],
                    },
                ]}
                tableHeaders={[
                    { title: t('employee') },
                    { title: t('bonus_type') },
                    { title: t('amount_sum') },
                    { title: t('billing_period') },
                    { title: t('date_of_payment') },
                    { title: t('payroll_period') },
                    { title: t('note') },
                    { title: t('record_type') },
                    { title: t('approval_status') },
                    { title: t('created') },
                ]}
                renderCells={(bonus, setData) => [
                    {
                        content: (
                            <>
                                <strong style={{ fontSize: '1rem' }}>
                                    {bonus?.contract?.employee?.fullname_with_titles}
                                </strong>{' '}
                                <br />
                                <span style={{ opacity: '0.8' }}>
                                    {bonus?.contract?.employee?.custom_prefix_and_id || ''}
                                </span>
                            </>
                        ),
                    },
                    { content: bonus.bonus_type?.title },
                    { content: bonus.amount + ' ' + bonus.currency },
                    { content: (bonus.billing_month || '-') + '/' + (bonus.billing_year || '-') },
                    {
                        content: bonus?.date_of_payment ? (
                            moment(bonus.date_of_payment).format(dateFormat)
                        ) : (
                            <CanView permissions={['payrolls.c_manage_all_bonus_payments']}>
                                <SuperDuperModal
                                    size="mini"
                                    header={t('add_date_of_payment')}
                                    trigger={
                                        <Header style={{ padding: 0, cursor: 'pointer', paddingTop: '0.5rem' }} as="h4">
                                            <Icon name="add-outline" style={{ fontSize: '1rem' }} />
                                        </Header>
                                    }
                                    content={
                                        <AddDateOfPayment data={bonus} endpoint={'bonus_payments/'} setData={setData} />
                                    }
                                />
                            </CanView>
                        ),
                    },
                    {
                        content: (
                            <>
                                {bonus.payroll === null ? (
                                    t('unassigned_period')
                                ) : (
                                    <>
                                        <span style={{ textTransform: 'capitalize' }}>
                                            {moment(bonus.payroll.date_from).locale(setLocaleLanguage()).format('MMMM')}{' '}
                                            {moment(bonus.payroll.date_from).format('YYYY')}
                                        </span>
                                    </>
                                )}
                            </>
                        ),
                    },
                    { content: bonus.note },
                    {
                        content: <>{bonus.type_display}</>,
                    },
                    {
                        content: (
                            <>
                                {bonus.is_approved && (
                                    <Label color="green">
                                        <span style={{ position: 'relative', top: '-0.2rem' }}>{t('approved')}</span>
                                        <Popup
                                            trigger={
                                                <Icon
                                                    name="information-circle-outline"
                                                    style={{ marginLeft: '0.5rem', fontSize: '1rem' }}
                                                />
                                            }
                                            content={
                                                t('approved_at') +
                                                ': ' +
                                                (bonus?.approval?.approval_datetime &&
                                                    tzDateTime(bonus?.approval?.approval_datetime).format(
                                                        dateFormat + ' HH:mm'
                                                    ))
                                            }
                                        />
                                    </Label>
                                )}
                                {!bonus.is_approved ? (
                                    bonus.approval?.is_approved === null ? (
                                        <Label color="orange">{t('pending')}</Label>
                                    ) : (
                                        <Label color="red">
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>{t('denied')}</span>
                                            <Popup
                                                trigger={
                                                    <Icon
                                                        name="information-circle-outline"
                                                        style={{ marginLeft: '0.5rem', fontSize: '1rem' }}
                                                    />
                                                }
                                                content={
                                                    <div>
                                                        {t('denied_at') +
                                                            ': ' +
                                                            tzDateTime(bonus?.approval?.approval_datetime).format(
                                                                dateFormat + ' HH:mm'
                                                            )}
                                                        <br />
                                                        <br />
                                                        <strong>{t('reason')}:</strong>
                                                        <p>{bonus.approval.rejection_reason || '--'}</p>
                                                    </div>
                                                }
                                            />
                                        </Label>
                                    )
                                ) : (
                                    ''
                                )}
                            </>
                        ),
                    },
                    {
                        content: (
                            <div>
                                {tzDateTime(bonus.created_on).format(dateFormat + ' HH:mm')} <br />
                                <span>
                                    {t('created_by')} <strong>{bonus.created_by?.name || '--'}</strong>
                                </span>
                            </div>
                        ),
                    },
                ]}
            />
        </CanView>
    )
}

export default Bonuses
