import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { dates } from '@helpers/dates';
import { requests } from '@helpers/requests';
import { alertDialog } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import { Grid, Button, Segment } from 'semantic-ui-react';

const InterruptionReasonsSelectList = ({ onClose, employee, setEmployee, interruptions, cancelActionDisplayText, endActivityDisplayText, onReturn }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)

    const [processing, setProcessing] = useState(false)

    const handleRestore = async (id) => {
        const request = await requests.del(API.ATTENDANCE_INTERRUPTIONS + id + "/")
        if (request.status === 204) {
            const fetchAttendance = await requests.patch(API.ATTENDANCE + employee.record.id + "/close_reset/" , { 
                end: null
            })
            if (fetchAttendance.status === 200) {
                setEmployee(prev => ({ 
                    ...prev, 
                    record: fetchAttendance.response
                }))
            }
        }
    }

    const handleInterruptionStart = async (interruption) => {
        setProcessing(true)

        let start = moment.utc().format("YYYY-MM-DD HH:mm:ss")
        const date = dates.convertUTCDateToLocalDate(start)

        const request = await requests.post(API.ATTENDANCE_INTERRUPTIONS, {
            record: employee.record.id,
            reason: interruption.id,
            date: moment().format('YYYY-MM-DD'),
            start: start
        })

        if (request.status === 201) {
            const fetchAttendance = await requests.get(API.ATTENDANCE + employee.record.id + "/")
            if (fetchAttendance.status === 200) {
                setEmployee(prev => ({ 
                    ...prev, 
                    record: fetchAttendance.response
                }))
                alertDialog(t('tracker_interruption_start_marked'), moment(date).format(`${dateFormat} HH:mm`), onReturn, () => handleRestore(request.response.id), endActivityDisplayText, cancelActionDisplayText)
            }
        }

        setProcessing(false)
    }

    return (
        <Segment 
            loading={processing}
            style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
                marginBottom: "1rem",
                marginTop: "1rem",
            }}
        >
            { (processing) && 
                <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                    { processing && t('processing_selected_reason') } 
                </p>
            }
            { (!processing) && 
                <Grid stackable>
                    <Grid.Row verticalAlign="center" columns={interruptions.length === 0 ? 1 : 2} stretched>
                        { interruptions.length === 0 && 
                            <Grid.Column textAlign="center">
                                <strong>{t('no_data')}</strong>
                            </Grid.Column>
                        }
                        { interruptions.map(interruption => (
                            <Grid.Column style={{ textAlign: 'center', marginBottom: "1rem" }}>
                                <Button 
                                    fluid
                                    size="large"
                                    className="rounded-edges circular" 
                                    onClick={() => handleInterruptionStart(interruption)}
                                    style={{ 
                                        background: "var(--dark)",
                                        color: "var(--light)"
                                    }}
                                >
                                    <small style={{ textTransform: "lowercase"}}> 
                                        <div style={{ fontSize: "1.5rem", marginBottom: "0.5rem", textAlign: "center" }}>
                                            <Icon name="play-outline"/>
                                        </div>
                                    </small>
                                    { interruption?.title }
                                </Button>
                            </Grid.Column>
                        )) } 
                    </Grid.Row>
                </Grid>
            }
        </Segment>
    );
};

export default InterruptionReasonsSelectList;