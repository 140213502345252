import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { setDotSeparator, isValidNumber } from '@helpers/functions';
// components
import ListView from '@components/ListView';
import CanView from '@components/perms/CanView';
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import GenerateDocuments from '@components/GenerateDocuments';

const WarehousePositions = () => {
    const { t } = useTranslation()

    return (
        <CanView permissions={['assets.c_view_warehouses']} redirect>
            <div style={{ marginTop: "1rem" }}></div>
            <ListView as="table"
                allowSearch
                allowSelection
                isSoftDeleteAllowed
                endpoint={API.ASSETS_WAREHOUSE_POSITIONS}
                tableHeaders={[
                    { title: t('title'), orderBy: "title" },
                    { title: t('section') },
                    { title: t('row') },
                    { title: t('max_storage_capacity') },
                    { title: t('reserved_for_item') },
                    { title: t('warehouse') },
                ]}
                initialFilters={{
                    warehouse: "",
                }}
                bulkActions={ (selected) => [
                    { 
                        as: "modal",
                        name: t('generate_documents'),
                        icon: "document-text-outline",
                        modal: <GenerateDocuments source="assets.WarehousePosition" selected={selected} />
                    }
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <SuperField as="choice"
                        search
                        text="title"
                        label={t("warehouse")}
                        value={filters.warehouse}
                        endpoint={API.ASSETS + "warehouses/?query={id, title}"}
                        onChange={(e, { value }) => setFilters({ ...filters, warehouse: value })}
                    />
                )}
                renderCells={(position) => ([
                    { content: position.title },
                    { content: position.section },
                    { content: position.row },
                    { content: position.quantity_limit || t('unlimited') },
                    { content: position.reserved_for_item 
                        ? (position.reserved_for_item?.code ? position.reserved_for_item?.code + " - " : "") + " " + position.reserved_for_item?.title
                        : t('not_specified')
                    },
                    { content: position.warehouse?.title || t('not_specified') },
                ])}
                actions={[
                    {
                        as: "modal",
                        type: "add",
                        modalSize: "tiny",
                        name: t('create_warehouse_position'),
                        modal: <WarehousePositionForm/>,
                        permissions: ['assets.c_manage_warehouses'],
                    },
                    {
                        as: "modal",
                        type: "edit",
                        name: t('edit'),
                        modalSize: "tiny",
                        permissions: ['assets.c_manage_warehouses'],
                        modal: <WarehousePositionForm/>
                    },
                    {
                        as: "delete",
                        name: t('delete'),
                        type: "delete",
                        text: t('delete_warehouse_position'),
                        permissions: ['assets.c_manage_warehouses'],
                    },
                ]}
            />
        </CanView>
    );
};

const WarehousePositionForm = ({ record, setData, setTotal, onClose }) => {
    const { t } = useTranslation()

    const [commodities, setCommodities] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        title: record?.title || "",
        section: record?.section || "",
        row: record?.row || "",
        quantity_limit: record?.quantity_limit || "",
        warehouse: record?.warehouse?.id || "",
        reserved_for_item:  record?.reserved_for_item?.id || "",
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        if( record?.id ){ // update
            const request = await requests.patch(API.ASSETS_WAREHOUSE_POSITIONS + record.id + "/", {
                title: formData.title,
                section: formData.section,
                row: formData.row,
                warehouse: formData.warehouse || null,
                reserved_for_item: formData.reserved_for_item || null,
                quantity_limit: formData.quantity_limit || null,
            })
            if( request.status === 200 ){
                setData(prev => prev.map(item => {
                    if( item.id === record.id ){
                        item = request.response 
                    }
                    
                    return item;
                }))

                onClose()
            }
        } else { // create
            const request = await requests.post(API.ASSETS_WAREHOUSE_POSITIONS, {
                title: formData.title,
                section: formData.section,
                row: formData.row,
                warehouse: formData.warehouse || null,
                reserved_for_item: formData.reserved_for_item || null,
                quantity_limit: formData.quantity_limit || null,
            })
            if(request.status === 201){
                setData(prev => [...prev, request.response])
                setTotal(count => count + 1)
                onClose()
            }
        }
        setIsProcessing(false)
    }

    useEffect(() => {
        async function fetchCatalogueItems() {
            const request = await requests.get(API.ORDERS + "catalogue_items/?query={id, code, title}")
            if (request.status === 200) {
                setCommodities(request.response)
            }
        }

        fetchCatalogueItems()
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                required
                autoFocus
                label={t('title')}
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('section')}
                    value={formData.section}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        section: value
                    }) }
                />
                <SuperField as="input"
                    label={t('row')}
                    value={formData.row}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        row: value
                    }) }
                />
            </Form.Group>

            <SuperField as="input"
                label={t('max_storage_capacity')}
                help={t('leave_it_blank_for_unlimited')}
                value={formData.quantity_limit}
                error={!isValidNumber(formData.quantity_limit, true) ? t('not_a_number') : false}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    quantity_limit: setDotSeparator(value)
                })}
            />

            <SuperField as="choice"
                search
                text="title"
                label={t("warehouse")}
                value={formData.warehouse}
                endpoint={API.ASSETS + "warehouses/?query={id, title}"}
                onChange={(e, { value }) => setFormData({ ...formData, warehouse: value })}
            />

            <SuperField as="choice"
                search
                text="title"
                label={t("reserved_for_item")}
                value={formData.reserved_for_item}
                customOptions={commodities.map(item => ({
                    key: item.id,
                    value: item.id,
                    text: `${item.code ? item.code + " - " : ""}${item.title}`
                }))}
                onChange={(e, { value }) => setFormData({ ...formData, reserved_for_item: value })}
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === "" || !isValidNumber(formData.quantity_limit, true)}
                    text={t('confirm')} />
            </Form.Field>
        </Form>
    )
}

export default WarehousePositions;