const userReducer = (user = {}, action) => {
    switch (action.type) {
        case "ADD_USER":
            return action.user;
        case "LOGOUT_USER":
            return {};
        default:
            return user;
    }
};

export default userReducer;
