import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { Popup } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// custom
import InvoicePreviewPDF from '../../../sm/invoices/components/InvoicePreviewPDF';

const InvoiceReferenceIndicator = ({ fulfillment, pushLeft }) => {
    const { t } = useTranslation()
    const dateFormat = useSelector(state => state.date_format)
    const invoiceRef = fulfillment?.invoice_reference
    const hasInvoice = (invoiceRef === "" || invoiceRef === null || invoiceRef === undefined) ? false : true

    let invoice_id = ""
    let invoice_number = ""
    // eslint-disable-next-line
    let invoice_vs = ""
    let invoice_date_issue = ""
    let invoice_date_supply = ""
    let invoice_date_due = ""

    if (hasInvoice) {
        const split = invoiceRef?.split(';')
        // eslint-disable-next-line
        const type = split[0] // expense/income
        invoice_id = split[1]
        invoice_number = split[2]
        invoice_vs = split[3] // vs
        // eslint-disable-next-line
        const invoice_status = split[4]
        invoice_date_issue = split[5]
        invoice_date_supply = split[5]
        invoice_date_due = split[7]
        // eslint-disable-next-line
        const invoice_date_paid = split[8]
    }

    const Indicator = ({ cursor }) => {
        return (
            <div style={{ cursor: cursor || "normal" }}>
                <Icon 
                    name={hasInvoice ? "checkmark-circle" : "alert-circle"}
                    style={{
                        top: "0.2rem",
                        fontSize: "0.8rem",
                        position: "relative",
                        color: hasInvoice ? "var(--success)" : "var(--danger)",
                    }}
                />
                <span style={{ position: "relative", top: "0.1rem", fontWeight: "bold" }}> { hasInvoice ? t('invoiced') : t('not_invoiced') } </span>
            </div>
        )
    }

    return (
        <>
            { hasInvoice 
                ? 
                <Popup
                    hoverable
                    position="left center"
                    content={
                        <div>
                            { hasInvoice &&
                                <p>
                                    { t('invoice_number') }: { " " }
                                    <strong>
                                        <SuperDuperModal
                                            size="small"
                                            trigger={
                                                <span style={{ color: "var(--primary)" }} className="ref-link">
                                                    { invoice_number }
                                                </span>
                                            }
                                            content={
                                                <InvoicePreviewPDF
                                                    id={invoice_id}
                                                />
                                            }
                                        />
                                        
                                    </strong> 
                                    <br/>
                                    { t('issue_invoice_date') }: <strong>{ invoice_date_issue ? moment(invoice_date_issue).format(dateFormat) : "--" }</strong> <br/>
                                    { t('supply_invoice_date') }: <strong>{ invoice_date_supply ? moment(invoice_date_supply).format(dateFormat) : "--" }</strong> <br/>
                                    { t('due_to_invoice_date') }: <strong>{ invoice_date_due ? moment(invoice_date_due).format(dateFormat) : "--" }</strong> <br/>
                                </p>
                            }
                        </div>
                    }
                    trigger={
                        <div>
                            <Indicator cursor="pointer"/>
                        </div>
                    }
                />
                : <Indicator/>
            }
        </>
    )
}

export default InvoiceReferenceIndicator;