import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import SuperField from '@components/forms/SuperField';
import SpinnerSegment from '@components/SpinnerSegment';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider, Segment, Radio, Button } from 'semantic-ui-react';
// specific
import OrderStageForm from './OrderStageForm';


const ProcessStageForm = ({ setData, setTotal, onClose, setSize, setHeader }) => {
    const { t } = useTranslation()
    const allowedOrderTypes = useSelector(state => state.allowed_order_types)

    const [isProcessing, setIsProcessing] = useState(false)
    const [isProcessingStageCreate, setIsProcessingStageCreate] = useState(false)
    const [view, setView] = useState(1)
    const [order, setOrder] = useState("")
    const [orders, setOrders] = useState([])
    const [selectFromExistingOrders, setSelectFromExistingOrders] = useState(false)
    const [processType, setProcessType] = useState("")
    const [processTypes, setProcessTypes] = useState([])
    const [createType, setCreateType] = useState(false)
    const [title, setTitle] = useState("")
    const [type, setType] = useState("")
    const [operationType, setOperationType] = useState("sell")
    const [fetchingOrders, setFetchingOrders] = useState(false)
    const [fetching, setFetching] = useState(false)

    const [processes, setProcesses] = useState([])
    const [processGroup, setProcessGroup] = useState("")
    const [loadingProcessGroup, setLoadingProcessGroup] = useState(false)

    useEffect(() => {
        async function fetchProcessTypes() {
            setFetching(true)
            const request = await requests.get(API.ORDERS + "sale_process_types/")
            if (request.status === 200) {
                setProcessTypes(request.response)
                if (request.response.length === 0) setCreateType(true)
            }
            setFetching(false)
        }

        async function fetchProcessGroups() {
            setLoadingProcessGroup(true)
            const request = await requests.get(API.ORDERS + "sale_processes/?query={id, title}")
            if (request.status === 200) setProcesses(request.response)
            setLoadingProcessGroup(false)
        }

        fetchProcessTypes()
        fetchProcessGroups()
    }, [])

    const handleAddProcessGroup = async (value) => {
        setLoadingProcessGroup(true)
        const request = await requests.post(API.ORDERS + "sale_processes/", {
            title: value
        })

        if (request.status === 201) {
            setProcessGroup(request.response.id)
            setProcesses(prev => [...prev, request.response])
        }
        setLoadingProcessGroup(false)
    }

    useEffect(() => {
        async function fetchOrders() {
            setFetchingOrders(true)
            if (view === 2 && processType !== "") {
                let query = ""
                const process = processTypes.find(item => item.id === processType)
                if (view === 2 && process) {
                    if (process.order_type === "sale") query += "&resource_type=saleorder"
                    if (process.order_type === "employment") query += "&resource_type=employmentorder"
                    if (process.order_type === "service") query += "&resource_type=serviceorder"
                    if (process.order_type === "transport") query += "&resource_type=transportorder"
                    if (process.order_type === "hr") query += "&resource_type=hrorder"
                
                    if (process.is_purchase_order_type) {
                        query += "&is_purchase_order=true"
                    } else {
                        query += "&is_purchase_order=false"
                    }

                    const request = await requests.get(API.ORDERS + "?only_basic_info=true&is_active=true" + query)
                    if (request.status === 200) {
                        setOrders(request.response)
                    }
                }
            }
            setFetchingOrders(false)
        }

        fetchOrders()
        // eslint-disable-next-line
    }, [view, processType, processTypes])

    const isValidForm = () => {
        if (createType === false && processType !== "") return true
        if (createType === true && title !== "" && type !== "" && operationType !== "") return true

        return false
    }

    const customTypeList = [
        // sell
        allowedOrderTypes['sale_order'] === true && { key: 1, is_purchase: false, value: "sale", text: t("sale_of_goods_or_services_to_customers") },
        // { key: 2, is_purchase: false, value: "employment", text: t("temporary_assignment_of_an_employee") },
        // { key: 3, is_purchase: false, value: "service", text: t("provision_of_service_services") },
        allowedOrderTypes['transport_order'] === true && { key: 4, is_purchase: false, value: "transport", text: t("transport_of_objects") },
        allowedOrderTypes['hr_order'] === true && { key: 5, is_purchase: false, value: "hr", text: t("recruitment_of_employees") },

        // buy
        allowedOrderTypes['sale_order'] === true && { key: 6, is_purchase: true, value: "sale", text: t("purchase_of_goods_or_services_from_suppliers") },
        allowedOrderTypes['transport_order'] === true && { key: 7, is_purchase: true, value: "transport", text: t("transport_of_objects") },
    ]

    const getListByType = (type) => {
        if (type === "") return []
        
        if (type === "sell") return customTypeList.filter(item => item.is_purchase === false)
        if (type === "buy") return customTypeList.filter(item => item.is_purchase === true)
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        if ( !createType ) {
            setView(2) // order create form
            setSize("small")
            setHeader(t('add_order'))
        } else { // create new operation
            let data = {
                title: title,
                is_purchase_order_type: operationType === "buy" ? true : false,
                order_type: type
            }

            const request = await requests.post(API.ORDERS + "sale_process_types/", data)
            if (request.status === 201) {
                setProcessType(request.response.id)
                setProcessTypes(prev => [...prev, request.response])
                setView(2)
                setSize("small")
                setHeader(t('add_order'))
            }
        }
        setIsProcessing(false)
    }

    const determinateDate = (order) => {
        let date = moment().format("YYYY-MM-DD") 
        if (order?.resourcetype === "TransportOrder") {
            date = order.loading_date
        } else if (order?.resourcetype === "HROrder") {
            date = order.start_date
        } 
        // else if (response.resourcetype === "SaleOrder") {
            // is there any option other then today??
        // }

        return date
    }

    const handleConfirm = async (newOrder, processGroup) => {
        setIsProcessingStageCreate(true)
        if (order !== "" && selectFromExistingOrders) {
            if (newOrder === null) {
                const fetchOrder = await requests.get(API.ORDERS + order + "/")
                if (fetchOrder.status === 200) {
                    newOrder = fetchOrder.response
                } 
            }
            const request = await requests.post(API.ORDERS + "sale_stages/", {
                type: processType,
                process: processGroup || null,
                order: order,
                date: determinateDate(newOrder)
            })

            if (request.status === 201) {
                if (newOrder.order_number === newOrder.original_order_number) { // not filled custom order number... assign based on stage number
                    const updateOrder = await requests.patch(API.ORDERS + newOrder.id + "/", {
                        order_number: request.response.number,
                        resourcetype: newOrder.resourcetype
                    })

                    if (updateOrder.status === 200) {
                        request.response.order_display = updateOrder.response
                    }
                }

                setData(prev => [...prev, request.response])
                setTotal(prev => prev + 1)
                onClose()
            }
        } else if (newOrder?.id !== undefined && !selectFromExistingOrders) {
            const request = await requests.post(API.ORDERS + "sale_stages/", {
                type: processType,
                process: processGroup || null,
                order: newOrder.id,
                date: determinateDate(newOrder)
            })

            if (request.status === 201) {
                if (newOrder.order_number === newOrder.original_order_number) { // not filled custom order number... assign based on stage number
                    const updateOrder = await requests.patch(API.ORDERS + newOrder.id + "/", {
                        order_number: request.response.number,
                        resourcetype: newOrder.resourcetype
                    })

                    if (updateOrder.status === 200) {
                        request.response.order_display = updateOrder.response
                    }
                }

                setData(prev => [...prev, request.response])
                setTotal(prev => prev + 1)
                onClose()
            }
        }

        setIsProcessingStageCreate(false)
    }

    const determinateOrder = () => {
        let isPurchase = false
        let resource_type = ""

        const process = processTypes.find(item => item.id === processType)
        if (process) {
            if (process.order_type === "sale") resource_type = "saleorder"
            if (process.order_type === "employment") resource_type = "employmentorder"
            if (process.order_type === "service") resource_type = "serviceorder"
            if (process.order_type === "transport") resource_type = "transportorder"
            if (process.order_type === "hr") resource_type = "hrorder"
        
            if (process.is_purchase_order_type) {
                isPurchase = true
            } else {
                isPurchase = false
            }
        }

        return { isPurchase: isPurchase, resourceType: resource_type}
    }

    return (
        <SpinnerSegment loading={fetching || isProcessingStageCreate} loadingMessage={isProcessingStageCreate ? t('processing_request') + "..." : t('loading')}>
            { view === 1 && 
                <Form onSubmit={handleSubmit}>
                    { !createType && 
                        <SuperField as="choice"
                            search
                            required
                            loading={fetching}
                            disabled={fetching}
                            value={processType}
                            label={t('select_from_existing_operations')}
                            onChange={(e, { value }) => setProcessType(value)}
                            customOptions={processTypes.map(item => ({ key: item.id, value: item.id, text: `${item.is_purchase_order_type ? t('buy') : t('sell') }: ${item.title}` }))}
                        /> 
                    }

                    <SuperField as="checkbox"
                        label={t('create_new_operation')}
                        checked={createType}
                        onChange={() => {
                            setProcessType("")
                            setCreateType(!createType)
                        }}
                    />

                    { createType && 
                        <>
                            <Divider/>
                            <SuperField as="input"
                                required
                                autoFocus
                                label={t('title')}
                                onChange={(e, { value }) => setTitle(value)}
                            />

                            <Form.Field>
                                <strong>{t('type')} <sup style={{ color: "red" }}>*</sup></strong>
                                <Form.Group style={{ marginTop: "0.5rem" }}>
                                    <SuperField as="radio"
                                        label={t('sell')}
                                        checked={ operationType === "sell" }
                                        onChange={() => {
                                            if (operationType === "sell") {
                                                setOperationType("sell")
                                            } else {
                                                setOperationType("sell")
                                                setType("")
                                            }
                                        }}
                                    />
                                    <SuperField as="radio"
                                        label={t('buy')}
                                        checked={ operationType === "buy" }
                                        onChange={() => {
                                            if (operationType === "buy") {
                                                setOperationType("buy")
                                            } else {
                                                setOperationType("buy")
                                                setType("")
                                            }
                                        }}
                                    />
                                </Form.Group>
                                { getListByType(operationType).map(item => (
                                    <Segment
                                        key={item.key}
                                        style={{ 
                                            display: "flex",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => setType(item.value)}
                                        className={`sale-process-type${item.value === type ? ' selected' : ""}`}>
                                        <Radio checked={item.value === type}/>    
                                        <div style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                                            { item.text }
                                        </div>
                                    </Segment>                                    
                                ))}
                            </Form.Field>
                        </>
                    }

                    <Divider />
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel onClose={onClose}/>
                        <ModalSubmit
                            text={t("confirm")}
                            disabled={isProcessing || !isValidForm()}
                            loading={isProcessing} 
                        />
                    </Form.Field>
                </Form>
            }

            { view === 2 && 
                <>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group widths="equal">
                            <SuperField as="checkbox"
                                label={t('select_from_existing_orders')}
                                checked={selectFromExistingOrders}
                                onChange={() => {
                                    setOrder("")
                                    setSelectFromExistingOrders(!selectFromExistingOrders)
                                }}
                            />
                        </Form.Group>
                        <Divider/>

                        { selectFromExistingOrders && 
                            <Form.Group widths="equal">
                                <SuperField as="choice"
                                    search
                                    required
                                    autoFocus
                                    value={order}
                                    loading={fetchingOrders}
                                    disabled={fetchingOrders}
                                    customOptions={orders.map(item => ({
                                        key: item.id,
                                        value: item.id,
                                        text: `${item.order_number} - ${item.name}`
                                    }))}
                                    label={t('select_from_existing_orders')}
                                    onChange={(e, { value }) => setOrder(value)}
                                /> 
                                <SuperField as="choice"
                                    search
                                    label={t('process_group')}
                                    width="10"
                                    value={processGroup}
                                    loading={loadingProcessGroup}
                                    disabled={loadingProcessGroup}
                                    help={t('process_group_of_orders_hint')}
                                    allowAdditions={true}
                                    onAddItem={(e, { value }) => handleAddProcessGroup(value)}
                                    onChange={(e, { value }) => setProcessGroup(value)}
                                    customOptions={processes.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                                />
                            </Form.Group>
                        }


                        {/* Create order by type */}
                        { selectFromExistingOrders 
                            ? 
                            <>
                                <Divider />
                                <Form.Field style={{ textAlign: "right" }}>
                                    <ModalCancel onClose={onClose}/>
                                    <Button
                                        primary
                                        content={t("confirm")}
                                        loading={isProcessing}
                                        onClick={() => handleConfirm(null, processGroup)}
                                        disabled={isProcessing || order === "" || fetchingOrders}
                                    />
                                </Form.Field>
                            </>
                            :
                            <>
                                <OrderStageForm 
                                    onClose={onClose}
                                    stageTypeTitle={ processTypes.find(item => item.id === processType)?.title || "" }
                                    isPurchase={determinateOrder().isPurchase}
                                    resourceType={determinateOrder().resourceType}
                                    handleConfirm={handleConfirm}

                                />
                            </>
                        }
                    </Form>
                </>
            }
        </SpinnerSegment>
    );
};

export default ProcessStageForm;