import React, { useState } from 'react'
// store
import { isEmpty } from "@store/services/helpers/validation"
import { commonService } from '@store/services/ServiceCommon';
import { API } from '@store/config'
// components
import { Divider, Form } from 'semantic-ui-react';
import UnitTypeField from '@components/forms/common/UnitTypeField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { useTranslation } from 'react-i18next';
import SuperField from '@components/forms/SuperField'


export const CreateUnitForm = ({ isCompany, onClose, onConfirm, unitId, companyId  }) => {
    console.log(unitId)
    const { t } = useTranslation()
    const [name, setName] = useState("");
    const [unitType, setUnitType] = useState("");
    const [responsiblePersons, setResponsiblePersons] = useState([]);

    const [isProcessing, setIsProcessing] = useState(false);
    const [errorList/*, setErrorList*/] = useState([]); // empty array

    const handleSubmit = async () => {
        setIsProcessing(true);

        let data = {
            name: name,
            unit_type: unitType,
            company: parseInt(companyId),
            parent: !isCompany ? unitId : null
        }

        if (responsiblePersons.length > 0) {
            data['responsible_persons'] =  { add: responsiblePersons }
        }

        const request = await commonService.createUnit(data)

        if( request.status === 201 ){
            onConfirm(request.response);
            onClose()
        }

        setIsProcessing(false);
    }


    return (
        <Form onSubmit={handleSubmit}>
            { errorList.length > 0 &&
                <ul>
                    { errorList.map( (error, index) =>
                        <li key={index}> {error} </li>
                    )}
                </ul>
            }

            <Form.Group widths={2}>
                <Form.Field>
                    <Form.Input
                        autoFocus
                        required
                        label={t('name')}
                        placeholder={t('name')}
                        value={name}
                        onChange={(e) => setName(e.target.value) }
                    />
                </Form.Field>

                <UnitTypeField
                    value={unitType}
                    setValue={(e, data) => setUnitType(data.value)}
                />
            </Form.Group>
            <SuperField
                fluid
                search
                multiple
                as="choice"
                label={t('responsible_persons')}
                value={responsiblePersons}
                text="fullname"
                endpoint={API.EMPLOYEES + '?query={id, fullname}&only_basic_info=true'}
                onChange={(e, { value }) => setResponsiblePersons(value)}
            />


            <Divider/>

            <Form.Field style={{ textAlign: "right"}}>
                <ModalCancel
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    text={t('save')}
                    disabled={isProcessing || isEmpty(name)}
                    loading={isProcessing}
                />
            </Form.Field>
        </Form>

    )
}