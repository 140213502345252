import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from '@services/helpers/validation';
// components
import { Form, Divider, Header } from 'semantic-ui-react';
import Address from '@components/forms/Address';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';

const AccommodationForm = ({ accommodation, onClose, setData, setTotal, handleRequest }) => {
    const { t } = useTranslation();

    const [addressForm, setAddressForm] = useState({
        id: accommodation?.address?.id || "",
        street: accommodation?.address?.street || "",
        number: accommodation?.address?.number || "",
        city: accommodation?.address?.city || "",
        postcode: accommodation?.address?.postcode || "",
        country: accommodation?.address?.country || "",
        state: accommodation?.address?.state || "",
        address_line: accommodation?.address?.address_line || "",
    })

    const [form, setForm] = useState({
        id: accommodation?.id || "",
        name: accommodation?.name || "",
        accommodation_type: accommodation?.accommodation_type || "",
        unit: accommodation?.unit?.id || "",
        accommodate_relation: accommodation?.accommodate_relation || "",
        contact_person_name: accommodation?.contact_person_name || "",
        price_per_night_per_person: accommodation?.price_per_night_per_person || null,
        total_bed_count: accommodation?.total_bed_count || 1,
        phone: accommodation?.phone || "",
        email: accommodation?.email || "",
        currency: accommodation?.currency || "EUR"
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleSubmit = async () => {
        setErrors(null);
        setIsProcessing(true);

        const data = {
            name: form.name,
            accommodation_type: form.accommodation_type,
            accommodate_relation: form.accommodate_relation,
            contact_person_name: form.contact_person_name,
            price_per_night_per_person: form.price_per_night_per_person !== "" ? form.price_per_night_per_person : null,
            total_bed_count: form.total_bed_count !== "" ? form.total_bed_count : null,
            address: addressForm,
            email: form.email,
            phone: form.phone,
            currency: form.currency,
            unit: form.unit
        }

        if (!form.id || form.id === 0) {
            const request = await requests.post(API.ACCOMMODATIONS, data)

            if (request.status === 201) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } else {
                setErrors(request.response)
            }
        } else {
            const request = await requests.put(API.ACCOMMODATIONS + form.id + "/", data)
            if (request.status === 200) {
                handleRequest(request.response, setData, setTotal)
                onClose()
            } else {
                setErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors}/>
            <SuperField as="input"
                autoFocus
                required
                label={t('name')}
                placeholder={t('name')}
                value={form.name}
                error={errors?.name?.[0] || false}
                onChange={(e, { value }) => setForm({
                    ...form,
                    name: value
                })}
            />
            <Form.Group widths="equal">
                <SuperField as="choice"
                    label={t('accommodate_relation')}
                    customOptions={[
                        { key: 1, value: 'co-owner', text: t('co-owner') },
                        { key: 2, value: 'co-renter', text: t('co-renter') },
                        { key: 3, value: 'owner', text: t('owner') },
                        { key: 4, value: 'renter', text: t('renter') },
                        { key: 5, value: 'subtenant', text: t('subtenant') }
                    ]}
                    value={form.accommodate_relation}
                    error={errors?.accommodate_relation?.[0] || false}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        accommodate_relation: value
                    })}
                />
                <SuperField as="choice"
                    label={t('accommodation_type')}
                    customOptions={[
                        { key: 1, value: 'family_house', text: t('family_house') },
                        { key: 2, value: 'flat', text: t('flat') },
                        { key: 3, value: 'flat_house', text: t('flat_house') },
                        { key: 4, value: 'hostel', text: t('hostel') }
                    ]}
                    value={form.accommodation_type}
                    error={errors?.accommodation_type?.[0] || false}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        accommodation_type: value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    required
                    label={t('total_bed_count')}
                    placeholder={t('total_bed_count')}
                    value={form.total_bed_count}
                    error={errors?.total_bed_count?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, total_bed_count: value}))}
                />
                <SuperField as="choice"
                    label={t('unit')}
                    endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                    text="name"
                    value={form.unit}
                    onChange={(e, { value }) => setForm({...form, unit: value })} 
                />
            </Form.Group>
            <Form.Group widths={'equal'}>
                <SuperField as="input"
                    label={t('price_per_night_per_person')}
                    placeholder={t('price_per_night_per_person')}
                    value={form.price_per_night_per_person}
                    error={errors?.price_per_night_per_person?.[0] || false}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        price_per_night_per_person: value.replace(",", ".")
                    })}
                />
                <SuperField as="choice"
                    width="4"
                    search
                    type="currency_codes"
                    label={t('currency')}
                    value={form.currency}
                    error={errors?.currency?.[0] || false}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        currency: value
                    })}
                />
            </Form.Group>
            <Divider />
            <Header content={t('contact')} style={{marginTop: 0}} />
            <div>
                <SuperField as="input"
                    label={t('contact_person_name')}
                    placeholder={t('contact_person_name')}
                    value={form.contact_person_name}
                    error={errors?.contact_person_name?.[0] || false}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        contact_person_name: value
                    })}
                />
                <Form.Group widths='equal'>
                    <PhoneField
                        hideType
                        placeholder={t('enter_number')}
                        error={errors?.phone?.[0] || false}
                        value={form.phone}
                        setValue={(e, { value }) => setForm({ 
                            ...form,
                            phone: value
                        })}
                    />
                    <EmailField
                        label={t('email')}
                        placeholder={t('enter_email')}
                        value={form.email}
                        error={errors?.email?.[0] || false}
                        setValue={(e, { value }) => setForm({ 
                            ...form,
                            email: value
                        })}
                    />
                </Form.Group>
            </div>
            <Divider />
            <Header content={t('address')} style={{marginTop: 0}} />
                <Address isForm address={addressForm} setAddress={setAddressForm}/>
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(form.name) || isNaN(form.total_bed_count) || form.total_bed_count <= 0 }
                    text={t('confirm')} />
            </Form.Field>
        </Form>
    );
};

export default AccommodationForm;