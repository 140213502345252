import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
//store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
//components
import { Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

export const DatesForm = ({ selected, setSelected, setData, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)

    const [form, setForm] = useState({
        valid_from: '',
        valid_to: '',
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        const dataset = {
            valid_from: form.valid_from,
            valid_to: form.valid_to,
        }

        for (let i = 0; i < selected.length; i++) {
            const request = await requests.patch(API.ACCESS_CARD + selected[i].id + "/", dataset)
            if (request.status === 200) {
                setData(prev => prev.filter(item => {
                    if (item.id === selected[i].id){
                        item.valid_from = request.response.valid_from
                        item.valid_to = request.response.valid_to
                    }
                    return item
                }))
            }
        }
        setSelected([])
        onClose()
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    clearable
                    label={t('valid_from')}
                    value={form.valid_from}
                    onChange={(e, { value }) => setForm({ ...form, valid_from: value })}
                />
                <SuperField as="datepicker"
                    required
                    clearable
                    label={t('valid_to')}
                    value={form.valid_to}
                    onChange={(e, { value }) => setForm({ ...form, valid_to: value })}
                />
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    text={t("confirm")} 
                    disabled={isProcessing || !form.valid_from || !form.valid_to}
                    loading={isProcessing} 
                />
            </Form.Field>
        </Form>
    )
}
        
        
export default DatesForm;
        