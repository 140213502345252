import React from 'react';
import { useTranslation } from "react-i18next";
// components
import { Grid } from 'semantic-ui-react';

const StatsHeader = ({ stats, rooms, accommodation }) => {
    const { t } = useTranslation();
    return (
        <>
            <Grid style={{ paddingBottom: 0, marginBottom: 0 }}>
                <Grid.Row columns={6} stretched textAlign='center' divided style={{ border: "solid 0.1px #eeeeee" }}>
                    <Grid.Column>
                        <strong>{t('total_beds')}</strong>
                        {accommodation?.total_bed_count || '0'}
                    </Grid.Column>
                    <Grid.Column>
                        <strong>{t('total_rooms')}</strong>
                        {rooms || '0'}
                    </Grid.Column>
                    <Grid.Column>
                        <strong>{t('total_nights')}</strong>
                        {stats?.total_nights || '0'}
                    </Grid.Column>
                    <Grid.Column>
                        <strong>{t('total_people')}</strong>
                        {stats?.total_people || '0'}
                    </Grid.Column>
                    <Grid.Column>
                        <strong>{t('total_price')}</strong>
                        {(stats?.total_price || '0') + " " + accommodation.currency}
                    </Grid.Column>
                    <Grid.Column>
                        <strong>{t('accommodation_tax')}</strong>
                        {(stats?.accommodation_tax || '0') + " " + accommodation.currency}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default StatsHeader;