import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { hiringService } from '@services/ServiceHiring';
// components
import { Header, Segment } from 'semantic-ui-react';
import Loading from '@components/general/Loading';
import HistoryStageRecordList from '../../hiring-rooms/components/activity-log/HistoryStageRecordList';

const CandidateHistory = ({ candidate }) => {
    const { t } = useTranslation();
    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(true)
    const [filterHistory, setFilterHistory] = useState(0)

    const loadHistoryLog = async () => {
        let filter  = ""
        if (filterHistory !== 0){
            filter = "?action="+filterHistory
        }
        const result = await hiringService.getHistory(candidate, filter)
        if (result.status === 200) {
            setHistory(result.response)
        }
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await loadHistoryLog();
            setLoading(false);
        };

        fetchData();
        // eslint-disable-next-line
    }, [candidate]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            let filter = ''
            if (filterHistory !== 0){
                filter = "?action="+filterHistory
            }
            const result = await hiringService.getHistory(candidate, filter)

            if (result.status === 200) {
                setHistory(result.response);
            }
            setLoading(false);
        };
        fetchData();

        // eslint-disable-next-line
    }, [filterHistory]);

    return (
        <Segment style={{ border: "none" }}>
            <Header as='h3' dividing textAlign="center">
                {t('hiring_room_activity_log')}
            </Header>
            { loading ? <Loading/> : <HistoryStageRecordList history={history} setFilterHistory={setFilterHistory} filterHistory={filterHistory} setHistory={setHistory}/> }
        </Segment>
    );
};

export default CandidateHistory;