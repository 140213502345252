import React from "react";
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config'
import { patchRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import { Grid, Header, Card, Label } from "semantic-ui-react";
import AvatarIcon from '@components/AvatarIcon';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import ResponsiblePersonAssignForm from './ResponsiblePersonAssignForm';


const UnitHeader = (props) => {
    const { t } = useTranslation()

    const onResponsiblePersonRemove = async (id, personID) => {
        const request = await patchRequest(API.UNITS + id + '/', {
            responsible_persons: {
                remove: [personID]
            }
        });
        if (request.status === 200) {
            props.setData(prevState => ({
                ...prevState,
                responsible_persons: request.response.responsible_persons
            }))
        }
    }

    return (
        <Card fluid style={{ borderRadius: 0 }}>
            <Grid centered>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column computer={5} tablet={5} mobile={16} style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                        <AvatarIcon size={150} name={props.data.name || "."} src={props.data.company.logo && props.data.company.logo} uploadable={false} />
                    </Grid.Column>
                    <Grid.Column computer={11} tablet={11} mobile={16}>
                        <Grid.Row>
                            <Grid><Header as="h2" style={{ paddingTop: '2rem', fontSize: '3rem' }}>{props.data.name}</Header>{props.action}</Grid>
                        </Grid.Row>
                            <Grid columns={3}>
                                <Grid.Row>
                                    <Grid.Column style={{ fontSize: "1.3rem" }} computer={16} tablet={5} mobile={16} >
                                        <Grid.Row>
                                            <Header style={{ paddingBottom: "1rem" }} as="h3"> {t('Info')}:</Header>
                                        </Grid.Row>
                                        {props.data.unit_type &&
                                            <Label basic size="large"> {props.data.unit_type?.name} </Label>
                                        }
                                        <Label basic size="large">
                                            <span style={{ paddingRight: "1rem" }}> {props.tree.attributes?.descendant_count} </span>
                                            <span> {t('unit_count')} </span>
                                        </Label>

                                        <Label basic size="large">
                                            <span style={{ paddingRight: "1rem" }}> {props.tree.attributes?.employee_count} </span>
                                            <span> {t('employee_count')} </span>
                                        </Label>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid columns={3}>
                            <Grid.Row>
                                <Grid.Column style={{ fontSize: "1.3rem" }} computer={16} tablet={5} mobile={16} >
                                    <Grid.Row>
                                        <Header style={{ paddingBottom: "1rem" }} as="h3"> {t('responsible_persons')}:</Header>
                                    </Grid.Row>

                                    { props.data.responsible_persons.map( (responsible_person) => (
                                        <>
                                            <Label style={{ marginTop: '0.3rem' }} key={responsible_person.id} basic size="large">
                                                <span style={{ position: "relative", top: "-0.2rem" }}> {responsible_person.fullname}  </span>
                                                <Icon name="close-outline" onClick={() => onResponsiblePersonRemove(props.data.id, responsible_person.id)} style={{ marginLeft: "0.5rem", cursor: "pointer", color: "var(--danger)"}}/>
                                            </Label>
                                        </>
                                    ))}

                                    <SuperDuperModal
                                        trigger={
                                            <Label style={{ fontSize: "1rem", marginTop: '0.3rem', cursor: "pointer" }} basic size="tiny">
                                                <Icon name="add-outline"/>
                                            </Label>
                                        }
                                        content={
                                            <ResponsiblePersonAssignForm
                                                unit = {props.data}
                                                setUnit = {props.setData}
                                            />
                                        }
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </Card>
    )

}
export default UnitHeader;
