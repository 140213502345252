import React from 'react';
import { useTranslation } from 'react-i18next';
// store
import { icons } from '@store/icons';
// components
// import { Container } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
// module specific components
import TasksBoard from './views/TasksBoard';

const Tasks = () => {
    const { t } = useTranslation()

    const accessPerms = ['tasks.c_view_tasks', 'tasks.c_manage_user_tasks'] 

    return (
        <CanView permissions={accessPerms} redirect>
            <BreadcrumbNav items={[
                { 'name': t('tasks'), 'icon': icons.TASKS, 'href': "" }
            ]}/>

            <TasksBoard/>
        </CanView>
    );
};

export default Tasks;