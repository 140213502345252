import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Loading from '@components/general/Loading';
import { Grid, Form, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';

import ProjectMembersTable from './ProjectMembersTable';
import ProjectMemberCreateForm from '../../forms/ProjectMemberCreateForm';


const ProjectMemberTab = ({ project }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [projectMembers, setProjectMembers] = useState([])

    const initState = {
        search: "",
        gitlab_role: ""
    }

    const [filters, setFilters] = useState(initState)

    async function fetchData(params){
        params = params || ""
        let queryparams = ''
        if (filters.gitlab_role !== '') queryparams += "&gitlab_role=" + filters.gitlab_role
        if (filters.search !== '') queryparams += "&search=" + filters.search

        setIsLoading(true)
        const request = await requests.get(API.PROJECT_MEMBERS + `?project=${project.id}${params}${queryparams}`);

        if (request.status === 200) {
            setProjectMembers(request.response);
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const onCreate = (response) => {
        setProjectMembers(prev => ([...prev, response]))
    }

    return (
        <>
        {isLoading ? <Loading /> :
        <>
        <Grid columns={2}>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Form style={{ marginTop: "1rem", paddingBottom: "0.5rem", float: 'right'}}>
                        <Form.Group>
                            { project?.allow_gitlab &&
                            <SuperField as="choice"
                                type="gitlab_roles"
                                label={t('gitlab_role')}
                                value={filters.gitlab_role}
                                onChange={ (e, {value}) => setFilters({ ...filters, gitlab_role: value }) }
                            />
                            }
                            <SuperField as="input"
                                icon="search"
                                iconPosition="left"
                                label={t('search')}
                                placeholder={t('search')}
                                value={filters.search}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    search: value
                                })}
                            />

                            <Form.Field style={{ marginTop: '1.8rem' }}>
                                <Button loading={isLoading} disabled={isLoading} size="small" content={t('apply')} primary onClick={ () => {fetchData()}} />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
                <Grid.Column width={2}>
                    <CanView permissions={['projects.c_manage_all_projects', 'projects.c_manage_user_projects']}>
                        <SuperDuperModal
                            header={t('add_member')}
                            trigger={
                                <Button size="small" style={{marginTop: '2.8rem', textAlign: 'right'}} primary>{t('add_member')}</Button>
                            }
                            content={
                                <ProjectMemberCreateForm project={project} onConfirm={(response, type) => onCreate(response, type)} />
                            }
                        />
                    </CanView>
                </Grid.Column>

            </Grid.Row>
        </Grid>
            <ProjectMembersTable
                projectMembers={projectMembers}
                setProjectMembers={setProjectMembers}
                project={project}
                filters={filters}
                setFilters={setFilters}
                fetchData={fetchData}
            />
        </>
        }
        </>
    )
}

export default ProjectMemberTab;



