import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-semantic-toasts'
// store
import { useHasPermissions, useIsMount } from '@helpers/hooks'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { convertTime } from '@helpers/dates'
import {
    addDateFormat,
    // addTimezoneFormat,
    addPrefSwitchToCandidate,
    addAutoTimesheetApproval,
    addAllowJumpOverStages,
    addAllowEmailNotifications,
    addAllowQRScanner,
    addAllowedOrderTypes,
    addDefaultOrderTypes,
    addAllowSequentialNumberingLogisticRecord,
} from '@store/actions'
// components
import SuperField from '@components/forms/SuperField'
import { Button, Form, Header, Container, Tab } from 'semantic-ui-react'

const Preferences = () => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    const dispatch = useDispatch()
    const format = useSelector((state) => state.date_format)
    // const timezone = useSelector(state => state.timezone)
    const switch_to_candidate = useSelector((state) => state.switch_to_candidate)
    const allow_jump_over_stages = useSelector((state) => state.allow_jump_over_stages)
    const allow_email_notifications = useSelector((state) => state.allow_email_notifications)
    const automatic_timesheet_approval = useSelector((state) => state.automatic_timesheet_approval)
    const allow_qr_scanner = useSelector((state) => state.allow_qr_scanner)
    const allow_sequential_numbering_logistic_record = useSelector(
        (state) => state.allow_sequential_numbering_logistic_record
    )

    const orderTypes = useSelector((state) => state.allowed_order_types)
    const canViewGlobalPreferences = useHasPermissions(['users.c_manage_global_preferences'])

    const [loadingTypes, setLoadingTypes] = useState(true)
    const [defaultFormat, setDefaultFormat] = useState(format)
    // const [defaultTimezone, setDefaultTimezone] = useState(timezone)
    const [switchToCandidate, setSwitchToCandidate] = useState(switch_to_candidate)
    const [automaticTimesheetApproval, setAutomaticTimesheetApproval] = useState(automatic_timesheet_approval)
    const [allowJumpOverStages, setAllowJumpOverStages] = useState(allow_jump_over_stages)
    const [allowEmailNotifications, setAllowEmailNotifications] = useState(allow_email_notifications)
    const [allowQRScanner, setAllowQRScanner] = useState(allow_qr_scanner)
    const [allowSequentialNumberingLogisticRecord, setAllowSequentialNumberingLogisticRecord] = useState(
        allow_sequential_numbering_logistic_record
    )

    const [hrOrder, setHrOrder] = useState(orderTypes.hr_order)
    const [serviceOrder, setServiceOrder] = useState(orderTypes.service_order)
    const [transportOrder, setTransportOrder] = useState(orderTypes.transport_order)
    const [employmentOrder, setEmploymentOrder] = useState(orderTypes.employment_order)
    const [saleOrder, setSaleOrder] = useState(orderTypes.sale_order)

    const [bonusPaymentAutoApproval, setBonusPaymentAutoApproval] = useState(false)
    // eslint-disable-next-line
    const [nightShiftMarkerStartTolerance, setNightShiftMarkerStartTolerance] = useState('')
    // eslint-disable-next-line
    const [nightShiftMarkerEndTolerance, setNightShiftMarkerEndTolerance] = useState('')

    const order = {
        hr_order: hrOrder,
        service_order: serviceOrder,
        transport_order: transportOrder,
        employment_order: employmentOrder,
        sale_order: saleOrder,
    }

    const [defaultTypes, setDefaultTypes] = useState([])

    useEffect(() => {
        async function getTypes() {
            setLoadingTypes(true)
            const result = await requests.get(API.PREFERENCES + 'display_orders__display_type_orders/')
            const requestApprovalBonuses = await requests.get(
                API.PREFERENCES + 'approve_record_automatically__attendance_bonus_automatically/'
            )
            const requestStartTolerance = await requests.get(
                API.PREFERENCES + 'attendance_preferences__attendance_night_shift_start_tolerance/'
            )
            const requestEndTolerance = await requests.get(
                API.PREFERENCES + 'attendance_preferences__attendance_night_shift_end_tolerance/'
            )

            if (result.status === 200) {
                setDefaultTypes(result.response.value)
            }

            if (requestApprovalBonuses.status === 200) {
                setBonusPaymentAutoApproval(requestApprovalBonuses.response.value)
            }
            if (requestStartTolerance.status === 200) {
                setNightShiftMarkerStartTolerance(convertTime(requestStartTolerance.response.value, false))
            }
            if (requestEndTolerance.status === 200) {
                setNightShiftMarkerEndTolerance(convertTime(requestEndTolerance.response.value, false))
            }
            setLoadingTypes(false)
        }

        getTypes()
    }, [])

    async function setStartTolerance() {
        const convertedValue = convertTime(nightShiftMarkerStartTolerance, true)
        await requests.patch(API.PREFERENCES + 'attendance_preferences__attendance_night_shift_start_tolerance/', {
            value: convertedValue,
        })
    }

    async function setEndTolerance() {
        const convertedValue = convertTime(nightShiftMarkerEndTolerance, true)
        await requests.patch(API.PREFERENCES + 'attendance_preferences__attendance_night_shift_end_tolerance/', {
            value: convertedValue,
        })
    }

    useEffect(() => {
        if (!isMount && !loadingTypes) {
            if (nightShiftMarkerStartTolerance !== '') setStartTolerance(nightShiftMarkerStartTolerance)
            if (nightShiftMarkerEndTolerance !== '') setEndTolerance(nightShiftMarkerEndTolerance)
        }

        // eslint-disable-next-line
    }, [nightShiftMarkerStartTolerance, nightShiftMarkerEndTolerance])

    const formats = ['YYYY-MM-DD', 'YYYY/MM/DD', 'DD.MM.YYYY', 'DD/MM/YYYY', 'DD-MM-YYYY', 'MM.DD.YYYY', 'MM/DD/YYYY']

    const defaultOrderTypesOptions = [
        {
            key: 'recieved',
            text: t('received_orders'),
            value: 'recieved',
        },
        {
            key: 'issued',
            text: t('issued_orders'),
            value: 'issued',
        },
    ]

    const setDatePreference = async (value) => {
        setDefaultFormat(value)
        const request = await requests.patch(API.PREFERENCES + 'date_format__global_date_format_preferences/', {
            value: value,
        })
        if (request.status === 200) {
            dispatch(addDateFormat(value))
            toast({
                type: 'success',
                icon: 'check circle',
                title: '',
                description: t('format_successfully_changed'),
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const panes = [
        {
            menuItem: t('user'),
            render: () => (
                <Tab.Pane>
                    <Header as="h3" content={t('other_preferences')} />
                    <SuperField
                        as="checkbox"
                        label={t('allow_user_to_receive_email_notifications')}
                        checked={allowEmailNotifications}
                        onChange={async () => {
                            const request = await requests.patch(
                                API.USER_PREFERENCES +
                                    'allow_sending_email_notification__allow_sending_email_notification/',
                                { value: !allowEmailNotifications }
                            )
                            if (request.status === 200) {
                                dispatch(addAllowEmailNotifications(!allowEmailNotifications))
                                setAllowEmailNotifications(!allowEmailNotifications)
                            }
                        }}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: canViewGlobalPreferences && t('global'),
            render: () => (
                <Tab.Pane>
                    <Form>
                        <Header as="h3" content={t('date_format_preference')} />
                        {/* <Header style={{ paddingBottom: 0 }} as="h3">{t('date_format_preference')}</Header> */}
                        {/* <Divider />
                        <p><strong>YYYY</strong> - {t('date_format_year')}</p>
                        <p><strong>MM</strong> - {t('date_format_month')}</p>
                        <p><strong>DD</strong> - {t('date_format_day')}</p> */}
                        <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                            {formats.map((value) => (
                                <Button
                                    active={defaultFormat === value}
                                    onClick={() => setDatePreference(value)}
                                    content={value}
                                />
                            ))}
                        </Button.Group>
                        <p>
                            {t('selected_format')}: <strong>{defaultFormat}</strong>, {t('for_example')} -{' '}
                            <strong>{moment().format(defaultFormat)}</strong>
                        </p>
                    </Form>

                    <Form onSubmit={(e) => e.preventDefault()} style={{ marginTop: '2rem' }}>
                        <Header as="h3">
                            {t('order_preferences')}
                            <Header.Subheader
                                content={t('order_preferences_description')}
                                style={{ paddingBottom: '1rem' }}
                            />
                        </Header>
                        <SuperField
                            as="checkbox"
                            label={t('recruitment')}
                            checked={hrOrder}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'display_orders__display_orders/',
                                    { value: { ...order, hr_order: !hrOrder } }
                                )
                                if (request.status === 200) {
                                    dispatch(addAllowedOrderTypes({ ...order, hr_order: !hrOrder }))
                                    setHrOrder(!hrOrder)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('employment')}
                            checked={employmentOrder}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'display_orders__display_orders/',
                                    { value: { ...order, employment_order: !employmentOrder } }
                                )
                                if (request.status === 200) {
                                    dispatch(addAllowedOrderTypes({ ...order, employment_order: !employmentOrder }))
                                    setEmploymentOrder(!employmentOrder)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('business')}
                            checked={saleOrder}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'display_orders__display_orders/',
                                    { value: { ...order, sale_order: !saleOrder } }
                                )
                                if (request.status === 200) {
                                    dispatch(addAllowedOrderTypes({ ...order, sale_order: !saleOrder }))
                                    setSaleOrder(!saleOrder)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('service')}
                            checked={serviceOrder}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'display_orders__display_orders/',
                                    { value: { ...order, service_order: !serviceOrder } }
                                )
                                if (request.status === 200) {
                                    dispatch(addAllowedOrderTypes({ ...order, service_order: !serviceOrder }))
                                    setServiceOrder(!serviceOrder)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('transport')}
                            checked={transportOrder}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'display_orders__display_orders/',
                                    { value: { ...order, transport_order: !transportOrder } }
                                )
                                if (request.status === 200) {
                                    dispatch(addAllowedOrderTypes({ ...order, transport_order: !transportOrder }))
                                    setTransportOrder(!transportOrder)
                                }
                            }}
                        />
                    </Form>

                    <Form onSubmit={(e) => e.preventDefault()} style={{ marginTop: '2rem' }}>
                        <Header as="h3">{t('default_orders')}</Header>
                        {/* <SuperField as="dropdown"
                            inline
                            label={ t('hr_order') + ":" }
                            options={defaultOrderTypesOptions}
                            value={defaultTypes.hr_order}
                            onChange={async (e, { value }) => {
                                const request = await requests.patch(API.PREFERENCES + "display_orders__display_type_orders/", { value: {...defaultTypes, hr_order: value} })
                                if (request.status === 200) {
                                    dispatch(addDefaultOrderTypes({...defaultTypes, hr_order: value}))
                                    setDefaultTypes({...defaultTypes, hr_order: value})
                                }
                            }}
                        />
                        <SuperField as="dropdown"
                            inline
                            label={ t('service_order') + ":" }
                            options={defaultOrderTypesOptions}
                            value={defaultTypes.service_order}
                            onChange={async (e, { value }) => {
                                const request = await requests.patch(API.PREFERENCES + "display_orders__display_type_orders/", { value: {...defaultTypes, service_order: value} })
                                if (request.status === 200) {
                                    dispatch(addDefaultOrderTypes({...defaultTypes, service_order: value}))
                                    setDefaultTypes({...defaultTypes, service_order: value})
                                }
                            }}
                        /> */}
                        <SuperField
                            as="dropdown"
                            inline
                            label={t('transport') + ':'}
                            options={defaultOrderTypesOptions}
                            value={defaultTypes.transport_order}
                            onChange={async (e, { value }) => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'display_orders__display_type_orders/',
                                    { value: { ...defaultTypes, transport_order: value } }
                                )
                                if (request.status === 200) {
                                    dispatch(addDefaultOrderTypes({ ...defaultTypes, transport_order: value }))
                                    setDefaultTypes({ ...defaultTypes, transport_order: value })
                                }
                            }}
                        />
                        {/* <SuperField as="dropdown"
                            inline
                            label={ t('employment_order') + ":" }
                            options={defaultOrderTypesOptions}
                            value={defaultTypes.employment_order}
                            onChange={async (e, { value }) => {
                                const request = await requests.patch(API.PREFERENCES + "display_orders__display_type_orders/", { value: {...defaultTypes, employment_order: value} })
                                if (request.status === 200) {
                                    dispatch(addDefaultOrderTypes({...defaultTypes, employment_order: value}))
                                    setDefaultTypes({...defaultTypes, employment_order: value})
                                }
                            }}
                        /> */}
                        <SuperField
                            as="dropdown"
                            inline
                            label={t('sale') + ':'}
                            options={defaultOrderTypesOptions}
                            value={defaultTypes.sale_order}
                            onChange={async (e, { value }) => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'display_orders__display_type_orders/',
                                    { value: { ...defaultTypes, sale_order: value } }
                                )
                                if (request.status === 200) {
                                    dispatch(addDefaultOrderTypes({ ...defaultTypes, sale_order: value }))
                                    setDefaultTypes({ ...defaultTypes, sale_order: value })
                                }
                            }}
                        />
                    </Form>

                    <Form onSubmit={(e) => e.preventDefault()} style={{ marginTop: '2rem' }}>
                        <Header as="h3">{t('attendance_preferences')}</Header>

                        <SuperField
                            as="checkbox"
                            label={t('allow_to_auto_approve_assigned_bonuses')}
                            checked={bonusPaymentAutoApproval}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'approve_record_automatically__attendance_bonus_automatically/',
                                    { value: !bonusPaymentAutoApproval }
                                )
                                if (request.status === 200) {
                                    // dispatch(addAllowedOrderTypes({ ...order, hr_order: !hrOrder }))
                                    setBonusPaymentAutoApproval(!bonusPaymentAutoApproval)
                                }
                            }}
                        />

                        <Form.Field style={{ marginTop: '1rem' }}>
                            <strong style={{ fontSize: '1.05rem' }}>
                                {t('set_tolerance_for_marking_attendance_record_as_night_shift')}
                            </strong>{' '}
                            <br />
                            <span style={{ opacity: 0.8 }}>{t('attendance_tolerance_explanation')}</span>
                        </Form.Field>
                        <Form.Group>
                            <SuperField
                                width={2}
                                as="timepicker"
                                label={t('time_from')}
                                value={nightShiftMarkerStartTolerance}
                                onChange={(e, { value }) => setNightShiftMarkerStartTolerance(value)}
                            />
                            <SuperField
                                width={2}
                                as="timepicker"
                                label={t('time_end')}
                                value={nightShiftMarkerEndTolerance}
                                onChange={(e, { value }) => setNightShiftMarkerEndTolerance(value)}
                            />
                        </Form.Group>
                    </Form>

                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Header as="h3" content={t('other_preferences')} style={{ marginTop: '2rem' }} />
                        <SuperField
                            as="checkbox"
                            label={t('allow_to_move_employee_to_candidate_evidence_when_delete')}
                            checked={switchToCandidate}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'switch_employee_to_candidate__switch_employee_to_candidate/',
                                    { value: !switchToCandidate }
                                )
                                if (request.status === 200) {
                                    dispatch(addPrefSwitchToCandidate(!switchToCandidate))
                                    setSwitchToCandidate(!switchToCandidate)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('allow_users_to_jump_over_steps_in_hiring_rooms')}
                            checked={allowJumpOverStages}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'candidates__allow_jump_over_stages/',
                                    { value: !allowJumpOverStages }
                                )
                                if (request.status === 200) {
                                    dispatch(addAllowJumpOverStages(!allowJumpOverStages))
                                    setAllowJumpOverStages(!allowJumpOverStages)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('allow_to_automatically_approve_timesheet_records')}
                            checked={automaticTimesheetApproval}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES +
                                        'approve_record_automatically__approve_timesheet_record_automatically/',
                                    {
                                        value: !automaticTimesheetApproval,
                                    }
                                )
                                if (request.status === 200) {
                                    if (!automaticTimesheetApproval === true) {
                                        const requestUpdate = await requests.get(
                                            API.TIMESHEETS + 'records/auto_approval/'
                                        )
                                        if (requestUpdate.status === 200) {
                                            toast({
                                                type: 'success',
                                                icon: 'check circle',
                                                title: '',
                                                description: t('records_updated_successfully'),
                                                animation: 'bounce',
                                                time: 2000,
                                            })
                                        }
                                    }

                                    dispatch(addAutoTimesheetApproval(!automaticTimesheetApproval))
                                    setAutomaticTimesheetApproval(!automaticTimesheetApproval)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('allow_qr_code_scanner_for_attendance_terminal')}
                            checked={allowQRScanner}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'allow_to_scan_qr_codes__allow_to_scan_qr_codes/',
                                    { value: !allowQRScanner }
                                )
                                if (request.status === 200) {
                                    dispatch(addAllowQRScanner(!allowQRScanner))
                                    setAllowQRScanner(!allowQRScanner)
                                }
                            }}
                        />
                        <SuperField
                            as="checkbox"
                            label={t('enable_sequential_numbering_of_logistics_records')}
                            checked={allowSequentialNumberingLogisticRecord}
                            onChange={async () => {
                                const request = await requests.patch(
                                    API.PREFERENCES + 'logistic_reference_numbers__logistic_reference_number_by_date/',
                                    {
                                        value: !allowSequentialNumberingLogisticRecord,
                                    }
                                )
                                if (request.status === 200) {
                                    dispatch(
                                        addAllowSequentialNumberingLogisticRecord(
                                            !allowSequentialNumberingLogisticRecord
                                        )
                                    )
                                    setAllowSequentialNumberingLogisticRecord(!allowSequentialNumberingLogisticRecord)
                                }
                            }}
                        />
                    </Form>
                </Tab.Pane>
            ),
        },
    ]

    // const setTimezonePreference = async (value) => {
    //     if (value === "") value = "0"
    //     setDefaultTimezone(value)
    //     const request = await requests.patch(API.USER_PREFERENCES + "timezone__timezone/", { value: value })
    //     if (request.status === 200) {
    //         dispatch(addTimezoneFormat(value))
    //         toast({
    //             type: 'success',
    //             icon: 'check circle',
    //             title: "",
    //             description: t('timezone_successfully_changed'),
    //             animation: 'bounce',
    //             time: 3000,
    //         });
    //     }
    // }

    return (
        <Container fluid style={{ marginTop: '1rem' }}>
            {/* <Form onSubmit={(e) => e.preventDefault()}>
                <Header as="h3" content={t('user_preferences')} style={{ marginTop: "2rem" }}/>
                <SuperField as="choice"
                    search
                    width="6"
                    label={t('select_timezone')}
                    value={defaultTimezone?.toString?.() || "0"}
                    customOptions={[
                        { key: 0, value: "0", text: "(GMT + 0) UTC" },
                        { key: 1, value: "1", text: "(GMT + 1) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
                        { key: 2, value: "1", text: "(GMT + 1) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
                        { key: 3, value: "2", text: "(GMT + 2) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius" },
                        { key: 4, value: "5.5", text: "(GMT + 5:30) Chennai, Kolkata, Mumbai, New Delhi" },
                    ]}
                    onChange={(e, { value }) => setTimezonePreference(value)}
                />
            </Form> */}

            <Tab panes={panes} />
        </Container>
    )
}

export default Preferences
