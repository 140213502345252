import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import '@store/languages'
// store
import { API } from '@store/config'
import { useHasPermission } from '@helpers/hooks'
import { tzDateTime, getTimezoneOffset } from '@helpers/dates'
import { requests, fileDownload } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import CanView from '@components/perms/CanView'
import SuperField from '@components/forms/SuperField'
import FlexRowWithToggle from '@components/FlexRowWithToggle'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Divider, Form, Button, Segment, Popup, Dropdown } from 'semantic-ui-react'
// specific components
import WeekManagement from '../components/management/WeekManagement'
import AssignAttendanceBonusForm from '../components/management/AssignAttendanceBonusForm'
// eslint-disable-next-line
import LongtermAbsenceForm from '../components/management/LongtermAbsenceForm'

const ManageEmployee = () => {
    const { t } = useTranslation()
    const date_format = useSelector((state) => state.date_format)
    // eslint-disable-next-line
    const profile = useSelector((state) => state.user.profile)

    // eslint-disable-next-line
    const canManageOnlyAssignedEmployees = useHasPermission(
        'attendance.c_can_manage_only_employees_as_responsible_person'
    )

    const excludeSelf = useHasPermission('attendance.c_can_not_manage_self_attendance')

    const [month, setMonth] = useState(moment().format('MM'))
    const [year, setYear] = useState(moment().format('YYYY'))
    const [employee, setEmployee] = useState('')
    const [unit, setUnit] = useState('')
    const [contracts, setContracts] = useState([])
    const [absenceTypes, setAbsenceTypes] = useState([])
    const [units, setUnits] = useState([])
    const [loading, setLoading] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [isUnitsFetched, setIsUnitFetched] = useState(false)
    const [excludeWeekend, setExcludeWeekend] = useState(false)
    const [showCorrectedRecords, setShowCorrectedRecords] = useState(false)
    const [loadingShifts, setLoadingShifts] = useState(false)
    const [loadingContracts, setLoadingContracts] = useState(true)

    const [holidays, setHolidays] = useState({})
    const [shifts, setShifts] = useState([])
    const [weeks, setWeeks] = useState([])
    const [records, setRecords] = useState([])
    const [weeksStats, setWeeksStats] = useState([])
    const [canPerformCorrections, setCanPerformCorrections] = useState(false)
    const [viewVoucher, setViewVoucher] = useState(false)
    const [viewSecondBreak, setViewSecondBreak] = useState(false)
    const [interruptionReasons, setInterruptionReasons] = useState([])

    const contract = contracts.find((item) => item.id === employee)

    const fetchAbsenceTypes = async () => {
        const request = await requests.get(API.ATTENDANCE_BASE + 'absence_types/?is_active=true')
        if (request.status === 200) setAbsenceTypes(request.response)
    }

    const fetchShifts = async () => {
        setLoadingShifts(true)
        const request = await requests.get(API.ATTENDANCE_BASE + 'shifts/')

        if (request.status === 200) {
            setShifts(
                request.response.map((item) => ({ ...handleShiftConvertion(item), is_processing: false, errors: null }))
            )
        }
        setLoadingShifts(false)
    }

    const fetchInterruptionReasons = async () => {
        const request = await requests.get(API.ATTENDANCE_INTERRUPTION_REASONS + '?query={id, title}')
        if (request.status === 200) setInterruptionReasons(request.response)
    }

    const fetchUnits = async () => {
        let fetchedUnits = []
        let isResponsible = ''
        if (canManageOnlyAssignedEmployees && profile?.id) {
            isResponsible = '&responsible_persons=' + profile.id
        }
        const request = await requests.get(
            API.UNITS + '?query={id, name}&only_basic_info=true&only_from_active_company=true' + isResponsible
        )
        if (request.status === 200) {
            setUnits(request.response)
            setIsUnitFetched(true)
            fetchedUnits = request.response
        }

        return fetchedUnits
    }

    const fetchContracts = async (unit) => {
        setLoadingContracts(true)
        let unitQuery = ''
        let fetchedUnits = []

        let isResponsible = ''
        if (canManageOnlyAssignedEmployees && profile?.id) {
            isResponsible = '&responsible_person=' + profile.id
        }

        if (!isUnitsFetched) {
            fetchedUnits = await fetchUnits()
        } else {
            fetchedUnits = units
        }

        if (fetchedUnits.length > 0) {
            if (unit) {
                unitQuery = '&unit=' + unit
            }
        }

        // get me current day and first day in month from previous month
        const currentDay = moment()
        const previousMonth = currentDay.clone().subtract(1, 'months')
        const contract_date_to = currentDay.format('YYYY-MM-DD')
        const contract_date_from = previousMonth.clone().startOf('month').format('YYYY-MM-DD')

        const query =
            '{id, fond, fond_per_unit, fond_per_unit_display, employee, work_position, available_vacation_fond}'
        const request = await requests.get(
            API.CONTRACTS +
                `?query=${query}&only_basic_info=true&valid_contract_based_on_date_from=${contract_date_from}&valid_contract_based_on_date_to=${contract_date_to}${unitQuery}${isResponsible}`
        )

        if (request.status === 200) {
            let fetchedContracts = request?.response?.results
            if (profile && excludeSelf) {
                fetchedContracts = fetchedContracts.filter((item) => item.employee.id !== profile.id)
            }

            setContracts(fetchedContracts || [])
        }
        setLoadingContracts(false)
    }

    const fetchPreferences = async () => {
        const request = await requests.get(
            API.PREFERENCES + 'attendance_preferences__allow_attendance_record_correction/'
        )
        if (request.status === 200) {
            setCanPerformCorrections(request.response.value)
        }

        const requestVoucherPref = await requests.get(
            API.PREFERENCES + 'attendance_preferences__global_lunch_voucher_preferences/'
        )
        if (requestVoucherPref.status === 200) {
            setViewVoucher(parseInt(requestVoucherPref?.response?.value || 0) > 0)
        }

        const requestSecondBreakPref = await requests.get(
            API.PREFERENCES + 'attendance_preferences__global_second_break_requirement/'
        )
        if (requestSecondBreakPref.status === 200) {
            setViewSecondBreak(parseFloat(requestSecondBreakPref?.response?.value || 0) > 0)
        }
    }

    useEffect(() => {
        fetchAbsenceTypes()
        fetchPreferences()
        fetchContracts()
        fetchShifts()
        // eslint-disable-next-line
    }, [])

    const handleShiftConvertion = (shift) => {
        if (!['', null].includes(shift.time_from)) {
            let datetime_from = moment(moment().format('YYYY-MM-DD') + ' ' + shift.time_from).format('YYYY-MM-DD HH:mm')
            // convert to Localtime:
            shift.time_from = tzDateTime(datetime_from).format('HH:mm')
        }

        if (!['', null].includes(shift.time_to)) {
            let datetime_to = moment(moment().format('YYYY-MM-DD') + ' ' + shift.time_to).format('YYYY-MM-DD HH:mm')
            // convert to Localtime:
            shift.time_to = tzDateTime(datetime_to).format('HH:mm')
        }

        if (!['', null].includes(shift.pause_length)) {
            if (parseFloat(shift.pause_length) > 0) {
                shift.pause_length = parseFloat(shift.pause_length)
            }
        }
        return shift
    }

    const monthList = [
        { key: 1, value: '01', text: t('january') },
        { key: 2, value: '02', text: t('february') },
        { key: 3, value: '03', text: t('march') },
        { key: 4, value: '04', text: t('april') },
        { key: 5, value: '05', text: t('may') },
        { key: 6, value: '06', text: t('june') },
        { key: 7, value: '07', text: t('july') },
        { key: 8, value: '08', text: t('august') },
        { key: 9, value: '09', text: t('september') },
        { key: 10, value: '10', text: t('october') },
        { key: 11, value: '11', text: t('november') },
        { key: 12, value: '12', text: t('december') },
    ]

    const getDates = (from, to) => {
        let dates = []
        // add logic to calculate days between two date range
        for (let day = from; day.isSameOrBefore(to); day.add(1, 'days')) {
            dates.push(day.format('YYYY-MM-DD'))
        }

        return dates
    }

    const generateXLSXReport = async (id) => {
        let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
        if (showCorrectedRecords) correctedRecordsQuery = ''
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')
        await fileDownload(
            'GET',
            API.API_URL +
                `/exports/new_dynamic_attendance/?is_blob=true&contracts=${
                    contract?.id
                }&date_from=${firstDay}&date_to=${lastDay}&empty_days=true&timezone=${getTimezoneOffset(
                    firstDay + ' 11:00'
                )}${correctedRecordsQuery}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `attendance_monthly_report_${firstDay}__${lastDay}.xlsx`
        )
    }

    const generateXLSXAbsenceReport = async (id) => {
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('form.')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')

        let queryParam = ''
        if (employee !== '') queryParam += `&contracts=${employee}`

        await fileDownload(
            'GET',
            API.EXPORTS + `payrolls/absences/?date_from=${firstDay}&date_to=${lastDay}&export_type=excel${queryParam}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `attendance_absences_report_${firstDay}__${lastDay}.xlsx`
        )
    }

    const generatePDFReport = async (id) => {
        let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
        if (showCorrectedRecords) correctedRecordsQuery = ''
        const firstDay = moment(year + '-' + month + '-01')
            .startOf('month')
            .format('YYYY-MM-DD')
        const lastDay = moment(year + '-' + month + '-01')
            .endOf('month')
            .format('YYYY-MM-DD')
        await fileDownload(
            'GET',
            API.API_URL +
                `/exports/attendance_pdf_download/${
                    contract?.employee?.id
                }/?is_blob=true&start_date=${firstDay}&end_date=${lastDay}&timezone=${getTimezoneOffset(
                    firstDay + ' 10:00'
                )}${correctedRecordsQuery}`,
            'application/pdf',
            `attendance_monthly_report_${firstDay}__${lastDay}.pdf`
        )
    }

    const generateMonthlyAttendance = async () => {
        setLoading(true)
        const firstDay = moment(year + '-' + month + '-01').startOf('month')
        const lastDay = moment(year + '-' + month + '-01').endOf('month')
        const dates = getDates(firstDay, lastDay)
        const duplicate_weeks = dates.map((date) => moment(date).isoWeek())
        const unique_weeks = [...new Set(duplicate_weeks)]
        setWeeks(unique_weeks)
        const attendance = []

        const request = await requests.get(API.COMMON + 'holidays/?country=SK&year=' + year)
        if (request.status === 200) setHolidays(request.response)

        await fetchInterruptionReasons()

        for (let i = 0; i < dates.length; i++) {
            attendance.push({
                week: moment(dates[i]).isoWeek(),
                day: dates[i],
            })
        }

        const attendance_week_stats = []
        for (let i = 0; i < unique_weeks.length; i++) {
            // calculate week start and end date
            let correctedRecordsQuery = '&ignore_attendance_correction_preference=true'
            if (showCorrectedRecords) correctedRecordsQuery = ''
            const weekDates = attendance.filter((item) => item.week === unique_weeks[i])
            const first_day = weekDates[0]?.day
            const last_day = weekDates[weekDates.length - 1]?.day
            const request_week_stats = await requests.get(
                API.ATTENDANCE +
                    `employee_stats/?date_from=${first_day}&date_to=${last_day}&contracts=${employee}${correctedRecordsQuery}`
            )

            if (request_week_stats.status === 200) {
                // set response from the API call
                attendance_week_stats.push({
                    week: unique_weeks[i],
                    ...request_week_stats.response,
                })
            } else {
                // set default on API call error
                attendance_week_stats.push({
                    week: unique_weeks[i],
                    working_hours: 0,
                    lunch_voucher: 0,
                    vacation: 0,
                    vacation_hours: 0,
                    absention: 0,
                    paragraph: 0,
                    paragraph_family_member: 0,
                    sick_day: 0,
                    care_for_a_family_member: 0,
                    require_manual_corrections: 0,
                    duplicated_records: [],
                })
            }
        }

        setWeeksStats(attendance_week_stats)
        setRecords(attendance)
        setTimeout(() => {
            setIsGenerated(true)
            setLoading(false)
        }, 500)
    }

    const getWeekDays = (week) => {
        return records.filter((record) => record.week === week).filter((item) => !isWeekendDay(item.day))
    }

    const isWeekendDay = (day) => {
        if (excludeWeekend === false) return false

        return [0, 6].includes(moment(day).day())
    }

    const countStat = (attribute) => {
        let total = 0
        for (let i = 0; i < weeksStats.length; i++) {
            const value = weeksStats[i][attribute]
            total += isNaN(value) || value === undefined || value === null || value === '' ? 0 : parseFloat(value)
        }

        return attribute === 'working_hours' ? parseFloat(total).toFixed(2) : total
    }

    const countWeekStat = (attribute, week) => {
        let total = 0
        const current_week_stats = weeksStats.find((item) => item.week === week)
        if (current_week_stats !== undefined) {
            const value = current_week_stats?.[attribute] || 0
            total = isNaN(value) || value === undefined || value === null || value === '' ? 0 : parseFloat(value)
            return attribute === 'working_hours' ? parseFloat(total).toFixed(2) : total
        }

        return total
    }

    const findDuplicatedRecords = (week) => {
        let total = 0
        const current_week_stats = weeksStats.find((item) => item.week === week)
        if (current_week_stats !== undefined) {
            return current_week_stats?.['duplicated_records'] || []
        }

        return total
    }

    return (
        <CanView permissions={['attendance.c_manage_all_records']} redirect>
            <Form>
                <Form.Group>
                    <SuperField
                        as="choice"
                        search
                        value={unit}
                        label={t('department')}
                        style={{ width: '200px' }}
                        loading={loadingContracts}
                        disabled={loadingContracts}
                        onChange={async (e, { value }) => {
                            setUnit(value)
                            await fetchContracts(value)
                        }}
                        help={t('select_a_department_to_retrieve_a_list_of_employees_in_that_department')}
                        customOptions={units.map((item) => ({
                            key: item.id,
                            value: item.id,
                            text: item.name,
                        }))}
                    />

                    <SuperField
                        as="choice"
                        search
                        required
                        label={t('employee')}
                        loading={loadingContracts}
                        disabled={loadingContracts}
                        value={employee}
                        customOptions={contracts.map((item) => ({
                            key: item.id,
                            value: item.id,
                            text: `${item.employee.fullname_with_titles}`,
                        }))}
                        onChange={(e, { value }) => {
                            if (value === '') setIsGenerated(false)
                            setEmployee(value)
                        }}
                    />
                    <SuperField
                        as="choice"
                        search
                        label={t('month')}
                        value={month}
                        clearable={false}
                        customOptions={monthList}
                        onChange={(e, { value }) => setMonth(value)}
                    />
                    <SuperField
                        as="yearpicker"
                        value={year}
                        label={t('year')}
                        style={{ width: '150px' }}
                        dateFormat="YYYY"
                        onChange={(e, { value }) => setYear(value)}
                    />

                    <Form.Field style={{ marginTop: '1.8rem' }}>
                        <Button
                            primary
                            type="button"
                            onClick={() => generateMonthlyAttendance()}
                            disabled={employee === '' || month === '' || year === ''}
                        >
                            {t('confirm')}
                        </Button>
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <SuperField
                        as="checkbox"
                        label={t('exclude_weekend')}
                        checked={excludeWeekend}
                        onChange={() => setExcludeWeekend(!excludeWeekend)}
                    />
                    <CanView permissions={['attendance.c_can_create_corrections']}>
                        {canPerformCorrections && (
                            <SuperField
                                as="checkbox"
                                label={t('show_corrected_records')}
                                checked={showCorrectedRecords}
                                onChange={() => setShowCorrectedRecords(!showCorrectedRecords)}
                            />
                        )}
                    </CanView>
                </Form.Group>
            </Form>
            <Divider />
            <Segment
                loading={loading}
                style={{
                    padding: 0,
                    background: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}
            >
                {loading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '6rem' }}>
                        {t('loading_monthly_attendance')}
                    </p>
                )}

                {!isGenerated && !loading && (
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '1rem', fontWeight: 'bold' }}>
                        {t('select_employee_to_generate_monthly_attendance')}
                    </p>
                )}

                {!loading && isGenerated && (
                    <Form>
                        <FlexRow>
                            <FlexItem fontSize="0.6rem">
                                <small>
                                    <strong style={{ fontSize: '1.2rem' }}>
                                        {' '}
                                        {monthList.find((item) => item.value === month)?.text || ''} {year}{' '}
                                    </strong>{' '}
                                    <br />
                                    <Icon name="calendar-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {moment(moment(year + '-' + month + '-01').startOf('month')).format(
                                            date_format
                                        )}
                                    </span>
                                    <Icon
                                        name="calendar-outline"
                                        style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                                    />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {moment(moment(year + '-' + month + '-01').endOf('month')).format(date_format)}
                                    </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>{t('total_days')}</strong> <br />
                                    <span> {records.filter((item) => !isWeekendDay(item.day)).length} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="40%" textAlign="center">
                                <small>
                                    <strong>{t('working_hours')}</strong> <br />
                                    <span> {countStat('working_hours')} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="70%" textAlign="center">
                                <small>
                                    <strong>
                                        {t('used_vacation')} / {t('vacation_status')}
                                    </strong>{' '}
                                    <br />
                                    <span>
                                        {' '}
                                        {countStat('vacation')} / {contract.available_vacation_fond || 0}{' '}
                                        {t('days_left').toLocaleLowerCase()}
                                    </span>
                                </small>
                            </FlexItem>
                            {viewVoucher && (
                                <FlexItem basis="30%" textAlign="center">
                                    <small>
                                        <strong>{t('lunch_voucher')}</strong> <br />
                                        <span> {countStat('lunch_voucher')} </span>
                                    </small>
                                </FlexItem>
                            )}
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>{t('absention')}</strong> <br />
                                    <span> {countStat('absention')} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>{t('paragraph')}</strong> <br />
                                    <span> {countStat('paragraph')} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>{t('family_member_paragraph')}</strong> <br />
                                    <span> {countStat('family_member_paragraph')} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>{t('sickday_shortcut')}</strong> <br />
                                    <span> {countStat('sick_day')} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="30%" textAlign="center">
                                <small>
                                    <strong>{t('care_for_a_family_member_shortcut')}</strong> <br />
                                    <span> {countStat('care_for_a_family_member')} </span>
                                </small>
                            </FlexItem>
                            <FlexItem basis="40%" textAlign="right">
                                <Dropdown direction="left" primary simple as={Button} text={t('action')}>
                                    <Dropdown.Menu style={{ borderRadius: '0' }}>
                                        <CanView
                                            permissions={[
                                                'payrolls.c_manage_all_bonus_payments',
                                                'payrolls.c_manage_user_bonus_payments',
                                            ]}
                                        >
                                            <SuperDuperModal
                                                header={t('assign_bonus')}
                                                trigger={<Dropdown.Item>{t('assign_bonus')}</Dropdown.Item>}
                                                content={
                                                    <AssignAttendanceBonusForm
                                                        monthList={monthList}
                                                        contract={contract}
                                                        month={month}
                                                        year={year}
                                                    />
                                                }
                                            />
                                        </CanView>
                                        {/* <SuperDuperModal
                                            header={t('longterm_work_absence')}
                                            size="tiny"
                                            trigger={<Dropdown.Item>{t('add_longterm_work_absence')}</Dropdown.Item>}
                                            content={
                                                <LongtermAbsenceForm
                                                    absenceTypes={absenceTypes}
                                                    employee={contract?.employee?.id}
                                                    records={weeksStats}
                                                    setRecords={setRecords}
                                                />
                                            }
                                        /> */}
                                        <CanView
                                            permissions={[
                                                'attendance.c_view_all_records',
                                                'attendance.c_can_manage_only_employees_as_responsible_person',
                                            ]}
                                        >
                                            <Dropdown.Item onClick={() => generateXLSXReport()}>
                                                {t('download_monthly_attendance_as_xlsx')}
                                            </Dropdown.Item>
                                        </CanView>
                                        <CanView
                                            permissions={[
                                                'attendance.c_view_all_records',
                                                'attendance.c_can_manage_only_employees_as_responsible_person',
                                            ]}
                                        >
                                            <Dropdown.Item onClick={() => generatePDFReport()}>
                                                {t('download_monthly_attendance_as_pdf')}
                                            </Dropdown.Item>
                                        </CanView>
                                        <CanView
                                            permissions={[
                                                'attendance.c_view_all_records',
                                                'attendance.c_can_manage_only_employees_as_responsible_person',
                                            ]}
                                        >
                                            <Dropdown.Item onClick={() => generateXLSXAbsenceReport()}>
                                                {t('download_monthly_absences_as_xlsx')}
                                            </Dropdown.Item>
                                        </CanView>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </FlexItem>
                        </FlexRow>
                        <Divider />
                        {weeks.map((week, idx) => (
                            <FlexRowWithToggle
                                background={'var(--light)'}
                                key={idx}
                                loading={false}
                                isOpen={moment().isoWeek() === week}
                                rowContent={
                                    <>
                                        <FlexItem fontSize="0.6rem">
                                            <small>
                                                <strong>
                                                    {t('week')} {week}:{' '}
                                                </strong>
                                                <Icon
                                                    name="calendar-outline"
                                                    style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                                                />
                                                <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                    {moment(getWeekDays(week)?.[0]?.day).format(date_format)}
                                                </span>
                                                <Icon
                                                    name="calendar-outline"
                                                    style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                                                />
                                                <span style={{ position: 'relative', top: '-0.1rem' }}>
                                                    {moment(
                                                        getWeekDays(week)?.[getWeekDays(week).length - 1]?.day
                                                    ).format(date_format)}
                                                </span>
                                                {(countWeekStat('require_manual_corrections', week) > 0 ||
                                                    findDuplicatedRecords(week).length > 0) && (
                                                    <Popup
                                                        position="right center"
                                                        trigger={
                                                            <Icon
                                                                name="alert-circle"
                                                                style={{
                                                                    color: 'var(--danger)',
                                                                    fontSize: '1.5rem',
                                                                    marginLeft: '1rem',
                                                                    position: 'relative',
                                                                    top: '0.2rem',
                                                                }}
                                                            />
                                                        }
                                                        content={t(
                                                            'week_contains_records_that_require_manual_correction'
                                                        )}
                                                    />
                                                )}
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('total_days')}</strong> <br />
                                                <span>
                                                    {' '}
                                                    {
                                                        records
                                                            .filter((record) => record.week === week)
                                                            .filter((item) => !isWeekendDay(item.day)).length
                                                    }{' '}
                                                </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('working_hours')}</strong> <br />
                                                <span> {countWeekStat('working_hours', week)} </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('vacation')}</strong> <br />
                                                <span> {countWeekStat('vacation', week)} </span>
                                            </small>
                                        </FlexItem>
                                        {viewVoucher && (
                                            <FlexItem basis="30%" textAlign="center">
                                                <small>
                                                    <strong>{t('lunch_voucher')}</strong> <br />
                                                    <span> {countWeekStat('lunch_voucher', week)} </span>
                                                </small>
                                            </FlexItem>
                                        )}
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('absention')}</strong> <br />
                                                <span> {countWeekStat('absention', week)} </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('paragraph')}</strong> <br />
                                                <span> {countWeekStat('paragraph', week)} </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('family_member_paragraph')}</strong> <br />
                                                <span> {countWeekStat('family_member_paragraph', week)} </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('sickday_shortcut')}</strong> <br />
                                                <span> {countWeekStat('sick_day', week)} </span>
                                            </small>
                                        </FlexItem>
                                        <FlexItem basis="30%" textAlign="center">
                                            <small>
                                                <strong>{t('care_for_a_family_member_shortcut')}</strong> <br />
                                                <span> {countWeekStat('care_for_a_family_member', week)} </span>
                                            </small>
                                        </FlexItem>
                                    </>
                                }
                                content={
                                    <WeekManagement
                                        key={idx}
                                        week={week}
                                        weekDates={records.filter((item) => item.week === week)}
                                        employee={employee}
                                        contract={contract}
                                        setContracts={setContracts}
                                        excludeWeekend={excludeWeekend}
                                        absenceTypes={absenceTypes}
                                        viewVoucher={viewVoucher}
                                        viewSecondBreak={viewSecondBreak}
                                        setWeeksStats={setWeeksStats}
                                        loadingShifts={loadingShifts}
                                        interruptionReasons={interruptionReasons}
                                        showCorrectedRecords={showCorrectedRecords}
                                        holidays={holidays}
                                        shifts={shifts}
                                    />
                                }
                            />
                        ))}
                    </Form>
                )}
            </Segment>
        </CanView>
    )
}

export default ManageEmployee
