import React, { Fragment, useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import UserAvatar from 'react-user-avatar'
import { Popup, Form, Button } from 'semantic-ui-react'
import ConfirmModal from '@components/modals/ConfrimModal'
import SuperField from '@components/forms/SuperField'
import { FlexTable, FlexHeader, FlexRow, FlexItem } from '@components/tables/FlexTable'
import CanView from '@components/perms/CanView';


const TaskTable = ({ tasks, setTasks }) => {
    const { t } = useTranslation()
    const [openedForm, setOpenedForm] = useState("")

    const onDelete = async (id) => {
        const request = await requests.del(API.ONBOARDING_ITEMS_CONFIG + id + "/")
        if( request.status === 204 ){
            setTasks(prevState => prevState.filter(item => item.id !== id))
        }
    }

    const TaskForm = ({ task, setTasks, visible, hideForm }) => {
        const { t } = useTranslation();
        const [title, setTitle] = useState(task ? task?.title : "")
        const [assignedTo, setAssignedTo] = useState(task ? task?.assigned_to.map(item => item.id) : "")
        const [processing, setProcessing] = useState(false)

        const onUpdate = async () => {
            setProcessing(true)
            const request = await requests.put(API.ONBOARDING_ITEMS_CONFIG + task.id + "/", {
                title: title,
                assigned_to: assignedTo
            })

            let employeesResult = []
            for (let i = 0; i < request.response.assigned_to.length; i++) {
                const employeeRequest = await requests.get(API.EMPLOYEES + request.response.assigned_to[i] + "/?query={id, fullname, profile_picture}")

                if( employeeRequest.status === 200 ){
                    employeesResult.push({
                        id: employeeRequest.response.id,
                        fullname: employeeRequest.response.fullname,
                        profile_picture: employeeRequest.response.profile_picture
                    })
                }
            }

            if( request.status === 200 ){
                setTasks(prevState => prevState.filter( (taskItem) => {
                    if( taskItem.id === task.id ){
                        taskItem.title = request.response.title
                        taskItem.assigned_to = employeesResult
                    }

                    return taskItem
                }))
                hideForm()
            }
            setProcessing(false)
        }

        return (
            <>
                { visible &&
                    <Form style={{ marginTop: "1rem" }}>
                        <Form.Group>
                            <SuperField as="input"
                                fluid
                                autoFocus
                                width={10}
                                value={title}
                                label={ t('title') }
                                onChange={ (e) => setTitle(e.target.value) }
                            />
                            <SuperField as="choice"
                                label={ t('assign_person') }
                                endpoint={API.GET_EMPLOYEES + "?query={id, fullname}"}
                                multiple
                                search
                                width={10}
                                text="fullname"
                                value={assignedTo}
                                onChange={(e, { value }) => setAssignedTo(value)}
                            />
                            <Form.Field width={6}>
                                <Button type="button" onClick={ () => onUpdate() } disabled={processing || title === ""} loading={processing} style={{ width: "100%", marginTop: "1.6rem" }} size="small" primary icon="plus" content={ t('save') }/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                }
            </>
        )
    }

    return (
        <FlexTable>
            <FlexRow borders fontSize="0.9rem">
                <FlexHeader content={t('task')}/>
                <FlexHeader content={t('assignee')}/>
                <CanView permissions={["onboarding.c_manage_all_checklist_configs"]}>
                    <FlexHeader basis="20%" content={t('actions')}/>
                </CanView>
            </FlexRow>

            { tasks.length === 0 && <FlexRow fontSize="0.9rem"> <FlexItem textAlign="center"> { t('no_task_assigned') } </FlexItem> </FlexRow> }

            {tasks?.map((item, index) => (
            <Fragment key={item.id}>
                <TaskForm
                    task={item}
                    setTasks={setTasks}
                    visible={ openedForm === "form_" + item.id }
                    hideForm={ () => setOpenedForm("") }
                />
                { openedForm !== "form_" + item.id &&
                    <FlexRow borders fontSize="0.9rem" key={item.id}>
                        <FlexItem bold content={item.title}/>
                        <FlexItem>
                            <div style={{ display: "flex", flexDirection: "row"}}>
                                {item.assigned_to.map(assignee => (
                                    <Popup
                                        style={{ cursor: "pointer"}}
                                        position='top center'
                                        trigger={
                                            <div style={{ cursor: "pointer" }}>
                                                <UserAvatar
                                                    key={index}
                                                    style={{ paddingLeft: '0rem', fontSize: 30 / 2.5 + "px" }}
                                                    size={30}
                                                    name={assignee?.fullname || "Something"}
                                                    color='var(--variant5)'
                                                    src={assignee?.profile_picture ? assignee?.profile_picture : ''}
                                                />
                                            </div>
                                        }
                                        content={ assignee?.fullname }
                                    />
                                ))}
                                </div>
                        </FlexItem>
                        <CanView permissions={["onboarding.c_manage_all_checklist_configs"]}>
                            <FlexItem basis="20%">
                                <FlexRow padding="0" background="transparent">
                                    <FlexItem>
                                        <Icon
                                            name="pencil-outline"
                                            style={{ color: "var(--dark)", cursor: "pointer" }}
                                            onClick={ () => setOpenedForm("form_" + item.id) }
                                        />
                                    </FlexItem>
                                    <FlexItem>
                                        <ConfirmModal
                                            description={t('are_you_sure_onboarding_task')}
                                            onConfirm={() => onDelete(item.id)}
                                            button={
                                                <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }}/>
                                            }
                                        />
                                    </FlexItem>
                                </FlexRow>
                            </FlexItem>
                        </CanView>
                    </FlexRow>
                }
            </Fragment>
            ))}
        </FlexTable>
    )
}

export default TaskTable
