import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { getRequest, deleteRequest } from '@services/ServiceCommon';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
import Loading from '@components/general/Loading';
import EmptyRow from '@components/tables/EmptyRow';
import BreadcrumbNav from '@components/BreadcrumbNav';
import ConfrimModal from '@components/modals/ConfrimModal';
import AvatarName from '@components/general/AvatarWithName';
import SuperDuperModal from "@components/modals/SuperDuperModal"
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import { Dropdown, Grid, Divider, Statistic, Header, Container, Label } from 'semantic-ui-react';
// module specific
import MemberForm from "../components/forms/MemberForm";
import TeamForm from '../components/forms/TeamForm';

const TeamsDetail = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const params = useParams(); // for getting URL parameters like ":id" that represent team ID

    const [team, setTeam] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [dataMembers, setDataMembers] = useState([]);
    const [stats, setStats] = useState({});

    const actions = [
        {
            component:
                <CanView permissions={["teams.c_manage_user_teams", "teams.c_manage_all_teams"]}>
                    <SuperDuperModal
                        trigger={
                            <OutlineAddButton color="violet" size="small" text={t('add_member')} />
                        }
                        size="small"
                        header={t('add_member')}
                        content={
                            <MemberForm
                                btnName={t('add_member')}
                                onConfirm={(response) => onCreate(response)}
                            />
                        }
                    />
                </CanView>
        },
        {
            component:
                <CanView permissions={["teams.c_manage_user_teams", "teams.c_manage_all_teams"]}>
                    <Dropdown style={{ backgroundColor: "transparent" }}
                        icon='ellipsis vertical'
                        button
                        simple
                        direction="left"
                        openOnFocus
                        className='icon'

                    >
                        <Dropdown.Menu>
                            <SuperDuperModal
                                trigger={
                                    <Dropdown.Item>
                                        <div>
                                            <Icon name="pencil-outline" style={{ marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                {t('update_team')}
                                            </span>
                                        </div>
                                    </Dropdown.Item>
                                }
                                size="small"
                                header={t('update_team')}
                                content={
                                    <TeamForm
                                        btnName={t('update')}
                                        team={team}
                                        onConfirm={(response) => onUpdate(response)}
                                    />
                                }
                            />
                            <CanView permissions={["teams.c_delete_all_teams"]}>
                                <ConfrimModal
                                    onConfirm={() => onDelete(team.id)} // function for deletion
                                    button={
                                        <Dropdown.Item>
                                            <div>
                                                <Icon style={{ color: "var(--danger)", marginRight: "0.5rem" }} name="close-outline" />
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    {t('delete')}
                                                </span>
                                            </div>
                                        </Dropdown.Item>
                                    }
                                    description={t('confrim_message_delete')}
                                />
                            </CanView>
                        </Dropdown.Menu>
                    </Dropdown>
                </CanView>
        }
    ];

    async function fetchTeamData() {
        const request = await getRequest(API.TEAMS + params.id + "/");

        if (request.status === 200) {
            setTeam(request.response);
        }

        const stats = await getRequest(API.TEAMS_STATS + params.id + "/stats/");
        if (stats.status === 200) {
            setStats(stats.response);
        }
    }

    async function fetchTeamMembers() {
        const members = await getRequest(API.TEAMMEMBERS + "?team=" + params.id);;
        if (members.status === 200) {
            const sortedMembers = members.response.sort((a, b) => a.is_leader > b.is_leader ? - 1 : Number(a.is_leader < b.is_leader))
            setDataMembers(sortedMembers);
        }
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            await fetchTeamData()
            await fetchTeamMembers()
            setIsLoading(false)
        }

        fetchData();
        // eslint-disable-next-line
    }, [params, history]);

    const onUpdate = (response) => {
        setTeam(response);
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.TEAMS + id + "/");
        if (request.status === 204) {
            history.push(routes.TEAMS_LIST)
        }
    };

    const onCreate = (response) => {
        let currentState = dataMembers
        if (response.is_leader) {
            currentState = currentState.filter(item => {
                item.is_leader = false
                return item
            })
        }

        setDataMembers([response, ...currentState].sort((a, b) => a.is_leader > b.is_leader ? - 1 : Number(a.is_leader < b.is_leader)));
    };

    const onUpdateMember = (response) => {
        setDataMembers(prevState => {
            let data = prevState.filter((item) => {
                if (item.id === response.id) {
                    item.is_leader = response.is_leader;
                    item.position = response.position;
                    item.profile = response.profile
                } else {
                    item.is_leader = false
                }

                return item;
            })

            return data.sort((a, b) => a.is_leader > b.is_leader ? - 1 : Number(a.is_leader < b.is_leader))
        });
    };

    async function onDeleteMember(id) {
        const request = await requests.del(API.TEAMMEMBERS + id + "/");
        if (request.status === 204) {
            setDataMembers(prevState => prevState.filter(item => item.id !== id));
        }
    };

    return (
        <CanView permissions={["teams.c_view_user_teams", "teams.c_view_all_teams"]} redirect>
            <BreadcrumbNav
                actions={actions}
                items={[
                    { 'name': t('teams'), 'icon': 'users', 'href': routes.TEAMS_LIST },
                    { 'name': team.name ? team.name : "", 'icon': 'users' }
                ]}
            />

            {isLoading ? <Loading /> :
                <Container fluid>
                    <Grid style={{ margin: "1rem" }}>
                        <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                            <Grid.Column computer={6} table={16} mobile={16}>
                                <Header as='h1' icon textAlign='center'>
                                    <Icon name='people-circle-outline' style={{ fontSize: "5rem" }} /><br />
                                    {team.name}
                                    <Header.Subheader>{team.description}</Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column computer={8} table={16} mobile={16}>
                                <Statistic.Group widths={3}>
                                    <Statistic label={t('total_members')} value={dataMembers.length} />
                                    <Statistic label={t('active_projects')} value={stats.project_count || 0} />
                                    <Statistic label={t('leader_assigned')} value={(dataMembers.some(member => member.is_leader === true)) ? t('yes') : t('no')} />
                                </Statistic.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider />

                    <CanView permissions={["teams.c_view_all_teams", "teams.c_view_user_teams"]}>
                        <FlexTable>
                            <FlexRow borders>
                                <FlexHeader content={t('name')} basis="150%" />
                                <FlexHeader content={t('position')} />
                                <FlexHeader content={t('email')} />
                                <FlexHeader content={t('phone')} />
                                <CanView permissions={["teams.c_manage_all_teams", "teams.c_manage_user_teams"]}>
                                    <FlexHeader content={t('actions')} basis="30%" />
                                </CanView>
                            </FlexRow>
                            <EmptyRow length={dataMembers.length} />
                            {dataMembers.map(member => (
                                <FlexRow padding="0.5rem" fontSize="0.9rem" background="transparent" border>
                                    <FlexItem basis="150%">
                                        <Link to={routes.EMPLYOEES_DETAIL + member.profile.id} target="_blank" style={{ fontWeight: "bold" }}>
                                            <AvatarName name={member.profile.fullname} avatar={member?.profile?.profile_picture || ""} className='team-member' />
                                        </Link>
                                    </FlexItem>
                                    <FlexItem>
                                        {member.is_leader && <Label size="tiny" color="purple">{t('leader')}</Label>}
                                        <Label size="tiny" color="orange">{member.position}</Label>
                                    </FlexItem>
                                    <FlexItem>
                                        {member?.profile?.personal_information?.contact_info?.emails[0]?.email
                                            ?
                                            <a href={`mailto:${member?.profile?.personal_information?.contact_info?.emails[0]?.email}`}>
                                                {member?.profile?.personal_information?.contact_info?.emails[0]?.email}
                                            </a>
                                            : "--"
                                        }
                                    </FlexItem>
                                    <FlexItem>
                                        {member?.profile?.personal_information?.contact_info?.phones[0]?.phone
                                            ?
                                            <a href={`tel:${member?.profile?.personal_information?.contact_info?.phones[0]?.phone}`}>
                                                {member?.profile?.personal_information?.contact_info?.phones[0]?.phone}
                                            </a>
                                            : "--"
                                        }
                                    </FlexItem>
                                    <CanView permissions={["teams.c_manage_all_teams", "teams.c_manage_user_teams"]}>
                                        <FlexItem basis="30%">
                                            <FlexRow background="transparent" padding="0">
                                                <FlexItem>
                                                    <Action
                                                        as="modal"
                                                        type="icon"
                                                        icon="pencil"
                                                        size="small"
                                                        tooltip={t('edit')}
                                                        modal={
                                                            <MemberForm
                                                                memberId={member.id}
                                                                btnName={t('update')}
                                                                onConfirm={onUpdateMember}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                                <FlexItem>
                                                    <Action
                                                        as='delete'
                                                        tooltip={t('delete')}
                                                        text={t('confrim_message_delete_member')}
                                                        onClick={() => onDeleteMember(member.id)}
                                                    />
                                                </FlexItem>
                                            </FlexRow>
                                        </FlexItem>
                                    </CanView>
                                </FlexRow>
                            ))}
                        </FlexTable>
                    </CanView>
                </Container>
            }
        </CanView>
    );

}

export default TeamsDetail;