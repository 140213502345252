import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { isEmpty, isPasswordError, isRepeatPasswordError } from '@helpers/validation';
import { requests } from '@helpers/requests';
// components
import { Form, Grid, Message, Header } from "semantic-ui-react";

// component
function PasswordForm({ user }) {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState("");
    const [reNewPassword, setReNewPassword] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState(null)
    const [view, setView] = useState(1)

    const notifyUser = (user) => {
        return (
            <>
                <br />
                <div><b>{t('username')}: </b>{user.username}</div>
                <div><b>{t('email')}: </b>{user.email}</div>
                <div><b>{t('new_pass')}: </b>{newPassword}</div>
                <br />
                <div><b>{t('notify_user')}</b></div>
            </>
        )
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;

        if (
            isPasswordError(newPassword) ||
            isRepeatPasswordError(newPassword, reNewPassword)
        ) {
            formError = true;
        }

        if (!formError) {
            const data = {
                new_password: newPassword,
                re_new_password: reNewPassword
            };

            let request = await requests.put(API.USERS + user.id + '/set_user_password/', data)

            if (request.status === 204) {
                setView(2)
            } else {
                setErrors(request.response)
            }
        }

        setIsProcessing(false);
    };

    return (
        <Grid centered>
            <Grid.Column style={{ width: '100%' }}>
                {
                    view === 1
                        ?
                        <Form onSubmit={handleSubmit}>
                            <Message
                                error
                                visible={errors?.new_password?.length > 0}
                                list={errors?.new_password || []}
                            />
                            <>
                                <Header as='h1' style={{ textAlign: "center", marginTop: 0 }} content={t('password_change')} />
                                <Form.Field fluid='true'>
                                    <Form.Input
                                        type="password"
                                        icon="lock"
                                        iconPosition="left"
                                        required
                                        autoFocus
                                        label={t('new_pass')}
                                        placeholder={t('new_pass')}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        error={
                                            !isEmpty(newPassword)
                                                ? isPasswordError(newPassword)
                                                : false
                                        }
                                    />
                                </Form.Field>

                                <Form.Field fluid='true'>
                                    <Form.Input
                                        type="password"
                                        icon="lock"
                                        iconPosition="left"
                                        required
                                        label={t('repeat_pass')}
                                        placeholder={t('repeat_pass')}
                                        value={reNewPassword}
                                        onChange={(e) =>
                                            setReNewPassword(e.target.value)
                                        }
                                        error={
                                            !isEmpty(reNewPassword)
                                                ? isRepeatPasswordError(
                                                    newPassword,
                                                    reNewPassword
                                                )
                                                : false
                                        }
                                    />
                                </Form.Field>

                                <Form.Button
                                    fluid primary
                                    size="huge"
                                    type="submit"
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        isEmpty(newPassword) ||
                                        isEmpty(reNewPassword) ||
                                        isPasswordError(newPassword) ||
                                        isRepeatPasswordError(newPassword, reNewPassword)
                                    }
                                >
                                    {t('reset_pass')}
                                </Form.Button>
                            </>
                        </Form>
                        :
                        <Message
                            header={t('password_changed')}
                            content={notifyUser(user)}
                        />
                }
            </Grid.Column>
        </Grid>
    );
}

export default PasswordForm;