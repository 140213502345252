import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next"
// store
import { companyService } from '@store/services/ServiceCompany';
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Container, Tab, Button } from 'semantic-ui-react'
import CompanyHeader from '../components/company/CompanyHeader'
import Loading from '@components/general/Loading';
import CompanyDetailUnits from '../components/company/units-tab/CompanyDetailUnits'
import { CompanyForm } from "@components/common/CompanyForm";
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';
import DetailView from '../components/company/home-tab/DetailView';
import CertificatesList from '../components/company/certificates-tab/CertificatesList';


const CompanyDetail = ({ company }) => {
    const { t } = useTranslation();

    const [id] = useState(company.id);
    const [data, setData] = useState({});
    const [unitData, setUnitData] = useState({});
    const [stats, setStats] = useState({});
    const [logo, setLogo] = useState({});

    const canViewHomeTab = useHasPermissions(['company.c_view_active_company', 'company.c_view_inactive_companies']);
    const canViewOrganisationTab = useHasPermissions(['company.c_view_company_tree']);
    const canViewCertificatesTab = useHasPermissions(['company.c_view_certificates']);

    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory()
    
    async function fetchData() {
        setData(company);
        const resultStats = await companyService.getCompanyStats(id);
        if (resultStats.status === 200) {
            setStats(resultStats.response);
        }

        const resultLogo = await companyService.getLogo(id);
        if (resultLogo.status === 200) {
            setLogo(resultLogo.response);
        }

        const result = await companyService.getTree(id);
        if (result.status === 200) {
            setUnitData(result.response);     
        }

        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [id, history, company]);

    const action = [
        <CanView permissions={["company.c_change_active_company", "company.c_change_inactive_companies"]}>
            <SuperDuperModal
                key={id}
                trigger={
                    <Button style={{ backgroundColor: "transparent", paddingLeft: "2rem" }} icon> <Icon name="pencil-outline" /></Button>
                }
                size="large"
                header={t('update_company')}
                content={
                    <CompanyForm
                        btnName="Update"
                        data={data}
                        onConfirm={(updated_item) => setData(updated_item)}
                    />
                }
            />
        </CanView>

    ];
    const actionAddWebsite = [
        <CanView permissions={["company.c_change_active_company", "company.c_change_inactive_companies"]}>
            <SuperDuperModal
                key={id + 1}
                trigger={
                    <Button icon='globe' as='a' target="_blank" size="small" content={t('add_website')} />

                }
                size="large"
                header={t('update_company')}
                content={
                    <CompanyForm
                        btnName="Update"
                        data={data}
                        onConfirm={(updated_item) => setData(updated_item)}
                    />
                }
            />
        </CanView>
    ];

    const panes = [
        {
            menuItem: canViewHomeTab && t('home'),
            render: () =>
                <CanView permissions={["company.c_view_active_company", "company.c_view_inactive_companies"]}>
                    <Tab.Pane>
                        <DetailView company={data} setCompany={setData} />
                    </Tab.Pane>
                </CanView>
        },
        {
            menuItem: canViewOrganisationTab && t('my_organisation'),
            render: () =>
                <CanView permissions={["company.c_view_company_tree"]}>
                    <Tab.Pane><CompanyDetailUnits unitData={unitData} fetchData={fetchData} /></Tab.Pane>
                </CanView>
        },
        {
            menuItem: canViewCertificatesTab && t('certificates'),
            render: () =>
                <CanView permissions={["company.c_view_certificates"]}>
                    <Tab.Pane><CertificatesList id={id} unitData={unitData} /></Tab.Pane>
                </CanView>
        },
        // {
        //     menuItem: canViewBankTab && t('bank_tab'),
        //     render: () =>
        //         <CanView permissions={["company.c_view_company_bankaccounts"]}>
        //             <Tab.Pane >{businessDetail?.id ? <DetailBankAccounts isManageable={isManagableBankAddress} businessDetailId={businessDetail?.id} /> : <p style={{ textAlign: "center" }}>{t('no_data')}</p>} </Tab.Pane>
        //         </CanView>
        // },
        // { menuItem: t('payroll_tab'),
        //  render: () =>
        //     <Tab.Pane><CompanyDetailPayroll /></Tab.Pane> },
        // { menuItem: canViewHomeTab && t('settings_tab'),
        //  render: () =>
        //  <CanView permissions={["company.c_view_active_company", "company.c_view_inactive_companies"]}>
        //     <Tab.Pane><CompanyDetailManagement data={data} /></Tab.Pane>
        //  </CanView>
        // },
    ]

    // This is center point for Organisation Detail Page,d
    return (
        <CanView permissions={["company.c_view_active_company", "company.c_view_inactive_companies"]}>
            <BreadcrumbNav items={[
                { 'name': data.name, 'icon': '', 'href': '' }
            ]} />
            {isLoading ? <Loading /> :
                <Container fluid>
                    <CompanyHeader stats={stats} data={data} logo={logo} action={action} actionAddWebsite={actionAddWebsite} />
                    <Tab panes={panes} />
                </Container>
            }
        </CanView>
    );
};

export default CompanyDetail;