import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// store
import { routes } from '@routes';
// components
import SuperField from '@components/forms/SuperField';

const GDPRField = ({ gdpr, checked, setChecked }) => {
    const { t } = useTranslation();

    return (
        <SuperField as="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
            label={
                {
                    children: (
                        <p>
                            { t('gdpr_message_1') }
                            <Link
                                to={ routes.GDPRS + gdpr.id }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                { gdpr.title }
                            </Link>
                            .
                            { gdpr.is_required && <sup style={{ color: "var(--danger)" }}>*</sup> }
                        </p>
                    ),
                }
            }

        />
    );
};

export default GDPRField;