import React, { useState } from "react";
// store
import { resetPasswordService } from "../../../store/services/ServiceResetPassword";
import { isEmpty, isEmailError } from "../../../store/services/helpers/validation";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../store/routes/index";

// styles
import { 
    Container,
    Grid,
    Segment, 
    Image, 
    Form
} from "semantic-ui-react";

// component
function ResetPasswordForm(props) {
    // state
    const [email, setEmail] = useState(props.email);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;
        if (isEmailError(email)) {
            formError = true;
        }

        if (!formError) {
            // send request for reset password
            if (await resetPasswordService.requestResetPassword(email)) {
                alert("Žiadosť bola odoslaná");
                setEmail("");
            } else {
                alert("Žiadosť sa nepodarilo odoslať");
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Container>
            <Grid centered>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Form onSubmit={handleSubmit}>
                        <Segment>
                            <Link to={routes.HOME}>
                                <Image centered src="/images/logo.svg" width="40%"/>
                            </Link>
                            <h1 style={{textAlign: "center"}}>Request password reset</h1>
                            <Form.Group>
                                <Form.Input
                                    icon="mail"
                                    iconPosition="left"
                                    fluid
                                    width={16}
                                    size="medium"
                                    required
                                    autoFocus
                                    label="Email"
                                    placeholder="Enter valid email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!isEmpty(email) ? isEmailError(email) : false}
                                />
                            </Form.Group>

                            <Form.Button
                                fluid primary
                                size="huge"
                                type="submit"
                                loading={isProcessing}
                                disabled={isProcessing || isEmpty(email)}
                            >
                                Odoslať žiadosť na obnovenie hesla
                            </Form.Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default connect()(ResetPasswordForm);
