import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { requests } from '@helpers/requests'
import { useHasPermissions } from '@helpers/hooks'
import { dates } from '@store/services/helpers/dates'
// components
import Icon from '@components/Icon'
import ListView from '@components/ListView'
import CanView from '@components/perms/CanView'
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'
import {
    ProjectApproval,
    BudgetApproval,
    InvoiceApproval,
    InvoiceApprover,
    RecruitmentStageApproval,
    RecruitmentApproval,
    PlannedCourseApproval,
    TimesheetApproval,
    BonusPaymentApproval,
    AdvancePaymentApproval,
    DeductionPaymentApproval,
    ConfirmForm,
    // onPerformAction
} from '../components/Approvals'

const AwaitingApprovals = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)
    const user = useSelector((state) => state.user)

    const getResourceName = (name) => {
        if (name === 'RecruitmentApproval') return t('recruitment')
        if (name === 'PlannedCourseApproval') return t('planned_course')
        if (name === 'InvoiceApproval') return t('invoice')
        if (name === 'AttendanceRecordPlannerApproval') return t('attendance_record_planner')
        if (name === 'BenefitApproval') return t('benefits')
        if (name === 'CostCenterRecordApproval') return t('cost_center_record')
        if (name === 'EventAbsenceApproval') return t('event_absence')
        if (name === 'ProjectApproval') return t('projects')
        if (name === 'RecruitmentStageApproval') return t('recruitment_stage')
        if (name === 'TimesheetApproval') return t('timesheet')
        if (name === 'BonusPaymentApproval') return t('payroll__bonus')
        if (name === 'AdvancePaymentApproval') return t('payroll__advance')
        if (name === 'DeductionPaymentApproval') return t('payroll__deduction')
    }

    let stateOptions = [
        { key: 1, text: t('invoices'), value: 'invoice' },
        { key: 2, text: t('budget'), value: 'budget' },
        { key: 3, text: t('planned_courses'), value: 'plannedcourse' },
        { key: 4, text: t('projects'), value: 'project' },
        { key: 5, text: t('recruitments'), value: 'recruitment' },
        { key: 6, text: t('recruitments_stage_move'), value: 'recruitmentstage' },
        { key: 7, text: t('payroll__bonus'), value: 'bonuspayment' },
        { key: 8, text: t('payroll__advance'), value: 'advancepayment' },
        { key: 9, text: t('payroll__deduction'), value: 'deductionspayment' },
    ]

    const hasPermToManageAll = useHasPermissions(['approvals.c_manage_all_approvals'])

    const onSingleApprover = async (status, approval, setData, setTotal, note) => {
        note = note || ''
        const request = await requests.patch(API.APPROVALS + approval.id + '/', {
            is_approved: status,
            rejection_reason: note,
        })

        if (request.status === 200) {
            setTotal((prev) => prev - 1)
            setData((prev) => prev.filter((data) => data.id !== approval.id))

            toast({
                type: 'success',
                icon: 'check circle',
                title: status ? t('approved') : t('denied'),
                description: '#' + approval.id,
                animation: 'bounce',
                time: 5000,
            })
        } else if (request.status === 400) {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: 'error',
                description: '',
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const onInvoiceApproval = async (status, approval, setData, setTotal, note) => {
        let approvalData = {}
        let profile = user.profile

        if (profile === undefined) {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('invalid_user'),
                description: '',
                animation: 'bounce',
                time: 5000,
            })
            return
        }

        if (profile?.id === approval.responsible_person?.id) {
            approvalData['approved_by_responsible_person'] = status
            if (note !== undefined) {
                approvalData['responsible_person_note'] = note
            }
        }
        if (profile?.id === approval.second_responsible_person?.id) {
            approvalData['approved_by_second_responsible_person'] = status
            if (note !== undefined) {
                approvalData['second_responsible_person_note'] = note
            }
        }
        if (profile?.id === approval.reviewer?.id) {
            approvalData['approved_by_reviewer'] = status
            if (note !== undefined) {
                approvalData['reviewer_note'] = note
            }
        }
        if (profile?.id === approval.second_reviewer?.id) {
            approvalData['approved_by_second_reviewer'] = status
            if (note !== undefined) {
                approvalData['second_reviewer_note'] = note
            }
        }

        const request = await requests.patch(API.APPROVALS + 'invoices/' + approval.id + '/', approvalData)

        if (request.status === 200) {
            const approval_status = request.response?.is_approved
            const approval_date = request.response?.approval_datetime || null
            if (approval_status === true || approval_status === false) {
                const connectionRequest = await requests.get(API.INVOICES + 'invoice_connections/')
                if (connectionRequest.status === 200) {
                    const invoice_id = approval.invoice_reference.split(';')[1]
                    let connection = connectionRequest?.response?.[0]
                    await requests.patch(API.INVOICES + invoice_id + '/?connection=' + connection.connection_id, {
                        approval: approval_status === true ? 1 : 3,
                        date_approved: approval_status === true ? approval_date : null,
                    })

                    // if is_approved is set to TRUE, try to change status from Draft to Pending
                    if (approval_status === true) {
                        await requests.patch(API.INVOICES + invoice_id + '/?connection=' + connection.connection_id, {
                            status: 1,
                        })
                    }
                }
            }

            if ([true, false].includes(approval_status)) {
                setTotal((prev) => prev - 1)
                setData((prev) => prev.filter((data) => data.id !== approval.id))
            } else {
                setData((prev) =>
                    prev.filter((item) => {
                        if (item.id === approval.id) {
                            item.is_approved = request.response.is_approved
                            item.automatic_approval = request.response.automatic_approval
                            item.approved_by_responsible_person = request.response.approved_by_responsible_person
                            item.approved_by_second_responsible_person =
                                request.response.approved_by_second_responsible_person
                            item.approved_by_reviewer = request.response.approved_by_reviewer
                            item.approved_by_second_reviewer = request.response.approved_by_second_reviewer
                            item.responsible_person_note = request.response.responsible_person_note
                            item.second_responsible_person_note = request.response.second_responsible_person_note
                            item.reviewer_note = request.response.reviewer_note
                            item.second_reviewer_note = request.response.second_reviewer_note
                            item.responsible_person = request.response.responsible_person
                            item.second_responsible_person = request.response.second_responsible_person
                            item.reviewer = request.response.reviewer
                            item.second_reviewer = request.response.second_reviewer
                        }

                        return item
                    })
                )
            }

            toast({
                type: 'success',
                icon: 'check circle',
                title: status ? t('approved') : t('denied'),
                description: '#' + approval.id,
                animation: 'bounce',
                time: 5000,
            })
        } else if (request.status === 400) {
            toast({
                type: 'error',
                icon: 'warning circle',
                title: t('error') + ' - #' + approval.id,
                description: request.response?.non_field_errors?.[0] || '',
                animation: 'bounce',
                time: 5000,
            })
        }
    }

    const isConfirmed = (approval, status) => {
        if (approval.resourcetype === 'InvoiceApproval') {
            if (
                user.profile_id === approval?.responsible_person?.id &&
                approval?.approved_by_responsible_person === status
            ) {
                return true
            }

            if (
                user.profile_id === approval?.second_responsible_person?.id &&
                approval?.approved_by_second_responsible_person === status
            ) {
                return true
            }

            if (user.profile_id === approval?.reviewer?.id && approval?.approved_by_reviewer === status) {
                return true
            }

            if (user.profile_id === approval?.second_reviewer?.id && approval?.approved_by_second_reviewer === status) {
                return true
            }
        } else {
            return approval.is_approved === status
        }
    }

    const ConfirmBulkForm = ({ onClose, selected, confirmType, setData, setTotal }) => {
        const { t } = useTranslation()
        const [isProcessing, setIsProcessing] = useState(false)
        const [note, setNote] = useState('')

        const handleSubmit = async () => {
            setIsProcessing(true)
            for (let i = 0; i < selected.length; i++) {
                if (confirmType === 'approve') {
                    if (selected[i].resourcetype === 'InvoiceApproval') {
                        await onInvoiceApproval(true, selected[i], setData, setTotal, note)
                    } else {
                        await onSingleApprover(true, selected[i], setData, setTotal)
                    }
                } else {
                    if (selected[i].resourcetype === 'InvoiceApproval') {
                        await onInvoiceApproval(false, selected[i], setData, setTotal, note)
                    } else {
                        await onSingleApprover(false, selected[i], setData, setTotal)
                    }
                }
            }

            onClose()
            setIsProcessing(false)
        }

        return (
            <Form onSubmit={handleSubmit}>
                <SuperField
                    fluid
                    as="textarea"
                    value={note}
                    onChange={(e, { value }) => setNote(value)}
                    label={t('note')}
                />
                <p style={{ color: 'var(--danger)', fontWeight: 'bold' }}>
                    {t('note_is_only_applied_to_invoice_approvals')}
                </p>
                <Divider />
                <Form.Field style={{ textAlign: 'right' }}>
                    <ModalCancel onClose={onClose} disabled={isProcessing} />
                    <ModalSubmit disabled={isProcessing} loading={isProcessing} text={t('confirm')} />
                </Form.Field>
            </Form>
        )
    }

    return (
        <CanView
            permissions={['approvals.c_view_all_approvals', 'approvals.c_view_user_approvals']}
            route={routes.APPROVALS}
            redirect
        >
            <ListView
                as="table"
                endpoint={API.APPROVALS}
                allowSelection
                actionsCellWidth="2"
                query={`&awaiting_approval=true`}
                initialFilters={{
                    resource_type: '',
                    responsible_person: '',
                    approved_by: '',
                    ordering: '-created_on',
                }}
                bulkActions={(selected, setData, setTotal) => [
                    {
                        as: 'modal',
                        name: t('approve'),
                        icon: 'checkmark-outline',
                        modal: (
                            <ConfirmBulkForm
                                setData={setData}
                                setTotal={setTotal}
                                selected={selected}
                                confirmType={'approve'}
                            />
                        ),
                    },
                    {
                        as: 'modal',
                        name: t('deny'),
                        icon: 'close-outline',
                        modal: (
                            <ConfirmBulkForm
                                setData={setData}
                                setTotal={setTotal}
                                selected={selected}
                                confirmType={'deny'}
                            />
                        ),
                    },
                ]}
                listAdditionActions={() => [
                    {
                        as: 'link',
                        name: t('pending'),
                        redirect: routes.APPROVALS,
                        isActive: true,
                    },
                    {
                        as: 'link',
                        name: t('approved'),
                        redirect: routes.APPROVALS + 'approved/',
                    },
                    {
                        as: 'link',
                        name: t('denied'),
                        redirect: routes.APPROVALS + 'denied/',
                    },
                ]}
                renderFilterFields={(filters, setFilters) => (
                    <>
                        <CanView permissions={['approvals.c_view_all_approvals']}>
                            <SuperField
                                as="choice"
                                search
                                label={t('responsible_person')}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                                text="fullname"
                                value={filters.responsible_person}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        responsible_person: value,
                                    })
                                }
                            />

                            <SuperField
                                as="choice"
                                label={t('resource')}
                                search
                                customOptions={stateOptions}
                                value={filters.resource_type}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        resource_type: value,
                                    })
                                }
                            />
                            <SuperField
                                as="choice"
                                search
                                label={t('approved_by')}
                                endpoint={API.EMPLOYEES + '?only_basic_info=true&is_active=true'}
                                text="fullname"
                                value={filters.approved_by}
                                onChange={(e, { value }) =>
                                    setFilters({
                                        ...filters,
                                        approved_by: value,
                                    })
                                }
                            />
                        </CanView>
                    </>
                )}
                actions={[
                    {
                        as: 'modal',
                        type: 'custom',
                        name: t('approve'),
                        icon: 'checkmark-outline',
                        customIconColor: 'var(--success)',
                        modal: (item, setData, setTotal) => (
                            <ConfirmForm
                                onConfirm={onInvoiceApproval}
                                setData={setData}
                                setTotal={setTotal}
                                approval={item}
                                confirmType={'approve'}
                            />
                        ),
                        isHidden: (item) => item.resourcetype !== 'InvoiceApproval' || isConfirmed(item, true),
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        name: t('deny'),
                        icon: 'close-outline',
                        customIconColor: 'var(--danger)',
                        modal: (item, setData, setTotal) => (
                            <ConfirmForm
                                onConfirm={onInvoiceApproval}
                                setData={setData}
                                setTotal={setTotal}
                                approval={item}
                                confirmType={'deny'}
                            />
                        ),
                        isHidden: (item) => item.resourcetype !== 'InvoiceApproval' || isConfirmed(item, false),
                    },
                    {
                        as: 'confirm',
                        type: 'custom',
                        name: t('approve'),
                        customIconColor: 'var(--success)',
                        icon: 'checkmark-outline',
                        text: t('are_you_sure'),
                        onClick: async (item, setData, setTotal) => onSingleApprover(true, item, setData, setTotal),
                        isHidden: (item) => item.resourcetype === 'InvoiceApproval',
                    },
                    {
                        as: 'modal',
                        type: 'custom',
                        name: t('deny'),
                        modalSize: 'tiny',
                        icon: 'close-outline',
                        customIconColor: 'var(--danger)',
                        modal: (item, setData, setTotal) => (
                            <ConfirmForm
                                onConfirm={onSingleApprover}
                                setData={setData}
                                setTotal={setTotal}
                                approval={item}
                                confirmType={'deny'}
                                isNotInvoice={true}
                            />
                        ),
                        isHidden: (item) => item.resourcetype === 'InvoiceApproval' || isConfirmed(item, false),
                    },
                    // {
                    //     as: 'confirm',
                    //     type: 'custom',
                    //     name: t('deny'),
                    //     customIconColor: 'var(--danger)',
                    //     icon: 'close-outline',
                    //     text: t('are_you_sure'),
                    //     onClick: async (item, setData, setTotal) => onSingleApprover(false, item, setData, setTotal),
                    //     isHidden: (item) => item.resourcetype === 'InvoiceApproval',
                    // },
                ]}
                tableHeaders={[
                    { title: t('ID') },
                    { title: t('requested_approval') },
                    { title: t('resource') },
                    { title: t('requested_when') },
                    { title: t('approval_status') },
                    { title: t('responsible_person') },
                ]}
                renderCells={(approval) => [
                    { width: 1, content: '#' + approval.id },
                    {
                        content: (
                            <>
                                {approval.resourcetype === 'RecruitmentApproval' && (
                                    <RecruitmentApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'PlannedCourseApproval' && (
                                    <PlannedCourseApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'ProjectApproval' && <ProjectApproval approval={approval} />}
                                {approval.resourcetype === 'BudgetApproval' && <BudgetApproval approval={approval} />}
                                {approval.resourcetype === 'InvoiceApproval' && (
                                    <InvoiceApproval approval={approval} hasPermToManageAll={hasPermToManageAll} />
                                )}
                                {approval.resourcetype === 'RecruitmentStageApproval' && (
                                    <RecruitmentStageApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'TimesheetApproval' && (
                                    <TimesheetApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'BonusPaymentApproval' && (
                                    <BonusPaymentApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'AdvancePaymentApproval' && (
                                    <AdvancePaymentApproval approval={approval} />
                                )}
                                {approval.resourcetype === 'DeductionPaymentApproval' && (
                                    <DeductionPaymentApproval approval={approval} />
                                )}
                            </>
                        ),
                    },
                    { content: getResourceName(approval.resourcetype) },
                    {
                        content: moment(dates.convertUTCDateToLocalDate(approval.created_on)).format(
                            `${dateFormat} HH:mm`
                        ),
                    },
                    {
                        content: t('pending'),
                    },
                    {
                        content:
                            approval.resourcetype === 'InvoiceApproval' ? (
                                <>
                                    <InvoiceApprover
                                        approver={approval.responsible_person}
                                        decision={approval.approved_by_responsible_person}
                                        note={approval.responsible_person_note}
                                        type={'responsible_person'}
                                    />
                                    <InvoiceApprover
                                        approver={approval.second_responsible_person}
                                        decision={approval.approved_by_second_responsible_person}
                                        note={approval.second_responsible_person_note}
                                        type={'second_responsible_person'}
                                    />
                                    <InvoiceApprover
                                        approver={approval.reviewer}
                                        decision={approval.approved_by_reviewer}
                                        note={approval.reviewer_note}
                                        type={'reviewer_person'}
                                    />
                                    <InvoiceApprover
                                        approver={approval.second_reviewer}
                                        decision={approval.approved_by_second_reviewer}
                                        note={approval.second_reviewer_note}
                                        type={'second_reviewer_person'}
                                    />
                                </>
                            ) : (
                                <>
                                    {approval.responsible_person?.fullname ? (
                                        <>
                                            <Icon name="circle outline" style={{ color: 'transparent' }} />
                                            <Link
                                                className="ref-link"
                                                to={routes.EMPLYOEES_DETAIL + approval?.responsible_person?.id}
                                                target="_blank"
                                            >
                                                {approval.responsible_person?.fullname}
                                            </Link>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ),
                    },
                ]}
            />
        </CanView>
    )
}

export default AwaitingApprovals
