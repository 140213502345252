import { APIRequest, URLManager } from './helpers/requests';
import { API } from "../config";

/*
 * Service functions
 */
export async function getRequest(URL, isPrivate) {
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: URL,
        method: "GET",
        private: isPrivate
    })
}

export async function createRequest(URL, data, isPrivate) {
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: URL,
        method: "POST",
        data: data,
        private: isPrivate
    })
}

export async function updateRequest(URL, data, isPrivate) {
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: URL,
        method: "PUT",
        data: data,
        private: isPrivate
    })
}

export async function patchRequest(URL, data, isPrivate) {
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: URL,
        method: "PATCH",
        data: data,
        private: isPrivate
    })
}

export async function deleteRequest(URL, isPrivate) {
    isPrivate = isPrivate || true

    return await APIRequest({
        url: URL,
        method: "DELETE",
        private: isPrivate
    })
}


export const commonService = {
    createBusinessDetail,
    getBusinessDetail,
    getChoices,
    getNaceCodes,
    createPhoneInfo,
    createEmailInfo,
    updateBusinessDetail,
    updateAddress,
    getLabels,
    createLabel,

    // *********
    createPerson,
    createAddress,
    createBirthInfo,
    createContactInfo,
    createUnit,
    getUnitTypes,
    createPassport,
    updatePassport,
    deletePassport,
    updateObject

};

async function getUnitTypes() {
    return await APIRequest({
        url: API.UNIT_TYPES,
        method: "GET",
        data: {},
        private: true
    })
}
async function createBusinessDetail(data) {
    return await APIRequest({
        url: API.CREATE_BUSINESS_DETAIL,
        method: "POST",
        data: data,
        private: true
    })
}
async function updateBusinessDetail(id, data) {
    return await APIRequest({
        url: API.CREATE_BUSINESS_DETAIL + id + '/',
        method: "PATCH",
        data: data,
        private: true
    })
}
async function getBusinessDetail(id, params) {
    params = params || "";
    return await APIRequest({
        url: URLManager.url_with_id(API.BUSINESS_DETAIL, id) + params,
        method: "GET",
        data: {},
        private: true
    })
}

async function getChoices() {
    return await APIRequest({
        url: API.CHOICES,
        method: "GET",
        data: {},
        private: true,
    })
}
async function getNaceCodes() {
    return await APIRequest({
        url: API.NACE_CODE,
        method: "GET",
        data: {},
        private: true
    })
}

async function createPassport(data) {
    return await APIRequest({
        url: API.PASSPORTS,
        method: "POST",
        data: data,
        private: true
    })
}
async function updatePassport(id, data) {
    return await APIRequest({
        url: URLManager.url_with_id(API.PASSPORTS, id),
        method: "PATCH",
        data: data,
        private: true
    })
}
async function deletePassport(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.PASSPORTS, id),
        method: "DELETE",
        data: {},
        private: true
    })
}

async function createEmailInfo(email) {
    return await APIRequest({
        url: API.CREATE_EMAIL,
        method: 'POST',
        data: { email: email },
        private: true
    });
}

async function createPhoneInfo(data) {
    return await APIRequest({
        url: API.CREATE_PHONE,
        method: 'POST',
        data: data,
        private: true
    });
}


async function updateAddress(id, data) {
    return await APIRequest({
        url: API.CREATE_ADDRESS + id + '/',
        method: "patch",
        data: data,
        private: true
    })
}

// *********
async function createPerson(data) {
    return await APIRequest({
        url: API.PERSONS,
        method: "POST",
        data: data,
        private: true
    })
}
async function createBirthInfo(data) {
    return await APIRequest({
        url: API.BIRTH_INFO,
        method: "POST",
        data: data,
        private: true
    })
}
async function createAddress(data) {
    return await APIRequest({
        url: API.ADDRESSES,
        method: "POST",
        data: data,
        private: true
    })
}

async function createContactInfo(data) {
    return await APIRequest({
        url: API.CONTACT_INTO,
        method: "POST",
        data: data,
        private: true
    })
}

async function createUnit(data) {
    return await APIRequest({
        url: API.UNITS,
        method: "POST",
        data: data,
        private: true
    })
}

async function updateObject(id, data, object) {

    return await APIRequest({
        url: object + id + '/',
        method: 'PATCH',
        data: { contact_info: data },
        private: true
    });
}
async function getLabels() {
    return await APIRequest({
        url: API.ACCOUNTS + 'labels/',
        method: "GET",
        data: {},
        private: true
    })
}
async function createLabel(data) {
    return await APIRequest({
        url: API.ACCOUNTS + 'labels/',
        method: "POST",
        data: data,
        private: true
    })
}
