import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components 
import { Form, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const ContractSignForm = ({ record, setData, onClose }) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(record?.date_of_signature || "");
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)

        const request = await requests.patch(API.CONTRACTS + record.id + "/", {
            date_of_signature: date,
            status: 1
        })

        if(request.status === 200){
            setData(prev => prev.map(item => {
                if (item.id === record.id) {
                    item = request.response
                }
                return item
            }))
            onClose()
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="datepicker"
                closable
                required
                label={t('date_of_signature')}
                value={date}
                onChange={(e, { value }) => setDate(value) }
            />

            <Button 
                fluid primary 
                loading={isProcessing} 
                content={ t('save') }
                disabled={isProcessing || date === ""} 
            />
        </Form>
    );
};

export default ContractSignForm;