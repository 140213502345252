const permissionReducer = (permissions = [], action) => {
    switch (action.type) {
        case "ADD_PERMISSIONS":
            return action.permissions;
        default:
            return permissions;
    }
};

export default permissionReducer;
