import React from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Grid, Button, Header } from 'semantic-ui-react';

const FilterForm = ({ setVisible, filters, setFilters, view, setView, fetchTasks }) => {
    const { t } = useTranslation()

    return (
        <Form
            style={{ paddingLeft: '1rem', paddingRight: '1rem', textAlign: 'left' }}
            onSubmit={(e) => e.preventDefault()}
        >
            <Grid>
                <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column>
                        <Header as="h2" content={t('filters')} style={{ marginTop: '1rem', textAlign: 'left' }} />
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Icon
                            name="close-outline"
                            style={{
                                cursor: 'pointer',
                                color: 'var(--danger)',
                                marginTop: '1.5rem',
                                fontSize: '1.5rem',
                            }}
                            onClick={() => setVisible(false)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    size="small"
                    label={t('date_from')}
                    value={filters.date_from}
                    style={{ height: "2.75rem" }}
                    onChange={(e, { value }) => setFilters({ ...filters, date_from: value })}
                />
                <SuperField as="datepicker"
                    size="small"
                    label={t('date_to')}
                    value={filters.date_to}
                    style={{ height: "2.75rem" }}
                    onChange={(e, { value }) => setFilters({ ...filters, date_to: value })}
                />
            </Form.Group>
            <DatePresets
                from={filters.date_from}
                to={filters.date_to}
                onSelection={(firstDay, lastDay, year) => {
                    setFilters(prev => ({
                        ...prev,
                        date_from: firstDay,
                        date_to: lastDay,
                    }))
                }}
            />

            <SuperField as="choice"
                search
                text="title"
                label={t('type')}
                value={filters.task_type}
                endpoint={API.TASKS + "task_type/"}
                onChange={(e, { value }) => setFilters(prev => ({...prev, task_type: value}))}
            />

            <Divider />
            <Form.Field>
                <Button primary type="button" content={t('confirm')} onClick={() => fetchTasks()}/>
            </Form.Field>
        </Form>
    );
};

export default FilterForm;