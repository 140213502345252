import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Form, Button, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const StartOnOff = ({ onClose, id, btnName }) => {
    const { t } = useTranslation();

    const [view, setView] = useState(0); // 1 - Active, 2 - Inactive
    const [isProcessing, setIsProcessing] = useState(false);
    const [template, setTemplate] = useState("")
    const [deadline, setDeadline] = useState("")
    const [errors, setErrors] = useState(null)

    const handleSubmit = async () => {
        setIsProcessing(true);
        const request = await requests.post(API.ONBOARDING_CHECKLIST_CONFIG + template + '/instantiate/', { employee: id, deadline: deadline })
        if (request.status === 201) {
            onClose();
        } else{ 
            setErrors(request.response)
        }

        setIsProcessing(false);
    }


    return (
        <Form onSubmit={handleSubmit}>
            {view === 0 &&
                <Button.Group fluid basic size='small' style={{ borderRadius: 0 }}>
                    <Button type='button' active={view === 1} onClick={() => setView(1)}>{'Onboarding'}</Button>
                    <Button type='button' active={view === 2} onClick={() => setView(2)}>{'Offboarding'}</Button>
                </Button.Group>}

            {view !== 0 &&
                <>
                    <SuperField as="choice"
                        search
                        required
                        text="title"
                        value={template}
                        label={t('choose_template')}
                        onChange={(e, value) => setTemplate(value.value)}
                        endpoint={API.ONBOARDING_CHECKLIST_CONFIG + '?type=' + view}
                    />
                    <SuperField as="datepicker"
                        required
                        label={t('deadline')}
                        value={deadline}
                        onChange={(e, { value }) => setDeadline(value)}
                    />
                </>
            }

            { errors !== null && <p style={{ color: "var(--danger)" }}> <Icon name="warning-outline"/> { errors?.non_field_errors?.[0] }</p> }

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    text={btnName}
                    loading={isProcessing}
                    disabled={isProcessing || template === "" || deadline === ""}
                />
            </Form.Field>

        </Form>
    );
};

export default StartOnOff;