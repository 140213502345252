import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-semantic-toasts'
import '@store/languages'
import moment from 'moment'
// store
import { API } from '@store/config'
import { useIsMount } from '@helpers/hooks'
import { requests } from '@helpers/requests'
import { setLocaleLanguage, tzDateTime } from '@helpers/dates'
// components
import SpinnerSegment from '@components/SpinnerSegment'
import { Container, Grid, Button, Table, Divider, Header, Card, Dropdown, Label } from 'semantic-ui-react'

const WarehouseManagement = ({ employee, setMode, setEmployee }) => {
    const { t } = useTranslation()
    const isMount = useIsMount()
    const dateFormat = useSelector((state) => state.date_format)
    const today = moment().format(dateFormat)

    const [loadingStockedPositions, setLoadingStockedPositions] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(true)
    const [process, setProcess] = useState(null)
    const [activeItem, setActiveItem] = useState(null)
    const [activePosition, setActivePosition] = useState(null)
    const [processes, setProcesses] = useState([])
    const [stockPositions, setStockPositions] = useState([])
    const [actionResponse, setActionResponse] = useState(null)
    const [viewActive, setViewActive] = useState(true)

    async function fetchAvailableStockedPositions(id) {
        if (id !== null && id !== undefined) {
            setLoadingStockedPositions(true)
            const request = await requests.get(
                API.ASSETS +
                    'stored_item_positions/?query={id, warehouse_position{id, title, section, row, quantity_limit}, quantity}&ordering=warehouse_position__title&stored_item=' +
                    id
            )
            if (request.status === 200) {
                setStockPositions(request.response)
            }
            setLoadingStockedPositions(false)
        }
    }

    async function fetchProcesses(intervalFetch) {
        intervalFetch = [undefined, false].includes(intervalFetch) ? false : true
        if (!intervalFetch) setLoading(true)
        const request = await requests.get(
            API.REPORTS + 'assets/warehouse_stats/?is_active=true&is_template=false&responsible_persons=' + employee.id
        )
        if (request.status === 200) {
            const results = request.response || []
            if (results?.length >= 1) {
                let selectedProcess = process
                if (selectedProcess === null) {
                    setProcess(results?.[0] || null)
                } else {
                    setProcess(results.find((item) => item.id === selectedProcess.id) || null)
                }

                if (results?.[0]?.items?.length === 1) {
                    let selectedItem = results?.[0]?.items?.[0]
                    if (intervalFetch && activeItem !== null) {
                        selectedItem = activeItem
                    } else {
                        setActiveItem(selectedItem || null)
                    }

                    if (!intervalFetch) {
                        await fetchAvailableStockedPositions(selectedItem?.stored_item_id)
                    }
                }
            }

            setProcesses(results || [])
        }
        setLoading(false)
    }

    const fetchProcessStats = async (process) => {
        const request = await requests.get(API.REPORTS + 'assets/warehouse_stats/?id=' + process.id)
        if (request.status === 200) {
            if (request.response.length === 1) {
                setProcess(request.response[0])
                setProcesses((prev) =>
                    prev.map((item) => {
                        if (item.id === process.id) {
                            item = request.response[0]
                        }

                        return item
                    })
                )
            }
        }
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!loading) {
                await fetchProcesses(true)
            }
        }, 10000)

        return () => clearInterval(interval)
    })

    useEffect(() => {
        fetchProcesses()
        // eslint-disable-next-line
    }, [employee])

    useEffect(() => {
        async function fetchDataOnStoredItemChange() {
            if (!isMount && activeItem !== null && !loading) {
                await fetchAvailableStockedPositions(activeItem?.stored_item_id)
            }
        }

        fetchDataOnStoredItemChange()
        // eslint-disable-next-line
    }, [activeItem])

    const onReturn = () => {
        setMode(1)
        setEmployee(null)
    }

    const calculateQuantityToDeduct = (item) => {
        let plan = item?.plan || 0
        let reality = item?.reality || 0
        let calculatedDiff = plan - reality
        if (calculatedDiff < 0) calculatedDiff = 0
        let remainingQuantity = calculatedDiff !== 0 ? parseFloat(calculatedDiff).toFixed(4) : 0
        let itemDeductQuantity = item?.weight_is_primary_measurement ? item?.average_weight || 1 : 1

        if (
            parseFloat(itemDeductQuantity) >= remainingQuantity ||
            parseFloat(itemDeductQuantity) === parseFloat(remainingQuantity) - 0.001
        ) {
            itemDeductQuantity = remainingQuantity
        }

        return itemDeductQuantity
    }

    const handlePositionListingReturn = async (item, isReturnAction) => {
        if (!isValidAction(process, item) && isReturnAction !== true) {
            setActiveItem(null)
            setStockPositions([])
            setActivePosition(null)
        } else {
            await fetchAvailableStockedPositions(activeItem?.stored_item_id)
            setActivePosition(null)
        }
    }

    const handlePositionSelection = async (item, isSamePosition) => {
        setActionResponse(null)
        setProcessing(true)
        if (!isSamePosition) setActivePosition(item)
        const request = await requests.post(API.ASSETS + 'warehouse_process_operations/', {
            quantity: calculateQuantityToDeduct(activeItem),
            operation: 6,
            stored_item: activeItem.stored_item_id,
            warehouse_process: process.id,
            warehouse_position: item.warehouse_position.id,
            warehouse_process_item: activeItem.id,
            performed_by: employee.id,
        })

        setActionResponse(request)
        if (request.status === 201) {
            await fetchProcessStats(process)
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: request.response?.non_field_errors?.[0] || t('unable_to_perform_action'),
                animation: 'pulse',
                time: 5000,
            })
            await handlePositionListingReturn(activeItem)
        }
        setProcessing(false)
    }

    const handlePositionReturn = async (item) => {
        setActionResponse(null)
        setProcessing(true)
        const isWeight = activeItem.weight_is_primary_measurement
        const request = await requests.post(API.ASSETS + 'warehouse_process_operations/', {
            quantity: isWeight ? activeItem.average_weight : 1,
            operation: 1,
            stored_item: activeItem.stored_item_id,
            warehouse_process: process.id,
            warehouse_position: item.warehouse_position.id,
            warehouse_process_item: activeItem.id,
            performed_by: employee.id,
        })

        setActionResponse(request)
        if (request.status === 201) {
            await fetchProcessStats(process)
            await handlePositionListingReturn(activeItem, true)
        }
        setProcessing(false)
    }

    const isValidAction = (process, selectedItem) => {
        const items = process.items
        const currentItem = items.find((item) => item.id === selectedItem.id)

        if (currentItem !== undefined && currentItem?.reality >= currentItem?.plan) return false

        return true
    }

    const isStockValid = (process, selectedItem) => {
        const items = process.items
        const currentItem = items.find((item) => item.id === selectedItem.id)

        if (currentItem !== undefined) {
            let difference = parseFloat(currentItem?.plan - currentItem?.reality).toFixed(4)
            if (difference < 0) difference = 0

            if (difference > currentItem.stock_quantity) return false
        }

        return true
    }

    const formatWeight = (quantity, item) => {
        if (item?.weight_is_primary_measurement) {
            quantity = parseFloat(quantity).toFixed(4)
            // quantity = quantity.tocodeString().replace(".0000", "")
        }

        return quantity
    }

    const renderStockDifference = (item) => {
        let plan = item.plan
        let reality = item.reality

        let diff = plan - reality

        if (diff < 0) diff = 0

        return formatWeight(diff, item)
    }

    const viewItems = (items) => {
        items = items || []
        if (viewActive) {
            return items.filter((obj) => obj.plan > obj.reality)
        }

        return items.filter((obj) => obj.plan <= obj.reality)
    }

    const totalActive = (items) => {
        items = items || []
        return items.filter((obj) => obj.plan > obj.reality).length
    }

    const displayPlan = (item) => {
        if (item.weight_is_primary_measurement) {
            return item.processing_quantity
        }

        return item.quantity
    }

    const displayReality = (item) => {
        if (item.weight_is_primary_measurement) {
            let reality = item.reality
            let average_unit_weight = item.average_weight

            return reality > 0 ? parseFloat(reality / average_unit_weight).toFixed(0) : 0
        }

        return item.reality
    }

    const displayItem = (item) => {
        const itemDisplay = (item.code ? item.code + ' - ' : '') + item.item
        const isRenviro = ['renviro.pulsawork.com', 'www.renviro.pulsawork.com'].includes(window.location.hostname)
        if (item.logistic_record && isRenviro) {
            let supplier = item?.logistic_record?.supplier || ''
            let deliverydate = item?.logistic_record?.delivery_date || ''
            return (
                supplier.substr(0, 3).toUpperCase() + moment(deliverydate).format('DDMMYYYY') + ' (' + itemDisplay + ')'
            )
        }

        return itemDisplay
    }

    return (
        <Container fluid style={{ padding: 0 }}>
            <Grid stackable style={{ borderBottom: '1px solid lightgray' }}>
                <Grid.Row columns="2" style={{ background: 'var(--light)', padding: '1rem' }} verticalAlign="middle">
                    <Grid.Column>
                        {processes.length === 0 ? (
                            t('process_not_available')
                        ) : processes.length > 1 ? (
                            <Dropdown
                                className="process-listing"
                                inline
                                text={
                                    process !== null ? (
                                        <div>
                                            <strong style={{ fontSize: '1.5rem' }}>{process.process} </strong>(
                                            {process?.percentage || '0'}%) <br />
                                            <span style={{ opacity: '0.8' }}>{process.note}</span>
                                        </div>
                                    ) : (
                                        <div>{t('process_not_selected')}</div>
                                    )
                                }
                            >
                                <Dropdown.Menu>
                                    {processes.map((item) => (
                                        <Dropdown.Item
                                            key={item.id}
                                            onClick={() => {
                                                if (item.id !== process?.id) {
                                                    setProcess(item)
                                                    if (item.items.length === 1) {
                                                        setActiveItem(item.items[0])
                                                    } else {
                                                        setActiveItem(null)
                                                    }
                                                    setActivePosition(null)
                                                    setStockPositions([])
                                                }
                                            }}
                                            style={{ paddingRight: '2rem' }}
                                        >
                                            <div>
                                                <strong style={{ fontSize: '1.2rem' }}>{item.process} </strong>(
                                                {item.percentage ? item.percentage.toString().replace('.00', '') : 0}%){' '}
                                                <br />
                                                <span style={{ opacity: '0.8' }}>{item.note}</span>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            <div>
                                <strong style={{ fontSize: '1.5rem' }}>{process.process} </strong>(
                                {process?.percentage || 0}%) <br />
                                <span style={{ opacity: '0.8' }}>{process.note}</span>
                            </div>
                        )}
                    </Grid.Column>
                    <Grid.Column
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}
                    >
                        <div style={{ marginRight: '2rem' }}>
                            <strong style={{ fontSize: '1.3rem' }}>{today}</strong> <br />
                            <span style={{ textTransform: 'capitalize' }}>
                                {moment(today, dateFormat).locale(setLocaleLanguage()).format('dddd')}
                            </span>
                        </div>
                        <div style={{ marginRight: '2rem' }}>
                            <strong style={{ fontSize: '1.3rem' }}>{employee?.name || '--'}</strong>
                            <div>
                                {employee?.unit === null ? (
                                    <span style={{ opacity: '0.5' }}>{t('not_specified')}</span>
                                ) : (
                                    <span>{employee?.unit}</span>
                                )}
                            </div>
                        </div>
                        <Button
                            className="rounded-edges circular"
                            style={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                color: 'var(--light)',
                                background: 'var(--danger)',
                                position: 'relative',
                                top: '0.2rem',
                            }}
                            onClick={() => onReturn()}
                        >
                            {t('logout')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <SpinnerSegment loading={loading} loadingMessage={t('loading_available_processes')}>
                {!loading && (
                    <>
                        {processes.length === 0 && (
                            <>
                                <h2 style={{ marginTop: '2rem', textAlign: 'center' }}>
                                    {' '}
                                    {t('you_dont_have_any_active_processes')}{' '}
                                </h2>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                                    <Button primary size="huge" onClick={() => fetchProcesses()}>
                                        {t('try_to_load_again')}
                                    </Button>
                                </div>
                            </>
                        )}
                        {processes.length > 0 && (
                            <Grid style={{ height: '90vh' }}>
                                <Grid.Row columns={2} divided="vertically">
                                    <Grid.Column
                                        width="6"
                                        style={{ padding: 0, background: 'var(--lightgrey)' }}
                                        stretched
                                    >
                                        <div style={{ padding: '1rem', paddingTop: 0 }}>
                                            <Button.Group fluid size="large" basic widths="equal">
                                                <Button
                                                    active={viewActive}
                                                    onClick={() => setViewActive(!viewActive)}
                                                    style={{ fontWeight: viewActive ? 'bold' : 'normal' }}
                                                >
                                                    {t('active')}{' '}
                                                    <Label style={{ marginLeft: '0.5rem' }} circular basic>
                                                        {totalActive(process?.items)}
                                                    </Label>
                                                </Button>
                                                <Button
                                                    active={!viewActive}
                                                    onClick={() => setViewActive(!viewActive)}
                                                    style={{ fontWeight: !viewActive ? 'bold' : 'normal' }}
                                                >
                                                    {t('completed')}
                                                </Button>
                                            </Button.Group>
                                            <Table celled>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell
                                                            style={{ padding: '0.5rem 1rem' }}
                                                            width="12"
                                                            content={t('items')}
                                                        />
                                                        <Table.HeaderCell
                                                            style={{ padding: '0.5rem 1rem', textAlign: 'center' }}
                                                            width="4"
                                                            content={t('for_processing')}
                                                        />
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {/* { reOrderItems(process?.items)?.map(item => ( */}
                                                    {viewItems(process?.items).length === 0 && (
                                                        <Table.Row>
                                                            <Table.Cell
                                                                colSpan="2"
                                                                style={{ textAlign: 'center', fontWeight: 'bold' }}
                                                            >
                                                                {t('no_items_to_processing')}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                    {viewItems(process?.items)?.map((item) => (
                                                        <Table.Row
                                                            style={{
                                                                cursor:
                                                                    item.reality >= item.plan ? 'normal' : 'pointer',
                                                                background:
                                                                    activeItem?.id === item?.id
                                                                        ? item.reality >= item.plan
                                                                            ? 'transparent'
                                                                            : '#83dcff'
                                                                        : 'transparent',
                                                                opacity: item.reality >= item.plan ? 0.5 : 1,
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    item?.id !== activeItem?.id &&
                                                                    item.reality !== item.plan
                                                                ) {
                                                                    setActiveItem(item)
                                                                    setActivePosition(null)
                                                                }
                                                            }}
                                                            key={item?.id}
                                                        >
                                                            <Table.Cell>
                                                                <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                                                                    {displayItem(item)}
                                                                </div>
                                                                <span style={{ opacity: '0.8', fontSize: '1.1rem' }}>
                                                                    <strong>{t('stock')}: </strong>
                                                                    {renderStockDifference(item)}
                                                                    {' ' + (item.measure_unit || '')}
                                                                    {!isStockValid(process, item) &&
                                                                        item.reality !== item.plan && (
                                                                            <span
                                                                                style={{
                                                                                    textTranform: 'uppercase',
                                                                                    color: 'var(--danger)',
                                                                                    fontWeight: 'bold',
                                                                                    marginLeft: '0.5rem',
                                                                                }}
                                                                            >
                                                                                <br />
                                                                                {t('not_enough_stock')}!
                                                                            </span>
                                                                        )}
                                                                </span>
                                                            </Table.Cell>
                                                            <Table.Cell textAlign="center">
                                                                <div style={{ fontSize: '1.1rem' }}>
                                                                    <strong>
                                                                        {displayReality(item)} / {displayPlan(item)}{' '}
                                                                    </strong>{' '}
                                                                    ({item.percentage || 0}%)
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width="10" style={{ padding: 0 }} stretched>
                                        <div style={{ padding: '1rem' }}>
                                            {activeItem === null && (
                                                <p
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '1.8rem',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {t('select_item_from_the_left_menu')}
                                                </p>
                                            )}

                                            {activeItem !== null && (
                                                <SpinnerSegment
                                                    loading={loadingStockedPositions}
                                                    loadingMessage={t('loading_stocked_positions')}
                                                >
                                                    {stockPositions.length === 0 && (
                                                        <Header
                                                            as="h2"
                                                            content={
                                                                <>
                                                                    {!isStockValid(process, activeItem) && (
                                                                        <span style={{ color: 'var(--danger)' }}>
                                                                            {t('not_enough_stock')}!
                                                                            <br />
                                                                        </span>
                                                                    )}
                                                                    {t(
                                                                        'there_are_not_any_stocked_positions_for_this_item'
                                                                    )}
                                                                </>
                                                            }
                                                            style={{ textAlign: 'center' }}
                                                        />
                                                    )}
                                                    {!loadingStockedPositions &&
                                                        activePosition === null &&
                                                        stockPositions.length > 0 && (
                                                            <>
                                                                <Header
                                                                    as="h2"
                                                                    content={
                                                                        <>
                                                                            {t('select_position_to_unload')}
                                                                            {!isStockValid(process, activeItem) && (
                                                                                <span
                                                                                    style={{ color: 'var(--danger)' }}
                                                                                >
                                                                                    <br />
                                                                                    {t('not_enough_stock')}!
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    }
                                                                    style={{ textAlign: 'center' }}
                                                                />
                                                                <Divider />
                                                                <Card.Group itemsPerRow="2" stackable>
                                                                    {stockPositions.map((item) => (
                                                                        <Card
                                                                            id={item.id}
                                                                            onClick={() =>
                                                                                handlePositionSelection(item, false)
                                                                            }
                                                                        >
                                                                            <Card.Content
                                                                                textAlign="center"
                                                                                style={{
                                                                                    color: 'var(--dark)',
                                                                                    padding: '2rem',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: '2.5rem',
                                                                                        fontWeight: 'bold',
                                                                                    }}
                                                                                >
                                                                                    {item.warehouse_position.title}
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        fontSize: '2rem',
                                                                                        marginTop: '1.5rem',
                                                                                    }}
                                                                                >
                                                                                    {formatWeight(
                                                                                        item.quantity,
                                                                                        activeItem
                                                                                    )}{' '}
                                                                                    {activeItem?.measure_unit || ''}
                                                                                </div>
                                                                            </Card.Content>
                                                                        </Card>
                                                                    ))}
                                                                </Card.Group>
                                                            </>
                                                        )}

                                                    {activePosition !== null && (
                                                        <SpinnerSegment
                                                            loading={processing}
                                                            loadingMessage={t('processing_request')}
                                                        >
                                                            {!processing && (
                                                                <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                                                    {/* { stockPositions.length > 1 && */}
                                                                    <div
                                                                        style={{
                                                                            fontSize: '2rem',
                                                                            color: 'var(--primary)',
                                                                            marginBottom: '4rem',
                                                                            fontWeight: 'bold',
                                                                            cursor: 'pointer',
                                                                            textDecoration: 'underline',
                                                                        }}
                                                                        onClick={() =>
                                                                            handlePositionListingReturn(activeItem)
                                                                        }
                                                                    >
                                                                        {t('return_to_position_listing')}
                                                                    </div>
                                                                    {/* } */}

                                                                    <div
                                                                        style={{
                                                                            fontSize: '2.5rem',
                                                                            fontWeight: 'bold',
                                                                            marginBottom: '2rem',
                                                                            textTransform: 'uppercase',
                                                                        }}
                                                                    >
                                                                        {t('item_unloaded')}!
                                                                    </div>
                                                                    {actionResponse?.response?.created_on && (
                                                                        <div
                                                                            style={{
                                                                                marginTop: '1rem',
                                                                                fontSize: '2rem',
                                                                                marginBottom: '2rem',
                                                                            }}
                                                                        >
                                                                            {tzDateTime(
                                                                                actionResponse?.response?.created_on
                                                                            ).format(dateFormat + ' HH:mm:ss')}
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            fontSize: '1.8rem',
                                                                            marginBottom: '1rem',
                                                                        }}
                                                                    >
                                                                        {displayItem(activeItem)}
                                                                        {/* { activeItem.code ? activeItem.code + " - " : "" }{ activeItem.item } */}
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            fontSize: '1.8rem',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        {activePosition.warehouse_position.title}{' '}
                                                                        {activePosition.warehouse_position.section
                                                                            ? '/' +
                                                                              activePosition.warehouse_position.section
                                                                            : ''}{' '}
                                                                        {activePosition.warehouse_position.row
                                                                            ? '/' +
                                                                              activePosition.warehouse_position.row
                                                                            : ''}{' '}
                                                                    </div>

                                                                    <Button.Group
                                                                        size="huge"
                                                                        style={{
                                                                            paddingTop: '1rem',
                                                                            marginTop: '1rem',
                                                                            borderTop: '1px solid var(--light)',
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            type="button"
                                                                            secondary
                                                                            style={{
                                                                                marginRight: '1rem',
                                                                                padding: '40px 100px',
                                                                                borderRadius: '3px',
                                                                            }}
                                                                            onClick={() =>
                                                                                handlePositionReturn(activePosition)
                                                                            }
                                                                        >
                                                                            {t('return_stored_item')}
                                                                        </Button>
                                                                        <Button
                                                                            disabled={
                                                                                !isValidAction(process, activeItem)
                                                                            }
                                                                            type="button"
                                                                            primary
                                                                            style={{
                                                                                padding: '40px 100px',
                                                                                borderRadius: '3px',
                                                                            }}
                                                                            onClick={() =>
                                                                                handlePositionSelection(
                                                                                    activePosition,
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            {t('unload_another_item')}
                                                                        </Button>
                                                                    </Button.Group>
                                                                </div>
                                                            )}
                                                        </SpinnerSegment>
                                                    )}
                                                </SpinnerSegment>
                                            )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        )}
                    </>
                )}
            </SpinnerSegment>
        </Container>
    )
}

export default WarehouseManagement
