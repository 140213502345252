import React from 'react';

import Icon from '@components/Icon';
import { Grid, Card, Item } from 'semantic-ui-react';

const QuickAccessLink = ({to, text, image, icon,  ...rest}) => {
    return (
        <Grid.Column {...rest}>
            <Card 
                fluid
                style={{ borderBottom: "2px solid var(--primary)", textAlign: "center",cursor: "pointer", borderRadius: 0, padding: "1rem" }} 
                onClick={to}
            >
                <Item>
                    {image && <Item.Image src={image} style={{ filter: "invert(1)" }} size="mini"/>}
                    {icon && <Item.Image size="mini"><Icon name={icon}/></Item.Image>}
                    <Item.Content verticalAlign='middle'>
                        <Item.Header>{text}</Item.Header>
                    </Item.Content>
                </Item>
            </Card>
        </Grid.Column>
    );
};

export default QuickAccessLink;