const journalReducer = (journal = null, action) => {
    switch (action.type) {
        case "ADD_JOURNAL":
            return action.payload;
        default:
            return journal;
    }
};

export default journalReducer;
