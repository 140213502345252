import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
// store
import { API } from '@store/config';
import { createRequest, patchRequest } from '@services/ServiceCommon';
import { useFetchData, useSelectOptions } from '@helpers/hooks'
// components
import { Form, Button, Header, Divider, Accordion, Modal } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperCheckboxField from '@components/forms/SuperCheckboxField';
import AccordionItem from '@components/AccordionItem';
import EstimatedTimeForm from './EstimatedTimeForm';

const IssueForm = ({ onClose, issueAsProps, employees, setIssues, setStats }) => {
    const params = useParams()
    const { t } = useTranslation()
    const [error, setError] = useState('')
    const [issue, setIssue] = useState({
        id: issueAsProps?.id ? issueAsProps.id : 0,
        number: issueAsProps?.number ? issueAsProps.number : "",
        name: issueAsProps?.name ? issueAsProps.name : ""
    })

    const employeesOptions = employees?.people_on_project ? employees?.people_on_project.map(employee => {
        return {
            key: employee.id,
            text: employee.fullname,
            value: employee.id,
        }
    }) : []

    const [parent_issues] = useFetchData(API.PROJECT_ISSUES + '?query={id,name,number}&project=' + params.id)

    function parentIssuesOptions() {
        if (parent_issues.isLoading) return []
        return parent_issues.data.map(issue => ({ key: issue.id, text: `#${issue.number} ${issue.name}`, value: issue.id })).filter(((item) => item.key !== issue.id))
    }
    const [processing, setProcessing] = useState(false)
    const [nameOfIssue, setNameOfIssue] = useState("")


    const handleSubmit = async () => {
        setProcessing(true)

        const request = await createRequest(API.PROJECT_ISSUES, {
            project: params.id,
            name: nameOfIssue
        })

        if (request.status === 201) {
            setIssues(prevState => ({
                ...prevState,
                data: [request.response, ...prevState.data]
            }))
            setStats((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    opened_issues: parseInt(prev.data.opened_issues) + 1,
                    all_issues: parseInt(prev.data.all_issues) + 1,
                }
            }))

            setIssue({
                ...issue,
                id: request.response.id,
                number: request.response.number,
                name: nameOfIssue
            })
        }

        setProcessing(false)
    }

    function reformatIssueList(actual, response) {
        return actual.filter((item) => {
            if (item.id === response.id) return false
            if (item.sub_issues.length > 0) item.sub_issues = reformatIssueList(item.sub_issues, response)
            return item
        })
    }

    function addResponseToList(actual, response) {
        if (response.parent) {
            return actual.filter((item) => {
                if (item.id === response.parent) {
                    item.sub_issues.push(response)
                    return item
                } else if (item.sub_issues.length > 0) {
                    addResponseToList(item.sub_issues, response)
                }
                return item
            })
        }
        return actual.concat([response])
    }

    function handleIssuesAfterUpdate(actual, response) {
        return addResponseToList(reformatIssueList(actual, response), response)
    }

    const ConfigureIssue = () => {
        const [configuration, setConfiguration] = useState({
            name: issue.name,
            description: issueAsProps?.description ? issueAsProps.description : "",
            milestone: issueAsProps?.milestone ? issueAsProps.milestone.id : "",
            labels: issueAsProps?.labels ? issueAsProps?.labels.map(assigned => assigned.id) : [],
            assigned: issueAsProps?.assigned ? issueAsProps?.assigned.map(assigned => assigned.id) : [],
            due_date: issueAsProps?.due_date ? issueAsProps.due_date : null,
            estimated_time: issueAsProps?.estimated_time ? issueAsProps.estimated_time : null,
            is_backlog: issueAsProps?.is_backlog ? issueAsProps.is_backlog : false,
            is_template: issueAsProps?.is_template ? issueAsProps.is_template : false,
            group: issueAsProps?.group ? issueAsProps.group.id : '',
            parent: issueAsProps?.parent ? issueAsProps.parent : '',
        })
        const [active, setActive] = useState(null)
        const [processing, setProcessing] = useState(false)
        const [groups, setGroups] = useSelectOptions(API.PROJECT_GROUPS + "?query={id, title}&project=" + params.id, "title")

        const handleSubmit = async () => {
            setProcessing(true)
            const request = await patchRequest(API.PROJECT_ISSUES + issue.id + "/", {
                ...configuration,
                labels: {
                    remove: issueAsProps?.labels ? issueAsProps?.labels.map(label => label.id) : [],
                    add: configuration.labels
                },
                assigned: {
                    remove: issueAsProps?.assigned ? issueAsProps?.assigned.map(assigned => assigned.id) : [],
                    add: configuration.assigned
                }
            })
            if (request.status === 200) {
                setIssues(prevState => {
                    return {
                        ...prevState,
                        data: handleIssuesAfterUpdate(prevState.data, request.response)
                    }
                })
                onClose()
            } else {
                if (request.response.parent) setError(request.response.parent)
                if (request.response.estimated_time) setError(request.response.estimated_time)
                if (request.response.is_backlog) setError(request.response.is_backlog)
                if (request.response.is_template) setError(request.response.is_template)
                if (request.response.labels) setError(request.response.labels)
                if (request.response.milestone) setError(request.response.milestone)
                if (request.response.name) setError(request.response.name)
            }

            setProcessing(false)
        }

        const handleAddItem = async (newItem) => {
            const request = await createRequest(API.PROJECT_GROUPS, {
                title: newItem,
                project: params.id
            })

            if (request.status === 201) {
                setGroups(prevState => ({
                    ...prevState,
                    options: [{
                        key: request.response.id,
                        value: request.response.id,
                        text: request.response.title
                    }, ...prevState.options]
                }))

                setConfiguration(prevState => ({
                    ...prevState,
                    group: request.response.id
                }))
            }
        }

        return (
            <Form onSubmit={handleSubmit}>
                <FlexRow padding="0" background="transparent">
                    <FlexItem basis="200%">
                        <Header as="h3">
                            <SuperField as="input" size="mini"
                                value={configuration.name}
                                onChange={(e, { value }) => setConfiguration({ ...configuration, name: value })}
                            />
                        </Header>
                    </FlexItem>
                    <FlexItem textAlign="right">
                        <small>
                            Reference number: <strong>#{issue.number}</strong>
                        </small>
                    </FlexItem>
                </FlexRow>

                <Divider />

                <Accordion fluid styled style={{ marginBottom: "1rem" }}>
                    <AccordionItem
                        index={0}
                        isActive={active === 0}
                        onClick={() => setActive(active === 0 ? null : 0)}
                        title={t('additional_information')}
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                    >
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                text="name"
                                label={t('milestone')}
                                endpoint={API.PROJECT_MILESTONES + `?query={id, name}&project=${params.id}`}
                                value={configuration.milestone}
                                onChange={(e, { value }) => setConfiguration({ ...configuration, milestone: value })}
                            />
                            <SuperCheckboxField
                                endpoint={API.PROJECT_LABELS + "?query={id, name}"}
                                text="name"
                                label={t('labels')}
                                value={configuration.labels}
                                onChange={
                                    (selected) => setConfiguration({ ...configuration, labels: selected })
                                }
                            />
                            <SuperCheckboxField
                                // endpoint={API.EMPLOYEES + "?query={id, fullname}"}
                                text="fullname"
                                customOptions={employeesOptions}
                                label={t('assignees')}
                                value={configuration.assigned}
                                onChange={
                                    (selected) => setConfiguration({ ...configuration, assigned: selected })
                                }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                allowAdditions
                                search
                                text="title"
                                loading={groups.isLoading}
                                disabled={groups.isLoading}
                                customOptions={groups.options}
                                label={t('group')}
                                value={configuration.group}
                                onAddItem={(e, { value }) => handleAddItem(value)}
                                onChange={(e, { value }) => setConfiguration({ ...configuration, group: value })}
                            />
                            <SuperField as="choice"
                                text="name"
                                customOptions={parentIssuesOptions()}
                                label={t('parent_issue')}
                                // endpoint={API.PROJECT_ISSUES + `?query={id, name}&project=${params.id}`}
                                value={configuration.parent}
                                onChange={(e, { value }) => setConfiguration({ ...configuration, parent: value })}
                            />
                            <SuperField as="datepicker"
                                size="small"
                                settings="preferences"
                                label={t('due_date')}
                                placeholder={t('due_date')}
                                value={configuration.due_date}
                                iconPosition="left"
                                onChange={(e, data) => setConfiguration({ ...configuration, due_date: data.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <SuperField
                                as="checkbox"
                                label={t('is_backlog')}
                                checked={configuration.is_backlog}
                                onChange={
                                    () => setConfiguration({ ...configuration, is_backlog: !configuration.is_backlog })
                                }
                            />
                            <SuperField
                                as="checkbox"
                                label={t('is_template')}
                                checked={configuration.is_template}
                                onChange={
                                    () => setConfiguration({ ...configuration, is_template: !configuration.is_template })
                                }
                            />
                        </Form.Group>
                    </AccordionItem>
                </Accordion>


                <SuperField as="textarea"
                    autoFocus
                    rows={10}
                    label={t('description')}
                    placeholder={t('enter_desc')}
                    value={configuration.description}
                    onChange={(e, { value }) => setConfiguration({ ...configuration, description: value })}
                />
                <EstimatedTimeForm
                    value={configuration.estimated_time}
                    onChange={(e) => setConfiguration({ ...configuration, estimated_time: e })} />
                <Button
                    primary
                    disabled={processing || issue.name === ""} loading={processing}
                >
                    {t('submit')}
                </Button>
            </Form>
        )
    }

    return (
        <div>
            {issue.id === 0 &&
                <Form onSubmit={handleSubmit}>
                    <SuperField as="input"
                        autoFocus
                        label={t('name')}
                        value={nameOfIssue}
                        onChange={(e, { value }) => setNameOfIssue(value)}
                        icon={
                            <Button
                                icon="arrow circle right"
                                primary
                                disabled={processing || nameOfIssue === ""} loading={processing}
                            />
                        }
                    />
                </Form>}
            {issue.id > 0 && <ConfigureIssue />}
            <Modal
                size='mini'
                open={error ? true : false}
                onClose={() => setError('')}
                centered
            >
                <Modal.Header>{t('error')}</Modal.Header>
                <Modal.Content>{error}</Modal.Content>
            </Modal>
        </div>
    );
};

export default IssueForm;