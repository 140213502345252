import React from 'react'
import { Bar } from "react-chartjs-2";

const BarGraph = ({data, onClick}) => {
    return (
        <Bar
            data={data}
            options={{
                title:{
                    display: true,
                    fontSize: 10,
                },
                legend:{
                    display: true,
                    labels: {
                        fontSize: 12,
                    },
                    position:'top'
                },
                responsive: true,
                beginAtZero: true,
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        }
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        }
                    }],
                },
            }}
        />
    )
};

export default BarGraph;
