import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
import { Button, Divider, Form, Grid, Image, Message, Segment } from 'semantic-ui-react';
import { FlexItem, FlexRow } from '@components/tables/FlexTable';
import HriisPublicationForm from './HriisPublicationForm';
import RabotaPublicationForm from './RabotaPublicationForm';
import ProfesiaSKPublicationForm from './ProfesiaSKPublicationForm';
import CanView from '@components/perms/CanView';
import ExternalPublicatonForm from './ExternalPublicatonForm';
import BackTo from '@components/BackTo';

const PublicationForm = ({
    offer,
    publications,
    publish,
    setPublications,
    setOnPublicationCreate,
    onPublicationCreate,
    externalPublications,
    onClose,
    setExternalPublications,
    setViewMode,
    viewMode,
}) => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [selectedOption, setSelectedOption] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    // eslint-disable-next-line
    const [createdPublication, setCreatedPublication] = useState({});
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {
        setCreatedPublication({});
        setOnPublicationCreate(true);
        setIsProcessing(true);

        const result = await requests.post(API.JOB_PUBLICATIONS, data);

        if (result.status === 201) {
            setCreatedPublication(result.response);
            setPublications([...publications, result.response]);
            setOnPublicationCreate(false);
        } else if (result.status === 400) {
            if (result.response.valid_until) {
                setErrors([...errors, result.response.valid_until[0]]);
            }

            if (result.response.rubricList) {
                setErrors([...errors, t('rubric_list') + ': ' + result.response.rubricList[0]]);
            }
        }

        setIsProcessing(false);
    };

    const PublisherBox = ({ name, logo, publisherIndex, disabled }) => {
        return (
            <FlexItem>
                <span
                    onClick={disabled ? null : () => setSelectedOption(publisherIndex)}
                    className={disabled ? 'publisher-box disabled' : 'publisher-box'}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                    {logo && <Image src={logo} style={{ width: '50%' }} />}
                    <h2 style={{ textAlign: 'center' }}>{name}</h2>
                </span>
            </FlexItem>
        );
    };

    return (
        <>
            {viewMode === 0 ? (
                <Grid>
                    <BackTo
                        onClick={() => setOnPublicationCreate()}
                        title={t('choose_publication_type')}
                        header={t('choose_publication_type')}
                    />
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                            <Segment
                                onClick={() => setViewMode(1)}
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                className="segment-hover"
                                textAlign="center"
                                content={t('publications')}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                            <Segment
                                onClick={() => setViewMode(2)}
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                className="segment-hover"
                                textAlign="center"
                                content={t('external_publications')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ) : viewMode === 1 ? (
                <Form onSubmit={handleSubmit}>
                    <Message error visible={errors.length > 0} header={t('error_submission_message')} list={errors} />

                    {selectedOption === '' && (
                        <>
                            <BackTo
                                title={t('back_to_publications')}
                                header={t('select_publisher')}
                                onClick={() => setViewMode(0)}
                            />

                            <FlexRow>
                                <CanView permissions={['jobs.c_general_publications']}>
                                    <PublisherBox name="PULSAWORK" publisherIndex={1} />
                                </CanView>
                                <CanView permissions={['integrations.c_rabota_ua_publications']}>
                                    <PublisherBox name="Rabota.ua" publisherIndex={3} />
                                </CanView>
                                <CanView permissions={['integrations.c_profesia_sk_publications']}>
                                    <PublisherBox name="Profesia.sk" publisherIndex={2} />
                                </CanView>
                            </FlexRow>
                            <FlexRow>
                                <CanView permissions={['integrations.c_profesia_sk_publications']}>
                                    <PublisherBox name="Kariera.sk" publisherIndex={5} />
                                </CanView>
                                <CanView permissions={['integrations.c_profesia_sk_publications']}>
                                    <PublisherBox name="Profesia.cz" publisherIndex={4} />
                                </CanView>
                            </FlexRow>
                        </>
                    )}

                    {selectedOption === 1 && (
                        <>
                            <BackTo
                                title={t('back_to_publisher')}
                                header={t('publisher') + ': PULSAWORK'}
                                onClick={() => {
                                    setViewMode(0);
                                    setSelectedOption('');
                                }}
                            />
                            <HriisPublicationForm offerId={offer.id} setData={setData} setIsDisabled={setIsDisabled} />
                        </>
                    )}
                    {selectedOption === 2 && (
                        <>
                            <BackTo
                                title={t('back_to_publisher')}
                                header={t('publisher') + ': Profesia SK'}
                                onClick={() => {
                                    setViewMode(0);
                                    setSelectedOption('');
                                }}
                            />
                            <ProfesiaSKPublicationForm
                                offerId={offer.id}
                                offer={offer}
                                setData={setData}
                                resourceType="Profesia_skJobPublication"
                                channel={1}
                                setIsDisabled={setIsDisabled}
                            />
                        </>
                    )}

                    {selectedOption === 3 && (
                        <>
                            <BackTo
                                title={t('back_to_publisher')}
                                header={t('publisher') + ': Rabota.ua'}
                                onClick={() => {
                                    setViewMode(0);
                                    setSelectedOption('');
                                }}
                            />
                            <RabotaPublicationForm offerId={offer.id} setData={setData} setIsDisabled={setIsDisabled} />
                        </>
                    )}

                    {selectedOption === 4 && (
                        <>
                            <BackTo
                                title={t('back_to_publisher')}
                                header={t('publisher') + ': Profesia CZ'}
                                onClick={() => {
                                    setViewMode(0);
                                    setSelectedOption('');
                                }}
                            />
                            <ProfesiaSKPublicationForm
                                offerId={offer.id}
                                offer={offer}
                                setData={setData}
                                resourceType="Profesia_skJobPublication"
                                channel={3}
                                setIsDisabled={setIsDisabled}
                            />
                        </>
                    )}

                    {selectedOption === 5 && (
                        <>
                            <BackTo
                                title={t('back_to_publisher')}
                                header={t('publisher') + ': Kariera SK'}
                                onClick={() => {
                                    setViewMode(0);
                                    setSelectedOption('');
                                }}
                            />
                            <ProfesiaSKPublicationForm
                                offerId={offer.id}
                                offer={offer}
                                setData={setData}
                                resourceType="Kariera_skJobPublication"
                                setIsDisabled={setIsDisabled}
                            />
                        </>
                    )}

                    {selectedOption !== '' && (
                        <>
                            <Divider />
                            <Button
                                primary
                                floated="right"
                                loading={isProcessing}
                                disabled={isProcessing || isDisabled}
                                style={{ marginBottom: '1rem' }}
                            >
                                {t('save')}
                            </Button>
                        </>
                    )}
                </Form>
            ) : (
                <ExternalPublicatonForm
                    offer={offer}
                    setExternalPublications={setExternalPublications}
                    externalPublications={externalPublications}
                    externalPublication={externalPublications}
                    onPublicationCreate={onPublicationCreate}
                    setOnPublicationCreate={setOnPublicationCreate}
                    publications={publications}
                    setViewMode={setViewMode}
                />
            )}
        </>
    );
};

export default PublicationForm;
