import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { tzDateTime } from '@helpers/dates';
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import { Popup, Form, Button, Grid, Divider, Header } from 'semantic-ui-react';
import UserAvatar from 'react-user-avatar';
import SuperField from '@components/forms/SuperField';
import Action from '@components/general/Action';

const QuickNotes = ({ isDirect, record, setData }) => {
    const { t } = useTranslation()
    const canManage = useHasPermissions(['accounts.c_manage_all_accounts', 'accounts.c_manage_assigned_accounts'])
    const dateFormat = useSelector(state => state.date_format)
    const [isVisible, setIsVisible] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [note, setNote] = useState("")

    const onDelete = async (id) => {
        const request = await requests.patch(API.ACCOUNTS + "business_details/" + record.id + '/', {
            notes: { remove: [id] }
        })
        if (request.status === 200) {
            if (isDirect) {
                setData(request.response)
            } else {
                setData((prev) => prev.map((item) => {
                    if (item.id === record.id) {
                        item = request.response
                    }
    
                    return item
                }))
            }
        }
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.patch(API.ACCOUNTS + "business_details/" + record.id + '/', {
            notes: { create: [{ text: note }] }
        })

        if (request.status === 200) {
            if (isDirect) {
                setData(request.response)
            } else {
                setData((prev) => prev.map((item) => {
                    if (item.id === record.id) {
                        item = request.response
                    }

                    return item
                }))
            }
            setNote("")
            setIsVisible(false)
        }
        setProcessing(false)
    }

    return (
        <Popup
            basic
            style={{ width: "400px", maxHeight: "500px", overflow: "auto" }}
            position="left center"
            wide="very"
            hoverable
            trigger={
                <div>
                    <Icon name="document-text-outline" style={{ fontSize: "1.3rem" }}/> { record.notes.length }
                </div>
            }
            content={
                <div>
                    <Grid style={{ padding: 0 }}>
                        <Grid.Row verticalAlign="middle" columns="2" style={{ padding: 0 }}>
                            <Grid.Column style={{ textAlign: 'left', padding: 0 }}>
                                <Header as={'h4'}>{t('notes')} ({record.notes.length})</Header>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: 'right', padding: 0 }}>
                                { canManage && 
                                    <Action
                                        as="custom"
                                        type="button"
                                        buttonText={isVisible ? t('close') : t('add_note')}
                                        iconColor={isVisible ? "var(--danger)" : "var(--primary)"}
                                        paddingLeft="1rem"
                                        paddingRight="1rem"
                                        onClick={() => setIsVisible(!isVisible)}
                                    />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    
                    <Divider style={{ marginTop: "0.3rem" }}/>
                    {isVisible && canManage && (
                        <>
                            <Form
                                onSubmit={handleSubmit}
                            >
                                <SuperField
                                    autoFocus
                                    rows="2"
                                    value={note}
                                    label={t('add_note')}
                                    fluid
                                    as="textarea"
                                    onChange={(e, { value }) => setNote(value)}
                                />
                                <Button
                                    primary
                                    size="tiny"
                                    disabled={processing || note === ''}
                                    loading={processing}
                                    content={t('confirm')}
                                />
                            </Form>
                            <Divider/>
                        </>
                    )}
                    {record.notes.length > 0 ? (
                        record.notes?.map((note, index) => (
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", paddingTop: index === 0 ? 0 : '1rem' }} key={index}>
                                <div style={{ display: "flex" }}>
                                    <UserAvatar
                                        size={35}
                                        style={{ marginTop: "0.5rem", paddingLeft: '0rem', fontSize: 55 / 2.5 + 'px' }}
                                        name={note?.created_by?.name || '--'}
                                        color="var(--variant5)"
                                        src={note?.created_by?.profile_picture || ''}
                                    />
                                    <div style={{ fontWeight: 'normal', paddingLeft: '0.8rem', paddingTop: '0.4rem' }}>
                                        <strong>{note?.created_by?.name || '--'}</strong>
                                        <span style={{ paddingLeft: '0.5rem', fontSize: '13px' }}>
                                            {note?.created_on
                                                ? tzDateTime(note?.created_on).format(dateFormat) +
                                                ' · ' +
                                                tzDateTime(note?.created_on).format('HH:mm')
                                                : '--'}
                                        </span>
                                        <br />
                                        <NoteContent isDirect={isDirect} recordNote={note} record={record} setData={setData} canManage={canManage}/>
                                    </div>
                                </div>
                                { canManage &&  
                                    <div style={{ paddingLeft: '0.8rem', paddingTop: '0.5rem', textAlign: "right" }}>
                                        <Icon name="close" onClick={() => onDelete(note.id)} style={{ cursor: "pointer", color: "var(--danger)" }} />
                                    </div>
                                }
                            </div>
                        ))
                    ) : (
                        <div style={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>{t('no_notes')}</div>
                    )}
                </div>
            }
        />
    );
};

const NoteContent = ({ isDirect, recordNote, record, setData, canManage }) => {
    const { t } = useTranslation()

    const [clickedToEdit, setClickedToEdit] = useState(false)
    const [note, setNote] = useState(recordNote.text)

    const onEdit = async () => {
        const request = await requests.patch(API.ACCOUNTS + "business_details/" + record.id + "/", {
            notes: { update: {[recordNote.id]: { text: note }} }
        })

        if (request.status === 200) {
            if (isDirect) {
                setData(request.response)
            } else {
                setData((prev) => prev.map((item) => {
                    if (item.id === record.id) {
                        item = request.response
                    }
    
                    return item
                }))
            }
            setClickedToEdit(false)
        }
    }

    return (
        <>
            <div>
                { clickedToEdit 
                    ?
                        <Form onSubmit={(e) => e.preventDefault()} style={{ marginBottom: 0 }}>
                            <Form.Field style={{ marginBottom: 0, paddingBottom: 0 }}>
                                <SuperField as="textarea"
                                    rows="1"
                                    style={{ marginBottom: 0 }}
                                    placeholder={t('add_note')}
                                    value={note}
                                    onChange={(e, {value}) => setNote(value)}
                                />
                                <span className="ref-link" style={{ color: "var(--primary)" }} onClick={() => onEdit()}>{t('confirm')}</span>
                            </Form.Field>
                        </Form>
                    : recordNote?.text || '--'
                }
            </div>
            { (canManage && !clickedToEdit) && 
                <small className="ref-link" style={{ opacity: 0.8, color: "var(--primary)" }} onClick={() => setClickedToEdit(true)}>{ t("click_to_edit_note") }</small>
            }
        </>

    )
}

export default QuickNotes;