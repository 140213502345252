import React, { useState } from 'react';
import {useSelector} from 'react-redux'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// store
import '@store/languages'
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import { Grid, Modal, Statistic, Progress } from 'semantic-ui-react';
import Action from '@components/general/Action';
import MilestoneForm from '../../forms/MilestoneForm';

const ProjectMilestonesCard = ({ milestone, setMilestones, milestones, project}) => {
    const { t } = useTranslation();

    const language = useSelector(state => state.language)
    const current_date = moment().format('YYYY-MM-DD')
    const in_progress = moment(milestone.due_date).diff(current_date, 'days')
    const due_date = moment(milestone.due_date, "YYYYMMDD").locale(language).fromNow()
    const from_date = moment(milestone.from_date, "YYYYMMDD").locale(language).fromNow()

    const [open, setOpen] = useState(false);

    const onDelete = async (id) => {
        const request = await requests.post(API.PROJECT_MILESTONES + id + "/delete/")
        if(request.status === 200){
            setMilestones(milestones.filter(item => item.id !== id))
        }
    }

    const onUpdate = (response) => {
        setMilestones(prev => prev.map(item => {
            if (item.id === response.id) {
                item = response
            }
            return item
        }))
    }


    return (
        <Grid style={ milestone.is_active ? styles.active_container : styles.inactive_container }>
            <Grid.Row style={{margin: 0,  paddingLeft: '1rem', paddingRight: '1rem'}}>
                <Grid.Column mobile={5} tablet={5} computer={5}>
                    <Statistic style={{ marginTop: '5px'}}>
                        {current_date <= milestone.due_date && milestone.from_date <= current_date ?
                        <>
                            <Statistic.Value>
                                <Icon name='calendar-outline' style={{ color: milestone.is_active ? 'green' : 'grey' }} />
                            </Statistic.Value>
                            <Statistic.Label style={{ fontSize: '1rem', marginTop: '5px' }}> {t('in_progress')} </Statistic.Label>
                            { milestone.from_date ?
                                <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}>{t('days_left')}: {in_progress} </Statistic.Label>
                            :
                                <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}> 
                                    { milestone.is_active 
                                        ? <> <Icon name="ellipse" style={{ color: "var(--success)" }} />{t('opened_')} </> 
                                        : <> <Icon name="ellipse" style={{ color: "var(--danger)" }}/> {t('closed_')} </>
                                    }
                                </Statistic.Label>
                            }
                        </>
                        : current_date < milestone.from_date ?
                        <>
                            <Statistic.Value>
                                <Icon name='calendar-outline' style={{ color: milestone.is_active ? 'yellow' : 'grey' }} />
                            </Statistic.Value>
                            <Statistic.Label style={{ fontSize: '1rem', marginTop: '5px' }}> {t('upcoming')}</Statistic.Label>
                            { milestone.from_date ?
                                <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}>{from_date}  </Statistic.Label>
                            :
                            <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}> 
                                { milestone.is_active 
                                    ? <> <Icon name="ellipse" style={{ color: "var(--success)" }} />{t('opened_')} </> 
                                    : <> <Icon name="ellipse" style={{ color: "var(--danger)" }}/> {t('closed_')} </>
                                }
                            </Statistic.Label>
                            }
                        </>
                        : milestone.is_active && milestone.from_date === null && milestone.due_date === null ?
                        <>
                            <Statistic.Value>
                                <Icon name='calendar-outline' style={{ color: "green" }} />
                            </Statistic.Value>
                            <Statistic.Label style={{ fontSize: '1rem', marginTop: '5px' }}> {t('opened_')} </Statistic.Label>
                            <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}> 
                                { milestone.is_active 
                                    ? <> <Icon name="ellipse" style={{ color: "var(--success)" }} />{t('opened_')} </> 
                                    : <> <Icon name="ellipse" style={{ color: "var(--danger)" }}/> {t('closed_')} </>
                                }
                            </Statistic.Label>
                        </>
                        : current_date > milestone?.due_date ?
                        <>
                            <Statistic.Value>
                                <Icon name='calendar-outline' style={{ color: milestone.is_active ? 'red' : 'grey' }} />
                            </Statistic.Value>
                            <Statistic.Label style={{ fontSize: '1rem', marginTop: '5px' }}> {t('expired_')}</Statistic.Label>
                            { milestone.from_date ?
                                <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}>{due_date}  </Statistic.Label>
                            :
                            <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}> 
                                { milestone.is_active 
                                    ? <> <Icon name="ellipse" style={{ color: "var(--success)" }} />{t('opened_')} </> 
                                    : <> <Icon name="ellipse" style={{ color: "var(--danger)" }}/> {t('closed_')} </>
                                }
                            </Statistic.Label>
                            }
                        </> : milestone?.is_active === false &&
                        <>
                            <Statistic.Value>
                                <Icon name='calendar-outline' style={{ color: "grey" }} />
                            </Statistic.Value>
                            <Statistic.Label style={{ fontSize: '1rem', marginTop: '5px' }}> {t('closed_')} </Statistic.Label>
                            <Statistic.Label style={{ fontSize: '0.8rem', fontWeight: 'bold'}}> 
                                { milestone.is_active 
                                    ? <> <Icon name="ellipse" style={{ color: "var(--success)" }} />{t('opened_')} </> 
                                    : <> <Icon name="ellipse" style={{ color: "var(--danger)" }}/> {t('closed_')} </>
                                }
                            </Statistic.Label>
                        </>
                        }
                    </Statistic>
                </Grid.Column>
                <Grid.Column mobile={9} tablet={9} computer={9}>
                    <strong>{ milestone.name }</strong> <br />
                    {milestone?.from_date || milestone?.due_date ?
                    <span>{ milestone?.from_date ? milestone?.from_date : <span style={{ fontSize: '2.5rem'}}>~</span> } - { milestone?.due_date ? milestone?.due_date : <span style={{ fontSize: '2.5rem'}}>~</span> }</span> :
                    ""
                    }
                    <Statistic size="tiny" style={{ display: 'flex', textAlign: 'center' }}>
                        <Statistic.Value> {milestone?.stats?.closed_issues_count} / {milestone?.stats?.issues_count} </Statistic.Value>
                        <Statistic.Label> {t('issues')} </Statistic.Label>
                    </Statistic>
                    <Progress style={{textAlign: 'center'}} percent={milestone.stats.progress} progress color='yellow' size='small' />
                </Grid.Column>

                <Grid.Column mobile={2} tablet={2} computer={2}>
                    <CanView permissions={['projects.c_manage_milestones']}>
                        <Action
                            as="modal"
                            type="icon"
                            icon="pencil-outline"
                            size="small"
                            popupText={t('edit')}
                            tooltip={t('edit')}
                            modal={
                                <MilestoneForm project={project} milestone={milestone} onConfirm={(response, type) => onUpdate(response, type)} />

                            }
                        />
                        <Action
                            as='delete'
                            type="icon"
                            size="small"
                            tooltip={t('delete')}
                            text={t('delete_milestone')}
                            onClick={() => onDelete(milestone.id)}
                        />
                    </CanView>
                </Grid.Column>
            </Grid.Row>

            <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
                <Modal.Header>{milestone?.name}</Modal.Header>
                <Modal.Content>{milestone?.description}</Modal.Content>
            </Modal>
        </Grid>
    );
};
const styles = {
    active_container: {
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 0,
        borderColor: 'var(--border)',
        borderBottomWidth: 3,
        borderBottomColor: '#21ba45',
    },
    inactive_container: {
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 0,
        borderColor: 'var(--border)',
        borderBottomWidth: 3,
        borderBottomColor: '#767676',
    }
};

export default ProjectMilestonesCard;
