import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { setDotSeparator } from '@helpers/functions';
// components
import CanView from '@components/perms/CanView';
import { Form, Divider, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import NonFieldErrors from '@components/NonFieldErrors';

const HROrderForm = ({ customSubmitHandler, record, setData, setTotal, onClose, accounts, projects, employees, owners }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        name: record?.name || "",
        order_number: record?.order_number || "",
        business_detail: record?.business_detail?.id || "",
        order_manager: record?.order_manager?.id || "",
        order_type: record?.order_type || 2,
        fee: record?.fee?.sum || 0,
        currency: record?.fee?.currency || "EUR",
        amount: record?.amount || 0,
        start_date: record?.start_date || "",
        end_date: record?.end_date || "",
        owner: record?.owner?.id || "",

        assigned_to: record?.assigned_to?.map(item => item.id) || [],
        order_status: record?.order_status || 1,
        is_active: record?.is_active !== undefined ? record?.is_active : true,
    })

    const handleSubmit = async () => {
        setProcessing(true)
        setErrors(null)

        let data = {
            name: form.name,
            order_number: form.order_number,
            order_type: form.order_type,
            resourcetype: "HROrder",
            order_manager: form.order_manager !== "" ? form.order_manager : null,
            business_detail: form.business_detail !== "" ? form.business_detail : null,
            is_active: form.is_active,
            order_status: form.order_status,
            amount: form.amount,
            owner: form.owner !== "" ? form.owner : null,
            start_date: form.start_date !== "" ? form.start_date : null,
            end_date: form.end_date !== "" ? form.end_date : null,
            fee: {
                sum: form.fee,
                currency: form.currency
            }
        }

        // handle data processing and api calls
        if (record === undefined) { // its create request
            if (form.assigned_to.length > 0) data['assigned_to'] = { add: form.assigned_to }

            const request = await requests.post(API.ORDERS, data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setTotal(prev => prev + 1)
                    setData(prev => ([request.response, ...prev]))
                    onClose()
                }
            }

        } else { // its update request
            data['assigned_to'] = { remove: record.assigned_to.map(item => item.id), add: form.assigned_to }

            const request = await requests.patch(API.ORDERS + record.id + "/", data)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                if (customSubmitHandler) {
                    customSubmitHandler(request.response)
                } else {
                    setData(prev => prev.map(item => {
                        if (item.id === record.id) {
                            item = request.response
                        }
                        return item
                    }))
                    onClose()
                }
            }
        }

        setProcessing(false)
    }

    useEffect(() => {
        if (owners.length === 1) {
            setForm(prev => ({...prev, owner: owners?.[0]?.value || ""}))
        }
    }, [owners])

    return (
        <Form onSubmit={handleSubmit}>
            { record !== undefined && (
                <>
                    <Header as="h3" content={t('update_order')}/>
                    <Divider/>
                </>
            )}

            <NonFieldErrors errors={errors} />

            <Form.Group>
                <SuperField as="input"
                    required
                    autoFocus
                    width="12"
                    label={t('name')}
                    value={form.name}
                    error={errors?.name?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, name: value}))}
                />
                <SuperField as="input"
                    width="4"
                    label={t('order_number')}
                    value={form.order_number}
                    error={errors?.order_number?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, order_number: value}))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    required
                    label={t('account')}
                    customOptions={accounts}
                    value={form.business_detail}
                    error={errors?.business_detail?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, business_detail: value}))}
                />
                <SuperField as="choice"
                    search
                    required
                    value={form.owner}
                    label={t('owner')}
                    customOptions={owners}
                    error={errors?.owner?.[0] || false}
                    onChange={(e, { value }) => setForm(prev => ({...prev, owner: value}))}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField as="datepicker"
                    label={t('start_date')}
                    value={form.start_date}
                    error={errors?.start_date?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, start_date: value })}
                />
                <SuperField as="datepicker"
                    label={t('end_date')}
                    value={form.end_date}
                    error={errors?.end_date?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, end_date: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    min="0"
                    type="number"
                    label={t('amount')}
                    value={form.amount}
                    error={errors?.amount?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, amount: value })}
                />
                <SuperField as="input"
                    label={t('fee')}
                    value={form.fee}
                    error={errors?.fee?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, fee: setDotSeparator(value) })}
                />
                <SuperField as="choice"
                    search
                    type="currency_codes"
                    label={t('currency')}
                    value={form.currency}
                    error={errors?.fee?.[0] || false}
                    onChange={(e, { value }) => setForm({ ...form, currency: value })}
                />
            </Form.Group>

            <Divider/>
            <Form.Group widths="equal">
                <CanView permissions={["orders.c_assign_order_manager"]}>
                    <SuperField as="choice"
                        search
                        label={t('order_manager')}
                        value={form.order_manager}
                        customOptions={employees}
                        error={errors?.order_manager?.[0] || false}
                        onChange={(e, { value }) => setForm(prev => ({...prev, order_manager: value}))}
                    />
                </CanView>
            </Form.Group>

            <SuperField as="choice"
                search
                multiple
                label={t('responsible_persons')}
                value={form.assigned_to}
                customOptions={employees}
                onChange={(e, { value }) => setForm({ ...form, assigned_to: value })}
            />

            <Divider/>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    label={t('state')}
                    disabled={record?.id === undefined || [undefined, null, ""].includes(record?.confirmed_on) }
                    type="order_status_choices"
                    value={form.order_status?.toString() || "1"}
                    onChange={(e, { value }) => setForm({ ...form, order_status: value })}
                />
                <SuperField as="choice"
                    label={t('status')}
                    value={form.is_active}
                    customOptions={[
                        { key: 0, value: true, text: t('active_order') },
                        { key: 1, value: false, text: t('closed_order') },
                    ]}
                    onChange={(e, { value }) => setForm({ ...form, is_active: value })}
                />
            </Form.Group>

            <Divider/>
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    loading={processing}
                    disabled={processing || form.name === "" || form.account === "" || form.owner === ""}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default HROrderForm;