import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
// components
import { Grid, Button, Divider } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';
// module specific components
import ItemsAndCategories from '../components/management/ItemsAndCategories';
import Warehouses from '../components/management/Warehouses';
import StoredItems from '../components/management/StoredItems';
import ManageItemsAndCategories from '../components/management/actions/ManageItemsAndCategories';
import WarehouseForm from '../components/management/forms/WarehouseForm';
import StoreItemForm from '../components/management/forms/StoreItemForm';
import StoredItemGroupForm from '../components/management/forms/StoredItemGroupForm';

const AssetsManagementView = ({ employees }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [view, setView] = useState(0)
    const [response, setResponse] = useState(null)

    useEffect(() => {
        history.replace({ pathname: location.pathname, search: '' })
        // eslint-disable-next-line
    }, [view])

    return (
        <>
            <Grid>
                <Grid.Row columns={2} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Grid.Column>
                        <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                            <CanView permissions={['assets.c_view_items']}>
                                <Button active={view === 0} content={t('stored_items')} onClick={() => setView(0)} />
                            </CanView>
                            <CanView permissions={['assets.c_view_warehouses']}>
                                <Button active={view === 1} content={t('warehouses')} onClick={() => setView(1)} />
                            </CanView>
                            <CanView permissions={['assets.c_view_items', 'assets.c_view_item_categories']}>
                                <Button active={view === 2} content={t('items_and_categories')} onClick={() => setView(2)} />
                            </CanView>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column>
                        <Button.Group size="small" floated="right">
                            { view === 0 && 
                                <CanView permissions={['assets.c_manage_items']}>
                                    <SuperDuperModal
                                        closeIcon
                                        trigger={
                                            <Button primary>{t('store_item')}</Button> 
                                        }
                                        size="tiny"
                                        content={<StoreItemForm setResponse={setResponse}/>}
                                    />
                                    <SuperDuperModal
                                        closeIcon
                                        trigger={
                                            <Button style={{ color: "var(--white)", background: "var(--dark)", marginLeft: "0.5rem" }}>{t('store_item_as_group')}</Button> 
                                        }
                                        content={<StoredItemGroupForm setResponse={setResponse}/>}
                                    />
                                </CanView>
                            }
                            { view === 1 && 
                                <CanView permissions={['assets.c_manage_warehouses']}>
                                    <SuperDuperModal
                                        trigger={
                                            <Button primary>{t('create_warehouse')}</Button> 
                                        }
                                        size="small"
                                        content={<WarehouseForm setResponse={setResponse}/>}
                                    />
                                </CanView>
                            }
                            { view === 2 && 
                                <CanView permissions={['assets.c_manage_items', 'assets.c_manage_item_categories']}>
                                    <SuperDuperModal
                                        trigger={
                                            <Button primary>{t('create_item_or_category')}</Button>
                                        }
                                        size="small"
                                        content={<ManageItemsAndCategories setResponse={setResponse}/>}
                                    />
                                </CanView>
                            }
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>

            <CanView permissions={['assets.c_view_items']}>
                { view === 0 && <StoredItems response={response} setResponse={setResponse}/> }
            </CanView>
            <CanView permissions={['assets.c_view_warehouses']}>
                { view === 1 && <Warehouses response={response} setResponse={setResponse}/> }
            </CanView>
            <CanView permissions={['assets.c_view_items', 'assets.c_view_item_categories']}>
                { view === 2 && <ItemsAndCategories response={response} setResponse={setResponse}/> }
            </CanView>
        </>
    );
};

export default AssetsManagementView;