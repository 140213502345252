import React from 'react';
import { API } from "@store/config";

import ProjectUpdateForm from '../forms/ProjectUpdateForm';
import ProjectMembersTab from './project-members-tab/ProjectMembersTab';
import ProjectMilestonesTab from './milestones-tab/ProjectMilestonesTab';
// import ProjectFilesTab from './documents/ProjectFilesTab';
import FilesTab from '@components/documents/FilesTab';


const ManagementTab = ({ project, setProject, managementPageView }) => {

    return (
        <>
        { managementPageView === 0 ?
            <ProjectMilestonesTab project={project} setProject={setProject} />
        : managementPageView === 1 ?
            <ProjectUpdateForm project={project} setProject={setProject} />
        : managementPageView === 2 ?
            <ProjectMembersTab project={project} />
        : managementPageView === 3 &&
            <FilesTab
                module={'project'}
                updateEndpoit={API.PROJECTS}
                getEndpoint={API.PROJECTS}
                group_params={`&project=${project.id}`}
                attachment_params={`?project=${project.id}`}
                hideCreate={true}
            />
        }
        </>
    );
};

export default ManagementTab;