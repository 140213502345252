import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
//store and services
import { API } from '@store/config';
import { requests } from '@helpers/requests';
//components
import { Button, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';


export const EditTaskForm = ({ onConfirm, onClose, taskEdit, checklistID, isInstance }) => {
    const { t } = useTranslation();

    const [task, setTask] = useState({
        titleTask: taskEdit?.title || '',
        checklistConfig: taskEdit?.checklist_config || checklistID,
        assignedTo: taskEdit?.assigned_to.map(item => item.id) || [],
        assignedTeams: taskEdit?.assigned_teams.map(item => item.id) || [],
    })

    const persons = taskEdit?.assigned_to.map(item => item.id) || []
    const teams = taskEdit?.assigned_teams.map(item => item.id) || []

    const editTask = async () => {
        if (taskEdit) {
            const data = {
                title: task.titleTask,
                assigned_teams: {
                    remove: teams,
                    add: task.assignedTeams
                },
                assigned_to: {
                    remove: persons,
                    add: task.assignedTo
                },
            }

            const result = await requests.patch(API.ONBOARDING_ITEMS + taskEdit.id + '/', data)
            if (result.status === 200) {
                onConfirm(result.response)

            }
        } else {
            let result = null
            let data = {
                title: task.titleTask,
                assigned_teams: {
                    add: task.assignedTeams
                },
                // checklist_config: task.checklistConfig,
                assigned_to: {
                    add: task.assignedTo
                },
            }
            if (isInstance) {
                data['checklist_instance'] = task.checklistConfig
                result = await requests.post(API.ONBOARDING_ITEMS, data)
            } else {
                data['checklist_config'] = task.checklistConfig
                result = await requests.post(API.ONBOARDING_ITEMS_CONFIG, data)
            }

            if (result.status === 201) {
                onConfirm(result.response)
            }

        }

    }

    return (
        <Form >
            <Form.Group widths='equal'>
                <SuperField as="input"
                    required
                    label={t('title')}
                    value={task.titleTask}
                    onChange={(e) => setTask({
                        ...task,
                        titleTask: e.target.value
                    })}
                />
                <SuperField as="choice"
                    label={t('assign_person')}
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                    multiple
                    search
                    text="fullname"
                    value={task.assignedTo}
                    onChange={(e, value) => setTask({
                        ...task,
                        assignedTo: value.value
                    })}
                />
                {/* <SuperField as="choice"
                    label={ t('assign_team')}
                    endpoint={API.TEAMS}
                    multiple
                    search
                    text="name"
                    value={task.assignedTeams}
                    onChange={(e, value) => setTask({
                        ...task,
                        assignedTeams: value.value
                    })}
                /> */}
                <Form.Field style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center'
                }} >
                    <Button size='big' icon='check' style={{ background: "none", lineHeight: '1.5rem', marginTop: "1rem" }} onClick={() => editTask()} />
                    <Button type="button" size='big' icon='close' style={{ background: "none", lineHeight: '1.5rem', marginTop: "1rem" }} onClick={() => onClose()} />
                </Form.Field >
            </Form.Group>
            <Form.Field style={{ textAlign: "right" }}>
            </Form.Field>
        </Form>
    )
}
export default EditTaskForm



