import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { patchRequest } from '@services/ServiceCommon';
// Components
import { Form } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperLabelField from '@components/forms/SuperLabelField';

const LabelsForm = ({ id, data, fieldName, patchEndpoint, labelEndpoint, query, onClose, onConfirm }) => {
    query = query || ""

    // data are data .... in array format
    // labelEndpoint is label endpoint for example API.PROJECT_LABEL
    // patchEndpoint is object endpoint for example API.PROJECT
    // query is optional parameter in case you want to limit patchEndpoint results

    const { t } = useTranslation();
    const [labels, setLabels] = useState(data.map(item => item.id))
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)
        const request = await patchRequest(patchEndpoint + id + "/" + (query ? ( `?query=${query}`): "") , {
            [fieldName]: {
                add: labels
            }
        })

        if (request.status === 200) {
            onConfirm(id, request.response?.[fieldName])
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperLabelField
                value={labels}
                onChange={(labels) => setLabels(labels)}
                endpoint={labelEndpoint}
            />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default LabelsForm;