import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
//store
import { requests } from '@helpers/requests';
//components
import Loading from '@components/general/Loading';
import Attachment from "../../pages/dashboard/hr/employees/components/documents/Attachment";
import { Button, Container, Grid, Header, Icon, Label } from "semantic-ui-react";

const ContractDocuments = ({ getEndpoint }) => {
    const { t } = useTranslation();
    
    const [documents, setDocuments] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const fetchDataAttachements = async () => {
        const request = await requests.get(getEndpoint)

        if (request.status === 200) {
            setDocuments(request.response.results)
        }
    }

    const FolderWithDocuments = ({contract}) => {
        const [open, setOpen] = useState(false)

        let totalFiles = 0

        if (contract.external_file) totalFiles += 1
        totalFiles += contract.external_file_additions.length

        return(
            <>
                {contract.external_file &&
                    <>
                        <Grid>
                            <Grid.Row style={{ paddingTop: 0 }}>
                                <Button icon labelPosition='left' style={{ backgroundColor: 'white' }} onClick={() => setOpen(!open)} >
                                    <Icon name={open ? 'folder open' : 'folder'} style={{ backgroundColor: 'white', color: 'var(--primary)', paddingBottom: "0.5rem" }} size='large' />
                                    <Header as={'h4'} style={{margin: 0, textAlign: "left", paddingTop: "0.6rem"}}>
                                        {t('contract') + " #" +  contract.id} - {contract.employer.name + ' - ' + contract.work_position.title}  <Label basic circular>{ totalFiles }</Label>
                                    </Header>
                                </Button>
                            </Grid.Row>
                        </Grid>
                        {open && 
                            <Container fluid>
                                {contract?.external_file && <Attachment docName={t('contract') + " - " + contract.employee.fullname_with_titles} docFile={contract.external_file} /> }
                                {contract?.external_file_additions?.length > 0 && contract?.external_file_additions?.map((doc) => ( <Attachment key={doc.id} docs={doc} /> ))}
                            </Container>
                        }
                    </>
                }
            </>
        )
    }

    console.log(documents)

    useEffect(() => {
        fetchDataAttachements()
        setIsLoading(false)
        // eslint-disable-next-line
    }, [])

    return (
        <Container fluid>
            {isLoading ? <Loading /> :
                <>
                    {documents &&
                        documents?.map((contract) => (<FolderWithDocuments contract={contract} />))
                    }
                </>
            }
        </Container>
    )
};

export default ContractDocuments;