import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//other
import SuperField from '@components/forms/SuperField';
import { Message, Form, Button } from 'semantic-ui-react';


function OtherInfoForm({data, setView, onConfirm, patchEndpoint}) {
    const { t } = useTranslation();
    const [insurances, setInsurances] = useState([])

    const [form, setForm] = useState({
        citizenship: data?.citizenship  || null,
        nationality: data?.nationality  || null,
        health_insurance: data?.health_insurance?.id || null,
        driver_licences: data?.driver_licences ? data?.driver_licences?.map(item => item.id) : [],
        last_job: data.last_job,
        is_external: data.is_external,
        is_foreigner: data.is_foreigner,
        is_employed_by_agencies: data.is_employed_by_agencies,
        last_country_residence: data.last_country_residence,
        workplace_country: data.workplace_country,
        acting_by: data.acting_by,
        source: data.source?.id || "",
        handicap_level: data.handicap_level,
        iban: data?.iban || "",
        shirt_size: data?.shirt_size || "",
        trousers_size: data?.trousers_size || "",
        shoe_size: data?.shoe_size || "",
        jacket_size: data?.jacket_size || "",
        has_driver_license: data?.has_driver_license || false,
        number_of_children: data?.number_of_children || 0,
    })

    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {

        setIsProcessing(true);

        let data_form = form
        
        let driver_licences = {
            remove: data?.driver_licences?.map(item => item.id) || [],
            add: form?.driver_licences || []
        }

        if (form?.driver_licences?.length === 0) {
            delete driver_licences.add
        }

        if (data?.driver_licences?.length === 0) {
            delete driver_licences.remove
        }
        
        delete data_form.driver_licences

        if ( [null, ""].includes(form.health_insurance)) {
            data_form.health_insurance = null
        }

        let has_driver_license = form.has_driver_license
        if (driver_licences?.add?.length > 0) {
            has_driver_license = true
        }

        const request = await requests.patch(patchEndpoint + `${data?.id}/`, {
            ...data_form,
            driver_licences: driver_licences,
            has_driver_license: has_driver_license
        })

        if (request.status === 200) {
            onConfirm(request.response)
            setView(0)
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false);
    }

    useEffect(() => {
        async function fetchHealthInsurances() {
            const request = await requests.get(API.HEALTH_INSURANCES + "?query={id, name, code}")

            if (request.status === 200) {
                setInsurances(request.response.map(item => ({
                    key: item.id,
                    value: item.id,
                    text: item.code + " - " + item.name
                })))
            }
        }

        fetchHealthInsurances()
    }, [])

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="choice"
                    type="citizenship"
                    search
                    label={t('citizenship')}
                    error={ errors?.citizenship || false }
                    value={form?.citizenship}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            citizenship: value
                        }))
                    }
                />
                <SuperField as="choice"
                    type="nationalities"
                    search
                    label={t('nationality')}
                    error={ errors?.nationality || false }
                    value={form?.nationality}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            nationality: value
                        }))
                    }
                />
                
                <SuperField as="choice"
                    type="countries"
                    search
                    label={t('last_country_of_residence')}
                    value={form?.last_country_residence?.toString()}
                    error={ errors?.last_country_residence || false }
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            last_country_residence: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    label={t('last_job')}
                    placeholder={t('last_job')}
                    value={form?.last_job}
                    error={ errors?.last_job || false }
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            last_job: value
                        }))
                    }
                />
                <SuperField as="choice"
                    type="countries"
                    search
                    label={t('workplace_country')}
                    value={form?.workplace_country?.toString()}
                    error={ errors?.workplace_country || false }
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            workplace_country: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('acting_by')}
                    placeholder={t('acting_by')}
                    error={ errors?.acting_by || false }
                    value={form?.acting_by}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            acting_by: value
                        }))
                    }
                />
                <SuperField as="choice"
                    search
                    text="name"
                    label={t('hired_based_on')}
                    value={form?.source}
                    error={ errors?.source || false }
                    onChange={ (e, {value}) =>
                        setForm(prev => ({
                            ...prev,
                            source: value
                        }))
                    }
                    endpoint={API.SOURCES}
                />
                <SuperField as="choice"
                    type="handicap_levels"
                    label={t('handicap_level')}
                    error={ errors?.handicap_level || false }
                    value={form?.handicap_level?.toString()}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            handicap_level: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={4}>
                <SuperField as="input"
                    label={ t('number_of_children') }
                    error={ errors?.number_of_children?.[0] || false }
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            number_of_children: value
                        })
                    }
                    value={form.number_of_children}
                />
                
                <Form.Field>
                    <SuperField as="choice"
                        search
                        multiple
                        text="driver_group"
                        label={t('driver_licence')}
                        error={ errors?.driver_licences || false }
                        value={form?.driver_licences}
                        onChange={ (e, { value }) => setForm(prev => ({
                            ...prev,
                            driver_licences: value,
                            has_driver_license: value?.length > 0
                        }))}
                        endpoint={API.DRIVER_LICENCE}
                    />

                    <SuperField as="checkbox"
                        label={ t('has_driver_license') }
                        style={{ paddingTop: '0.5rem' }}
                        error={ errors?.has_driver_licence || false }
                        onChange={() =>
                            setForm({
                                ...form,
                                has_driver_license: !form.has_driver_license 
                            })
                        }
                        checked={form.has_driver_license }
                    />


                </Form.Field>
                
                <SuperField as="choice"
                    search
                    customOptions={insurances}
                    label={t('health_insurance')}
                    error={ errors?.health_insurance?.[0] || false }
                    value={form?.health_insurance}
                    onChange={ (e, {value}) => setForm(prev => ({
                        ...prev,
                        health_insurance: value
                    }))}
                />

                <SuperField as="input"
                    search
                    label={t('bank_account')}
                    error={ errors?.iban?.[0] || false }
                    value={form?.iban}
                    onChange={ (e, {value}) => setForm(prev => ({
                        ...prev, iban: value
                    }))}
                />
            </Form.Group>

            <Form.Group widths={4}>
                <SuperField as="input"
                    label={t('shirt_size')}
                    error={ errors?.shirt_size?.[0] || false }
                    value={form?.shirt_size}
                    onChange={ (e, { value}) =>
                    setForm(prev => ({
                        ...prev,
                        shirt_size: value
                    }))}
                />
                <SuperField as="input"
                    label={t('jacket_size')}
                    error={ errors?.jacket_size?.[0] || false }
                    value={form?.jacket_size}
                    onChange={ (e, { value}) =>
                    setForm(prev => ({
                        ...prev,
                        jacket_size: value
                    }))}
                />
                <SuperField as="input"
                    label={t('trousers_size')}
                    error={ errors?.trousers_size?.[0] || false }
                    value={form?.trousers_size}
                    onChange={ (e, { value}) =>
                    setForm(prev => ({
                        ...prev,
                        trousers_size: value
                    }))}
                />
                <SuperField as="input"
                    label={t('shoe_size')}
                    error={ errors?.shoe_size?.[0] || false }
                    value={form?.shoe_size}
                    onChange={ (e, { value}) =>
                    setForm(prev => ({
                        ...prev,
                        shoe_size: value
                    }))}
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={ t('is_external') }
                error={ errors?.is_external || false }
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        is_external: !form.is_external
                    })
                }
                checked={form.is_external}
            />

            <SuperField as="checkbox"
                label={ t('is_foreigner') }
                error={ errors?.is_foreigner || false }
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        is_foreigner: !form.is_foreigner
                    })
                }
                checked={form.is_foreigner}
            />

            <SuperField as="checkbox"
                label={ t('agency_employee') }
                error={ errors?.is_employed_by_agencies || false }
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        is_employed_by_agencies: !form.is_employed_by_agencies
                    })
                }
                checked={form.is_employed_by_agencies}
            />

            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    disabled={isProcessing}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default OtherInfoForm