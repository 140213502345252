import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from '@helpers/validation';
import { useSelectOptions, useHasPermission } from '@helpers/hooks';
import { createRequest } from '@services/ServiceCommon';
// components
import { DateInput } from 'semantic-ui-calendar-react';
import { Divider, Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';
// import HoursField from './fields/HoursField';
// import ProgressField from './fields/ProgressField';
import ProjectDropdownField from '@components/forms/projects/ProjectDropdownField';
import MilestoneDropdownField from '@components/forms/projects/MilestoneDropdownField';
// others
import moment from 'moment';

const RecordForm = ({ record, onClose, onSubmit, options, setData }) => {
    const { t } = useTranslation();
    // Form-data states
    const [category, setCategory] = useState(record?.category ? record.category.id : "");
    const [project, setProject] = useState(record?.project ? record.project.id : "");
    const [milestone, setMilestone] = useState(record ? record?.milestone?.id ? record.milestone?.id : "" : "");
    // const [progress, setProgress] = useState(record ? record.progress : 0);
    const [hours, setHours] = useState(record ? record.hours : 0.25);
    const [activity, setActivity] = useState(record ? record.activity : "");
    const [date, setDate] = useState(record ? moment(record.date).format("DD.MM.YYYY") : moment().format("DD.MM.YYYY"));
    const [business_detail, setBusinessDetail] = useState(record?.business_detail ? record.business_detail.id : "");

    // Form states:
    const [categoryTypes, setCategoryTypes] = useSelectOptions(API.TIMESHEETS + "categories/", "title")
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);

    const canManageCategories = useHasPermission('timesheets.c_manage_timesheet_categories')

    const refreshForm = () => {
        setDate(moment().format("DD.MM.YYYY")) // reset date to current
        setActivity("") // reset hours
        setProject("") // reset project dropdown
        setMilestone("") // reset milestone

        // slider states
        setHours(0.25) // reset hours
        // setProgress(0) // reset progress
    }

    const handleError = (response) => {
        let errorList = [];
        if (response.non_field_errors) {
            for (let i = 0; i < response.non_field_errors.length; i++) {
                errorList.push(response.non_field_errors[i]);
            }
        }

        if (response.date) {
            for (let i = 0; i < response.date.length; i++) {
                errorList.push(response.date[i]);
            }
        }

        if (response.hours) {
            for (let i = 0; i < response.hours.length; i++) {
                errorList.push(response.hours[i]);
            }
        }

        setErrors(errorList);
    }

    const onActivityChange = (value) => {
        setSuccess(false);
        setErrors([]);
        setActivity(value);
    }

    /* Handle Form Submittion */
    const handleSubmit = async () => {
        setSuccess(false);
        setErrors([])
        setIsProcessing(true);

        // create data object:
        const data = {
            date: moment(date, "DD.MM.YYYY").format("YYYY-MM-DD"),
            activity: activity,
            // progress: progress !== 0.1 ? progress : 0 ,
            hours: hours,
            category: category,
            business_detail: business_detail
        }

        if (project !== "") {
            data.project = project;
            if (milestone !== "") { data.milestone = milestone; }
        } else {
            // data.progress = 0
        }

        // perform update when record was passed as props variable
        if (record) {
            const resultUpdate = await requests.put(API.TIMESHEETS + 'records/' + record.id + "/", data)

            if (resultUpdate.status === 200) {
                // close modal and update row
                onSubmit(resultUpdate.response, setData);
                onClose();
            } else {
                handleError(resultUpdate.response);
                setSuccess(false);
            }
        } else { // perform create when record wasnt passed as props variable
            const resultCreate = await requests.post(API.TIMESHEETS + 'records/', data);
            if (resultCreate.status === 201) {
                // close modal and update row
                if (onSubmit !== undefined) {
                    onSubmit(resultCreate.response, setData);
                }
                refreshForm();
                setSuccess(true);
            } else {
                handleError(resultCreate.response);
                setSuccess(false);
            }
        }

        setIsProcessing(false);
    }

    const handleAddItem = async (newItem) => {
        setCategoryTypes({ ...categoryTypes, isLoading: true })
        const request = await createRequest(API.TIMESHEETS + "categories/", {
            title: newItem
        })

        if (request.status === 201) {
            setCategoryTypes(prevState => {
                return {
                    isLoading: false,
                    options: [{
                        key: request.response.id,
                        value: request.response.id,
                        text: request.response.title
                    }, ...prevState.options]
                }
            })

            setCategory(request.response.id)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t('error_submission_message')}
                list={errors}
            />

            <Message
                success
                visible={success}
                icon="check circle outline"
                header={t('success_header_message')}
                content={t('success_content_message')}
            />

            <SuperField as="choice"
                allowAdditions={canManageCategories}
                search
                autoFocus
                text="title"
                help={canManageCategories ? t('add_activity_type_hint') : null}
                loading={categoryTypes.isLoading}
                disabled={categoryTypes.isLoading}
                customOptions={categoryTypes.options}
                label={t('activity')}
                required
                value={category}
                onAddItem={canManageCategories ? (e, { value }) => handleAddItem(value) : null}
                onChange={(e, { value }) => setCategory(value)}
            />
            <Form.Group widths={2}>
                <Form.Field widths="equal">
                    <DateInput
                        required
                        closable
                        dateFormat="DD.MM.YYYY"
                        label={t('date')}
                        placeholder="DD.MM.YYYY"
                        value={date}
                        iconPosition="left"
                        hideMobileKeyboard
                        onChange={(e, data) => setDate(data.value)}
                    />
                </Form.Field>
                <SuperField as="input"
                    value={hours}
                    required
                    label={t('hours')}
                    error={hours > 24 || hours < 0}
                    pattern="^-?[0-9]\d*\.?\d*$"
                    onChange={(e, data) => setHours(data.value)}
                />
            </Form.Group>
            <SuperField as="choice"
                search
                customOptions={options}
                label={t('client')}
                value={business_detail}
                onChange={(e, data) => setBusinessDetail(data.value)}
            />
            <SuperField as="textarea"
                value={activity}
                label={t('note')}
                onChange={(e, data) => onActivityChange(data.value)}
            />

            <Divider />

            <Form.Group widths={2}>
                <Form.Field>
                    <ProjectDropdownField
                        currentuser={true}
                        value={project}
                        onChange={setProject}
                    />
                </Form.Field>
                <Form.Field>
                    <MilestoneDropdownField
                        milestone={milestone}
                        setMilestone={setMilestone}
                        project={project}
                        disabled={project === "" ? true : false}
                    />
                </Form.Field>
            </Form.Group>

            {/* <Form.Group>
                <Form.Field style={{width: "100%"}} disabled={project === "" ? true : false}>
                    <ProgressField progress={progress} setProgress={setProgress}/>
                </Form.Field>
            </Form.Group> */}

            <Divider />

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    text={record ? t('save') : t('save_and_add_another')}
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(date) || isEmpty(hours) || hours === 0}
                />
            </Form.Field>
        </Form>
    );
};

export default RecordForm;