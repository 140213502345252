import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Button, Header, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';

const CostCenterForm = ({ onClose, setData, setTotal, record }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        id: record?.id || null,
        code: record?.code || '',
        title: record?.title || '',
        unit: record?.unit?.id || "",
        projects: record?.projects?.map(item => item.id) || [],
    })

    function requiredFilled() {
        if (!form.title) return true
        if (isProcessing) return true
        return false
    }

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true)
        let formData = {
            code: form.code,
            title: form.title,
            unit: form.unit ? form.unit : null
        }

        if (record?.id !== undefined && record?.unit?.id === form.unit ) {
            delete formData.unit
        }

        if (record?.id !== undefined && record?.code !== "" && record?.code === form.code ) {
            delete formData.code
        }

        if (form.projects.length > 0 ) {
            formData.projects = {
                add: form.projects
            }

            if (record?.projects?.length > 0) {
                formData.projects['remove'] = record?.projects?.map(item => item.id)
            }
        }

        if ( form.id === null ){
            const request = await requests.post(API.COST_CENTERS, formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setData(prev =>  [...prev, request.response])
                setTotal(prev => prev + 1)
                onClose()
            }
        } else {
            const request = await requests.patch(API.COST_CENTERS + form.id + "/", formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData(prev => prev.filter(item => {
                    if (item.id === form.id){
                        item.code = request.response.code
                        item.title = request.response.title
                        item.projects = request.response.projects
                        item.unit = request.response.unit
                    }

                    return item
                }))
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            { record?.id !== undefined &&
                <>
                    <Header as="h3" content={t('update')}/>
                    <Divider/>
                </>
            }
            <NonFieldErrors errors={errors}/>
            <Form.Group widths="equal">
                <SuperField
                    required
                    fluid
                    autoFocus
                    label={t('title')}
                    as="input"
                    value={form.title}
                    placeholder={t('title')}
                    error={errors?.title?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, title: value }))} 
                />
                <SuperField
                    label={t('code')}
                    as="input"
                    value={form.code}
                    placeholder={t('code')}
                    width="6"
                    error={errors?.code?.[0] || false}
                    onChange={(e, { value }) => setForm((prev) => ({ ...prev, code: value }))} 
                />
            </Form.Group>
            <SuperField
                label={t('unit')}
                as="choice"
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                text="name"
                value={form.unit}
                error={errors?.unit?.[0] || false}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, unit: value }))} 
            />
            <SuperField
                fluid
                search
                multiple
                as="choice"
                text="name"
                label={t('projects')}
                value={form.projects}
                error={errors?.projects?.[0] || false}
                endpoint={API.PROJECTS + '?query={id,name}'}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, projects: value }))} 
            />
            <Form.Group style={{ display: 'flex', justifyContent: 'flex-end', margin: 0 }}>
                <Button type='reset' onClick={onClose}>{t('cancel')}</Button>
                <Button primary
                    type='submit'
                    style={{ marginRight: 0 }}
                    disabled={requiredFilled()}
                    loading={isProcessing}
                >
                    {record?.id ? t('update_cost_center') : t('add_cost_center')}
                </Button>
            </Form.Group>
        </Form>
    )
}

export default CostCenterForm;