import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import { Header, Divider, Grid, Button } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import FamilyForm from '../forms/FamilyForm'
import ConfrimModal from '@components/modals/ConfrimModal'
import { FlexItem, FlexRow } from '@components/tables/FlexTable'

const FamilySection = ({ isManageable, result, setResult, patchEndpoint, canManage }) => {
    const { t } = useTranslation()

    const [family, setFamily] = useState(result?.family || [])

    const onConfirm = async (newFamily) => {
        const data = {
            family: {
                add: [newFamily.id],
            },
        }

        const request = await requests.patch(patchEndpoint + result?.id + '/', data)

        if (request.status === 200) {
            setFamily([...family, newFamily])
            setResult({
                ...result,
                data: {
                    ...result.data,
                    family: [...family, newFamily],
                },
            })
        }
    }

    const onDelete = async (id) => {
        const request = await requests.patch(patchEndpoint + result?.id + '/', {
            family: {
                remove: [id],
            },
        })
        if (request.status === 200) {
            setFamily((prevState) => prevState.filter((item) => item.id !== id))
            setResult({
                ...result,
                data: {
                    ...result.data,
                    family: request.response.family,
                },
            })
        }
    }
    const onUpdate = (updateItem) => {
        const newFamilyList = family.filter((item) => {
            if (item.id === updateItem.id) {
                item.person_type = updateItem.person_type
                item.firstname = updateItem.firstname
                item.surname = updateItem.surname
                item.all_previous_surnames = updateItem.all_previous_surnames
                item.name_at_birth = updateItem.name_at_birth
                item.permanent_address = updateItem.permanent_address
                item.citizenship = updateItem.citizenship
                item.place_and_country_of_birth = updateItem.place_and_country_of_birth
                item.date_of_birth = updateItem.date_of_birth
            }

            return item
        })

        setFamily(newFamilyList)
        setResult({
            ...result,
            data: {
                ...result.data,
                family: newFamilyList,
            },
        })
    }

    return (
        <Fragment>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column
                        only="computer"
                        computer="3"
                        stretched
                        style={{ borderRadius: '4px', paddingTop: '1rem', backgroundColor: 'var(--light)' }}
                    >
                        <FlexRow background="transparent" padding="0">
                            <FlexItem textAlign="center">
                                <Header icon>
                                    <Icon name="accessibility-outline" style={{ fontSize: '5rem' }} />
                                    <h3>{t('family')}</h3>
                                </Header>
                            </FlexItem>
                        </FlexRow>
                    </Grid.Column>
                    <Grid.Column computer="12" tablet="16" mobile="16">
                        <Grid columns="2" style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <Grid.Column width="10" style={{ paddingTop: '0.8rem', paddingBottom: 0 }}>
                                <Header floated="left" as="h3" style={{ marginBottom: 0 }}>
                                    <Icon name="accessibility-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.2rem' }}>{t('family')}</span>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width="6" textAlign="right" style={{ padding: 0 }}>
                                {canManage && (
                                    <SuperDuperModal
                                        header={t('add_family')}
                                        trigger={
                                            <Header
                                                style={{ padding: 0, paddingTop: '1rem', cursor: 'pointer' }}
                                                floated="right"
                                                as="h4"
                                            >
                                                <Icon
                                                    name="add-outline"
                                                    style={{ fontSize: '1.2rem', marginRight: '0.5rem' }}
                                                />
                                                <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                    {t('add_family')}
                                                </span>
                                            </Header>
                                        }
                                        content={
                                            <FamilyForm interest={{}} onConfirm={(newFamily) => onConfirm(newFamily)} />
                                        }
                                    />
                                )}
                            </Grid.Column>
                        </Grid>

                        <Divider />
                        {family.length > 0 ? (
                            <Grid stretched style={{ margin: '1rem' }}>
                                {family.map((item, index) => (
                                    <Grid.Column key={index} width={16} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <Fragment>
                                            <Grid
                                                style={{
                                                    backgroundColor: 'var(--default)',
                                                    marginTop: '.5rem',
                                                    borderRadius: 0,
                                                }}
                                            >
                                                <Grid.Row>
                                                    <Grid.Column width="14" style={{ paddingLeft: 0 }}>
                                                        <h4>{item.person_type?.name}</h4>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <SuperDuperModal
                                                            trigger={
                                                                <Button
                                                                    icon={<Icon name="pencil-outline" />}
                                                                    style={{
                                                                        backgroundColor: 'var(--default)',
                                                                        color: 'var(--primary)',
                                                                        padding: 0,
                                                                    }}
                                                                />
                                                            }
                                                            header={t('update_family')}
                                                            content={
                                                                <FamilyForm
                                                                    family={item}
                                                                    onConfirm={(updatedFamily) =>
                                                                        onUpdate(updatedFamily)
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <ConfrimModal
                                                            onConfirm={() => onDelete(item.id)}
                                                            button={
                                                                <Button
                                                                    icon={<Icon name="close-outline" />}
                                                                    style={{
                                                                        backgroundColor: 'var(--default)',
                                                                        color: 'var(--danger)',
                                                                        padding: 0,
                                                                    }}
                                                                />
                                                            }
                                                            description={t('delete_family_member')}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Grid stackable style={{ padding: 0 }}>
                                                <Grid.Row columns={5}>
                                                    <Grid.Column>
                                                        {t('firstname')} <br />
                                                        <strong>{item?.firstname || '~'}</strong>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {t('lastname')} <br />
                                                        <strong>{item?.surname || '~'}</strong>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {t('name_at_birth')} <br />
                                                        <strong>{item?.name_at_birth || '~'}</strong>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {t('previous_surnames')} <br />
                                                        <strong>{item?.all_previous_surnames || '~'}</strong>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {t('date_of_birth')} <br />
                                                        <strong>{item?.date_of_birth || '~'}</strong>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row columns={5}>
                                                    <Grid.Column>
                                                        {t('place_and_country_of_birth')} <br />
                                                        <strong>{item?.place_and_country_of_birth || '~'}</strong>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {t('citizenship')} <br />
                                                        <strong>{item?.citizenship_display || '~'}</strong>
                                                    </Grid.Column>
                                                    <Grid.Column width={9}>
                                                        {t('permanent_address')} <br />
                                                        <strong>{item?.permanent_address || '~'}</strong>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Fragment>
                                        <Divider />
                                    </Grid.Column>
                                ))}
                            </Grid>
                        ) : (
                            <Grid>
                                <Grid.Column textAlign="center" computer="16" tablet="16" mobile="16">
                                    <Header as="h4">{t('nothing')}</Header>
                                </Grid.Column>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default FamilySection
