import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//other
import SuperField from '@components/forms/SuperField';
import { Message, Form, Button } from 'semantic-ui-react';


function FamilyForm({ family, onConfirm, onClose}) {
    const { t } = useTranslation();

    const [form, setForm] = useState({
        id: family ? family.id : 0,
        person_type: family?.person_type?.id || null,
        firstname: family?.firstname || "",
        surname: family?.surname || "",
        name_at_birth: family?.name_at_birth || "",
        all_previous_surnames: family?.all_previous_surnames || "",
        date_of_birth: family?.date_of_birth || null,
        place_and_country_of_birth: family?.place_and_country_of_birth || "",
        citizenship: family?.citizenship || null,
        permanent_address: family?.permanent_address || ""
    })

    const [errors, setErrors] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSuccess = (response) => {
        onConfirm(response);
        onClose()
    }

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        if(form?.id) {
            const request = await requests.post(API.FAMILY_MEMBERS, form);
            if (request.status === 201) {
                handleSuccess(request.response)
            } else if( request.status === 400 ) {
                setErrors(request.response)
            }
        } else {
            const request = await requests.patch(API.FAMILY_MEMBERS + form.id + '/', form);
            if (request.status === 200) {
                handleSuccess(request.response)
            } else if( request.status === 400 ) {
                setErrors(request.response)
            }
        }

    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />
            <Form.Group widths={2}>
                <SuperField as="choice"
                    search
                    required
                    text="name"
                    label={t('person_type')}
                    error={ errors?.person_type?.[0] || false }
                    value={form?.person_type}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            person_type: value
                        }))
                    }
                    endpoint={API.PERSON_TYPE}
                />
                <SuperField as="datepicker"
                    label={ t('date_of_birth') }
                    error={ errors?.date_of_birth?.[0] || false }
                    value={form?.date_of_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            date_of_birth: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={2}>
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('firstname')}
                    placeholder={t('firstname')}
                    error={ errors?.firstname?.[0] || false }
                    value={form?.firstname}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            firstname: value
                        }))
                    }
                />
                 <SuperField as="input"
                    required
                    label={t('lastname')}
                    placeholder={t('lastname')}
                    error={ errors?.surname?.[0] || false }
                    value={form?.surname}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            surname: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={2}>
                <SuperField as="input"
                    label={t('name_at_birth')}
                    placeholder={t('name_at_birth')}
                    error={ errors?.name_at_birth?.[0] || false }
                    value={form?.name_at_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            name_at_birth: value
                        }))
                    }
                />
                 <SuperField as="input"
                    label={t('previous_surnames')}
                    placeholder={t('previous_surnames')}
                    error={ errors?.all_previous_surnames?.[0] || false }
                    value={form?.all_previous_surnames}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            all_previous_surnames: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={2}>
                <SuperField as="choice"
                    type="citizenship"
                    seatch
                    label={t('citizenship')}
                    error={ errors?.citizenship?.[0] || false }
                    value={form?.citizenship}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            citizenship: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('place_and_country_of_birth')}
                    placeholder={t('place_and_country_of_birth')}
                    error={ errors?.place_and_country_of_birth?.[0] || false }
                    value={form?.place_and_country_of_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            place_and_country_of_birth: value
                        }))
                    }
                />
            </Form.Group>
            <SuperField as="input"
                label={t('permanent_address')}
                placeholder={t('permanent_address')}
                error={ errors?.permanent_address?.[0] || false }
                value={form?.permanent_address}
                onChange={(e, { value }) =>
                    setForm(prev => ({
                        ...prev,
                        permanent_address: value
                    }))
                }
            />
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    primary
                    loading={isProcessing}
                    disabled={
                        isProcessing
                    }
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default FamilyForm