import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { isEmpty } from '@services/helpers/validation';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const BenefitForm = ({ record, onClose, setData, setTotal }) => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        id: record?.id || "",
        name: record?.name || "",
        category: record?.category_id || null,
        icon: record?.icon || "",
        amount: record?.amount || 1
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        //data preparation
        const data = {
            name: form.name,
            category: form.category ? form.category : null,
            icon: form.icon
        }

        if (!form.id || form.id === 0) {
            const request = await requests.post(API.BENEFITS, data)

            if (request.status === 201) {
                setData(prev => [request.response, ...prev])
                setTotal(prev => prev + 1)
            } else {
                setErrors(request.response)
            }
        } else {
            const request = await requests.put(API.BENEFITS + form.id + "/", data)
            if (request.status === 200) {
                setData(prev => prev.map(item => {
                    if (item.id === form.id) {
                        item = request.response
                    }

                    return item
                }))
            } else {
                setErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors}/>
            <Form.Group widths="equal">
                <SuperField as="input"
                    autoFocus
                    required
                    label={t('name')}
                    placeholder={t('name')}
                    error={errors?.name || false}
                    value={form.name}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        name: value
                    })}
                />
                <SuperField as="choice"
                    search
                    label={t('category')}
                    // settings="types/benefits-categories"
                    value={form.category}
                    endpoint={API.BENEFITS_CATEGORIES}
                    error={errors?.category || false}
                    text="name"
                    onChange={(e, { value }) => setForm({
                        ...form,
                        category: value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('icon')}
                    placeholder={t('icon')}
                    value={form.icon}
                    error={errors?.icon || false}
                    onChange={(e, { value }) => setForm({
                        ...form,
                        icon: value
                    })}
                />
                {/* <Form.Input
                    disabled
                    label={t('amount')}
                    placeholder={t('amount')}
                    value={benefit?.amount}
                    onChange={(e) => setBenefit({
                        ...benefit,
                        amount: e.target.value
                    })}
                /> */}
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(form.name)}
                    text={t('confirm')} />
            </Form.Field>
        </Form>
    );
};

export default BenefitForm;