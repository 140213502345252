const companyReducer = (company = {}, action) => {
    switch (action.type) {
        case "ADD_COMPANY":
            return action.payload;
        default:
            return company;
    }
};

export default companyReducer;
