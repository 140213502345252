import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from "@store/services/helpers/validation"
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Button } from 'semantic-ui-react'


function TeamForm({ team, btnName, onClose, onConfirm }) {
    // form data
    const [teamId] = useState(team ? team.id : 0); // if 0 then its Create Form, else Update
    const [name, setName] = useState(() => team ? team.name : "");
    const [description, setDescription] = useState(() => team ? team.description : "");

    // form state
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errorList, setErrorList] = useState([]); // empty array

    const { t } = useTranslation();

    // async/await
    const handleSubmit = async () => {
        setIsProcessing(true);
        // data preparation
        const data = {
            name: name,
            description: description
        }

        if (teamId === 0) { // is create form
            // send data to API for Team Creation
            const result = await requests.post(API.TEAMS, data);

            if (result.status === 201) { // Created
                onConfirm(result.response); // { id: 1, name: "test", description: "test" }
                onClose();
            } else {
                const error_message = result.response.name; // { name: "team with this email already exists" }
                setErrorList([...errorList, error_message[0]]);
            }
        } else {
            // send data to API for Team Update
            const result = await requests.put(API.TEAMS + teamId + "/", data)

            if (result.status === 200) {
                onConfirm(result.response)
                onClose();
            } else {
                const error_message = result.response.name; // { name: "team with this email already exists" }
                setErrorList([...errorList, error_message[0]]);
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            { errorList.length > 0 &&
                <ul>
                    {errorList.map((error, index) =>
                        <li key={index}> {error} </li>
                    )}
                </ul>
            }

            <Form.Field>
                <Form.Input
                    autoFocus
                    required
                    label={t('team_name')}
                    placeholder={t('team_name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Field>
            <Form.Field>
                <Form.Input
                    label={t('team_description')}
                    placeholder={t('team_description')}
                    control='textarea' rows='2'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    type="button"
                    style={{ backgroundColor: "var(--variant5)" }}
                    onClick={onClose}
                    disabled={isProcessing}>
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    style={{ backgroundColor: "var(--success)" }}
                    disabled={isProcessing || isEmpty(name)}
                    loading={isProcessing}
                >
                    {btnName}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default TeamForm