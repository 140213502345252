import React from 'react';
import { useTranslation } from 'react-i18next';
// store
import { routes } from '@routes';
import { API } from '@store/config';
// components
import ListView from '@components/ListView';
import SuperField from '@components/forms/SuperField';
import GenerateDocuments from '@components/GenerateDocuments';
// module specific components
import StockItemForm from '../components/StockItemForm';
import StoredItemHistory from '../components/StoredItemHistory';
import StoredItemOverview from '../components/StoredItemOverview';
import StockItemCorrectionForm from '../components/StockItemCorrectionForm';

const StockList = ({ warehouses, selectedWarehouse }) => {
    const { t } = useTranslation()

    return (
        <ListView as="table"
            allowSearch
            allowSelection
            isSoftDeleteAllowed
            actionsCellWidth="2"
            key={selectedWarehouse?.id}
            endpoint={API.ASSET_STORED_ITEMS}
            tableHeaders={[
                { title: t('code') },
                { title: t('item') },
                { title: t('measurement_type') + " / " + t('measure_unit') },
                { title: t('warehouse') },
                { title: t('current_stock') },
                // { title: t('note') },
            ]}
            settings={[
                {
                    permissions: ['orders.c_manage_catalogue'],
                    as: "link",
                    // target: "_blank",
                    linkTo: routes.SETTINGS + "catalogue-items",
                    name: t('catalogue_items_management'),
                    icon: "book-outline"
                },
                {
                    permissions: ['assets.c_manage_warehouses'],
                    as: "link",
                    // target: "_blank",
                    linkTo: routes.SETTINGS + "warehouses",
                    name: t('warehouse_management'),
                    icon: "albums-outline"
                },
                {
                    permissions: ['assets.c_manage_warehouses'],
                    as: "link",
                    // target: "_blank",
                    linkTo: routes.SETTINGS + "warehouse-positions",
                    name: t('warehouse_positions_management'),
                    icon: "albums-outline"
                },
            ]}
            initialFilters={{
                warehouse: selectedWarehouse?.id || "",
                catalogue_item: "",
                show_only_stored_items: "",
                exclude_asset_item: true,
                code: ""
            }}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <SuperField as="choice"
                        search
                        text="title"
                        label={t("item")}
                        value={filters.catalogue_item}
                        endpoint={API.CATALOGUE_ITEMS + "?query={id, title}&is_service=false"}
                        onChange={(e, { value }) => setFilters({ ...filters, catalogue_item: value })}
                    />

                    <SuperField as="input"
                        label={t("code")}
                        value={filters.code}
                        onChange={(e, { value }) => setFilters({ ...filters, code: value })}
                    />

                    { selectedWarehouse?.id === undefined && 
                        <SuperField as="choice"
                            search
                            label={t("warehouse")}
                            value={filters.warehouse}
                            onChange={(e, { value }) => setFilters({ ...filters, warehouse: value })}
                            customOptions={warehouses.map(item => ({ key: item.id, value: item.id, text: item.title }))}
                        />
                    }

                    <SuperField as="checkbox"
                        label={t('show_only_stored_items')}
                        checked={filters.show_only_stored_items}
                        onChange={() => setFilters({ ...filters, show_only_stored_items: filters.show_only_stored_items === "" ? true : ""})}
                    />
                </>
            )}
            renderCells={(stockitem, setData, setTotal, idx, filters) => (
                [
                    {
                        content: (stockitem?.catalogue_item?.code  ? stockitem?.catalogue_item?.code : "--")
                    },
                    {
                        content: stockitem?.catalogue_item?.title
                    },
                    {
                        content: (stockitem?.catalogue_item?.weight_is_primary_measurement ? t('weight') : t('packages_pieces')) + " / " + (stockitem?.catalogue_item?.measure_unit?.abbreviation)
                    },
                    {
                        content: stockitem.warehouse?.title || ""
                    },
                    {
                        content: stockitem.quantity
                    },
                    
                    // {
                    //     content: stockitem.note
                    // },
                ]
            )}
            bulkActions={ (selected) => [
                { 
                    as: "modal",
                    name: t('generate_documents'),
                    icon: "document-text-outline",
                    modal: <GenerateDocuments source="assets.StoredItem" selected={selected} />
                }
            ]}
            actions={[
                {
                    as: "modal",
                    type: "add",
                    modalSize: "tiny",
                    name: t('store_item'),
                    modalCentered: false,
                    modal: <StockItemForm warehouses={warehouses} selectedWarehouse={selectedWarehouse}/>,
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_items', 'assets.c_view_warehouse_processes', 'assets.c_manage_warehouse_processes'],
                },
                {
                    as: "modal",
                    type: "custom",
                    modalSize: "tiny",
                    icon: "add-circle-outline",
                    name: t('store_item'),
                    modalCentered: false,
                    customIconColor: "var(--dark)",
                    modal: <StockItemForm warehouses={warehouses} selectedWarehouse={selectedWarehouse}/>,
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_items', 'assets.c_view_warehouse_processes', 'assets.c_manage_warehouse_processes']
                },
                {
                    as: "modal",
                    type: "custom",
                    icon: "eye-outline",
                    modalSize: "large",
                    name: t('stock_details'),
                    customIconColor: "var(--primary)",
                    modal: (item) => <StoredItemOverview storedItem={item}/>,
                    matchEveryPermission: true,
                    permissions: ['assets.c_view_items', 'assets.c_view_warehouse_processes']
                },
                {
                    as: "modal",
                    type: "custom",
                    modalSize: "tiny",
                    icon: "swap-vertical-outline",
                    name: t('make_a_correction'),
                    customIconColor: "var(--success)",
                    modal: (item, setData) => <StockItemCorrectionForm storedItem={item} setData={setData}/>,
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_items', 'assets.c_view_warehouse_processes', 'assets.c_manage_warehouse_processes']
                },
                {
                    as: "modal",
                    type: "custom",
                    name: t('history'),
                    modalSize: "large",
                    icon: "reader-outline",
                    permissions: ['assets.c_view_item_history'],
                    customIconColor: "var(--blue)",
                    modal: <StoredItemHistory/>
                },
                {
                    name: t('delete'),
                    type: "delete",
                    as: "delete",
                    text: t('stored_item_delete_message'),
                    matchEveryPermission: true,
                    permissions: ['assets.c_manage_items', 'assets.c_manage_warehouse_processes'],
                },
            ]}
        />
    );
};

export default StockList;