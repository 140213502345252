import React from 'react';
// store 
import { routes } from '@store/routes'
// components
import { Grid, Header } from 'semantic-ui-react';
import ReportCard from './ReportCard';
import CanView from '@components/perms/CanView';

const ReportSection = ({ isActive, reports, section, sectionName }) => {
    return (
        <>
            { isActive &&
                <>
                    { reports.filter(report => report.section === section && report.isActive === true).length > 0 &&
                        <>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Header as="h2" content={sectionName} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                {reports.map((report, index) => (
                                    <CanView permissions={report.canView}>
                                        { report.section === section &&
                                            <ReportCard
                                                key={index}
                                                linkTo={report.isActive ? routes.REPORTS + report.slug + "/" : routes.REPORTS}
                                                category={report.category}
                                                name={report.name}
                                                isActive={report.isActive}
                                            />
                                        }
                                    </CanView>
                                ))}
                            </Grid.Row>
                        </>}
                </>
            }
        </>
    );
};

export default ReportSection;