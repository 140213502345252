import React from "react";
//components
import { Grid, Rating } from "semantic-ui-react";

const RatingComment = (props) => {
    return (
        <Grid columns={2}>
            <Grid.Row verticalAlign="middle" textAlign="left">
                <div style={{ paddingTop: "0.4rem" }}>
                    <Rating
                        maxRating={5}
                        rating={props.rating}
                        clearable
                        size="huge"
                        disabled
                    />
                </div>
            </Grid.Row>
        </Grid>
    );
};
export default RatingComment;
