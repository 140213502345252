import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-semantic-toasts'
import * as ExcelJS from 'exceljs'
import moment from 'moment'
// store
import { routes } from '@routes'
import { API } from '@store/config'
import { useHasEveryPermissions } from '@helpers/hooks'
import { requests } from '@helpers/requests'
// components
import Icon from '@components/Icon'
import SuperField from '@components/forms/SuperField'
import { ExcelLikeTable } from '@components/ExcelLikeTable'
import SpinnerSegment from '@components/SpinnerSegment'
import ModalSubmit from '@components/buttons/ModalSubmit'
import ModalCancel from '@components/buttons/ModalCancel'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Button, Grid, Modal, Form, Divider, Header, Message, Dropdown, Table } from 'semantic-ui-react'
// order module component
import OrderDetail from '../../orders/shared/OrderDetail'

const LogisticBulkImport = () => {
    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const [file, setFile] = useState(null)
    const [isFileUpload, setIsFileUpload] = useState(false)
    const [items, setItems] = useState([])
    const [response, setResponse] = useState(null)
    const [schema] = useState({
        id: 'import-parko-logistics-bulk',
        title: 'import_parko_logistics-bulk',
        config: {
            headers: [
                {
                    columnName: 'Trans. type',
                    title: 'transport_type',
                    type: 'text',
                    valueVariable: 'text',
                    required: 'true',
                },
                {
                    columnName: 'Order num.',
                    title: 'order_number',
                    type: 'text',
                    valueVariable: 'text',
                    required: 'true',
                },
                { columnName: 'Commodity', title: 'commodity', type: 'text', valueVariable: 'text', required: 'true' },
                {
                    columnName: 'Departure date / loading date',
                    title: 'loading_date',
                    type: 'text',
                    valueVariable: 'text',
                    dataType: 'date',
                    dateFormat: 'DD.MM.YYYY',
                    required: 'true',
                },
                {
                    columnName: 'Expected delivery date',
                    title: 'expected_delivery_date',
                    type: 'text',
                    valueVariable: 'text',
                    dataType: 'date',
                    required: 'true',
                    dateFormat: 'DD.MM.YYYY',
                },
                {
                    columnName: 'Dispatch station / loading address',
                    title: 'loading_address',
                    type: 'text',
                    valueVariable: 'text',
                },
                { columnName: 'Shipper / Carrier', title: 'carrier', type: 'text', valueVariable: 'text' },
                {
                    columnName: 'Containers/Type of wagon',
                    title: 'type_of_wagon_containers',
                    type: 'text',
                    valueVariable: 'text',
                },
                {
                    columnName: 'Trans. number / Wagon number',
                    title: 'transport_number',
                    type: 'text',
                    valueVariable: 'text',
                },
                { columnName: 'RWB / Group', title: 'rwb__group', type: 'text', valueVariable: 'text' },
                {
                    columnName: 'Quantity',
                    title: 'quantity',
                    type: 'text',
                    valueVariable: 'text',
                    required: 'true',
                    dataType: 'floatnumber',
                },
                {
                    columnName: 'Fare/Transport fee',
                    title: 'transport_fee',
                    type: 'text',
                    valueVariable: 'text',
                    dataType: 'floatnumber',
                },
                {
                    columnName: 'Destination station/address',
                    title: 'destination_address',
                    type: 'text',
                    valueVariable: 'text',
                },
                { columnName: 'Invoice', title: 'invoice_number', type: 'text', valueVariable: 'text' },
            ],
            evidence: 'logistics',
            endpoint: 'logistics/',
        },
    })
    const [isCleared, setIsCleared] = useState(false)
    const [wasSchemeChanged, setWasSchemeChanged] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    function excludeEmptyRows(items) {
        const list = items.filter((item) => {
            return Object.values(item).some((value) => value) !== false
        })

        return list
    }

    const isTableFilled = () => {
        return excludeEmptyRows(getDataset(items)).length > 0
    }

    const getDataset = (data) => {
        let dataset = []
        let config = schema?.config?.headers

        for (let i = 0; i < data.length; i++) {
            let newItem = {}
            let item = data[i]

            for (let y = 0; y < config?.length; y++) {
                const title = config[y].title
                const dateType = config[y].dataType
                const dateFormat = config[y].dateFormat || 'YYYY-MM-DD'

                // if empty assign empty string and continue
                if ([undefined, null, ''].includes(item[title])) {
                    if (dateType === 'date') {
                        newItem[title] = null
                    } else {
                        newItem[title] = ''
                    }

                    continue
                }

                // if not empty the convert to proper datatypes
                if (dateType === 'number') {
                    if (isNaN(item[title])) {
                        newItem[title] = item[title]
                    } else {
                        newItem[title] = parseInt(item[title])
                    }
                }
                if (dateType === 'floatNumber') newItem[title] = parseFloat(item[title])
                if (dateType === 'date') {
                    if (moment(item[title]).isValid()) {
                        newItem[title] = moment(item[title]).format(dateFormat)
                    } else {
                        newItem[title] = item[title]
                    }
                }

                if (!['number', 'floatNumber', 'date'].includes(dateType)) {
                    newItem[title] = item[title]
                }
            }

            dataset.push(newItem)
        }

        return dataset
    }

    const handleFileFormSubmit = async () => {
        await handleImport(file)
    }

    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Logistics')
        const headers = schema.config.headers.map((item) => item.columnName)
        worksheet.addRow(headers)

        excludeEmptyRows(getDataset(items)).forEach((item) => {
            const { '#': excludedColumn, ...newItem } = item
            const rowData = Object.values(newItem)
            worksheet.addRow(rowData)
        })

        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const file = new File([blob], 'data.xlsx', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(file)
        downloadLink.download = 'logistics_bulk__' + moment().format('DD.MM.YYYY') + '.xlsx'

        document.body.appendChild(downloadLink)
        downloadLink.click()
    }

    const handleImport = async (file) => {
        setIsProcessing(true)
        setIsOpen(true)
        setResponse(null)
        setIsFileUpload(false)

        file = file || undefined
        if (file === undefined) {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Logistics')
            const headers = schema.config.headers.map((item) => item.columnName)
            worksheet.addRow(headers)

            excludeEmptyRows(getDataset(items)).forEach((item) => {
                const { '#': excludedColumn, ...newItem } = item
                const rowData = Object.values(newItem)
                worksheet.addRow(rowData)
            })

            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            file = new File([blob], 'data.xlsx', {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
        }

        const formData = new FormData()
        formData.append('file', file)

        const request = await requests.post(API.IMPORTS + schema.config.endpoint, formData)
        setResponse(request)
        setIsProcessing(false)
    }

    return (
        <div style={{ width: '100%' }}>
            <Grid style={{ marginBottom: '1rem' }}>
                <Grid.Row columns={1} style={{ padding: 0 }}>
                    <Grid.Column style={{ padding: 0, textAlign: 'right' }}>
                        <Dropdown
                            simple
                            style={{ background: 'var(--black)', color: 'var(--white)', marginRight: '0.5rem' }}
                            className="button icon"
                            floating
                            trigger={
                                <span>
                                    {/* <Icon name="information-circle-outline" style={{ marginRight: "0.5rem" }}/> */}
                                    <span style={{ marginRight: '1rem', position: 'relative', top: '-0.1rem' }}>
                                        {t('imports_more_options')}
                                    </span>
                                </span>
                            }
                        >
                            <Dropdown.Menu style={{ zIndex: 999 }}>
                                <SuperDuperModal
                                    size="tiny"
                                    centered={false}
                                    trigger={
                                        <Dropdown.Item>
                                            <Icon name="book-outline" style={{ marginRight: '0.5rem' }} />
                                            <span style={{ position: 'relative', top: '-0.2rem' }}>
                                                {t('show_columns_description')}
                                            </span>
                                        </Dropdown.Item>
                                    }
                                    content={<ImportColumnDocumentation />}
                                />
                                <Dropdown.Item onClick={() => handleDownload()}>
                                    <Icon name="download-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {' '}
                                        {t('download_table_as_xlsx')}{' '}
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={isProcessing}
                                    onClick={() => {
                                        setIsOpen(true)
                                        setIsFileUpload(true)
                                    }}
                                >
                                    <Icon name="push-outline" style={{ marginRight: '0.5rem' }} />
                                    <span style={{ position: 'relative', top: '-0.1rem' }}>
                                        {' '}
                                        {t('import_your_file')}{' '}
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button disabled style={{ background: 'transparent', opacity: '0.8' }} content="|" />
                        <Button
                            disabled={!isTableFilled()}
                            style={{ background: 'var(--danger)', color: 'var(--white)' }}
                            size="small"
                            onClick={() => setIsCleared(true)}
                        >
                            <Icon name="trash-outline" style={{ marginRight: '0.5rem' }} />
                            <span style={{ position: 'relative', top: '-0.1rem' }}> {t('clear')} </span>
                        </Button>
                        <Button
                            primary
                            size="small"
                            disabled={!isTableFilled() || isProcessing}
                            onClick={() => handleImport()}
                        >
                            <Icon name="push-outline" style={{ marginRight: '0.5rem' }} />
                            <span style={{ position: 'relative', top: '-0.1rem' }}> {t('import')} </span>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <ExcelLikeTable
                selectedScheme={schema}
                onChange={(items) => setItems(items)}
                isCleared={isCleared}
                setIsCleared={setIsCleared}
                wasSchemeChanged={wasSchemeChanged}
                setWasSchemeChanged={setWasSchemeChanged}
                isProcessing={isProcessing}
            />

            <Modal
                size={isFileUpload ? 'tiny' : 'small'}
                open={isOpen}
                centered={false}
                closeIcon={!isProcessing && response !== null && response.status !== 200}
                closeOnDimmerClick={false}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                onClose={() => {
                    setResponse(null)
                    setFile(null)
                    setIsOpen(false)
                    setIsProcessing(false)
                }}
            >
                <ModalContentView
                    isFileUpload={isFileUpload}
                    handleFileFormSubmit={handleFileFormSubmit}
                    setIsOpen={setIsOpen}
                    setIsFileUpload={setIsFileUpload}
                    isProcessing={isProcessing}
                    file={file}
                    setFile={setFile}
                    response={response}
                />
            </Modal>
        </div>
    )
}

const ModalContentView = ({
    isFileUpload,
    handleFileFormSubmit,
    setIsOpen,
    setIsFileUpload,
    isProcessing,
    file,
    setFile,
    response,
}) => {
    const { t } = useTranslation()

    const hasErrors = response?.status >= 400

    const onClose = () => {
        setFile(null)
        setIsOpen(false)
        setIsFileUpload(false)
    }

    return (
        <Modal.Content>
            {isFileUpload ? (
                <Form onSubmit={handleFileFormSubmit}>
                    <SuperField
                        as="input"
                        type="file"
                        required
                        label={t('upload_file')}
                        id="fileInput"
                        // ref={(el) => console.log(el)}
                        onChange={(event) => {
                            if (event.target?.files?.length > 0) {
                                setFile(event.target.files[0])
                            }
                        }}
                    />

                    <Divider />
                    <Form.Field style={{ textAlign: 'right' }}>
                        <ModalCancel onClose={onClose} />
                        <ModalSubmit disabled={file === null} />
                    </Form.Field>
                </Form>
            ) : (
                <SpinnerSegment
                    loading={isProcessing}
                    loadingMessage={
                        <div style={{ fontSize: '1.5rem' }}>
                            {t('processing_import_this_may_take_a_while')} <br />
                            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {t('do_not_close_this_window_please')}
                            </p>
                        </div>
                    }
                >
                    {!isProcessing && (
                        <>
                            <Header as="h3" content={t('bulk_import_results')} />
                            <Divider />
                            {hasErrors ? (
                                <RenderErrors response={response?.response || null} />
                            ) : (
                                <AfterImportActions response={response} onClose={onClose} />
                            )}
                        </>
                    )}
                </SpinnerSegment>
            )}
        </Modal.Content>
    )
}

const RenderErrors = ({ response }) => {
    const { t } = useTranslation()
    const invalidRecords = response?.result?.invalid || []

    return (
        <>
            {(response?.detail || response?.file) && (
                <Message visible error style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                    <Icon
                        name="warning-outline"
                        style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                    />
                    <span style={{ position: 'relative', top: '-0.2rem' }}>{response?.detail || response?.file}</span>
                </Message>
            )}

            {invalidRecords?.length !== 0 && (
                <>
                    <Message visible info style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                        <Icon
                            name="information-circle-outline"
                            style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                        />
                        <span style={{ position: 'relative', top: '-0.2rem' }}>
                            {t('some_records_contain_errors_see_list_down_bellow')}.
                        </span>
                    </Message>
                    <Divider />

                    {invalidRecords.map((error, idx) => (
                        <div key={idx}>
                            <strong>
                                {t('invalid_row')} {error.row}:
                            </strong>{' '}
                            <br />
                            <ul>
                                {error.errors.map((item, idx) => (
                                    <>
                                        {typeof item === 'object' ? (
                                            Array.isArray(item) ? (
                                                item?.map((message, index) => (
                                                    <li key={index}>
                                                        {' '}
                                                        {message} <br />
                                                    </li>
                                                ))
                                            ) : item !== undefined && item !== null ? (
                                                Object.keys(item)?.map((key) => (
                                                    <>
                                                        <strong> {key}: </strong> <br />
                                                        <ul>
                                                            {item[key].map((message) => (
                                                                <li> {message} </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                ))
                                            ) : null
                                        ) : (
                                            <li key={idx}>
                                                {' '}
                                                {item} <br />
                                            </li>
                                        )}
                                    </>
                                ))}
                            </ul>
                        </div>
                    ))}
                </>
            )}
        </>
    )
}

const ImportColumnDocumentation = ({ onClose }) => {
    const { t } = useTranslation()

    return (
        <>
            <Header as="h3" content={t('columns_description')} />
            <Divider />

            <div>
                <strong>{t('transport_type')}</strong> (
                <Link target="_blank" to={routes.SETTINGS + 'types/transport-types/'}>
                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                        {t('manage_transport_types')}
                    </span>
                </Link>
                ) <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>povinný údaj</li>
                    <li>{t('logistic__transport_type_column_desc')}</li>
                </ul>
            </div>

            <div>
                <strong>{t('order_number')}</strong> (
                <Link target="_blank" to={routes.ORDERS}>
                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                        {t('manage_orders')}
                    </span>
                </Link>
                ) <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>povinný údaj</li>
                    <li>očakáva sa textový výraz ako vlastné čislo alebo interné číslo priradené k objednávke</li>
                    <li>
                        čislo objednávky slúži na identifikáciu a automatické spojenie logistického záznamu a
                        existujúcej objednávky
                    </li>
                </ul>
            </div>

            <div>
                <strong>{t('commodity')}</strong> (
                <Link target="_blank" to={routes.SETTINGS + 'catalogue-items/'}>
                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                        {t('manage_catalogue_items')}
                    </span>
                </Link>
                ) <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>povinný údaj</li>
                    <li>
                        očakáva sa textový výraz ako názov katalógovej položky alebo čiselný kód priradený v katalógu
                        položiek
                    </li>
                    <li>
                        tovar slúži na identifikáciu a automatické spojenie logistického záznamu a objednaného tovaru v
                        objednávke
                    </li>
                </ul>
            </div>

            <div>
                <strong>{t('loading_date')}</strong> <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>povinný údaj</li>
                    <li>očakáva sa dátum vo formáte DD.MM.YYYY (príklad: 24.12.2023)</li>
                </ul>
            </div>
            <div>
                <strong>{t('expected_delivery_date')}</strong> <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>povinný údaj</li>
                    <li>očakáva sa dátum vo formáte DD.MM.YYYY (príklad: 24.12.2023)</li>
                </ul>
            </div>
            <div>
                <strong>{t('loading_address')}</strong> <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>očakáva sa textový výraz vo forme názov mesta</li>
                </ul>
            </div>
            <div>
                <strong>{t('carrier')}</strong> (
                <Link target="_blank" to={routes.SUPPLIERS}>
                    <span className="ref-link" style={{ color: 'var(--primary)' }}>
                        {t('manage_suppliers')}
                    </span>
                </Link>
                )<br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>očakáva sa textový výraz ako IČO (bez medzier) alebo názov ako obchodné meno spoločnosti</li>
                    <li>
                        slúži na bližšiu špecifikáciu o dopravcovi a automaticky prepája existujúci záznam z evidencie
                        dodávateľov, ak sa záznam v evidencií nezhoduje, tak sa nepriradí a ostane dopravca nevyplnený v
                        logistickom zázname
                    </li>
                </ul>
            </div>
            <div>
                <strong>{t('type_of_wagon_containers')}</strong>
                <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>očakáva sa textový výraz ako bližšie označenie umiestnania vo vozidle</li>
                </ul>
            </div>
            <div>
                <strong>{t('transport_number')}</strong>
                <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>očakáva sa textový výraz ako bližšie označenie vozidla (napr. EČV, číslo vlaku, čislo lode)</li>
                </ul>
            </div>
            <div>
                <strong>{t('rwb__group')}</strong>
                <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>očakáva sa textový výraz ako bližšie označenie umiestnenia vo vozidle</li>
                </ul>
            </div>
            <div>
                <strong>{t('quantity')}</strong>
                <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>
                        očakáva sa čiselný výraz ako množstvo tovaru - celočiselné alebo s desatinou časťou (max. 4)
                    </li>
                </ul>
            </div>
            <div>
                <strong>{t('transport_fee')}</strong> <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>
                        očakáva sa čiselný výraz ako cena prepravy (celková), navyšuje cenu na zaplatenie, ktorá sa
                        vypočíta ako jednotková cena objednaného tovaru * objednané množstvo + cena za dopravu
                    </li>
                </ul>
            </div>
            <div>
                <strong>{t('destination_address')}</strong> <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>očakáva sa textový výraz vo forme názov mesta</li>
                </ul>
            </div>
            <div>
                <strong>{t('invoice_number')}</strong> <br />
                <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>nepovinný údaj</li>
                    <li>
                        očakáva sa textový výraz ako číslo faktúry v prípade, že tento údaj je vyplnený systém
                        automaticky priradí dátum dodania do logistického záznamu a označí záznam ako doručené
                    </li>
                </ul>
            </div>
        </>
    )
}

const AfterImportActions = ({ response, onClose }) => {
    const { t } = useTranslation()
    let history = useHistory()
    const dateFormat = useSelector((state) => state.date_format)

    const canViewOrder = useHasEveryPermissions(['orders.c_view_order'])
    const hadFulfillmentPerms = useHasEveryPermissions(['orders.c_manage_order_fulfillment'])

    const [createOrderFulfillments, setCreateOrderFulfillments] = useState(false)
    const [redirectToEvidence, setRedirectToEvidence] = useState(true)
    const [processing, setProcessing] = useState(false)

    const totals = response?.response?.result?.totals?.new
    const records = response?.response?.new_records || []

    const handleSubmit = async () => {
        setProcessing(true)
        if (redirectToEvidence) {
            history.push(routes.LOGISTICS)
        }

        if (createOrderFulfillments) {
            const fulfillments = []
            for (let i = 0; i < records.length; i++) {
                const record = records[i]
                const fulfillment = {
                    order: record.order.id,
                    resourcetype: 'SaleFulfillment',
                    total_fee: record.price_paid,
                    order_item: record.order_commodity.id,
                    currency: record.currency,
                    quantity: record.quantity,
                    delivery_date: record.delivery_date || record.expected_delivery_date,
                    logistic_record: record.id,
                }

                fulfillments.push(fulfillment)
            }

            const request = await requests.post(API.ORDERS + 'fulfillments/bulk/', fulfillments)
            if (request.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('fulfillments_assigned_successfully'),
                    animation: 'pulse',
                    time: 2000,
                })
                history.push(routes.LOGISTICS)
            }
        }
        setProcessing(false)
    }

    return (
        <>
            <Message visible success style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                <Icon
                    name="checkmark-circle-outline"
                    style={{ marginRight: '0.8rem', fontSize: '1.5rem', position: 'relative', top: '0.1rem' }}
                />
                <span style={{ position: 'relative', top: '-0.2rem' }}>
                    {t('import_was_successful_total_newly_added_records')}: {totals?.new || 0}
                </span>
            </Message>
            <Divider />
            <Header as="h4" content={t('select_action_to_peform')} />

            <Form onSubmit={handleSubmit}>
                <SuperField
                    as="radio"
                    label={t('redirect_to_evidence')}
                    checked={redirectToEvidence}
                    onChange={() => {
                        setRedirectToEvidence(true)
                        setCreateOrderFulfillments(false)
                    }}
                />

                <Form.Field>
                    <SuperField
                        as="radio"
                        disabled={!hadFulfillmentPerms || records.length === 0}
                        label={t('create_order_fulfillments') + ' (' + records.length + ')'}
                        checked={createOrderFulfillments}
                        help={t('create_fulfillmnets_from_logistic_record_hint')}
                        onChange={() => {
                            setCreateOrderFulfillments(true)
                            setRedirectToEvidence(false)
                        }}
                    />
                    {!hadFulfillmentPerms && (
                        <p style={{ color: 'var(--danger)', fontWeight: 'bold' }}>
                            {t('you_dont_have_permissions_to_create_order_fullfillments')}
                        </p>
                    )}
                    {/* { hadFulfillmentPerms && records.length !== 0 
                        ? 
                        <div style={{ marginLeft: "1.8rem" }}>
                            <SuperField as="checkbox"
                                label={t('attach_invoice_to_fulfillments')}
                                // onChange={() => {
                                //     setCreateOrderFulfillments(true)
                                //     setRedirectToEvidence(false)
                                // }}
                            />
                        </div>
                        : null } */}
                </Form.Field>
                {createOrderFulfillments && records.length > 0 ? (
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content={t('order_number')} />
                                <Table.HeaderCell content={t('commodity')} />
                                <Table.HeaderCell content={t('delivery_date')} />
                                <Table.HeaderCell content={t('quantity')} />
                                <Table.HeaderCell content={t('sum')} />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {records.map((record, idx) => (
                                <Table.Row key={record.id}>
                                    <Table.Cell>
                                        {record.order.is_deleted !== true ? (
                                            canViewOrder ? (
                                                <SuperDuperModal
                                                    size="large"
                                                    trigger={
                                                        <span className="ref-link" style={{ color: 'var(--primary)' }}>
                                                            {record?.order?.order_number ||
                                                                record?.order.original_order_number}
                                                        </span>
                                                    }
                                                    content={<OrderDetail id={record.order.id} />}
                                                />
                                            ) : (
                                                <span>
                                                    {record?.order?.order_number || record?.order.original_order_number}
                                                </span>
                                            )
                                        ) : (
                                            <span style={{ color: 'var(--danger)' }}> {t('order_was_deleted')} </span>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <strong>
                                            {record.order_commodity
                                                ? `${
                                                      record.order_commodity?.item?.code
                                                          ? record.order_commodity?.item?.code
                                                          : ''
                                                  } ${record.order_commodity?.item?.title}`
                                                : '--'}
                                        </strong>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(record.delivery_date || record.expected_delivery_date).format(
                                            dateFormat
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {record.quantity} {record.measure_unit ? record.measure_unit?.abbreviation : ''}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {record.price_paid} {record.currency}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                ) : null}

                <Divider />
                <Form.Field style={{ textAlign: 'right' }}>
                    <ModalCancel disabled={processing} onClose={onClose} />
                    <ModalSubmit disabled={processing} loading={processing} />
                </Form.Field>
            </Form>
        </>
    )
}

export default LogisticBulkImport
