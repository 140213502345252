import React from 'react';
import { useTranslation } from "react-i18next"
// store
import { /*isEmpty,*/ isEmptyErrorList } from '@helpers/validation';
// components
import { Button, Checkbox, Divider, Form, Icon } from 'semantic-ui-react';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';
import ChoiceField from '@components/forms/common/ChoiceField';

const PersonView = ({ visible, person, setPerson, isExternal, setIsExternal, setView }) => {
    const { t } = useTranslation();

    return (
        <div>
            {visible &&
                <>
                    <Form.Group widths={3}>
                        <Form.Field>
                            <Form.Input
                                required
                                autoFocus
                                label={t("personal_number")}
                                placeholder={t("enter_personal_number")}
                                value={person.personalNumber}
                                onChange={(e) => setPerson({
                                    ...person,
                                    personalNumber: e.target.value
                                })}
                            />
                        </Form.Field>

                        <ChoiceField
                            required
                            type="genders"
                            label={t('gender')}
                            value={person.gender}
                            onChange={(e, data) => setPerson({
                                ...person,
                                gender: data.value
                            })}
                        />

                        <ChoiceField
                            required
                            type="marital_statuses"
                            label={t("marital_status")}
                            value={person.maritalStatus}
                            onChange={(e, data) => setPerson({
                                ...person,
                                maritalStatus: data.value
                            })}
                        />
                    </Form.Group>
                    <Form.Group widths={2}>
                        <Form.Field>
                            <Form.Input
                                required
                                label={t("firstname")}
                                placeholder={t("enter_firstname")}
                                value={person.firstname}
                                onChange={(e) => setPerson({
                                    ...person,
                                    firstname: e.target.value
                                })}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                required
                                label={t("lastname")}
                                placeholder={t("enter_lastname")}
                                value={person.lastname}
                                onChange={(e) => setPerson({
                                    ...person,
                                    lastname: e.target.value
                                })}
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group widths={2}>
                        <PhoneField
                            required
                            placeholder={t("enter_phone")}
                            value={person.contact.phone}
                            setValue={(e) => setPerson({
                                ...person,
                                contact: {
                                    ...person.contact,
                                    phone: e.target.value
                                }
                            })}
                            phoneType={person.contact.phoneType}
                            setPhoneType={(e, data) => setPerson({
                                ...person,
                                contact: {
                                    ...person.contact,
                                    phoneType: data.value
                                }
                            })}
                        />

                        <EmailField
                            placeholder={t("enter_email")}
                            value={person.contact.email}
                            setValue={(e) => setPerson({
                                ...person,
                                contact: {
                                    ...person.contact,
                                    email: e.target.value
                                }
                            })}
                        />
                    </Form.Group>

                    <Divider style={{ marginTop: "2rem", marginBottom: "2rem" }} horizontal>
                        {t("add_info")}
                    </Divider>

                    <Form.Group widths={3}>
                        <Form.Field>
                            <Form.Input
                                label={t("middlename")}
                                placeholder={t("enter_middlename")}
                                value={person.middlename}
                                onChange={(e) => setPerson({
                                    ...person,
                                    middlename: e.target.value
                                })}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label={t("title_before")}
                                placeholder={t("enter_title_before")}
                                value={person.titleBefore}
                                onChange={(e) => setPerson({
                                    ...person,
                                    titleBefore: e.target.value
                                })}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                label={t("title_after")}
                                placeholder={t("enter_title_after")}
                                value={person.titleAfter}
                                onChange={(e) => setPerson({
                                    ...person,
                                    titleAfter: e.target.value
                                })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Checkbox
                        label={t('set_is_external')}
                        checked={isExternal}
                        onChange={() => setIsExternal(!isExternal)}
                    />

                    <Divider />

                    <Button.Group floated="right">
                        <Button
                            type="button"
                            onClick={() => setView(2)}
                            primary
                            disabled={!isEmptyErrorList([
                                person.firstname,
                                person.lastname,
                                person.personalNumber,
                                person.gender,
                                person.contact.phone,
                                person.contact.phoneType,
                                person.maritalStatus
                            ])}>
                            {t("next")} <Icon name="arrow right" />
                        </Button>
                    </Button.Group>
                </>
            }
        </div>
    );
};

export default PersonView;