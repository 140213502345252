import React, { useEffect, useState } from "react";

// store
import { activationService } from "../../../store/services/ServiceActivation";
import { LOADING_TIME } from "../../../store/config";

// components
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import ActivationSuccessMessage from "./helpers/ActivationSuccessMessage";
import ActivationErrorMessage from "./helpers/ActivationErrorMessage";

// component
function Activation(props) {

    // state
    const [userId] = useState(props.match.params.uid);
    const [token] = useState(props.match.params.token);
    const [isLoading, setIsLoading] = useState(false);
    const [tryingAgain, setTryingAgain] = useState(false);
    const [activationSuccessful, setActivationSuccessful] = useState(false);

    // check if activation is successful
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const result = await activationService.getUserActivation(
                userId,
                token
            );
            if (!result) {
                setActivationSuccessful(false);
            } else {
                setActivationSuccessful(true);
            }

            setTimeout(function () {
                setIsLoading(false);
            }, LOADING_TIME);
        };
        fetchData();
    }, [userId, token]);

    const activateAgain = async () => {
        setTryingAgain(true);

        const result = await activationService.getUserActivation(userId, token);
        if (result) {
            setTryingAgain(false);
            setActivationSuccessful(true);
        } else {
            setTryingAgain(false);
            setActivationSuccessful(false);
        }
    };

    // template
    return (
        <div className="center-element">
            {isLoading ? (
                <Segment style={{ maxWidth: 450, width: 450 }} placeholder>
                    <Dimmer active inverted>
                        <Loader size="massive" inverted>
                            Your account is activating, please wait.
                        </Loader>
                    </Dimmer>
                </Segment>
            ) : (
                <Segment
                    className="center-segment"
                    textAlign="center"
                    loading={tryingAgain}
                >
                    {activationSuccessful ? (
                        <ActivationSuccessMessage />
                    ) : (
                        <ActivationErrorMessage
                            tryAgain={activateAgain}
                        />
                    )}
                </Segment>
            )}
        </div>
    );
}

export default Activation;
