import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import NonFieldErrors from '@components/NonFieldErrors';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ApprovalRequestForm = ({ selected, onClose, setData }) => {
    const { t } = useTranslation()
    const records = selected.map(item => item.id)
    const [form, setForm] = useState({
        responsible_person: "",
        records: { add: records }
    })

    const [errors, setErrors] = useState(null)
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async () => {
        setErrors(null)
        setProcessing(true)
        const request = await requests.post(API.APPROVALS + "timesheets/", form)

        if (request.status === 201) {
            setData(prev => prev.filter(item => {
                if (records.includes(item.id)) {
                    item.approval = request.response
                }

                return item
            }))
            onClose()
        } else if (request.status === 400) {
            setErrors(request.response)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <NonFieldErrors errors={errors} />
            <SuperField as="choice"
                search
                required 
                label={t('responsible_person')}
                value={form.responsible_person}
                error={errors?.responsible_person?.[0] || false}
                onChange={ (e, { value }) => setForm({ ...form, responsible_person: value })}
                endpoint={API.EMPLOYEES + "?only_basic_info=true&has_user=true&is_active=true"}
                text="fullname"
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalSubmit
                    loading={processing}
                    disabled={processing || form.responsible_person === ""}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default ApprovalRequestForm;