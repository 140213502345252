import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { Divider, Grid, Header, Label, Segment, Table } from 'semantic-ui-react';

const CatalogueItemDetail = ({ item }) => {
    const { t } = useTranslation();

    return (
        <div style={{ padding: '2rem' }}>
            <Header as={'h3'}>
                <Grid style={{ padding: 0 }}>
                    <Grid.Row columns="2" style={{ padding: 0 }}>
                        <Grid.Column textAlign='left' style={{ padding: 0 }}>
                            {item.title}
                            <Label
                                style={{
                                    fontSize: '0.9rem',
                                    padding: '0.4rem',
                                    paddingTop: '0.6rem',
                                    marginLeft: '2rem',
                                    color: 'white',
                                    backgroundColor: item?.category?.color,
                                }}
                                key={item?.category?.id}
                            >
                                <span style={{ position: 'relative', top: '-0.1rem' }}>{item?.category?.title}</span>
                            </Label>
                            {item.is_fragile && <Label content={item?.is_fragile && t('fragile')} />}
                            {item.code && <Label content={item?.code} color={'black'} />}
                            <br />
                            <Header.Subheader>
                                <div>{item?.is_service ? t('service') : t('product')}</div>
                            </Header.Subheader>
                        </Grid.Column>
                        <Grid.Column textAlign="right" style={{ padding: 0 }}>
                            <div style={{ textAlign: 'right', fontSize: '15px' }}>
                                <Icon name='ellipse' style={{ color: !item.is_archived ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }} />
                                <span style={{ position: "relative", top: "-0.1rem" }}> {!item.is_archived  ? t('active') : t('archived')} </span>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Header>
            <Divider />
            <Grid>
                <Grid.Row columns={item?.material_color ? '4' : '3'} divided>
                    <Grid.Column textAlign="left">
                        <Header as={'h4'}>
                            {t('amount')}
                            <Header.Subheader content={item?.amount || "-"} />
                        </Header>
                    </Grid.Column>
                    {item?.material_color &&
                        <Grid.Column textAlign="left">
                            <Header as={'h4'}>
                                {t('material_color')}
                                <Header.Subheader content={item?.material_color || "-"} />
                            </Header>
                        </Grid.Column>
                    }
                    <Grid.Column textAlign="left">
                        <Header as={'h4'}>
                            {t('quality')}
                            <Header.Subheader content={item?.quality || "-"} />
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign="left">
                        <Header as={'h4'}>
                            {t('owner')}
                            <Header.Subheader content={item?.owner?.name || "-"} />
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            <Grid>
                <Grid.Row columns={item.is_service ? '1' : '2'}>
                    <Grid.Column style={{ paddingLeft: 0, paddingRight: item.is_service && 0 }}>
                        <Segment>
                            <Header as={'h4'} content={t('price')} />
                            <Table singleLine style={{ border: 0 }}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell textAlign='left' content={t('buy_unit_price')} />
                                        <Table.Cell textAlign='right' content={item?.buy_price ? (item.buy_price + " " + item?.buy_price_currency) : "-"} />
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell textAlign='left' content={t('sell_unit_price')} />
                                        <Table.Cell textAlign='right' content={item?.sell_price_without_tax ? (item.sell_price_without_tax + " " + item?.buy_price_currency) : "-"} />
                                    </Table.Row>
                                    {/* <Table.Row>
                                        <Table.Cell textAlign='left' content={t('sell_price_with_tax')} />
                                        <Table.Cell textAlign='right' content={item?.sell_price_with_tax ? (item.sell_price_with_tax + " " + item?.buy_price_currency) : "-"} />
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell textAlign='left' content={t('sell_tax')} />
                                        <Table.Cell textAlign='right' content={item?.sell_tax || "-"} />
                                    </Table.Row> */}
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Grid.Column>
                    {!item.is_service &&
                        <Grid.Column style={{ paddingRight: 0 }}>
                            <Segment>
                                <Header as={'h4'} content={t('measurements')} />
                                <Table singleLine style={{ border: 0 }}>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell textAlign='left' content={t('measurement_x')} />
                                            <Table.Cell textAlign='right' content={item?.measurement_x ? (item?.measurement_x + " " + item?.measure_unit?.abbreviation) : "-"} />
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left' content={t('measurement_y')} />
                                            <Table.Cell textAlign='right' content={item?.measurement_y ? (item?.measurement_y + " " + item?.measure_unit?.abbreviation) : "-"} />
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left' content={t('measurement_z')} />
                                            <Table.Cell textAlign='right' content={item?.measurement_z ? (item?.measurement_z + " " + item?.measure_unit?.abbreviation) : "-"} />
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell textAlign='left' content={t('average_package_weight')} />
                                            <Table.Cell textAlign='right' content={item?.average_package_weight ? (item?.average_package_weight + " " + item?.measure_unit?.abbreviation) : "-"} />
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    }
                </Grid.Row>
            </Grid>
            {item?.description &&
                <>
                    <Header as={'h4'} content={t('description')} />
                    <Header.Subheader style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{item?.description}</Header.Subheader>
                    <Header.Subheader style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{item?.addition_description_info}</Header.Subheader>
                </>
            }
        </div>
    );
};

export default CatalogueItemDetail;
