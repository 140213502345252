import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Address from '@components/forms/Address';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Header, Divider } from 'semantic-ui-react';

const EmployedPersonForm = ({ record, setData, onClose }) => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [form, setForm] = useState({
        fee_per_hour: record?.fee_per_hour || "",
        currency: record?.currency || "",
        valid_from: record?.valid_from || "",
        valid_to: record?.valid_to || "",
        calculate_work_hours_based_on: record?.calculate_work_hours_based_on?.toString() || "",
    })

    const [workplace, setWorkplace] = useState({
        street: record?.workplace_address?.street || "",
        city: record?.workplace_address?.city || "",
        postcode: record?.workplace_address?.postcode || "",
        country: record?.workplace_address?.country || "SK",
        address_line: record?.workplace_address?.address_line || "",
        state: record?.workplace_address?.state || "",
        number: record?.workplace_address?.number || "",
        orientation_number: record?.workplace_address?.orientation_number || ""
    })

    const isWorkplaceFilled = () => {
        if (
            workplace.street === "" || 
            workplace.city === "" || 
            workplace.postcode === "" || 
            workplace.country === ""
        ) {
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        setProcessing(true)
        const request = await requests.patch(API.ORDERS + "employment_items/" + record.id + "/", {
            ...form,
            workplace_address: isWorkplaceFilled() ? workplace : null,
            fee_per_hour: form.fee_per_hour !== "" ? form.fee_per_hour : null,
            valid_from: form.valid_from !== "" ? form.valid_from : null,
            valid_to: form.valid_to !== "" ? form.valid_to : null,
            currency: form.currency
        })

        if (request.status === 200) {
            setData(prev => prev.map(item => {
                if (item.id === record.id) {
                    item = request.response
                }
                return item
            }))
            onClose()
        }
        setProcessing(false)
    }


    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3">{ t('update') }</Header>
            <Divider/>

            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    type="number"
                    label={t("fee_per_hour")}
                    value={form.fee_per_hour}
                    onChange={(e, { value }) => setForm(prev => ({...prev, fee_per_hour: value}))}
                />
                <SuperField as="choice"
                    search
                    clearable={false}
                    type="currency_codes"
                    label={t("currency")}
                    value={form.currency}
                    onChange={(e, { value }) => setForm(prev => ({...prev, currency: value}))}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    label={t("valid_from")}
                    value={form.valid_from}
                    onChange={(e, { value }) => setForm(prev => ({...prev, valid_from: value}))}
                />
                <SuperField as="datepicker"
                    label={t("valid_to")}
                    value={form.valid_to}
                    onChange={(e, { value }) => setForm(prev => ({...prev, valid_to: value}))}
                />
            </Form.Group>

            <SuperField as="choice"
                clearable={false}
                type="employment_order_calculate_based_on"
                label={t("calculate_work_hours_based_on")}
                help={t('calculate_work_hours_based_on_hint')}
                value={form.calculate_work_hours_based_on}
                onChange={(e, { value }) => setForm(prev => ({...prev, calculate_work_hours_based_on: value}))}
            />

            <Header as="h4" content={t('workplace')} style={{ marginBottom: "0rem" }}/>
            <Divider />
            <Address 
                isForm 
                address={workplace}
                setAddress={setWorkplace}
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    text={t('confirm')}
                    loading={processing}
                    disabled={processing || form.fee_per_hour === ""}
                />
            </Form.Field>
        </Form>
    );
};

export default EmployedPersonForm;