import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
import { useHasPermissions } from '@helpers/hooks';
// components
import { Form } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import AvatarList from '@components/AvatarList';
import RecruitersNewForm from '../forms/RecruitersNewForm';

const RecruitersList = ({ candidate, recruitment, recruiters, room }) => {
    const { t } = useTranslation();

    const canManage = useHasPermissions(["candidates.c_manage_recruitment"]);

    const [data, setData] = useState(recruiters || []);
    const [dataRecruiters, setDataRecruiters] = useState(recruiters ? recruiters?.map(item => item?.recruiter?.id) : [] )
    const [options, setOptions] = useState([]);

    useEffect(() => {
        async function fetchRecruitersOptions() {
            let optionsArray = []
            for (let i = 0; i < room?.recruiters?.length; i++) {
                optionsArray.push({
                    key: room.recruiters[i].id, text: room.recruiters[i].fullname, value: room.recruiters[i].id
                });
            }
            setOptions(optionsArray);
        }

        fetchRecruitersOptions();
        // eslint-disable-next-line
    }, [])

    const onRemove = async (id) => {
        const request = await requests.del(API.RECRUITMENT_ASSIGNMENTS + id + "/")

        if (request.status === 204) {
            const newList = data.filter(item => item.id !== id)
            setData(newList)
        }
    }

    const RecruitersForm = ({ data, room, dataRecruiters, setDataRecruiters, onClose }) => {
        const [isProcessing, setIsProcessing] = useState(false)
        const dataArray = data?.map(item => item.recruiter.id)

        const onSubmit = async () => {
            setIsProcessing(true)

            if(dataRecruiters){
                for(let i = 0; i < dataRecruiters.length; i++){
                    if(!dataArray.includes(dataRecruiters[i])){
                        const dataObject = {
                            candidate: candidate,
                            recruiter: dataRecruiters[i],
                            recruitment: recruitment
                        }

                        const request = await requests.post(API.RECRUITMENT_ASSIGNMENTS, dataObject)

                        if (request.status === 201) {
                            setData(prev => [request.response, ...prev])
                        } 
                    }
                }
            }

            onClose()
            setIsProcessing(false)
        }

        return (
            <Form onSubmit={onSubmit}>
                <RecruitersNewForm
                    value={dataRecruiters}
                    onChange={(value) => setDataRecruiters(value)}
                    options={options} 
                    setOptions={setOptions} 
                    recruiters={recruiters} 
                    room={room}
                />
                <Form.Field style={{ textAlign: "right" }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit
                        disabled={isProcessing}
                        loading={isProcessing}
                        text={t('confirm')}
                    />
                </Form.Field>
            </Form>
        );
    }

    return (
        <Fragment>
            <AvatarList 
                size="25"
                alignDirection="start"
                allowAdditions
                canManage={canManage}
                persons={
                    data?.map(item => ({
                        ID: item?.id,
                        id: item?.recruiter?.id,
                        name: item?.recruiter?.fullname,
                        avatar: item?.recruiter?.profile_picture,
                        displayName: item?.recruiter?.fullname_with_titles || item?.recruiter?.fullname,
                    }))
                }
                addForm={<RecruitersForm data={data} room={room} dataRecruiters={dataRecruiters} setDataRecruiters={setDataRecruiters} />}
                onRemove={(id) => onRemove(id)}
            />
        </Fragment>
    );
};

export default RecruitersList;
