import React, { useState } from 'react';
import { HuePicker } from 'react-color';
import { useSelector } from 'react-redux';


const ColorPicker = ({ onChange, value }) => {
    const default_color = useSelector((state) => state.global_pref.colors.primary)
    const [color, setColor] = useState(value ? value : default_color)

    function handleChange(input) {
        setColor(input)
        onChange(input)
    }
    return (
        <div style={{ display: 'flex', flexDirection: "column" }}>
            <HuePicker
                color={color}
                onChange={(e) => handleChange(e.hex)}
                width={'100%'}
            />
            <div
                style={{ backgroundColor: color, padding: '1rem' }}
            />
        </div>
    );
};

export default ColorPicker;
