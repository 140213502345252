import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line
import moment from 'moment'
// components
import { Form, Divider, Label } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const findAbsenceType = (type, absenceTypes) => {
    return absenceTypes.find((item) => item.id === type)
}

const AbsenceForm = ({ onClose, day, absenceTypes, record, setRecords }) => {
    const { t } = useTranslation()

    const [removeAbsence, setRemoveAbsence] = useState(false)
    const [form, setForm] = useState({
        type: record?.data?.absence_type || '',
        vacation_hours: record?.data?.vacation_hours || 8,
        value: record?.data?.[findAbsenceType(record?.data?.absence_type, absenceTypes)?.source] || '',
    })

    const handleSubmit = () => {
        let source = findAbsenceType(form.type, absenceTypes)?.source
        if (source === 'absence') source = 'absention'

        if (removeAbsence === false) {
            setRecords((prev) =>
                prev.filter((item) => {
                    if (item.day === day) {
                        item.data = {
                            ...item.data,
                            vacation: '',
                            care_for_a_family_member: '',
                            family_member_paragraph: '',
                            paragraph: '',
                            sick_day: '',
                            absention: '',
                            absence_type: form.type,
                            absence_type_display: findAbsenceType(form.type, absenceTypes)?.title,
                            [source]: form.value,
                            vacation_hours: source === 'vacation' ? form.vacation_hours : '',
                        }
                    }

                    return item
                })
            )
        } else {
            setRecords((prev) =>
                prev.filter((item) => {
                    if (item.day === day) {
                        item.data = {
                            ...item.data,
                            absence_type: '',
                            absence_type_display: '',
                            [source]: '',
                        }
                    }

                    return item
                })
            )
        }

        onClose()
    }

    const isUpdate = () => {
        let source = findAbsenceType(form.type, absenceTypes)?.source
        if (source === 'absence') source = 'absention'

        return record.data[source] > 0
    }

    const isFormValid = () => {
        let source = findAbsenceType(form.type, absenceTypes)?.source
        if (source === 'absence') source = 'absention'

        if (form.type === '') return false
        if (form.value === '' || isNaN(form.value) || parseFloat(form.value) > 24 || parseFloat(form.value) <= 0)
            return false
        if (source === 'vacation') {
            if (
                form.vacation_hours === '' ||
                isNaN(form.vacation_hours) ||
                parseFloat(form.vacation_hours) > 24 ||
                parseFloat(form.vacation_hours) <= 0
            )
                return false
        }
        return true
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                as="choice"
                search
                required
                value={form.type}
                label={t('absence_type')}
                customOptions={absenceTypes.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.title,
                }))}
                onChange={(e, { value }) => {
                    setForm((prev) => ({ ...prev, type: value, value: '' }))
                }}
            />

            {findAbsenceType(form.type, absenceTypes)?.source !== 'vacation' ? (
                <Form.Field>
                    <SuperField
                        as="input"
                        required
                        label={t('number_of_hours')}
                        value={form.value}
                        error={
                            isNaN(form.value) || parseFloat(form.value) > 24 || parseFloat(form.value) <= 0
                                ? t('invalid_number')
                                : false
                        }
                        onChange={(e, { value }) => setForm((prev) => ({ ...prev, value: value.replace(',', '.') }))}
                    />
                    <Label.Group simple>
                        <Label
                            style={{ cursor: 'pointer' }}
                            active={form.value.toString() === '7.5'}
                            onClick={() => setForm((prev) => ({ ...prev, value: 7.5 }))}
                        >
                            7.5h
                        </Label>
                        <Label
                            style={{ cursor: 'pointer' }}
                            active={form.value.toString() === '8'}
                            onClick={() => setForm((prev) => ({ ...prev, value: 8 }))}
                        >
                            8h
                        </Label>
                        <Label
                            style={{ cursor: 'pointer' }}
                            active={form.value.toString() === '12'}
                            onClick={() => setForm((prev) => ({ ...prev, value: 12 }))}
                        >
                            12h
                        </Label>
                    </Label.Group>
                </Form.Field>
            ) : (
                <>
                    <SuperField
                        as="choice"
                        required
                        label={t('choose_option')}
                        value={form.value !== '0.00' ? form.value || '' : ''}
                        customOptions={[
                            { key: 1, value: '1.00', text: t('fullday_vacation') },
                            { key: 2, value: '0.50', text: t('halfday_vacation') },
                        ]}
                        onChange={(e, { value }) => {
                            if (parseFloat(value) === 0.5) {
                                setForm((prev) => ({ ...prev, value: value, vacation_hours: 4 }))
                            } else {
                                setForm((prev) => ({ ...prev, value: value, vacation_hours: 8 }))
                            }
                        }}
                    />

                    <Form.Field>
                        <SuperField
                            as="input"
                            required
                            label={t('number_of_hours')}
                            value={form.vacation_hours}
                            error={
                                isNaN(form.vacation_hours) ||
                                parseFloat(form.vacation_hours) > 24 ||
                                parseFloat(form.vacation_hours) <= 0
                                    ? t('invalid_number')
                                    : false
                            }
                            onChange={(e, { value }) =>
                                setForm((prev) => ({ ...prev, vacation_hours: value.replace(',', '.') }))
                            }
                        />
                        <Label.Group simple>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={form.vacation_hours.toString() === '4'}
                                onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 4 }))}
                            >
                                4h
                            </Label>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={form.vacation_hours.toString() === '6'}
                                onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 6 }))}
                            >
                                6h
                            </Label>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={form.vacation_hours.toString() === '7.5'}
                                onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 7.5 }))}
                            >
                                7.5h
                            </Label>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={form.vacation_hours.toString() === '8'}
                                onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 8 }))}
                            >
                                8h
                            </Label>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={form.vacation_hours.toString() === '10'}
                                onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 10 }))}
                            >
                                10h
                            </Label>
                            <Label
                                style={{ cursor: 'pointer' }}
                                active={form.vacation_hours.toString() === '12'}
                                onClick={() => setForm((prev) => ({ ...prev, vacation_hours: 12 }))}
                            >
                                12h
                            </Label>
                        </Label.Group>
                    </Form.Field>
                </>
            )}

            {isUpdate() && (
                <SuperField
                    as="checkbox"
                    label={t('remove_work_absence')}
                    checked={removeAbsence}
                    onChange={() => setRemoveAbsence(!removeAbsence)}
                />
            )}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit disabled={!isFormValid()} />
            </Form.Field>
        </Form>
    )
}

export default AbsenceForm
